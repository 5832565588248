import React, { Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CrearProducto from "./crearProducto";
import DescuentoProductos from "./Descuentos/Descuento";
import EliminarProducto from "./EliminarProducto";
import Tooltip from "@material-ui/core/Tooltip";
import GenerarEtiquetaBarcode from "./Etiquetas/GenerarEtiquetaBarcode";
import { formatoMexico } from "../../../../config/reuserFunctions";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_INVENTORY } from "../../../../gql/Catalogos/productos";
import { useContext } from "react";
import { RegProductoContext } from "../../../../context/Catalogos/CtxRegProducto";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "60vh",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
  avatar: {
    width: 130,
    height: 130,
  },
  encabezadoEditar: {
    position: "sticky",
    right: 192.5,
  },
  encabezadoDescuento: {
    position: "sticky",
    right: 84.5,
  },
  encabezadoEliminar: {
    position: "sticky",
    right: 0,
  },
  columnaFijaEditar: {
    position: "sticky",
    right: 192.5,
    backgroundColor: "#fff",
  },
  columnaFijaDescuento: {
    position: "sticky",
    right: 84.5,
    backgroundColor: "#fff",
  },
  columnaFijaEliminar: {
    position: "sticky",
    right: 0,
    backgroundColor: "#fff",
  },
  tableFixed: {
    position: "sticky",
    top: 0,
  },
  tableCellLong: {
    minWidth: "250px",
  },
  tableCellNormal: {
    minWidth: "170px",
  },
  tableCellShort: {
    minWidth: "100px",
  },
});

export default function ListaProductos({
  page,
  setPage,
  limit,
  isOnline,
  obtenerProductos,
}) {
  const classes = useStyles();
  const [shadow, setShadow] = React.useState("leftShadowTable");
  const tableEl = React.useRef();
  /* Queries */

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === realWidth) {
      setShadow("removeShadowTable");
      return;
    }
    setShadow("leftShadowTable");
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <div className={classes.root}>
      <TableContainer
        className={classes.container}
        ref={tableEl}
        onScroll={handleOnScroll}
      >
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead className="tableFixed">
            <TableRow>
              <TableCell className={classes.tableCellNormal}>
                Código de barras
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Clave alterna
              </TableCell>
              <TableCell className={classes.tableCellLong}>
                Nombre comercial
              </TableCell>
              <TableCell className={classes.tableCellShort}>
                Existencia
              </TableCell>
              <TableCell className={classes.tableCellShort}>Tipo</TableCell>

              <TableCell className={classes.tableCellNormal}>
                Clave SAT
              </TableCell>
              <TableCell className={classes.tableCellLong}>Categoría</TableCell>
              <TableCell className={classes.tableCellLong}>
                Subcategoría
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Departamento
              </TableCell>
              <TableCell className={classes.tableCellNormal}>Marca</TableCell>
              <TableCell className={classes.tableCellShort}>Receta</TableCell>
              <TableCell className={classes.tableCellLong}>
                Monedero Electrónico
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Unidad de compra
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Cantidad por unidad
              </TableCell>
              <TableCell className={classes.tableCellLong}>
                Precio de compra
              </TableCell>
              <TableCell className={classes.tableCellShort}>IVA</TableCell>
              <TableCell className={classes.tableCellShort}>IEPS</TableCell>
              <TableCell className={classes.tableCellNormal}>
                Precio 1
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Precio 2
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Precio 3
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Precio 4
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Precio 5
              </TableCell>
              <TableCell className={classes.tableCellNormal}>
                Precio 6
              </TableCell>
              <TableCell className={classes.tableCellShort}>
                Descuento
              </TableCell>
              <TableCell className={classes.tableCellShort}>Unidad</TableCell>
              <TableCell className={classes.tableCellNormal}>
                Vender sin inventario
              </TableCell>
              <TableCell className={`${classes.encabezadoEditar} ${shadow}`}>
                Editar
              </TableCell>
              <TableCell className={classes.encabezadoDescuento}>
                Descuento
              </TableCell>
              <TableCell className={classes.encabezadoEliminar}>
                Eliminar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="hide-scroll">
            {obtenerProductos.docs.map((producto, index) => {
              return (
                <RenderTableRows
                  key={index}
                  producto={producto}
                  isOnline={isOnline}
                  classes={classes}
                  shadow={shadow}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={obtenerProductos.totalDocs}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
      />
    </div>
  );
}

const RenderTableRows = ({ producto, isOnline, classes, shadow }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { setAlert } = useContext(RegProductoContext);
  const [loading, setLoading] = useState(false);
  const [sinInventario, setSinInventario] = useState(
    producto.withoutInventory ? producto.withoutInventory : false
  );
  const [updateInventorySell] = useMutation(UPDATE_INVENTORY);
  const { getData } = useContext(AccesosContext);

  const checkWithoutInventory = async (e) => {
    setLoading(true);
    try {
      const result = await updateInventorySell({
        variables: {
          id: producto._id,
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          withoutInventory: e.target.checked,
        },
      });
      setSinInventario(!sinInventario);
      setAlert({
        message: `¡Listo! ${result.data.updateInventorySell.message}`,
        status: "success",
        open: true,
      });
      getData(sesion.empresa, sesion.sucursal, "PRODUCTOS");
      //setUpdate(!update);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <GenerarEtiquetaBarcode producto={producto} />
        </TableCell>
        <TableCell>{producto.datos_generales.clave_alterna}</TableCell>
        <TableCell>{producto.datos_generales.nombre_comercial}</TableCell>
        <TableCell>
          {producto.inventario_general.map(
            (existencia) =>
              `${existencia.cantidad_existente} ${existencia.unidad_inventario}`
          )}
        </TableCell>
        <TableCell>{producto.datos_generales.tipo_producto}</TableCell>

        <TableCell>
          {producto.datos_generales.clave_producto_sat.Value}
        </TableCell>
        <TableCell>{producto.datos_generales.categoria}</TableCell>
        <TableCell>{producto.datos_generales.subcategoria}</TableCell>
        <TableCell>{producto.datos_generales.departamento}</TableCell>
        <TableCell>{producto.datos_generales.marca}</TableCell>
        <TableCell>
          {producto.datos_generales.receta_farmacia === true ? "SI" : "NO"}
        </TableCell>
        <TableCell>{"$" + producto.precios.monedero_electronico}</TableCell>
        <TableCell>{producto.precios.unidad_de_compra.unidad}</TableCell>
        <TableCell>{producto.precios.unidad_de_compra.cantidad}</TableCell>
        <TableCell>
          {"$" +
            formatoMexico(
              producto.precios.precio_de_compra.precio_con_impuesto
            )}
        </TableCell>
        <TableCell>
          {"$" + formatoMexico(producto.precios.precio_de_compra.iva)}
        </TableCell>
        <TableCell>
          {"$" + formatoMexico(producto.precios.precio_de_compra.ieps)}
        </TableCell>

        {producto.precios.precios_producto.map((precio, index) => {
          return (
            <TableCell key={index}>
              {"$" + formatoMexico(precio.precio_neto)}
            </TableCell>
          );
        })}

        <TableCell>
          {(producto.unidades_de_venta[0] &&
            producto.unidades_de_venta[0].descuento_activo === true) ||
          (producto.medidas_producto[0] &&
            producto.medidas_producto[0].descuento_activo === true)
            ? "SI"
            : "NO"}
        </TableCell>

        {producto.unidades_de_venta.map((unidades_de_venta, index) => {
          if (unidades_de_venta.unidad_principal === true) {
            return (
              <TableCell key={index}>{unidades_de_venta.unidad}</TableCell>
            );
          }
        })}

        <TableCell>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <FormControlLabel
              control={
                <Checkbox
                  checked={sinInventario}
                  onChange={checkWithoutInventory}
                  name="withoutInventory"
                />
              }
              name="vender_sin_inventario"
            />
          )}
        </TableCell>

        <TableCell
          className={`${classes.columnaFijaEditar} ${shadow}`}
          align="center"
          padding="checkbox"
        >
          {sesion.accesos.catalogos.productos.editar === false ? null : (
            <CrearProducto
              isOnline={isOnline}
              accion={true}
              datos={producto}
              sinInventario={sinInventario}
              setSinInventario={setSinInventario}
            />
          )}
        </TableCell>
        <TableCell
          className={classes.columnaFijaDescuento}
          align="center"
          padding="checkbox"
        >
          <DescuentoProductos isOnline={isOnline} datos={producto} />
        </TableCell>
        <TableCell
          className={classes.columnaFijaEliminar}
          align="center"
          padding="checkbox"
        >
          {sesion.accesos.catalogos.productos.eliminar === false ? null : (
            <Tooltip
              title="Solo se puede eliminar un producto si este tiene 0 en inventarios"
              arrow
            >
              <EliminarProducto isOnline={isOnline} datos={producto} />
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
