import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { useMutation, useQuery } from "@apollo/client";
import { OBTENER_CAJAS } from "../../../gql/Cajas/cajas";
import { REGISTRAR_TURNOS } from "../../../gql/Ventas/abrir_cerrar_turno";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { AccesosContext } from "../../../context/Accesos/accesosCtx";
import moment from "moment-timezone";
import { Launch } from "@material-ui/icons";
import { MainContext } from "../../../context/MainCtx";

export default function AbrirTurno({ loading, setLoading }) {
  let sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [CrearRegistroDeTurno] = useMutation(REGISTRAR_TURNOS);
  const { /* setTurnoActivo,  */ setAlert } = useContext(VentasContext);
  const { isOnline, getData, ventaToCloud } = useContext(AccesosContext);
  const [error, setError] = useState(false);
  const [abrirTurno, setAbrirTurno] = useState({
    turno_en_curso: "MATUTINO",
    caja_elegida: "",
    monto_abrir: "0",
  });
  const [numeroCaja, setNumeroCaja] = useState("");
  const { setTurnoEnCurso } = React.useContext(MainContext);

  const principal = sesion.accesos.tesoreria.caja_principal.ver;

  let obtenerCajasSucursal = [];

  const cajas = useQuery(OBTENER_CAJAS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
    },
  });

  const obtenerTurno = (e) => {
    setAbrirTurno({ ...abrirTurno, [e.target.name]: e.target.value });
  };

  if (cajas.data) {
    obtenerCajasSucursal = cajas.data.obtenerCajasSucursal;
  }

  let arraySesion = { ...sesion, turno_en_caja_activo: true };

  const enviarDatos = async () => {
    setLoading(true);
    try {
      if (
        !abrirTurno?.turno_en_curso ||
        !abrirTurno?.caja_elegida ||
        !abrirTurno?.monto_abrir
      ) {
        setError(true);
        setLoading(false);
        setAlert({
          message: `Por favor complete los datos obligatorios`,
          status: "error",
          open: true,
        });
        return;
      } else {
        const input = {
          horario_en_turno: abrirTurno?.turno_en_curso,
          concepto: "ABRIR TURNO",
          numero_caja: numeroCaja.toString(),
          comentarios: abrirTurno?.comentarios,
          id_caja: abrirTurno?.caja_elegida,
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          id_usuario: sesion._id,
          usuario_en_turno: {
            nombre: sesion.nombre,
            telefono: sesion.telefono,
            numero_usuario: sesion.numero_usuario,
            email: sesion.email,
          },
          hora_entrada: {
            hora: moment().tz("America/Mexico_City").format("hh"),
            minutos: moment().tz("America/Mexico_City").format("mm"),
            segundos: moment().tz("America/Mexico_City").format("ss"),
            completa: moment().tz("America/Mexico_City").format("HH:mm:ss"),
          },
          hora_salida: {
            hora: "",
            minutos: "",
            segundos: "",
            completa: "",
          },
          fecha_entrada: {
            year: moment().tz("America/Mexico_City").format("YYYY"),
            mes: moment()
              .tz("America/Mexico_City")
              .tz("America/Mexico_City")
              .format("MM"),
            dia: moment().tz("America/Mexico_City").format("DD"),
            no_semana_year: moment()
              .tz("America/Mexico_City")
              .week()
              .toString(),
            no_dia_year: moment()
              .tz("America/Mexico_City")
              .dayOfYear()
              .toString(),
            completa: moment().tz("America/Mexico_City").format("YYYY-MM-DD"),
          },
          fecha_salida: {
            year: "",
            mes: "",
            dia: "",
            no_semana_year: "",
            no_dia_year: "",
            completa: "",
          },
          fecha_movimiento: moment().tz("America/Mexico_City").format(),
          montos_en_caja: {
            monto_efectivo: {
              monto: parseFloat(abrirTurno?.monto_abrir),
              metodo_pago: "01",
            },
            monto_tarjeta_debito: {
              monto: 0,
              metodo_pago: "28",
            },
            monto_tarjeta_credito: {
              monto: 0,
              metodo_pago: "04",
            },
            monto_creditos: {
              monto: 0,
              metodo_pago: "99",
            },
            monto_monedero: {
              monto: 0,
              metodo_pago: "05",
            },
            monto_transferencia: {
              monto: 0,
              metodo_pago: "03",
            },
            monto_cheques: {
              monto: 0,
              metodo_pago: "02",
            },
            monto_vales_despensa: {
              monto: 0,
              metodo_pago: "08",
            },
          },
        };
        const variableTurnoAbierto = await CrearRegistroDeTurno({
          variables: {
            activa: true,
            input,
            isOnline: isOnline,
          },
        });
        if (variableTurnoAbierto.data.crearRegistroDeTurno === null) {
          setLoading(false);
          setAlert({
            message: `Lo sentimos esta caja ya esta en uso`,
            status: "error",
            open: true,
          });
        } else {
          let turno = {
            ...variableTurnoAbierto.data.crearRegistroDeTurno,
            onCloud: isOnline,
          };
          setTurnoEnCurso(turno)
          localStorage.setItem("sesionCafi", JSON.stringify(arraySesion));
          //setTurnoActivo(true);
          setAlert({
            message: `Turno abierto`,
            status: "success",
            open: true,
          });
          //if (type !== "FRENTE") handleClickOpen();

          if (ventaToCloud && isOnline) {
            getData(sesion.empresa, sesion.sucursal);
            //window.location.reload();
          }
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error)
      if (error.networkError?.result) {
        console.log(error.networkError.result.errors);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors.message);
      }
      setAlert({
        message: `Error: ${error.message}`,
        status: "error",
        open: true,
      });
      /* if (type !== "FRENTE") {
        handleClickOpen();
      } */
    }
  };

  const handleTurno = (event, turno_en_curso) => {
    setAbrirTurno({ ...abrirTurno, turno_en_curso });
  };

  return (
    <React.Fragment>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Empleado</Typography>
            <TextField
              fullWidth
              size="small"
              name="nombre_usuario"
              onChange={obtenerTurno}
              disabled={true}
              value={sesion ? sesion.nombre : ""}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">Abrir con</Typography>
            <TextField
              fullWidth
              size="small"
              name="monto_abrir"
              inputProps={{ min: 0 }}
              type="number"
              onChange={obtenerTurno}
              value={abrirTurno?.monto_abrir}
              variant="outlined"
              error={error && !abrirTurno?.monto_abrir}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2">Turno</Typography>
            <ToggleButtonGroup
              value={abrirTurno?.turno_en_curso}
              exclusive
              onChange={handleTurno}
              size="small"
              style={{ width: "100%" }}
            >
              <ToggleButton
                style={{ textTransform: "none", width: "100%" }}
                value="MATUTINO"
              >
                Matutino
              </ToggleButton>
              <ToggleButton
                style={{ textTransform: "none", width: "100%" }}
                value="VESPERTINO"
              >
                Vespertino
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 8 }}>
            <Typography variant="subtitle2">
              <span style={{ color: "red" }}>* </span>Selecciona caja disponible
            </Typography>
            <Grid container spacing={1} style={{ marginTop: 4 }}>
              {obtenerCajasSucursal.map((caja) => {
                const disabled = caja.activa || (caja.principal && !principal);
                const value = abrirTurno?.caja_elegida || "";
                return (
                  <Grid item key={caja.numero_caja}>
                    <Button
                      variant={value === caja._id ? "contained" : "outlined"}
                      value={value}
                      disabled={disabled}
                      onClick={() => {
                        setNumeroCaja(caja.numero_caja);
                        setAbrirTurno({
                          ...abrirTurno,
                          caja_elegida: caja._id,
                        });
                      }}
                      style={{ textTransform: "none" }}
                      color={value === caja._id ? "primary" : "default"}
                    >
                      Caja {caja.numero_caja}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Comentarios</Typography>
            <TextField
              fullWidth
              size="small"
              name="comentarios"
              multiline
              minRows={3}
              onChange={obtenerTurno}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="flex-end">
          <Button
            onClick={enviarDatos}
            variant="contained"
            disableElevation
            color="primary"
            size="large"
            startIcon={
              loading || cajas.loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <Launch />
              )
            }
            disabled={loading || cajas.loading}
          >
            Abrir turno
          </Button>
        </Box>
      </DialogActions>
    </React.Fragment>
  );
}
