import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ArrowBack, Done } from "@material-ui/icons";
import { useQuery } from "@apollo/client";
import { GetQueryMedidas } from "../../../../graphql/queries";
import { MainContext } from "../../../../../../../../context/MainCtx";
import { TraspasosContext } from "../../../../Context/TraspasosContext";
import MedidasContent from "./MedidasContent";

export default function Medidas() {
  const { tokenDecoded } = React.useContext(MainContext);
  const {
    traspaso,
    setTraspaso,
    item,
    setItem,
    initialItem,
  } = React.useContext(TraspasosContext);

  const { loading, data, error } = useQuery(GetQueryMedidas, {
    variables: {
      empresa: tokenDecoded.empresa._id,
      sucursal: tokenDecoded.sucursal._id,
    },
    fetchPolicy: "network-only",
  });

  if (!item.product_selected) return null;
  if (loading) return <p>loading</p>;
  if (error) return <p>error</p>;

  const handleClose = () => {
    setItem(initialItem);
  };

  const addItemTable = () => {
    //darle formato al array
    let new_medidas = [];
    let cantidad_total = 0;

    item.new_medidas.forEach((res) => {
      if (res.cantidad_nueva) {
        const {
          almacen,
          cantidad_nueva,
          descuento,
          descuento_activo,
          unidad,
          codigo_unidad,
          _id,
          ...medida
        } = res;
        cantidad_total += cantidad_nueva;
        new_medidas.push({medida, nuevaCantidad: cantidad_nueva});
      }
    });
    let newItem = { 
        ...item,
        new_medidas,
        cantidad_total,
     };
     

    const productos = [...traspaso.productos];
    //verificar si ya esta en la tabla
    if (productos.length) {
      let repetido = false;
      productos.forEach((elm, i) => {
        if (elm.product_selected?._id === newItem.product_selected?._id) {
          repetido = true;
          productos.splice(i, 1, newItem);
          return;
        }
      });
      if (!repetido) productos.unshift(newItem);
    } else {
      productos.unshift(newItem);
    }

    setTraspaso({
      ...traspaso,
      productos,
    });
    setItem(initialItem);
  };

  const open =
    item.product_selected.datos_generales.tipo_producto !== "OTROS" && true;
  const queryMedidas = {
    data: data?.obtenerConsultasProducto,
    refetch: data.refetch,
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={handleClose}
      ModalProps={{
        BackdropProps: {
          style: {
            background: "none",
          },
        },
      }}
      PaperProps={{
        elevation: 1,
        style: {
          width: "60vw",
        },
      }}
    >
      <DialogTitle component="div">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            <Box mx={1} />
            <Typography variant="h6" color="gray">
              {item.product_selected.datos_generales.nombre_comercial}
            </Typography>
          </Box>
          <Button
            size="small"
            variant="contained"
            disableElevation
            color="primary"
            sx={{ textTransform: "none" }}
            startIcon={<Done />}
            onClick={addItemTable}
          >
            Guardar
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <MedidasContent queryMedidas={queryMedidas} />
      </DialogContent>
    </Drawer>
  );
}
