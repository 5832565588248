import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
  Input,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import React, { Fragment, useContext, useState } from "react";
import moment from "moment-timezone";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import BackdropComponent from "../../../../components/Layouts/BackDrop";
import {
  CREAR_MOVIMIENTO_CUENTA,
  EDITAR_MOVIMIENTO_CUENTA,
} from "../../../../gql/Empresa/sucursales";

import CrearNuevaCuenta from "../../../ventas/Operaciones/DepositoRetiros/crearNuevaCuenta";
import CrearNuevaSubcuenta from "../../../ventas/Operaciones/DepositoRetiros/CrearSubcuenta";
import { OBTENER_USUARIOS } from "../../../../gql/Catalogos/usuarios";

import { useMutation, useQuery } from "@apollo/client";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { MainContext } from "../../../../context/MainCtx";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formInputFlex: {
    display: "flex",
    "& > *": {
      margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
  },
  formInput: {
    margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  formComboBox: {
    height: "50%",
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: "96%",
    maxWidth: "96%",
  },

  icon: {
    width: 40,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 200;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

//const conceptos = ['CORTE DE CAJA','TRANSFERENCIA DE EFECTIVO','APORTACION SOCIOS'];

export default function RetiroDeposito({
  cuenta,
  setReload,
  tipo,
  cajas,
  cuentas,
  refetch,
  open,
  setOpen,
  rowSelectedToEdit,
  setRowSelectedToEdit,
}) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { turnoEnCurso } = React.useContext(MainContext);
  const [CrearMovimientoCuenta] = useMutation(CREAR_MOVIMIENTO_CUENTA);
  const [EditarMovimientoCuenta] = useMutation(EDITAR_MOVIMIENTO_CUENTA);
  const classes = useStyles();
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const [datosMovimiento, setDatosMovimiento] = useState({});
  //const [concepto, setConcepto] = useState("");
  const [usuarioEntrega, setUsuarioEntrega] = useState({});
  const [loading, setLoading] = useState(false);
  const [usuarioRecibe, setUsuarioRecibe] = useState({});
  const [origen, setOrigen] = useState({});
  const [destino, setDestino] = useState({});
  const [cuentaConcepto, setCuentaConcepto] = useState({
    cuenta: {},
    subCuenta: {},
    subcuentas: [],
  });
  const [fechaMovimientoEntrega, setFechaMovimientoEntrega] = useState("");
  const { isOnline } = useContext(AccesosContext);
  //const [fechaMovimientoRecibe, setFechaMovimientoRecibe] = useState("");

  let usuarios = [];

  React.useEffect(() => {
    try {
      let objectTo = {};
      let subCuentasHere = [];
      let cuentaHere = {},
        subCuentaHere = {},
        origenCaja = {},
        destinoCaja = {},
        usuario = {},
        tipoMovimiento = {},
        cantidadHere = 0;

      if (Object.keys(rowSelectedToEdit).length !== 0) {
        Object.keys(rowSelectedToEdit).forEach((element) => {
          if (element === "_id") {
            objectTo._id = rowSelectedToEdit[element];
            console.log(rowSelectedToEdit[element]);
          }
          if (element === "tipo_movimiento") {
            objectTo.tipo_movimiento =
              rowSelectedToEdit[element] === "CUENTA_RETIRO"
                ? "RETIRO"
                : "DEPOSITO";
            tipoMovimiento = objectTo.tipo_movimiento;
          }
          if (element === "montos_en_caja") {
            cantidadHere = rowSelectedToEdit[element].monto_efectivo.monto;
          }

          if (element === "id_concepto_cuenta") {
            if (rowSelectedToEdit[element] !== null) {
              const result = cuentas.filter(
                (res) => res._id === rowSelectedToEdit[element]
              );
              cuentaHere = result[0];
              subCuentasHere = result[0].subcuentas;
            }
            //objectTo.cantidad = rowSelectedToEdit[element].monto_efectivo.monto;
          }
          if (element === "id_concepto_subcuenta") {
            if (rowSelectedToEdit[element] !== null) {
              const result = subCuentasHere.filter(
                (res) => res._id === rowSelectedToEdit[element]
              );
              subCuentaHere = result[0];
            }
            //objectTo.cantidad = rowSelectedToEdit[element].monto_efectivo.monto;
          }
          if (element === "origen_caja") {
            if (rowSelectedToEdit[element] !== null) {
              const result = cajas.filter(
                (res) => res._id === rowSelectedToEdit[element]._id
              );
              origenCaja = result[0];
              objectTo.origen_caja = result[0]._id;
            }
          }
          if (element === "destino_caja") {
            if (rowSelectedToEdit[element] !== null) {
              const result = cajas.filter(
                (res) => res._id === rowSelectedToEdit[element]._id
              );
              destinoCaja = result[0];
              objectTo.destino_caja = result[0]._id;
            }
          }
          if (element === "usuario_entrega") {
            if (rowSelectedToEdit[element] !== null) {
              const result = usuarios.filter(
                (res) => res._id === rowSelectedToEdit[element]._id
              );
              usuario = result[0];
              objectTo.usuario_entrega = result[0]._id;
              setUsuarioEntrega(usuario);
            }
          }
          if (element === "usuario_destino") {
            if (rowSelectedToEdit[element] !== null) {
              const result = usuarios.filter(
                (res) => res._id === rowSelectedToEdit[element]._id
              );
              usuario = result[0];
              objectTo.usuario_destino = result[0]._id;
              setUsuarioRecibe(usuario);
            }
          }
          if (element === "fecha_movimiento_entrega") {
            objectTo.fecha_movimiento_entrega = rowSelectedToEdit[element];
            setFechaMovimientoEntrega(objectTo.fecha_movimiento_entrega);
          }
          if (element === "comentarios") {
            objectTo.comentarios = rowSelectedToEdit[element];
            // setComentarios(objectTo.comentarios);
          }
        });

        objectTo.cantidad =
          tipoMovimiento === "RETIRO"
            ? parseFloat(cantidadHere * -1)
            : parseFloat(cantidadHere);
        setOrigen(origenCaja);
        setDestino(destinoCaja);
        setCuentaConcepto({
          cuenta: cuentaHere,
          subcuentas: subCuentasHere,
          subCuenta: subCuentaHere,
        });
        setDatosMovimiento({ ...datosMovimiento, ...objectTo, editar: true });
      } else {
        setDatosMovimiento({ ...datosMovimiento, editar: false });
      }
    } catch (error) {
      console.log(error);
    }
  }, [rowSelectedToEdit]);

  const queryObtenerUsuarios = useQuery(OBTENER_USUARIOS, {
    variables: {
      sucursal: `${sesion.sucursal._id}`,
      empresa: `${sesion.empresa._id}`,
      eliminado: false,
    },

    fetchPolicy: "network-only",
  });

  const handleClickOpen = () => {
    setDatosMovimiento({
      tipo_movimiento: "",
      cantidad: "",
      fecha_movimiento: "",
    });
    setDestino("");
    setOrigen("");
    setUsuarioEntrega("");
    setUsuarioRecibe("");
    setAlert({ message: "", status: "", open: false });
    setCuentaConcepto({ cuenta: {}, subcuentas: [], subCuenta: {} });
    setOpen(!open);
  };

  const onChangeDatos = (e) => {
    if (e.target.name === "tipo_movimiento") {
      setDestino("");
      setOrigen("");
      setUsuarioEntrega("");
      setUsuarioRecibe("");
      setAlert({ message: "", status: "", open: false });
      setCuentaConcepto({ cuenta: {}, subcuentas: [], subCuenta: {} });
      setDatosMovimiento({});
    }
    setDatosMovimiento({ ...datosMovimiento, [e.target.name]: e.target.value });
  };

  const input = {
    tipo_movimiento:
      datosMovimiento.tipo_movimiento === "RETIRO"
        ? "CUENTA_RETIRO"
        : "CUENTA_DEPOSITO",
    id_User: sesion._id,
    //rol_movimiento: tipo === true ? "CUENTA-EMPRESA" : "CUENTA",
    rol_movimiento: "CAJA_PRINCIPAL",
    //numero_caja: turnoEnCurso ? parseInt(turnoEnCurso.numero_caja) : 0,
    //id_Caja: turnoEnCurso ? turnoEnCurso.id_caja : null,
    numero_usuario_creador: sesion.numero_usuario.toString(),
    nombre_usuario_creador: sesion.nombre,
    horario_turno: turnoEnCurso?.horario_turno
      ? turnoEnCurso.horario_turno
      : "",
    fecha_movimiento_entrega: fechaMovimientoEntrega,
    montos_en_caja: {
      monto_efectivo: {
        monto:
          datosMovimiento.tipo_movimiento === "RETIRO"
            ? parseFloat(datosMovimiento.cantidad * -1)
            : parseFloat(datosMovimiento.cantidad),
        metodo_pago: "01",
      },
      monto_tarjeta_debito: {
        monto: 0,
        metodo_pago: "28",
      },
      monto_tarjeta_credito: {
        monto: 0,
        metodo_pago: "04",
      },
      monto_creditos: {
        monto: 0,
        metodo_pago: "99",
      },
      monto_monedero: {
        monto: 0,
        metodo_pago: "05",
      },
      monto_transferencia: {
        monto: 0,
        metodo_pago: "03",
      },
      monto_cheques: {
        monto: 0,
        metodo_pago: "02",
      },
      monto_vales_despensa: {
        monto: 0,
        metodo_pago: "08",
      },
    },
    comentarios: datosMovimiento.comentarios ? datosMovimiento.comentarios : "",
  };

  const enviarDatos = async () => {
    try {
      setLoading(true);
      if (datosMovimiento.cantidad !== "" && datosMovimiento.cantidad <= 0) {
        setAlert({
          message: "La cantidad debe ser mayor a cero.",
          status: "error",
          open: true,
        });
        setLoading(false);
        return null;
      }

      if (datosMovimiento.tipo_movimiento !== "RETIRO") {
        if (
          fechaMovimientoEntrega === "" ||
          !datosMovimiento.tipo_movimiento ||
          !datosMovimiento.cantidad
        ) {
          setAlert({
            message: "Por favor complete los datos",
            status: "error",
            open: true,
          });
          setLoading(false);
          return null;
        }
      } else {
        if (
          fechaMovimientoEntrega === "" ||
          !datosMovimiento.tipo_movimiento ||
          !datosMovimiento.cantidad
        ) {
          setAlert({
            message: "Por favor complete los datos",
            status: "error",
            open: true,
          });
          setLoading(false);
          return null;
        }
      }

      let nameVariableUsuario =
        datosMovimiento.tipo_movimiento !== "RETIRO"
          ? "usuario_entrega"
          : "usuario_destino";
      let nameVariableCaja =
        datosMovimiento.tipo_movimiento !== "RETIRO"
          ? "origen_caja"
          : "destino_caja";
      let inputToSend = {
        ...input,
        [nameVariableUsuario]:
          datosMovimiento.tipo_movimiento !== "RETIRO"
            ? datosMovimiento.usuario_entrega
            : datosMovimiento.usuario_destino,
        [nameVariableCaja]:
          datosMovimiento.tipo_movimiento !== "RETIRO"
            ? datosMovimiento.origen_caja
            : datosMovimiento.destino_caja,
        concepto_cuenta: cuentaConcepto.cuenta.cuenta,
        id_concepto_cuenta: cuentaConcepto.cuenta._id,
        concepto_subcuenta: cuentaConcepto.subCuenta.subcuenta,
        id_concepto_subcuenta: cuentaConcepto.subCuenta._id,
      };
      let movimiento = {};
      let message = "";

      if (datosMovimiento.editar) {
        inputToSend._id = datosMovimiento._id;
        movimiento = await EditarMovimientoCuenta({
          variables: {
            input: inputToSend,
            empresa: sesion.empresa._id,
            sucursal: sesion.sucursal._id,
            tipo: tipo,
          },
        });
        message = movimiento.data.editarMovimientoCuenta.message;
      } else {
        movimiento = await CrearMovimientoCuenta({
          variables: {
            input: inputToSend,
            empresa: sesion.empresa._id,
            sucursal: sesion.sucursal._id,
            tipo: tipo,
          },
        });
        message = movimiento.data.crearMovimientoCuenta.message;
      }
      setLoading(false);
      setAlert({
        message: message,
        status: "success",
        open: true,
      });

      //refetch();
      setReload(true);
      handleClickOpen();
    } catch (error) {
      setLoading(false);
      setAlert({
        message: error?.message,
        status: "error",
        open: true,
      });
      handleClickOpen();
    }
  };
  const obtenerCuenta = (_, child) => {
    const { cuenta } = child.props;

    setCuentaConcepto({
      cuenta: cuenta ? cuenta : {},
      subcuentas: cuenta ? cuenta.subcuentas : [],
      subCuenta: {},
    });
  };
  const obtenerSubcuenta = (_, child) => {
    const { subCuenta } = child.props;

    setCuentaConcepto({
      ...cuentaConcepto,
      subCuenta: subCuenta ? subCuenta : {},
    });
  };
  /* const handleChangeConcepto = (event) => {
        try {    
            let con = event.target.value;  
           
            setDatosMovimiento({...datosMovimiento, 'concepto': con });
            
            setConcepto(con);    
        } catch (error) {
            //console.log(error)
        }
      
    }; */
  const handleChangeUsuarioEntrega = (event) => {
    try {
      let usu = event.target.value;

      setDatosMovimiento({ ...datosMovimiento, usuario_entrega: usu._id });
      setUsuarioEntrega(usu);
    } catch (error) {
      //console.log(error)
    }
  };
  const handleChangeUsuarioRecibe = (event) => {
    try {
      let usu = event.target.value;

      setDatosMovimiento({ ...datosMovimiento, usuario_destino: usu._id });
      setUsuarioRecibe(usu);
    } catch (error) {
      //console.log(error)
    }
  };
  const handleChangeOrigen = (event) => {
    try {
      let ori = event.target.value;
      setDatosMovimiento({ ...datosMovimiento, origen_caja: ori._id });
      setOrigen(ori);
    } catch (error) {
      //console.log(error)
    }
  };
  const handleChangeDestino = (event) => {
    try {
      let des = event.target.value;
      setDatosMovimiento({ ...datosMovimiento, destino_caja: des._id });
      setDestino(des);
    } catch (error) {
      //console.log(error)
    }
  };
  const handleChangeFechaMovimiento = (event) => {
    try {
      let fecMov = event.target.value;
      setFechaMovimientoEntrega(fecMov);
    } catch (error) {
      //console.log(error)
    }
  };

  if (queryObtenerUsuarios.data) {
    try {
      usuarios = queryObtenerUsuarios.data.obtenerUsuarios;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Fragment>
      <SnackBarMessages alert={alert} setAlert={setAlert} />

      <Button startIcon={<Add />} onClick={handleClickOpen}>
        Realizar movimiento
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <BackdropComponent loading={loading} setLoading={setLoading} />
          <Box textAlign={"center"} p={1}>
            <Typography variant="h5">Movimiento en caja principal</Typography>
          </Box>
          <div className={classes.formInputFlex}>
            <Box width="100%">
              <Typography>Usuario</Typography>
              <TextField
                fullWidth
                size="small"
                name="usuario"
                variant="outlined"
                disabled={true}
                value={sesion?.nombre}
              />
            </Box>
            <Box width="100%">
              <Typography>Fecha y hora</Typography>
              <TextField
                fullWidth
                disabled={true}
                size="small"
                value={moment()
                  .tz("America/Mexico_City")
                  .format("D MMMM YYYY, h:mm:ss")}
                name="fecha_movimiento"
                variant="outlined"
              />
            </Box>
          </div>
          <div className={classes.formInputFlex}>
            <Box width="50%">
              <Typography>Movimiento a Realizar</Typography>
              <Select
                className={classes.formComboBox}
                size="small"
                error={
                  alert.status === "error" && !datosMovimiento.tipo_movimiento
                    ? true
                    : false
                }
                variant="outlined"
                name="tipo_movimiento"
                onChange={onChangeDatos}
                fullWidth
                value={datosMovimiento.tipo_movimiento}
              >
                <MenuItem value="DEPOSITO">Depósito</MenuItem>
                <MenuItem value="RETIRO">Retiro</MenuItem>
              </Select>
            </Box>
            <Box width="50%">
              <Typography>Cantidad</Typography>
              <TextField
                fullWidth
                error={
                  (alert.status === "error" && !datosMovimiento.cantidad) ||
                  (alert.status === "error" && datosMovimiento.cantidad < 1)
                    ? true
                    : false
                }
                onChange={onChangeDatos}
                size="small"
                InputProps={{ type: "number" }}
                name="cantidad"
                variant="outlined"
                value={datosMovimiento.cantidad}
              />
            </Box>
          </div>

          {datosMovimiento.tipo_movimiento ? (
            <div>
              <Box>
                <Typography>Concepto de Movimiento:</Typography>
              </Box>

              <div className={classes.formInputFlex}>
                <Box width="42%" display="flex" alignItems="center">
                  <FormControl className={classes.formControl}>
                    <InputLabel id="label-select-cuenta">Cuenta</InputLabel>
                    <Select
                      id="concepto-cuenta"
                      onChange={obtenerCuenta}
                      name="cuenta"
                      labelId="label-select-cuenta"
                      label="Cuenta"
                      value={
                        cuentaConcepto.cuenta.cuenta
                          ? cuentaConcepto.cuenta.cuenta
                          : ""
                      }
                    >
                      <MenuItem value="">
                        <em>Selecciona uno</em>
                      </MenuItem>
                      {cuentas.map((cuenta, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={cuenta.cuenta}
                            cuenta={cuenta}
                          >
                            {cuenta.cuenta}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Box mt={2}>
                    <CrearNuevaCuenta refetch={refetch} />
                  </Box>
                </Box>
                <Box width="42%" display="flex" alignItems="center">
                  <FormControl
                    style={{ marginLeft: "10%" }}
                    className={classes.formControl}
                  >
                    <InputLabel id="label-select-subcuenta">
                      Subcuenta
                    </InputLabel>
                    <Select
                      id="concepto-subcuenta"
                      onChange={obtenerSubcuenta}
                      name="subCuenta"
                      labelId="label-select-subcuenta"
                      label="Subcuenta"
                      value={
                        cuentaConcepto.subCuenta.subcuenta
                          ? cuentaConcepto.subCuenta.subcuenta
                          : ""
                      }
                    >
                      <MenuItem value="">
                        <em>Selecciona uno</em>
                      </MenuItem>
                      {cuentaConcepto.subcuentas.map((subcuenta, index) => {
                        return (
                          <MenuItem
                            key={index}
                            value={subcuenta.subcuenta}
                            subCuenta={subcuenta}
                          >
                            {subcuenta.subcuenta}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <Box mt={2}>
                    <CrearNuevaSubcuenta
                      cuenta={cuentaConcepto}
                      refetch={refetch}
                      setCuenta={setCuentaConcepto}
                      datosMovimiento={datosMovimiento}
                      setDatosMovimiento={setDatosMovimiento}
                    />
                  </Box>
                </Box>
              </div>
              <div className={classes.formInputFlex}>
                {/* <Box style={{width:'50%'}}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="concepto-label">Concepto</InputLabel>
                            <Select
                            labelId="concepto-label"
                            id="concepto-name"
                            value={(concepto) ? concepto : ""}
                            onChange={handleChangeConcepto}
                            input={<Input />}
                            MenuProps={MenuProps}
                            >
                            {conceptos.map((concepto, index) => {
                            
                                return(
                                <MenuItem key={index} value={concepto} >
                                    {concepto}
                                </MenuItem>
                                )})
                            }
                            </Select>
                        </FormControl>
                    </Box> */}

                {datosMovimiento.tipo_movimiento === "DEPOSITO" ? (
                  <Box style={{ width: "50%" }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="origen-label">Origen</InputLabel>
                      <Select
                        labelId="origen-label"
                        id="origen-name"
                        value={origen ? origen : ""}
                        onChange={handleChangeOrigen}
                        input={<Input />}
                        MenuProps={MenuProps}
                      >
                        {cajas.map((caja) => {
                          if (!caja.principal) {
                            return (
                              <MenuItem key={caja._id} value={caja}>
                                Caja{" "}
                                {caja.principal
                                  ? "principal"
                                  : caja.numero_caja}
                              </MenuItem>
                            );
                          } else {
                            return <div />;
                          }
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <Box style={{ width: "50%" }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="destino-label">Destino</InputLabel>
                      <Select
                        labelId="destino-label"
                        id="destino-name"
                        value={destino ? destino : ""}
                        onChange={handleChangeDestino}
                        input={<Input />}
                        MenuProps={MenuProps}
                      >
                        {cajas.map((caja) => {
                          if (!caja.principal) {
                            return (
                              <MenuItem key={caja._id} value={caja}>
                                Caja {caja.numero_caja}
                              </MenuItem>
                            );
                          } else {
                            return <div />;
                          }
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {datosMovimiento.tipo_movimiento === "DEPOSITO" ? (
                  <Box style={{ width: "50%" }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="entrega-label">Quién entrega</InputLabel>
                      <Select
                        labelId="entrega-label"
                        id="entrega-name"
                        value={usuarioEntrega ? usuarioEntrega : ""}
                        onChange={handleChangeUsuarioEntrega}
                        input={<Input />}
                        MenuProps={MenuProps}
                      >
                        {usuarios.map((usuario) => {
                          return (
                            <MenuItem key={usuario._id} value={usuario}>
                              {usuario.nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                ) : (
                  <Box style={{ width: "50%" }}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="entrega-label">Quién recibe</InputLabel>
                      <Select
                        labelId="entrega-label"
                        id="entrega-name"
                        value={usuarioRecibe ? usuarioRecibe : ""}
                        onChange={handleChangeUsuarioRecibe}
                        input={<Input />}
                        MenuProps={MenuProps}
                      >
                        {usuarios.map((usuario) => {
                          return (
                            <MenuItem key={usuario._id} value={usuario}>
                              {usuario.nombre}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Box>
                )}
              </div>

              <div className={classes.formInputFlex}>
                <Box style={{ width: "46%" }}>
                  <Typography>
                    <b>Fecha Movimiento :</b>
                  </Typography>

                  <Box display="flex">
                    <TextField
                      fullWidth
                      error={
                        alert.status === "error" &&
                        fechaMovimientoEntrega === ""
                          ? true
                          : false
                      }
                      type="date"
                      onChange={handleChangeFechaMovimiento}
                      size="small"
                      name="fecha_movimiento"
                      variant="outlined"
                      value={fechaMovimientoEntrega}
                    />
                  </Box>
                </Box>
              </div>
            </div>
          ) : (
            <div />
          )}

          <div className={classes.formInputFlex}>
            <Box width="100%">
              <Typography>Comentarios</Typography>
              <TextField
                className={classes.input}
                fullWidth
                onChange={onChangeDatos}
                size="small"
                multiline
                rows={2}
                name="comentarios"
                variant="outlined"
                value={datosMovimiento.comentarios}
              />
            </Box>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleClickOpen}
            size="large"
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={enviarDatos}
            size="large"
            disabled={!isOnline}
          >
            Realizar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
