import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ArrowBack, Close, RemoveCircle } from "@material-ui/icons";

import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import GetCFDIRelated from "./GetCFDIRelated";
import { CircularProgress } from "@material-ui/core";
import { CANCELAR_FACTURA } from "../../../../../gql/Facturacion/Facturacion";
import SnackBarMessages from "../../../../../components/SnackBarMessages";
import { useMutation } from "@apollo/client";

const statusCanceled = ["canceled", "acepted", "expired"];
const motivosCancelacion = [
  { code: "01", description: "Comprobante emitido con errores con relación" },
  { code: "02", description: "Comprobante emitido con errores sin relación" },
  { code: "03", description: "No se llevó a cabo la operación" },
  {
    code: "04",
    description: "Operación nominativa relacionada con una factura global",
  },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CancelarCFDI({ cfdi, refetch }) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [status, setStatus] = React.useState(
    statusCanceled.some((state) => cfdi.status === state)
  );
  const complement = cfdi.complementos.length > 0 ? true : false;
  const [canceledData, setCanceledData] = React.useState({
    cfdiId: "",
    motive: "",
    uuidReplacement: "",
  });
  const [alert, setAlert] = React.useState({
    message: "",
    status: "",
    open: false,
  });
  const [cancelarCfdi] = useMutation(CANCELAR_FACTURA);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setCanceledData({
      cfdiId: "",
      motive: "",
      uuidReplacement: "",
    });
  };

  const obtenerMotivo = (motive) => {
    let data = {
      ...canceledData,
      motive,
    };
    if (motive !== motivosCancelacion[0].code) data.uuidReplacement = "";
    setCanceledData(data);
  };

  const handleDeleteRelated = () => {
    setCanceledData({
      ...canceledData,
      uuidReplacement: "",
    });
  };

  const handleCancelarCfdi = async () => {
    try {
      const input = {
        ...canceledData,
        cfdiId: cfdi.id_cfdi,
        idFactura: cfdi._id,
      };
      if (!canceledData.motive) return;
      if (
        canceledData.motive === motivosCancelacion[0].code &&
        !canceledData.uuidReplacement
      ) {
        return;
      }
      setLoading(true);
      const res = await cancelarCfdi({
        variables: {
          input,
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
        },
      });
      setLoading(false);
      setStatus(true);
      setAlert({
        message: res.data.cancelarCfdi.message,
        status: "success",
        open: true,
      });
      refetch();
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlert({
        message: error.message,
        status: "error",
        open: true,
      });
    }
  };

  const show =
    canceledData.motive === motivosCancelacion[0].code ? false : true;

  return (
    <div>
      <Button
        onClick={() => handleOpen()}
        size="small"
        color="secondary"
        disabled={status || complement}
        startIcon={<RemoveCircle fontSize="small" />}
      >
        Cancelar CFDI
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <SnackBarMessages alert={alert} setAlert={setAlert} />
        <DialogTitle component="div">
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Cancelación de CFDI</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box width={300} mb={1}>
            <Typography color="textSecondary">Motivo cancelación</Typography>
            <FormControl
              variant="outlined"
              size="small"
              name="motive"
              fullWidth
            >
              <Select
                value={canceledData.motive}
                name="motive"
                onChange={(e) => obtenerMotivo(e.target.value)}
              >
                <MenuItem value="">
                  <em>Selecciona uno</em>
                </MenuItem>
                {motivosCancelacion.map((motivo, index) => (
                  <MenuItem
                    key={index}
                    value={motivo.code}
                  >{`${motivo.code} - ${motivo.description}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box width={300} mb={1} hidden={show}>
            <Typography color="textSecondary">
              Remplazar por nuevo CFDI relacionado
            </Typography>
            <TextField
              value={canceledData.uuidReplacement}
              size="small"
              variant="outlined"
              fullWidth
              name="uuidReplacement"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        display: canceledData.uuidReplacement ? "flex" : "none",
                      }}
                      size="small"
                      onClick={handleDeleteRelated}
                    >
                      <Close />
                    </IconButton>
                    <GetCFDIRelated
                      setCanceledData={setCanceledData}
                      setAlert={setAlert}
                    />
                  </InputAdornment>
                ),
                readOnly: true,
                disabled: true,
              }}
            />
          </Box>
          <Box mb={5} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => handleCancelarCfdi()}
              disabled={loading}
              startIcon={
                loading ? <CircularProgress size={20} color="inherit" /> : null
              }
            >
              Cancelar CFDI
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
