import { Impresora } from "../../../../components/Perifericos/TicketPrinter/Impresora";
import { formatoMexico } from "../../../../config/reuserFunctions";
import table from "text-table";
import moment from "moment-timezone";
const RUTA_API = "http://localhost:8000";

export const imprimirTicketCorte = async (
  sesion,
  corte,
  montos,
  ventas,
  fechaInicio,
  fechaFin,
  productos
) => {
  const {
    monto_efectivo,
    monto_tarjeta_debito,
    monto_tarjeta_credito,
    monto_transferencia,
    monto_monedero,
    monto_creditos,
    monto_cheques,
    monto_vales_despensa,
  } = corte.montos_en_caja;

  const {
    movimientos_efectivo,
    movimientos_gastos,
    movimientos_ingresos,
  } = montos;

  const gastos_tarjetas =
    movimientos_gastos.monto_tarjeta_debito +
    movimientos_gastos.monto_tarjeta_credito;
  const ingresos_tarjetas =
    movimientos_ingresos.monto_tarjeta_debito +
    movimientos_ingresos.monto_tarjeta_credito;
  const totales_tarjetas =
    movimientos_efectivo.monto_tarjeta_debito +
    movimientos_efectivo.monto_tarjeta_credito;
  const corte_tarjetas =
    monto_tarjeta_debito.monto + monto_tarjeta_credito.monto;

  //GASTOS E INGRESOS
  let tableHeadEfectivo = table([["Concepto", "     ", "Gastos", "Ingresos"]]);
  var tableBodyEfectivo = table([
    ["SALDO INCIAL ", `$0`, `$${formatoMexico(montos.monto_inicial)}`],
    [
      "EFECTIVO ",
      `$${formatoMexico(movimientos_gastos.monto_efectivo)}`,
      `$${formatoMexico(movimientos_ingresos.monto_efectivo)}`,
    ],
    [
      "TARJETAS ",
      `$${formatoMexico(gastos_tarjetas)}`,
      `$${formatoMexico(ingresos_tarjetas)}`,
    ],
    [
      "TRANSFERENCIAS ",
      `$${formatoMexico(movimientos_gastos.monto_transferencia)}`,
      `$${formatoMexico(movimientos_ingresos.monto_transferencia)}`,
    ],
    [
      "MONEDERO ",
      `$${formatoMexico(movimientos_gastos.monto_monedero)}`,
      `$${formatoMexico(movimientos_ingresos.monto_monedero)}`,
    ],
    [
      "CREDITOS ",
      `$${formatoMexico(movimientos_gastos.monto_creditos)}`,
      `$${formatoMexico(movimientos_ingresos.monto_creditos)}`,
    ],
    [
      "CHEQUES ",
      `$${formatoMexico(movimientos_gastos.monto_cheques)}`,
      `$${formatoMexico(movimientos_ingresos.monto_cheques)}`,
    ],
    [
      "VALES DESPENSA ",
      `$${formatoMexico(movimientos_gastos.monto_vales_despensa)}`,
      `$${formatoMexico(movimientos_ingresos.monto_vales_despensa)}`,
    ],
  ]);
  //TABLA MONTOS SISTEMA
  let tableHeadSistema = table([["Concepto", "     ", "Monto"]]);
  var tableBodySistema = table([
    [
      "EFECTIVO TOTAL",
      `$${formatoMexico(movimientos_efectivo.monto_efectivo)}`,
    ],
    ["TARJETAS", `$${formatoMexico(totales_tarjetas)}`],
    [
      "TRANSFERENCIAS ",
      `$${formatoMexico(movimientos_efectivo.monto_transferencia)}`,
    ],
    ["MONEDERO ", `$${formatoMexico(movimientos_efectivo.monto_monedero)}`],
    ["CREDITOS ", `$${formatoMexico(movimientos_efectivo.monto_creditos)}`],
    ["CHEQUES ", `$${formatoMexico(movimientos_efectivo.monto_cheques)}`],
    [
      "VALES DESPENSA ",
      `$${formatoMexico(movimientos_efectivo.monto_vales_despensa)} `,
    ],
  ]);

  //TABLA DECLARADO
  let tableHeadDeclarado = table([
    ["Concepto", "     ", "Declarado", "Diferencia"],
  ]);
  var tableBodyDeclarado = table([
    { align: ["l", "r"] },
    [
      "EFECTIVO TOTAL ",
      `$${formatoMexico(monto_efectivo.monto)}`,
      `$${formatoMexico(
        monto_efectivo.monto - movimientos_efectivo.monto_efectivo
      )}`,
    ],
    [
      "TARJETAS ",
      `$${formatoMexico(corte_tarjetas)}`,
      `$${formatoMexico(corte_tarjetas - totales_tarjetas)}`,
    ],
    [
      "TRANSFERENCIAS ",
      `$${formatoMexico(monto_transferencia.monto)}`,
      ` $${formatoMexico(
        monto_transferencia.monto - movimientos_efectivo.monto_transferencia
      )}`,
    ],
    [
      "MONEDERO ",
      `$${formatoMexico(monto_monedero.monto)}`,
      `$${formatoMexico(
        monto_monedero.monto - movimientos_efectivo.monto_monedero
      )}`,
    ],
    [
      "CREDITOS ",
      `$${formatoMexico(monto_creditos.monto)}`,
      `$${formatoMexico(
        monto_creditos.monto - movimientos_efectivo.monto_creditos
      )}`,
    ],
    [
      "CHEQUES ",
      `$${formatoMexico(monto_cheques.monto)}`,
      `$${formatoMexico(
        monto_cheques.monto - movimientos_efectivo.monto_cheques
      )}`,
    ],
    [
      "VALES DESPENSA ",
      `$${formatoMexico(monto_vales_despensa.monto)}`,
      `$${formatoMexico(
        monto_vales_despensa.monto - movimientos_efectivo.monto_vales_despensa
      )}`,
    ],
  ]);

  let tableHeadProductos = table([
    { align: ["l", "r", "r"] },
    ["Producto                  ", "Cantidad", "Total"],
  ]);
  let tableBodyProductos = [{ align: ["l", "r", "r"] }];

  productos.forEach((res) => {
    const { producto, cantidad, total } = res;
    tableBodyProductos.push([
      `${producto.nombre}`,
      `${cantidad}`,
      `${formatoMexico(total)}`,
    ]);
  });

  tableBodyProductos = table(tableBodyProductos);

  try {
    const { direccion } = sesion.sucursal;
    const address = `${direccion.calle} #${direccion.no_ext}, Col. ${direccion.colonia}`;
    const location = `${direccion.municipio}, ${direccion.estado}, ${direccion.pais}`;

    let impresora = new Impresora(RUTA_API);
    impresora.cut();
    impresora.setFontSize(1, 1);
    impresora.setEmphasize(0);
    impresora.setAlign("center");
    impresora.write("Punto de venta CAFI\n");
    impresora.feed(1);
    impresora.write(`${sesion.empresa.nombre_empresa}\n`);
    impresora.write(`Sucursal: ${corte.sucursal.nombre_sucursal}\n`);
    impresora.write(`RFC: ${sesion.empresa.rfc}\n`);
    impresora.write(`${address}\n`);
    impresora.write(`${location}\n`);
    impresora.feed(1);
    impresora.setFontSize(2, 1);
    impresora.write("CORTE CAJA\n");
    impresora.setFontSize(1, 1);
    impresora.write(
      `Del ${moment(fechaInicio).tz("America/Mexico_City").format("lll")}\n`
    );
    impresora.write(
      `Al ${moment(fechaFin).tz("America/Mexico_City").format("lll")}\n`
    );
    impresora.feed(1);
    impresora.write(`Usuario: ${corte.usuario_en_turno.nombre}\n`);
    impresora.write(
      `No. usuario: ${corte.usuario_en_turno.numero_usuario} Caja: ${corte.numero_caja}\n`
    );
    impresora.write(`Turno: ${corte.horario_en_turno}\n`);
    impresora.feed(1);
    impresora.setFontSize(2, 1);
    impresora.write("GASTOS E INGRESOS\n"); //gastos en ingresos
    impresora.setFontSize(1, 1);
    impresora.setAlign("left");
    impresora.write(`${tableHeadEfectivo}\n`);
    impresora.write("________________________________________________\n");
    impresora.write(`${tableBodyEfectivo}\n`);
    impresora.feed(1);
    impresora.setFontSize(2, 1);
    impresora.setAlign("center");
    impresora.write("MONTOS EN SISTEMA\n"); //montos en CAJA/
    impresora.setFontSize(1, 1);
    impresora.setAlign("left");
    impresora.write(`${tableHeadSistema}\n`);
    impresora.write("________________________________________________\n");
    impresora.write(`${tableBodySistema}\n`);
    impresora.feed(1);
    impresora.setFontSize(2, 1);
    impresora.setAlign("center");
    impresora.write("VENTAS\n");
    impresora.setFontSize(1, 1);
    impresora.setAlign("left");
    impresora.write("________________________________________________\n");
    impresora.write(`SUBTOTAL: $${formatoMexico(ventas.subtotal_ventas)}\n`);
    impresora.write(`IMPUESTOS: $${formatoMexico(ventas.impuestos)}\n`);
    impresora.write(`DESCUENTOS: $${formatoMexico(ventas.total_descuentos)}\n`);
    impresora.write(`TOTAL: $${formatoMexico(ventas.total_ventas)}\n`);
    impresora.feed(1);
    impresora.write(`VENTAS A CONTADO: ${ventas.ventas_contado}\n`);
    impresora.write(`VENTAS A CREDITO: ${ventas.ventas_creditos}\n`);
    impresora.write(`VENTAS CON DESC.: ${ventas.ventas_descuentos}\n`);
    impresora.write(`VENTAS CANCELADAS: ${ventas.ventas_canceladas}\n`);
    impresora.write(`TOTAL DE VENTAS: ${ventas.cantidad_total_ventas}\n`);
    impresora.feed(1);
    if (productos.length) {
      impresora.write("Lista Productos Vendidos\n");
      impresora.setAlign("left");
      impresora.write(`${tableHeadProductos}\n`);
      impresora.write(`${tableBodyProductos}\n`);
      impresora.feed(1);
    }
    impresora.setFontSize(2, 1);
    impresora.setAlign("center");
    impresora.write("DECLARADO EN CAJA\n");
    impresora.setFontSize(1, 1);
    impresora.setAlign("left");
    impresora.write(`${tableHeadDeclarado}\n`);
    impresora.write("________________________________________________\n");
    impresora.write(`${tableBodyDeclarado}\n`);
    impresora.feed(1);
    impresora.setAlign("center");
    impresora.write("Firma del Cajero \n");
    impresora.feed(2);
    impresora.write("__________________________\n");
    impresora.feed(1);
    impresora.write("Firma del Responsable \n");
    impresora.feed(2);
    impresora.write("__________________________\n");
    impresora.feed(3);
    impresora.cut();
    impresora.cutPartial(); // Pongo este y también cut porque en ocasiones no funciona con cut, solo con cutPartial

    await impresora.end();
    return { succes: true, error: false, message: "OK" };
  } catch (error) {
    console.log(error);
    return { succes: false, error: true, message: error };
  }
};
