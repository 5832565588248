import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Add } from "@material-ui/icons";
import { useDropzone } from "react-dropzone";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { TiendaCtx } from "../../../../../../context/Empresa/tiendaCtx";
import { CREAR_BANNER } from "../../../../../../gql/Empresa/tienda";
import { useMutation } from "@apollo/client";
import { AccesosContext } from "../../../../../../context/Accesos/accesosCtx";

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 200,
    border: "dashed 2px #000000",
    textAlign: "center",
  },
  img: {
    width: "100%",
    height: "100%",
  },
}));

export default function CrearBanner() {
  const classes = useStyles();
  const { isOnline } = React.useContext(AccesosContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [banner, setBanner] = React.useState(null);
  const [preview, setPreview] = React.useState("");
  const { setAlert, tienda } = React.useContext(TiendaCtx);
  const [crearBanner] = useMutation(CREAR_BANNER);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBanner(null);
    setPreview("");
  };

  //dropzone
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function () {
        let image = reader.result;
        setPreview(image);
      };
      setBanner(acceptedFiles[0]);
    },
    [banner, setBanner]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop,
  });

  const handleSaveBanner = async () => {
    try {
      setLoading(true);
      const resp = await crearBanner({
        variables: {
          input: { imagen: banner },
          tienda: tienda.data._id,
        },
      });
      setLoading(false);
      tienda.refetch();
      setAlert({
        message: resp.data.crearBanner.message,
        status: "success",
        open: true,
      });
      handleClose();
    } catch (error) {
      console.log(error);
      if (error.networkError) console.log(error.networkError.result);
      setLoading(false);
      setAlert({ message: error.message, status: "error", open: true });
    }
  };

  return (
    <div>
      <Button disabled={!isOnline} color="primary" onClick={handleClickOpen} startIcon={<Add />}>
        Nuevo
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialg-crear-banner-main"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="dialg-crear-banner-main">
          {"Crear nuevo banner principal"}
        </DialogTitle>
        <DialogContent>
          <Box className={classes.imgContainer} {...getRootProps()}>
            <input {...getInputProps()} />
            {preview ? (
              <img
                alt="banner principal"
                className={classes.img}
                src={`${preview}`}
              />
            ) : isDragActive ? (
              <p>Suelta tu imagen aqui ...</p>
            ) : (
              <p>
                Arrastra y suelta tu imagen aquí, o haz clic para seleccionar
                alguna
              </p>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : <Add />
            }
            disabled={loading || !isOnline}
            onClick={handleSaveBanner}
            color="primary"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
