import React from "react";
import { Container, Grid, Box } from "@material-ui/core";
import RegistroAlmacen from "./RegistroAlmacen/RegistroAlmacen";
import Traspasos from "./Traspasos";
//import InventariosPorAlmacen from "./InventarioPorAlmacenOLD/InventariosPorAlmacen";
//import { TraspasosProvider } from "../../../context/Almacenes/traspasosAlmacen";
import InvetariosAlmacen from "./InventariosAlmacen";

export default function Almacenes() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  return (
    <Container maxWidth="md">
      <Grid container spacing={3} justifyContent="center">
        {sesion.accesos.almacenes.almacen.ver === false ? null : (
          <Grid item md={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <RegistroAlmacen />
            </Box>
          </Grid>
        )}
        {/* {sesion.accesos.almacenes.traspasos.ver === false ? null : (
          <Grid item md={3}>
            <Box display="flex" justifyContent="center">
              <TraspasosProvider>
                <TraspasosOLD
                  productosAlmacenQuery={productosAlmacenQuery}
                  page={page}
                  setPage={setPage}
                  limit={limit}
                  pageAlm={pageAlm}
                  setPageAlm={setPageAlm}
                />
              </TraspasosProvider>
            </Box>
          </Grid>
        )} */}
        {sesion.accesos.almacenes.traspasos.ver === false ? null : (
          <Grid item md={3}>
            <Box display="flex" justifyContent="center">
              <Traspasos />
            </Box>
          </Grid>
        )}
        {/* {sesion.accesos.almacenes.inventario_almacen.ver === false ? null : (
          <Grid item md={3}>
            <Box display="flex" justifyContent="center">
              <TraspasosProvider>
                <InventariosPorAlmacen />
              </TraspasosProvider>
            </Box>
          </Grid>
        )} */}
        {sesion.accesos.almacenes.inventario_almacen.ver === false ? null : (
          <Grid item md={3}>
            <InvetariosAlmacen />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
