import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx.js";
import { Grid, makeStyles } from "@material-ui/core";
import CfdiRelacionado from "./CfdiRelated/index.jsx";

const periodicidadArray = [
  { code: "01", description: "Diario" },
  { code: "02", description: "Semanal" },
  { code: "03", description: "Quincenal" },
  { code: "04", description: "Mensual" },
  { code: "05", description: "Bimestral" },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(1),
    height: "100%",
  },
  title: {
    position: "absolute",
    top: -30,
    left: -18,
    backgroundColor: "white",
    padding: "0 8px",
  },
}));

export default function OtrosDatosCfdi({ setAlert }) {
  const {
    datosFactura,
    setDatosFactura,
    error_validation,
    isPublicoGeneral,
    isCfdiRelated,
    setIsCfdiRelated,
    setIsPublicoGeneral,
  } = React.useContext(FacturacionCtx);
  const classes = useStyles();

  const obtenerDatos = (e) => {
    const { name, value } = e.target;
    setDatosFactura({
      ...datosFactura,
      [name]: value,
    });
  };

  const publicoGeneral = (e) => {
    const { checked } = e.target;
    setIsPublicoGeneral(checked);
    if (checked) {
      setDatosFactura({
        ...datosFactura,
        receiver: {
          ...datosFactura.receiver,
          Rfc: "XAXX010101000",
          CfdiUse: "S01",
          Name: "PUBLICO EN GENERAL",
          FiscalRegime: "616",
        },
      });
    } else {
      setDatosFactura({
        ...datosFactura,
        receiver: {
          ...datosFactura.receiver,
          Rfc: "",
          CfdiUse: "",
          Name: "",
          FiscalRegime: "",
          TaxZipCode: "",
        },
      });
    }
  };

  const handleCfdiRelated = (e) => {
    const { checked } = e.target;
    setIsCfdiRelated(checked);
    if (checked) {
      setDatosFactura({
        ...datosFactura,
        Relations: {
          Type: "",
          Cfdis: [{ Uuid: "" }],
        },
      });
    } else {
      const { Relations, ...input } = datosFactura;
      setDatosFactura(input);
    }
  };

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isPublicoGeneral}
                onChange={publicoGeneral}
                color="primary"
              />
            }
            label="Público General"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isCfdiRelated}
                onChange={handleCfdiRelated}
                color="primary"
              />
            }
            label="CFDI Relacionado"
          />
        </Grid>
        <Grid item md={6}>
          {isPublicoGeneral ? (
            <FormControl
              variant="outlined"
              fullWidth
              size="small"
              name="Periodicity-form-control"
              error={
                error_validation.status &&
                isPublicoGeneral &&
                datosFactura.periodicity === ""
              }
            >
              <Select
                labelId="periodicity"
                value={datosFactura.periodicity}
                name="periodicity"
                onChange={obtenerDatos}
                style={{
                  paddingLeft: 4,
                  height: 32,
                }}
                startAdornment={
                  <InputAdornment position="start">Periodicidad</InputAdornment>
                }
              >
                <MenuItem value="">
                  <em>Selecciona uno</em>
                </MenuItem>
                {periodicidadArray.map((per) => (
                  <MenuItem
                    key={per.code}
                    value={per.code}
                  >{`${per.code} - ${per.description}`}</MenuItem>
                ))}
              </Select>
              <FormHelperText>
                {error_validation.status &&
                isPublicoGeneral &&
                datosFactura.periodicity === ""
                  ? error_validation.message
                  : ""}
              </FormHelperText>
            </FormControl>
          ) : null}
          {isCfdiRelated ? <CfdiRelacionado setAlert={setAlert} /> : null}
        </Grid>
      </Grid>
    </Paper>
  );
}
