import React, { Fragment, useContext, useEffect } from "react";
import {
  Box,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { RegProductoContext } from "../../../../../context/Catalogos/CtxRegProducto";
import { useState } from "react";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  precioTitle: {
    width: theme.spacing(20),
  },
  marginInput: {
    marginTop: 19,
  },
  titulos: {
    fontWeight: 500,
  },
}));

export default function PreciosVenta() {
  const { preciosP, datos_generales } = useContext(RegProductoContext);
  let newPreciosP = [...preciosP];

  return (
    <Fragment>
      <Box my={2}>
        <Typography>
          <b>Precios de venta</b>
        </Typography>
        <Divider />
      </Box>
      {datos_generales.tipo_producto &&
      datos_generales.tipo_producto !== "OTROS" ? (
        <Box my={1}>
          <Alert severity="info">
            Tu producto es de tipo <b>{datos_generales.tipo_producto}</b>, en
            este caso tendras <b>Unidades de Medida</b>, que serán tus unidades
            de venta, las encontraras en el ultimo menu como{" "}
            <b>"TALLAS Y COLORES"</b>, si estas registrando el acticulo tendras
            que guardar y editar tu articulo para encontrar este menu.
            <p>
              En la sección <b>"TALLAS Y COLORES"</b> podrás agregar tus{" "}
              <b>medidas, colores y precios</b>.
            </p>
          </Alert>
        </Box>
      ) : null}
      <Box display="flex">
        {preciosP.map((res, index) => (
          <RenderPreciosP
            key={index}
            data={res}
            index={index}
            newPreciosP={newPreciosP}
          />
        ))}
      </Box>
    </Fragment>
  );
}

const RenderPreciosP = ({ data, index, newPreciosP }) => {
  const classes = useStyles();
  const {
    preciosP,
    precios,
    unidadVentaXDefecto,
    setUnidadVentaXDefecto,
    setPreciosP,
    unidadVentaSecundaria,
    setUnidadVentaSecundaria,
  } = useContext(RegProductoContext);
  let preciosVenta = { ...preciosP[index] };
  const [error, setError] = useState(false);

  const PCU = precios.unidad_de_compra.precio_unitario_con_impuesto;
  const CANT = precios.unidad_de_compra.cantidad;
  const IVA = precios.iva;
  const IEPS = precios.ieps;

  const obtenerUtilidad = (valor_base) => {
    if (!valor_base && valor_base !== 0) {
      preciosVenta.utilidad = "";
      newPreciosP.splice(index, 1, preciosVenta);
      setPreciosP(newPreciosP);
      return;
    }

    let { descuento, descuento_activo } = unidadVentaXDefecto;
    let cantidad_sec = unidadVentaSecundaria.cantidad;
    let descuento_sec = unidadVentaSecundaria.descuento;
    let descuento_activo_sec = unidadVentaSecundaria.descuento_activo;
    let utilidad = valor_base / 100;

    //PCU = precio_unitario_con_impuestos
    //precio venta y neto con utilidad
    const PRECIO_VENTA = parseFloat(PCU * utilidad + PCU);
    //calculo ieps
    let ieps_precio = PRECIO_VENTA * parseFloat(IEPS/100); //PCU y no precio_venta porque PRECIO_VENTA ya tiene utilidad en este punto
    //calculo iva
    let iva_precio =
      (PRECIO_VENTA + ieps_precio) * parseFloat(IVA/100);
    const PRECIO_NETO = PRECIO_VENTA + iva_precio + ieps_precio;

    preciosVenta.utilidad = parseFloat(valor_base);
    preciosVenta.precio_venta = parseFloat(PRECIO_VENTA.toFixed(2));
    preciosVenta.iva_precio = parseFloat(iva_precio.toFixed(2));
    preciosVenta.ieps_precio = parseFloat(ieps_precio.toFixed(2));

    //PCU => precio_unitario_con_impuesto;
    if (PRECIO_NETO < PCU && PRECIO_NETO !== 0) {
      setError(true);
    } else {
      setError(false);
    }
    preciosVenta.precio_neto = parseFloat(PRECIO_NETO.toFixed(2));

    //meter los precios a unidadXdefecto
    let unidadXDefecto = {
      ...unidadVentaXDefecto,
      precio: PRECIO_NETO,
      precio_unidad: {
        numero_precio: preciosVenta.numero_precio,
        precio_neto: parseFloat(PRECIO_NETO.toFixed(2)),
        precio_venta: parseFloat(PRECIO_VENTA.toFixed(2)),
        unidad_mayoreo: preciosVenta.unidad_mayoreo,
        iva_precio: parseFloat(iva_precio.toFixed(2)),
        ieps_precio: parseFloat(ieps_precio.toFixed(2)),
        utilidad: preciosVenta.utilidad,
        unidad_maxima: false,
      },
    };
    let unidadSecundaria = {
      ...unidadVentaSecundaria,
      precio: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
      precio_unidad: {
        numero_precio: preciosVenta.numero_precio,
        precio_neto: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
        precio_venta: parseFloat((cantidad_sec * PRECIO_VENTA).toFixed(2)),
        unidad_mayoreo: preciosVenta.unidad_mayoreo,
        iva_precio: parseFloat((cantidad_sec * iva_precio).toFixed(2)),
        ieps_precio: parseFloat((cantidad_sec * ieps_precio).toFixed(2)),
        utilidad: preciosVenta.utilidad,
        unidad_maxima: false,
      },
    };

    if (preciosVenta.numero_precio === 1) {
      if (descuento_activo === true) {
        let PVD = (PRECIO_NETO * descuento.porciento) / 100; //precio_venta_descuento
        //calculo ieps
        const ieps_precio =
          PVD * parseFloat(`0.${IEPS < 10 ? `0${IEPS}` : IEPS}`);
        //calculo iva
        const iva_precio =
          (PVD + ieps_precio) * parseFloat(`0.${IVA < 10 ? `0${IVA}` : IVA}`);

        const PND = PVD + iva_precio + ieps_precio; //precio neto con descuento

        unidadXDefecto = {
          ...unidadXDefecto,
          precio: parseFloat(PRECIO_NETO.toFixed(2)),
          descuento: {
            ...unidadVentaXDefecto.descuento,
            precio_neto: PND,
          },
        };
      }
      if (descuento_activo_sec === true) {
        //calcular nuevo precio entre %
        let PVDS = (PRECIO_VENTA * descuento_sec.porciento) / 100; //precio_venta_descuento_secundario (unidaddeventa secundaria)
        const iva_precio_sec =
          PVDS * parseFloat(`0.${IVA < 10 ? `0${IVA}` : IVA}`);
        const ieps_precio_sec =
          PVDS * parseFloat(`0.${IEPS < 10 ? `0${IEPS}` : IEPS}`);

        let PNDS = PVDS + iva_precio_sec + ieps_precio_sec; //precio neto descuento secundario

        unidadSecundaria = {
          ...unidadSecundaria,
          precio: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
          descuento: {
            ...unidadVentaSecundaria.descuento,
            precio_neto: parseFloat((cantidad_sec * PNDS).toFixed(2)),
          },
        };
      }
      setUnidadVentaXDefecto(unidadXDefecto);
      setUnidadVentaSecundaria(unidadSecundaria);
    }

    newPreciosP.splice(index, 1, preciosVenta);
    setPreciosP(newPreciosP);
  };

  const obtenerPrecioNeto = (value) => {
    if (!value) {
      /* console.log(value); */
      preciosVenta.precio_neto = "";
      newPreciosP.splice(index, 1, preciosVenta);
      setPreciosP(newPreciosP);
      return;
    }

    const PRECIO_NETO = parseFloat(value);
    let {
      /* unidad, cantidad,  */ descuento,
      descuento_activo,
    } = unidadVentaXDefecto;
    let cantidad_sec = unidadVentaSecundaria.cantidad;
    let descuento_sec = unidadVentaSecundaria.descuento;
    let descuento_activo_sec = unidadVentaSecundaria.descuento_activo;

    //sacarle impuestos al PRECIO_NETO
    const pv_sin_iva = PRECIO_NETO / (IVA / 100 + 1);
    const pv_sin_ieps = pv_sin_iva / (IEPS / 100 + 1);

    const PRECIO_VENTA = parseFloat(pv_sin_ieps); //este es el precio de venta aun con GANANCIA;

    let ieps_precio = PRECIO_VENTA * parseFloat(IEPS / 100);
    let iva_precio = (PRECIO_VENTA + ieps_precio) * parseFloat(IVA / 100);
    let impuestos = iva_precio + ieps_precio;
    let utilidad_base = ((PRECIO_NETO - impuestos - PCU) / PCU) * 100;

    preciosVenta.precio_neto = parseFloat(PRECIO_NETO.toFixed(2));
    preciosVenta.precio_venta = parseFloat(PRECIO_VENTA.toFixed(2));
    preciosVenta.iva_precio = parseFloat(iva_precio.toFixed(2));
    preciosVenta.ieps_precio = parseFloat(ieps_precio.toFixed(2));
    preciosVenta.utilidad = parseFloat(utilidad_base.toFixed(3));

    //PCU => precio_unitario_con_impuesto;
    if (PRECIO_NETO < PCU && PRECIO_NETO !== 0) {
      setError(true);
    } else {
      setError(false);
    }

    //meter los precios a unidadXdefecto
    let unidadXDefecto = {
      ...unidadVentaXDefecto,
      precio: PRECIO_NETO,
      precio_unidad: {
        numero_precio: preciosVenta.numero_precio,
        precio_neto: parseFloat(PRECIO_NETO.toFixed(2)),
        precio_venta: parseFloat(PRECIO_VENTA.toFixed(2)),
        unidad_mayoreo: preciosVenta.unidad_mayoreo,
        iva_precio: parseFloat(iva_precio.toFixed(2)),
        ieps_precio: parseFloat(ieps_precio.toFixed(2)),
        utilidad: preciosVenta.utilidad,
        unidad_maxima: false,
      },
    };
    let unidadSecundaria = {
      ...unidadVentaSecundaria,
      precio: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
      precio_unidad: {
        numero_precio: preciosVenta.numero_precio,
        precio_neto: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
        precio_venta: parseFloat((cantidad_sec * PRECIO_VENTA).toFixed(2)),
        unidad_mayoreo: preciosVenta.unidad_mayoreo,
        iva_precio: parseFloat((cantidad_sec * iva_precio).toFixed(2)),
        ieps_precio: parseFloat((cantidad_sec * ieps_precio).toFixed(2)),
        utilidad: preciosVenta.utilidad,
        unidad_maxima: false,
      },
    };

    if (preciosVenta.numero_precio === 1) {
      if (descuento_activo === true) {
        let PVD = PRECIO_VENTA - (PRECIO_VENTA * (descuento.porciento / 100));
        //calculo ieps
        const ieps_precio =
          PVD * parseFloat(`0.${IEPS < 10 ? `0${IEPS}` : IEPS}`);
        //calculo iva
        const iva_precio =
          (PVD + ieps_precio) * parseFloat(`0.${IVA < 10 ? `0${IVA}` : IVA}`);
        const PND = PVD + iva_precio + ieps_precio; //precio neto con descuento

        unidadXDefecto = {
          ...unidadXDefecto,
          precio: parseFloat(PRECIO_NETO.toFixed(2)),
          descuento: {
            ...unidadVentaXDefecto.descuento,
            precio_neto: PND,
          },
        };
      }
      if (descuento_activo_sec === true) {
        //calcular nuevo precio entre %
        let PVDS = (PRECIO_VENTA * descuento_sec.porciento) / 100; //precio_venta_descuento_secundario (unidaddeventa secundaria)
        const iva_precio_sec =
          PVDS * parseFloat(`0.${IVA < 10 ? `0${IVA}` : IVA}`);
        const ieps_precio_sec =
          PVDS * parseFloat(`0.${IEPS < 10 ? `0${IEPS}` : IEPS}`);

        let PNDS = PVDS + iva_precio_sec + ieps_precio_sec; //precio neto descuento secundario

        unidadSecundaria = {
          ...unidadSecundaria,
          precio: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
          descuento: {
            ...unidadVentaSecundaria.descuento,
            precio_neto: parseFloat((cantidad_sec * PNDS).toFixed(2)),
          },
        };
      }
      setUnidadVentaXDefecto(unidadXDefecto);
      setUnidadVentaSecundaria(unidadSecundaria);
    }

    newPreciosP.splice(index, 1, preciosVenta);
    setPreciosP(newPreciosP);
  };

  const obtenerMayoreo = (value) => {
    if (!value) {
      preciosVenta.unidad_mayoreo = "";
      newPreciosP.splice(index, 1, preciosVenta);
      setPreciosP(newPreciosP);
      return;
    }
    preciosVenta.unidad_mayoreo = parseInt(value);
    newPreciosP.splice(index, 1, preciosVenta);
    setPreciosP(newPreciosP);
  };

  const calculos = () => {
    //something
    let { descuento, descuento_activo } = unidadVentaXDefecto;
    console.log(descuento)
    let cantidad_sec = unidadVentaSecundaria.cantidad;
    let descuento_sec = unidadVentaSecundaria.descuento;
    let descuento_activo_sec = unidadVentaSecundaria.descuento_activo;
    //before let utilidad_base = preciosVenta.utilidad ? preciosVenta.utilidad : 0;
    //before let utilidad = parseFloat((utilidad_base / 100));
    //let utilidad = parseFloat((preciosVenta.precio_venta - PCU) / PCU);

    const PRECIO_NETO = parseFloat(preciosVenta.precio_neto);
    const pv_sin_iva = PRECIO_NETO / (IVA / 100 + 1);
    const pv_sin_ieps = pv_sin_iva / (IEPS / 100 + 1);
    const PRECIO_VENTA = parseFloat(pv_sin_ieps);
    let ieps_precio = PRECIO_VENTA * parseFloat(IEPS / 100);
    let iva_precio = (PRECIO_VENTA + ieps_precio) * parseFloat(IVA / 100);
    let impuestos = iva_precio + ieps_precio;
    let utilidad_base = ((PRECIO_NETO - impuestos - PCU) / PCU) * 100;

    //PCU = precio_unitario_con_impuestos
    //precio venta y neto con utilidad
    //before const PRECIO_VENTA = parseFloat(PCU * utilidad + PCU);
    //calculo ieps
    //before let ieps_precio = PRECIO_VENTA * (IEPS/100);
    //calculo iva
    //before let iva_precio = (PRECIO_VENTA + ieps_precio) * (IVA/100);
    //before const PRECIO_NETO = PRECIO_VENTA + iva_precio + ieps_precio;

    //meter los valores a preciosVenta
    preciosVenta.precio_venta = parseFloat(PRECIO_VENTA.toFixed(2));
    preciosVenta.precio_neto = parseFloat(PRECIO_NETO.toFixed(2));
    preciosVenta.iva_precio = parseFloat(iva_precio.toFixed(2));
    preciosVenta.ieps_precio = parseFloat(ieps_precio.toFixed(2));
    preciosVenta.utilidad = parseFloat(utilidad_base.toFixed(2));

    //meter los precios a unidadXdefecto
    let unidadXDefecto = {
      ...unidadVentaXDefecto,
      precio: PRECIO_NETO,
      precio_unidad: {
        numero_precio: preciosVenta.numero_precio,
        precio_neto: parseFloat(PRECIO_NETO.toFixed(2)),
        precio_venta: parseFloat(PRECIO_VENTA.toFixed(2)),
        unidad_mayoreo: preciosVenta.unidad_mayoreo,
        iva_precio: parseFloat(iva_precio.toFixed(2)),
        ieps_precio: parseFloat(ieps_precio.toFixed(2)),
        utilidad: preciosVenta.utilidad,
        unidad_maxima: false,
      },
    };

    let unidadSecundaria = {
      ...unidadVentaSecundaria,
      precio: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
      precio_unidad: {
        numero_precio: preciosVenta.numero_precio,
        precio_neto: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
        precio_venta: parseFloat((cantidad_sec * PRECIO_VENTA).toFixed(2)),
        unidad_mayoreo: preciosVenta.unidad_mayoreo,
        iva_precio: parseFloat((cantidad_sec * iva_precio).toFixed(2)),
        ieps_precio: parseFloat((cantidad_sec * ieps_precio).toFixed(2)),
        utilidad: preciosVenta.utilidad,
        unidad_maxima: false,
      },
    };

    if (preciosVenta.numero_precio === 1) {
      if (descuento_activo === true) {
        //Preciocondescuento = Preciooriginal−(Preciooriginal×Porcentajededescuento)
        let PVD = PRECIO_VENTA - (PRECIO_VENTA * (descuento.porciento / 100)); //precio_venta_descuento
        //calculo ieps
        const ieps_precio =
          PVD * parseFloat(`0.${IEPS < 10 ? `0${IEPS}` : IEPS}`);
        //calculo iva
        const iva_precio =
          (PVD + ieps_precio) * parseFloat(`0.${IVA < 10 ? `0${IVA}` : IVA}`);

        const PND = PVD + iva_precio + ieps_precio; //precio neto con descuento

        unidadXDefecto = {
          ...unidadXDefecto,
          precio: parseFloat(PRECIO_NETO.toFixed(2)),
          descuento: {
            ...unidadVentaXDefecto.descuento,
            precio_neto: PND,
          },
        };
      }
      if (descuento_activo_sec === true) {
        //calcular nuevo precio entre %
        let PVDS = (PRECIO_VENTA * descuento_sec.porciento) / 100; //precio_venta_descuento_secundario (unidaddeventa secundaria)
        const iva_precio_sec =
          PVDS * parseFloat(`0.${IVA < 10 ? `0${IVA}` : IVA}`);
        const ieps_precio_sec =
          PVDS * parseFloat(`0.${IEPS < 10 ? `0${IEPS}` : IEPS}`);

        let PNDS = PVDS + iva_precio_sec + ieps_precio_sec; //precio neto descuento secundario

        unidadSecundaria = {
          ...unidadSecundaria,
          precio: parseFloat((cantidad_sec * PRECIO_NETO).toFixed(2)),
          descuento: {
            ...unidadVentaSecundaria.descuento,
            precio_neto: parseFloat((cantidad_sec * PNDS).toFixed(2)),
          },
        };
      }
      setUnidadVentaXDefecto(unidadXDefecto);
      setUnidadVentaSecundaria(unidadSecundaria);
    }

    newPreciosP.splice(index, 1, preciosVenta);
    setPreciosP(newPreciosP);
  };

  useEffect(() => {
    if (preciosVenta.numero_precio === 1 || preciosVenta.precio_neto) {
      calculos();
    }
  }, [PCU, CANT, IVA, IEPS]);

  const verificarCampoVacio = (name, value) => {
    switch (name) {
      case "utilidad":
        if (!value) {
          obtenerUtilidad(0);
          setError(false);
        }
        break;
      case "precio_neto":
        if (!value) {
          obtenerUtilidad(0);
          setError(false);
        }
        break;
      case "unidad_mayoreo":
        if (!value) {
          preciosVenta.unidad_mayoreo = 0;
          newPreciosP.splice(index, 1, preciosVenta);
          setPreciosP(newPreciosP);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Box>
      <Box display="flex" my={1} mr={1}>
        <Box
          alignItems="flex-end"
          display={data.numero_precio > 1 ? "none" : "flex"}
        >
          <Typography className={classes.precioTitle}>
            <b>Utilidad</b>
          </Typography>
        </Box>
        <Box minWidth={130}>
          <Typography className={classes.titulos}>
            Precio {data.numero_precio}
          </Typography>
          <Box mb={2} />
          <TextField
            disabled={!parseFloat(PCU)}
            fullWidth
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
            size="small"
            value={preciosVenta.utilidad}
            name="utilidad"
            variant="outlined"
            onChange={(e) => obtenerUtilidad(e.target.value)}
            onBlur={() =>
              verificarCampoVacio("utilidad", preciosVenta.utilidad)
            }
            error={preciosVenta.utilidad === ""}
          />
        </Box>
      </Box>
      <Box display="flex" my={2} mr={1}>
        <Box
          alignItems="flex-end"
          display={data.numero_precio > 1 ? "none" : "flex"}
        >
          <Box
            alignItems="flex-end"
            flexDirection="column"
            display={data.numero_precio > 1 ? "none" : "flex"}
          >
            <Typography className={classes.precioTitle}>
              <b>Precio de venta</b>
            </Typography>
            <Typography
              className={classes.precioTitle}
              variant="caption"
              color="textSecondary"
            >
              (precio sin impuestos)
            </Typography>
          </Box>
        </Box>
        <Box pl={1}>
          <Typography>
            <b>${parseFloat(preciosVenta.precio_venta.toFixed(2))}</b>
          </Typography>
        </Box>
      </Box>
      <Box display="flex" my={1} mr={1}>
        <Box
          alignItems="flex-end"
          flexDirection="column"
          display={data.numero_precio > 1 ? "none" : "flex"}
        >
          <Typography className={classes.precioTitle}>
            <b>Precio venta neto</b>
          </Typography>
          <Typography
            className={classes.precioTitle}
            variant="caption"
            color="textSecondary"
          >
            (precio con impuestos)
          </Typography>
        </Box>
        <Box className={data.numero_precio > 1 ? classes.marginInput : ""}>
          <TextField
            disabled={!parseFloat(PCU)}
            fullWidth
            type="number"
            InputProps={{
              inputProps: { min: 0 },
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            size="small"
            name="precio_neto"
            variant="outlined"
            value={preciosVenta.precio_neto}
            onChange={(e) => obtenerPrecioNeto(e.target.value)}
            onBlur={() =>
              verificarCampoVacio("precio_neto", preciosVenta.precio_neto)
            }
            error={preciosVenta.precio_neto === "" || error}
            helperText={error ? "Precio menor a costo" : ""}
          />
        </Box>
      </Box>

      <Box display="flex" my={2} mr={1}>
        <Box
          alignItems="flex-end"
          display={data.numero_precio > 1 ? "none" : "flex"}
        >
          <Typography className={classes.precioTitle}>
            <b>Unidad por mayoreo</b>
          </Typography>
        </Box>
        {data.numero_precio > 1 ? (
          <Box>
            <TextField
              disabled={!parseFloat(PCU)}
              fullWidth
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              size="small"
              name="unidad_mayoreo"
              variant="outlined"
              value={preciosVenta.unidad_mayoreo}
              onChange={(e) => obtenerMayoreo(e.target.value)}
              onBlur={() =>
                verificarCampoVacio(
                  "unidad_mayoreo",
                  preciosVenta.unidad_mayoreo
                )
              }
              error={preciosVenta.unidad_mayoreo === ""}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};
