import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { BsCartPlusFill } from "react-icons/bs";
import moment from "moment-timezone";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { ClienteCtx } from "../../../context/Catalogos/crearClienteCtx";
import { CustomButton } from "../../../components/CustomMuiComponents";
import { MainContext } from "../../../context/MainCtx";

const VentaEnEspera = (props) => {
  const {
    updateTablaVentas,
    setUpdateTablaVentas,
    setDatosVentasActual,
    ventaRapida,
  } = React.useContext(VentasContext);
  const { updateClientVenta, setUpdateClientVenta } = React.useContext(
    ClienteCtx
  );
  const { turnoEnCurso } = React.useContext(MainContext);
  const ventaLS = JSON.parse(localStorage.getItem("DatosVentas"));
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const agregarVentaEnEspera = () => {
    let datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));
    let datos = localStorage.getItem("ListaEnEspera");

    if (datosVenta !== null) {
      if (datos === null) {
        localStorage.setItem(
          "ListaEnEspera",
          JSON.stringify([
            {
              datosVenta,
              fecha: moment().tz("America/Mexico_City").format("MM/DD/YYYY"),
            },
          ])
        );
        cleanData();
        handleClickOpen();
      } else {
        let data = JSON.parse(datos);
        data.push({
          datosVenta,
          fecha: moment().tz("America/Mexico_City").format("MM/DD/YYYY"),
        });
        localStorage.setItem("ListaEnEspera", JSON.stringify(data));
        cleanData();
        handleClickOpen();
      }
    }
  };

  const cleanData = () => {
    localStorage.removeItem("DatosVentas");
    localStorage.removeItem("VentaOriginal");
    setUpdateTablaVentas(!updateTablaVentas);
    setUpdateClientVenta(!updateClientVenta);
    setDatosVentasActual({
      subTotal: 0,
      total: 0,
      impuestos: 0,
      iva: 0,
      ieps: 0,
      descuento: 0,
      monedero: 0,
    });
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 69) {
        agregarVentaEnEspera();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open, cleanData]);

  return (
    <React.Fragment>
      {ventaRapida ? (
        <CustomButton
          {...props}
          fullWidth
          variant="outlined"
          className="button-panel-venta b-secondary"
          size="large"
          startIcon={<BsCartPlusFill className="icon-btn-secondary" />}
          onClick={() => agregarVentaEnEspera()}
          disabled={
            !turnoEnCurso || !ventaLS || (ventaLS && ventaLS.nota_credito)
          }
        >
          Espera
        </CustomButton>
      ) : (
        <Button
          {...props}
          onClick={() => agregarVentaEnEspera()}
          className="button-panel-venta b-secondary"
          fullWidth
          variant="outlined"
          disabled={
            !turnoEnCurso || !ventaLS || (ventaLS && ventaLS.nota_credito)
          }
        >
          <Box>
            <BsCartPlusFill className="icon-btn-secondary" />
            <Typography className="text-btn-panel">Anadir espera</Typography>
            <Typography className="text-btn-panel-sec">Alt + E</Typography>
          </Box>
        </Button>
      )}
    </React.Fragment>
  );
};

VentaEnEspera.muiName = Button.muiName;
export default VentaEnEspera;
