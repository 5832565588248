import React, { forwardRef } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { IconButton, makeStyles } from "@material-ui/core";
import {
  FacturacionCtx,
  FacturacionProvider,
} from "../../../../context/Facturacion/facturacionCtx";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import RealizarFactura from "./RealizarFactura";
import { ArrowBack } from "@material-ui/icons";
import FacturaForm from "./FacturaForm";
import { factura_initial_state } from "./initial_factura_states";
import DetallesFactura from "./TablaVenta/TablaDetallesFactura";
import { FcDocument } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: "0px 20px",
    width: "100%",
  },
  title: {
    display: "flex",
    flex: 1,
  },
  icon: {
    fontSize: 100,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NuevaFactura() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth onClick={() => handleClickOpen()}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <FcDocument className={classes.icon} />
          </Box>
          Nuevo CFDI
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={() => handleClose()}
        disableEscapeKeyDown
        TransitionComponent={Transition}
      >
        <FacturacionProvider>
          <DialogFacturaPrincipal handleClose={handleClose} open={open} />
        </FacturacionProvider>
      </Dialog>
    </div>
  );
}

const DialogFacturaPrincipal = ({ handleClose, open }) => {
  const classes = useStyles();
  const { venta_factura } = React.useContext(FacturacionCtx);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [alert, setAlert] = React.useState({
    message: "",
    status: "",
    open: false,
  });

  const handleClickOpen = () => {
    setOpenCancel(true);
  };

  const cancelarCrearCFDI = () => {
    if (venta_factura !== null) {
      handleClickOpen();
    } else {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <DialogTitle component="div" className={classes.appBar}>
        <Box display="flex" alignItems="center" width="100%" gridGap={10}>
          <IconButton onClick={() => cancelarCrearCFDI()} color="primary">
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Nuevo CFDI
          </Typography>
          <RealizarFactura setAlert={setAlert} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <SnackBarMessages alert={alert} setAlert={setAlert} />
        <FacturaForm setAlert={setAlert} open={open} />
        <DetallesFactura />
      </DialogContent>
      <CancelarCrearFactura
        open={openCancel}
        setOpen={setOpenCancel}
        handleCloseFactura={handleClose}
      />
    </React.Fragment>
  );
};

const CancelarCrearFactura = ({ open, setOpen, handleCloseFactura }) => {
  const {
    setDatosFactura,
    setCodigoPostal,
    setProductos,
    setVentaFactura,
    setCPValido,
    setError,
  } = React.useContext(FacturacionCtx);

  const limpiarCampos = () => {
    setDatosFactura(factura_initial_state);
    setVentaFactura(null);
    setProductos([]);
    setCodigoPostal("");
    setCPValido(false);
    setError({ status: false, message: "" });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const cancelar = () => {
    limpiarCampos();
    handleClose();
    handleCloseFactura();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
      >
        <DialogTitle>{"¿Estás seguro de cancelar esta factura?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cerrar</Button>
          <Button onClick={() => cancelar()} color="primary">
            Cancelar Factura
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
