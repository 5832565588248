import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import { OBTENER_CUENTAS } from "../../../../gql/Catalogos/centroCostos";
import {
  CREAR_HISTORIAL_CAJA,
  OBTENER_PRE_CORTE_CAJA,
} from "../../../../gql/Cajas/cajas";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { VentasContext } from "../../../../context/Ventas/ventasContext";
import { Done, ImportExport } from "@material-ui/icons";
import CrearNuevaCuenta from "./crearNuevaCuenta";
import CrearNuevaSubcuenta from "./CrearSubcuenta";
import { ImpDepositosRetiros } from "./ImpDepostosRetiros";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { MainContext } from "../../../../context/MainCtx";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DepositoRetiroCaja() {
  const { turnoEnCurso } = React.useContext(MainContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { isOnline } = useContext(AccesosContext);
  const [CrearHistorialCaja] = useMutation(CREAR_HISTORIAL_CAJA);

  const { setAlert } = useContext(VentasContext);
  const [open, setOpen] = useState(false);
  const [error_data, setError] = useState(false);
  const [cargando, setCargando] = useState(false);
  const [datosMovimiento, setDatosMovimiento] = useState({
    tipo_movimiento: "DEPOSITO",
  });
  const [cuentaConcepto, setCuentaConcepto] = useState({
    cuenta: {},
    subCuenta: {},
    subcuentas: [],
  });
  const [dineroEnCaja, setDineroEnCaja] = React.useState(0);
  const client = useApolloClient();

  const { loading, data, error, refetch } = useQuery(OBTENER_CUENTAS, {
    variables: {
      empresa: sesion.empresa._id,
    },
  });

  const input = {
    horario_en_turno: "ABRIR TURNO",
    id_caja: turnoEnCurso ? turnoEnCurso.id_caja : "",
    id_usuario: sesion._id,
    token_turno_user: turnoEnCurso ? turnoEnCurso.token_turno_user : "",
  };

  const getPrecorte = async () => {
    try {
      const response = await client.query({
        query: OBTENER_PRE_CORTE_CAJA,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          input: input,
        },
        fetchPolicy: "network-only",
      });
      setDineroEnCaja(
        response.data.obtenerPreCorteCaja.monto_efectivo_precorte
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(!open);
    getPrecorte();
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 68) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open]);

  if (!data || loading || error) {
    return (
      <ListItem button disabled>
        <ListItemIcon>
          <ImportExport />
        </ListItemIcon>
        <ListItemText primary="Depositos y Retiros" />
      </ListItem>
    );
  }

  const obtenerCuenta = (_, child) => {
    const { cuenta } = child.props;

    setCuentaConcepto({
      cuenta: cuenta ? cuenta : {},
      subcuentas: cuenta ? cuenta.subcuentas : [],
      subCuenta: {},
    });
  };
  const obtenerSubcuenta = (_, child) => {
    const { subCuenta } = child.props;

    setCuentaConcepto({
      ...cuentaConcepto,
      subCuenta: subCuenta ? subCuenta : {},
    });
  };

  const obtenerDatos = (e) => {
    const { value, name } = e.target;
    setDatosMovimiento({ ...datosMovimiento, [name]: value });
  };

  const enviarDatos = async () => {
    setCargando(true);
    try {
      if (!turnoEnCurso && sesion?.turno_en_caja_activo === false) {
        setCargando(false);
        setAlert({
          message: `Por el momento no hay ningún turno activo.`,
          status: "error",
          open: true,
        });
        return null;
      } else {
        if (
          !datosMovimiento.tipo_movimiento ||
          !datosMovimiento.monto_efectivo
        ) {
          setError(true);
          setCargando(false);
          setAlert({
            message: `Por favor complete los datos.`,
            status: "error",
            open: true,
          });
          return null;
        } else {
          if (
            datosMovimiento.tipo_movimiento === "RETIRO" &&
            dineroEnCaja < parseFloat(datosMovimiento.monto_efectivo)
          ) {
            setCargando(false);
            setAlert({
              message:
                "Lo sentimos no hay dinero suficiente en la caja para esta acción.",
              status: "error",
              open: true,
            });
            return null;
          }

          const input = {
            tipo_movimiento: datosMovimiento.tipo_movimiento,
            //concepto: datosMovimiento.concepto,
            concepto_cuenta: cuentaConcepto.cuenta.cuenta,
            concepto_subcuenta: cuentaConcepto.subCuenta.subcuenta,
            numero_caja: parseInt(turnoEnCurso.numero_caja),
            id_Caja: turnoEnCurso.id_caja,
            horario_turno: turnoEnCurso.horario_en_turno,
            rol_movimiento: "CAJA",
            id_User: sesion._id,
            numero_usuario_creador: sesion.numero_usuario,
            nombre_usuario_creador: sesion.nombre,
            montos_en_caja: {
              monto_efectivo: {
                monto: parseFloat(datosMovimiento.monto_efectivo),
                metodo_pago: "01",
              },
              monto_tarjeta_debito: {
                monto: 0,
                metodo_pago: "28",
              },
              monto_tarjeta_credito: {
                monto: 0,
                metodo_pago: "04",
              },
              monto_creditos: {
                monto: 0,
                metodo_pago: "99",
              },
              monto_monedero: {
                monto: 0,
                metodo_pago: "05",
              },
              monto_transferencia: {
                monto: 0,
                metodo_pago: "03",
              },
              monto_cheques: {
                monto: 0,
                metodo_pago: "02",
              },
              monto_vales_despensa: {
                monto: 0,
                metodo_pago: "08",
              },
            },
            empresa: sesion.empresa._id,
            sucursal: sesion.sucursal._id,
          };

          await CrearHistorialCaja({
            variables: {
              empresa: sesion.empresa._id,
              sucursal: sesion.sucursal._id,
              input,
            },
          });
          await ImpDepositosRetiros(sesion, input);
          setAlert({
            message: `Nuevo movimiento registrado`,
            status: "success",
            open: true,
          });
          setCargando(false);
          setCuentaConcepto({
            cuenta: {},
            subCuenta: {},
            subcuentas: [],
          });
          setDatosMovimiento({
            tipo_movimiento: "DEPOSITO",
          });
          handleClickOpen();
        }
      }
    } catch (error) {
      setCargando(false);
      setAlert({
        message: `Error: ${error.message}`,
        status: "error",
        open: true,
      });
      handleClickOpen();
    }
  };

  const handleTipoMovimiento = (e, tipo_movimiento) => {
    setDatosMovimiento({ ...datosMovimiento, tipo_movimiento });
  };

  return (
    <>
      <ListItem
        button
        onClick={() => {
          handleClickOpen();
        }}
        disabled={!turnoEnCurso}
      >
        <ListItemIcon>
          <ImportExport />
        </ListItemIcon>
        <ListItemText primary="Depositos y Retiros" />
      </ListItem>

      <Dialog
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClickOpen();
          }
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle component="div">
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Deposito/Retiro Caja</Typography>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClickOpen}
              size="large"
              //disabled={cargando}
            >
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            {sesion.turno_en_caja_activo === true ? (
              <Fragment>
                <Box width="100%" display="flex">
                  <Box mt={2}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={datosMovimiento.tipo_movimiento}
                        onChange={handleTipoMovimiento}
                      >
                        <FormControlLabel
                          value="DEPOSITO"
                          control={<Radio color="primary" />}
                          label="Deposito"
                        />
                        <FormControlLabel
                          value="RETIRO"
                          control={<Radio color="primary" />}
                          label="Retiro"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box width="40%" ml={1}>
                    <Typography>Monto:</Typography>
                    <Box display="flex">
                      <TextField
                        fullWidth
                        style={{ marginLeft: 3 }}
                        size="small"
                        type="number"
                        name="monto_efectivo"
                        onChange={obtenerDatos}
                        id="form-producto-codigo-barras"
                        variant="outlined"
                        error={error_data && !datosMovimiento.monto_efectivo}
                      />
                    </Box>
                  </Box>
                </Box>

                <Box width="100%">
                  <Box my={1}>
                    <Typography>Concepto de Movimiento:</Typography>
                  </Box>
                  <Box display="flex">
                    <Box display="flex" alignItems="center" width="50%">
                      <FormControl
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={error_data}
                      >
                        <InputLabel id="label-select-cuenta">Cuenta</InputLabel>
                        <Select
                          id="concepto-deposito-cuenta"
                          onChange={obtenerCuenta}
                          name="cuenta"
                          labelId="label-select-cuenta"
                          label="Cuenta"
                          value={
                            cuentaConcepto.cuenta.cuenta
                              ? cuentaConcepto.cuenta.cuenta
                              : ""
                          }
                        >
                          <MenuItem value="">
                            <em>Selecciona uno</em>
                          </MenuItem>
                          {data?.obtenerCuentas.map((cuenta, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={cuenta.cuenta}
                                cuenta={cuenta}
                              >
                                {cuenta.cuenta}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <CrearNuevaCuenta refetch={refetch} isOnline={isOnline} />
                    </Box>
                    <Box display="flex" alignItems="center" width="50%">
                      <FormControl
                        variant="outlined"
                        fullWidth
                        size="small"
                        error={error_data}
                      >
                        <InputLabel id="label-select-subcuenta">
                          Subcuenta
                        </InputLabel>
                        <Select
                          id="concepto-deposito-subcuenta"
                          onChange={obtenerSubcuenta}
                          name="subCuenta"
                          labelId="label-select-subcuenta"
                          label="Subcuenta"
                          value={
                            cuentaConcepto.subCuenta.subcuenta
                              ? cuentaConcepto.subCuenta.subcuenta
                              : ""
                          }
                        >
                          <MenuItem value="">
                            <em>Selecciona uno</em>
                          </MenuItem>
                          {cuentaConcepto.subcuentas.map((subcuenta, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={subcuenta.subcuenta}
                                subCuenta={subcuenta}
                              >
                                {subcuenta.subcuenta}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      <CrearNuevaSubcuenta
                        cuenta={cuentaConcepto}
                        refetch={refetch}
                        setCuenta={setCuentaConcepto}
                        datosMovimiento={datosMovimiento}
                        setDatosMovimiento={setDatosMovimiento}
                        isOnline={isOnline}
                      />
                    </Box>
                  </Box>
                </Box>
              </Fragment>
            ) : (
              <Box textAlign="center" p={2}>
                <Typography variant="h6">
                  <b>Por el momento no hay un turno en sesión</b>
                </Typography>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={enviarDatos}
            variant="contained"
            color="primary"
            size="large"
            disabled={cargando}
            startIcon={
              cargando ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <Done />
              )
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
