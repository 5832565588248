import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { Box, Fab, Paper, ThemeProvider, createTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { TiendaCtx } from "../../../../../context/Empresa/tiendaCtx";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  float: {
    position: "absolute",
    right: 16,
    bottom: 16,
  },
  appbar: {
    position: "absolute",
  },
}));

export default function TestComponent() {
  const { color } = React.useContext(TiendaCtx);
  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: {
        main: color.primary.hex,
      },
      secondary: {
        main: color.secondary.hex,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.root}>
        <AppBar className={classes.appbar} color="primary">
          <Toolbar>
            <IconButton color="inherit" edge="end">
              <MenuIcon />
            </IconButton>
            <Box mx={1} />
            <Typography variant="h6">Test Color</Typography>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Box p={1} height={230}>
          <Typography variant="h4">
            Texto de ejemplo
          </Typography>
          <Typography variant="h5">
            Texto de ejemplo
          </Typography>
          <Typography variant="h6">
            Texto de ejemplo
          </Typography>
          <Typography paragraph>
            Texto de ejemplo
          </Typography>
        </Box>
        <Fab color="secondary" aria-label="add" className={classes.float}>
          <Add />
        </Fab>
      </Paper>
    </ThemeProvider>
  );
}
