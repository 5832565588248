import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "lowercase",
    margin: "0px 4px",
  },
}));

export default function CategoriasSearch({
  searchProducto,
  obtenerDatos,
  queryCats,
  error,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [isDragging, setIsDragging] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const scrollContainerRef = React.useRef(null);

  if (!queryCats || error) {
    return (
      <Box mb={1.3} display="flex" gridGap={10}>
        {[1, 2, 3, 4, 5].map((res) => (
          <Skeleton
            key={res}
            style={{ width: 70, height: 30, transform: "none" }}
          />
        ))}
      </Box>
    );
  }

  const categorias = queryCats?.data?.obtenerCategoriasVenta || [];

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setStartX(event.pageX - scrollContainerRef.current.offsetLeft);
    setScrollLeft(scrollContainerRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const x = event.pageX - scrollContainerRef.current.offsetLeft;
    const distance = x - startX;
    scrollContainerRef.current.scrollLeft = scrollLeft - distance;
  };

  const handleChange = (value) => {
    obtenerDatos("cat", value);
  };

  return (
    <Box mb={1.5}>
      <Box
        className="scroll-container"
        ref={scrollContainerRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
      >
        <Box className="scroll-content">
          {categorias.map(({ categoria }, index) => (
            <Button
              ley={index}
              color={searchProducto.cat === categoria ? "primary" : "inherit"}
              variant="contained"
              disableElevation
              size="small"
              className={classes.button}
              style={{
                backgroundColor:
                  searchProducto.cat === categoria && theme.palette.cafiPrimary,
              }}
              onClick={() => handleChange(categoria)}
            >
              {categoria}
            </Button>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
