import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import { Link, useNavigate } from "react-router-dom";
import { grey } from "@material-ui/core/colors";
import { Person, ShoppingCart } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import ComponentOnline from "../../components/Connection/ComponentOnline";
import { AccesosContext } from "../../context/Accesos/accesosCtx";
import ProximoVencerAlert from "../VerificateLicence/ProximoVencerAlert";
import DownloadInstaller from "../DownloadIntaller/DownloadInstaller";
import { MainContext } from "../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.navbar,
    color: grey[800],
    height: "5vh",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  avatar: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.main,
  },
}));

function NavegacionAdmin(props) {
  const classes = useStyles();
  const token = localStorage.getItem("sesionCafi");
  const { turnoEnCurso } = React.useContext(MainContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { isOnline, ventasToCloud } = useContext(AccesosContext);
  let usuario;
  const navigate = useNavigate();

  if (token !== null) usuario = JSON.parse(localStorage.getItem("sesionCafi"));

  const signOut = () => {
    localStorage.removeItem("sesionCafi");
    localStorage.removeItem("tokenCafi");
    localStorage.removeItem("ListaEnEspera");
    navigate("/");
  };

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" flexGrow={1} alignItems="center">
        <Box mx={0.5} />
        <DownloadInstaller />
        <Box mx={0.5} />
        <Person color="action" />
        <Box mx={1}>
          <Typography color="textSecondary">
            {`${usuario.nombre.split(" ")[0]}`}
          </Typography>
        </Box>
        <ProximoVencerAlert />
      </Box>
      <Box display="flex" alignItems={"center"}>
        <ComponentOnline
          isOnline={isOnline}
          classes={classes}
          ventasToCloud={ventasToCloud}
          sesion={sesion}
          fromVentas={false}
          size="small"
        />
        <Box display="flex" alignItems={"center"}>
          <Button
            component={Link}
            to="/ventas"
            size="medium"
            className={classes.menuButton}
            startIcon={<ShoppingCart color="primary" />}
          >
            Panel de venta
          </Button>
          {sesion.turno_en_caja_activo === true && turnoEnCurso ? (
            <Button
              color="inherit"
              size="medium"
              className={classes.menuButton}
              variant="text"
              disabled={true}
              startIcon={<PowerSettingsNewIcon />}
            >
              TURNO ACTIVO
            </Button>
          ) : (
            <CerrarSesionDialog signOut={signOut} />
          )}
        </Box>
      </Box>
    </Box>
  );
}

const CerrarSesionDialog = ({ signOut }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="secondary"
        size="medium"
        className={classes.menuButton}
        startIcon={<PowerSettingsNewIcon />}
        variant="text"
        onClick={handleClickOpen}
      >
        Cerrar sesión
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>¿Seguro que deseas cerrar sesión?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button onClick={signOut} color="secondary" variant="contained">
            Cerrar sesión
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NavegacionAdmin;
