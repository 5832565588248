import { gql } from "@apollo/client";

export const CREAR_CAJA = gql`
  mutation CrearCaja($input: CrearCajasInput!, $empresa: ID!, $sucursal: ID!) {
    crearCaja(input: $input, empresa: $empresa, sucursal: $sucursal) {
      message
    }
  }
`;

export const OBTENER_CAJAS = gql`
  query ObtenerCajasSucursales($empresa: ID!, $sucursal: ID!) {
    obtenerCajasSucursal(empresa: $empresa, sucursal: $sucursal) {
      _id
      numero_caja
      activa
      usuario_creador {
        numero_usuario
        nombre
        email
      }
      cantidad_efectivo_actual
      usuario_en_caja {
        _id
        numero_usuario
        nombre
        email
      }
      principal
    }
  }
`;

export const CREAR_HISTORIAL_CAJA = gql`
  mutation CrearHistorialCaja(
    $input: CrearHistorialCajasInput!
    $empresa: ID!
    $sucursal: ID!
  ) {
    crearHistorialCaja(input: $input, empresa: $empresa, sucursal: $sucursal) {
      message
    }
  }
`;

export const OBTENER_HISTORIAL_CAJA = gql`
  query ObtenerHistorialCaja(
    $input: HistorialCajasInput
    $id_Caja: ID!
    $empresa: ID!
    $sucursal: ID!
    $limit: Int
    $offset: Int
  ) {
    obtenerHistorialCaja(
      input: $input
      id_Caja: $id_Caja
      empresa: $empresa
      sucursal: $sucursal
      limit: $limit
      offset: $offset
    ) {
      docs {
        tipo_movimiento
        concepto
        concepto_cuenta
        concepto_subcuenta
        horario_turno
        numero_caja
        numero_usuario_creador
        nombre_usuario_creador
        hora_moviento {
          hora
          minutos
          segundos
          completa
        }
        fecha_movimiento {
          year
          mes
          dia
          no_dia_year
          no_semana_year
          completa
        }
        montos_en_caja {
          monto_efectivo {
            monto
          }
          monto_tarjeta_credito {
            monto
          }
          monto_tarjeta_debito {
            monto
          }
          monto_creditos {
            monto
          }
          monto_monedero {
            monto
          }
          monto_transferencia {
            monto
          }
          monto_cheques {
            monto
          }
          monto_vales_despensa {
            monto
          }
        }
      }
      totalDocs
    }
  }
`;

export const ELIMINAR_CAJA = gql`
  mutation eliminarCaja($id: ID!, $empresa: ID!, $sucursal: ID!) {
    eliminarCaja(id: $id, empresa: $empresa, sucursal: $sucursal) {
      message
    }
  }
`;

export const ACTUALIZAR_CAJA = gql`
  mutation ActualizarCaja(
    $input: EditarCaja
    $id: ID!
    $empresa: ID!
    $sucursal: ID!
  ) {
    actualizarCaja(
      id: $id
      input: $input
      empresa: $empresa
      sucursal: $sucursal
    ) {
      _id
      numero_caja
      activa
    }
  }
`;

export const OBTENER_PRE_CORTE_CAJA = gql`
  query ObtenerPreCorteCaja(
    $empresa: ID!
    $sucursal: ID!
    $input: PreCortesDeCajaInput
    $cajaPrincipal: Boolean
  ) {
    obtenerPreCorteCaja(
      empresa: $empresa
      sucursal: $sucursal
      input: $input
      cajaPrincipal: $cajaPrincipal
    ) {
      monto_efectivo_precorte
    }
  }
`;

export const OBTENER_CORTES_CAJA = gql`
  query ObtenerCortesDeCaja(
    $empresa: ID!
    $sucursal: ID!
    $input: FiltroCorteDeCajaInput
    $limit: Int
    $offset: Int
  ) {
    obtenerCortesDeCaja(
      empresa: $empresa
      sucursal: $sucursal
      input: $input
      limit: $limit
      offset: $offset
    ) {
      docs {
        concepto
        token_turno_user
        numero_caja
        horario_en_turno
        id_caja
        id_usuario
        hora_salida {
          completa
        }
        fecha_salida {
          completa
        }
        fecha_movimiento
        usuario_en_turno {
          nombre
          telefono
          email
          numero_usuario
        }
        montos_en_caja {
          monto_efectivo {
            monto
          }
          monto_tarjeta_credito {
            monto
          }
          monto_tarjeta_debito {
            monto
          }
          monto_creditos {
            monto
          }
          monto_monedero {
            monto
          }
          monto_transferencia {
            monto
          }
          monto_cheques {
            monto
          }
          monto_vales_despensa {
            monto
          }
        }
        sucursal {
          nombre_sucursal
        }
      }
      totalDocs
    }
  }
`;

export const OBTENER_CORTE_CAJA = gql`
  query ObtenerCorteCaja(
    $empresa: ID!
    $sucursal: ID!
    $input: CorteDeCajaInput
  ) {
    obtenerCorteCaja(empresa: $empresa, sucursal: $sucursal, input: $input) {
      productos {
        producto {
          nombre
          codigo_barras
          clave
        }
        cantidad
        total
      }
      montos_en_sistema {
        movimientos_efectivo {
          monto_inicial
          monto_efectivo
          monto_tarjeta_debito
          monto_tarjeta_credito
          monto_creditos
          monto_monedero
          monto_transferencia
          monto_cheques
          monto_vales_despensa
        }
        movimientos_gastos {
          monto_efectivo
          monto_tarjeta_debito
          monto_tarjeta_credito
          monto_creditos
          monto_monedero
          monto_transferencia
          monto_cheques
          monto_vales_despensa
        }
        movimientos_ingresos {
          monto_efectivo
          monto_tarjeta_debito
          monto_tarjeta_credito
          monto_creditos
          monto_monedero
          monto_transferencia
          monto_cheques
          monto_vales_despensa
        }
      }
      datos_ventas {
        subtotal_ventas
        impuestos
        total_descuentos
        total_ventas
        cantidad_total_ventas
        ventas_contado
        ventas_creditos
        ventas_descuentos
        ventas_canceladas
      }
      fecha_inicio
      fecha_fin
    }
  }
`;
export const GetLastSesion = gql`
  query GetLastSesion($usuario: ID, $sucursal: ID) {
    getLastSesion(usuario: $usuario, sucursal: $sucursal) {
      _id
      horario_en_turno
      token_turno_user
      concepto
      numero_caja
      id_caja
      comentarios
      hora_entrada {
        hora
        minutos
        segundos
        completa
      }
      hora_salida {
        hora
        minutos
        segundos
        completa
      }
      fecha_entrada {
        year
        mes
        dia
        no_semana_year
        no_dia_year
        completa
      }
      fecha_salida {
        year
        mes
        dia
        no_semana_year
        no_dia_year
        completa
      }
      montos_en_caja {
        monto_efectivo {
          monto
          metodo_pago
        }
        monto_tarjeta_debito {
          monto
          metodo_pago
        }
        monto_tarjeta_credito {
          monto
          metodo_pago
        }
        monto_creditos {
          monto
          metodo_pago
        }
        monto_monedero {
          monto
          metodo_pago
        }
        monto_transferencia {
          monto
          metodo_pago
        }
        monto_cheques {
          monto
          metodo_pago
        }
        monto_vales_despensa {
          monto
          metodo_pago
        }
      }
      fecha_movimiento
      id_usuario
      usuario_en_turno {
        _id
        username_login
        numero_usuario
        nombre
        turno_en_caja_activo
        password
        telefono
        celular
        email
        direccion {
          calle
          no_ext
          no_int
          codigo_postal
          colonia
          municipio
          localidad
          estado
          pais
        }
        imagen
        estado_usuario
        empresa {
          _id
          nombre_empresa
          razon_social
          nombre_dueno
          id_tienda
          tienda
          telefono_dueno
          correo_empresa
          celular
          imagen
          nombre_fiscal
          rfc
          regimen_fiscal
          curp
          info_adicio
          valor_puntos
          direccion {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          direccionFiscal {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          datosBancarios {
            cuenta
            sucursal
            clave_banco
          }
          sucursales_activas
          limite_sucursales
          limite_timbres
          timbres_usados
          cuenta_empresa {
            efectivo
            bancario
          }
          sello_sat
          nombre_cer
          nombre_key
          fecha_registro_sello_sat
          vender_sin_inventario
          empresa_activa
          fecha_registro
        }
        sucursal {
          _id
          nombre_sucursal
          descripcion
          usuario_sucursal
          id_usuario
          numero_usuario
          password_sucursal
          direccion {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          id_empresa {
            _id
            nombre_empresa
            razon_social
            nombre_dueno
            id_tienda
            tienda
            telefono_dueno
            correo_empresa
            celular
            imagen
            nombre_fiscal
            rfc
            regimen_fiscal
            curp
            info_adicio
            valor_puntos
            sucursales_activas
            limite_sucursales
            limite_timbres
            timbres_usados
            sello_sat
            nombre_cer
            nombre_key
            fecha_registro_sello_sat
            vender_sin_inventario
            empresa_activa
            fecha_registro
          }
          cuenta_sucursal {
            efectivo
          }
          codigo_licencia
          fecha_inicio_licencia
          fecha_vencimiento_licencia
          licencia_activa
          fecha_registro
          createdAt
        }
        accesos {
          catalogos {
            clientes {
              ver
              agregar
              editar
              eliminar
            }
            usuarios {
              ver
              agregar
              editar
              eliminar
            }
            contabilidad {
              ver
              agregar
              editar
              eliminar
            }
            provedores {
              ver
              agregar
              editar
              eliminar
            }
            marcas {
              ver
              agregar
              editar
              eliminar
            }
            productos {
              ver
              agregar
              editar
              eliminar
            }
            tallas_numeros {
              ver
              agregar
              editar
              eliminar
            }
            cajas {
              ver
              agregar
              editar
              eliminar
            }
            departamentos {
              ver
              agregar
              editar
              eliminar
            }
            categorias {
              ver
              agregar
              editar
              eliminar
            }
            colores {
              ver
              agregar
              editar
              eliminar
            }
            centro_costos {
              ver
              agregar
              editar
              eliminar
            }
            conceptos_almacen {
              ver
              agregar
              editar
              eliminar
            }
          }
          mi_empresa {
            datos_empresa {
              ver
              agregar
              editar
              eliminar
            }
            informacion_fiscal {
              ver
              agregar
              editar
              eliminar
            }
          }
          compras {
            abrir_compra {
              ver
              agregar
              editar
              eliminar
            }
            compras_realizadas {
              ver
              agregar
              editar
              eliminar
            }
            compras_espera {
              ver
              agregar
              editar
              eliminar
            }
          }
          tesoreria {
            cuentas_empresa {
              ver
              agregar
              editar
              eliminar
            }
            egresos {
              ver
              agregar
              editar
              eliminar
            }
            abonos_proveedores {
              ver
              agregar
              editar
              eliminar
            }
            abonos_clientes {
              ver
              agregar
              editar
              eliminar
            }
            caja_principal {
              ver
              agregar
              editar
              eliminar
            }
          }
          reportes {
            reporte_historial_cajas {
              ver
              agregar
              editar
              eliminar
            }
            reporte_abonos {
              ver
              agregar
              editar
              eliminar
            }
            reporte_compras {
              ver
              agregar
              editar
              eliminar
            }
            reporte_ventas {
              ver
              agregar
              editar
              eliminar
            }
            rerporte_almacen {
              ver
              agregar
              editar
              eliminar
            }
            reporte_corte_caja {
              ver
              agregar
              editar
              eliminar
            }
            reporte_tesoreria {
              ver
              agregar
              editar
              eliminar
            }
          }
          ventas {
            cancelar_venta {
              ver
              agregar
              editar
              eliminar
            }
            precios_productos {
              ver
              agregar
              editar
              eliminar
            }
            pre_corte {
              ver
              agregar
              editar
              eliminar
            }
            cotizaciones {
              ver
              agregar
              editar
              eliminar
            }
            administrador {
              ver
              agregar
              editar
              eliminar
            }
            eliminar_ventas {
              ver
              agregar
              editar
              eliminar
            }
            producto_rapido {
              ver
              agregar
              editar
              eliminar
            }
          }
          almacenes {
            almacen {
              ver
              agregar
              editar
              eliminar
            }
            traspasos {
              ver
              agregar
              editar
              eliminar
            }
            inventario_almacen {
              ver
              agregar
              editar
              eliminar
            }
          }
          facturacion {
            generar_cdfi {
              ver
              agregar
              editar
              eliminar
            }
            cdfi_realizados {
              ver
              agregar
              editar
              eliminar
            }
            registro_series_cdfi {
              ver
              agregar
              editar
              eliminar
            }
          }
        }
        eliminado
      }
      empresa
      sucursal
      onCloud
    }
  }
`;
