import { gql } from "@apollo/client";

export const OBTENER_ABONOS_PROVEEDORES = gql`
  query obtenerAbonosProveedores(
    $empresa: ID!
    $sucursal: ID!
    $filtro: String
    $limit: Int
    $offset: Int
  ) {
    obtenerAbonosProveedores(
      empresa: $empresa
      sucursal: $sucursal
      filtro: $filtro
      limit: $limit
      offset: $offset
    ) {
      docs {
        _id
        estatus_credito
        usuario {
          _id
          numero_usuario
          nombre
          estado_usuario
        }
        empresa {
          _id
          nombre_empresa
        }
        sucursal {
          _id
          nombre_sucursal
        }
        proveedor {
          id_proveedor {
            _id
            numero_cliente
            clave_cliente
            nombre_cliente
            telefono
            email
          }
          numero_cliente
          clave_cliente
          nombre_cliente
        }
        productos {
          _id
          id_compra
          id_traspaso
          id_producto
          producto {
            almacen_inicial {
              almacen
              cantidad
              fecha_de_expiracion
              id_almacen
            }
            datos_generales {
              codigo_barras
              clave_alterna
              tipo_producto
              nombre_comercial
              nombre_generico
              descripcion
              id_categoria
              categoria
              subcategoria
              id_subcategoria
              id_departamento
              departamento
              id_marca
              marca
              clave_producto_sat {
                Name
                Value
              }
              receta_farmacia
            }
            precios {
              ieps
              ieps_activo
              iva
              iva_activo
              monedero
              monedero_electronico
              granel
              inventario {
                inventario_minimo
                inventario_maximo
                unidad_de_inventario
              }
              precio_de_compra {
                precio_con_impuesto
                precio_sin_impuesto
                iva
                ieps
              }
              precios_producto {
                numero_precio
                precio_neto
                precio_venta
                unidad_mayoreo
                utilidad
              }
              unidad_de_compra {
                cantidad
                precio_unitario_con_impuesto
                precio_unitario_sin_impuesto
                unidad
              }
            }
            unidades_de_venta {
              _id
              cantidad
              codigo_barras
              id_producto
              precio
              unidad
              unidad_principal
            }
          }
          concepto
          cantidad
          cantidad_regalo
          cantidad_total
          costo
          descuento_porcentaje
          descuento_precio
          impuestos
          mantener_precio
          subtotal
          total
          medida {
            id_medida
            medida
            tipo
          }
          color {
            id_color
            color
            hex
          }
          unidad
          id_unidad_venta
          empresa
          sucursal
          usuario
          year_registro
          numero_semana_year
          numero_mes_year
          fecha_registro
        }
        status
        almacen {
          id_almacen {
            _id
            nombre_almacen
            id_usuario_encargado {
              _id
              numero_usuario
              nombre
            }
          }
          nombre_almacen
        }
        compra_credito
        credito_pagado
        saldo_credito_pendiente
        fecha_vencimiento_credito
        subtotal
        impuestos
        total
        year_registro
        numero_semana_year
        numero_mes_year
        fecha_registro
        folio
      }
      totalDocs
    }
  }
`;

export const OBTENER_ESTADOS_DE_CUENTA = gql`
  query obtenerEstadosDeCuenta(
    $empresa: ID!
    $sucursal: ID
    $limit: Int
    $offset: Int
    $filtro: EstadosDeCuentaInput
  ) {
    obtenerEstadosDeCuenta(
      empresa: $empresa
      sucursal: $sucursal
      limit: $limit
      offset: $offset
      filtro: $filtro
    ) {
      docs {
        _id
        clave_cliente
        nombre_cliente
        telefono
        email
        estado_cliente
        fecha_nacimiento
        empresa
        sucursal
        numero_cliente
        eliminado
        credito_disponible
        limite_credito
        monedero_electronico
        dias_credito
        ventas {
          _id
          folio
          descuento
          ieps
          impuestos
          iva
          monedero
          subTotal
          total
          venta_cliente
          status
          montos_en_caja {
            monto_efectivo {
              monto
              metodo_pago
            }
            monto_tarjeta_debito {
              monto
              metodo_pago
            }
            monto_tarjeta_credito {
              monto
              metodo_pago
            }
            monto_creditos {
              monto
              metodo_pago
            }
            monto_monedero {
              monto
              metodo_pago
            }
            monto_transferencia {
              monto
              metodo_pago
            }
            monto_cheques {
              monto
              metodo_pago
            }
            monto_vales_despensa {
              monto
              metodo_pago
            }
          }
          credito
          abono_minimo
          saldo_credito_pendiente
          credito_pagado
          descuento_general_activo
          descuento_general {
            cantidad_descontado
            porciento
            precio_con_descuento
          }
          id_caja
          fecha_de_vencimiento_credito
          dias_de_credito_venta
          empresa
          sucursal
          usuario
          cliente {
            _id
            numero_cliente
            clave_cliente
            representante
            nombre_cliente
            rfc
            curp
            telefono
            celular
            email
            numero_descuento
            limite_credito
            dias_credito
            razon_social
            regimen_fiscal
            direccion {
              calle
              no_ext
              no_int
              codigo_postal
              colonia
              municipio
              localidad
              estado
              pais
            }
            imagen
            estado_cliente
            tipo_cliente
            fecha_nacimiento
            fecha_registro
            eliminado
            banco
            numero_cuenta
            empresa {
              _id
              nombre_empresa
              nombre_dueno
              id_tienda
              tienda
              telefono_dueno
              correo_empresa
              celular
              imagen
              nombre_fiscal
              rfc
              regimen_fiscal
              curp
              info_adicio
              valor_puntos
              direccion {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              direccionFiscal {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              datosBancarios {
                cuenta
                sucursal
                clave_banco
              }
              sucursales_activas
              limite_sucursales
              limite_timbres
              timbres_usados
              cuenta_empresa {
                efectivo
                bancario
              }
              sello_sat
              nombre_cer
              nombre_key
              fecha_registro_sello_sat
              vender_sin_inventario
              empresa_activa
              fecha_registro
            }
            sucursal {
              _id
              nombre_sucursal
              descripcion
              usuario_sucursal
              id_usuario
              numero_usuario
              password_sucursal
              direccion {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              id_empresa {
                _id
                nombre_empresa
                nombre_dueno
                id_tienda
                tienda
                telefono_dueno
                correo_empresa
                celular
                imagen
                nombre_fiscal
                rfc
                regimen_fiscal
                curp
                info_adicio
                valor_puntos
                sucursales_activas
                limite_sucursales
                limite_timbres
                timbres_usados
                sello_sat
                nombre_cer
                nombre_key
                fecha_registro_sello_sat
                vender_sin_inventario
                empresa_activa
                fecha_registro
              }
              cuenta_sucursal {
                efectivo
              }
              codigo_licencia
              fecha_inicio_licencia
              fecha_vencimiento_licencia
              licencia_activa
              fecha_registro
              createdAt
            }
            monedero_electronico
            credito_disponible
          }
          year_registro
          numero_semana_year
          numero_mes_year
          fecha_registro
          forma_pago
          metodo_pago
          tipo_emision
          fecha_venta
          turno
          abonos {
            _id
            numero_caja
            id_Caja
            horario_turno
            tipo_movimiento
            rol_movimiento
            fecha_movimiento {
              year
              mes
              dia
              no_semana_year
              no_dia_year
              completa
            }
            montos_en_caja {
              monto_efectivo {
                monto
                metodo_pago
              }
              monto_tarjeta_debito {
                monto
                metodo_pago
              }
              monto_tarjeta_credito {
                monto
                metodo_pago
              }
              monto_creditos {
                monto
                metodo_pago
              }
              monto_monedero {
                monto
                metodo_pago
              }
              monto_transferencia {
                monto
                metodo_pago
              }
              monto_cheques {
                monto
                metodo_pago
              }
              monto_vales_despensa {
                monto
                metodo_pago
              }
            }
            monto_total_abonado
            metodo_de_pago {
              clave
              metodo
            }
            saldo_credito_pendiente
            descuento {
              porciento_descuento
              dinero_descontado
              total_con_descuento
            }
            id_usuario
            numero_usuario_creador
            nombre_usuario_creador
            id_cliente
            numero_cliente
            nombre_cliente
            telefono_cliente
            email_cliente
            id_egreso
            provedor_egreso
            folio_egreso
            id_compra
            status
          }
        }
      }
      totalDocs
    }
  }
`;

export const OBTENER_ESTADOS_DE_CUENTA_PROVEEDOR = gql`
  query obtenerEstadosDeCuentaProveedor(
    $empresa: ID!
    $sucursal: ID
    $limit: Int
    $offset: Int
    $filtros: EstadosDeCuentaProveedoresInput
  ) {
    obtenerEstadosDeCuentaProveedor(
      empresa: $empresa
      sucursal: $sucursal
      filtros: $filtros
      limit: $limit
      offset: $offset
    ) {
      docs {
        _id
        clave_cliente
        credito_disponible
        dias_credito
        eliminado
        email
        estado_cliente
        empresa
        fecha_nacimiento
        limite_credito
        monedero_electronico
        nombre_cliente
        numero_cliente
        sucursal
        telefono
        saldo_total_pendiente {
          saldo
        }
        compras {
          _id
          almacen {
            default_almacen
            id_almacen {
              _id
              nombre_almacen
              id_usuario_encargado {
                _id
                username_login
                numero_usuario
                nombre
                turno_en_caja_activo
                password
                telefono
                celular
                email
                direccion {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                imagen
                estado_usuario
                sucursal {
                  _id
                  nombre_sucursal
                  descripcion
                  usuario_sucursal
                  id_usuario
                  numero_usuario
                  password_sucursal
                  codigo_licencia
                  fecha_inicio_licencia
                  fecha_vencimiento_licencia
                  licencia_activa
                  fecha_registro
                  createdAt
                }
                accesos {
                  catalogos {
                    clientes {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    usuarios {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    contabilidad {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    provedores {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    marcas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    productos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    tallas_numeros {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    cajas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    departamentos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    categorias {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    colores {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    centro_costos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    conceptos_almacen {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  mi_empresa {
                    datos_empresa {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    informacion_fiscal {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  compras {
                    abrir_compra {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    compras_realizadas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    compras_espera {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  tesoreria {
                    cuentas_empresa {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    egresos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    abonos_proveedores {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    abonos_clientes {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    caja_principal {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  reportes {
                    reporte_historial_cajas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_abonos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_compras {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_ventas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    rerporte_almacen {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_corte_caja {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_tesoreria {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  ventas {
                    cancelar_venta {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    precios_productos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    pre_corte {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    cotizaciones {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    administrador {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    eliminar_ventas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    producto_rapido {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  almacenes {
                    almacen {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    traspasos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    inventario_almacen {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  facturacion {
                    generar_cdfi {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    cdfi_realizados {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    registro_series_cdfi {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                }
                eliminado
              }

              id_sucursal {
                _id
                nombre_sucursal
                descripcion
                usuario_sucursal
                id_usuario
                numero_usuario
                password_sucursal
                direccion {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                id_empresa {
                  _id
                  nombre_empresa
                  razon_social
                  nombre_dueno
                  id_tienda
                  tienda
                  telefono_dueno
                  correo_empresa
                  celular
                  imagen
                  nombre_fiscal
                  rfc
                  regimen_fiscal
                  curp
                  info_adicio
                  valor_puntos
                  sucursales_activas
                  limite_sucursales
                  limite_timbres
                  timbres_usados
                  sello_sat
                  nombre_cer
                  nombre_key
                  fecha_registro_sello_sat
                  vender_sin_inventario
                  empresa_activa
                  fecha_registro
                }
                cuenta_sucursal {
                  efectivo
                }
                codigo_licencia
                fecha_inicio_licencia
                fecha_vencimiento_licencia
                licencia_activa
                fecha_registro
                createdAt
              }

              empresa {
                _id
                nombre_empresa
                razon_social
                nombre_dueno
                id_tienda
                tienda
                telefono_dueno
                correo_empresa
                celular
                imagen
                nombre_fiscal
                rfc
                regimen_fiscal
                curp
                info_adicio
                valor_puntos
                direccion {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                direccionFiscal {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                datosBancarios {
                  cuenta
                  sucursal
                  clave_banco
                }
                sucursales_activas
                limite_sucursales
                limite_timbres
                timbres_usados
                cuenta_empresa {
                  efectivo
                  bancario
                }
                sello_sat
                nombre_cer
                nombre_key
                fecha_registro_sello_sat
                vender_sin_inventario
                empresa_activa
                fecha_registro
              }
              direccion {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              default_almacen
            }
            nombre_almacen
          }
          compra_credito
          credito_pagado
          descuento {
            cantidad_descontada
            porcentaje
            precio_con_descuento
          }
          descuento_aplicado
          empresa {
            _id
            nombre_empresa
            razon_social
            nombre_dueno
            id_tienda
            tienda
            telefono_dueno
            correo_empresa
            celular
            imagen
            nombre_fiscal
            rfc
            regimen_fiscal
            curp
            info_adicio
            valor_puntos
            direccion {
              calle
              no_ext
              no_int
              codigo_postal
              colonia
              municipio
              localidad
              estado
              pais
            }
            direccionFiscal {
              calle
              no_ext
              no_int
              codigo_postal
              colonia
              municipio
              localidad
              estado
              pais
            }
            datosBancarios {
              cuenta
              sucursal
              clave_banco
            }
            sucursales_activas
            limite_sucursales
            limite_timbres
            timbres_usados
            cuenta_empresa {
              efectivo
              bancario
            }
            sello_sat
            nombre_cer
            nombre_key
            fecha_registro_sello_sat
            vender_sin_inventario
            empresa_activa
            fecha_registro
          }
          en_espera
          estatus_credito
          fecha_registro
          fecha_vencimiento_credito
          folio
          forma_pago
          impuestos
          metodo_de_pago {
            clave
            metodo
          }
          numero_mes_year
          numero_semana_year
          proveedor {
            clave_cliente
            nombre_cliente
            numero_cliente
            id_proveedor {
              _id
              numero_cliente
              clave_cliente
              representante
              nombre_cliente
              rfc
              curp
              telefono
              celular
              email
              numero_descuento
              limite_credito
              dias_credito
              razon_social
              regimen_fiscal
              direccion {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              imagen
              estado_cliente
              tipo_cliente
              fecha_nacimiento
              fecha_registro
              eliminado
              banco
              numero_cuenta
              empresa {
                _id
                nombre_empresa
                razon_social
                nombre_dueno
                id_tienda
                tienda
                telefono_dueno
                correo_empresa
                celular
                imagen
                nombre_fiscal
                rfc
                regimen_fiscal
                curp
                info_adicio
                valor_puntos
                direccion {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                direccionFiscal {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                datosBancarios {
                  cuenta
                  sucursal
                  clave_banco
                }
                sucursales_activas
                limite_sucursales
                limite_timbres
                timbres_usados
                cuenta_empresa {
                  efectivo
                  bancario
                }
                sello_sat
                nombre_cer
                nombre_key
                fecha_registro_sello_sat
                vender_sin_inventario
                empresa_activa
                fecha_registro
              }
              sucursal {
                _id
                nombre_sucursal
                descripcion
                usuario_sucursal
                id_usuario
                numero_usuario
                password_sucursal
                direccion {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                id_empresa {
                  _id
                  nombre_empresa
                  razon_social
                  nombre_dueno
                  id_tienda
                  tienda
                  telefono_dueno
                  correo_empresa
                  celular
                  imagen
                  nombre_fiscal
                  rfc
                  regimen_fiscal
                  curp
                  info_adicio
                  valor_puntos
                  sucursales_activas
                  limite_sucursales
                  limite_timbres
                  timbres_usados
                  sello_sat
                  nombre_cer
                  nombre_key
                  fecha_registro_sello_sat
                  vender_sin_inventario
                  empresa_activa
                  fecha_registro
                }
                cuenta_sucursal {
                  efectivo
                }
                codigo_licencia
                fecha_inicio_licencia
                fecha_vencimiento_licencia
                licencia_activa
                fecha_registro
                createdAt
              }
              monedero_electronico
              credito_disponible
            }
          }
          abonos {
            _id
            numero_caja
            id_Caja
            horario_turno
            tipo_movimiento
            rol_movimiento
            fecha_movimiento {
              year
              mes
              dia
              no_semana_year
              no_dia_year
              completa
            }
            montos_en_caja {
              monto_efectivo {
                monto
                metodo_pago
              }
              monto_tarjeta_debito {
                monto
                metodo_pago
              }
              monto_tarjeta_credito {
                monto
                metodo_pago
              }
              monto_creditos {
                monto
                metodo_pago
              }
              monto_monedero {
                monto
                metodo_pago
              }
              monto_transferencia {
                monto
                metodo_pago
              }
              monto_cheques {
                monto
                metodo_pago
              }
              monto_vales_despensa {
                monto
                metodo_pago
              }
            }
            monto_total_abonado
            metodo_de_pago {
              clave
              metodo
            }
            saldo_credito_pendiente
            descuento {
              porciento_descuento
              dinero_descontado
              total_con_descuento
            }
            id_usuario
            numero_usuario_creador
            nombre_usuario_creador
            id_cliente
            numero_cliente
            nombre_cliente
            telefono_cliente
            email_cliente
            id_egreso
            provedor_egreso
            folio_egreso
            id_compra
            status
          }
          productos {
            _id
            id_compra
            id_traspaso
            id_venta
            id_producto
            id_proveedor
            id_almacen
            folio_compra
            almacen {
              id_almacen
              nombre_almacen
              default_almacen
            }
            proveedor {
              _id
              clave_cliente
              numero_cliente
              nombre_cliente
            }
            producto {
              almacen_inicial {
                almacen
                cantidad
                fecha_de_expiracion
                id_almacen
                default_almacen
              }
              datos_generales {
                codigo_barras
                clave_alterna
                tipo_producto
                nombre_comercial
                nombre_generico
                descripcion
                id_categoria
                categoria
                subcategoria
                id_subcategoria
                id_departamento
                departamento
                id_marca
                marca
                clave_producto_sat {
                  Name
                  Value
                }
                receta_farmacia
              }
              precios {
                ieps
                ieps_activo
                inventario {
                  inventario_minimo
                  inventario_maximo
                  unidad_de_inventario
                  codigo_unidad
                }
                iva
                iva_activo
                monedero
                monedero_electronico
                precio_de_compra {
                  precio_con_impuesto
                  precio_sin_impuesto
                  iva
                  ieps
                }
                precios_producto {
                  numero_precio
                  precio_neto
                  precio_venta
                  iva_precio
                  ieps_precio
                  unidad_mayoreo
                  utilidad
                  precio_general
                  cantidad_unidad
                  unidad_maxima
                }
                unidad_de_compra {
                  cantidad
                  precio_unitario_con_impuesto
                  precio_unitario_sin_impuesto
                  unidad
                  codigo_unidad
                }
                granel
                litros
              }
              unidades_de_venta {
                cantidad
                codigo_barras
                id_producto
                precio
                unidad
                codigo_unidad
                unidad_principal
                _id
              }
            }
            concepto
            cantidad
            cantidad_regalo
            unidad_regalo
            cantidad_total
            cantidad_venta
            iva_total
            ieps_total
            costo
            descuento_porcentaje
            descuento_precio
            descuento_producto {
              cantidad_unidad
              numero_precio
              unidad_maxima
              precio_general
              precio_neto
              precio_venta
              iva_precio
              ieps_precio
              utilidad
              porciento
              dinero_descontado
            }
            compra_credito
            venta_credito
            forma_pago
            impuestos
            mantener_precio
            subtotal
            total
            medida {
              id_medida
              medida
              tipo
            }
            color {
              id_color
              color
              hex
            }
            unidad
            codigo_unidad
            id_unidad_venta
            empresa
            sucursal
            usuario
            year_registro
            numero_semana_year
            numero_mes_year
            fecha_registro
            nota_credito {
              id_nota_credito
              cantidad_devuelta
              cantidad_vendida
              total
            }
            venta {
              _id
              folio
              cliente {
                _id
                numero_cliente
                clave_cliente
                representante
                nombre_cliente
                rfc
                curp
                telefono
                celular
                email
                numero_descuento
                limite_credito
                dias_credito
                razon_social
                regimen_fiscal
                direccion {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                imagen
                estado_cliente
                tipo_cliente
                fecha_nacimiento
                fecha_registro
                eliminado
                banco
                numero_cuenta
                monedero_electronico
                credito_disponible
              }
              descuento
              ieps
              impuestos
              iva
              monedero
              subtotal_base
              total_base
              subTotal
              total
              venta_cliente
              status
              tipo_emision
              observaciones
              forma_pago
              metodo_pago
              credito
              descuento_general_activo
              id_caja {
                _id
                numero_caja
                activa
              }
              fecha_de_vencimiento_credito
              dias_de_credito_venta
              saldo_credito_pendiente
              empresa
              sucursal
              usuario {
                _id
                username_login
                numero_usuario
                nombre
                turno_en_caja_activo
                password
                telefono
                celular
                email
                imagen
                estado_usuario
                accesos {
                  catalogos {
                    clientes {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    usuarios {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    contabilidad {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    provedores {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    marcas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    productos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    tallas_numeros {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    cajas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    departamentos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    categorias {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    colores {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    centro_costos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    conceptos_almacen {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  mi_empresa {
                    datos_empresa {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    informacion_fiscal {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  compras {
                    abrir_compra {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    compras_realizadas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    compras_espera {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  tesoreria {
                    cuentas_empresa {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    egresos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    abonos_proveedores {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    abonos_clientes {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    caja_principal {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  reportes {
                    reporte_historial_cajas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_abonos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_compras {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_ventas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    rerporte_almacen {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_corte_caja {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    reporte_tesoreria {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  ventas {
                    cancelar_venta {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    precios_productos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    pre_corte {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    cotizaciones {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    administrador {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    eliminar_ventas {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    producto_rapido {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  almacenes {
                    almacen {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    traspasos {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    inventario_almacen {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                  facturacion {
                    generar_cdfi {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    cdfi_realizados {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                    registro_series_cdfi {
                      ver
                      agregar
                      editar
                      eliminar
                    }
                  }
                }
                eliminado
              }
              productos {
                _id
                producto {
                  _id
                  imagenes {
                    url_imagen
                    location_imagen
                    key_imagen
                    extencion_imagen
                  }
                  centro_de_costos {
                    cuenta
                    id_cuenta
                    id_subcuenta
                    subcuenta
                  }
                  precio_plazos {
                    precio_cajas {
                      plazo
                      unidad
                      codigo_unidad
                      precio
                    }
                    precio_costales {
                      plazo
                      unidad
                      codigo_unidad
                      precio
                    }
                    precio_piezas {
                      plazo
                      unidad
                      codigo_unidad
                      precio
                    }
                    precio_tarimas {
                      plazo
                      unidad
                      codigo_unidad
                      precio
                    }
                  }
                  empresa
                  sucursal
                  usuario
                  unidades_de_venta {
                    _id
                    precio
                    cantidad
                    concepto
                    unidad
                    codigo_unidad
                    unidad_principal
                    unidad_activa
                    codigo_barras
                    id_producto
                    empresa
                    sucursal
                    descuento_activo
                    default
                    createdAt
                    updatedAt
                  }
                  medidas_producto {
                    _id
                    unidad
                    codigo_unidad
                    cantidad
                    cantidad_nueva
                    almacen
                    codigo_barras
                    color {
                      hex
                      nombre
                      _id
                    }
                    descuento_activo
                    existencia
                    medida {
                      talla
                      tipo
                      _id
                    }
                    nombre_comercial
                    precio
                  }
                  medidas_registradas
                }
                id_producto {
                  _id
                  empresa
                  sucursal
                  usuario
                  medidas_registradas
                }
                concepto
                cantidad
                iva_total
                ieps_total
                subtotal_antes_de_impuestos
                subtotal
                impuestos
                total
                medida {
                  id_medida
                  medida
                  tipo
                }
                color {
                  id_color
                  color
                  hex
                }
                cantidad_venta
                precio
                precio_a_vender
                precio_actual_producto
                default
                unidad
                codigo_unidad
                id_unidad_venta {
                  _id
                  precio
                  cantidad
                  concepto
                  unidad
                  codigo_unidad
                  unidad_principal
                  unidad_activa
                  codigo_barras
                  id_producto
                  empresa
                  sucursal
                  descuento {
                    cantidad_unidad
                    numero_precio
                    unidad_maxima
                    precio_general
                    precio_neto
                    precio_venta
                    iva_precio
                    ieps_precio
                    utilidad
                    porciento
                    dinero_descontado
                  }
                  descuento_activo
                  default
                  createdAt
                  updatedAt
                  precio_unidad {
                    numero_precio
                    precio_neto
                    precio_venta
                    iva_precio
                    ieps_precio
                    unidad_mayoreo
                    utilidad
                    precio_general
                    cantidad_unidad
                    unidad_maxima
                  }
                }
                year_registro
                numero_semana_year
                numero_mes_year
                fecha_registro
                precio_unidad {
                  numero_precio
                  precio_neto
                  precio_venta
                  iva_precio
                  ieps_precio
                  unidad_mayoreo
                  utilidad
                  precio_general
                  cantidad_unidad
                  unidad_maxima
                }
                inventario_general {
                  _id
                  cantidad_existente
                  unidad_inventario
                  codigo_unidad
                  cantidad_existente_maxima
                  unidad_maxima
                  id_almacen_general
                  eliminado
                }
                descuento_activo
              }
              fecha_registro
              montos_en_caja {
                monto_efectivo {
                  monto
                  metodo_pago
                }
                monto_tarjeta_debito {
                  monto
                  metodo_pago
                }
                monto_tarjeta_credito {
                  monto
                  metodo_pago
                }
                monto_creditos {
                  monto
                  metodo_pago
                }
                monto_monedero {
                  monto
                  metodo_pago
                }
                monto_transferencia {
                  monto
                  metodo_pago
                }
                monto_cheques {
                  monto
                  metodo_pago
                }
                monto_vales_despensa {
                  monto
                  metodo_pago
                }
              }
              abono_minimo
              cambio
              nota_credito {
                _id
                generar_cfdi
                descuento
                ieps
                impuestos
                iva
                subTotal
                total
                id_factura
                observaciones
                payment_form
                payment_method
                empresa
                sucursal
                folio
                fecha_registro
                cambio
                devolucion_en
              }
            }
            compra {
              _id
              folio
              empresa {
                _id
                nombre_empresa
                razon_social
                nombre_dueno
                id_tienda
                tienda
                telefono_dueno
                correo_empresa
                celular
                imagen
                nombre_fiscal
                rfc
                regimen_fiscal
                curp
                info_adicio
                valor_puntos
                direccion {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                direccionFiscal {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                datosBancarios {
                  cuenta
                  sucursal
                  clave_banco
                }
                sucursales_activas
                limite_sucursales
                limite_timbres
                timbres_usados
                cuenta_empresa {
                  efectivo
                  bancario
                }
                sello_sat
                nombre_cer
                nombre_key
                fecha_registro_sello_sat
                vender_sin_inventario
                empresa_activa
                fecha_registro
              }
              sucursal {
                _id
                nombre_sucursal
                descripcion
                usuario_sucursal
                id_usuario
                numero_usuario
                password_sucursal
                direccion {
                  calle
                  no_ext
                  no_int
                  codigo_postal
                  colonia
                  municipio
                  localidad
                  estado
                  pais
                }
                id_empresa {
                  _id
                  nombre_empresa
                  razon_social
                  nombre_dueno
                  id_tienda
                  tienda
                  telefono_dueno
                  correo_empresa
                  celular
                  imagen
                  nombre_fiscal
                  rfc
                  regimen_fiscal
                  curp
                  info_adicio
                  valor_puntos
                  sucursales_activas
                  limite_sucursales
                  limite_timbres
                  timbres_usados
                  sello_sat
                  nombre_cer
                  nombre_key
                  fecha_registro_sello_sat
                  vender_sin_inventario
                  empresa_activa
                  fecha_registro
                }
                cuenta_sucursal {
                  efectivo
                }
                codigo_licencia
                fecha_inicio_licencia
                fecha_vencimiento_licencia
                licencia_activa
                fecha_registro
                createdAt
              }
              proveedor {
                id_proveedor {
                  _id
                  numero_cliente
                  clave_cliente
                  representante
                  nombre_cliente
                  rfc
                  curp
                  telefono
                  celular
                  email
                  numero_descuento
                  limite_credito
                  dias_credito
                  razon_social
                  regimen_fiscal
                  imagen
                  estado_cliente
                  tipo_cliente
                  fecha_nacimiento
                  fecha_registro
                  eliminado
                  banco
                  numero_cuenta
                  monedero_electronico
                  credito_disponible
                }
                clave_cliente
                numero_cliente
                nombre_cliente
              }
              productos {
                _id
                id_compra
                id_traspaso
                id_venta
                id_producto
                id_proveedor
                id_almacen
                folio_compra
                concepto
                cantidad
                cantidad_regalo
                unidad_regalo
                cantidad_total
                cantidad_venta
                iva_total
                ieps_total
                costo
                descuento_porcentaje
                descuento_precio
                compra_credito
                venta_credito
                forma_pago
                impuestos
                mantener_precio
                subtotal
                total
                unidad
                codigo_unidad
                id_unidad_venta
                empresa
                sucursal
                usuario
                year_registro
                numero_semana_year
                numero_mes_year
                fecha_registro
                subtotal_antes_de_impuestos
                precio
                precio_a_vender
                precio_actual_producto
                descuento_activo
                default
              }
              en_espera
              almacen {
                id_almacen {
                  _id
                  nombre_almacen
                  id_usuario_encargado {
                    _id
                    username_login
                    numero_usuario
                    nombre
                    turno_en_caja_activo
                    password
                    telefono
                    celular
                    email
                    imagen
                    estado_usuario
                    eliminado
                  }
                  id_sucursal {
                    _id
                    nombre_sucursal
                    descripcion
                    usuario_sucursal
                    id_usuario
                    numero_usuario
                    password_sucursal
                    codigo_licencia
                    fecha_inicio_licencia
                    fecha_vencimiento_licencia
                    licencia_activa
                    fecha_registro
                    createdAt
                  }
                  direccion {
                    calle
                    no_ext
                    no_int
                    codigo_postal
                    colonia
                    municipio
                    localidad
                    estado
                    pais
                  }
                  default_almacen
                }
                nombre_almacen
                default_almacen
              }
              metodo_de_pago {
                clave
                metodo
              }
              compra_credito
              fecha_vencimiento_credito
              credito_pagado
              saldo_credito_pendiente
              forma_pago
              descuento_aplicado
              descuento {
                porcentaje
                cantidad_descontada
                precio_con_descuento
              }
              subtotal
              impuestos
              total
              year_registro
              numero_semana_year
              numero_mes_year
              fecha_registro
              estatus_credito
              status
              abonos {
                _id
                numero_caja
                id_Caja
                horario_turno
                tipo_movimiento
                rol_movimiento
                fecha_movimiento {
                  year
                  mes
                  dia
                  no_semana_year
                  no_dia_year
                  completa
                }
                montos_en_caja {
                  monto_efectivo {
                    monto
                    metodo_pago
                  }
                  monto_tarjeta_debito {
                    monto
                    metodo_pago
                  }
                  monto_tarjeta_credito {
                    monto
                    metodo_pago
                  }
                  monto_creditos {
                    monto
                    metodo_pago
                  }
                  monto_monedero {
                    monto
                    metodo_pago
                  }
                  monto_transferencia {
                    monto
                    metodo_pago
                  }
                  monto_cheques {
                    monto
                    metodo_pago
                  }
                  monto_vales_despensa {
                    monto
                    metodo_pago
                  }
                }
                monto_total_abonado
                saldo_credito_pendiente
                descuento {
                  porciento_descuento
                  dinero_descontado
                  total_con_descuento
                }
                id_usuario
                numero_usuario_creador
                nombre_usuario_creador
                id_cliente
                numero_cliente
                nombre_cliente
                telefono_cliente
                email_cliente
                id_egreso
                provedor_egreso
                folio_egreso
                id_compra
                status
              }
            }
            factura {
              id_cfdi
              serie
              currency
              expedition_place
              folio
              cfdi_type
              payment_form
              payment_method
              logo_url
              date
              issuer {
                FiscalRegime
                Rfc
                TaxName
              }
              receiver {
                Rfc
                Name
                CfdiUse
              }
              items {
                ProductCode
                IdentificationNumber
                Description
                Unit
                UnitCode
                UnitPrice
                Quantity
                Subtotal
                Discount
                Taxes {
                  Total
                  Name
                  Base
                  Rate
                  IsRetention
                }
                Total
              }
              taxes {
                Total
                Name
                Rate
                Type
              }
              complement {
                TaxStamp {
                  Uuid
                  Date
                  CdfiSign
                  SatCertNumber
                  SatSign
                  RfcProvCertif
                }
                Payments {
                  _id
                  RelatedDocuments {
                    _id
                    Uuid
                    Folio
                    Currency
                    PaymentMethod
                    PartialityNumber
                    PreviousBalanceAmount
                    AmountPaid
                    ImpSaldoInsoluto
                  }
                  Date
                  PaymentForm
                  Currency
                  Amount
                  ExpectedPaid
                }
              }
              original_string
              sub_total
              total
              discount
              year_registro
              numero_semana_year
              numero_mes_year
              fecha_registro
              empresa
              sucursal
              id_venta
              folio_venta
              tipo
              complementos {
                id_cfdi
                serie
                currency
                expedition_place
                folio
                cfdi_type
                payment_form
                payment_method
                logo_url
                date
                original_string
                sub_total
                total
                discount
                year_registro
                numero_semana_year
                numero_mes_year
                fecha_registro
                empresa
                sucursal
                id_venta
                folio_venta
                tipo
              }
              id_nota
            }
            precio_unidad {
              numero_precio
              precio_neto
              precio_venta
              iva_precio
              ieps_precio
              unidad_mayoreo
              utilidad
              precio_general
              cantidad_unidad
              unidad_maxima
            }
            subtotal_antes_de_impuestos
            precio_actual_object {
              cantidad_unidad
              numero_precio
              unidad_maxima
              unidad_mayoreo
              precio_general
              precio_neto
              precio_venta
              iva_precio
              ieps_precio
              utilidad
              porciento
              dinero_descontado
            }
            granel_producto {
              granel
              valor
            }
            precio
            precio_a_vender
            precio_actual_producto
            descuento_activo
            default
          }
          saldo_credito_pendiente
          status
          subtotal
          sucursal {
            _id
            nombre_sucursal
            descripcion
            usuario_sucursal
            id_usuario
            numero_usuario
            password_sucursal
            direccion {
              calle
              no_ext
              no_int
              codigo_postal
              colonia
              municipio
              localidad
              estado
              pais
            }
            id_empresa {
              _id
              nombre_empresa
              razon_social
              nombre_dueno
              id_tienda
              tienda
              telefono_dueno
              correo_empresa
              celular
              imagen
              nombre_fiscal
              rfc
              regimen_fiscal
              curp
              info_adicio
              valor_puntos
              direccion {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              direccionFiscal {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              datosBancarios {
                cuenta
                sucursal
                clave_banco
              }
              sucursales_activas
              limite_sucursales
              limite_timbres
              timbres_usados
              cuenta_empresa {
                efectivo
                bancario
              }
              sello_sat
              nombre_cer
              nombre_key
              fecha_registro_sello_sat
              vender_sin_inventario
              empresa_activa
              fecha_registro
            }
            cuenta_sucursal {
              efectivo
            }
            codigo_licencia
            fecha_inicio_licencia
            fecha_vencimiento_licencia
            licencia_activa
            fecha_registro
            createdAt
          }
          total
          usuario {
            _id
            username_login
            numero_usuario
            nombre
            turno_en_caja_activo
            password
            telefono
            celular
            email
            direccion {
              calle
              no_ext
              no_int
              codigo_postal
              colonia
              municipio
              localidad
              estado
              pais
            }
            imagen
            estado_usuario
            empresa {
              _id
              nombre_empresa
              razon_social
              nombre_dueno
              id_tienda
              tienda
              telefono_dueno
              correo_empresa
              celular
              imagen
              nombre_fiscal
              rfc
              regimen_fiscal
              curp
              info_adicio
              valor_puntos
              direccion {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              direccionFiscal {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              datosBancarios {
                cuenta
                sucursal
                clave_banco
              }
              sucursales_activas
              limite_sucursales
              limite_timbres
              timbres_usados
              cuenta_empresa {
                efectivo
                bancario
              }
              sello_sat
              nombre_cer
              nombre_key
              fecha_registro_sello_sat
              vender_sin_inventario
              empresa_activa
              fecha_registro
            }
            sucursal {
              _id
              nombre_sucursal
              descripcion
              usuario_sucursal
              id_usuario
              numero_usuario
              password_sucursal
              direccion {
                calle
                no_ext
                no_int
                codigo_postal
                colonia
                municipio
                localidad
                estado
                pais
              }
              id_empresa {
                _id
                nombre_empresa
                razon_social
                nombre_dueno
                id_tienda
                tienda
                telefono_dueno
                correo_empresa
                celular
                imagen
                nombre_fiscal
                rfc
                regimen_fiscal
                curp
                info_adicio
                valor_puntos
                sucursales_activas
                limite_sucursales
                limite_timbres
                timbres_usados
                sello_sat
                nombre_cer
                nombre_key
                fecha_registro_sello_sat
                vender_sin_inventario
                empresa_activa
                fecha_registro
              }
              cuenta_sucursal {
                efectivo
              }
              codigo_licencia
              fecha_inicio_licencia
              fecha_vencimiento_licencia
              licencia_activa
              fecha_registro
              createdAt
            }
            accesos {
              catalogos {
                clientes {
                  ver
                  agregar
                  editar
                  eliminar
                }
                usuarios {
                  ver
                  agregar
                  editar
                  eliminar
                }
                contabilidad {
                  ver
                  agregar
                  editar
                  eliminar
                }
                provedores {
                  ver
                  agregar
                  editar
                  eliminar
                }
                marcas {
                  ver
                  agregar
                  editar
                  eliminar
                }
                productos {
                  ver
                  agregar
                  editar
                  eliminar
                }
                tallas_numeros {
                  ver
                  agregar
                  editar
                  eliminar
                }
                cajas {
                  ver
                  agregar
                  editar
                  eliminar
                }
                departamentos {
                  ver
                  agregar
                  editar
                  eliminar
                }
                categorias {
                  ver
                  agregar
                  editar
                  eliminar
                }
                colores {
                  ver
                  agregar
                  editar
                  eliminar
                }
                centro_costos {
                  ver
                  agregar
                  editar
                  eliminar
                }
                conceptos_almacen {
                  ver
                  agregar
                  editar
                  eliminar
                }
              }
              mi_empresa {
                datos_empresa {
                  ver
                  agregar
                  editar
                  eliminar
                }
                informacion_fiscal {
                  ver
                  agregar
                  editar
                  eliminar
                }
              }
              compras {
                abrir_compra {
                  ver
                  agregar
                  editar
                  eliminar
                }
                compras_realizadas {
                  ver
                  agregar
                  editar
                  eliminar
                }
                compras_espera {
                  ver
                  agregar
                  editar
                  eliminar
                }
              }
              tesoreria {
                cuentas_empresa {
                  ver
                  agregar
                  editar
                  eliminar
                }
                egresos {
                  ver
                  agregar
                  editar
                  eliminar
                }
                abonos_proveedores {
                  ver
                  agregar
                  editar
                  eliminar
                }
                abonos_clientes {
                  ver
                  agregar
                  editar
                  eliminar
                }
                caja_principal {
                  ver
                  agregar
                  editar
                  eliminar
                }
              }
              reportes {
                reporte_historial_cajas {
                  ver
                  agregar
                  editar
                  eliminar
                }
                reporte_abonos {
                  ver
                  agregar
                  editar
                  eliminar
                }
                reporte_compras {
                  ver
                  agregar
                  editar
                  eliminar
                }
                reporte_ventas {
                  ver
                  agregar
                  editar
                  eliminar
                }
                rerporte_almacen {
                  ver
                  agregar
                  editar
                  eliminar
                }
                reporte_corte_caja {
                  ver
                  agregar
                  editar
                  eliminar
                }
                reporte_tesoreria {
                  ver
                  agregar
                  editar
                  eliminar
                }
              }
              ventas {
                cancelar_venta {
                  ver
                  agregar
                  editar
                  eliminar
                }
                precios_productos {
                  ver
                  agregar
                  editar
                  eliminar
                }
                pre_corte {
                  ver
                  agregar
                  editar
                  eliminar
                }
                cotizaciones {
                  ver
                  agregar
                  editar
                  eliminar
                }
                administrador {
                  ver
                  agregar
                  editar
                  eliminar
                }
                eliminar_ventas {
                  ver
                  agregar
                  editar
                  eliminar
                }
                producto_rapido {
                  ver
                  agregar
                  editar
                  eliminar
                }
              }
              almacenes {
                almacen {
                  ver
                  agregar
                  editar
                  eliminar
                }
                traspasos {
                  ver
                  agregar
                  editar
                  eliminar
                }
                inventario_almacen {
                  ver
                  agregar
                  editar
                  eliminar
                }
              }
              facturacion {
                generar_cdfi {
                  ver
                  agregar
                  editar
                  eliminar
                }
                cdfi_realizados {
                  ver
                  agregar
                  editar
                  eliminar
                }
                registro_series_cdfi {
                  ver
                  agregar
                  editar
                  eliminar
                }
              }
            }
            eliminado
          }
          year_registro
        }
      }
      totalDocs
    }
  }
`;

export const OBTENER_HISTORIAL_ABONOS = gql`
  query obtenerHistorialAbonos(
    $empresa: ID!
    $sucursal: ID
    $input: ObtenerHistorialAbonosInput
    $offset: Int
    $limit: Int
  ) {
    obtenerHistorialAbonos(
      empresa: $empresa
      sucursal: $sucursal
      input: $input
      offset: $offset
      limit: $limit
    ) {
      docs {
        _id
        numero_caja
        id_Caja
        folio_compra
        folio_venta
        horario_turno
        tipo_movimiento
        rol_movimiento
        fecha_movimiento {
          year
          mes
          dia
          no_semana_year
          no_dia_year
          completa
        }
        montos_en_caja {
          monto_efectivo {
            monto
            metodo_pago
          }
          monto_tarjeta_debito {
            monto
            metodo_pago
          }
          monto_tarjeta_credito {
            monto
            metodo_pago
          }
          monto_creditos {
            monto
            metodo_pago
          }
          monto_monedero {
            monto
            metodo_pago
          }
          monto_transferencia {
            monto
            metodo_pago
          }
          monto_cheques {
            monto
            metodo_pago
          }
          monto_vales_despensa {
            monto
            metodo_pago
          }
        }
        monto_total_abonado
        metodo_de_pago {
          clave
          metodo
        }
        saldo_credito_pendiente
        descuento {
          porciento_descuento
          dinero_descontado
          total_con_descuento
        }
        id_usuario
        numero_usuario_creador
        nombre_usuario_creador
        id_cliente
        numero_cliente
        nombre_cliente
        telefono_cliente
        email_cliente
        id_egreso
        provedor_egreso
        folio_egreso
        id_compra
        status
      }
      totalDocs
    }
  }
`;

export const OBTENER_CLIENTES_ESTADO_DE_CUENTA = gql`
  query getClientesEstadoCuenta(
    $empresa: ID!
    $filtro: String
    $limit: Int
    $offset: Int
  ) {
    getClientesEstadoCuenta(
      empresa: $empresa
      filtro: $filtro
      limit: $limit
      offset: $offset
    ) {
      docs {
        _id
        numero_cliente
        clave_cliente
        representante
        nombre_cliente
        rfc
        curp
        telefono
        celular
        email
        numero_descuento
        limite_credito
        dias_credito
        razon_social
        regimen_fiscal
        saldo_pendiente {
          total
        }
        direccion {
          calle
          no_ext
          no_int
          codigo_postal
          colonia
          municipio
          localidad
          estado
          pais
        }
        imagen
        estado_cliente
        tipo_cliente
        fecha_nacimiento
        fecha_registro
        eliminado
        banco
        numero_cuenta
        empresa {
          _id
          nombre_empresa
          razon_social
          nombre_dueno
          id_tienda
          tienda
          telefono_dueno
          correo_empresa
          celular
          imagen
          nombre_fiscal
          rfc
          regimen_fiscal
          curp
          info_adicio
          valor_puntos
          direccion {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          direccionFiscal {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          datosBancarios {
            cuenta
            sucursal
            clave_banco
          }
          sucursales_activas
          limite_sucursales
          limite_timbres
          timbres_usados
          cuenta_empresa {
            efectivo
            bancario
          }
          sello_sat
          nombre_cer
          nombre_key
          fecha_registro_sello_sat
          vender_sin_inventario
          empresa_activa
          fecha_registro
        }
        sucursal {
          _id
          nombre_sucursal
          descripcion
          usuario_sucursal
          id_usuario
          numero_usuario
          password_sucursal
          direccion {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          id_empresa {
            _id
            nombre_empresa
            razon_social
            nombre_dueno
            id_tienda
            tienda
            telefono_dueno
            correo_empresa
            celular
            imagen
            nombre_fiscal
            rfc
            regimen_fiscal
            curp
            info_adicio
            valor_puntos
            sucursales_activas
            limite_sucursales
            limite_timbres
            timbres_usados
            sello_sat
            nombre_cer
            nombre_key
            fecha_registro_sello_sat
            vender_sin_inventario
            empresa_activa
            fecha_registro
          }
          cuenta_sucursal {
            efectivo
          }
          codigo_licencia
          fecha_inicio_licencia
          fecha_vencimiento_licencia
          licencia_activa
          fecha_registro
          createdAt
        }
        monedero_electronico
        credito_disponible
      }
      totalDocs
    }
  }
`;

export const OBTENER_PROVEEDORES_ESTADO_DE_CUENTA = gql`
  query getProveedoresEstadoCuenta(
    $empresa: ID!
    $filtro: String
    $limit: Int
    $offset: Int
  ) {
    getProveedoresEstadoCuenta(
      empresa: $empresa
      filtro: $filtro
      limit: $limit
      offset: $offset
    ) {
      docs {
        _id
        numero_cliente
        clave_cliente
        representante
        nombre_cliente
        rfc
        curp
        telefono
        celular
        email
        numero_descuento
        limite_credito
        dias_credito
        razon_social
        regimen_fiscal
        saldo_pendiente {
          total
        }
        direccion {
          calle
          no_ext
          no_int
          codigo_postal
          colonia
          municipio
          localidad
          estado
          pais
        }
        imagen
        estado_cliente
        tipo_cliente
        fecha_nacimiento
        fecha_registro
        eliminado
        banco
        numero_cuenta
        empresa {
          _id
          nombre_empresa
          razon_social
          nombre_dueno
          id_tienda
          tienda
          telefono_dueno
          correo_empresa
          celular
          imagen
          nombre_fiscal
          rfc
          regimen_fiscal
          curp
          info_adicio
          valor_puntos
          direccion {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          direccionFiscal {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          datosBancarios {
            cuenta
            sucursal
            clave_banco
          }
          sucursales_activas
          limite_sucursales
          limite_timbres
          timbres_usados
          cuenta_empresa {
            efectivo
            bancario
          }
          sello_sat
          nombre_cer
          nombre_key
          fecha_registro_sello_sat
          vender_sin_inventario
          empresa_activa
          fecha_registro
        }
        sucursal {
          _id
          nombre_sucursal
          descripcion
          usuario_sucursal
          id_usuario
          numero_usuario
          password_sucursal
          direccion {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          id_empresa {
            _id
            nombre_empresa
            razon_social
            nombre_dueno
            id_tienda
            tienda
            telefono_dueno
            correo_empresa
            celular
            imagen
            nombre_fiscal
            rfc
            regimen_fiscal
            curp
            info_adicio
            valor_puntos
            sucursales_activas
            limite_sucursales
            limite_timbres
            timbres_usados
            sello_sat
            nombre_cer
            nombre_key
            fecha_registro_sello_sat
            vender_sin_inventario
            empresa_activa
            fecha_registro
          }
          cuenta_sucursal {
            efectivo
          }
          codigo_licencia
          fecha_inicio_licencia
          fecha_vencimiento_licencia
          licencia_activa
          fecha_registro
          createdAt
        }
        monedero_electronico
        credito_disponible
      }
      totalDocs
    }
  }
`;

export const OBTENER_VENTAS_ESTADO_DE_CUENTA = gql`
  query GetVentasEstadoCuenta(
    $cliente: ID!
    $filtro: getVentasEstadoCuentaInput
    $limit: Int
    $offset: Int
  ) {
    getVentasEstadoCuenta(
      cliente: $cliente
      filtro: $filtro
      limit: $limit
      offset: $offset
    ) {
      docs {
        _id
        folio
        ieps
        impuestos
        iva
        total_base
        total
        venta_cliente
        status
        tipo_emision
        forma_pago
        metodo_pago
        credito
        id_caja {
          _id
          numero_caja
          activa
        }
        fecha_de_vencimiento_credito
        dias_de_credito_venta
        saldo_credito_pendiente
        empresa
        sucursal
        fecha_registro
        abonos {
          _id
          numero_caja
          id_Caja
          folio_compra
          folio_venta
          horario_turno
          tipo_movimiento
          rol_movimiento
          fecha_movimiento {
            completa
          }
          monto_total_abonado
          metodo_de_pago {
            clave
            metodo
          }
          saldo_credito_pendiente
          descuento {
            porciento_descuento
            dinero_descontado
            total_con_descuento
          }
          id_usuario
          numero_usuario_creador
          nombre_usuario_creador
          id_cliente
          numero_cliente
          nombre_cliente
          telefono_cliente
          email_cliente
          id_egreso
          provedor_egreso
          id_compra
          status
        }
      }
      saldoPendienteTotal
      totalDocs
      totalVenta
    }
  }
`;

export const OBTENER_COMPRAS_ESTADO_DE_CUENTA = gql`
  query getComprasEstadoCuenta(
    $cliente: ID!
    $filtro: getVentasEstadoCuentaInput
    $limit: Int
    $offset: Int
  ) {
    getComprasEstadoCuenta(
      cliente: $cliente
      filtro: $filtro
      limit: $limit
      offset: $offset
    ) {
      docs {
        _id
        abonos {
          _id
          numero_caja
          id_Caja
          folio_compra
          folio_venta
          horario_turno
          tipo_movimiento
          rol_movimiento
          fecha_movimiento {
            year
            mes
            dia
            no_semana_year
            no_dia_year
            completa
          }
          montos_en_caja {
            monto_efectivo {
              monto
              metodo_pago
            }
            monto_tarjeta_debito {
              monto
              metodo_pago
            }
            monto_tarjeta_credito {
              monto
              metodo_pago
            }
            monto_creditos {
              monto
              metodo_pago
            }
            monto_monedero {
              monto
              metodo_pago
            }
            monto_transferencia {
              monto
              metodo_pago
            }
            monto_cheques {
              monto
              metodo_pago
            }
            monto_vales_despensa {
              monto
              metodo_pago
            }
          }
          monto_total_abonado
          metodo_de_pago {
            clave
            metodo
          }
          saldo_credito_pendiente
          descuento {
            porciento_descuento
            dinero_descontado
            total_con_descuento
          }
          id_usuario
          numero_usuario_creador
          nombre_usuario_creador
          id_cliente
          numero_cliente
          nombre_cliente
          telefono_cliente
          email_cliente
          id_egreso
          provedor_egreso
          folio_egreso
          id_compra
          status
        }
        fecha_registro
        fecha_vencimiento_credito
        folio
        metodo_de_pago {
          metodo
          clave
        }
        saldo_credito_pendiente
        status
        total
      }
      saldoPendienteTotal
      totalCompra
      totalDocs
    }
  }
`;

export const OBTENER_ABONOS_ESTADO_DE_CUENTA = gql`
  query getAbonosEstadoCuenta($id: ID!, $tipo: String) {
    getAbonosEstadoCuenta(id: $id, tipo: $tipo) {
      _id
      numero_caja
      id_Caja
      folio_compra
      folio_venta
      horario_turno
      tipo_movimiento
      rol_movimiento
      fecha_movimiento {
        year
        mes
        dia
        no_semana_year
        no_dia_year
        completa
      }
      montos_en_caja {
        monto_efectivo {
          monto
          metodo_pago
        }
        monto_tarjeta_debito {
          monto
          metodo_pago
        }
        monto_tarjeta_credito {
          monto
          metodo_pago
        }
        monto_creditos {
          monto
          metodo_pago
        }
        monto_monedero {
          monto
          metodo_pago
        }
        monto_transferencia {
          monto
          metodo_pago
        }
        monto_cheques {
          monto
          metodo_pago
        }
        monto_vales_despensa {
          monto
          metodo_pago
        }
      }
      monto_total_abonado
      metodo_de_pago {
        clave
        metodo
      }
      saldo_credito_pendiente
      descuento {
        porciento_descuento
        dinero_descontado
        total_con_descuento
      }
      id_usuario
      numero_usuario_creador
      nombre_usuario_creador
      id_cliente
      numero_cliente
      nombre_cliente
      telefono_cliente
      email_cliente
      id_egreso
      provedor_egreso
      folio_egreso
      id_compra
      status
    }
  }
`;

export const OBTENER_HISTORIAL_ABONOS_CLIENTE = gql`
  query historialVentasACredito(
    $empresa: ID!
    $sucursal: ID!
    $filtro: HistorialVentasCreditoInput
    $limit: Int
    $offset: Int
  ) {
    historialVentasACredito(
      empresa: $empresa
      sucursal: $sucursal
      filtro: $filtro
      limit: $limit
      offset: $offset
    ) {
      docs {
        _id
        folio
        facturacion
        estatus_credito
        cliente {
          _id
          numero_cliente
          clave_cliente
          representante
          nombre_cliente
          rfc
          curp
          telefono
          celular
          email
          numero_descuento
          limite_credito
          dias_credito
          razon_social
          regimen_fiscal
          direccion {
            calle
            no_ext
            no_int
            codigo_postal
            colonia
            municipio
            localidad
            estado
            pais
          }
          imagen
          estado_cliente
          tipo_cliente
          eliminado
          banco
          numero_cuenta
          monedero_electronico
          credito_disponible
          empresa {
            _id
          }
          sucursal {
            _id
          }
        }
        descuento
        ieps
        impuestos
        iva
        monedero
        subTotal
        total
        venta_cliente
        credito
        descuento_general_activo
        id_caja {
          _id
          numero_caja
          activa
        }
        fecha_de_vencimiento_credito
        dias_de_credito_venta
        saldo_credito_pendiente
        credito_pagado
        empresa
        sucursal
        usuario {
          _id
          numero_usuario
          nombre
        }
        productos {
          _id
          producto {
            _id
            datos_generales {
              codigo_barras
              clave_alterna
              tipo_producto
              nombre_comercial
              nombre_generico
              descripcion
              id_categoria
              categoria
              subcategoria
              id_subcategoria
              id_departamento
              departamento
              id_marca
              marca
              clave_producto_sat {
                Name
                Value
              }
              receta_farmacia
            }
            precios {
              ieps
              ieps_activo
              inventario {
                inventario_minimo
                inventario_maximo
                unidad_de_inventario
                codigo_unidad
              }
              iva
              iva_activo
              monedero
              monedero_electronico
              precio_de_compra {
                precio_con_impuesto
                precio_sin_impuesto
                iva
                ieps
              }
              precios_producto {
                numero_precio
                precio_neto
                precio_venta
                iva_precio
                ieps_precio
                unidad_mayoreo
                utilidad
                precio_general
                cantidad_unidad
                unidad_maxima
              }
              unidad_de_compra {
                cantidad
                precio_unitario_con_impuesto
                precio_unitario_sin_impuesto
                unidad
                codigo_unidad
              }
              granel
              litros
            }
            imagenes {
              url_imagen
              location_imagen
              key_imagen
              extencion_imagen
            }
            centro_de_costos {
              cuenta
              id_cuenta
              id_subcuenta
              subcuenta
            }
            precio_plazos {
              precio_cajas {
                plazo
                unidad
                codigo_unidad
                precio
              }
              precio_costales {
                plazo
                unidad
                codigo_unidad
                precio
              }
              precio_piezas {
                plazo
                unidad
                codigo_unidad
                precio
              }
            }
            unidades_de_venta {
              _id
              precio
              cantidad
              concepto
              unidad
              codigo_unidad
              unidad_principal
              codigo_barras
              codigo_barras
              id_producto
              empresa
              sucursal
              descuento {
                cantidad_unidad
                numero_precio
                unidad_maxima
                precio_general
                precio_neto
                precio_venta
                iva_precio
                ieps_precio
                utilidad
                porciento
                dinero_descontado
              }
              descuento_activo
              default
              precio_unidad {
                numero_precio
                precio_neto
                precio_venta
                iva_precio
                ieps_precio
                unidad_mayoreo
                utilidad
                precio_general
                cantidad_unidad
                unidad_maxima
              }
            }
            inventario_general {
              _id
              cantidad_existente
              unidad_inventario
              codigo_unidad
              cantidad_existente_maxima
              unidad_maxima
              id_almacen_general
              eliminado
            }
            medidas_producto {
              _id
              cantidad
              cantidad_nueva
              almacen
              codigo_barras
              color {
                hex
                nombre
                _id
              }
              descuento {
                cantidad_unidad
                numero_precio
                unidad_maxima
                precio_general
                precio_neto
                precio_venta
                iva_precio
                ieps_precio
                utilidad
                porciento
                dinero_descontado
              }
              descuento_activo
              existencia
              medida {
                talla
                tipo
                _id
              }
              nombre_comercial
              precio
              precio_unidad {
                numero_precio
                precio_neto
                precio_venta
                iva_precio
                ieps_precio
                unidad_mayoreo
                utilidad
                precio_general
                cantidad_unidad
                unidad_maxima
              }
            }
            empresa
            sucursal
          }
          concepto
          cantidad
          iva_total
          ieps_total
          subtotal
          impuestos
          total
          medida {
            id_medida
            medida
            tipo
          }
          color {
            id_color
            color
            hex
          }
          cantidad_venta
          granel_producto {
            granel
            valor
          }
          precio
          precio_a_vender
          precio_actual_producto
          default
          unidad
          codigo_unidad
          id_unidad_venta {
            _id
            precio
            cantidad
            concepto
            unidad
            codigo_unidad
            unidad_principal
            unidad_activa
            codigo_barras
            id_producto
            descuento {
              cantidad_unidad
              numero_precio
              unidad_maxima
              precio_general
              precio_neto
              precio_venta
              iva_precio
              ieps_precio
              utilidad
              porciento
              dinero_descontado
            }
            descuento_activo
            default
            precio_unidad {
              numero_precio
              precio_neto
              precio_venta
              iva_precio
              ieps_precio
              unidad_mayoreo
              utilidad
              precio_general
              cantidad_unidad
              unidad_maxima
            }
          }
          inventario_general {
            _id
            cantidad_existente
            unidad_inventario
            codigo_unidad
            cantidad_existente_maxima
            unidad_maxima
            id_almacen_general
            eliminado
          }
          year_registro
          fecha_registro
          precio_unidad {
            numero_precio
            precio_neto
            precio_venta
            iva_precio
            ieps_precio
            unidad_mayoreo
            utilidad
            precio_general
            cantidad_unidad
            unidad_maxima
          }
          precio_actual_object {
            cantidad_unidad
            numero_precio
            unidad_maxima
            unidad_mayoreo
            precio_general
            precio_neto
            precio_venta
            iva_precio
            ieps_precio
            utilidad
            porciento
            dinero_descontado
          }
        }
        fecha_registro
        factura {
          id_cfdi
          serie
          currency
          expedition_place
          folio
          cfdi_type
          payment_form
          payment_method
          logo_url
          date
          issuer {
            FiscalRegime
            Rfc
            TaxName
          }
          receiver {
            Rfc
            Name
            CfdiUse
          }
          items {
            ProductCode
            IdentificationNumber
            Description
            Unit
            UnitCode
            UnitPrice
            Quantity
            Subtotal
            Discount
            Taxes {
              Total
              Name
              Base
              Rate
              IsRetention
            }
            Total
          }
          original_string
          sub_total
          total
          discount
          fecha_registro
          empresa
          sucursal
        }
        montos_en_caja {
          monto_efectivo {
            monto
            metodo_pago
          }
          monto_tarjeta_debito {
            monto
            metodo_pago
          }
          monto_tarjeta_credito {
            monto
            metodo_pago
          }
          monto_creditos {
            monto
            metodo_pago
          }
          monto_monedero {
            monto
            metodo_pago
          }
          monto_transferencia {
            monto
            metodo_pago
          }
          monto_cheques {
            monto
            metodo_pago
          }
          monto_vales_despensa {
            monto
            metodo_pago
          }
        }
        status
        abonos {
          _id
          numero_caja
          status
          id_Caja
          horario_turno
          tipo_movimiento
          rol_movimiento
          fecha_movimiento {
            completa
          }
          saldo_credito_pendiente
          metodo_de_pago {
            clave
            metodo
          }
          numero_usuario_creador
          nombre_usuario_creador
          monto_total_abonado
          numero_cliente
          nombre_cliente
          telefono_cliente
          email_cliente
          montos_en_caja {
            monto_efectivo {
              monto
            }
            monto_tarjeta_credito {
              monto
            }
            monto_tarjeta_debito {
              monto
            }
            monto_creditos {
              monto
            }
            monto_monedero {
              monto
            }
            monto_transferencia {
              monto
            }
            monto_cheques {
              monto
            }
            monto_vales_despensa {
              monto
            }
          }
        }
      }
      totalDocs
    }
  }
`;

export const CREAR_ABONO = gql`
  mutation CrearAbono($empresa: ID!, $sucursal: ID!, $input: CrearAbonoInput) {
    crearAbono(empresa: $empresa, sucursal: $sucursal, input: $input) {
      message
    }
  }
`;

export const CREAR_ABONO_CLIENTE = gql`
  mutation crearAbonoVentaCredito(
    $empresa: ID!
    $sucursal: ID!
    $input: AbonoVentasCreditoInput
  ) {
    crearAbonoVentaCredito(
      empresa: $empresa
      sucursal: $sucursal
      input: $input
    ) {
      message
      pdf
      xml
      success
    }
  }
`;

export const CANCELAR_ABONO_CLIENTE = gql`
  mutation cancelarAbonoCliente(
    $empresa: ID!
    $sucursal: ID!
    $input: CancelarAbonoInput
  ) {
    cancelarAbonoCliente(
      empresa: $empresa
      sucursal: $sucursal
      input: $input
    ) {
      message
    }
  }
`;

export const CANCELAR_ABONO_PROVEEDOR = gql`
  mutation cancelarAbonoProveedor(
    $empresa: ID!
    $sucursal: ID!
    $input: CancelarAbonoProveedorInput
  ) {
    cancelarAbonoProveedor(
      empresa: $empresa
      sucursal: $sucursal
      input: $input
    ) {
      message
    }
  }
`;
