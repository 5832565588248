import React, { useContext, useState } from "react";
import moment from "moment-timezone";
import { verificarDatosFactura } from "./validacion_factura";
import { getAddressCodes, complement_initial_state } from "../NuevaFactura/initial_factura_states";
import { FacturacionCtx } from "../../../../context/Facturacion/facturacionCtx";
import { CREAR_FACTURA } from "../../../../gql/Facturacion/Facturacion";
import { useMutation } from "@apollo/client";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { Button, CircularProgress } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { verificarDatosCartaP } from "./validacion_carta";
import { factura_initial_state } from "../NuevaFactura/initial_factura_states";

export default function RealizarFactura({ setAlert }) {
  const { isOnline } = useContext(AccesosContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = useState(false);
  const {
    datosFactura,
    setDatosFactura,
    codigo_postal,
    setProductos,
    venta_factura,
    setVentaFactura,
    setError,
    setIsCfdiRelated,
    complementCartaPorte,
    setComplementCartaPorte,
  } = useContext(FacturacionCtx);
  const [CrearFactura] = useMutation(CREAR_FACTURA);

  const limpiarCampos = (folio = "", serie = "") => {
    try {
      setDatosFactura({ ...factura_initial_state, folio, serie });
      setComplementCartaPorte(complement_initial_state);
      setVentaFactura(null);
      setProductos([]);
      setIsCfdiRelated(false);
    } catch (error) {
      console.log(error);
    }
  };

  const crearComplementoCartaPorte = async () => {
    try {
      if (!venta_factura) return;
      setLoading(true);
      let CfdiObj = { ...datosFactura };
      let CompObj = { ...complementCartaPorte };

      CfdiObj.cfdi_type = "T";
      CfdiObj.expedition_place = codigo_postal;
      CfdiObj.id_venta = venta_factura._id;
      CfdiObj.folio_venta = venta_factura.folio;
      CfdiObj.receiver = {
        FiscalRegime: CfdiObj.issuer.FiscalRegime,
        Rfc: CfdiObj.issuer.Rfc,
        Name: CfdiObj.issuer.Name,
        TaxZipCode: codigo_postal,
        CfdiUse: "S01",
      };

      delete CfdiObj.payment_form;
      delete CfdiObj.payment_method;
      delete CfdiObj.periodicity;

      //VALIDAR Cfdi y Carta Porte
      const validateCompObj = verificarDatosCartaP(
        CompObj.Complemento.CartaPorte30
      );
      const validateCfdiObj = verificarDatosFactura(CfdiObj);

      if (validateCompObj.error) {
        setAlert({
          message: validateCompObj.message,
          status: true,
          open: true,
        });
        setLoading(false);
        return;
      }
      if (validateCfdiObj) {
        setError({ status: true, message: validateCfdiObj.message });
        setLoading(false);
        return;
      }

      //poner la fecha de facturacion
      if (datosFactura.date === "1") {
        CfdiObj.date = moment()
          .tz("America/Mexico_City")
          .subtract(1, "d")
          .format("YYYY-MM-DDTHH:mm:ss");
      } else if (datosFactura.date === "2") {
        CfdiObj.date = moment()
          .tz("America/Mexico_City")
          .subtract(2, "d")
          .format("YYYY-MM-DDTHH:mm:ss");
      } else {
        CfdiObj.date = moment()
          .tz("America/Mexico_City")
          .format("YYYY-MM-DDTHH:mm:ss");
      }

      const Address = await getAddressCodes(sesion.empresa.direccion);
      CompObj.Complemento.CartaPorte30.Ubicaciones[0].Domicilio = Address;
      CfdiObj = { ...CfdiObj, ...CompObj };

      //console.log(CfdiObj);
      /* setLoading(false);
      return; */

      await CrearFactura({
        variables: {
          input: CfdiObj,
        },
      });

      //console.log(result);

      setLoading(false);
      setAlert({
        message: `¡Listo! `,
        status: "success",
        open: true,
      });
      const folio = (parseFloat(CfdiObj.folio) + 1).toString();
      const serie = CfdiObj.serie;
      limpiarCampos(folio, serie);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message) {
        setAlert({
          message: error.message,
          status: "error",
          open: true,
        });
      } else if (error.networkError) {
        console.log(error.networkError.result);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors);
      }
    }
  };

  return (
    <Button
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : <Done />
      }
      onClick={() => crearComplementoCartaPorte()}
      variant="contained"
      disableElevation
      style={{ textTransform: "none" }}
      color="primary"
      disabled={
        loading ||
        !venta_factura ||
        !isOnline ||
        !datosFactura.issuer.Rfc ||
        !datosFactura.issuer.FiscalRegime
      }
    >
      Guardar
    </Button>
  );
}
