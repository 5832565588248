import React, { useCallback, useContext, useEffect } from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  ACTUALIZAR_SUCURSAL,
  GET_SUCURSAL_CLOUD,
} from "../../gql/Empresa/sucursales";
import { AccesosContext } from "../../context/Accesos/accesosCtx";
import {
  DesktopAccessDisabled,
  PowerSettingsNew,
  Refresh,
} from "@material-ui/icons";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import NotServerConected from "../Connection/NotServerConected";
import { MainContext } from "../../context/MainCtx";

function LicenceVerificate() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { setSucursalData, sucursalData } = useContext(AccesosContext);
  const [networkError, setNetworkError] = React.useState("");
  const client = useApolloClient();
  const sucursalID = sesion ? sesion.sucursal._id : null;
  const navigate = useNavigate();

  const getSucursal = useCallback(async () => {
    try {
      const response = await client.query({
        query: GET_SUCURSAL_CLOUD,
        variables: { id: sucursalID },
        fetchPolicy: "network-only",
      });
      if (response.data && response.data.getSucursalCloud)
        setSucursalData(response.data.getSucursalCloud);
    } catch (error) {
      console.log(error);
      if(error.networkError && error.networkError.message){
        setNetworkError(error.networkError.message)
      }
    }
  }, [client, sucursalID, setSucursalData]);

  useEffect(() => {
    getSucursal();
  }, [getSucursal]);

  useEffect(() => {
    if (!sesion) {
      navigate("/");
    }
  }, [sesion, navigate]);

  if (!sesion) return null;

  if(networkError){
    return <NotServerConected />
  }

  if (!sucursalData) return null;

  return <IntervalVerificator sucursalID={sucursalID} />;
}

const IntervalVerificator = ({ sucursalID }) => {
  /* 
  status codes
    [0] OK
    [1] TO_EXPIRE
    [2] EXPIRED
  */

  const {
    sucursalData,
    setStatus,
    status,
    status_codes,
    setDiasRestantes,
  } = useContext(AccesosContext);
  const { turnoEnCurso } = React.useContext(MainContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const date_expire = sucursalData
    ? sucursalData.fecha_vencimiento_licencia
    : "";

  const [editarSucursal] = useMutation(ACTUALIZAR_SUCURSAL);

  const desactivarLicencia = useCallback(async () => {
    try {
      const res = await editarSucursal({
        variables: {
          id: sucursalID,
          input: {
            licencia_activa: false,
          },
        },
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  }, [sucursalID]);

  const verifyLicence = () => {
    let date_now = moment().tz("America/Mexico_City").format();
    const date_expire_alert = moment(date_expire).subtract(5, "days").format();

    const dias_restantes = moment(date_expire).diff(
      moment().tz("America/Mexico_City"),
      "days"
    );
    setDiasRestantes(dias_restantes);
    if (date_now >= date_expire_alert) {
      //entra a alerta de expiracion
      if (date_now >= date_expire) {
        //ya vencio
        setStatus(status_codes[2]);
        if (sucursalData.licencia_activa) desactivarLicencia();
      } else {
        //ya esta proximo a vencer
        setStatus(status_codes[1]);
      }
    } else {
      //esta sin novedad
      setStatus(status_codes[0]);
    }
  };

  useEffect(() => {
    verifyLicence();
    setInterval(() => {
      verifyLicence();
    }, 3600000);
  }, [date_expire, setStatus, status_codes]);

  const signOut = () => {
    localStorage.removeItem("sesionCafi");
    localStorage.removeItem("tokenCafi");
    localStorage.removeItem("ListaEnEspera");
    window.location.reload();
  };

  //licencia_activa: Boolean
  //fecha_vencimiento_licencia
  if (status === status_codes[2]) {
    return (
      <Backdrop
        open={true}
        style={{
          zIndex: 999999,
          color: "white",
        }}
      >
        <Box
          style={{
            borderRadius: 10,
            backgroundColor: "rgb(0,0,0, 0.7)",
            padding: "50px",
          }}
        >
          <Box display="flex" alignItems="center">
            <DesktopAccessDisabled style={{ fontSize: 50 }} />
            <Box ml={2}>
              <Typography variant="h6">Tu licencia ha expirado</Typography>
              <Typography variant="h6">
                Adquiere una nueva licencia para seguir usando CAFI punto de
                venta
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              startIcon={<Refresh />}
              variant="outlined"
              color="inherit"
              onClick={() => window.location.reload()}
            >
              Recargar ventana
            </Button>
            <Box mx={2} />
            {sesion.turno_en_caja_activo === true && turnoEnCurso ? null : (
              <Button
                startIcon={<PowerSettingsNew />}
                variant="outlined"
                color="inherit"
                onClick={() => signOut()}
              >
                Salir del sistema
              </Button>
            )}
          </Box>
        </Box>
      </Backdrop>
    );
  } else {
    return null;
  }
};

export default LicenceVerificate;
