import React from "react";
import { RegProductoProvider } from "../../context/Catalogos/CtxRegProducto";
import AdminInicio from "../../pages/sucursales/AdminInicio";
import LicenceVerificate from "../VerificateLicence/LicenceVerificate";
import NavegacionAdmin from "../Navegaciones/NavegacionAdmin";
import { useNavigate } from "react-router-dom";
import { VentasProvider } from "../../context/Ventas/ventasContext";

export default function LayoutAdmin() {
  const sesion = localStorage.getItem("sesionCafi");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!sesion) {
      navigate("/");
    }
  }, [sesion, navigate]);

  if (!sesion) return null;

  return (
    <div>
      <VentasProvider>
        <RegProductoProvider>
          <LicenceVerificate />
          <NavegacionAdmin />
          <AdminInicio />
        </RegProductoProvider>
      </VentasProvider>
    </div>
  );
}
