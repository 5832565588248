import React, { Fragment, useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles, TablePagination } from "@material-ui/core";
import { ArrowBack, Error, Search } from "@material-ui/icons";
import ErrorPage from "../../../../../components/ErrorPage";
import { useQuery } from "@apollo/client";
import { OBTENER_CLIENTES } from "../../../../../gql/Catalogos/clientes";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx";
import CrearCliente from "../../../Catalogos/Cliente/CrearCliente";
import { Alert } from "@material-ui/lab";
import { useDebounce } from "use-debounce";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    height: "45vh",
  },
}));

export default function ListaClientesFacturas() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [open, setOpen] = useState(false);
  const [openAlertRfc, setOpenAlertRfc] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [page, setPage] = React.useState(0);
  const [value] = useDebounce(filtro, 500);
  const { isPublicoGeneral } = useContext(FacturacionCtx);
  const limit = 20;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseAlertRFC = () => {
    setOpenAlertRfc(false);
  };
  const openAlertRFC = () => {
    setOpenAlertRfc(true);
  };

  /* Queries */
  const resultado_clientes = useQuery(OBTENER_CLIENTES, {
    variables: {
      tipo: "CLIENTE",
      filtros: { cliente: value },
      empresa: sesion.empresa._id,
      eliminado: false,
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  return (
    <div>
      <IconButton
        onClick={() => handleOpen()}
        size="small"
        disabled={isPublicoGeneral}
      >
        <Search />
      </IconButton>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gridGap={10}>
            <IconButton color="primary" onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Selecionar cliente</Typography>
            <Box flexGrow={1} />
            <TextField
              fullWidth
              size="small"
              placeholder="Buscar por: Numero de cliente, clave o nombre"
              variant="outlined"
              onChange={(e) => setFiltro(e.target.value)}
              value={filtro}
              style={{ width: 360 }}
            />
            <CrearCliente
              tipo="CLIENTE"
              accion="registrar"
              refetch={resultado_clientes.refetch}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openAlertRfc}
            onClose={handleCloseAlertRFC}
            message="El cliente debe de tener RFC, RAZÓN SOCIAL y Régimen fiscal."
            autoHideDuration={2000}
          />
          <Box mb={1}>
            <Alert severity="info">
              Para seleccionar un cliente haz un doble click!
            </Alert>
          </Box>
          <RenderListClientes
            handleClose={handleClose}
            resultado_clientes={resultado_clientes}
            openAlertRFC={openAlertRFC}
            page={page}
            setPage={setPage}
            limit={limit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const RenderListClientes = ({
  handleClose,
  resultado_clientes,
  openAlertRFC,
  page,
  setPage,
  limit,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const { datosFactura, setDatosFactura } = useContext(FacturacionCtx);

  const { loading, data, error, refetch } = resultado_clientes;

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) {
    return <ErrorPage />;
  }

  const { obtenerClientes } = data;

  const obtenerClienteTabla = (click, row) => {
    setSelected(row.nombre_cliente);
    if (click === 2) {
      if (!row.rfc || !row.regimen_fiscal || !row.razon_social) {
        openAlertRFC();
        return;
      }
      setDatosFactura({
        ...datosFactura,
        receiver: {
          ...datosFactura.receiver,
          Name: row.nombre_cliente,
          Rfc: row.rfc,
          FiscalRegime: row.regimen_fiscal,
          TaxZipCode: row.direccion.codigo_postal,
          Address: {
            Street: row.direccion.calle,
            ExteriorNumber: row.direccion.no_ext,
            InteriorNumber: row.direccion.no_int,
            Neighborhood: row.direccion.colonia,
            ZipCode: row.direccion.codigo_postal,
            Municipality: row.direccion.municipio,
            State: row.direccion.estado,
            Country: row.direccion.pais,
          },
        },
      });
      handleClose();
    }
  };

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <Paper variant="outlined">
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell minwidth="100">No. Cliente</TableCell>
              <TableCell minwidth="100">Clave</TableCell>
              <TableCell minwidth="150">Nombre</TableCell>
              <TableCell minwidth="150">Razon Social</TableCell>
              <TableCell minwidth="150">RFC</TableCell>
              <TableCell minwidth="150">Régimen fiscal</TableCell>
              <TableCell minwidth="150">Editar Cliente</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {obtenerClientes.docs.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  onClick={(e) => obtenerClienteTabla(e.detail, row)}
                  selected={row.nombre_cliente === selected}
                >
                  <TableCell>{row.numero_cliente}</TableCell>
                  <TableCell>{row.clave_cliente}</TableCell>
                  <TableCell>{row.nombre_cliente}</TableCell>
                  <TableCell>
                    {row.razon_social ? (
                      row.razon_social
                    ) : (
                      <Error color="action" />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.rfc ? row.rfc : <Error color="action" />}
                  </TableCell>
                  <TableCell>
                    {row.regimen_fiscal ? (
                      row.regimen_fiscal
                    ) : (
                      <Error color="action" />
                    )}
                  </TableCell>
                  <TableCell>
                    <CrearCliente
                      tipo="CLIENTE"
                      accion="actualizar"
                      datos={row}
                      refetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={obtenerClientes.totalDocs}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};
