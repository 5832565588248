import React, { Fragment } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import EliminarProductoVenta from "../EliminarProductoVenta";
import { formatoMexico } from "../../../config/reuserFunctions";
import EliminarProductoNota from "../EliminarProductoNota";
import { IconButton, Typography, makeStyles } from "@material-ui/core";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import SetPrecioProducto from "../Operaciones/SetPrecioProducto";
import { RiScales2Fill } from "react-icons/ri";
import { blueGrey } from "@material-ui/core/colors";
import QuantityInput from "../../venta_rapida/TablaComponent/CuantityInput";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: 0, // Puedes ajustar esto según tus necesidades
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 8px 8px 8px",
    },
  },
  tableRow: {
    "& .MuiTableCell-root ": {
      borderColor: blueGrey[50],
    },
  },
}));

export default function TableRowsArticulos({
  producto,
  datosVentas,
  index,
  calculateWeight,
}) {
  const { setDatosVentasActual } = React.useContext(VentasContext);
  const classes = useStyles();

  const name = () => {
    const { nombre_comercial } = producto.id_producto.datos_generales;
    const { color, medida } = producto;

    if (color || medida) {
      if ((color.nombre || color.color) && (medida.talla || medida.medida)) {
        return `${nombre_comercial} Talla ${medida.talla || medida.medida} ${
          color.nombre || color.color
        }`;
      } else {
        return nombre_comercial;
      }
    } else {
      return nombre_comercial;
    }
  };

  return (
    <Fragment>
      <TableRow
        role="checkbox"
        style={{ padding: 0, margin: 0 }}
        tabIndex={-1}
        key={producto._id}
        className={classes.tableRow}
      >
        <TableCell padding="checkbox">
          <SetPrecioProducto producto={producto} />
        </TableCell>
        <TableCell style={{ padding: 0 }}>
          <Typography noWrap variant="body2">
            {name()}
          </Typography>
        </TableCell>
        {datosVentas && datosVentas.nota_credito ? (
          <TableCell style={{ textAlign: "center", padding: 0 }}>
            {producto.cantidad_venta_original}
          </TableCell>
        ) : null}
        <TableCell padding="checkbox" align="right">
          {producto.unidad.toUpperCase() === "KG" ? (
            <IconButton color="default" onClick={calculateWeight} size="small">
              <RiScales2Fill />
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell align="right">
          <QuantityInput producto={producto} withUnidad />
        </TableCell>
        <TableCell align="right">
          -{formatoMexico(producto.precio_actual_object?.porciento, 0)}%
        </TableCell>
        <TableCell align="right">
          ${formatoMexico(producto.precio_actual_producto)}
        </TableCell>
        <TableCell align="right">
          $
          {producto.total_total_producto
            ? formatoMexico(producto.total_total_producto)
            : 0}
        </TableCell>
        <TableCell>
          {datosVentas && datosVentas.nota_credito ? (
            <EliminarProductoNota
              producto={producto}
              setDatosVentasActual={setDatosVentasActual}
              index={index}
            />
          ) : (
            <EliminarProductoVenta
              producto={producto}
              setDatosVentasActual={setDatosVentasActual}
            />
          )}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
