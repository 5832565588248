import React from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Slide from "@material-ui/core/Slide";
import { ArrowBack } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ProductosSinClaveSat({ productos, open, handleClose }) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gridGap={10}>
            <IconButton color="primary" onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">
              Productos sin CLAVE del catalogo SAT
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Hay productos sin clave de catalogo de productos del sat, sin esta
            clave no puede realizar facturas
          </DialogContentText>
          <TableContainer style={{ maxHeight: "40vh" }}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Código de barras</TableCell>
                  <TableCell>Clave alterna</TableCell>
                  <TableCell>Producto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productos.map((row, index) => {
                  const { datos_generales } = row.id_producto;
                  return (
                    <TableRow key={index} role="checkbox" tabIndex={-1}>
                      <TableCell>
                        <Typography>{datos_generales.codigo_barras}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{datos_generales.clave_alterna}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {datos_generales.nombre_comercial}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}
