import React, {useState} from 'react';
import {
    Button,
    Box 
  } from "@material-ui/core";
import { RiFileExcel2Line } from "react-icons/ri";
import ImportXlsButtonMenu from "./ImportXlsButtonMenu";

function ImportXlsButton() {
    const [openMenu, setOpenMenu] = useState(null);
    const handleClick = (event) => {
        setOpenMenu(event.currentTarget);
      };
    return (
        <Box display="flex" alignItems={"center"}>
            <Button
                variant="text"
                color="primary"
                startIcon={<RiFileExcel2Line />}
                onClick ={handleClick}
            >
                Importar
            </Button> 
            <ImportXlsButtonMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
        </Box>
    )
}

export default ImportXlsButton