import { Button } from "@material-ui/core";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { RiFileExcel2Line } from "react-icons/ri";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";
import ExcelExportButton from "../../../../components/ExcelExportButton";

export default function ExportExcel({ historialCortes, refetch }) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    setValue(value);

    if (!value) {
      refetch({
        limit: 20,
        offset: 0,
      });
    } else {
      refetch({
        limit: 0,
        offset: 0,
      });
    }
  };
  return (
    <div>
      <Button
        variant="text"
        color="primary"
        startIcon={<RiFileExcel2Line />}
        onClick={handleClickOpen}
      >
        Exportar Excel
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>{"Exportar Reporte de cortes de caja"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            -Exportar pagina actual: exportará la pagina actual
          </DialogContentText>
          <DialogContentText>
            -Exportar todo: exportará todos los registros filtrados
          </DialogContentText>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={value}
              onChange={(e) => handleChange(e.target.value)}
            >
              <MenuItem value={0}>Contenido Actual</MenuItem>
              <MenuItem value={1}>Todos los registros</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <ExportarExcelAction datosExcel={historialCortes.docs} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ExportarExcelAction = ({ datosExcel }) => {
  const cortesCajaData = datosExcel.map((cortes) => {
    const cortes_caja = {
      fecha: moment(cortes.fecha_salida.completa)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY"),
      hora_movimiento: cortes.hora_salida.completa,
      horario_turno: cortes.horario_en_turno,
      sucursal: cortes.sucursal.nombre_sucursal,
      nombre: cortes.usuario_en_turno.nombre,
      numero_usuario: cortes.usuario_en_turno.numero_usuario,
      concepto: "Corte Caja",
      caja: cortes.numero_caja,
      monto_efectivo: cortes.montos_en_caja.monto_efectivo.monto,
      monto_creditos: cortes.montos_en_caja.monto_creditos.monto,
      monto_tarjeta_debito: cortes.montos_en_caja.monto_tarjeta_debito.monto,
      monto_tarjeta_credito: cortes.montos_en_caja.monto_tarjeta_credito.monto,
      monto_monedero: cortes.montos_en_caja.monto_monedero.monto,
      monto_cheques: cortes.montos_en_caja.monto_cheques.monto,
      monto_transferencia: cortes.montos_en_caja.monto_transferencia.monto,
      monto_vales_despensa: cortes.montos_en_caja.monto_vales_despensa.monto,
    };

    return cortes_caja;
  });

  return (
    <>
      <ExcelExportButton
        data={cortesCajaData}
        name="Reporte de Historial de Cortes"
        button
      />
    </>
  );
};
