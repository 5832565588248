import React from "react";
import { Box, makeStyles, Divider, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import cafiLogo from "../../../../img/logo_cafi_vertical.png";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../config/reuserFunctions";
import { grey } from "@material-ui/core/colors";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      fontSize: 13,
      padding: "2px 10px",
    },
  },
  tableFooter: {
    "& .MuiTableCell-root": {
      padding: "0px 10px",
      border: "none",
    },
    "& .headertop": {
      //borderTop: "2px solid",
      borderColor: grey[600],
      paddingTop: theme.spacing(1),
    },
  },
  showTerminos: {
    display: "none",
    [`@media print`]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: 10,
    },
  },
  hiddenEditable: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 10,
    [`@media print`]: {
      display: "none",
    },
  },
  body: {
    [`@media print`]: {
      minHeight: "26cm",
      display: "flex",
      flexDirection: "column",
    },
  },
}));

export default function ContentDocument({ cotizacion, refcomp }) {
  const classes = useStyles();
  const [terminos, setTerminos] = React.useState("");

  return (
    <Box ref={refcomp} p={4} className={classes.body}>
      <HeaderDoc cotizacion={cotizacion} />
      <VentaDoc cotizacion={cotizacion} />
      <Box flexGrow={1} />
      <TerminosEditable terminos={terminos} setTerminos={setTerminos} />
      <Terminos terminos={terminos} />
      <FooterPage />
    </Box>
  );
}

const HeaderDoc = ({ cotizacion }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  // obtener direccion
  let address = "";
  let municipio = "";
  let pais = "";
  const { direccion } = sesion.sucursal;
  if (
    direccion &&
    direccion.calle &&
    direccion.no_ext &&
    direccion.municipio &&
    direccion.estado &&
    direccion.pais
  ) {
    address = `${direccion.calle} #${direccion.no_ext}`;
    municipio = `${direccion.municipio}, ${direccion.estado}`;
    pais = direccion.pais;
  }

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography style={{ fontSize: "22pt" }}>
            <b>Cotización</b>
          </Typography>
        </Box>
        <Box mx={3}>
          <Typography style={{ fontSize: "12pt" }} align="center">
            <b>Folio</b>
          </Typography>
          <Typography style={{ fontSize: "12pt" }} align="center">
            {cotizacion.folio}
          </Typography>
        </Box>
        <Box mx={3}>
          <Typography style={{ fontSize: "12pt" }} align="center">
            <b>Fecha</b>
          </Typography>
          <Typography style={{ fontSize: "12pt" }} align="center">
            {formatoFechaCorta(cotizacion.fecha_registro)}
          </Typography>
        </Box>
        <Box mx={3}>
          <Typography style={{ fontSize: "12pt" }} align="center">
            <b>Vigencia</b>
          </Typography>
          <Typography style={{ fontSize: "12pt" }} align="center">
            {formatoFechaCorta(cotizacion.fecha_vencimiento_cotizacion)}
          </Typography>
        </Box>
      </Box>
      <Divider style={{ margin: "4px 0", height: "2px" }} />
      <Box display="flex" gridGap={10} mb={1}>
        <Box
          style={{
            width: 150,
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={sesion.empresa.imagen ? sesion.empresa.imagen : cafiLogo}
            alt="cafi punto de venta"
            style={{ maxWidth: "100%", minWidth: "100%" }}
          />
        </Box>
        <Box flexGrow={1}>
          <Typography style={{ fontSize: "10pt" }}>
            <b>{`${sesion.empresa.nombre_empresa} • Sucursal ${sesion.sucursal.nombre_sucursal}`}</b>
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Dirección: </b>
            {` ${address}, ${municipio}, ${pais}`}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>RFC: </b> {sesion.empresa.rfc}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Tel: </b> {sesion.empresa.telefono_dueno}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Vendedor: </b> {sesion.nombre}
          </Typography>
        </Box>
        <ClienteDoc cotizacion={cotizacion} />
      </Box>
    </>
  );
};
const ClienteDoc = ({ cotizacion }) => {
  if (!cotizacion.cliente?._id) {
    return (
      <Box>
        <Typography variant="body2">Público General</Typography>
      </Box>
    );
  }

  const { direccion } = cotizacion.cliente;

  return (
    <Box>
      <Box>
        <Typography style={{ fontSize: "10pt" }} align="right">
          <b>{cotizacion.cliente?.nombre_cliente}</b>
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {`${direccion?.calle} #${direccion?.no_ext} col. ${direccion?.colonia} CP${direccion?.codigo_postal}`}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {`${direccion?.municipio}, ${direccion?.estado}, ${direccion?.pais}`}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {cotizacion.cliente?.telefono}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {cotizacion.cliente?.email}
        </Typography>
      </Box>
    </Box>
  );
};
const VentaDoc = ({ cotizacion }) => {
  const classes = useStyles();
  const items = cotizacion?.productos || [];

  const name = (item) => {
    const { nombre_comercial } = item.id_producto.datos_generales;
    const { concepto, color, medida } = item;

    if (concepto === "medidas") {
      if (color.nombre && medida.talla) {
        return `${nombre_comercial} T${medida.talla} ${color.nombre}`;
      } else {
        return nombre_comercial;
      }
    } else {
      return nombre_comercial;
    }
  };

  const descuento = (item) => {
    //`$${formatoMexico(dinero_descontado)} - %${porciento}`
    if (item.descuento_general_activo === true) {
      const { dinero_descontado, porciento } = item.descuento_general;
      return `$${formatoMexico(dinero_descontado)} - %${porciento}`;
    } else {
      return "$0.00";
    }
  };

  return (
    <>
      <Divider style={{ margin: "4px 0", height: "2px" }} />
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>PRODUCTO/SERVICIO</TableCell>
              <TableCell align="right">CANT</TableCell>
              <TableCell align="right">UNITARIO</TableCell>
              <TableCell align="right">DESCUENTO</TableCell>
              <TableCell align="right">IMPORTE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{name(item)}</TableCell>
                  <TableCell align="right">{`${item.cantidad_venta} ${item.unidad}`}</TableCell>
                  <TableCell align="right">
                    ${formatoMexico(item.precio_a_vender)}
                  </TableCell>
                  <TableCell align="right">{descuento(item)}</TableCell>
                  <TableCell align="right">
                    ${formatoMexico(item.total_total_producto)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow className={classes.tableFooter}>
              <TableCell className="headertop" colSpan={3} />
              <TableCell className="headertop" colSpan={1} align="right">
                <b>SUBTOTAL</b>
              </TableCell>
              <TableCell className="headertop" align="right">
                ${formatoMexico(cotizacion.subTotal)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableFooter}>
              <TableCell colSpan={3} />
              <TableCell align="right">
                <b>DESCUENTO</b>
              </TableCell>
              <TableCell align="right">
                ${formatoMexico(cotizacion.descuento)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableFooter}>
              <TableCell colSpan={3} />
              <TableCell align="right">
                <b>TOTAL</b>
              </TableCell>
              <TableCell align="right">
                ${formatoMexico(cotizacion.total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
const TerminosEditable = ({ terminos, setTerminos }) => {
  const classes = useStyles();

  return (
    <Box className={classes.hiddenEditable}>
      <Box>
        <Typography>Términos y condiciones</Typography>
        <TextField
          size="small"
          variant="outlined"
          fullWidth
          multiline
          rows={3}
          value={terminos}
          onChange={(e) => setTerminos(e.target.value)}
        />
      </Box>
      <Box display="flex" alignItems="flex-end" justifyContent="center">
        <Box textAlign="center">
          <Typography>
            ________________________________________________
          </Typography>
          <Typography>Firma</Typography>
        </Box>
      </Box>
    </Box>
  );
};
const Terminos = ({ terminos }) => {
  const classes = useStyles();

  return (
    <Box className={classes.showTerminos} mt={5}>
      <Box>
        <Typography>Términos y condiciones</Typography>
        <Typography>{terminos}</Typography>
      </Box>
      <Box display="flex" alignItems="flex-end" justifyContent="center">
        <Box textAlign="center">
          <Typography>
            ________________________________________________
          </Typography>
          <Typography>Firma</Typography>
        </Box>
      </Box>
    </Box>
  );
};
const FooterPage = () => {
  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      <Typography align="center" variant="caption" color="textSecondary">
        Creado desde CAFI punto de venta
      </Typography>
      <Typography align="right" variant="caption" color="textSecondary">
        {moment().tz("America/Mexico_City").format("lll")}
      </Typography>
    </Box>
  );
};
