import { Box, Button, Dialog, Slide, makeStyles } from "@material-ui/core";
import React from "react";
import BackdropComponent from "../../../../../components/Layouts/BackDrop";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { OBTENER_CAJAS } from "../../../../../gql/Cajas/cajas";
import { useEffect } from "react";
import SnackBarMessages from "../../../../../components/SnackBarMessages";
import TabsReportesCajas from "./TabsReportesCajas";
import FiltrosReportesCajas from "../ReportesHistorialCajas/FiltrosReportesCajas";
import { FcTimeline } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 100,
  },
  paper: {
    alignItems: "flex-end",
  },
  fullScreen: {
    height: "100vh",
  },
  root: {
    width: "100%",
  },
  container: {
    height: "70vh",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  rootBusqueda: {
    display: "flex",
    paddingLeft: theme.spacing(2),
  },
  tipo_movimiento: {
    position: "sticky",
    left: 0,
    backgroundColor: "#fff",
    // height: "70vh",
    fontFamily: "Cooperative",
  },
}));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Cajas({
  obtenerCajasSucursal,
  cajaSelected,
  setCajaSelected,
}) {
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const classes = useStyles();
  const [datosBuscar, setDatosBuscar] = useState([]);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(0);
  const { data, refetch } = useQuery(OBTENER_CAJAS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
    },
  });

  let obtenerHistorialCaja = [];

  useEffect(() => {
    setLoading(true);
    refetch();
    setLoading(false);
  }, [refetch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(!open);
    setValue(0);
  };

  if (data) {
    obtenerCajasSucursal = data.obtenerCajasSucursal;
  }

  useEffect(() => {
    if (data) {
      setCajaSelected(obtenerCajasSucursal[0]);
    }
  }, [open]);

  return (
    <>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <BackdropComponent loading={loading} setLoading={setLoading} />
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcTimeline style={{ fontSize: 100 }} />
          </Box>
          Historial de Cajas
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
        classes={{
          scrollPaper: classes.paper,
          paperFullScreen: classes.fullScreen,
        }}
        hideBackdrop={true}
        PaperProps={{ elevation: 0 }}
      >
        <TabsReportesCajas
          classes={classes}
          value={value}
          handleChange={handleChange}
          obtenerCajasSucursal={obtenerCajasSucursal}
          setCajaSelected={setCajaSelected}
          a11yProps={a11yProps}
          handleClickOpen={handleClickOpen}
          datosBuscar={datosBuscar}
          cajaSelected={cajaSelected}
          setDatosBuscar={setDatosBuscar}
        />
        {/* <HeadIconsReportesCajas handleClickOpen={handleClickOpen} /> */}
        <FiltrosReportesCajas
          obtenerHistorialCaja={obtenerHistorialCaja}
          cajaSelected={cajaSelected}
          classes={classes}
          page={page}
          setPage={setPage}
          datosBuscar={datosBuscar}
          setDatosBuscar={setDatosBuscar}
        />
      </Dialog>
    </>
  );
}
