import { Button, Select, TextField, styled } from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { Autocomplete } from "@material-ui/lab";

export const CustomSearchTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    cursor: "pointer",
    "& .MuiInputBase-input": {
      padding: "6.2px 10px 6.2px 0px",
      width: "56px",
      transition: "width .3s",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiInputBase-input:focus": {
      width: "250px",
      cursor: "text",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B2BAC2",
      border: "1px solid"
    },
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: 14,
}));

export const CustomTableTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    fontSize: 14,
    height: "35px",
    "& .MuiInputBase-input": {
      padding: "2px 0px 2px 4px",
      fontSize: 14,
    },
    "& fieldset": {
      borderColor: "#F3F3F3",
    },
    "&:hover fieldset": {
      borderColor: "#E5E5E5",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #E5E5E5",
    },
    "&.Mui-disabled fieldset": {
      border: "1px solid #F0F0F0",
    },
  },
}));

export const CustomTableAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiAutocomplete-input": {
    "&:-webkit-autofill": {
      transition: "background-color 5000s ease-in-out 0s",
      backgroundColor: "transparent!important",
      "&::before": {
        content: "none",
      },
    },
  },
  "& .MuiAutocomplete-inputRoot": {
    padding: "0px 36px 2px 4px!important",
    fontSize: 14,
    //height: 27,
    alignItems: "center",
    "& .MuiInputBase-input": {
      padding: "0px",
      fontSize: 14,
    },
    "& .MuiAutocomplete-tag": {
      //height: 20,
      padding: "0px",
      marginTop: 0,
      marginBottom: 0,
      fontSize: 14,
    },
  },
}));

export const CustomTableSelect = styled(Select)(({ theme }) => ({
  borderColor: "#F3F3F3",
  "& .MuiSelect-select": {
    padding: "8px 0px 4px 8px",
    height: 20,
  },
  "&.Mui-disabled fieldset": {
    borderColor: "#F3F3F3!important",
  },
  "& fieldset": {
    borderColor: "#F3F3F3",
  },
  "&:hover fieldset": {
    borderColor: "#F3F3F3!important",
  },
  "&.Mui-focused fieldset": {
    border: "1px solid #F3F3F3!important",
  },
}));

export const CustomEditableTextField = styled(TextField)(({ theme }) => ({
  "input:-webkit-autofill,input:-webkit-autofill:hover,input:-webkit-autofill:focus,input:-webkit-autofill:active": {
    WebkitBoxShadow: `0 0 0 30px ${theme.palette.background.paper} inset !important`,
  },
  "& .MuiOutlinedInput-root": {
    cursor: "pointer",
    //padding: 0,
    "& fieldset": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      cursor: "pointer",
      padding: "6px",
      fontSize: 18,
    },
    "& .MuiInputBase-input:focus": {
      cursor: "text",
    },
    "&:hover fieldset": {
      border: "none",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid #B2BAC2",
    },
  },
}));
export const CustomSearchVentaTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    cursor: "pointer",
    "& .MuiInputBase-input": {
      padding: "6.2px 10px 6.2px 0px",
      width: "80px",
      transition: "width .3s",
      //cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& .MuiInputBase-input:focus": {
      width: "140px",
      //cursor: "text",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B2BAC2",
      border: "1px solid"
    },
  },
}));
export const CustomSearchVentaRapidaTF = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& .MuiInputBase-input": {
      padding: "6.2px 10px 6.2px 0px",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B2BAC2",
      border: "1px solid"
    },
  },
}));
export const CustomTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& .MuiInputBase-input": {
      padding: "8px 10px 8px 2px",
    },
    "& fieldset": {
      borderColor: blueGrey[50],
    },
    "&:hover fieldset": {
      borderColor: blueGrey[200],
    },
    "&.Mui-focused fieldset": {
      borderColor: blueGrey[200],
      border: "1px solid"
    },
  },
}));