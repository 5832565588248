import React from "react";
import { Grid, Tooltip, makeStyles, useTheme, Button } from "@material-ui/core";

function SelectMedida({ unidades, setDatosEtiqueta }) {
  const [selected, setSelected] = React.useState("");

  if (unidades.length === 0) return null;

  return (
    <>
      <Grid container spacing={4} justifyContent="center">
        {unidades.map((unidad) => (
          <MappingProductos
            key={`presentacion-etiqueta-${unidad._id}`}
            unidad={unidad}
            setDatosEtiqueta={setDatosEtiqueta}
            setSelected={setSelected}
            selected={selected}
          />
        ))}
      </Grid>
    </>
  );
}

const useStyles = makeStyles({
  medida: {
    height: 64,
    width: 74,
    fontSize: 28,
  },
  badge: {
    "& .MuiBadge-badge": {
      height: 30,
      width: 30,
      borderRadius: "100%",
      fontSize: 18,
    },
  },
});

const MappingProductos = ({ unidad, setDatosEtiqueta, setSelected, selected }) => {
  const classes = useStyles();
  const theme = useTheme();
  

  const selectMedida = () => {
    setSelected(unidad._id)
    setDatosEtiqueta((state) => ({
      ...state,
      medida: {
        talla: unidad.medida.talla,
        color: unidad.color.nombre,
      },
      precio: unidad.precio,
      codigo: unidad.codigo_barras,
    }));
  };

  return (
    <Grid item className={classes.badge}>
      <Tooltip title={unidad.color.nombre} placement="top" arrow>
        <Button
          disabled={!unidad.codigo_barras}
          disableElevation
          variant="contained"
          className={classes.medida}
          style={{
            backgroundColor: unidad.color.hex,
            color: theme.palette.getContrastText(unidad.color.hex),
            border: selected === unidad._id ? "2px black solid" : "none"
          }}
          onClick={() => selectMedida()}
        >
          {unidad.medida.talla}
        </Button>
      </Tooltip>
    </Grid>
  );
};

export default SelectMedida;
