import React, { Fragment, useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { ArrowBack, Description, Search } from "@material-ui/icons";
import ErrorPage from "../../../../../components/ErrorPage";
import { useQuery } from "@apollo/client";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx";
import ProductosSinClaveSat from "./ProductosSinClave.jsx";
import { OBTENER_VENTAS_SUCURSAL } from "../../../../../gql/Ventas/ventas_generales";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import { Alert } from "@material-ui/lab";
import { useDebounce } from "use-debounce";
import moment from "moment-timezone";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  container: {
    height: "50vh",
  },
}));

export default function ListaVentasFactura({ setAlert }) {
  const [open, setOpen] = useState(false);
  const [filtro, setFiltro] = useState("");
  const { complementCartaPorte } = useContext(FacturacionCtx);

  const handleOpen = () => {
    const verificate = verifyDestinos();
    if (!verificate) return;
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const verifyDestinos = () => {
    let compObj = { ...complementCartaPorte };
    const Ubicaciones = compObj.Complemento.CartaPorte30.Ubicaciones;

    if (Ubicaciones.length < 2) {
      setAlert({ open: true, message: "No hay ubicaciones", status: "error" });
      return false;
    }
    let origen = Ubicaciones[0];
    let destino = Ubicaciones[Ubicaciones.length - 1];

    if (origen.TipoUbicacion !== "Origen") {
      setAlert({
        open: true,
        message: "No hay ubicacion Origen",
        status: "error",
      });
      return false;
    }
    if (destino.TipoUbicacion !== "Destino") {
      setAlert({
        open: true,
        message: "No hay ubicacion Destino",
        status: "error",
      });
      return false;
    }

    return true;
  };

  return (
    <div>
      <IconButton onClick={() => handleOpen()} size="small">
        <Search />
      </IconButton>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gridGap={10}>
            <IconButton color="primary" onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Seleccionar venta</Typography>
            <Box flexGrow={1} />
            <TextField
              size="small"
              placeholder="Buscar por: Folio, cliente, clave o nombre"
              variant="outlined"
              onChange={(e) => setFiltro(e.target.value)}
              value={filtro}
              style={{ width: 330 }}
            />
            <TextField
              size="small"
              variant="outlined"
              type="date"
              onChange={(e) => setFiltro(e.target.value)}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <ComponentBusquedaVenta handleClose={handleClose} filtro={filtro} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ComponentBusquedaVenta = ({ handleClose, filtro }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [open_productos, setOpenProductos] = useState(false);
  const [productos_sin_clave, setProductosSinClave] = useState([]);
  const [page, setPage] = useState(0);
  const limit = 20;

  const openProductosClaves = () => setOpenProductos(true);
  const closeProductosClaves = () => setOpenProductos(false);

  const [value] = useDebounce(filtro, 500);

  const isDate = moment(value).isValid();

  /* Queries */
  const resultado_ventas = useQuery(OBTENER_VENTAS_SUCURSAL, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      filtros: {
        isDate,
        busqueda: value ? value : "",
        filtro: "",
        vista: "FACTURACION",
      },
      limit,
      offset: value ? 0 : page,
    },
    fetchPolicy: "network-only",
  });

  return (
    <Fragment>
      <ProductosSinClaveSat
        productos={productos_sin_clave}
        open={open_productos}
        handleClose={closeProductosClaves}
      />
      <Box mb={1}>
        <Alert severity="info">
          <strong> - </strong>Para seleccionar una venta haz un doble clic!
        </Alert>
      </Box>
      <RenderLista
        resultado_ventas={resultado_ventas}
        handleClose={handleClose}
        setProductosSinClave={setProductosSinClave}
        openProductosClaves={openProductosClaves}
        page={page}
        setPage={setPage}
        limit={limit}
      />
    </Fragment>
  );
};

const RenderLista = ({
  resultado_ventas,
  handleClose,
  setProductosSinClave,
  openProductosClaves,
  page,
  setPage,
  limit,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState("");
  const {
    setVentaFactura,
    setComplementCartaPorte,
    complementCartaPorte,
    setDatosFactura,
  } = useContext(FacturacionCtx);
  const { loading, data, error } = resultado_ventas;
  const [open, setOpen] = useState(false);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) {
    return <ErrorPage />;
  }

  const { obtenerVentasSucursal } = data;

  const obtenerVenta = (click, data, facturada) => {
    //console.log(data);
    setSelected(data.folio);
    const { productos, ...venta } = { ...data };
    if (click === 2) {
      if (facturada) {
        setOpen(true);
        return;
      }
      const notSatCode = productos.filter(
        (res) => !res.id_producto.datos_generales?.clave_producto_sat?.Value
      );
      if (notSatCode.length > 0) {
        setProductosSinClave(notSatCode);
        openProductosClaves();
        return;
      }

      let compObj = { ...complementCartaPorte };
      const Ubi = compObj.Complemento.CartaPorte30.Ubicaciones;

      let IDOrigen = "";
      let IDDestino = "";

      const items = [];
      const Mercancia = [];

      //buscar origen y destino
      if (Ubi.length > 0) IDOrigen = Ubi[0].IDUbicacion;
      if (Ubi.length > 1) IDDestino = Ubi[Ubi.length - 1].IDUbicacion;

      productos.forEach((producto) => {
        const { datos_generales, _id } = producto.id_producto;
        items.push({
          ProductCode: datos_generales.clave_producto_sat.Value,
          IdentificationNumber: _id,
          Description: datos_generales.nombre_comercial,
          UnitCode: producto.codigo_unidad,
          UnitPrice: "0",
          Quantity: producto.cantidad_venta.toString(),
          TaxObject: "01",
          Subtotal: "0",
          Total: "0",
        });
        Mercancia.push({
          BienesTransp: datos_generales.clave_producto_sat.Value,
          Descripcion: datos_generales.nombre_comercial,
          Cantidad: producto.cantidad_venta.toString(),
          ClaveUnidad: producto.codigo_unidad,
          MaterialPeligroso: "No",
          PesoEnKg: "",
          CantidadTransporta: [
            {
              Cantidad: producto.cantidad_venta.toString(),
              IDOrigen,
              IDDestino,
            },
          ],
        });
      });

      setDatosFactura((st) => ({ ...st, items }));
      setComplementCartaPorte((st) => ({
        ...st,
        Complemento: {
          ...st.Complemento,
          CartaPorte30: {
            ...st.Complemento.CartaPorte30,
            Mercancias: {
              ...st.Complemento.CartaPorte30.Mercancias,
              Mercancia,
              NumTotalMercancias: (Mercancia.length).toString(),
            },
          },
        },
      }));
      setVentaFactura(venta);
      handleClose();
    }
  };

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <Paper variant="outlined">
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={() => setOpen(false)}
        message="Esta venta ya fue facturada"
        autoHideDuration={3000}
      />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">CFDI</TableCell>
              <TableCell>Folio</TableCell>
              <TableCell>Fecha</TableCell>
              <TableCell style={{ minWidth: 200 }}>Cliente</TableCell>
              <TableCell style={{ minWidth: 150 }}>RFC</TableCell>
              <TableCell>Descuento</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Impuestos</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {obtenerVentasSucursal.docs.map((data, index) => {
              const facturada = data.factura.length > 0;
              return (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  selected={data.folio === selected}
                  onClick={(e) => obtenerVenta(e.detail, data, facturada)}
                >
                  <TableCell>
                    {facturada ? <Description color="action" /> : null}
                  </TableCell>
                  <TableCell>{data.folio}</TableCell>
                  <TableCell>
                    {moment(data.fecha_registro)
                      .tz("America/Mexico_City")
                      .format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {data.cliente !== null ? data.cliente.nombre_cliente : "-"}
                  </TableCell>
                  <TableCell>{data.cliente?.rfc}</TableCell>
                  <TableCell>
                    ${data.descuento ? formatoMexico(data.descuento) : 0}
                  </TableCell>
                  <TableCell>${formatoMexico(data.subTotal)}</TableCell>
                  <TableCell>${formatoMexico(data.impuestos)}</TableCell>
                  <TableCell>${formatoMexico(data.total)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={obtenerVentasSucursal.totalDocs}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};
