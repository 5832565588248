import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Tab,
  Tabs,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import ReportesCliente from "./Clientes/ReportesCliente";
import PropTypes from "prop-types";
import ReportesAbono from "./Abono/ReportesAbono";
import { FcRatings } from "react-icons/fc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    alignItems: "flex-end",
  },
  fullScreen: {
    height: "100vh",
  },
}));

export default function ReportesAbonos() {
  const [val, setVal] = useState(0);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(!open);
    setVal(0);
  };

  const handleChange = (event, newValue) => {
    setVal(newValue);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClickOpen}
      >
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcRatings style={{ fontSize: 100 }} />
          </Box>
          Cuentas clientes
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
        hideBackdrop={true}
        PaperProps={{ elevation: 0 }}
      >
        <DialogTitle component="div" style={{padding: "0 20px"}}>
          <Tabs
            value={val}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Estados de cuentas clientes" {...a11yProps(0)} />
            <Tab label="Abonos" {...a11yProps(1)} />
          </Tabs>
        </DialogTitle>
        <DialogContent>
          <TabPanel value={val} index={0}>
            <ReportesCliente handleClickOpen={handleClickOpen} />
          </TabPanel>
          <TabPanel value={val} index={1}>
            <ReportesAbono handleClickOpen={handleClickOpen}></ReportesAbono>
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
}
