import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import { FaRegFileCode, FaRegFilePdf } from "react-icons/fa";
import { ArrowBack, EmailOutlined } from "@material-ui/icons";
import ErrorPage from "../../../../components/ErrorPage";
import {
  Box,
  CircularProgress,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import InnerHTML from "dangerously-set-html-content";
import SendInvoice from "./SendInvoice";
import CancelarCFDI from "./CancelarCFDI";

const base64toBlob = (data, type) => {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type });
};

export default function DetallesFacturaModal({
  factura,
  facturaBase64,
  setOpen,
  loading,
  refetch,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  if (loading) {
    return (
      <Fragment>
        <DialogTitle component="div" style={{ padding: "4px 10px 4px 10px" }}>
          <Box display="flex" alignItems="center">
            <IconButton color="primary" onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Factura</Typography>
            <Box flexGrow={1} />
            <Button startIcon={<EmailOutlined />} disabled>
              Enviar
            </Button>
            <Box mx={1} />
            <Button startIcon={<FaRegFilePdf />} disabled>
              PDF
            </Button>
            <Box mx={1} />
            <Button startIcon={<FaRegFileCode />} disabled>
              XML
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="70vh"
          >
            <CircularProgress />
            <Typography variant="h6">Cargando...</Typography>
          </Box>
        </DialogContent>
      </Fragment>
    );
  }

  if (!facturaBase64 || !factura) {
    return (
      <Fragment>
        <DialogTitle component="div" style={{ padding: "4px 10px 4px 10px" }}>
          <Box display="flex" alignItems="center">
            <IconButton color="primary" onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Factura</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="70vh"
          >
            <ErrorPage />
          </Box>
        </DialogContent>
      </Fragment>
    );
  }
  //decodificar base64 an html
  var decodedHTML = window.atob(facturaBase64.htmlBase64);
  //decodificar pdfBase64 a pdf
  const blob_pdf = base64toBlob(facturaBase64.pdfBase64, "application/pdf");
  const url_pdf = URL.createObjectURL(blob_pdf);

  //decodificar xmlBase64 a xml
  const blob_xml = base64toBlob(facturaBase64.xmlBase64, "text/plain");
  const url_xml = URL.createObjectURL(blob_xml);

  return (
    <Fragment>
      <DialogTitle component="div" style={{ padding: "4px 10px 4px 10px" }}>
        <Box display="flex" alignItems="center">
          <IconButton color="primary" onClick={handleClose}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h6">Factura</Typography>
          <Box flexGrow={1} />
          <SendInvoice factura={factura} />
          <Box mx={1} />
          <Button
            color="primary"
            startIcon={<FaRegFilePdf />}
            download={`CFDI ${factura.fecha_registro} ${factura.receiver.Name}`}
            href={url_pdf}
          >
            PDF
          </Button>
          <Box mx={1} />
          <Button
            color="primary"
            startIcon={<FaRegFileCode />}
            download={`CFDI ${factura.fecha_registro} ${factura.receiver.Name}.xml`}
            href={url_xml}
          >
            XML
          </Button>
          <Box mx={1} />
          <CancelarCFDI cfdi={factura} refetch={refetch} />
        </Box>
      </DialogTitle>
      <DialogContent>
        <InnerHTML html={decodedHTML} />
      </DialogContent>
    </Fragment>
  );
}
