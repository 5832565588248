import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Search } from "@material-ui/icons";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

export default function BottomHeader({ values, setValues, setPage }) {

  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setPage(0);
  };

  const obtenerChecks = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
    setPage(0);
  };


  const setCheckActivos = (e) => {
    obtenerChecks(e);
    setValues({
      ...values,
      con_existencia: e.target.checked,
      sin_existencia: false,
    });
    setPage(0);
  };

  const setCheckInactivos = (e) => {
    obtenerChecks(e);
    setValues({
      ...values,
      sin_existencia: e.target.checked,
      con_existencia: false,
    });
    setPage(0);
  };

  return (
    <Box mt={1}>
      <Grid container spacing={1} alignItems="center">
        <Grid item md={4}>
          <Typography>Buscar producto:</Typography>
          <TextField
            fullWidth
            placeholder="Código, clave alterna, nombre"
            onChange={(e) => obtenerCampos(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={values.producto}
            variant="outlined"
            size="small"
            name="producto"
          />
        </Grid>
        <Grid item md={2}>
          <Typography>Categoría:</Typography>
          <TextField
            fullWidth
            placeholder="Buscar por categoría"
            onChange={(e) => obtenerCampos(e)}
            value={values.cat}
            variant="outlined"
            size="small"
            name="cat"
          />
        </Grid>
        <Grid item md={2}>
          <Typography>Subcategoría:</Typography>
          <TextField
            fullWidth
            placeholder="Buscar por subcategoría"
            onChange={(e) => obtenerCampos(e)}
            value={values.subcat}
            variant="outlined"
            size="small"
            name="subcat"
          />
        </Grid>
        <Grid item md={2}>
          <Typography>Departamento:</Typography>
          <TextField
            fullWidth
            placeholder="Buscar por departamento"
            onChange={(e) => obtenerCampos(e)}
            value={values.departamento}
            variant="outlined"
            size="small"
            name="departamento"
          />
        </Grid>
        <Grid item md={2}>
          <Typography>Marca:</Typography>
          <TextField
            fullWidth
            placeholder="Buscar por marca"
            onChange={(e) => obtenerCampos(e)}
            value={values.marca}
            variant="outlined"
            size="small"
            name="marca"
          />
        </Grid>
        <Grid item md={2}>
          <Typography>Tipo:</Typography>
          <FormControl variant="outlined" fullWidth size="small">
            <Select
              name="tipo"
              onChange={(e) => obtenerCampos(e)}
              value={values.tipo}
            >
              <MenuItem value="">
                <em>Cualquier tipo</em>
              </MenuItem>
              <MenuItem value="ROPA">ROPA</MenuItem>
              <MenuItem value="CALZADO">CALZADO</MenuItem>
              <MenuItem value="OTROS">OTROS</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <Typography>Unidad de compra:</Typography>
          <FormControl variant="outlined" fullWidth size="small">
            <Select
              name="unidad_compra"
              onChange={(e) => obtenerCampos(e)}
              value={values.unidad_compra}
            >
              <MenuItem value="">
                <em>Cualquier tipo</em>
              </MenuItem>
              <MenuItem value="Costal">Costal</MenuItem>
              <MenuItem value="Caja">Caja</MenuItem>
              <MenuItem value="Kg">Kg</MenuItem>
              <MenuItem value="Pz">Pz</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.con_existencia}
                onChange={setCheckActivos}
                name="con_existencia"
                color="default"
              />
            }
            label="En existencia"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.sin_existencia}
                onChange={setCheckInactivos}
                name="sin_existencia"
                color="default"
              />
            }
            label="Sin existencia"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
