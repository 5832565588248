import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import { CustomCheckboxCanceladas } from "../../Abonos/CustomCheckBoxsStyles";
import { CustomTableTextField } from "../../../../../components/CustomMuiComponents";

export default function FiltrosAbonos({ datosFiltro, setDatosFiltro }) {
  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setDatosFiltro({
      ...datosFiltro,
      [name]: value,
    });
  };

  const obtenerChecks = (e) => {
    const { name, checked } = e.target;
    if (name === "canceladas") {
      setDatosFiltro({
        ...datosFiltro,
        [name]: checked,
        realizadas: false,
      });
    } else {
      setDatosFiltro({
        ...datosFiltro,
        [name]: checked,
        canceladas: false,
      });
    }
  };

  return (
    <Box my={1}>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <CustomTableTextField
            fullWidth
            type="date"
            onChange={(e) => obtenerCampos(e)}
            value={datosFiltro.fecha_inicio}
            variant="outlined"
            size="small"
            name="fecha_inicio"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">De:</InputAdornment>
              ),
            }}
            style={{
              width: 150,
            }}
          />
        </Grid>
        <Grid item>
          <CustomTableTextField
            fullWidth
            onChange={(e) => obtenerCampos(e)}
            value={datosFiltro.fecha_fin}
            type="date"
            variant="outlined"
            size="small"
            name="fecha_fin"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">a:</InputAdornment>
              ),
            }}
            style={{
              width: 150,
            }}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={datosFiltro.realizadas}
                onChange={(e) => obtenerChecks(e)}
                name="realizadas"
                color="default"
              />
            }
            label="Realizadas"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <CustomCheckboxCanceladas
                checked={datosFiltro.canceladas}
                onChange={(e) => obtenerChecks(e)}
                name="canceladas"
                color="default"
              />
            }
            label="Canceladas"
          />
        </Grid>
      </Grid>
    </Box>
  );
}
