import React from "react";
import { InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { TraspasosContext } from "../../Context/TraspasosContext";
import { GetConceptos } from "../../graphql/queries";
import { CustomTableSelect } from "../../../../../../components/CustomMuiComponents";
import { MainContext } from "../../../../../../context/MainCtx";

export default function ConceptosTraspaso() {
  const { tokenDecoded } = React.useContext(MainContext);
  const { traspaso, setTraspaso } = React.useContext(TraspasosContext);

  const { loading, data, error } = useQuery(GetConceptos, {
    variables: {
      empresa: tokenDecoded.empresa._id,
      sucursal: tokenDecoded.sucursal._id,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <FormControl size="small" variant="outlined" fullWidth disabled>
        <InputLabel id="loading">Elige un concepto</InputLabel>
        <CustomTableSelect
          labelId="loading"
          label="Elige un concepto"
          value="loading"
        >
          <MenuItem value="loading">Cargando...</MenuItem>
        </CustomTableSelect>
      </FormControl>
    );
  }

  if (error) {
    return (
      <FormControl size="small" variant="outlined" fullWidth disabled>
        <InputLabel id="error">Elige un concepto</InputLabel>
        <CustomTableSelect
          labelId="error"
          label="Elige un concepto"
          value="error"
        >
          <MenuItem value="error">Error</MenuItem>
        </CustomTableSelect>
      </FormControl>
    );
  }

  const conceptos = data?.obtenerConceptosAlmacen || [];

  const handleChangeConcepto = (e) => {
    const { value } = e.target;
    const salida = value.nombre_concepto === "SALIDAS POR TRASPASOS" && true;
    setTraspaso({
      ...traspaso,
      concepto_traspaso: value,
      almacen_origen: null,
      almacen_destino: null,
      sucursalDestino: salida ? null : tokenDecoded.sucursal._id,
    });
  };

  return (
    <>
      <FormControl
        size="small"
        variant="outlined"
        fullWidth
        disabled={traspaso.productos.length}
      >
        <InputLabel id="concepto-label">Elige un concepto</InputLabel>
        <CustomTableSelect
          labelId="concepto-label"
          id="concepto-name"
          label="Elige un concepto"
          value={traspaso.concepto_traspaso || ""}
          onChange={handleChangeConcepto}
        >
          {conceptos.map((concepto) => {
            return (
              <MenuItem key={concepto._id} value={concepto}>
                {concepto.nombre_concepto}
              </MenuItem>
            );
          })}
        </CustomTableSelect>
      </FormControl>
    </>
  );
}
