import { Box, Paper, Typography } from "@material-ui/core";
import React, { Fragment } from "react";
import Parametros from "../Parametros";

export default function Reportes({ obtenerAccesos, arregloAccesos }) {
  return (
    <Fragment>
      <Box p={1} display="flex" justifyContent="center">
        <Box width="80%">
          <Typography variant="h6">
            Marca los permisos que deseas asignar a este usuario
          </Typography>
        </Box>
      </Box>
      <Box p={1} display="flex" justifyContent="center">
        <Box width="80%">
          <Paper elevation={3}>
            <Box display="flex">
              <Box ml={1} p={1} flexGrow={1}>
                <Typography variant="h6">
                  <b>Reportes Historiales Cajas</b>
                </Typography>
              </Box>
              <Parametros
                arregloAccesos={arregloAccesos}
                obtenerAccesos={obtenerAccesos}
                departamento={"reportes"}
                subDepartamento={"reporte_historial_cajas"}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box p={1} display="flex" justifyContent="center">
        <Box width="80%">
          <Paper elevation={3}>
            <Box display="flex">
              <Box ml={1} p={1} flexGrow={1}>
                <Typography variant="h6">
                  <b>Reportes abonos </b>
                </Typography>
              </Box>
              <Parametros
                arregloAccesos={arregloAccesos}
                obtenerAccesos={obtenerAccesos}
                departamento={"reportes"}
                subDepartamento={"reporte_abonos"}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box p={1} display="flex" justifyContent="center">
        <Box width="80%">
          <Paper elevation={3}>
            <Box display="flex">
              <Box ml={1} p={1} flexGrow={1}>
                <Typography variant="h6">
                  <b>Reportes de compras </b>
                </Typography>
              </Box>
              <Parametros
                arregloAccesos={arregloAccesos}
                obtenerAccesos={obtenerAccesos}
                departamento={"reportes"}
                subDepartamento={"reporte_compras"}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box p={1} display="flex" justifyContent="center">
        <Box width="80%">
          <Paper elevation={3}>
            <Box display="flex">
              <Box ml={1} p={1} flexGrow={1}>
                <Typography variant="h6">
                  <b>Reportes de ventas </b>
                </Typography>
              </Box>
              <Parametros
                arregloAccesos={arregloAccesos}
                obtenerAccesos={obtenerAccesos}
                departamento={"reportes"}
                subDepartamento={"reporte_ventas"}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box p={1} display="flex" justifyContent="center">
        <Box width="80%">
          <Paper elevation={3}>
            <Box display="flex">
              <Box ml={1} p={1} flexGrow={1}>
                <Typography variant="h6">
                  <b>Reportes de almacenes </b>
                </Typography>
              </Box>
              <Parametros
                arregloAccesos={arregloAccesos}
                obtenerAccesos={obtenerAccesos}
                departamento={"reportes"}
                subDepartamento={"rerporte_almacen"}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box p={1} display="flex" justifyContent="center">
        <Box width="80%">
          <Paper elevation={3}>
            <Box display="flex">
              <Box ml={1} p={1} flexGrow={1}>
                <Typography variant="h6">
                  <b>Reportes de cortes </b>
                </Typography>
              </Box>
              <Parametros
                arregloAccesos={arregloAccesos}
                obtenerAccesos={obtenerAccesos}
                departamento={"reportes"}
                subDepartamento={"reporte_corte_caja"}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
      <Box p={1} display="flex" justifyContent="center">
        <Box width="80%">
          <Paper elevation={3}>
            <Box display="flex">
              <Box ml={1} p={1} flexGrow={1}>
                <Typography variant="h6">
                  <b>Reportes tesoreria </b>
                </Typography>
              </Box>
              <Parametros
                arregloAccesos={arregloAccesos}
                obtenerAccesos={obtenerAccesos}
                departamento={"reportes"}
                subDepartamento={"reporte_tesoreria"}
              />
            </Box>
          </Paper>
        </Box>
      </Box>
    </Fragment>
  );
}
