import React from "react";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import DialogTitle from "@material-ui/core/DialogTitle";
import NavbarTicketPanel from "./Navbar";
import BottomActions from "./BottomActions";
import AbrirTurno from "../../ventas/AbrirCerrarTurno/AbrirTurno";
import TablaArticulosIndex from "../../ventas/TablaArticulos/TablaIndex";
import { makeStyles } from "@material-ui/core";
import DownloadInstaller from "../../../components/DownloadIntaller/DownloadInstaller";
import ComponentOnline from "../../../components/Connection/ComponentOnline";
import GoAdminPanel from "../Navbar/GoAdmin";
import CerrarSesion from "../Navbar/CerrarSesion";
import { AccesosContext } from "../../../context/Accesos/accesosCtx";
import GetLastTurno from "../../ventas/AbrirCerrarTurno/GetLastSesion";
import { MainContext } from "../../../context/MainCtx";

export default function TicketPanel() {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <AbrirTurnoEnVentas />
      <Paper variant="outlined">
        <NavbarTicketPanel />
        <TablaArticulosIndex />
        <BottomActions />
      </Paper>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "40vw",
    height: "calc(100vh - 40px)",
    top: 20,
    //bottom: "auto",
    right: 20,
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
  },
}));

function AbrirTurnoEnVentas() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { isOnline, ventasToCloud } = React.useContext(AccesosContext);
  const { turnoEnCurso } = React.useContext(MainContext);

  return (
    <Drawer
      anchor="right"
      open={!turnoEnCurso}
      keepMounted
      disableEscapeKeyDown={false}
      classes={{ paper: classes.drawerPaper }}
    >
      <DialogTitle component="div">
        <Box display="flex" alignItems="center" gridGap={10}>
          <Typography variant="h6">Iniciar Turno</Typography>
          <GetLastTurno setLoading={setLoading} loading={loading} />
          <Box flexGrow={1} />
          <DownloadInstaller />
          <ComponentOnline
            isOnline={isOnline}
            ventasToCloud={ventasToCloud}
            sesion={sesion}
            fromVentas={true}
          />
          <GoAdminPanel />
          <CerrarSesion />
        </Box>
      </DialogTitle>
      <AbrirTurno setLoading={setLoading} loading={loading} />
    </Drawer>
  );
}
