import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { CardMedia } from "@material-ui/core";
import CardTest from "./CardTest";

export default function PresetsCards({ banner, preview }) {
  switch (banner.typeBanner) {
    case "ONLY_BANNER":
      return <OnlyBanner banner={banner} preview={preview} />;
    case "BANNER_WITH_CARDS":
      return <BannerWithCards banner={banner} preview={preview} />;
    case "ONLY_CARDS":
      return <OnlyCards banner={banner} preview={preview} />;
    case "CARDS_IMAGE_LEFT":
      return <CardsImageLeft banner={banner} preview={preview} />;
    case "CARDS_IMAGE_RIGHT":
      return <CardsImageRight banner={banner} preview={preview} />;
    default:
      return null;
  }
}

export const OnlyBanner = ({ banner, preview }) => (
  <Box width="100%" height={preview ? 220 : 100} px={3} py={preview ? 4 : 1}>
    <Typography align="center" variant={preview ? "h5" : "body"}>
      {banner.title}
    </Typography>
    <Box width="100%" my={1}>
      <CardMedia
        style={{ height: preview ? 160 : 50, borderRadius: 5 }}
        image={banner.banner.url_imagen}
        title={banner.title}
      />
    </Box>
  </Box>
);
export const BannerWithCards = ({ banner, preview }) => (
  <Box width="100%" px={3} py={preview ? 4 : 1}>
    <Typography align="center" variant={preview ? "h5" : "body"}>
      {banner.title}
    </Typography>
    <Grid container spacing={preview ? 3 : 1} style={{ marginTop: 8 }}>
      <Grid item xs={12}>
        <Box width="100%">
          <CardMedia
            style={{ height: preview ? 160 : 50, borderRadius: 5 }}
            image={banner.banner.url_imagen}
            title={banner.title}
          />
        </Box>
      </Grid>
      <Grid item xs={3}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
    </Grid>
  </Box>
);
export const OnlyCards = ({ banner, preview }) => (
  <Box width="100%" px={3} py={preview ? 4 : 1}>
    <Typography align="center" variant={preview ? "h5" : "body"}>
      {banner.title}
    </Typography>
    <Grid container spacing={preview ? 3 : 1} style={{ marginTop: 8 }}>
      <Grid item xs={3}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={3}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
    </Grid>
  </Box>
);
export const CardsImageLeft = ({ banner, preview }) => (
  <Box width="100%" px={3} py={preview ? 4 : 1}>
    <Typography align="center" variant={preview ? "h5" : "body"}>
      {banner.title}
    </Typography>
    <Grid container spacing={preview ? 3 : 1} style={{ marginTop: 8 }}>
      <Grid item xs={5}>
        <Box width="100%">
          <CardMedia
            style={{ height: preview ? 180 : 50, borderRadius: 5 }}
            image={banner.sides.url_imagen}
            title={banner.title}
          />
        </Box>
      </Grid>
      <Grid item xs={2}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={1}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
    </Grid>
  </Box>
);
export const CardsImageRight = ({ banner, preview }) => (
  <Box width="100%" px={3} py={preview ? 4 : 1}>
    <Typography align="center" variant={preview ? "h5" : "body"}>
      {banner.title}
    </Typography>
    <Grid container spacing={preview ? 3 : 1} style={{ marginTop: 8 }}>
      <Grid item xs={1}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {preview ? (
          <CardTest />
        ) : (
          <Box
            width="100%"
            height={preview ? 180 : 50}
            borderRadius={5}
            bgcolor="#0000001C"
          />
        )}
      </Grid>
      <Grid item xs={5}>
        <Box width="100%">
          <CardMedia
            style={{ height: preview ? 180 : 50, borderRadius: 5 }}
            image={banner.sides.url_imagen}
            title={banner.title}
          />
        </Box>
      </Grid>
    </Grid>
  </Box>
);
