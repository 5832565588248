import * as React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ArrowBack, NotificationsActive } from "@material-ui/icons";
import { Grid, useTheme } from "@material-ui/core";
import { formatoFechaCorta } from "../../../../../../config/reuserFunctions";
import ProductosTraspaso from "./ProductosTraspaso";
import GuardarTraspasoRecibido from "./GuardarTraspaso";
import { MainContext } from "../../../../../../context/MainCtx";

export default function RecibirTraspaso({ traspaso }) {
  const { tokenDecoded } = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const sucursal = tokenDecoded?.sucursal._id;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!traspaso.traspasoEntreSucursales) return null;
  if (traspaso.sucursalOrigen._id === sucursal) return null;
  if (traspaso.aceptado) return null;

  return (
    <div>
      <IconButton onClick={handleOpen} size="small">
        <NotificationsActive
          fontSize="small"
          style={{ color: theme.palette.cafiPrimary }}
        />
      </IconButton>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          BackdropProps: {
            style: {
              background: "rgba(0,0,0, 0.1)",
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          style: {
            width: "60vw",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex" alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Box mx={1} />
              <Box>
                <Typography variant="h6">
                  Recibir traspaso de{" "}
                  {traspaso?.sucursalOrigen?.nombre_sucursal}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {formatoFechaCorta(traspaso.fecha_registro)}
                </Typography>
              </Box>
            </Box>
            <GuardarTraspasoRecibido
              traspaso={traspaso}
              handleClose={handleClose}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                <b>Origen</b>
              </Typography>
              <Typography>
                {traspaso.sucursalOrigen?.nombre_sucursal}
              </Typography>
              <Typography>
                Almacen: {traspaso.almacen_origen?.nombre_almacen}
              </Typography>
              <Typography>
                {traspaso.concepto_traspaso?.nombre_concepto}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>
                <b>Destino</b>
              </Typography>
              <Typography>
                {traspaso.sucursalDestino?.nombre_sucursal}
              </Typography>
              <Typography>
                Almacen: {traspaso.almacen_destino?.nombre_almacen}
              </Typography>
              <Typography>ENTRADAS POR TRASPASOS</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <b>Artículos</b>
              </Typography>
              <ProductosTraspaso productos={traspaso.productos} />
            </Grid>
          </Grid>
        </DialogContent>
      </Drawer>
    </div>
  );
}
