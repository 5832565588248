import * as React from "react";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { ProdAlmContext } from "../Context/ProdAlmContext";
import ProdAlmTableBody from "./TableBody";
import {
  getComparator,
  stableSort,
} from "../../../../../config/reuserFunctions";
import ProdAlmTableHead from "./TableHead";

export default function ProductosAlmacenTable() {
  const {
    almacensQuery,
    setPageConfig,
    pageConfig,
    setTableRef,
  } = React.useContext(ProdAlmContext);
  const {
    docs,
    totalDocs,
    almacenes,
  } = almacensQuery?.data?.getProductAlmacens;
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("nombre");
  const tableEl = React.useRef();

  const renderSorted = React.useMemo(() => {
    return stableSort(docs, getComparator(order, orderBy));
  }, [order, orderBy, docs]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig({ limit: parseInt(event.target.value), page: 0 });
  };

  React.useEffect(() => {
    if (tableEl?.current) {
      setTableRef(tableEl);
    }
  }, [tableEl]);

  return (
    <>
      <Paper variant="outlined">
        <TableContainer
          style={{
            height: "80vh",
            borderRadius: 2,
          }}
        >
          <Table stickyHeader size="small" innerRef={tableEl}>
            <ProdAlmTableHead
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
              almacenes={almacenes}
            />
            <ProdAlmTableBody data={renderSorted} almacenes={almacenes} />
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        component="div"
        count={totalDocs}
        page={pageConfig.page}
        onPageChange={handleChangePage}
        rowsPerPage={pageConfig.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
