import React from "react";
import { InputAdornment } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { ProdAlmContext } from "../Context/ProdAlmContext";
import { CustomSearchTextField } from "../../../../../components/CustomMuiComponents";

export default function SearchComponent({style = null}) {
  const { filter, setFilter } = React.useContext(ProdAlmContext);
  
  return (
    <CustomSearchTextField
      placeholder="Buscar"
      size="small"
      variant="outlined"
      value={filter.producto}
      onChange={(e) => setFilter({...filter, producto: e.target.value})}
      style={style}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      }}
    />
  );
}
