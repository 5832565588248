import React from "react";
import ClientesVentasPanel from "./ClientesVentas";
import Box from "@material-ui/core/Box";
import { ClienteCtx } from "../../../context/Catalogos/crearClienteCtx";
import { VentasContext } from "../../../context/Ventas/ventasContext";

export default function BuscarCliente() {
  const datosVentas = JSON.parse(localStorage.getItem("DatosVentas")) || {};
  const { updateClientVenta } = React.useContext(
    ClienteCtx
  );

  const { setClientesVentas } = React.useContext(VentasContext);

  React.useEffect(() => {
    if (datosVentas?.cliente?.nombre_cliente) {
      setClientesVentas(datosVentas.cliente);
      return;
    }
    setClientesVentas([]);
  }, [updateClientVenta]);

  return (
    <Box display="flex" alignItems="center" gridGap={10}>
      <ClientesVentasPanel small={true} />
    </Box>
  );
}
