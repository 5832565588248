import React, { createContext, useState } from "react";
import {
  factura_initial_state,
  complement_initial_state,
  getAddressCodes,
  getIdOrigenDestino,
} from "../../pages/sucursales/Facturacion/NuevaFactura/initial_factura_states";

export const FacturacionCtx = createContext();

export const FacturacionProvider = ({ children }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [isPublicoGeneral, setIsPublicoGeneral] = React.useState(false);
  const [isCfdiRelated, setIsCfdiRelated] = React.useState(false);
  const [datosFactura, setDatosFactura] = useState(factura_initial_state);
  const [complementCartaPorte, setComplementCartaPorte] = useState(
    complement_initial_state
  );

  const [venta_factura, setVentaFactura] = useState(null);
  const [productos, setProductos] = useState([]);

  const [cp_valido, setCPValido] = useState(false);
  const [codigo_postal, setCodigoPostal] = useState("");
  const [error_validation, setError] = useState({ status: false, message: "" });

  const getFistDestino = async (checked) => {
    const { receiver, issuer } = datosFactura;
    const isClienteValido = receiver.Rfc && !isPublicoGeneral;
    if (checked) {
      //setear como Traslado el cfdi
      setDatosFactura((st) => ({
        ...st,
        cfdi_type: "T",
      }));
      //armar origen y destino
      let Ubicaciones = [];
      Ubicaciones.push({
        Nombre: issuer.Name,
        TipoUbicacion: "Origen",
        IDUbicacion: getIdOrigenDestino("OR"),
        FechaHoraSalidaLlegada: "",
        RFCRemitenteDestinatario: issuer.Rfc,
        Domicilio: await getAddressCodes(sesion.empresa.direccion),
        AddresWithNames: sesion.empresa.direccion,
      });
      if (isClienteValido) {
        Ubicaciones.push({
          Nombre: receiver.Name,
          TipoUbicacion: "Destino",
          IDUbicacion: getIdOrigenDestino("DE"),
          FechaHoraSalidaLlegada: "",
          DistanciaRecorrida: "",
          RFCRemitenteDestinatario: receiver.Rfc,
          Domicilio: await getAddressCodes(receiver.Address),
          AddresWithNames: receiver.Address,
        });
      }
      setComplementCartaPorte((st) => ({
        ...st,
        Complemento: {
          CartaPorte30: {
            ...st.Complemento.CartaPorte30,
            Ubicaciones,
          },
        },
      }));
    } else {
      setComplementCartaPorte(complement_initial_state);
      setDatosFactura((st) => ({
        ...st,
        cfdi_type: "I",
      }));
    }
  };

  return (
    <FacturacionCtx.Provider
      value={{
        datosFactura,
        setDatosFactura,
        cp_valido,
        setCPValido,
        codigo_postal,
        setCodigoPostal,
        error_validation,
        setError,
        venta_factura,
        setVentaFactura,
        productos,
        setProductos,
        isPublicoGeneral,
        setIsPublicoGeneral,
        isCfdiRelated,
        setIsCfdiRelated,
        getFistDestino,
        complementCartaPorte,
        setComplementCartaPorte,
      }}
    >
      {children}
    </FacturacionCtx.Provider>
  );
};
