import React, { useEffect } from "react";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { RiFileExcel2Line } from "react-icons/ri";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { formatoMexico } from "../../../../config/reuserFunctions";
import { formaPago } from "../../Facturacion/catalogos";
import { useQuery } from "@apollo/client";
import { OBTENER_REPORTE_COMPRAS } from "../../../../gql/Compras/reporte_compras";
import moment from "moment";
import ExcelExportButton from "../../../../components/ExcelExportButton";

export default function ExportarCompras({ filtro, setFiltro }) {
  const [open, setOpen] = React.useState(false);
  const [datosExcel, setDatosExcel] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const limit = 20;
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const { data, refetch } = useQuery(OBTENER_REPORTE_COMPRAS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: {
        fecha_inicio: filtro.fecha_inicio ? filtro.fecha_inicio : "",
        fecha_fin: filtro.fecha_fin ? filtro.fecha_fin : "",
        proveedor: filtro.proveedor ? filtro.proveedor : "",
        metodo_pago: filtro.metodo_pago ? filtro.metodo_pago : "",
        forma_pago: filtro.forma_pago ? filtro.forma_pago : "",
        producto: filtro.producto ? filtro.producto : "",
        canceladas: filtro.canceladas ? filtro.canceladas : false,
        vencidas: filtro.vencidas ? filtro.vencidas : false,
        vigentes: filtro.vigentes ? filtro.vigentes : false,
        liquidadas: filtro.liquidadas ? filtro.liquidadas : false,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      setDatosExcel(data.obtenerProductoMovimientos.docs);
    }
  }, [data]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    setValue(value);

    if (!value) {
      refetch({
        limit: 20,
        offset: 0,
      });
    } else {
      refetch({
        limit: 0,
        offset: 0,
      });
    }
  };
  return (
    <div>
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<RiFileExcel2Line />}
        onClick={handleClickOpen}
      >
        Exportar Excel
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>{"Exportar Reportes de compras"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            -Exportar pagina actual: exportará la pagina actual
          </DialogContentText>
          <DialogContentText>
            -Exportar todo: exportará todos los registros filtrados
          </DialogContentText>
          <FormControl variant="outlined" fullWidth>
            <Select
              value={value}
              onChange={(e) => handleChange(e.target.value)}
            >
              <MenuItem value={0}>Contenido Actual</MenuItem>
              <MenuItem value={1}>Todos los registros</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <ExportarExcelAction
            handleClose={handleClose}
            datosExcel={datosExcel}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ExportarExcelAction = ({ datosExcel, handleClose }) => {
  const compras = datosExcel.map((item) => {
    const {
      compra,
      producto,
      fecha_registro,
      almacen,
      proveedor,
      color,
      medida,
      cantidad,
      unidad,
      unidad_regalo,
      cantidad_regalo,
      cantidad_total,
      compra_credito,
      costo,
      descuento_porcentaje,
      descuento_precio,
      total,
    } = item;
    const forma_pago_filtrada = formaPago.filter(
      (forma) => forma.Value === item.forma_pago
    );
    const forma_pago = forma_pago_filtrada[0];

    const compra_realizada = {
      status:
        compra.status && compra.status === "CANCELADO"
          ? "CANCELADO"
          : "REALIZADO",
      producto: producto.datos_generales.nombre_comercial,
      fecha: moment(fecha_registro)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY"),
      fecha_registro: moment(fecha_registro)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY, hh:mm a"),
      almacen: almacen.nombre_almacen,
      proveedor: proveedor.nombre_cliente,
      color: color ? color.color : "N/A",
      medida: medida ? `${medida.medida}/${medida.tipo}` : "N/A",
      cantidad,
      unidad,
      unidad_regalo,
      cantidad_regalo,
      cantidad_total,
      fecha_vencimiento_credito: moment(compra.fecha_vencimiento_credito)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY"),
      credito: compra_credito
        ? compra.credito_pagado
          ? "PAGADO"
          : compra.fecha_vencimiento_credito <= moment().format("YYYY-MM-DD")
          ? "VENCIDA"
          : "PENDIENTE"
        : "-",
      compra_credito: compra_credito === true ? "Credito" : "Contado",
      forma_pago: `${forma_pago.Value} - ${forma_pago.Name}`,
      costo: `$${costo ? formatoMexico(costo) : 0}`,
      descuento_porcentaje: `%${descuento_porcentaje}`,
      descuento_precio: `$${
        descuento_precio ? formatoMexico(descuento_precio) : 0
      }`,
      total: `$${total ? formatoMexico(total) : 0}`,
    };

    return compra_realizada;
  });

  return <ExcelExportButton data={compras} name="Reporte de compras" button />;
};
