import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles } from "@material-ui/core";
import UbicacionesCartaPorte from "./Ubicaciones/index.jsx";
import TransporteCartaPorte from "./AutoTransporte/index.jsx";
import TransportistaCartaPorte from "./Transportista/index.jsx";
import FechaHoraSalidaLlegada from "./FechaHoraSalidaLlegada.jsx";
import { CustoTextField } from "../../NuevaFactura/CustomTextField.jsx";
import ListaVentasFactura from "../ListaVentas/ListaVentasFactura.jsx";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx.js";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    height: "100%",
  },
  title: {
    position: "absolute",
    top: -30,
    left: -18,
    backgroundColor: "white",
    padding: "0 8px",
  },
}));

export default function ComplementoCartaPorte({setAlert}) {
  const classes = useStyles();
  const { venta_factura } = React.useContext(FacturacionCtx);

  return (
    <Box variant="outlined" className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <UbicacionesCartaPorte />
        </Grid>
        <Grid item md={3}>
          <TransporteCartaPorte />
        </Grid>
        <Grid item md={3}>
          <TransportistaCartaPorte />
        </Grid>
        <Grid item>
          <FechaHoraSalidaLlegada />
        </Grid>
        <Grid item>
          <Typography color="textSecondary">Venta</Typography>
          <CustoTextField
            placeholder="Selecciona venta"
            fullWidth
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ListaVentasFactura setAlert={setAlert} />
                </InputAdornment>
              ),
              readOnly: true,
              disabled: true,
            }}
            value={venta_factura ? venta_factura.folio : ""}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
