import * as React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Done } from "@material-ui/icons";
import { MainContext } from "../../../../../../context/MainCtx";
import { TraspasosContext } from "../../Context/TraspasosContext";
import { useMutation } from "@apollo/client";
import { AceptarTrasEntreSuc } from "../../graphql/mutations";
import { CloudContext } from "../../../../../../context/CloudCtx";

export default function GuardarTraspasoRecibido({ traspaso, handleClose }) {
  const { traspasosQuery } = React.useContext(TraspasosContext);
  const { syncDataBases } = React.useContext(CloudContext);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const [loading, setLoading] = React.useState(false);
  const [aceptarTrasEntreSuc] = useMutation(AceptarTrasEntreSuc);

  const handleSaveTraspaso = async () => {
    try {
      let input = {
        id_traspaso: traspaso._id,
        almacen_origen: traspaso.almacen_origen._id,
        almacen_destino: traspaso.almacen_destino._id,
        productos: traspaso.productos,
        sucursalOrigen: traspaso.sucursalOrigen._id,
        sucursalDestino: traspaso.sucursalDestino._id,
      };
      setLoading(true);
      const response = await aceptarTrasEntreSuc({
        variables: {
          input,
          usuario: tokenDecoded._id,
          empresa: tokenDecoded.empresa._id,
        },
      });
      snackMessage({
        message: response.data.aceptarTrasEntreSuc.message,
        variant: "success",
      });
      syncDataBases();
      traspasosQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <Button
      size="small"
      color="primary"
      variant="contained"
      disableElevation
      sx={{ textTransform: "none" }}
      onClick={handleSaveTraspaso}
      startIcon={
        loading ? <CircularProgress size={20} color="inherit" /> : <Done />
      }
    >
      Guardar
    </Button>
  );
}
