import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { ArrowBack, Search } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import {
  Box,
  IconButton,
  Grid,
  Container,
  TextField,
  InputAdornment,
  DialogTitle,
} from "@material-ui/core";
import ListaUsuarios from "./ListaUsuario";
import CrearUsuario from "./CrearUsuario";
import { UsuarioProvider } from "../../../../context/Catalogos/usuarioContext";
import UsuariosInactivosComponent from "./UsuariosInactivos";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { FcManager } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(1),
    flex: 1,
    flexGrow: 1,
  },
  icon: {
    width: 100,
  },
  root: {
    display: "flex",
    paddingLeft: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Usuarios() {
  const { isOnline } = useContext(AccesosContext);
  const classes = useStyles();
  const permisosUsuario = JSON.parse(localStorage.getItem("sesionCafi"));

  const [open, setOpen] = React.useState(false);
  const [filtro, setFiltro] = useState("");
  const [values, setValues] = useState("");
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const pressEnter = (e) => {
    if (e.key === "Enter") setFiltro(e.target.defaultValue);
  };

  return (
    <div>
      <UsuarioProvider>
        <Button fullWidth onClick={handleClickOpen}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <FcManager style={{ fontSize: 100 }} />
            </Box>
            Usuarios
          </Box>
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle component="div">
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Typography variant="h6" className={classes.title}>
                Usuarios
              </Typography>
              {permisosUsuario.accesos.catalogos.usuarios.agregar ===
              false ? null : (
                <UsuariosInactivosComponent isOnline={isOnline} />
              )}
              <Box mx={1} />
              {permisosUsuario.accesos.catalogos.usuarios.agregar ===
              false ? null : (
                <CrearUsuario
                  accion="registrar"
                  datos={undefined}
                  isOnline={isOnline}
                />
              )}
              <Box mx={1} />
              <IconButton
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                <ArrowBack />
              </IconButton>
            </Box>
          </DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Container maxWidth="lx">
              <Grid container spacing={2}>
                <Grid item xs={8} md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Buscar usuarios por nombre o correo..."
                    onChange={(e) => setValues(e.target.value)}
                    onKeyPress={pressEnter}
                    value={values}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton onClick={() => setFiltro(values)}>
                            <Search />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Box my={1}>
                <ListaUsuarios
                  isOnline={isOnline}
                  sucursal={sesion.sucursal._id}
                  empresa={sesion.empresa._id}
                  filtro={filtro}
                />
              </Box>
            </Container>
          </Box>
        </Dialog>
      </UsuarioProvider>
    </div>
  );
}
