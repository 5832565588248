import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  TablePagination,
  Checkbox,
} from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import { OBTENER_CORTE_CAJA } from "../../../../gql/Cajas/cajas";
import { useLazyQuery } from "@apollo/client";
import { formatoFechaCorta } from "../../../../config/reuserFunctions";
import { imprimirTicketCorte } from "./PrintTicketCorte";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import InformacionCorteCaja from "./TablaCorte";
import moment from "moment";

const columns = [
  { label: "Fecha Corte", minWidth: 100 },
  { label: "Hora Corte", minWidth: 100 },
  { label: "Usuario", minWidth: 100 },
  { label: "Turno Horario", minWidth: 100 },
  { label: "Caja", minWidth: 100 },
  { label: "Sucursal", minWidth: 100 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    height: "65vh",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export default function InfoCorteCaja({
  loading,
  cortes,
  page,
  setPage,
  limit,
}) {
  const classes = useStyles();

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="30vh"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box>
      <Paper className={classes.root} variant="outlined">
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="center"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {cortes.docs.map((row) => {
                return <RowsRender key={row._id} corte={row} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={cortes.totalDocs}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
}

function RowsRender({ corte }) {
  const [open, setOpen] = useState(false);
  const [printProducts, setPrintProducts] = useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [alert, setAlert] = useState({ message: "", status: "", open: false });

  const [obtenerCorte, { data, error, loading }] = useLazyQuery(
    OBTENER_CORTE_CAJA,
    {
      variables: {
        sucursal: sesion.sucursal._id,
        empresa: sesion.empresa._id,
        input: {
          usuario: corte.id_usuario,
          caja: corte.id_caja,
          token_turno_user: corte.token_turno_user,
          number_user: corte.usuario_en_turno.numero_usuario,
          getProducts: printProducts,
        },
      },
      fetchPolicy: "network-only",
    }
  );

  let productos = [];
  let montos_sistema = null;
  let datos_ventas = null;
  let fechaInicio = "";
  let fechaFin = "";

  const handleClickOpen = () => {
    setOpen(!open);
    if (!open) {
      obtenerCorte();
    }
  };

  if (error) {
    console.log(error);
    return (
      <>
        <TableRow
          hover
          role="checkbox"
          handleClickOpen
          onClick={handleClickOpen}
          tabIndex={-1}
          key={corte._id}
        >
          <TableCell align="center">
            {formatoFechaCorta(corte.fecha_salida.completa)}
          </TableCell>
          <TableCell align="center">{corte.hora_salida.completa}</TableCell>
          <TableCell align="center">{corte.usuario_en_turno.nombre}</TableCell>
          <TableCell align="center">{corte.horario_en_turno}</TableCell>
          <TableCell align="center">{corte.numero_caja}</TableCell>
          <TableCell align="center">{corte.sucursal.nombre_sucursal}</TableCell>
        </TableRow>

        <Dialog open={open} onClose={handleClickOpen}>
          <DialogContent>
            <DialogContentText>
              Ocurrió un error con este corte de caja
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              size="small"
              onClick={() => handleClickOpen()}
            >
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  if (data) {
    productos = data.obtenerCorteCaja.productos;
    montos_sistema = data.obtenerCorteCaja.montos_en_sistema;
    datos_ventas = data.obtenerCorteCaja.datos_ventas;
    fechaInicio = data.obtenerCorteCaja.fecha_inicio;
    fechaFin = data.obtenerCorteCaja.fecha_fin;
  }

  const handlePrintTicket = async () => {
    try {
      setLoadingPrint(true);
      const result = await imprimirTicketCorte(
        sesion,
        corte,
        montos_sistema,
        datos_ventas,
        fechaInicio,
        fechaFin,
        productos
      );
      if (result.error) {
        setAlert({
          message: "Error de conexión con la impresora",
          status: "error",
          open: true,
        });
      }
      setLoadingPrint(false);
    } catch (error) {
      console.log(error);
      setLoadingPrint(false);
      setAlert({ message: "Hubo un error", status: "error", open: true });
    }
  };

  const handleToglePrintProducts = (checked) => {
    setPrintProducts(checked);
    obtenerCorte();
  };

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        handleClickOpen
        onClick={handleClickOpen}
        tabIndex={-1}
        key={corte._id}
      >
        <TableCell align="center">
          {moment(corte.fecha_salida.completa)
            .tz("America/Mexico_City")
            .format("DD/MM/YYYY")}
        </TableCell>
        <TableCell align="center">{corte.hora_salida.completa}</TableCell>
        <TableCell align="center">{corte.usuario_en_turno.nombre}</TableCell>
        <TableCell align="center">{corte.horario_en_turno}</TableCell>
        <TableCell align="center">{corte.numero_caja}</TableCell>
        <TableCell align="center">{corte.sucursal.nombre_sucursal}</TableCell>
      </TableRow>

      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        onClose={handleClickOpen}
      >
        <SnackBarMessages alert={alert} setAlert={setAlert} />
        <DialogContent>
          <InformacionCorteCaja
            corte={corte}
            montos_sistema={montos_sistema}
            datos_ventas={datos_ventas}
            loading={loading}
            fechaInicio={fechaInicio}
            fechaFin={fechaFin}
          />
        </DialogContent>
        <DialogActions>
          <FormControlLabel
            control={
              <Checkbox
                value={printProducts}
                onChange={(e) => handleToglePrintProducts(e.target.checked)}
              />
            }
            label="Imprimir Lista productos"
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => handlePrintTicket()}
            disabled={loadingPrint}
            startIcon={
              loadingPrint ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <ReceiptIcon />
              )
            }
          >
            Imprimir Ticket
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
