import React, { useEffect, useState } from "react";
import { FaRegFilePdf } from "react-icons/fa";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../../config/reuserFunctions";
import { formaPago } from "../../../Facturacion/catalogos";
import {
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import cafiLogo from "../../../../../img/Cafi.svg";
import {
  Close,
  CheckBoxOutlineBlankOutlined,
  CheckBox,
  InfoOutlined,
  GetApp,
} from "@material-ui/icons";
import moment from "moment-timezone";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useApolloClient } from "@apollo/client";
import { OBTENER_REPORTE_VENTAS_VENTA } from "../../../../../gql/Ventas/ventas_generales";
import { ModalSeleccion } from "./ModalSeleccion";
import EditIcon from "@material-ui/icons/Edit";
import { TablaConcepto } from "./TablaConcepto";

const useStyles = makeStyles((theme) => ({
  textTable: {
    fontSize: "12px",
  },
  filtros: {
    color: "#2980ba",
    fontSize: "12px",
  },

  size: {
    width: "22.5cm",
    overflowX: "auto",
  },
  tabla: {
    width: "100%",
  },
  encabezado: {
    backgroundColor: "#2980ba",
    color: "#fff",
    fontSize: "12px",
  },
}));

const ITEM_HEIGHT = 200;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

export default function ExportarRVPDF({ filtros, initial_state_filtros }) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState(false);
  const [concepto, setConcepto] = React.useState("general");
  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const [openModal, setOpenModal] = useState(false);
  const client = useApolloClient();

  const handleClick = () => {
    setOpenModal(!openModal);
  };

  /* Queries */
  const getProductos = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: OBTENER_REPORTE_VENTAS_VENTA,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          filtros,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoading(false);
      if (response.data) {
        setData(response.data.obtenerVentasByVentaReportes);
        setOpen(true);
      }
      if (response.error) setError(true);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    getProductos();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeConcepto = (event) => {
    setData({ ...data, concepto: event.target.value });
  };

  const obtenerCamposConcepto = (e) => {
    setConcepto(e.target.value);
  };

  const printDocument = () => {
    setLoadingPdf(true);
    const divInformation = document.getElementById(
      "filtros-reporte-ventas-container"
    );
    html2canvas(divInformation).then((canvas) => {
      const doc = new jsPDF("p", "mm", "letter");
      //Filter Information
      const imgData = canvas.toDataURL("image/png");
      const imgProps = doc.getImageProperties(imgData);
      const th = doc.internal.pageSize.getWidth() - 5;
      const conceptosWidth = 100;
      const marginTable = th - conceptosWidth;
      const pdfHeight = (imgProps.height * th) / imgProps.width;
      doc.addImage(imgData, "JPEG", 5, 0, th, pdfHeight);
      //Table information
      doc.autoTable({
        html: "#tabla-reporte-ventas",
        theme: "striped",
        // pageBreak: "always",
        // showHead: "everypage",
        startY: 47,
        margin: { left: 15, right: 15, top: 10 },
        styles: {
          fontSize: 8,
        },
        didDrawPage: function (data) {
          let footerStr = `Pagina ${data.pageNumber}`;
          doc.setFontSize(10);
          doc.text(footerStr, 185, 265);
        },
        didParseCell: function (data) {
          var rows = data.table.body;
          if (data.row.index === rows.length - 1 && concepto === "general") {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });
      doc.autoTable({
        html: "#tabla-conceptos",
        theme: "striped",
        pageBreak: "avoid",
        startY: doc.lastAutoTable.finalY + 10,
        tableWidth: conceptosWidth,
        margin: { left: marginTable - 10, top: 25 },
        styles: {
          fontSize: 8,
          halign: "right",
        },
        didParseCell: function (data) {
          var rows = data.table.body;
          if (data.row.index === rows.length - 1) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });
      doc.save("Reporte de ventas.pdf");
      setLoadingPdf(false);
      handleClose();
    });
  };

  if (loading) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        PDF
      </Button>
    );
  }

  if (error) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        PDF
      </Button>
    );
  }

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        startIcon={<FaRegFilePdf />}
        onClick={() => handleClickOpen()}
      >
        PDF
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{ style: { minWidth: "1050px" } }}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <Box style={{ flexGrow: 1 }}>
              <Box mx={1}></Box>
              <FormControl
                variant="outlined"
                size="small"
                style={{ marginTop: "5px" }}
              >
                <InputLabel>Concepto</InputLabel>
                <Select
                  label="Concepto"
                  name="concepto"
                  onChange={obtenerCamposConcepto}
                  value={concepto}
                >
                  <MenuItem value="general">
                    <em>General</em>
                  </MenuItem>
                  <MenuItem value="tipo_emision">Tipo de emisión</MenuItem>
                  <MenuItem value="usuario">Usuario</MenuItem>
                  <MenuItem value="caja">Caja</MenuItem>
                  <MenuItem value="metodo_pago">Método de pago</MenuItem>
                  <MenuItem value="forma_pago">Forma de Pago</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* <Box mx={1} /> */}
            <Button
              variant="text"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleClick}
            >
              Personalizar Reporte
            </Button>
            <Box mx={1} />
            <Button
              startIcon={
                loadingPdf ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <GetApp />
                )
              }
              color="primary"
              onClick={() => printDocument()}
            >
              Descargar
            </Button>
            <Box mx={1} />
            <Button startIcon={<Close />} onClick={handleClose}>
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ContentPDF
            data={data}
            filtros={filtros}
            initial_state_filtros={initial_state_filtros}
            handleClick={handleClick}
            openModal={openModal}
            setOpenModal={setOpenModal}
            concepto={concepto}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ContentPDF = ({
  data,
  filtros,
  initial_state_filtros,
  handleClick,
  openModal,
  setOpenModal,
  concepto,
}) => {
  const initial_filtro = {
    folio: true,
    fecha: false,
    cliente: true,
    clave_cliente: false,
    usuario: false,
    caja: true,
    tipo_emision: false,
    forma_pago: true,
    metodo_pago: false,
    credito_pendiente: false,
    fecha_limite_credito: false,
    descuento: true,
    subtotal: true,
    impuestos: false,
    total: true,
  };

  const [valuesColumnas, setValuesColumnas] = useState(initial_filtro);
  const classes = useStyles();
  const arrayCeldas = Object.values(valuesColumnas).filter(
    (res) => res === true
  );

  if (arrayCeldas.length >= 2) {
    arrayCeldas.length = arrayCeldas.length - 2;
  } else {
    arrayCeldas.shift(); //Elimina el primer elemento del array
  }

  return (
    <Box id="reporte-ventas-pdf-container">
      <FiltrosComponentPDF
        filtros={filtros}
        valuesColumnas={valuesColumnas}
        setValuesColumnas={setValuesColumnas}
        handleClick={handleClick}
        openModal={openModal}
        setOpenModal={setOpenModal}
        classes={classes}
      />
      <Box pb={5} display="flex" justifyContent="center">
        <TableContainer className={classes.size}>
          <Table
            size="small"
            id="tabla-reporte-ventas"
            className={classes.tabla}
            // stickyHeader
            // aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                {valuesColumnas.folio ? (
                  <TableCell className={classes.encabezado}>Folio</TableCell>
                ) : null}
                {valuesColumnas.fecha ? (
                  <TableCell className={classes.encabezado}>Fecha</TableCell>
                ) : null}
                {valuesColumnas.cliente ? (
                  <TableCell className={classes.encabezado}>Cliente</TableCell>
                ) : null}
                {valuesColumnas.clave_cliente ? (
                  <TableCell className={classes.encabezado}>
                    Clave cliente
                  </TableCell>
                ) : null}
                {valuesColumnas.usuario ? (
                  <TableCell className={classes.encabezado}>Usuario</TableCell>
                ) : null}
                {valuesColumnas.caja ? (
                  <TableCell className={classes.encabezado}>Caja</TableCell>
                ) : null}
                {valuesColumnas.tipo_emision ? (
                  <TableCell className={classes.encabezado}>
                    T. Emisión
                  </TableCell>
                ) : null}
                {valuesColumnas.forma_pago ? (
                  <TableCell className={classes.encabezado}>F. Pago</TableCell>
                ) : null}
                {valuesColumnas.metodo_pago ? (
                  <TableCell className={classes.encabezado}>M. Pago</TableCell>
                ) : null}
                {valuesColumnas.credito_pendiente ? (
                  <TableCell className={classes.encabezado}>
                    Cred. Pendiente
                  </TableCell>
                ) : null}
                {valuesColumnas.fecha_limite_credito ? (
                  <TableCell className={classes.encabezado}>
                    F. lim. cred.
                  </TableCell>
                ) : null}
                {valuesColumnas.subtotal ? (
                  <TableCell className={classes.encabezado}>SubTotal</TableCell>
                ) : null}
                {valuesColumnas.descuento ? (
                  <TableCell className={classes.encabezado}>Desc.</TableCell>
                ) : null}
                {valuesColumnas.impuestos ? (
                  <TableCell className={classes.encabezado}>
                    Impuestos
                  </TableCell>
                ) : null}
                {valuesColumnas.total ? (
                  <TableCell className={classes.encabezado}>Total</TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.docs.map((data, index) => {
                let forma_pago = "-";
                if (data.forma_pago) {
                  const resultForma = formaPago.filter(
                    (res) => res.Value === data.forma_pago
                  );
                  forma_pago = resultForma[0];
                }
                return (
                  <TableRow key={index} role="checkbox" tabIndex={-1}>
                    {valuesColumnas.folio ? (
                      <TableCell className={classes.textTable}>
                        {data.folio}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.fecha ? (
                      <TableCell className={classes.textTable}>
                        {moment(data.fecha_registro)
                          .tz("America/Mexico_City")
                          .format("DD/MM/YYYY")}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.cliente ? (
                      <TableCell className={classes.textTable}>
                        {data.cliente !== null
                          ? data.cliente.nombre_cliente
                          : "Público general"}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.clave_cliente ? (
                      <TableCell className={classes.textTable}>
                        {data.clave_cliente}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.usuario ? (
                      <TableCell className={classes.textTable}>
                        {data.usuario.nombre}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.caja ? (
                      <TableCell className={classes.textTable}>
                        {data.id_caja.numero_caja}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.tipo_emision ? (
                      <TableCell className={classes.textTable}>
                        {data.tipo_emision}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.forma_pago ? (
                      <TableCell
                        className={classes.textTable}
                      >{`${forma_pago.Name}`}</TableCell>
                    ) : null}

                    {valuesColumnas.metodo_pago ? (
                      <TableCell className={classes.textTable}>{`${
                        data.credito ? "Credito" : "Contado"
                      }`}</TableCell>
                    ) : null}

                    {valuesColumnas.credito_pendiente ? (
                      <TableCell className={classes.textTable}>
                        {`$${
                          data.credito
                            ? formatoMexico(data.saldo_credito_pendiente)
                            : 0
                        }`}
                      </TableCell>
                    ) : null}

                    {valuesColumnas.fecha_limite_credito ? (
                      <TableCell className={classes.textTable}>
                        {data.credito
                          ? moment(data.fecha_de_vencimiento_credito)
                              .tz("America/Mexico_City")
                              .format("DD/MM/YYYY")
                          : "-"}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.subtotal ? (
                      <TableCell className={classes.textTable}>
                        ${formatoMexico(data.subTotal)}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.descuento ? (
                      <TableCell className={classes.textTable}>
                        {`$${
                          data.descuento ? formatoMexico(data.descuento) : 0
                        }`}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.impuestos ? (
                      <TableCell className={classes.textTable}>
                        ${formatoMexico(data.impuestos)}
                      </TableCell>
                    ) : null}
                    {valuesColumnas.total ? (
                      <TableCell className={classes.textTable}>
                        ${formatoMexico(data.total)}
                      </TableCell>
                    ) : null}
                  </TableRow>
                );
              })}
              {concepto === "general" ? (
                <TableRow>
                  {arrayCeldas.map((columna, index) => {
                    if (columna === true) {
                      return <TableCell key={index} />;
                    } else {
                      return null;
                    }
                  })}
                  <TableCell style={{ fontWeight: "800", flex: 1 }}>
                    Total
                  </TableCell>
                  <TableCell style={{ fontWeight: "800" }}>
                    ${formatoMexico(data.totalVenta)}
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablaConcepto concepto={concepto} data={data}></TablaConcepto>
    </Box>
  );
};

const FiltrosComponentPDF = ({
  filtros,
  valuesColumnas,
  setValuesColumnas,
  handleClick,
  openModal,
  setOpenModal,
  classes,
}) => {
  const {
    fecha_inicio,
    fecha_fin,
    usuario,
    cliente,
    caja,
    publico_general,
    metodo_pago,
    forma_pago,
    canceladas,
    facturadas,
    notas_credito,
    vigentes,
    vencidas,
    liquidadas,
  } = filtros;
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const obtenerFormaPago = () => {
    let formaP = "Todas";
    if (forma_pago) {
      const forma = formaPago.filter((res) => res.Value === forma_pago);
      formaP = forma[0].Name;
    }

    return formaP;
  };

  let imagenEmpresa = sesion.empresa.imagen;

  return (
    <Box px={8.5} py={4.7} id="filtros-reporte-ventas-container">
      <Box
        display="flex"
        style={{ height: 80 }}
        alignItems="center"
        justifyContent="center"
        mb={2}
      >
        <Typography variant="h5" align="center">
          Reporte de ventas {fecha_inicio && fecha_fin ? "del " : ""}{" "}
          {formatoFechaCorta(fecha_inicio)}{" "}
          {fecha_inicio && fecha_fin ? "al " : ""}{" "}
          {formatoFechaCorta(fecha_fin)}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={2}>
          <Box display="flex" style={{ height: 60 }} alignItems="center">
            <img
              src={imagenEmpresa ? imagenEmpresa : cafiLogo}
              alt="cafi punto de venta"
              height="100%"
            />
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex">
            <Typography className={classes.filtros}>
              <b>Sucursal:</b>
            </Typography>
            <Box mx={1} />
            <Typography className={classes.textTable}>
              {sesion ? sesion.sucursal.nombre_sucursal : "Todos"}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography className={classes.filtros}>
              <b>Vendedor:</b>
            </Typography>
            <Box mx={1} />
            <Typography className={classes.textTable}>
              {usuario ? usuario : "Todos"}
            </Typography>
          </Box>

          <Box display="flex">
            <Typography className={classes.filtros}>
              <b>Cliente:</b>
            </Typography>
            <Box mx={1} />
            <Typography className={classes.textTable}>
              {publico_general
                ? "Público general"
                : cliente
                ? cliente
                : "Todos"}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Box display="flex">
            <Typography className={classes.filtros}>
              <b>Caja:</b>
            </Typography>
            <Box mx={1} />
            <Typography className={classes.textTable}>
              {caja ? caja : "Todas"}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography className={classes.filtros}>
              <b>Método de pago:</b>
            </Typography>
            <Box mx={1} />
            <Typography className={classes.textTable}>
              {metodo_pago
                ? metodo_pago === "PPD"
                  ? "crédito"
                  : "Contado"
                : "Todos"}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography className={classes.filtros}>
              <b>Forma de pago:</b>
            </Typography>
            <Box mx={1} />
            <Typography className={classes.textTable}>
              {obtenerFormaPago()}
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex">
            {canceladas ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankOutlined />
            )}
            <Box mx={0.5} />
            <Typography className={classes.textTable}>
              <b>Canceladas</b>
            </Typography>
          </Box>
          <Box display="flex">
            {facturadas ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankOutlined />
            )}
            <Box mx={0.5} />
            <Typography className={classes.textTable}>
              <b>Facturadas</b>
            </Typography>
          </Box>

          <Box display="flex">
            {notas_credito ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankOutlined />
            )}
            <Box mx={0.5} />
            <Typography className={classes.textTable}>
              <b>Notas crédito</b>
            </Typography>
          </Box>
        </Grid>

        <Grid item>
          <Box display="flex">
            {vigentes ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankOutlined />
            )}
            <Box mx={0.5} />
            <Typography className={classes.textTable}>
              <b>Vigentes</b>
            </Typography>
          </Box>

          <Box display="flex">
            {vencidas ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankOutlined />
            )}
            <Box mx={0.5} />
            <Typography className={classes.textTable}>
              <b>Vencidas</b>
            </Typography>
          </Box>

          <Box display="flex">
            {liquidadas ? (
              <CheckBox color="primary" />
            ) : (
              <CheckBoxOutlineBlankOutlined />
            )}
            <Box mx={0.5} />
            <Typography className={classes.textTable}>
              <b>Pagadas</b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <ModalSeleccion
        openModal={openModal}
        setOpenModal={setOpenModal}
        valuesColumnas={valuesColumnas}
        setValuesColumnas={setValuesColumnas}
      />
    </Box>
  );
};
