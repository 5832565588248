import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../../config/reuserFunctions";
import moment from "moment";

const columns = [
  { id: "fecha", label: "Fecha", minWidth: 150 },
  { id: "hora", label: "Fecha y hora", minWidth: 150 },
  { id: "movimiento", label: "Movimiento", minWidth: 150 },
  { id: "usuario", label: "Cajero", minWidth: 200 },
  { id: "hTurno", label: "Horario Turno", minWidth: 150 },
  { id: "concepto", label: "Concepto", minWidth: 100 },
  { id: "monto_cheques", label: "Cheques", minWidth: 80 },
  { id: "monto_creditos", label: "Crédito", minWidth: 80 },
  { id: "monto_efectivo", label: "Efectivo", minWidth: 100 },
  { id: "monto_monedero", label: "Monedero", minWidth: 100 },
  { id: "monto_tarjeta_credito", label: "Tarjeta de crédito", minWidth: 152 },
  { id: "monto_tarjeta_debito", label: "Tarjeta de débito", minWidth: 149 },
  { id: "monto_transferencia", label: "Transferencia", minWidth: 100 },
  { id: "monto_vales_despensa", label: "Vales de despensa", minWidth: 160 },
];

const useStyles = makeStyles((theme) => ({
  tipo_movimiento: {
    minWidth: columns[2].minWidth,
    position: "sticky",
    left: 0,
  },
  root: {
    width: "100%",
  },
  container: {
    height: "70vh",
  },
}));

export default function TablaReportesCajas({
  obtenerHistorialCaja,
  page,
  setPage,
  loading,
}) {
  const classes = useStyles();
  const [shadow, setShadow] = React.useState("removeShadowTable");
  const tableEl = React.useRef();

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    let sumaWidths = columns[0].minWidth + columns[1].minWidth;
    if (scrollLeft < sumaWidths) {
      setShadow("removeShadowTable");
      return;
    }
    setShadow("rightShadowTable");
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;

    changeClassName(scrollLeft, realWidth);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="30vh"
      >
        <CircularProgress />
      </Box>
    );

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <Box>
      <Paper className={classes.root}>
        <TableContainer
          className={classes.container}
          ref={tableEl}
          onScroll={handleOnScroll}
        >
          <Table size="small" aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    align="center"
                    className={index === 2 ? shadow : null}
                    style={{
                      minWidth: column.minWidth,
                      position: index === 2 ? "sticky" : "unset",
                      left: 0,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {obtenerHistorialCaja
                ? obtenerHistorialCaja.docs.map((row, index) => {
                    return (
                      <RowsRender key={index} datos={row} shadow={shadow} />
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={obtenerHistorialCaja.totalDocs}
          rowsPerPage={20}
          page={page}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
}

function RowsRender({ datos, shadow }) {
  const concepto = datos.concepto
    ? datos.concepto
    : datos.concepto_cuenta
    ? datos.concepto_cuenta
    : "";
  return (
    <>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        style={{ position: "relative" }}
      >
        <TableCell align="center">
          {moment(datos.fecha_movimiento.completa)
            .tz("America/Mexico_City")
            .format("DD/MM/YYYY")}
        </TableCell>
        <TableCell align="center">
          {moment(datos.fecha_movimiento.completa)
            .tz("America/Mexico_City")
            .format("DD/MM/YYYY, hh:mm a")}
        </TableCell>
        <TableCell
          align="center"
          className={shadow}
          style={{
            position: "sticky",
            left: 0,
          }}
        >
          {datos.tipo_movimiento}
        </TableCell>
        <TableCell align="center">{datos.nombre_usuario_creador}</TableCell>
        <TableCell align="center">{datos.horario_turno}</TableCell>
        <TableCell align="center">{concepto}</TableCell>
        <TableCell align="center">
          {"$" + formatoMexico(datos.montos_en_caja.monto_cheques.monto)}
        </TableCell>
        <TableCell align="center">
          {"$" + formatoMexico(datos.montos_en_caja.monto_creditos.monto)}
        </TableCell>
        <TableCell align="center">
          {"$" + formatoMexico(datos.montos_en_caja.monto_efectivo.monto)}
        </TableCell>
        <TableCell align="center">
          {"$" + formatoMexico(datos.montos_en_caja.monto_monedero.monto)}
        </TableCell>
        <TableCell align="center">
          {"$" +
            formatoMexico(datos.montos_en_caja.monto_tarjeta_credito.monto)}
        </TableCell>
        <TableCell align="center">
          {"$" + formatoMexico(datos.montos_en_caja.monto_tarjeta_debito.monto)}
        </TableCell>
        <TableCell align="center">
          {"$" + formatoMexico(datos.montos_en_caja.monto_transferencia.monto)}
        </TableCell>
        <TableCell align="center">
          {"$" + formatoMexico(datos.montos_en_caja.monto_vales_despensa.monto)}
        </TableCell>
      </TableRow>
    </>
  );
}
