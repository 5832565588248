import React, { useContext, useState } from "react";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import ListaProductos from "./ListaProductos";
import { RegProductoContext } from "../../../../context/Catalogos/CtxRegProducto";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import {
  CircularProgress,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { OBTENER_PRODUCTOS } from "../../../../gql/Catalogos/productos";
import ErrorPage from "../../../../components/ErrorPage";
import TopHeader from "./Header/TopHeader";
import BottomHeader from "./Header/BottomHeader";
import { FcPackage } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  iconSvg: {
    width: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initial_state_filtro = {
  producto: "",
  cat: "",
  subcat: "",
  departamento: "",
  marca: "",
  tipo: "",
  unidad_compra: "",
  con_existencia: false,
  sin_existencia: false,
};

export default function Productos() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { isOnline } = useContext(AccesosContext);
  const { alert, setAlert } = useContext(RegProductoContext);
  const [values, setValues] = useState(initial_state_filtro);
  const [page, setPage] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth onClick={() => handleClickOpen()}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcPackage style={{ fontSize: 100 }} />
          </Box>
          Productos
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <SnackBarMessages alert={alert} setAlert={setAlert} />
        <DialogTitle component="div" style={{ paddingBottom: 4 }}>
          <Box>
            <TopHeader
              values={values}
              setValues={setValues}
              setPage={setPage}
              isOnline={isOnline}
              handleClose={handleClose}
              initialState={initial_state_filtro}
            />
            <BottomHeader
              values={values}
              setValues={setValues}
              setPage={setPage}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <RegistroComponent
            setAlert={setAlert}
            values={values}
            page={page}
            setPage={setPage}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const RegistroComponent = ({ setAlert, values, page, setPage }) => {
  const { isOnline } = useContext(AccesosContext);
  const { updateProductos } = useContext(RegProductoContext);
  const [value] = useDebounce(values, 500);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const limit = 20;

  const { data, loading, error, refetch } = useQuery(OBTENER_PRODUCTOS, {
    variables: {
      sucursal: sesion.sucursal._id,
      empresa: sesion.empresa._id,
      filtros: value,
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    refetch();
  }, [updateProductos]);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="30vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) {
    return <ErrorPage error={error} />;
  }

  const { obtenerProductos } = data;

  return (
    <div>
      <ListaProductos
        setPage={setPage}
        page={page}
        limit={limit}
        isOnline={isOnline}
        obtenerProductos={obtenerProductos}
      />
    </div>
  );
};
