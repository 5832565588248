import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import { Add, Done } from "@material-ui/icons";
import TipoBanner from "./TipoBanner";
import SelectArticulos from "./SelectArticulos";
import PresetsCardsEditable from "../Presets/PressetsEditable";
import { TiendaCtx } from "../../../../../../../context/Empresa/tiendaCtx";
import { CircularProgress } from "@material-ui/core";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  CREAR_CUSTOM_BANNER,
  GET_ATTRIBUTES_PRODUCT,
} from "../../../../../../../gql/Empresa/tienda";
import { AccesosContext } from "../../../../../../../context/Accesos/accesosCtx";

const initial_attr = {
  data: undefined,
  error: undefined,
  loading: false,
};

export default function CrearCustomBanner() {
  const client = useApolloClient();
  const [crearCustomBanner] = useMutation(CREAR_CUSTOM_BANNER);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { isOnline } = React.useContext(AccesosContext);
  const { tienda, setAlert } = React.useContext(TiendaCtx);
  const [attr, setAttr] = React.useState(initial_attr);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [tipo, setTipo] = React.useState("");
  const [titulo, setTitulo] = React.useState("");
  const [tipoArticulo, setTipoArticulo] = React.useState({
    tipo: "",
    filtro: "",
  });
  const [bigBanner, setBigBanner] = React.useState({
    image: "",
    preview: "",
  });
  const [sideBanner, setSideBanner] = React.useState({
    image: "",
    preview: "",
  });

  const getAttributes = async () => {
    try {
      setAttr((st) => ({ ...st, loading: true }));
      const { loading, data, error } = await client.query({
        query: GET_ATTRIBUTES_PRODUCT,
        variables: {
          empresa: sesion.empresa._id,
        },
        fetchPolicy: "network-only",
      });
      if (data) {
        setAttr({ data: data.getProductAttributs, loading, error });
      }
      if (error) {
        setAttr({ data, error, loading });
      }
    } catch (error) {
      console.log(error);
      if (error.networkError) console.log(error.networkError.result);
      setAttr({ data: undefined, error, loading });
    }
  };

  const cleanStates = () => {
    setTipo("");
    setTitulo("");
    setTipoArticulo({ tipo: "", filtro: "" });
    setBigBanner({ image: "", preview: "" });
    setSideBanner({ image: "", preview: "" });
  };

  const handleClickOpen = () => {
    setOpen(true);
    getAttributes();
  };

  const handleClose = () => {
    setOpen(false);
    cleanStates();
  };

  const handleSaveBanner = async () => {
    try {
      setLoading(true);
      //create input
      let input = {
        typeBanner: tipo,
        title: titulo,
        getFrom: tipoArticulo.tipo,
        filter: tipoArticulo.filtro,
      };
      if (bigBanner.image) input.banner = bigBanner.image;
      if (sideBanner.image) input.sides = sideBanner.image;
      const resp = await crearCustomBanner({
        variables: {
          input,
          tienda: tienda.data._id,
        },
      });
      setLoading(false);
      tienda.refetch();
      setAlert({
        message: resp.data.crearCustomBanner.message,
        status: "success",
        open: true,
      });
      handleClose();
    } catch (error) {
      console.log(error);
      if (error.networkError) console.log(error.networkError.result);
      setLoading(false);
      setAlert({ message: error.message, status: "error", open: true });
    }
  };

  return (
    <Box my={1}>
      <Box display="flex" justifyContent="center">
        <Button disabled={!isOnline} startIcon={<Add />} color="primary" onClick={handleClickOpen}>
          Nuevo banner
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogContent>
          <TextField
            margin="dense"
            label="Titulo (opcional)"
            size="small"
            fullWidth
            variant="outlined"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
          />
          <SelectArticulos
            tipoArticulo={tipoArticulo}
            setTipoArticulo={setTipoArticulo}
            attr={attr}
          />
          <TipoBanner tipo={tipo} setTipo={setTipo} />
          <PresetsCardsEditable
            type={tipo}
            bigBanner={bigBanner}
            setBigBanner={setBigBanner}
            sideBanner={sideBanner}
            setSideBanner={setSideBanner}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={handleSaveBanner}
            color="primary"
            disabled={loading || !isOnline}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : <Done />
            }
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
