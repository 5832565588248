import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import { CropOriginal, Menu } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { Img } from "react-image";
import { formatoMexico } from "../../../config/reuserFunctions";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "30vw",
    height: "calc(100vh - 72px)",
    top: 58,
    right: 20,
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
  },
}));

export default function DetalleClienteVentas({ data }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const nombre = data.nombre_cliente ? data.nombre_cliente.split(" ") : "";

  const handleDrawerToogle = () => {
    setOpen(!open);
  };

  function MyImageComponent({ imagen }) {
    return imagen ? (
      <Img
        src={[imagen]}
        style={{ maxHeight: "100%", maxWidth: "100%", borderRadius: 5 }}
      />
    ) : (
      <CropOriginal fontSize="large" color="disabled" />
    );
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleDrawerToogle} size="small">
        <Menu />
      </IconButton>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper }}
        elevation={0}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0)",
          },
        }}
        onClose={handleDrawerToogle}
      >
        <DialogTitle component="div">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">{nombre[0]}</Typography>
            <Button
              size="small"
              color="inherit"
              variant="contained"
              disableElevation
              style={{ textTransform: "none" }}
              onClick={handleDrawerToogle}
              startIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" gridGap={10}>
            <Box
              height={100}
              width={100}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={5}
            >
              <MyImageComponent imagen={data.imagen} />
            </Box>
            <Box py={1}>
              <Typography variant="subtitle2">{`${data.nombre_cliente}`}</Typography>
              <Typography variant="caption">{`No. cliente: ${data.clave_cliente}`}</Typography>
              <Typography variant="body2">{`Tel ${data.telefono}`}</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gridGap={10}>
            <Box p={1}>
              <Typography variant="subtitle2">Dirección</Typography>
              <Divider />
              <Typography variant="caption">{`${data.direccion?.calle} ${data.direccion?.no_ext} ${data.direccion?.colonia} CP${data.direccion?.codigo_postal}`}</Typography>
              <Typography variant="caption">{`${data.direccion?.municipio} ${data.direccion?.estado} ${data.direccion?.pais}`}</Typography>
            </Box>
            <Box p={1}>
              <Typography variant="subtitle2">Crédito</Typography>
              <Divider />
              <Typography
                variant="caption"
                style={{ display: "block" }}
              >{`Disponible: $${formatoMexico(
                data.credito_disponible
              )}`}</Typography>
              <Typography
                variant="caption"
                style={{ display: "block" }}
              >{`Límite: $${formatoMexico(data.limite_credito)}`}</Typography>
              <Typography
                variant="caption"
                style={{ display: "block" }}
              >{`Dinero electrónico: $${formatoMexico(
                data.monedero_electronico
              )}`}</Typography>
            </Box>
            <Box p={1}>
              <Typography variant="subtitle2">Facturación</Typography>
              <Divider />
              <Typography
                variant="caption"
                style={{ display: "block" }}
              >{`Razón social: ${data.razon_social}`}</Typography>
              <Typography
                variant="caption"
                style={{ display: "block" }}
              >{`Régimen fiscal: ${data.regimen_fiscal}`}</Typography>
              <Typography
                variant="caption"
                style={{ display: "block" }}
              >{`RFC: ${data.rfc}`}</Typography>
            </Box>
          </Box>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}
