import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CafiIcon from "../icons/Custom/CafiIcon";
import "./style.css";

export default function CustomLoader({ height = "90vh", size = 40 }) {
  return (
    <div>
      <Box
        sx={{
          height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <CafiIcon color="secondary" size={size} className="animate-loading" />
        <Box sx={{ my: 1 }} />
        <Typography color="gray" align="center" variant="h6">Cargando</Typography>
      </Box>
    </div>
  );
}
