import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import HistoryIcon from "@material-ui/icons/History";
import Table from "@material-ui/core/Table";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DetalleVentaCredito from "./AbonarSeleccion/DetalleVentaCredito";
import HistorialAbonos from "./AbonarSeleccion/HistorialAbonos";
import IconLiquidar from "./Liquidar";
import Abonar from "./Abonar";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../../../config/reuserFunctions";
import { Dehaze } from "@material-ui/icons";
//import DetallesCuenta from './DetalleCuenta/DetallesCuenta';

const rows = [];

const headCells = [
  { id: "folio", numeric: false, disablePadding: true, label: "Folio" },
  {
    id: "fecha",
    minWidth: 180,
    numeric: false,
    disablePadding: true,
    label: "Fecha",
  },
  {
    id: "fechaVencimiento",
    numeric: false,
    minWidth: 180,
    disablePadding: true,
    label: "Fecha de vencimiento",
  },
  {
    id: "cliente",
    minWidth: 270,
    numeric: false,
    disablePadding: true,
    label: "Cliente",
  },
  {
    id: "totalVenta",
    minWidth: 130,
    numeric: false,
    disablePadding: true,
    label: "Total venta",
  },
  {
    id: "faltaPagar",
    minWidth: 150,
    numeric: false,
    disablePadding: true,
    label: "Falta por pagar",
  },

  {
    id: "detalle",
    label: "Detalles",
    minWidth: 40,
  },
  {
    id: "historialAbonos",
    label: "Historial",
    minWidth: 40,
  },
  {
    id: "abonar",
    label: "Abonar",
    minWidth: 40,
  },
  {
    id: "liquidar",
    label: "Liquidar",
    minWidth: 40,
  },
];
const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          let isSticky =
            headCell.id === "liquidar" ||
            headCell.id === "abonar" ||
            headCell.id === "historialAbonos" ||
            headCell.id === "detalle";
          return (
            <TableCell
              key={headCell.id}
              style={{ minWidth: headCell.minWidth }}
              align={headCell.numeric ? "right" : "left"}
              className={
                isSticky
                  ? headCell.id === "liquidar"
                    ? props.classes.colFixedLiquidar
                    : headCell.id === "abonar"
                    ? props.classes.colFixedAbonar
                    : headCell.id === "historialAbonos"
                    ? props.classes.colFixedHistorial
                    : props.classes.colFixedDetalles
                  : ""
              }
            >
              {sesion.accesos.tesoreria.abonos_clientes.agregar === false
                ? headCell.icon
                : null}
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  container: {
    height: "60vh",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagada: {
    backgroundColor: "#EDFFF3",
    "&:hover": {
      backgroundColor: "#D8FFE5",
    },
  },
  vencidas: {
    backgroundColor: "#FFF4E1",
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
  vencer: {
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
  canceladas: {
    backgroundColor: "#FFF4F4",
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
  colFixedDetalles: {
    posision: "sticky",
    right: 265,
  },
  colFixedHistorial: {
    posision: "sticky",
    right: 170,
  },
  colFixedAbonar: {
    posision: "sticky",
    right: 85,
  },
  colFixedLiquidar: {
    posision: "sticky",
    right: 0,
  },
}));

export default function TablaVentasCredito(props) {
  const classes = useStyles();
  const tableEl = React.useRef();
  const [selected, setSelected] = useState([]);
  /* const { setVentas} = useContext(AbonosCtx); */
  const [openDetalle, setOpenDetalle] = useState(false);
  const [openHistorial, setOpenHistorial] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const [shadow, setShadow] = useState("leftShadowTable");
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === realWidth) {
      setShadow("removeShadowTable");
      return;
    }
    setShadow("leftShadowTable");
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth - 0.5;
    changeClassName(scrollLeft, realWidth);
  };
  /* const handleClick = (event, item) => {
         try {
			let newSelected = [];
			const selectedIndex = selected.indexOf(item);
			//console.log(selected,selectedIndex);
			
			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, item);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
			}
			
			setSelected(newSelected);
			setVentas(newSelected); 
		 } catch (error) {
			 
		 }
	
		
	}; */
  const detalle = (row) => {
    setRowSelected(row);
    setOpenDetalle(true);
  };

  const historial = (row) => {
    setRowSelected(row);
    setOpenHistorial(true);
  };

  const isSelected = (item) => selected.indexOf(item) !== -1;
  const handleChangePage = (_, nextPage) => {
    props.setPage(nextPage);
  };

  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer
          ref={tableEl}
          onScroll={handleOnScroll}
          className={classes.container}
        >
          <Table stickyHeader size="small" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
            />
            <TableBody>
              {props.rows.docs.map((row, index) => {
                const isItemSelected = isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    key={index}
                    // onClick={(event) => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    selected={isItemSelected}
                    className={
                      row.status === "CANCELADO"
                        ? classes.canceladas
                        : row.estatus_credito === "PAGADA"
                        ? classes.pagada
                        : row.estatus_credito === "VENCIDA"
                        ? classes.vencidas
                        : classes.vencer
                    }
                  >
                    <TableCell id={labelId}>{row.folio}</TableCell>
                    <TableCell>{` ${formatoFechaCorta(
                      row.fecha_registro
                    )}`}</TableCell>
                    <TableCell>
                      {formatoFechaCorta(row.fecha_de_vencimiento_credito)}
                    </TableCell>
                    <TableCell>{row.cliente.nombre_cliente}</TableCell>
                    <TableCell>${formatoMexico(row.total)}</TableCell>
                    <TableCell>
                      ${formatoMexico(row.saldo_credito_pendiente)}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="checkbox"
                      style={{
                        position: "sticky",
                        right: 265,
                        backgroundColor: "white",
                      }}
                      className={shadow}
                    >
                      <IconButton
                        aria-label="detalle"
                        onClick={() => {
                          detalle(row);
                        }}
                        size="small"
                      >
                        <Dehaze />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="checkbox"
                      style={{
                        position: "sticky",
                        right: 170,
                        backgroundColor: "white",
                      }}
                    >
                      <IconButton
                        aria-label="historial"
                        onClick={() => {
                          historial(row);
                        }}
                        size="small"
                      >
                        <HistoryIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="checkbox"
                      style={{
                        position: "sticky",
                        right: 85,
                        backgroundColor: "white",
                      }}
                    >
                      {row.status === "CANCELADO" ||
                      sesion.accesos.tesoreria.abonos_clientes.agregar ===
                        false ? (
                        <div />
                      ) : (
                        <Abonar
                          cliente={row.cliente}
                          total_ventas={row.saldo_credito_pendiente}
                          setLoading={props.setLoading}
                          venta={row}
                          index={index}
                          recargar={props.recargar}
                          estatus_credito={row.estatus_credito}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      padding="checkbox"
                      style={{
                        position: "sticky",
                        right: 0,
                        backgroundColor: "white",
                      }}
                    >
                      {row.status === "CANCELADO" ||
                      sesion.accesos.tesoreria.abonos_clientes.agregar ===
                        false ? (
                        <div />
                      ) : (
                        <IconLiquidar
                          isIcon={true}
                          cliente={row.cliente}
                          total_ventas={row.saldo_credito_pendiente}
                          index={index}
                          setLoading={props.setLoading}
                          recargar={props.recargar}
                          estatus_credito={row.estatus_credito}
                          venta={row}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              {/* {emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={props.rows.totalDocs}
          rowsPerPage={props.limit}
          page={props.page}
          onPageChange={handleChangePage}
        />

        <DetalleVentaCredito
          openDetalle={openDetalle}
          setOpenDetalle={setOpenDetalle}
          datos={rowSelected}
        />
        <HistorialAbonos
          openHistorial={openHistorial}
          setOpenHistorial={setOpenHistorial}
          rowSelected={rowSelected}
          recargar={props.recargar}
        />
      </Paper>
    </div>
  );
}
