import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { Close } from "@material-ui/icons";
import ListaVentasRealizadas from "./ListaVentasRealizadas";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { FaShoppingBag } from "react-icons/fa";
import { CustomButton } from "../../../components/CustomMuiComponents";
import { MainContext } from "../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VentasRealizadas() {
  const { turnoEnCurso } = React.useContext(MainContext);
  let datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
  const { updateTablaVentas, ventaRapida } = React.useContext(VentasContext);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 86) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open]);

  React.useEffect(() => {
    datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
  }, [updateTablaVentas]);

  return (
    <React.Fragment>
      {ventaRapida ? (
        <CustomButton
          className="button-panel-venta b-primary"
          fullWidth
          size="large"
          variant="outlined"
          onClick={handleClickOpen}
          startIcon={<FaShoppingBag className="icon-btn-primary" />}
          disabled={!turnoEnCurso || (datosVentas && datosVentas.nota_credito)}
        >
          Ventas
        </CustomButton>
      ) : (
        <Button
          className="button-panel-venta b-primary"
          fullWidth
          variant="outlined"
          onClick={handleClickOpen}
          disabled={!turnoEnCurso || (datosVentas && datosVentas.nota_credito)}
        >
          <Box>
            <FaShoppingBag className="icon-btn-primary" />
            <Typography className="text-btn-panel">Ventas</Typography>
            <Typography className="text-btn-panel-sec">Alt + V</Typography>
          </Box>
        </Button>
      )}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
      >
        <DialogTitle component="div">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Ventas Realizadas</Typography>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => handleClickOpen()}
            >
              <Close />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ListaVentasRealizadas handleClose={handleClickOpen} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
