import React from "react";
import Box from "@material-ui/core/Box";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ClearAll, Search } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { CustomSearchVentaRapidaTF } from "../../../components/CustomMuiComponents";

export default function BuscarProducto({
  searchProducto,
  obtenerDatos,
  inputProductos,
  cleanFiltros,
  error,
}) {
  const { updateTablaVentas, setDatosVentasActual } = React.useContext(
    VentasContext
  );

  React.useEffect(() => {
    const venta = JSON.parse(localStorage.getItem("DatosVentas"));
    if (venta !== null) {
      setDatosVentasActual({
        subTotal: parseFloat(venta.subTotal),
        total: parseFloat(venta.total),
        impuestos: parseFloat(venta.impuestos),
        iva: parseFloat(venta.iva),
        ieps: parseFloat(venta.ieps),
        descuento: parseFloat(venta.descuento),
        monedero: parseFloat(venta.monedero),
      });
    }
    inputProductos?.current?.focus();
  }, [updateTablaVentas]);

  if(error){
    return(
      <Box pb={1}>
      <CustomSearchVentaRapidaTF
        variant="outlined"
        placeholder="Buscar por nombre, clave o código de barras"
        name="producto"
        size="small"
        fullWidth
        disabled
        InputProps={{
          style: { paddingRight: 8 },
          startAdornment: (
            <InputAdornment position="start">
              <Search color="action" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                size="small"
                disableElevation
                disabled
                variant="contained"
                startIcon={<ClearAll />}
                style={{ textTransform: "none", padding: 2, width: 100 }}
              >
                Borrar
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </Box>
    )
  }

  return (
    <Box pb={1.5} width="100%">
      <CustomSearchVentaRapidaTF
        variant="outlined"
        placeholder="Buscar por nombre, clave o código de barras"
        name="producto"
        size="small"
        value={searchProducto.producto}
        onChange={(e) => obtenerDatos("producto", e.target.value)}
        autoFocus
        fullWidth
        InputProps={{
          style: { paddingRight: 8 },
          startAdornment: (
            <InputAdornment position="start">
              <Search color="action" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Button
                size="small"
                color="primary"
                disableElevation
                onClick={cleanFiltros}
                startIcon={<ClearAll />}
                style={{ textTransform: "none", padding: 2, width: 100 }}
              >
                Borrar
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
