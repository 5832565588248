import React, { useContext } from "react";
import {
  Button,
  Box,
  Dialog,
  Typography,
  Container,
  makeStyles,
  Slide,
  IconButton,
} from "@material-ui/core";
import { FcFaq } from "react-icons/fc";
import VistaMarcas from "./VistaMarcas";
import { MarcasProvider } from "../../../../context/Catalogos/Marcas";
import DescripcionCatalogo from "../../../../components/DescripcionCatalogo";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { ArrowBack } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "4px",
    marginTop: "5px",
  },
  icon: {
    fontSize: 100,
  },
}));

export default function Marcas() {
  const { isOnline } = useContext(AccesosContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const descripcion =
    "En este apartado se registran las marcas que se asignarán a un producto.";
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MarcasProvider>
        <Button fullWidth onClick={handleClickOpen}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <FcFaq style={{ fontSize: 100 }} />
            </Box>
            Marcas
          </Box>
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Typography variant="h6" className={classes.title}>
            Marcas
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            position="absolute"
            top={5}
            right={10}
          >
            <DescripcionCatalogo texto={descripcion} />
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              <ArrowBack />
            </IconButton>
          </Box>

          <Box mt={4}>
            <Container maxWidth="md">
              <VistaMarcas isOnline={isOnline} />
            </Container>
          </Box>
        </Dialog>
      </MarcasProvider>
    </div>
  );
}
