import { useQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useDebounce } from "use-debounce";
import { ClienteCtx } from "../../../../context/Catalogos/crearClienteCtx";
import { OBTENER_CLIENTES } from "../../../../gql/Catalogos/clientes";
import moment from "moment-timezone";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import ExcelExportButton from "../../../../components/ExcelExportButton";

function ExportarProveedores({ filtro, tipo }) {
  const { update } = useContext(ClienteCtx);
  const { isOnline } = React.useContext(AccesosContext);
  const [values] = useDebounce(filtro, 1000);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const { data, refetch } = useQuery(OBTENER_CLIENTES, {
    variables: {
      tipo: "PROVEEDOR",
      filtros: values,
      empresa: sesion.empresa._id,
      eliminado: false,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
  }, [update, refetch]);


  let proveedores = {};
  if (data) {
    proveedores = data.obtenerClientes.docs.map((proveedor) => {
      const lista_proveedores = {
        clave_cliente:
          proveedor.clave_cliente !== null ? proveedor.clave_cliente : "-",
        numero_cliente:
          proveedor.numero_cliente !== null ? proveedor.numero_cliente : "-",
        nombre_cliente: proveedor.nombre_cliente,
        representante: proveedor.representante,
        fecha_registro: moment(proveedor.fecha_registro).tz("America/Mexico_City").format("DD/MM/YYYY"),
        curp: proveedor.curp !== null ? proveedor.curp : "-",
        telefono: proveedor.telefono,
        correo: proveedor.email,
        direccion:
          proveedor.direccion.calle !== "" && proveedor.direccion.no_ext !== ""
            ? proveedor.direccion.calle + " #" + proveedor.direccion.no_ext
            : "-",
        estado_cliente:
          proveedor.estado_cliente === true ? "ACTIVO" : "INACTIVO",
        tipo: proveedor.tipo_cliente,
        credito_disponible: proveedor.credito_disponible
          ? "$" + proveedor.credito_disponible
          : "-",
        limite_credito: proveedor.credito_disponible
          ? "$" + proveedor.limite_credito
          : "-",
        monedero_electronico: proveedor.monedero_electronico
          ? "$" + proveedor.monedero_electronico
          : "-",

        //OBLIGATORIOS Nombre, representante, email, telefono,

        //NO OBLIGATORIOS  clave, numero, fecha registro, curp, dirección,
        // estado_cliente, tipo, crédito, límite de crédito, monedero electronico
      };

      // console.log("Proveedores", lista_proveedores);
      return lista_proveedores;
    });
  }

  return (
    <ExcelExportButton data={proveedores} name="Lista de proveedores" button />
  );
}

export default ExportarProveedores;
