import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import { VentasContext } from "../../context/Ventas/ventasContext";
import { useApolloClient } from "@apollo/client";
import { LOGEAR_USUARIO_ACCESOS } from "../../gql/Catalogos/usuarios";
import BackdropComponent from "../Layouts/BackDrop";
import { AccesosContext } from "../../context/Accesos/accesosCtx";
import { Close, Done } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Acceso() {
  const client = useApolloClient();

  const { setAlert } = useContext(VentasContext);

  const {
    departamentos,
    abrirPanelAcceso,
    setAbrirPanelAcceso,
    setReloadProductoRapido,
    setReloadAdministrador,
    setReloadCancelarVenta,
    setReloadVerPrecios,
    setReloadVerPreCorte,
    setReloadEliminarVentaEspera,
    setReloadCrearCotizacion,
  } = useContext(AccesosContext);

  const [datosUser, setDatosUser] = useState({
    numero_usuario: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleClickClose = async () => {
    setDatosUser({
      numero_usuario: "",
      password: "",
    });
    setAbrirPanelAcceso(false);
  };

  const obtenerUsername = (e) => {
    setDatosUser({
      ...datosUser,
      numero_usuario: e.target.value.toUpperCase(),
    });
  };
  const obtenerPassword = (e) => {
    setDatosUser({
      ...datosUser,
      password: e.target.value,
    });
  };

  const input = {
    numero_usuario: datosUser.numero_usuario.trim(),
    password: datosUser.password.trim(),
    departamento: departamentos.departamento,
    subDepartamento: departamentos.subDepartamento,
    tipo_acceso: departamentos.tipo_acceso,
  };

  const obtenerQuery = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await client.query({
        query: LOGEAR_USUARIO_ACCESOS,
        variables: { input },
        fetchPolicy: "network-only",
      });
      if (response.data.obtenerAccesoPermiso.permiso_concedido === true) {
        SwitchPermisos(response.data.obtenerAccesoPermiso.subDepartamento);
        setAlert({
          message: "Permiso Autorizado",
          status: "success",
          open: true,
        });
        setDatosUser({
          numero_usuario: "",
          password: "",
        });
        setAbrirPanelAcceso(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message) {
        setAlert({
          message: error.message,
          status: "error",
          open: true,
        });
      }
    }
  };

  const SwitchPermisos = (subDepartamento) => {
    switch (subDepartamento) {
      case "administrador":
        setReloadAdministrador(true);
        break;
      case "producto_rapido":
        setReloadProductoRapido(true);
        break;
      case "cancelar_venta":
        setReloadCancelarVenta(true);
        break;
      case "precios_productos":
        setReloadVerPrecios(true);
        break;
      case "pre_corte":
        setReloadVerPreCorte(true);
        break;
      case "cotizaciones":
        setReloadCrearCotizacion(true);
        break;
      case "eliminar_ventas":
        setReloadEliminarVentaEspera(true);
        break;
      default:
        setAlert({
          message: "Permiso no autorizado",
          status: "error",
          open: true,
        });
        break;
    }
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="xs"
        fullWidth
        open={abrirPanelAcceso}
        onClose={handleClickClose}
        TransitionComponent={Transition}
      >
        <BackdropComponent loading={loading} />
        <Box p={3}>
          <Box py={2}>
            <Typography variant="h6" align="center">
              <b>Autorizar acceso</b>
            </Typography>
            {/* <Box>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleClickClose()}
                size="medium"
              >
                <CloseIcon />
              </Button>
            </Box> */}
          </Box>
          <form onSubmit={obtenerQuery} autoComplete="off">
            <Box width="100%" my={1}>
              <Typography>
                <b>Numero Usuario o username:</b>
              </Typography>
              <Box display="flex">
                <TextField
                  autoFocus
                  fullWidth
                  name="numero_usuario"
                  size="small"
                  variant="outlined"
                  onChange={obtenerUsername}
                  value={datosUser.numero_usuario}
                />
              </Box>
            </Box>
            <Box width="100%" my={1}>
              <Typography>
                <b>Contrasena:</b>
              </Typography>
              <Box display="flex">
                <TextField
                  fullWidth
                  size="small"
                  type="password"
                  name="password"
                  variant="outlined"
                  onChange={obtenerPassword}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Button
                color="secondary"
                size="medium"
                variant="contained"
                onClick={() => handleClickClose()}
                startIcon={<Close />}
                disableElevation
              >
                Cancelar
              </Button>
              <Box mx={1} />
              <Button
                color="primary"
                size="medium"
                variant="contained"
                type="submit"
                startIcon={<Done />}
              >
                Entrar
              </Button>
            </Box>
          </form>
        </Box>
      </Dialog>
    </Fragment>
  );
}
