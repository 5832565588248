import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import CrearProducto from "../crearProducto";
import { ArrowBack, ClearOutlined, Close } from "@material-ui/icons";
import ProductosEliminados from "../ProductosEliminados";
import { Grid, IconButton, makeStyles } from "@material-ui/core";
import ExportarProductosExcel from "../ExportarProductosExcel";
import ImportXls from "../ImportXLS/ImportXlsButton";
//import NombrePrecios from "../NombresPrecios/NombresPrecios";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    marginLeft: theme.spacing(1),
  },
}));

export default function TopHeader({ values, setValues, setPage, isOnline, handleClose, initialState }) {
  const classes = useStyles();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const limpiarFiltros = () => {
    setValues(initialState);
    setPage(0);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Typography variant="h6" className={classes.title}>
        Productos
      </Typography>
      <Box flexGrow={1} />
      <Grid item>
        <Button
          color="primary"
          startIcon={<ClearOutlined />}
          onClick={() => limpiarFiltros()}
        >
          Limpiar filtros
        </Button>
      </Grid>
      <Grid item>
        <ProductosEliminados isOnline={isOnline} />
      </Grid>
      <Grid item>
        <ExportarProductosExcel filtros={values} isOnline={isOnline} />
      </Grid>
      <Grid item>
        < ImportXls />
      </Grid>

      {/* <Grid item>
        <NombrePrecios isOnline={isOnline} />
      </Grid> */}
      <Grid item>
        {sesion.accesos.catalogos.productos.agregar === false ? null : (
          <CrearProducto accion={false} isOnline={isOnline} />
        )}
      </Grid>
      <Grid item>
        <IconButton variant="contained" color="primary" onClick={handleClose}>
          <ArrowBack />
        </IconButton>
      </Grid>
    </Grid>
  );
}
