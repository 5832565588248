import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import {
  ArrowBack,
  CheckCircleTwoTone,
  Close,
  HighlightOffTwoTone,
  Search,
} from "@material-ui/icons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";
import { green, red } from "@material-ui/core/colors";
import {
  CustomSearchTextField,
  CustomTableTextField,
} from "../../../../../components/CustomMuiComponents";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  containerProveedores: {
    height: "80vh",
  },
  containerCompras: {
    height: "75vh",
  },
  containerAbonos: {
    height: "75vh",
  },
}));

export default function TablaEstadoDeCuentaProveedores({
  openVista,
  datosProveedor,
  datosCompra,
  handleClickOpenProveedores,
  handleClickOpenCompras,
  handleClickOpenAbonos,
  proveedor,
  compra,
  proveedorNombre,
  offsetProveedores,
  setOffsetProveedores,
  limitProveedores,
  docsProveedores,
  offsetCompras,
  setOffsetCompras,
  limitCompras,
  docsCompra,
  abonos,
  saldoTotalPendiente,
  obtenerCamposCompra,
  filtrosCompra,
  limpiarDatos,
}) {
  const classes = useStyles();

  const handleChangePage = (_, nextPage) => {
    setOffsetProveedores(nextPage);
  };

  const handleChangePageC = (_, nextPage) => {
    setOffsetCompras(nextPage);
  };

  switch (openVista) {
    case "Proveedores":
      return (
        <>
          <Box mt={2}>
            {proveedor.length ? (
              <Paper className={classes.root} variant="outlined">
                <TableContainer className={classes.containerProveedores}>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>Nombre</TableCell>
                        <TableCell>Teléfono</TableCell>
                        <TableCell>Saldo Pendiente</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {proveedor?.map((proveedor, index) => {
                        return (
                          <TableRow
                            key={index}
                            role="checkbox"
                            tabIndex={-1}
                            hover
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickOpenCompras(proveedor)}
                          >
                            <TableCell>{proveedor.numero_cliente}</TableCell>
                            <TableCell>{proveedor.nombre_cliente}</TableCell>
                            <TableCell>{proveedor.telefono}</TableCell>
                            <TableCell>
                              {"$" +
                                formatoMexico(proveedor.saldo_pendiente.total)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={docsProveedores}
                  rowsPerPage={limitProveedores}
                  page={offsetProveedores}
                  onPageChange={handleChangePage}
                />
              </Paper>
            ) : (
              <Box display="flex" justifyContent="center" mt={10}>
                <Typography variant="h5" color="textSecondary">
                  No hay información
                </Typography>
              </Box>
            )}
          </Box>
        </>
      );

    case "Compras":
      return (
        <>
          <Box mt={2} mb={1} display="flex" alignItems="center">
            <Box flexGrow={1} display="flex" alignItems="center" gridGap={10}>
              <IconButton
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleClickOpenProveedores()}
              >
                <ArrowBack fontSize="small" />
              </IconButton>
              <Button size="small" onClick={() => handleClickOpenProveedores()}>
                PROVEEDORES
              </Button>
              <ArrowForwardIosIcon style={{ fontSize: "15px" }} />
              <Button size="small">{proveedorNombre.toUpperCase()}</Button>
            </Box>
            <Box display="flex" alignItems="center" gridGap={10}>
              <CustomTableTextField
                fullWidth
                onChange={(e) => obtenerCamposCompra(e)}
                type="date"
                value={filtrosCompra.fecha_inicio}
                variant="outlined"
                size="small"
                name="fecha_inicio"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">De:</InputAdornment>
                  ),
                }}
                style={{
                  width: 150,
                }}
              />
              <CustomTableTextField
                fullWidth
                onChange={(e) => obtenerCamposCompra(e)}
                type="date"
                value={filtrosCompra.fecha_fin}
                variant="outlined"
                size="small"
                name="fecha_fin"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">a:</InputAdornment>
                  ),
                }}
                style={{
                  width: 150,
                }}
              />
              <CustomSearchTextField
                placeholder="Folio"
                onChange={(e) => obtenerCamposCompra(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                value={filtrosCompra.folio}
                variant="outlined"
                size="small"
                name="folio"
                style={{
                  width: 150,
                }}
              />
              <IconButton size="small" onClick={limpiarDatos}>
                <Close fontSize="small" />
              </IconButton>
            </Box>
          </Box>
          <Box>
            {compra.length ? (
              <Paper className={classes.root} variant="outlined">
                <TableContainer className={classes.containerCompras}>
                  <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">Status</TableCell>
                        <TableCell>Folio</TableCell>
                        <TableCell>Fecha de compra</TableCell>
                        <TableCell>Total</TableCell>
                        <TableCell>Abonado</TableCell>
                        <TableCell>Saldo Pendiente</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {compra?.map((compra, index) => {
                        const cancel = compra.status === "CANCELADO";
                        return (
                          <TableRow
                            key={index}
                            role="checkbox"
                            tabIndex={-1}
                            hover
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClickOpenAbonos(compra)}
                          >
                            <TableCell>
                              {cancel ? (
                                <HighlightOffTwoTone
                                  fontSize="small"
                                  htmlColor={red[400]}
                                />
                              ) : (
                                <CheckCircleTwoTone
                                  fontSize="small"
                                  htmlColor={green[400]}
                                />
                              )}
                            </TableCell>
                            <TableCell>{compra.folio}</TableCell>
                            <TableCell>
                              {moment(compra.fecha_registro)
                                .tz("America/Mexico_City")
                                .format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell>
                              {"$" + formatoMexico(compra.total)}
                            </TableCell>
                            <TableCell>
                              {`$${formatoMexico(
                                compra.total - compra.saldo_credito_pendiente
                              )}`}
                            </TableCell>
                            <TableCell>
                              {"$" +
                                formatoMexico(compra.saldo_credito_pendiente)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography>
                    <b>Saldo Pendiente Total:</b>{" "}
                    {"$" + formatoMexico(saldoTotalPendiente)}
                  </Typography>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={docsCompra}
                    rowsPerPage={limitCompras}
                    page={offsetCompras}
                    onPageChange={handleChangePageC}
                  />
                </Box>
              </Paper>
            ) : (
              <Box display="flex" justifyContent="center" mt={10}>
                <Typography variant="h5" color="textSecondary">
                  Sin compras
                </Typography>
              </Box>
            )}
          </Box>
        </>
      );

    case "Abonos":
      return (
        <>
          <Box mt={2} display="flex" alignItems="center">
            <IconButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleClickOpenCompras(datosProveedor)}
            >
              <ArrowBack fontSize="small" />
            </IconButton>
            <Button size="small" onClick={() => handleClickOpenProveedores()}>
              PROVEEDORES
            </Button>
            <ArrowForwardIosIcon style={{ fontSize: "15px" }} />
            <Button
              size="small"
              onClick={() => handleClickOpenCompras(datosProveedor)}
            >
              COMPRAS A {proveedorNombre?.toUpperCase()}
            </Button>
            <ArrowForwardIosIcon style={{ fontSize: "15px" }} />
            <Button size="small">ABONOS A{datosCompra?.folio}</Button>
          </Box>
          <Box mt={1}>
            {abonos.length ? (
              <Paper className={classes.root} variant="outlined">
                <TableContainer className={classes.containerAbonos}>
                  <Table stickyHeader size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">Status</TableCell>
                        <TableCell>Fecha del abono</TableCell>
                        <TableCell>Monto</TableCell>
                        <TableCell>Caja</TableCell>
                        <TableCell>Turno</TableCell>
                        <TableCell>Método de pago</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {datosCompra?.abonos?.map((abono, index) => {
                        const cancel = abono.status === "CANCELADO";
                        return (
                          <TableRow key={index}>
                            <TableCell>
                              {cancel ? (
                                <HighlightOffTwoTone
                                  fontSize="small"
                                  htmlColor={red[400]}
                                />
                              ) : (
                                <CheckCircleTwoTone
                                  fontSize="small"
                                  htmlColor={green[400]}
                                />
                              )}
                            </TableCell>
                            <TableCell>
                              {moment(abono.fecha_movimiento.completa)
                                .tz("America/Mexico_City")
                                .format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell>
                              {"$" + formatoMexico(abono.monto_total_abonado)}
                            </TableCell>
                            <TableCell>
                              {abono.numero_caja ? abono.numero_caja : ""}
                            </TableCell>
                            <TableCell>
                              {abono.horario_turno ? abono.horario_turno : ""}
                            </TableCell>
                            <TableCell>
                              {abono.metodo_de_pago
                                ? abono.metodo_de_pago.metodo.toUpperCase()
                                : "S/D"}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gridGap={15}
                  m={1}
                >
                  <Typography>
                    <b>Saldo pendiente:</b>{" "}
                    {"$" +
                      formatoMexico(
                        datosCompra.status !== "CANCELADO"
                          ? datosCompra.saldo_credito_pendiente
                          : "0"
                      )}
                  </Typography>
                  <Typography>
                    <b>Total de la compra:</b>{" "}
                    {"$" +
                      formatoMexico(
                        datosCompra.status !== "CANCELADO"
                          ? datosCompra.total
                          : "0"
                      )}
                  </Typography>
                </Box>
              </Paper>
            ) : (
              <Box display="flex" justifyContent="center" mt={10}>
                <Typography variant="h5" color="textSecondary">
                  No hay información
                </Typography>
              </Box>
            )}
          </Box>
        </>
      );
    default:
      break;
  }

  return { openVista };
}
