import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { TraspasosContext } from "../../../../Context/TraspasosContext";

const compareFunction = (a, b) => {
  if (a.medida.talla && b.medida.talla) {
    return a.medida.talla.localeCompare(b.medida.talla);
  }
};

const useStyles = makeStyles((theme) => ({
  colorContainer: {
    height: 30,
    width: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function TablaPresentaciones() {
  const { item } = React.useContext(TraspasosContext);

  const presentaciones = [...item.new_medidas].sort((a, b) =>
    compareFunction(a, b)
  );

  return (
    <Paper variant="outlined">
      <TableContainer>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Código de barras</TableCell>
              <TableCell width={200}>Nombre</TableCell>
              <TableCell>Presentación</TableCell>
              <TableCell width={170}>Cantidad - Almacén.</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {presentaciones.map((producto, index) => {
              return (
                <RenderPresentacionesRows
                  key={index}
                  producto={producto}
                  index={index}
                  presentaciones={presentaciones}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const RenderPresentacionesRows = ({ producto, index, presentaciones }) => {
  const { item, setItem, traspaso } = React.useContext(TraspasosContext);
  const classes = useStyles();
  const theme = useTheme();

  const copy_element_presentacion = { ...presentaciones[index] };
  const copy_producto = { ...producto };

  const obtenerCantidadNueva = (e) => {
    const { value } = e.target;
    if(traspaso.almacen_origen && value > producto.cantidad) return
    if (!value) {
      copy_element_presentacion.cantidad_nueva = "";
      copy_element_presentacion.existencia = copy_producto.existencia;
      presentaciones.splice(index, 1, copy_element_presentacion);
      setItem({ ...item, new_medidas: presentaciones });
      return;
    }
    copy_element_presentacion.cantidad_nueva = parseFloat(value);
    copy_element_presentacion.existencia = true;
    presentaciones.splice(index, 1, copy_element_presentacion);
    setItem({ ...item, new_medidas: presentaciones });
  };

  const obtenerDatos = (e) => {
    const { value } = e.target;
    if (!value) {
      copy_element_presentacion.codigo_barras = "";
      presentaciones.splice(index, 1, copy_element_presentacion);
      setItem({ ...item, new_medidas: presentaciones });
      return;
    }
    copy_element_presentacion.codigo_barras = value;
    presentaciones.splice(index, 1, copy_element_presentacion);
    setItem({ ...item, new_medidas: presentaciones });
  };

  return (
    <TableRow hover>
      <TableCell width={220}>
        <Box display="flex">
          <TextField
            onChange={(e) => obtenerDatos(e)}
            value={copy_producto.codigo_barras}
            variant="outlined"
            size="small"
            disabled={
              presentaciones.length > 0 && !copy_producto.nuevo ? true : false
            }
          />
        </Box>
      </TableCell>
      <TableCell width={200}>{copy_producto.nombre_comercial}</TableCell>
      <TableCell padding="checkbox">
        {copy_producto.color._id ? (
          <Tooltip
            title={copy_producto.color.nombre}
            placement="top"
            arrow
            TransitionComponent={Zoom}
          >
            <Chip
              className={classes.colorContainer}
              style={{
                backgroundColor: copy_producto.color.hex,
                color: theme.palette.getContrastText(copy_producto.color.hex),
              }}
              label={copy_producto.medida._id ? copy_producto.medida.talla : ""}
            />
          </Tooltip>
        ) : null}
      </TableCell>
      <TableCell>
        <TextField
          onChange={(e) => obtenerCantidadNueva(e)}
          value={copy_producto.cantidad_nueva}
          type="number"
          variant="outlined"
          size="small"
          style={{ width: 120 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Chip label={producto.cantidad} size="small" />
              </InputAdornment>
            ),
            inputProps: {
              min: 0,
              max: traspaso.almacen_origen ? producto.cantidad : null,
            },
          }}
        />
      </TableCell>
    </TableRow>
  );
};
