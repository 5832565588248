import React from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogTitle from "@material-ui/core/DialogTitle";
import Badge from "@material-ui/core/Badge";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { RiScales2Fill } from "react-icons/ri";
import { Delete, Done, FileCopy, Replay, Usb } from "@material-ui/icons";
import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import { MainContext } from "../../../context/MainCtx";

const initialBascula = {
  path: "",
  baudRate: "",
  dataBits: "",
  stopBits: "",
  flowControl: false,
};

export default function Bascula() {
  const { respoint } = React.useContext(MainContext);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [peso, setPeso] = React.useState("");
  const storageBascula = JSON.parse(localStorage.getItem("cafiBascula"));
  const [bascula, setBascula] = React.useState(
    storageBascula || initialBascula
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setBascula(storageBascula || initialBascula);
    if (success) setSuccess("");
    if (error) setError("");
    if (peso) setPeso("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBascula({
      ...bascula,
      [name]: value,
    });
  };

  const handleSave = () => {
    try {
      if (error) setError("");
      if (success) setSuccess("");
      if (
        !bascula.path ||
        !bascula.baudRate ||
        !bascula.dataBits ||
        !bascula.stopBits
      ) {
        setError("Campos incompletos");
        return;
      }
      localStorage.setItem("cafiBascula", JSON.stringify(bascula));
      setSuccess("Báscula configurada");
    } catch (error) {
      setError(error.message);
    }
  };
  const handleDelete = () => {
    try {
      if (error) setError("");
      if (success) setSuccess("");
      localStorage.removeItem("cafiBascula");
      setBascula(initialBascula);
      setSuccess("Configuración báscula eliminada");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleTest = () => {
    if (error) setError("");
    if (success) setSuccess("");
    if (
      !bascula.path ||
      !bascula.baudRate ||
      !bascula.dataBits ||
      !bascula.stopBits
    ) {
      setError("Campos incompletos");
      return;
    }
    setLoading(true);
    axios
      .post(`${respoint}bascula/getPeso`, bascula)
      .then((res) => {
        setPeso(res.data.peso);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <div>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon>
          <Badge
            overlap="rectangular"
            badgeContent={1}
            variant="dot"
            color={storageBascula ? "default" : "error"}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <RiScales2Fill style={{ fontSize: 25 }} />
          </Badge>
        </ListItemIcon>
        <ListItemText primary="Bascula" />
      </ListItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="d-bascula-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="d-bascula-title">
          Configurar báscula digital
        </DialogTitle>
        <DialogContent>
          {error ? <Alert severity="error">{error}</Alert> : null}
          {success ? <Alert severity="success">{success}</Alert> : null}
          <Grid container spacing={2}>
            <Grid item md={6}>
              <PortList
                setBascula={setBascula}
                error={error}
                setError={setError}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                variant="outlined"
                size="small"
                label="Puerto"
                margin="dense"
                name="path"
                fullWidth
                value={bascula.path}
              />
              <TextField
                variant="outlined"
                size="small"
                label="Velocidad"
                margin="dense"
                name="baudRate"
                placeholder="9600"
                fullWidth
                value={bascula.baudRate}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                size="small"
                label="Bit de datos"
                margin="dense"
                name="dataBits"
                placeholder="8"
                fullWidth
                value={bascula.dataBits}
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                size="small"
                label="Bit de parada"
                margin="dense"
                name="stopBits"
                placeholder="1"
                fullWidth
                value={bascula.stopBits}
                onChange={handleChange}
              />
              <FormControl component="fieldset" margin="dense">
                <FormLabel component="legend">Control de flujo</FormLabel>
                <RadioGroup
                  value={bascula.flowControl}
                  name="flowControl"
                  onChange={(e) => {
                    setBascula({
                      ...bascula,
                      flowControl: e.target.value === "true" ? true : false,
                    });
                  }}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Si"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                value={peso}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Button
                        startIcon={
                          loading ? <CircularProgress size={20} /> : null
                        }
                        size="small"
                        color="primary"
                        onClick={handleTest}
                      >
                        Probar
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<Delete />}
            color="secondary"
            onClick={handleDelete}
          >
            Eliminar Config
          </Button>
          <Button startIcon={<Done />} color="primary" onClick={handleSave}>
            Guardar config
          </Button>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const PortList = ({ setBascula, error, setError }) => {
  const localPorts = JSON.parse(localStorage.getItem("SerialPorts"));
  const [loading, setLoading] = React.useState(false);
  const [ports, setPorts] = React.useState([]);
  const { respoint } = React.useContext(MainContext);

  const getListPorts = () => {
    setLoading(true);
    if (error) setError("");
    axios
      .get(`${respoint}bascula/getPorts`)
      .then((res) => {
        localStorage.setItem("SerialPorts", JSON.stringify(res.data));
        setPorts(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };
  return (
    <Box>
      <Box mb={1}>
        <Alert severity="info">
          Busca el puerto donde conectate tu báscula, la demas configuración la
          encontraras en el manual de tu báscula
        </Alert>
      </Box>
      <Button
        startIcon={
          loading ? <CircularProgress size={20} color="inherit" /> : <Replay />
        }
        fullWidth
        variant="contained"
        disableElevation
        color="primary"
        style={{ textTransform: "none" }}
        size="small"
        onClick={() => getListPorts()}
      >
        Buscar/Recargar puertos
      </Button>
      {(localPorts ? localPorts : ports).map(({ path }, indx) => (
        <List dense disablePadding key={indx}>
          <ListItem style={{ padding: 0 }}>
            <ListItemIcon style={{ minWidth: 36 }}>
              <Usb />
            </ListItemIcon>
            <ListItemText primary={path} secondary="Serial port" />
            <ListItemSecondaryAction>
              <IconButton
                size="small"
                onClick={() => setBascula((st) => ({ ...st, path }))}
              >
                <FileCopy fontSize="small" />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      ))}
    </Box>
  );
};
