let arregloVacio = {
  mi_empresa: {
    datos_empresa: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    informacion_fiscal: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
  },
  compras: {
    abrir_compra: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    compras_realizadas: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    compras_espera: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
  },
  tesoreria: {
    cuentas_empresa: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    egresos: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    abonos_proveedores: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    abonos_clientes: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    caja_principal: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
  },
  reportes: {
    reporte_historial_cajas: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    reporte_abonos: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    reporte_compras: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    reporte_ventas: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    rerporte_almacen: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    reporte_corte_caja: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    reporte_tesoreria: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
  },
  ventas: {
    cancelar_venta: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    precios_productos: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    pre_corte: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    cotizaciones: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    administrador: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    eliminar_ventas: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    producto_rapido: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
  },
  almacenes: {
    almacen: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    traspasos: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    inventario_almacen: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
  },
  catalogos: {
    clientes: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    usuarios: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    productos: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    marcas: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    contabilidad: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    provedores: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    cajas: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    departamentos: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    centro_costos: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    conceptos_almacen: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    categorias: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    colores: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
    tallas_numeros: {
      ver: false,
      agregar: false,
      eliminar: false,
      editar: false,
    },
  },
  facturacion: {
    generar_cdfi: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    cdfi_realizados: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
    registro_series_cdfi: {
      ver: false,
      agregar: false,
      editar: false,
      eliminar: false,
    },
  },
};

export default arregloVacio;
