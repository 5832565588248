import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { FcTodoList } from "react-icons/fc";
import FiltrosCompras from "./FiltrosCompra";
import { ArrowBack, ClearOutlined } from "@material-ui/icons";
import ExportarCompras from "./ExportarCompras";
import ComponentOnline from "../../../../components/Connection/ComponentOnline";
import { useDebounce } from "use-debounce";
import ErrorPage from "../../../../components/ErrorPage";
import { useQuery } from "@apollo/client";
import { OBTENER_REPORTE_COMPRAS } from "../../../../gql/Compras/reporte_compras";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  paper: {
    alignItems: "flex-end",
  },
  fullScreen: {
    height: "100vh",
  },
}));

export default function ReportesCompras() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [filtro, setFiltro] = useState({
    fecha_inicio: "",
    fecha_fin: "",
    proveedor: "",
    metodo_pago: "",
    forma_pago: "",
    producto: "",
    canceladas: false,
    vencidas: false,
    vigentes: false,
    liquidadas: false,
  });
  const [value] = useDebounce(filtro, 700);

  const limpiarFiltros = () => {
    setFiltro({
      fecha_inicio: "",
      fecha_fin: "",
      proveedor: "",
      metodo_pago: "",
      forma_pago: "",
      producto: "",
      canceladas: false,
      vencidas: false,
      vigentes: false,
      liquidadas: false,
    });
  };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <Box>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcTodoList style={{ fontSize: 100 }} />
          </Box>
          Reportes Compras
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
        classes={{
          scrollPaper: classes.paper,
          paperFullScreen: classes.fullScreen,
        }}
        hideBackdrop={true}
        PaperProps={{ elevation: 0 }}
      >
        <Typography variant="h6" className={classes.title}>
          Reportes Compras
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <ComponentOnline classes />
          <Box mx={1} />
          <Button
            color="primary"
            startIcon={<ClearOutlined />}
            onClick={() => limpiarFiltros()}
          >
            Limpiar filtros
          </Button>
          <Box mx={1} />
          <ExportarCompras filtro={filtro} setFiltro={setFiltro} />
          <Box mx={1} />
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <ContentData value={value} filtro={filtro} setFiltro={setFiltro} />
      </Dialog>
    </Box>
  );
}

const ContentData = ({ value, filtro, setFiltro }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const page = 0;
  const limit = 20;

  const { loading, data, error, refetch } = useQuery(OBTENER_REPORTE_COMPRAS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: {
        fecha_inicio: value.fecha_inicio ? value.fecha_inicio : "",
        fecha_fin: value.fecha_fin ? value.fecha_fin : "",
        proveedor: value.proveedor ? value.proveedor : "",
        metodo_pago: value.metodo_pago ? value.metodo_pago : "",
        forma_pago: value.forma_pago ? value.forma_pago : "",
        producto: value.producto ? value.producto : "",
        canceladas: value.canceladas ? value.canceladas : false,
        vencidas: value.vencidas ? value.vencidas : false,
        vigentes: value.vigentes ? value.vigentes : false,
        liquidadas: value.liquidadas ? value.liquidadas : false,
      },
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
        <Typography variant="h6">Cargando...</Typography>
      </Box>
    );
  }

  if (error) return <ErrorPage error={error} />;

  const { obtenerProductoMovimientos } = data;

  return (
    <FiltrosCompras
      value={value}
      filtro={filtro}
      setFiltro={setFiltro}
      refetch={refetch}
      obtenerProductoMovimientos={obtenerProductoMovimientos}
      limit={limit}
      page={page}
    />
  );
};
