import * as React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableSortLabel } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import FilterSelectTable from "../../../../../components/FilterSelect";
import { ProdAlmContext } from "../Context/ProdAlmContext";

let headCells = [
  {
    id: "codigo",
    padding: "checkbox",
    align: "left",
    label: "Código",
  },
  {
    id: "nombre",
    padding: "normal",
    align: "left",
    label: "Artículo",
    style: { minWidth: 250 },
  },
  {
    id: "categoria",
    padding: "checkbox",
    align: "left",
    label: "Categoria",
    filter: {
      filteringData: "categorias",
      filterName: "categoria",
      filterBy: "categoria",
    },
    style: { minWidth: 120}
  },
  {
    id: "subcategoria",
    padding: "checkbox",
    align: "left",
    label: "Subcategoria",
    style: { minWidth: 140}
  },
  {
    id: "departamento",
    padding: "checkbox",
    align: "left",
    label: "Departamento",
    filter: {
      filteringData: "departamentos",
      filterName: "nombre_departamentos",
      filterBy: "nombre_departamentos",
    },
    style: { minWidth: 140}
  },
  {
    id: "marca",
    padding: "checkbox",
    align: "left",
    label: "Marca",
    filter: {
      filteringData: "marcas",
      filterName: "nombre_marca",
      filterBy: "nombre_marca",
    },
    style: { minWidth: 120}
  },
  {
    id: "unidad",
    padding: "checkbox",
    align: "left",
    label: "Unidad",
  },
  {
    id: "costo",
    padding: "checkbox",
    align: "left",
    label: "Costo",
  },
  {
    id: "costoTotal",
    padding: "checkbox",
    align: "left",
    label: "Costo total",
  },
];

export default function TraspasoTableHead({
  handleSort,
  order,
  orderBy,
  almacenes,
}) {
  const { queryCats, filter, setFilter } = React.useContext(ProdAlmContext);

  let almacenCells = [];
  if (almacenes.length) {
    almacenCells = almacenes.map((alm) => ({
      id: alm.nombre_almacen,
      padding: "checkbox",
      align: "left",
      label: alm.nombre_almacen,
      style: { backgroundColor: yellow[50] },
    }));
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={headCell.style}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
            {headCell.filter ? (
              <FilterSelectTable
                label={headCell.label}
                query={
                  queryCats?.data?.obtenerConsultasProducto[
                    headCell.filter.filteringData
                  ]
                }
                loading={queryCats.loading}
                error={queryCats.error}
                value={filter}
                setFilter={setFilter}
                //setFilterCats={setFilterCats}
                //filterCats={filterCats}
                filterName={headCell.filter.filterName}
                filterBy={headCell.filter.filterBy}
              />
            ) : null}
          </TableCell>
        ))}
        {almacenCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={headCell.style}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell style={{ minWidth: 180, backgroundColor: yellow[50] }}>
          Total almacenes
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
