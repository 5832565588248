import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ColorLens, Done } from "@material-ui/icons";
import { Grid, Slider, Typography } from "@material-ui/core";
import TestComponent from "./TestCompontent";
import { colors, shades } from "./colores";
import { TiendaCtx } from "../../../../../context/Empresa/tiendaCtx";

export default function TemaTienda() {
  const [open, setOpen] = React.useState(false);
  const { setColor, color, inital_color_state, tienda } = React.useContext(
    TiendaCtx
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setColor(
      tienda.data && tienda.data.theming.primary.hex
        ? tienda.data.theming
        : inital_color_state
    );
  };

  const handleChangeShadePrimary = (e, value_shade) => {
    const shade = shades.filter((res) => value_shade === res.value);
    const name = colors.filter((res) => res.name === color.primary.name);
    setColor((state) => ({
      ...state,
      primary: {
        ...state.primary,
        shade: shade[0].label,
        value_shade,
        hex: name[0].color[shade[0].label],
      },
    }));
  };

  const handleChangeShadeSecondary = (e, value_shade) => {
    const shade = shades.filter((res) => value_shade === res.value);
    const name = colors.filter((res) => res.name === color.secondary.name);
    setColor((state) => ({
      ...state,
      secondary: {
        ...state.secondary,
        shade: shade[0].label,
        value_shade,
        hex: name[0].color[shade[0].label],
      },
    }));
  };

  React.useEffect(() => {
    if(tienda.data && tienda.data.theming.primary.hex){
      setColor(tienda.data.theming);
    }
  }, [tienda.data])

  return (
    <div>
      <Button
        size="small"
        color="primary"
        startIcon={<ColorLens />}
        onClick={handleClickOpen}
      >
        Colores
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="dialog-colores-title"
        aria-describedby="dialog-colores-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="dialog-colores-title">
          {"Colores principales de tu tienda"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-colores-description">
            Puedes seleccionar los colores principales para tu tienda, aplicará
            a botones, navegaciones e iconos.
          </DialogContentText>
          <Grid
            container
            spacing={3}
            alignItems="flex-end"
            justifyContent="center"
          >
            <Grid item md={3}>
              <Typography variant="h6">Primario</Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>Intensidad</Typography>
                  <Slider
                    marks
                    onChange={handleChangeShadePrimary}
                    value={color.primary.value_shade}
                    min={1}
                    max={14}
                    valueLabelDisplay="auto"
                  />
                </Grid>
                {colors.map((color, index) => (
                  <ItemColorPrimary
                    key={`${index}-${color.name}`}
                    colorRender={color}
                    setColor={setColor}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item md={3}>
              <Typography variant="h6">Secundario</Typography>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>Intensidad</Typography>
                  <Slider
                    value={color.secondary.value_shade}
                    marks
                    onChange={handleChangeShadeSecondary}
                    valueLabelDisplay="auto"
                    min={1}
                    max={14}
                  />
                </Grid>
                {colors.map((color, index) => (
                  <ItemColorSecondary
                    key={`${color.name}-${index}`}
                    colorRender={color}
                    setColor={setColor}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item md={4}>
              <TestComponent />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const ItemColorPrimary = ({ colorRender, setColor }) => {
  const { color } = React.useContext(TiendaCtx);
  const color_item = colorRender.color[color.primary.shade];

  return (
    <Grid
      item
      xs={3}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 48,
        border: color.primary.hex === color_item ? "1px solid white" : "none",
        backgroundColor: color_item,
      }}
      onClick={() =>
        setColor((state) => ({
          ...state,
          primary: {
            ...state.primary,
            hex: color_item,
            name: colorRender.name,
          },
        }))
      }
    >
      {color.primary.hex === color_item ? (
        <Done htmlColor="white" style={{ fontSize: 40 }} />
      ) : null}
    </Grid>
  );
};

const ItemColorSecondary = ({ colorRender, setColor }) => {
  const { color } = React.useContext(TiendaCtx);
  const color_item = colorRender.color[color.secondary.shade];
  return (
    <Grid
      item
      xs={3}
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: 48,
        border: color.secondary.hex === color_item ? "1px solid white" : "none",
        backgroundColor: color_item,
      }}
      onClick={() =>
        setColor((state) => ({
          ...state,
          secondary: {
            ...state.secondary,
            hex: color_item,
            name: colorRender.name,
          },
        }))
      }
    >
      {color.secondary.hex === color_item ? (
        <Done htmlColor="white" style={{ fontSize: 40 }} />
      ) : null}
    </Grid>
  );
};
