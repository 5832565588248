import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import TableProductos from "./TableProductos";
import CustomLoader from "../../../components/CustomLoader";
import NoMatchPage from "../../../components/NoMatchPage";
import { OBTENER_PRODUCTOS_SUCURSALS } from "../../../gql/Ventas/ventas_generales";
import { useQuery } from "@apollo/client";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
}));

export default function TabsSucursals({producto}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [page, setPage] = React.useState(0);
  const limit = 10;

  const { data, loading, error } = useQuery(OBTENER_PRODUCTOS_SUCURSALS, {
    variables: {
      empresa: sesion.empresa._id,
      input: {
        producto,
      },
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    setPage(0)
  }, [producto])

  if (loading) return <CustomLoader height="50vh" />;
  if (error) return <NoMatchPage message={error.message} />;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        variant="scrollable"
        scrollButtons="auto"
        style={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 99,
        }}
      >
        {data.obtenerProductosBySucursal.map(
          ({ _id, nombre_sucursal }, index) => (
            <Tab
              label={nombre_sucursal}
              {...a11yProps(index)}
              key={`tab-${index}-${_id}`}
            />
          )
        )}
      </Tabs>
      {data.obtenerProductosBySucursal.map(
        ({ _id, productos_almacen }, index) => (
          <TabPanel
            value={value}
            index={index}
            key={`tabPanel-${index}-${_id}`}
          >
            <TableProductos
              productos={productos_almacen}
              page={page}
              setPage={setPage}
              limit={limit}
            />
          </TabPanel>
        )
      )}
    </div>
  );
}
