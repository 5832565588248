import React, { Fragment } from "react";
import {
  formatoFecha,
  formatoMexico,
} from "../../../../config/reuserFunctions";
import ExcelExportButton from "../../../../components/ExcelExportButton";

export default function ExportarProductosComprasExcel({ compra }) {
  const {
    almacen,
    proveedor,
    fecha_registro,
    subtotal,
    impuestos,
    total,
    productos,
  } = compra;

  const compra_realizada = [
    {
      almacen: almacen.nombre_almacen,
      proveedor: proveedor.nombre_cliente,
      fecha_registro: formatoFecha(fecha_registro),
      total: formatoMexico(total),
    },
  ];

  const productos_detalles = productos.map((productos) => {
    const {
      producto,
      medida,
      color,
      unidad,
      cantidad,
      Cantidad_regalo,
      cantidad_total,
      descuento_precio,
      descuento_porcentaje,
      total,
    } = productos;
    const detalle_producto = {
      producto: producto.datos_generales.nombre_comercial,
      medida: medida ? medida.medida : "N/A",
      color: color ? color.color : "N/A",
      unidad,
      cantidad,
      Cantidad_regalo,
      cantidad_total,
      descuento_precio,
      descuento_porcentaje,
      total: formatoMexico(total),
    };

    return detalle_producto;
  });

  return (
    <Fragment>
      <ExcelExportButton
            data={productos_detalles}
            name={`Productos Compra`}
            button
          />
    </Fragment>
  );
}
