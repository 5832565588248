import React, { useContext, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { VentasContext } from "../../context/Ventas/ventasContext";
import { OfflineBolt } from "@material-ui/icons";

const SwitchVentaRapida = () => {
  /* const classes = useStyles(); */
  const { ventaRapida, setVentaRapida } = useContext(VentasContext);

  const handleChange = (value) => {
    localStorage.setItem("ventaRapida", JSON.stringify(value));
    setVentaRapida(value);
  };

  useEffect(() => {
    if (ventaRapida === null) {
      localStorage.setItem("ventaRapida", false);
    }
  }, [ventaRapida]);

  return (
    <ListItem button onClick={(e) => handleChange(!ventaRapida)}>
      <ListItemIcon>
        <OfflineBolt color={ventaRapida ? "primary" : "action"} />
      </ListItemIcon>
      <ListItemText primary={ventaRapida ? "Modo Normal" : "Modo Lite"} />
    </ListItem>
  );
};

export default SwitchVentaRapida;
