import React, { useContext, useState, useEffect } from "react";
import { Detector } from "react-detect-offline";
import Box from "@material-ui/core/Box";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Close, Wifi, WifiOff } from "@material-ui/icons";
import Slide from "@material-ui/core/Slide";
import { AccesosContext } from "../../context/Accesos/accesosCtx";

export default function ConexionDetect() {
  return (
    <Box>
      <Detector polling={{url:'https://ipv4.icanhazip.com', interval:5000, timeout:5000, enabled:true}} render={({ online }) => <SnackOnline online={online} />} />
    </Box>
  );
}

const SnackOnline = ({ online }) => {
  const [open, setOpen] = useState(true);
  const { isOnline, setIsOnline } = useContext(AccesosContext);
  const [firstLoad, setLoasetFirstLoad] = useState(true);
  
  useEffect(() => {
    if (online) {
    
      setIsOnline(online);
    } else {
      setIsOnline(online);
      setLoasetFirstLoad(false);
    }

    setOpen(true);
  }, [online, setIsOnline, isOnline]);

  if (firstLoad) {
    return null;
  } else {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        autoHideDuration={isOnline ? 6000 : null}
        TransitionComponent={Slide}
        onClose={() => setOpen(false)}
        message={
          <Box display="flex">
            {isOnline ? (
              <Wifi htmlColor="#81c784" />
            ) : (
              <WifiOff htmlColor="#bdbdbd" />
            )}
            <Box mx={1} />
            <Typography>
              {isOnline ? "Estás conectado" : "Sin conexión"}
            </Typography>
          </Box>
        }
        action={
          isOnline ? (
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setOpen(false)}
              >
                <Close fontSize="small" />
              </IconButton>
            </React.Fragment>
          ) : null
        }
      />
    );
  }
};

/* const SnackOffline = () => (
  <Snackbar
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    open={true}
    TransitionComponent={Slide}
    message={
      <Box display="flex">
        <WifiOff htmlColor="#bdbdbd" />
        <Box mx={1} />
        <Typography>Sin Conexión</Typography>
      </Box>
    }
  />
);
 */