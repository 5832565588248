import React, { useState, forwardRef, useEffect, useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {
  RestoreFromTrash,
  Close,
  Search,
  DeleteOutline,
} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Box,
  IconButton,
  CircularProgress,
  Typography,
  TextField,
  InputAdornment,
  TablePagination,
} from "@material-ui/core";
import { Fragment } from "react";
import ErrorPage from "../../../../components/ErrorPage";
import { useMutation, useQuery } from "@apollo/client";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import {
  ACTUALIZAR_CLIENTE,
  OBTENER_CLIENTES,
} from "../../../../gql/Catalogos/clientes";
import moment from "moment-timezone";
import { ClienteCtx } from "../../../../context/Catalogos/crearClienteCtx";
import { useDebounce } from "use-debounce";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ClientesInactivosComponent({ tipo }) {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({ cliente: "" });
  const { isOnline } = useContext(AccesosContext);
  const handleModalToggle = () => setOpen(!open);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [value] = useDebounce(filter, 1000);
  const { update } = useContext(ClienteCtx);
  const { data, refetch } = useQuery(OBTENER_CLIENTES, {
    variables: {
      tipo: "PROVEEDOR",
      filtros: value,
      empresa: sesion.empresa._id,
      eliminado: true,
    },
    fetchPolicy: "network-only",
  });

  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  useEffect(() => {
    refetch();
  }, [update, refetch]);

  return (
    <div>
      <Button
        disableElevation
        color="inherit"
        onClick={handleModalToggle}
        startIcon={<DeleteOutline />}
        disabled={!isOnline}
      >
        Eliminados
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            handleModalToggle();
          }
        }}
        aria-labelledby="dialog-clientes-eliminados"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="dialog-clientes-eliminados">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              {tipo === "CLIENTE" ? "Clientes" : "Proveedores "} eliminados
            </Typography>
            <Button
              variant="contained"
              onClick={handleModalToggle}
              color="secondary"
            >
              <Close />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <TextField
              fullWidth
              placeholder="Buscar cliente: No. cliente, clave o nombre..."
              onChange={(e) => obtenerCampos(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              value={filter.cliente}
              variant="outlined"
              size="small"
              name="cliente"
            />
          </Box>
          <ClientesInactivos
            tipo={tipo}
            filter={filter}
            refetchProveedores={refetch}
            dataProveedores={data}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ClientesInactivos = ({
  tipo,
  filter,
  refetchProveedores,
  dataProveedores,
}) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [value] = useDebounce(filter, 1000);
  const { update } = useContext(ClienteCtx);
  const [page, setPage] = useState(0);
  const limit = 20;

  /* Queries */
  const { loading, data, error, refetch } = useQuery(OBTENER_CLIENTES, {
    variables: {
      tipo: "CLIENTE",
      filtros: value,
      empresa: sesion.empresa._id,
      eliminado: true,
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
  }, [update, refetch]);

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) {
    console.log(error);
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
      >
        <ErrorPage error={error} />
      </Box>
    );
  }

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  // const { obtenerClientes } = data;
  // const obtenerProveedores = dataProveedores;
  // console.log("Clientes: ", obtenerClientes);
  // console.log("Proveedores: ", obtenerProveedores);
  let dataMap = data;
  if (tipo === "PROVEEDOR") {
    dataMap = dataProveedores;
  }
  return (
    <Box height="60vh">
      <TableContainer style={{ height: "50vh" }}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                No. {tipo === "CLIENTE" ? "Cliente" : "Proveedor "}
              </TableCell>
              <TableCell>Clave</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>Correo</TableCell>
              <TableCell>Fecha Registro</TableCell>
              <TableCell align="right">Reactivar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataMap.obtenerClientes.docs.map((row, index) => (
              <RenderClientes
                key={index}
                datos={row}
                refetchProveedores={refetchProveedores}
                tipo={tipo}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={dataMap.obtenerClientes.totalDocs}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
      />
    </Box>
  );
};

const RenderClientes = ({ datos, refetchProveedores, tipo }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const { setUpdate, update } = useContext(ClienteCtx);
  const { isOnline } = useContext(AccesosContext);

  const [actualizarCliente] = useMutation(ACTUALIZAR_CLIENTE);

  const cambiarEstado = async (e) => {
    setLoading(true);
    let copy_cliente = { ...datos };
    try {
      const {
        numero_cliente,
        _id,
        clave_cliente,
        sucursal,
        empresa,
        ...input
      } = copy_cliente;
      await actualizarCliente({
        variables: {
          input: {
            ...input,
            eliminado: false,
          },
          id: datos._id,
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
        },
      });
      setLoading(false);
      setUpdate(!update);
      if (refetchProveedores) refetchProveedores();
      handleDeleteToggle();
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDeleteToggle = () => setOpen(!open);

  return (
    <Fragment>
      <TableRow>
        <TableCell>
          <Typography>{datos.numero_cliente}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{datos.clave_cliente}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{datos.nombre_cliente}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{datos.email}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {moment(datos.fecha_registro)
              .tz("America/Mexico_City")
              .format("DD/MM/YYYY")}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton
            color="primary"
            size="small"
            onClick={handleDeleteToggle}
            disabled={!isOnline}
          >
            <RestoreFromTrash style={{ fontSize: 30 }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <Dialog
        open={open}
        onClose={handleDeleteToggle}
        aria-labelledby="reactivar-cliente-dialog"
      >
        <DialogTitle id="reactivar-cliente-dialog">
          {`Se reactivará este ${
            tipo === "CLIENTE" ? "cliente" : "proveedor "
          }`}
        </DialogTitle>
        <SnackBarMessages alert={alert} setAlert={setAlert} />
        <DialogActions>
          <Button onClick={handleDeleteToggle} color="inherit">
            Cancelar
          </Button>
          <Button
            onClick={() => cambiarEstado()}
            color="primary"
            autoFocus
            startIcon={loading ? <CircularProgress size={20} /> : null}
            disabled={!isOnline}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
