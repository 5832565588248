import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Close, Person, Search } from "@material-ui/icons";
import ListaClientes from "./ListaClientes";
import CrearCliente from "../../sucursales/Catalogos/Cliente/CrearCliente";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonGroup } from "@material-ui/core";
import { ClienteCtx } from "../../../context/Catalogos/crearClienteCtx";
import { CustomSearchVentaRapidaTF } from "../../../components/CustomMuiComponents";
import { MainContext } from "../../../context/MainCtx";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "30vw",
    height: "calc(100vh - 72px)",
    top: 58,
    //bottom: "auto",
    right: 20,
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
  },
}));

export default function ClientesVentas() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { updateClientVenta, setUpdateClientVenta } = React.useContext(
    ClienteCtx
  );
  const { turnoEnCurso } = React.useContext(MainContext);
  const datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
  const cliente = datosVentas?.cliente && datosVentas?.cliente.nombre_cliente;
  const [page, setPage] = React.useState(0);
  const [values, setValues] = React.useState("");
  const [search, setSearch] = React.useState("");

  const handleDrawerToogle = () => {
    setOpen(!open);
  };

  const removeCliente = async () => {
    const productosVentas = !datosVentas ? [] : datosVentas.productos;
    if (!productosVentas.length) {
      //eliminar de storage
      localStorage.removeItem("DatosVentas");
    } else {
      //solo eliminar cliente de storage
      localStorage.setItem(
        "DatosVentas",
        JSON.stringify({
          ...datosVentas,
          cliente: {},
          venta_cliente: false,
          tipo_emision: datosVentas?.tipo_emision || "TICKET",
        })
      );
    }
    setUpdateClientVenta(!updateClientVenta);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 67) {
        handleDrawerToogle();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearch(values);
  };

  return (
    <React.Fragment>
      <ButtonGroup size="small">
        <Button
          color={cliente ? "primary" : "default"}
          disableElevation
          variant="contained"
          onClick={handleDrawerToogle}
          disabled={!turnoEnCurso}
          size="small"
          style={{
            textTransform: "none",
            backgroundColor: cliente ? theme.palette.cafiPrimary : "",
          }}
          startIcon={cliente ? <Person /> : <Search />}
        >
          {cliente ? cliente : "Público General"}
        </Button>
        {cliente ? (
          <Button onClick={removeCliente}>
            <Close />
          </Button>
        ) : null}
      </ButtonGroup>
      <Drawer
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0)",
          },
        }}
        onClose={handleDrawerToogle}
      >
        <DialogTitle component="div">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Clientes</Typography>
            <Box display="flex" alignItems="center" gridGap={10}>
              <CrearCliente
                tipo="CLIENTE"
                accion="registrar"
                ventas={true}
                handleClickOpen={handleDrawerToogle}
              />
              <Button
                size="small"
                color="inherit"
                variant="contained"
                disableElevation
                style={{ textTransform: "none" }}
                onClick={handleDrawerToogle}
                startIcon={<CloseIcon />}
              >
                Cerrar
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <Box px={2} pb={0.5}>
          <form onSubmit={handleSubmit}>
            <CustomSearchVentaRapidaTF
              placeholder="Buscar número, nombre o email"
              onChange={(e) => {
                setValues(e.target.value);
                if(!e.target.value){
                  setSearch("");
                }
              }}
              fullWidth
              value={values}
              size="small"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start" />,
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      startIcon={<Search color="action" />}
                      size="small"
                      type="submit"
                      style={{ textTransform: "none" }}
                    >
                      Buscar
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Box>
        <ListaClientes
          tipo="CLIENTE"
          filtro={{
            cliente: search,
          }}
          ventas={true}
          handleClickOpen={handleDrawerToogle}
          page={page}
          setPage={setPage}
        />
      </Drawer>
    </React.Fragment>
  );
}
