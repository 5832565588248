import axios from "axios";

const token = process.env.REACT_APP_FACTURAMA_TOKEN;
const url = process.env.REACT_APP_FACTURAMA_URL;

async function list(path, callback) {
  //console.log(url + path);
  let resultado = null;
  await axios
    .get(url + path, {
      headers: { Authorization: "Basic " + token },
      responseType: "json",
    })
    .then(
      (res) =>
        callback(
          undefined,
          res.data
        ) /* {
      resultado = { data: res.data, success: true };
    } */
    )
    .catch(
      (err) =>
        callback(
          err
        ) /* {
      resultado = { message: err.response.data.Message, success: false };
    } */
    );
  return resultado;
}
const Catalogs = {
  States: async function (country, callback) {
    return await list(
      "catalogs/municipalities?countryCode" + country,
      callback
    );
  },
  Municipalities: function (state, callback) {
    return list("catalogs/municipalities?stateCode" + state, callback);
  },
  Localities: function (state, callback) {
    return list("catalogs/localities?stateCode" + state, callback);
  },
  Neighborhoods: function (postalCode, callback) {
    return list("catalogs/neighborhoods?postalCode=" + postalCode, callback);
  },
  ProductsOrServices: function (keyword, callback) {
    return list("catalogs/ProductsOrServices?keyword=" + keyword, callback);
  },
  PostalCodes: function (keyword, callback) {
    return list("catalogs/PostalCodes?keyword=" + keyword, callback);
  },
  Units: function (callback) {
    return list("catalogs/Units", callback);
  },
  Currencies: function (callback) {
    return list("catalogs/Currencies", callback);
  },
  Countries: function () {
    return list("catalogs/Countries");
  },
  PaymentForms: function (callback) {
    return list("catalogs/PaymentForms", callback);
  },
  PaymentMethods: function (callback) {
    return list("catalogs/PaymentMethods", callback);
  },
  FederalTaxes: function (callback) {
    return list("catalogs/FederalTaxes", callback);
  },
  FiscalRegimens: function (callback) {
    return list("catalogs/FiscalRegimens", callback);
  },
  CfdiTypes: function (callback) {
    return list("Catalogs/CfdiTypes", callback);
  },
  RelationTypes: function (callback) {
    return list("catalogs/RelationTypes", callback);
  },
  CfdiUses: function (keyword, callback) {
    return list("catalogs/CfdiUses?keyword=" + keyword, callback);
  },
};
export default Catalogs;
