import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import { formaPago } from "../../../Facturacion/catalogos";

export const TablaConcepto = ({ concepto, data }) => {
  switch (concepto) {
    case "tipo_emision":
      return (
        <Box display="flex" justifyContent="center">
          <Box style={{ width: "22.5cm" }}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box mt={2} pb={5} style={{ width: "50%" }}>
                <TableContainer>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="a dense table"
                    id="tabla-conceptos"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Tipo de Emisión
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Monto
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Factura</TableCell>
                        <TableCell>
                          ${formatoMexico(data.conceptoTipoEmision[0].factura)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Nota de remisión</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoTipoEmision[0].nota_remision
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Ticket</TableCell>
                        <TableCell>
                          ${formatoMexico(data.conceptoTipoEmision[0].ticket)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "800" }}>
                          Total
                        </TableCell>
                        <TableCell style={{ fontWeight: "800" }}>
                          ${formatoMexico(data.totalVenta)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      );

    case "usuario":
      return (
        <Box display="flex" justifyContent="center">
          <Box style={{ width: "22.5cm" }}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box mt={2} pb={5} style={{ width: "50%" }}>
                <TableContainer>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="a dense table"
                    id="tabla-conceptos"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Usuario
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Monto
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.conceptoUsuario.map((usuario, index, key) => (
                        <TableRow>
                          <TableCell key={index}>{usuario.usuario}</TableCell>
                          <TableCell key={key}>
                            ${formatoMexico(usuario.total)}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell style={{ fontWeight: "800" }}>
                          Total
                        </TableCell>
                        <TableCell style={{ fontWeight: "800" }}>
                          ${formatoMexico(data.totalVenta)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      );

    case "caja":
      return (
        <Box display="flex" justifyContent="center">
          <Box style={{ width: "22.5cm" }}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box mt={2} pb={5} style={{ width: "50%" }}>
                <TableContainer>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="a dense table"
                    id="tabla-conceptos"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Caja
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Monto
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.conceptoCaja.map((caja, index, key) => (
                        <TableRow>
                          <TableCell key={index}>{caja.caja}</TableCell>
                          <TableCell key={key}>
                            ${formatoMexico(caja.total)}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell style={{ fontWeight: "800" }}>
                          Total
                        </TableCell>
                        <TableCell style={{ fontWeight: "800" }}>
                          ${formatoMexico(data.totalVenta)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      );

    case "metodo_pago":
      return (
        <Box display="flex" justifyContent="center">
          <Box style={{ width: "22.5cm" }}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box mt={2} pb={5} style={{ width: "50%" }}>
                <TableContainer>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="a dense table"
                    id="tabla-conceptos"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Método de Pago
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Monto
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Crédito</TableCell>
                        <TableCell>
                          ${formatoMexico(data.conceptoMetodoDePago[0].credito)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Contado</TableCell>
                        <TableCell>
                          ${formatoMexico(data.conceptoMetodoDePago[0].contado)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "800" }}>
                          Total
                        </TableCell>
                        <TableCell style={{ fontWeight: "800" }}>
                          ${formatoMexico(data.totalVenta)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      );

    case "forma_pago":
      return (
        <Box display="flex" justifyContent="center">
          <Box style={{ width: "22.5cm" }}>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box mt={2} pb={5} style={{ width: "50%" }}>
                <TableContainer>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="a dense table"
                    id="tabla-conceptos"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Forma de Pago
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#2980ba", color: "#fff" }}
                        >
                          Monto
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Efectivo</TableCell>
                        <TableCell>
                          ${formatoMexico(data.conceptoFormaDePago[0].efectivo)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cheque Nominativo</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoFormaDePago[0].cheque_nominativo
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Transferencia</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoFormaDePago[0].transferencia
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tarjeta de credito</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoFormaDePago[0].tarjeta_credito
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Monedero electrónico</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoFormaDePago[0].monedero_electronico
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Dinero electrónico</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoFormaDePago[0].dinero_electronico
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Vales de despensa</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoFormaDePago[0].vales_despensa
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Tarjeta de débito</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoFormaDePago[0].tarjeta_debito
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Aplicación de anticipos</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(data.conceptoFormaDePago[0].anticipos)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Por definir</TableCell>
                        <TableCell>
                          $
                          {formatoMexico(
                            data.conceptoFormaDePago[0].por_definir
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ fontWeight: "800" }}>
                          Total
                        </TableCell>
                        <TableCell style={{ fontWeight: "800" }}>
                          ${formatoMexico(data.totalVenta)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      );

    default:
      return null;
  }
};
