import React from "react";
import { Box } from "@material-ui/core";
import ItemProducto from "./ItemProducto";
import { Skeleton } from "@material-ui/lab";
import ErrorMessage from "../../../../components/ErrorMessage";

export default function ListaProductos({
  productosBusqueda,
  setProductoSeleccionado,
  productoSeleccionado,
  loading,
  agregarProductos,
  error,
}) {
  const height = "calc(100vh - 206px)";
  if (loading) return <RenderLoading />;
  if (error) return <ErrorMessage message={error?.message} height={height} />;

  const handleTableSelect = (e, producto, value) => {
    agregarProductos(producto, value);
    setProductoSeleccionado(producto);
  };

  return (
    <Box
      height={height}
      display="grid"
      gridTemplateColumns="repeat(5, minmax(100px, 1fr))"
      gridTemplateRows="repeat(4, minmax(100px, 1fr))"
      gridGap={8}
      mb={1.5}
    >
      {productosBusqueda.docs.map((producto, index) => {
        return (
          <ItemProducto
            key={index}
            producto={producto}
            handleTableSelect={handleTableSelect}
            productoSeleccionado={productoSeleccionado}
          />
        );
      })}
    </Box>
  );
}

const RenderLoading = () => {
  return (
    <Box
      height="calc(100vh - 202px)"
      display="grid"
      gridTemplateColumns="repeat(5, minmax(100px, 1fr))"
      gridTemplateRows="repeat(4, minmax(100px, 1fr))"
      gridGap={8}
      mb={1}
    >
      {[1, 2, 3, 4, 5, 6, 7].map((res) => {
        return <Skeleton style={{ height: "100%" }} />;
      })}
    </Box>
  );
};
