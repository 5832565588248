import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Barcode from "react-barcode";
import { Typography, Box } from "@material-ui/core";
import { formatoMexico } from "../../../../../config/reuserFunctions";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const EtiquetaMultiple = ({ datosEtiqueta }) => {
  const options = {
    width: 1,
    height: 17,
    format: "CODE128",
    displayValue: true,
    fontOptions: "",
    font: "monospace",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 0,
    fontSize: 10,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 1,
    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined,
  };
  const {
    margen,
    cantidad_etiquetas,
    size,
    nombre,
    medida,
    cantidad,
    unidad,
    precio,
    codigo,
  } = datosEtiqueta;
  const sizes = size.split("x");

  let newArray = [];
  for (let index = 0; index < cantidad_etiquetas; index++) {
    newArray.push(index);
  }

  const styles = {
    minWidth: sizes[0],
    minHeight: sizes[1],
    padding: "8px 2px 8px 2px",
    margin: `0 ${margen}mm 0 ${margen}mm`,
  };

  const render_etiquetas = newArray.map((res) => {
    return (
      <Card variant="outlined" style={styles} key={res}>
        <CardContent style={{ padding: 0 }}>
          <Typography
            noWrap
            style={{
              lineHeight: 1,
              fontSize: 7,
            }}
          >
            {nombre}
          </Typography>
          <Box display="flex" justifyContent="center">
            <Barcode value={codigo} {...options} />
          </Box>
        </CardContent>
      </Card>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "90mm",
        height: sizes[1],
      }}
    >
      {render_etiquetas}
    </div>
  );
};
