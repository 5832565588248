import {
  Box,
  Checkbox,
  Drawer,
  FormControlLabel,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";

export const ModalSeleccion = ({
  openModal,
  setOpenModal,
  valuesColumnas,
  setValuesColumnas,
}) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  const obtenerChecks = (e) => {
    const { name, checked } = e.target;
    setValuesColumnas({
      ...valuesColumnas,
      [name]: checked,
    });
  };

  return (
    <Drawer
      open={openModal}
      onClose={handleClose}
      anchor="right"
      style={{ minWidth: "100px" }}
    >
      <Box
        display="flex"
        alignItems="center"
        position="fixed"
        sx={{
          ml: 2,
          mr: 2,
          minWidth: "150px",
          bgcolor: "#fff",
          zIndex: 10,
        }}
      >
        <IconButton variant="contained" color="primary" onClick={handleClose}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" style={{ margin: "15px" }}>
          Personalizar tabla
        </Typography>
      </Box>

      <Toolbar />

      <Box sx={{ ml: 5, mr: 5, mb: 2 }}>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.folio}
                onChange={obtenerChecks}
                name="folio"
                color="primary"
              />
            }
            label="Folio"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.fecha}
                onChange={obtenerChecks}
                name="fecha"
                color="primary"
              />
            }
            label="Fecha"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.cliente}
                onChange={obtenerChecks}
                name="cliente"
                color="primary"
              />
            }
            label="Cliente"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.clave_cliente}
                onChange={obtenerChecks}
                name="clave_cliente"
                color="primary"
              />
            }
            label="Clave cliente"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.usuario}
                onChange={obtenerChecks}
                name="usuario"
                color="primary"
              />
            }
            label="Usuario"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.caja}
                onChange={obtenerChecks}
                name="caja"
                color="primary"
              />
            }
            label="Caja"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.tipo_emision}
                onChange={obtenerChecks}
                name="tipo_emision"
                color="primary"
              />
            }
            label="Tipo de emisión"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.forma_pago}
                onChange={obtenerChecks}
                name="forma_pago"
                color="primary"
              />
            }
            label="Forma de pago"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.metodo_pago}
                onChange={obtenerChecks}
                name="metodo_pago"
                color="primary"
              />
            }
            label="Método de pago"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.credito_pendiente}
                onChange={obtenerChecks}
                name="credito_pendiente"
                color="primary"
              />
            }
            label="Crédito pendiente"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.fecha_limite_credito}
                onChange={obtenerChecks}
                name="fecha_limite_credito"
                color="primary"
              />
            }
            label="Fecha límite de crédito"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.descuento}
                onChange={obtenerChecks}
                name="descuento"
                color="primary"
              />
            }
            label="Descuento"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.subtotal}
                onChange={obtenerChecks}
                name="subtotal"
                color="primary"
              />
            }
            label="Subtotal"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.impuestos}
                onChange={obtenerChecks}
                name="impuestos"
                color="primary"
              />
            }
            label="Impuestos"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={valuesColumnas.total}
                onChange={obtenerChecks}
                name="total"
                color="primary"
              />
            }
            label="Total"
          />
        </Box>
        {/* 
        <Grid content>
          <Box m={1} />
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleClose}>
              Cerrar
            </Button>
          </Grid>
        </Grid> */}
      </Box>
    </Drawer>
  );
};
