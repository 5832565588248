import React from "react";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";
import { TiendaCtx } from "../../../../../../context/Empresa/tiendaCtx";
import EliminarBanner from "./EliminarBanner";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const useStyles = makeStyles((theme) => ({
  root: {
    with: "100%",
  },
  media: {
    height: 100,
    borderRadius: 5,
  },
}));

export default function CardBanner() {
  const classes = useStyles();
  const { data, setData } = React.useContext(TiendaCtx);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newState = [...data.banners];
    const [removed] = newState.splice(sourceIndex, 1);
    newState.splice(destinationIndex, 0, removed);

    setData((state) => ({
      ...state,
      banners: newState,
      orderChanged: newState.filter((res) => res._id),
    }));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="banners">
        {(provided) => (
          <Grid
            container
            spacing={2}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {data?.banners?.map((res, index) => (
              <Draggable key={res._id} draggableId={res._id} index={index}>
                {(provided) => (
                  <Grid
                    item
                    xs={12}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <Paper className={classes.root}>
                      <ItemBannerCart
                        banner={res}
                        provided={provided}
                      />
                    </Paper>
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const ItemBannerCart = ({ banner, provided }) => {
  const classes = useStyles();
  const { url_imagen } = banner;
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "grab",
        }}
        {...provided.dragHandleProps}
      >
        <DragIndicator
          color="action"
          style={{ marginLeft: 4 }}
        />
      </Grid>
      <Grid item xs={10}>
        <CardMedia
          className={classes.media}
          image={url_imagen}
          title="banner principal"
        />
      </Grid>
      <Grid
        item
        xs={1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EliminarBanner banner={banner} />
      </Grid>
    </Grid>
  );
};
