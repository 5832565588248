import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Box from "@material-ui/core/Box";
import DownloadInstaller from "../../../components/DownloadIntaller/DownloadInstaller";
import ComponentOnline from "../../../components/Connection/ComponentOnline";
import { AccesosContext } from "../../../context/Accesos/accesosCtx";
import { useNavigate } from "react-router-dom";
import Acceso from "../../../components/AccesosPassword/Acceso";
import Bascula from "../../../components/Perifericos/Bascula";
import TicketPrinterComponent from "../../../components/Perifericos/TicketPrinter";
import SwitchVentaRapida from "../../../components/Ventas/SwitchVentaRapida";
import SwitchAutoVentaToCloud from "../../../components/Ventas/SwitchAutoVentaToCloud";
import ProductoRapidoIndex from "../../ventas/ArticuloRapido/indexArticuloRapido";
import ListaCotizaciones from "../../ventas/Cotizacion/ListaCotizaciones";
import PedidosVenta from "../../ventas/Pedidos/Pedidos";
import DepositoRetiroCaja from "../../ventas/Operaciones/DepositoRetiros/DepositoRetiroCaja";
import Turnos from "../../ventas/AbrirCerrarTurno/Turnos";
import PreCorteCaja from "../../ventas/Operaciones/Precorte/PreCorteCaja";
import VentasEspera from "../../ventas/VentasEspera/VentasEspera";
import CerrarSesion from "./CerrarSesion";
import GoAdminPanel from "./GoAdmin";
import { MainContext } from "../../../context/MainCtx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.cafiPrimary,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7.5) + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function NavbarVentas() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { turnoEnCurso } = React.useContext(MainContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {
    isOnline,
    ventasToCloud,
    reloadAdministrador,
    setReloadAdministrador,
  } = React.useContext(AccesosContext);

  const handleDrawerToogle = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    if (reloadAdministrador === true) {
      navigate("/admin");
      setReloadAdministrador(false);
    }
  }, [reloadAdministrador]);

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Acceso setReloadAdministrador={setReloadAdministrador} />
        <Box px={2} display="flex" alignItems="center" gridGap={5}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToogle}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Box mx={1}>
            <Typography variant="subtitle2" color="inherit">
              {`${sesion.nombre} • Caja ${turnoEnCurso?.numero_caja}`}
            </Typography>
          </Box>
          <Box flexGrow={1} />
          <DownloadInstaller />
          <ComponentOnline
            isOnline={isOnline}
            ventasToCloud={ventasToCloud}
            sesion={sesion}
            fromVentas={true}
            size="small"
          />
          <GoAdminPanel />
          <CerrarSesion />
        </Box>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Toolbar variant="dense" />
        <List>
          <ProductoRapidoIndex />
          <ListaCotizaciones />
          <PedidosVenta />
          <DepositoRetiroCaja />
          <Turnos />
          <PreCorteCaja />
          <VentasEspera />
        </List>
        <Divider />
        <List>
          <SwitchVentaRapida />
          <SwitchAutoVentaToCloud />
          <TicketPrinterComponent />
          <Bascula />
        </List>
      </Drawer>
    </>
  );
}
