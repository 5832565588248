export const verificarDatosFactura = (datos) => {
  const {
    cfdi_type,
    currency,
    date,
    empresa,
    expedition_place,
    folio,
    items,
    receiver,
    serie,
    sucursal,
  } = datos;

  //verificar datos factura
  if (
    !cfdi_type ||
    !currency ||
    !date ||
    !empresa ||
    !expedition_place ||
    !folio ||
    !items ||
    !receiver ||
    !serie ||
    !sucursal
  ) {
    return { message: "Campo requerido" };
  }

  return null;
};
