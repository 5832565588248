import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { RegProductoContext } from "../../../../../context/Catalogos/CtxRegProducto";

const useStyles = makeStyles((theme) => ({
  titulos: {
    fontWeight: 500,
  },
}));

export default function Impuestos() {
  const classes = useStyles();
  const { precios, setPrecios } = React.useContext(RegProductoContext);

  /* CHECKBOX IVA */
  const obtenerIva = async (e) => {
    const name = e.target.name;
    const value = name === "iva_activo" ? e.target.checked : e.target.value;
    if (name === "iva" && !value) {
      setPrecios({
        ...precios,
        iva: "",
      });
      return;
    }
    setPrecios({
      ...precios,
      iva_activo: name === "iva_activo" ? value : precios.iva_activo,
      iva: name === "iva" ? parseFloat(value) : value ? 16 : 0,
    });
  };

  /* CHECKBOX IEPS */
  const obtenerIeps = async (e) => {
    const name = e.target.name;
    const value = name === "ieps_activo" ? e.target.checked : e.target.value;
    if (name === "ieps" && !value) {
      setPrecios({
        ...precios,
        ieps: "",
      });
      return;
    }
    setPrecios({
      ...precios,
      ieps_activo: name === "ieps_activo" ? value : precios.ieps_activo,
      ieps: name === "ieps" ? parseFloat(value) : value ? precios.ieps : 0,
    });
  };

  return (
    <Box>
      <Box my={2}>
        <Typography>
          <b>Impuestos</b>
        </Typography>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <Box>
            <Typography noWrap className={classes.titulos}>
              IVA
            </Typography>
            <TextField
              fullWidth
              disabled={!precios.iva_activo}
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">
                    <Checkbox
                      checked={precios.iva_activo}
                      onChange={obtenerIva}
                      name="iva_activo"
                    />
                    <Typography>%</Typography>
                  </InputAdornment>
                ),
              }}
              size="small"
              name="iva"
              id="form-producto-iva"
              variant="outlined"
              value={precios.iva}
              onChange={obtenerIva}
              onFocus={(e) => {
                const { name, value } = e.target;
                if (parseFloat(value) === 0) {
                  setPrecios({
                    ...precios,
                    [name]: "",
                  });
                }
              }}
              onBlur={(e) => {
                const { name, value } = e.target;
                if (!value) {
                  setPrecios({
                    ...precios,
                    [name]: 16,
                  });
                }
              }}
              error={precios.iva === ""}
            />
          </Box>
        </Grid>
        <Grid item md={3} xs={12}>
          <Box>
            <Typography noWrap className={classes.titulos}>
              IEPS
            </Typography>
            <TextField
              disabled={!precios.ieps_activo}
              fullWidth
              type="number"
              InputProps={{
                inputProps: { min: 0 },
                startAdornment: (
                  <InputAdornment position="start">
                    <Checkbox
                      checked={precios.ieps_activo}
                      onChange={obtenerIeps}
                      name="ieps_activo"
                    />
                    <Typography>%</Typography>
                  </InputAdornment>
                ),
              }}
              size="small"
              name="ieps"
              id="form-producto-ieps"
              variant="outlined"
              value={precios.ieps}
              onChange={obtenerIeps}
              onFocus={(e) => {
                const { name, value } = e.target;
                if (parseFloat(value) === 0) {
                  setPrecios({
                    ...precios,
                    [name]: "",
                  });
                }
              }}
              onBlur={(e) => {
                const { name, value } = e.target;
                if (!value) {
                  setPrecios({
                    ...precios,
                    [name]: 0,
                  });
                }
              }}
              error={precios.ieps === ""}
            />
          </Box>
        </Grid>
        {/* 
          <Grid item md={4} xs={12}>
            <Box display="flex" alignItems="flex-end" height="100%" ml={1}>
              <Alert severity="info">Selecciona los impuestos aplicables</Alert>
            </Box>
          </Grid> */}
      </Grid>
    </Box>
  );
}
