import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import {
  Box,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { ArrowBack, ClearOutlined, Search } from "@material-ui/icons";
import TablaProovedores from "./ListaProveedores";
import CrearCliente from "../Cliente/CrearCliente";
import ImportarProveedores from "./ImportXLS/ImportXlsButton";
import DescripcionCatalogo from "../../../../components/DescripcionCatalogo";
import ClientesInactivosComponent from "../Cliente/ClientesInactivos";
import { FcInTransit } from "react-icons/fc";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import ExportarProveedores from "./ExportarProveedores";
import { useDebounce } from "use-debounce";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  icon: {
    width: 100,
    fontSize: 100,
  },
  root: {
    display: "flex",
    paddingLeft: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initial_state_filtro = {
  cliente: "",
  fecha_inicio: "",
  fecha_fin: "",
  activos: false,
  inactivos: false,
};

export default function Proveedores() {
  const { isOnline } = useContext(AccesosContext);
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const descripcion =
    "En este apartado se registran los proveedores que se podrán seleccionar en la compra de un producto.";

  const [values, setValues] = useState(initial_state_filtro);
  const [value] = useDebounce(values, 700);
  const permisosUsuario = JSON.parse(localStorage.getItem("sesionCafi"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    limpiarFiltros();
  };

  const limpiarFiltros = () => {
    setValues(initial_state_filtro);
    setPage(0);
  };

  return (
    <div>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            {/* <img
              src="distribution.svg"
              alt="icono numero calzado"
              className={classes.icon}
            /> */}
            <FcInTransit className={classes.icon} />
          </Box>
          Proveedores
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Typography variant="h6" className={classes.title}>
          Proveedores
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <Button
            color="primary"
            startIcon={<ClearOutlined />}
            onClick={() => limpiarFiltros()}
          >
            Limpiar filtros
          </Button>
          <Box mx={1} />
          {permisosUsuario.accesos.catalogos.clientes.agregar ===
          false ? null : (
            <>
              <ClientesInactivosComponent
                isOnline={isOnline}
                tipo="PROVEEDOR"
              />
              <Box mx={1} />
            </>
          )}

          <ExportarProveedores filtro={values} tipo="PROVEEDOR" />
          <Box mx={1} />
          {permisosUsuario.accesos.catalogos.provedores.agregar ===
          false ? null : (
            <>
              <ImportarProveedores />
              <Box mx={1} />
            </>
          )}
          {permisosUsuario.accesos.catalogos.provedores.agregar ===
          false ? null : (
            <>
              <CrearCliente
                isOnline={isOnline}
                tipo="PROVEEDOR"
                accion="registrar"
              />
              <Box mx={0.5} />
            </>
          )}
          <DescripcionCatalogo texto={descripcion} />
          <IconButton variant="contained" color="primary" onClick={handleClose}>
            <ArrowBack />
          </IconButton>
        </Box>
        <DialogContent>
          <ProveedoresComponent
            isOnline={isOnline}
            value={value}
            values={values}
            setValues={setValues}
            page={page}
            setPage={setPage}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const ProveedoresComponent = ({
  isOnline,
  values,
  setValues,
  page,
  setPage,
  value,
}) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  // const { loading, data, error, refetch } = useQuery(OBTENER_CLIENTES, {
  //   variables: {
  //     tipo: "PROVEEDOR",
  //     filtros: value,
  //     empresa: sesion.empresa._id,
  //     eliminado: true,
  //     limit,
  //     offset: page,
  //   },
  //   fetchPolicy: "network-only",
  // });

  // if (loading)
  //   return (
  //     <Box
  //       display="flex"
  //       justifyContent="center"
  //       alignItems="center"
  //       height="30vh"
  //     >
  //       <CircularProgress />
  //     </Box>
  //   );
  // if (error) {
  //   return <ErrorPage error={error} />;
  // }

  // const { obtenerClientes } = data;

  // console.log(obtenerClientes);

  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setPage(0);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <Typography>Buscar proveedor:</Typography>
          <TextField
            fullWidth
            placeholder="Clave, No., Nombre, Teléfono, Correo..."
            onChange={(e) => obtenerCampos(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            value={values.cliente}
            variant="outlined"
            size="small"
            name="cliente"
          />
        </Grid>
        <Grid item md={3}>
          <Typography>Desde:</Typography>
          <TextField
            fullWidth
            onChange={(e) => obtenerCampos(e)}
            type="date"
            value={values.fecha_inicio}
            variant="outlined"
            size="small"
            name="fecha_inicio"
          />
        </Grid>
        <Grid item md={3}>
          <Typography>Hasta:</Typography>
          <TextField
            fullWidth
            onChange={(e) => obtenerCampos(e)}
            type="date"
            value={values.fecha_fin}
            variant="outlined"
            size="small"
            name="fecha_fin"
          />
        </Grid>
      </Grid>
      <Box mt={5}>
        <TablaProovedores
          isOnline={isOnline}
          page={page}
          setPage={setPage}
          values={values}
          sesion={sesion}
          value={value}
        />
      </Box>
    </Box>
  );
};
