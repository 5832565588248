import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import { Box, TablePagination, Typography } from "@material-ui/core";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../config/reuserFunctions";
import { formaPago } from "../../Facturacion/catalogos";
import { useQuery } from "@apollo/client";
import { OBTENER_REPORTE_COMPRAS } from "../../../../gql/Compras/reporte_compras";
import { OBTENER_COMPRAS_REALIZADAS } from "../../../../gql/Compras/compras";

const columns = [
  {
    label: "Nombre producto",
    minWidth: 300,
    position: "sticky",
  },
  { label: "Fecha", minWidth: 160, position: "static" },
  { label: "Fecha y hora", minWidth: 160, position: "static" },
  { label: "Almacen", minWidth: 100, position: "static" },
  { label: "Proveedor", minWidth: 150, position: "static" },
  { label: "M. pago", minWidth: 100, position: "static" },
  { label: "F. pago", minWidth: 130, position: "static" },
  { label: "Cantidad", minWidth: 90, position: "static" },
  { label: "Fecha límite crédito", minWidth: 170, position: "static" },
  { label: "Saldo pendiente", minWidth: 150, position: "static" },
  { label: "Descuento", minWidth: 120, position: "static" },
  { label: "Total", minWidth: 120, position: "static" },
];

const useStyles = makeStyles((theme) => ({
  container: {
    height: "60vh",
  },
  normal_color: {
    position: "sticky",
    left: 0,
    backgroundColor: "#fff",
    borderLeft: "6px solid #FFF",
  },
  cancelada: {
    position: "sticky",
    left: 0,
    backgroundColor: "#fff",
    borderLeft: " 6px solid #FF8A8A",
  },
}));

export default function TablaComprasFiltradas({
  data,
  page,
  setPage,
  refetch,
  filtro,
  limit,
}) {
  const classes = useStyles();
  const [shadow, setShadow] = React.useState("rightShadowTable");
  const tableEl = React.useRef();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === 0) {
      setShadow("removeShadowTable");
      return;
    }
    setShadow("rightShadowTable");
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
    refetch({
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      filtros: filtro,
      offset: nextPage,
    });
  };

  return (
    <Box my={2}>
      <Paper variant="outlined">
        <TableContainer
          className={classes.container}
          ref={tableEl}
          onScroll={handleOnScroll}
        >
          <Table size="small" stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    style={{
                      minWidth: column.minWidth,
                      position: column.position,
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.docs.map((data, index) => {
                const result = formaPago.filter(
                  (res) => res.Value === data.forma_pago
                );
                let forma_pago = result[0];

                return (
                  <TableRow key={index} role="checkbox" tabIndex={-1}>
                    <TableCell
                      className={
                        data.compra && data.compra.status === "CANCELADO"
                          ? `${classes.cancelada} ${shadow}`
                          : `${classes.normal_color} ${shadow}`
                      }
                    >
                      {data.producto.datos_generales.nombre_comercial}
                    </TableCell>
                    <TableCell>
                      {moment(data.compra.fecha_registro)
                        .tz("America/Mexico_City")
                        .format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(data.compra.fecha_registro)
                        .tz("America/Mexico_City")
                        .format("DD/MM/YYYY, hh:mm a")}
                    </TableCell>
                    <TableCell>{data.almacen.nombre_almacen}</TableCell>
                    <TableCell>{data.proveedor.nombre_cliente}</TableCell>
                    <TableCell>
                      {data.compra_credito ? "Crédito" : "Contado"}
                    </TableCell>
                    <TableCell>
                      {`${forma_pago.Value}-${forma_pago.Name}`}
                    </TableCell>
                    <TableCell>
                      {`${data.cantidad_total} - ${data.unidad}`}
                    </TableCell>
                    <TableCell>
                      {moment(data.compra.fecha_vencimiento_credito)
                        .tz("America/Mexico_City")
                        .format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>
                      {formatoMexico(data.compra.saldo_credito_pendiente)}
                    </TableCell>
                    <TableCell>
                      {`%${data.descuento_porcentaje} - $${
                        data.descuento_precio
                          ? formatoMexico(data.descuento_precio)
                          : 0
                      }`}
                    </TableCell>
                    <TableCell>${formatoMexico(data.total)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mr={3}
        >
          <Typography variant="h6">
            Total: $ {formatoMexico(data.totalCompra)}
          </Typography>
          <Box mx={2} />
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={data.totalDocs}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
          />
        </Box>
      </Paper>
    </Box>
  );
}
