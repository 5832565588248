import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { formatoMexico } from "../../../../config/reuserFunctions";
import { VentasContext } from "../../../../context/Ventas/ventasContext";
import { lightGreen } from "@material-ui/core/colors";

export default function VentaTotales() {
  const { clientesVentas, DatosVentasActual, ventaRapida } = React.useContext(
    VentasContext
  );
  let datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
  const hidden = { display: ventaRapida ? "none" : "block" };
  const show = { display: ventaRapida ? "flex" : "none" };
  const nota = datosVentas && datosVentas.nota_credito;

  return (
    <Box>
      <Grid container>
        <Grid item md={5} xs={12} style={hidden}>
          <Box mx={2}>
            <Typography variant="subtitle1">
              <b>
                {`Dinero electrónico $${formatoMexico(
                  clientesVentas?.monedero_electronico
                )}`}
              </b>
            </Typography>
            <Typography variant="subtitle1">
              <b>
                {`Crédito disponible $${formatoMexico(
                  clientesVentas.credito_disponible
                )}`}
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item md={7} xs={12} style={hidden}>
          <Box display="flex" justifyContent="flex-end" gridGap={15} mx={2}>
            <Box display="flex" justifyContent="flex-end">
              <Typography variant="subtitle1" align="right">
                <b>
                  {`Descuento $${
                    DatosVentasActual?.descuento
                      ? formatoMexico(DatosVentasActual.descuento)
                      : 0
                  }`}
                </b>
              </Typography>
            </Box>
            <Typography variant="subtitle1" align="right">
              <b>
                {`Subtotal $${
                  DatosVentasActual?.subTotal
                    ? formatoMexico(DatosVentasActual.subTotal)
                    : 0
                }`}
              </b>
            </Typography>
            <Typography variant="subtitle1" align="right">
              <b>
                {`Impuestos $${
                  DatosVentasActual?.impuestos
                    ? formatoMexico(DatosVentasActual.impuestos)
                    : 0
                }`}
              </b>
            </Typography>
          </Box>
          <Box
            m={1}
            p={1}
            style={{ backgroundColor: lightGreen[100], borderRadius: 5 }}
            display="flex"
            justifyContent="flex-end"
            gridGap={16}
          >
            <Typography variant="h5" align="right">
              <b>
                {`Total $${
                  DatosVentasActual?.total
                    ? formatoMexico(DatosVentasActual.total)
                    : 0
                }`}
              </b>
            </Typography>
            {nota ? (
              <Typography variant="h5" align="right">
                <b>
                  {`Cambio $${formatoMexico(DatosVentasActual?.saldo_favor)}`}
                </b>
              </Typography>
            ) : null}
          </Box>
        </Grid>
        <Grid item xs={12} style={show}>
          <Box
            mx={1}
            p={1}
            width="100%"
            display="flex"
            justifyContent="flex-end"
            gridGap={16}
          >
            <Typography variant="h5" align="right">
              <b>{`Total $${formatoMexico(DatosVentasActual?.total)}`}</b>
            </Typography>
            {nota ? (
              <Typography variant="h5" align="right">
                <b>
                  {`Cambio $${formatoMexico(DatosVentasActual?.saldo_favor)}`}
                </b>
              </Typography>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
