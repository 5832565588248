import { withSnackbar } from "notistack";
import React, { createContext } from "react";
import { gql, useApolloClient } from "@apollo/client";
import { MainContext } from "./MainCtx";

export const CloudContext = createContext();

const initialQuery = {
  loading: false,
  error: null,
};

const SyncDbsQuery = gql`
  query syncDbs($empresa: ID!, $sucursal: ID!) {
    syncDbs(empresa: $empresa, sucursal: $sucursal) {
      message
    }
  }
`;

const CloudCtxProvider = ({ children }) => {
  const { tokenDecoded, snackMessage, isWeb } = React.useContext(MainContext);
  const [querySync, setQuerySync] = React.useState(initialQuery);
  const apolloClient = useApolloClient();

  const syncDataBases = async (refetch = null) => {
    try {
      if(isWeb) return
      setQuerySync({ ...querySync, loading: true });
      const response = await apolloClient.query({
        query: SyncDbsQuery,
        variables: {
          empresa: tokenDecoded.empresa._id,
          sucursal: tokenDecoded.sucursal._id,
        },
        fetchPolicy: "network-only",
      });
      if(refetch) refetch();
      setQuerySync({ ...querySync, loading: false });
      snackMessage({
        message: response.data.syncDbs.message,
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      setQuerySync({ ...querySync, loading: false, error });
    }
  };

  return (
    <CloudContext.Provider value={{ syncDataBases, querySync }}>
      {children}
    </CloudContext.Provider>
  );
};

export default withSnackbar(CloudCtxProvider);
