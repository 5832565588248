import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import ListCortes from "./ListCortes";
import ExportExcel from "./ExportExcel";

import { useQuery } from "@apollo/client";
import {
  OBTENER_CAJAS,
  OBTENER_CORTES_CAJA,
} from "../../../../gql/Cajas/cajas";
import { useDebounce } from "use-debounce";
import TicketPrinterComponent from "../../../../components/Perifericos/TicketPrinter";
import Close from "@material-ui/icons/Close";
import { ArrowBack } from "@material-ui/icons";
import ComponentOnline from "../../../../components/Connection/ComponentOnline";
import { FcFinePrint } from "react-icons/fc";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  icon: {
    width: 100,
  },
  paper: {
    alignItems: "flex-end",
  },
  fullScreen: {
    height: "100vh",
  },
}));

export default function ReportesCortes() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [datosFiltro, setDatosFiltro] = useState([]);
  const [value] = useDebounce(datosFiltro, 500);
  const limit = 20;
  let cajas = [];
  let historialCortes = [];

  const cajasBase = useQuery(OBTENER_CAJAS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
    },
  });

  const { loading, data, error, refetch } = useQuery(OBTENER_CORTES_CAJA, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: {
        fecha_consulta: datosFiltro.fecha_consulta
          ? datosFiltro.fecha_consulta
          : "",
        usuario: value.usuario ? value.usuario : "",
        numero_caja: datosFiltro.numero_caja
          ? parseInt(datosFiltro.numero_caja)
          : null,
      },
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  if (cajasBase.loading === false && loading === false) {
    cajas = cajasBase.data.obtenerCajasSucursal;
    historialCortes = data.obtenerCortesDeCaja;
  }

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const obtenerDatos = (e) => {
    setDatosFiltro({ ...datosFiltro, [e.target.name]: e.target.value });
  };

  const limpiarDatos = () => {
    setDatosFiltro([]);
  };

  return (
    <>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcFinePrint style={{ fontSize: 100 }} />
          </Box>
          Reportes cortes de caja
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
        classes={{
          scrollPaper: classes.paper,
          paperFullScreen: classes.fullScreen,
        }}
        hideBackdrop={true}
        PaperProps={{ elevation: 0 }}
      >
        <Typography variant="h6" className={classes.title}>
          Reportes cortes de caja
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <ComponentOnline classes />
          <Box mx={1} />
          <Button
            color="primary"
            variant="text"
            onClick={limpiarDatos}
            startIcon={<Close />}
          >
            Limpiar Filtro
          </Button>
          <Box mx={1} />
          <ExportExcel historialCortes={historialCortes} refetch={refetch} />
          <Box mx={1} />
          <TicketPrinterComponent turnoEnCurso={true} icon="config" />
          <Box mx={1} />
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <Typography>Fecha de corte:</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    name="fecha_consulta"
                    variant="outlined"
                    type="date"
                    onChange={obtenerDatos}
                    value={
                      datosFiltro.fecha_consulta
                        ? datosFiltro.fecha_consulta
                        : ""
                    }
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography>Usuario:</Typography>
                  <TextField
                    fullWidth
                    size="small"
                    name="usuario"
                    variant="outlined"
                    onChange={obtenerDatos}
                    value={datosFiltro.usuario ? datosFiltro.usuario : ""}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <Typography>Caja:</Typography>
                  <FormControl variant="outlined" fullWidth size="small">
                    <Select
                      id="form-producto-tipo"
                      name="numero_caja"
                      onChange={obtenerDatos}
                      value={
                        datosFiltro.numero_caja ? datosFiltro.numero_caja : ""
                      }
                    >
                      <MenuItem value="">
                        <em>Selecciona uno</em>
                      </MenuItem>
                      {cajas?.map((caja) => {
                        return (
                          <MenuItem
                            key={caja.numero_caja}
                            value={caja.numero_caja}
                          >
                            Caja {caja.numero_caja}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <ListCortes
                cortes={historialCortes}
                loading={loading}
                page={page}
                setPage={setPage}
                limit={limit}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
