import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

export default function TableHeadArticulos({ datosVentas }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        <TableCell>Artículo</TableCell>
        {datosVentas && datosVentas.nota_credito ? (
          <TableCell padding="checkbox">Cant. Vendida</TableCell>
        ) : null}
        <TableCell padding="checkbox" align="right" />
        <TableCell padding="checkbox" align="center">Cantidad</TableCell>
        <TableCell padding="checkbox" align="right">
          Desc
        </TableCell>
        <TableCell
          align="right"
          style={{
            width: 120,
            lineHeight: "unset",
          }}
        >
          Unitario
        </TableCell>
        <TableCell
          align="right"
          style={{
            width: 120,
            lineHeight: "unset",
          }}
        >
          Total
        </TableCell>
        <TableCell padding="checkbox" />
      </TableRow>
    </TableHead>
  );
}
