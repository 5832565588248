import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import BuscarProducto from "../../ventas/BuscarProductos/BuscarProducto";
import VentasRealizadas from "../../ventas/VentasRealizadas/VentasRealizadas";
import VentaEnEspera from "../../ventas/Operaciones/VentaEnEspera";
import Cotizacion from "../../ventas/Cotizacion/Cotizacion";
import AbrirCajon from "../../ventas/Operaciones/AbrirCajon";
import Abonos from "../../ventas/Abonos/Abonos";
import FacturaEnVenta from "../../ventas/Operaciones/FacturaEnVenta";
import ExistenciasSucursals from "../../ventas/ExistenciasSucursals";
import CerrarVenta from "../../ventas/CerrarVenta/CerrarVenta";
import CancelarVenta from "../../ventas/Operaciones/CancelarVenta";
import { makeStyles } from "@material-ui/core";
import NotaCredito from "../../ventas/NotaCredito/NotaCredito";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 64px)",
  },
}));

export default function PanelMenu() {
  const classes = useStyles();
  const datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));
  
  return (
    <Box p={2} py={1.5}>
      <Grid container spacing={1} className={classes.container}>
        <Grid item md={6} xs={12}>
        {datosVenta && datosVenta.nota_credito ? (
            <NotaCredito />
          ) : (
            <CerrarVenta />
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <CancelarVenta />
        </Grid>
        <Grid item md={6} xs={12}>
          <BuscarProducto />
        </Grid>
        <Grid item md={6} xs={12}>
          <VentasRealizadas />
        </Grid>
        <Grid item md={6} xs={12}>
          <VentaEnEspera />
        </Grid>
        <Grid item md={6} xs={12}>
          <AbrirCajon />
        </Grid>
        <Grid item md={6} xs={12}>
          <Cotizacion type="GENERAR" />
        </Grid>
        <Grid item md={6} xs={12}>
          <ExistenciasSucursals />
        </Grid>
        <Grid item md={6} xs={12}>
          <Abonos />
        </Grid>
        <Grid item md={6} xs={12}>
          <FacturaEnVenta />
        </Grid>
      </Grid>
    </Box>
  );
}
