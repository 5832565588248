import React from "react";
import Button from "@material-ui/core/Button";
import { Settings } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { AccesosContext } from "../../../context/Accesos/accesosCtx";

export default function GoAdminPanel() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const navigate = useNavigate();

  const {
    setAbrirPanelAcceso,
    abrirPanelAcceso,
    setDepartamentos,
  } = React.useContext(AccesosContext);

  function Administrador() {
    if (sesion.accesos.ventas.administrador.ver === true) {
      navigate("/admin");
    } else {
      setAbrirPanelAcceso(!abrirPanelAcceso);
      setDepartamentos({
        departamento: "ventas",
        subDepartamento: "administrador",
        tipo_acceso: "ver",
      });
    }
  }

  return (
    <Button
      color="inherit"
      onClick={Administrador}
      startIcon={<Settings />}
      style={{ textTransform: "none" }}
    >
      Admin
    </Button>
  );
}
