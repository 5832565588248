import { gql } from "@apollo/client";

export const CrearTraspaso = gql`
  mutation CrearTraspaso(
    $input: CrearTraspasoInput
    $usuario: ID!
    $empresa: ID!
  ) {
    crearTraspaso(input: $input, usuario: $usuario, empresa: $empresa) {
      message
      resp
    }
  }
`;

export const CreateTrasEntreSuc = gql`
  mutation crearTrasEntreSuc(
    $input: CrearTraspasoInput
    $usuario: ID!
    $empresa: ID!
  ) {
    crearTrasEntreSuc(input: $input, usuario: $usuario, empresa: $empresa) {
      message
    }
  }
`;

export const AceptarTrasEntreSuc = gql`
  mutation aceptarTrasEntreSuc(
    $input: AceptarTraspasoInput
    $usuario: ID!
    $empresa: ID!
  ) {
    aceptarTrasEntreSuc(input: $input, usuario: $usuario, empresa: $empresa) {
      message
    }
  }
`;
