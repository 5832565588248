import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { REGISTRAR_TURNOS } from "../../../gql/Ventas/abrir_cerrar_turno";
import moment from "moment-timezone";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { useMutation } from "@apollo/client";
import { formatoMexico } from "../../../config/reuserFunctions";
import { useNavigate } from "react-router-dom";
import { AccesosContext } from "../../../context/Accesos/accesosCtx";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { CustomTextField } from "../../../components/CustomMuiComponents";
import { CallReceived } from "@material-ui/icons";
import { MainContext } from "../../../context/MainCtx";

function CerrarTurno(props) {
  const { handleClickOpen, setLoading, loading } = props;
  const [CrearRegistroDeTurno] = useMutation(REGISTRAR_TURNOS);
  const { setAlert, ubicacionTurno } = React.useContext(VentasContext);
  const { isOnline, ventaToCloud, getData } = React.useContext(AccesosContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { turnoEnCurso, setTurnoEnCurso } = React.useContext(MainContext);
  const navigate = useNavigate();

  const [montoTurno, setMontoTurno] = React.useState([]);
  const [cerrarTurno, setCerrarTurno] = React.useState({
    horario_en_turno: "MATUTINO",
  });
  const [montoResguardo, setMontoResguardo] = React.useState(0);
  const [montoRetiro, setMontoRetiro] = React.useState(0);
  const [montosTotales, setMontosTotales] = React.useState(0);
  const obtenerCamposMontos = (e) => {
    const montos = { ...montoTurno, [e.target.name]: e.target.value };
    setMontoTurno(montos);

    let sum = 0;
    for (const property in montos) {
      sum += parseFloat(montos[property]);
    }
    setMontosTotales(sum);
  };

  const obtenerCampos = (e) => {
    setCerrarTurno({ ...cerrarTurno, [e.target.name]: e.target.value });
  };

  const handleTurno = (event, horario_en_turno) => {
    setCerrarTurno({ ...cerrarTurno, horario_en_turno });
  };

  const obtenerMontoResguardo = (e) => {
    const montoEfectivo = parseFloat(montoTurno.monto_efectivo);
    const montoResguardo = parseFloat(e.target.value);
    const montoTranferir = montoEfectivo - montoResguardo;

    if (Math.sign(montoTranferir) === -1) {
      return;
    }

    setMontoResguardo(montoResguardo);
    //setMontoRetiro(montoTranferir);
  };

  const obtenerMontoTransfer = (e) => {
    const montoEfectivo = parseFloat(montoTurno.monto_efectivo);
    const montoTranferir = parseFloat(e.target.value);
    const montoResguardo = montoEfectivo - montoTranferir;

    if (Math.sign(montoResguardo) === -1) {
      return;
    }

    //setMontoResguardo(montoResguardo);
    setMontoRetiro(montoTranferir);
  };

  const input = {
    horario_en_turno: cerrarTurno?.horario_en_turno,
    concepto: "CERRAR TURNO",
    token_turno_user: turnoEnCurso ? turnoEnCurso.token_turno_user : "",
    numero_caja: turnoEnCurso ? turnoEnCurso?.numero_caja.toString() : "",
    comentarios: cerrarTurno?.comentarios,
    id_caja: turnoEnCurso ? turnoEnCurso.id_caja : "",
    empresa: sesion.empresa._id,
    sucursal: sesion.sucursal._id,
    id_usuario: sesion._id,
    usuario_en_turno: {
      nombre: sesion.nombre,
      telefono: sesion.telefono,
      numero_usuario: sesion.numero_usuario,
      email: sesion.email,
    },
    hora_entrada: {
      hora: "",
      minutos: "",
      segundos: "",
      completa: "",
    },
    hora_salida: {
      hora: moment().tz("America/Mexico_City").format("hh"),
      minutos: moment().tz("America/Mexico_City").format("mm"),
      segundos: moment().tz("America/Mexico_City").format("ss"),
      completa: moment().tz("America/Mexico_City").format("HH:mm:ss"),
    },
    fecha_entrada: {
      year: "",
      mes: "",
      dia: "",
      no_semana_year: "",
      no_dia_year: "",
      completa: "",
    },
    fecha_salida: {
      year: moment().tz("America/Mexico_City").format("YYYY"),
      mes: moment().tz("America/Mexico_City").format("MM"),
      dia: moment().tz("America/Mexico_City").format("DD"),
      no_semana_year: moment().tz("America/Mexico_City").week().toString(),
      no_dia_year: moment().tz("America/Mexico_City").dayOfYear().toString(),
      completa: moment().tz("America/Mexico_City").format("YYYY-MM-DD"),
    },
    fecha_movimiento: moment().tz("America/Mexico_City").format(),
    montoRetiro,
    montos_en_caja: {
      monto_efectivo: {
        monto: parseFloat(montoResguardo),
        metodo_pago: "01",
      },
      monto_tarjeta_debito: {
        monto: parseFloat(
          montoTurno.monto_tarjeta_debito ? montoTurno.monto_tarjeta_debito : 0
        ),
        metodo_pago: "28",
      },
      monto_tarjeta_credito: {
        monto: parseFloat(
          montoTurno.monto_tarjeta_credito
            ? montoTurno.monto_tarjeta_credito
            : 0
        ),
        metodo_pago: "04",
      },
      monto_creditos: {
        monto: parseFloat(
          montoTurno.monto_creditos ? montoTurno.monto_creditos : 0
        ),
        metodo_pago: "99",
      },
      monto_monedero: {
        monto: parseFloat(
          montoTurno.monto_puntos ? montoTurno.monto_puntos : 0
        ),
        metodo_pago: "05",
      },
      monto_transferencia: {
        monto: parseFloat(
          montoTurno.monto_transferencia ? montoTurno.monto_transferencia : 0
        ),
        metodo_pago: "03",
      },
      monto_cheques: {
        monto: parseFloat(
          montoTurno.monto_cheques ? montoTurno.monto_cheques : 0
        ),
        metodo_pago: "02",
      },
      monto_vales_despensa: {
        monto: parseFloat(
          montoTurno.monto_vales_despensa ? montoTurno.monto_vales_despensa : 0
        ),
        metodo_pago: "08",
      },
    },
  };

  let arraySesion = { ...sesion, turno_en_caja_activo: false };

  const actualizarTurnoSesion = (ubicacionTurno) => {
    if (ubicacionTurno === "SESION") {
      localStorage.removeItem("sesionCafi");
      localStorage.removeItem("tokenCafi");
      
      localStorage.removeItem("ListaEnEspera");
      localStorage.removeItem("DatosVentas");
      localStorage.removeItem("VentaOriginal");
      setTurnoEnCurso(null)
      navigate("/");
    } else {
      localStorage.setItem("sesionCafi", JSON.stringify(arraySesion));
      localStorage.removeItem("DatosVentas");
      localStorage.removeItem("ListaEnEspera");
      
      localStorage.removeItem("VentaOriginal");
      setTurnoEnCurso(null)
    }
  };

  const enviarDatos = async () => {
    setLoading(true);
    try {
      if (!cerrarTurno?.horario_en_turno) {
        //setError(true);
        setLoading(false);
        setAlert({
          message: `Por favor elija que turno está cerrando`,
          status: "error",
          open: true,
        });
        return;
      } else {
        await CrearRegistroDeTurno({
          variables: {
            activa: false,
            input,
            isOnline: isOnline,
          },
        });
        setAlert({
          message: `Turno cerrado`,
          status: "success",
          open: true,
        });
        handleClickOpen();
        actualizarTurnoSesion(ubicacionTurno);
        if (ventaToCloud && isOnline) getData(sesion.empresa, sesion.sucursal);
        /* setLoading(false); */
        /*  setTurnoActivo(true); */
        window.location.reload();
      }
    } catch (error) {
      setAlert({
        message: `Error: ${error.message}`,
        status: "error",
        open: true,
      });
      setLoading(false);
      handleClickOpen();
    }
  };

  return (
    <Fragment>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Turno</Typography>
            <ToggleButtonGroup
              value={cerrarTurno?.horario_en_turno}
              exclusive
              onChange={handleTurno}
              size="small"
              style={{ width: "100%" }}
            >
              <ToggleButton
                style={{ textTransform: "none", width: "100%" }}
                value="MATUTINO"
              >
                Matutino
              </ToggleButton>
              <ToggleButton
                style={{ textTransform: "none", width: "100%" }}
                value="VESPERTINO"
              >
                Vespertino
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">Montos Recibidos</Typography>
            <CustomTextField
              fullWidth
              name="monto_efectivo"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              /* defaultValue={0} */
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">Efectivo $</InputAdornment>
                ),
              }}
              color="primary"
              onChange={(e) => {
                obtenerCamposMontos(e);
                setMontoResguardo(parseFloat(e.target.value));
              }}
            />
            <CustomTextField
              fullWidth
              name="monto_creditos"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              /* defaultValue={0} */
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">Creditos $</InputAdornment>
                ),
              }}
              color="primary"
              onChange={obtenerCamposMontos}
            />
            <CustomTextField
              fullWidth
              name="monto_tarjeta_debito"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">Tarjetas $</InputAdornment>
                ),
              }}
              color="primary"
              onChange={obtenerCamposMontos}
            />
            <CustomTextField
              fullWidth
              name="monto_transferencia"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    Transferencias $
                  </InputAdornment>
                ),
              }}
              color="primary"
              onChange={obtenerCamposMontos}
            />
            <CustomTextField
              fullWidth
              name="monto_puntos"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">Monedero $</InputAdornment>
                ),
              }}
              color="primary"
              onChange={obtenerCamposMontos}
            />
            <CustomTextField
              fullWidth
              name="monto_cheques"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              /* defaultValue={0} */
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">Cheques $</InputAdornment>
                ),
              }}
              color="primary"
              onChange={obtenerCamposMontos}
            />
            <CustomTextField
              fullWidth
              name="monto_vales_despensa"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              /* defaultValue={0} */
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    Vales despensa $
                  </InputAdornment>
                ),
              }}
              color="primary"
              onChange={obtenerCamposMontos}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} style={{ display: "none" }}>
            <Typography variant="subtitle2">Mantener en caja:</Typography>
            <TextField
              fullWidth
              name="monto_reguardo"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              value={montoResguardo}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">$</InputAdornment>
                ),
              }}
              color="primary"
              onChange={obtenerMontoResguardo}
            />
          </Grid>
          <Grid item xs={6} style={{ display: "none" }}>
            <Typography variant="subtitle2">Retirar de caja:</Typography>
            <TextField
              fullWidth
              name="monto_transferencia"
              variant="outlined"
              size="small"
              inputProps={{ min: 0 }}
              type="number"
              style={{ marginBottom: 8 }}
              value={montoRetiro}
              placeholder="0.00"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">$</InputAdornment>
                ),
              }}
              color="primary"
              onChange={obtenerMontoTransfer}
            />
          </Grid>
        </Grid>
        <Box mt={1}>
          <Typography variant="subtitle2">Comentarios:</Typography>
          <TextField
            fullWidth
            multiline
            onChange={obtenerCampos}
            rows={2}
            size="small"
            name="comentarios"
            id="form-producto-codigo-barras"
            variant="outlined"
          />
        </Box>
        <Box mt={1} display="flex" justifyContent="flex-end">
          <div>
            <Box display="flex">
              <Typography variant="subtitle2">
                <b>Total en efectivo: </b>
              </Typography>
              <Box mx={1} />
              <Typography>
                ${formatoMexico(montoTurno.monto_efectivo)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle2">
                <b>Monto total: </b>
              </Typography>
              <Box mx={1} />
              <Typography>${formatoMexico(montosTotales)}</Typography>
            </Box>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={enviarDatos}
          variant="contained"
          disableElevation
          color="primary"
          size="large"
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <CallReceived />
            )
          }
        >
          Cerrar Turno
        </Button>
      </DialogActions>
    </Fragment>
  );
}

export default CerrarTurno;
