import React, { useContext, useState } from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import CerrarTurno from "./CerrarTurno";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { FaUserClock } from "react-icons/fa";
import { MainContext } from "../../../context/MainCtx";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "40vw",
    height: "calc(100vh - 40px)",
    top: 20,
    //bottom: "auto",
    right: 20,
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
  },
}));

export default function Turnos() {
  const { abrirTurnosDialog, setAbrirTurnosDialog } = useContext(VentasContext);
  const { turnoEnCurso } = React.useContext(MainContext);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setAbrirTurnosDialog(!abrirTurnosDialog);
    /* setTurnoActivo(true); */
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 85) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [abrirTurnosDialog]);

  return (
    <>
      <ListItem
        button
        onClick={() => handleClickOpen()}
        disabled={!turnoEnCurso}
      >
        <ListItemIcon>
          <FaUserClock style={{ fontSize: 24 }} />
        </ListItemIcon>
        <ListItemText primary="Cerrar Turno" />
      </ListItem>
      <Drawer
        anchor="right"
        open={abrirTurnosDialog}
        onClose={handleClickOpen}
        classes={{ paper: classes.drawerPaper }}
      >
        <DialogTitle component="div">
          <Box display="flex" alignItems="center" gridGap={10}>
            <Typography variant="h6">Cerrar Turno</Typography>
            <Typography variant="h6">
              {`• Caja ${turnoEnCurso?.numero_caja}`}
            </Typography>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              disableElevation
              onClick={handleClickOpen}
              size="small"
              disabled={loading}
              startIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <CerrarTurno
          setLoading={setLoading}
          loading={loading}
          handleClickOpen={handleClickOpen}
        />
        {/* <ContenidoTurnos handleClickOpen={handleClickOpen} value={value} /> */}
      </Drawer>
    </>
  );
}

/* 
const ContenidoTurnos = ({ handleClickOpen, value }) => {
  const { turnoEnCurso } = React.useContext(MainContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const [loading, setLoading] = useState(false);

  if (loading) return <BackdropComponent loading={loading} />;

  return (
    <DialogContent style={{ padding: 0 }}>
      {sesion?.turno_en_caja_activo === true && turnoEnCurso ? (
        <TabPanel style={{ padding: 0 }} value={value} index={0}>
          <CerrarTurno
            setLoading={setLoading}
            handleClickOpen={handleClickOpen}
          />
        </TabPanel>
      ) : (
        <TabPanel value={value} index={0}>
          <AbrirTurno
            loading={loading}
            setLoading={setLoading}
            handleClickOpen={handleClickOpen}
          />
        </TabPanel>
      )}
    </DialogContent>
  );
}; */
