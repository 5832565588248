import React from "react";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import PresetsCards, { OnlyBanner } from "../Presets/PresetsCards";

export default function TipoBanner({ tipo, setTipo }) {
  const handleChange = (value) => setTipo(value);
  return (
    <div>
      <FormControl variant="outlined" size="small" fullWidth margin="dense">
        <InputLabel id="tipp-banner-select">Tipo de banner</InputLabel>
        <Select
          labelId="tipp-banner-select"
          value={tipo}
          onChange={(e) => handleChange(e.target.value)}
          label="Tipo de banner"
        >
          <MenuItem value="">
            <em>Selecciona una opción</em>
          </MenuItem>
          <MenuItem value="ONLY_BANNER">Solo imagen grande</MenuItem>
          <MenuItem value="ONLY_CARDS">Solo cards de artículos</MenuItem>
          <MenuItem value="BANNER_WITH_CARDS">
            Imagen grandes con cards de artículos
          </MenuItem>
          <MenuItem value="CARDS_IMAGE_LEFT">
            Cards de articulos con imagen a la izquierda
          </MenuItem>
          <MenuItem value="CARDS_IMAGE_RIGHT">
            Cards de articulos con imagen a la derecha
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
