import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Dialog,
  Box,
  Button,
  DialogTitle,
  DialogActions,
  Slide,
  IconButton,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import { CANCELAR_ABONO_PROVEEDOR } from "../../../../../../../gql/Tesoreria/abonos";
import { useQuery } from "@apollo/client";
import { OBTENER_HISTORIAL_ABONOS } from "../../../../../../../gql/Tesoreria/abonos";
import moment from "moment-timezone";
import { TesoreriaCtx } from "../../../../../../../context/Tesoreria/tesoreriaCtx";
import { formatoFecha } from "../../../../../../../config/reuserFunctions";
import { imprimirTicketAbonos } from "../../../ImpTicketAbono";
import { DescriptionOutlined } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../../../../../context/MainCtx";
import ExcelExportButton from "../../../../../../../components/ExcelExportButton";
const columns = [
  { id: "fecha", label: "Fecha de abono", minWidth: 170, align: "center" },
  { id: "cliente", label: "Nombre", minWidth: 170, align: "center" },
  { id: "abono", label: "Monto", minWidth: 100, align: "right" },
  { id: "ticket", label: "Ticket", minWidth: 100, align: "center" },
  { id: "cancelar", label: "Cancelar", minWidth: 100, align: "center" },
];

const columnsEffect = [
  { id: "fecha_movimiento", label: "Fecha", minWidth: 60, widthPx: 160 },
  {
    id: "monto_total_abonado",
    label: "Cantidad abono",
    minWidth: 170,
    widthPx: 160,
  },
  { id: "estado", label: "Estado", minWidth: 170, widthPx: 160 },
];
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "45vh",
  },
  container: {
    maxHeight: "100%",
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TablaAbonos(props) {
  const { turnoEnCurso } = React.useContext(MainContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { reload, setReload, setAlert } = useContext(TesoreriaCtx);

  const classes = useStyles();

  const [abonoSelected, setAbonoSelected] = useState({});
  const [openCancelar, setOpenCancelar] = useState(false);
  const [loadingCancelar, setLoadingCancelar] = useState(false);

  const [cancelarAbonoProveedor] = useMutation(CANCELAR_ABONO_PROVEEDOR);
  const navigate = useNavigate();

  let dataExcel = [];

  const { data, refetch } = useQuery(OBTENER_HISTORIAL_ABONOS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: {
        fecha_inicio: "",
        fecha_fin: "",
        usuario: "",
        //id_cliente: "",
        id_egreso: "",
        rol_movimiento: "ABONO_PROVEEDOR",
        //id_compra: "",
        folio: "",
        forma_pago: "",
        caja: 0,
        canceladas: false,
        realizadas: false,
        id_cliente: props.cuenta.proveedor.id_proveedor._id.toString(),
        id_compra: props.cuenta._id.toString(),
      },
    },
    fetchPolicy: "network-only",
  });

  let abonos = [];
  if (data) {
    abonos = data.obtenerHistorialAbonos.docs;
    abonos.forEach((element) => {
      dataExcel.push({
        fecha_movimiento: formatoFecha(element.fecha_movimiento.completa),
        monto_total_abonado: element.monto_total_abonado,
        estado: element.status,
      });
    });
  }

  useEffect(() => {
    refetch();
  }, [reload]);

  const handleOpen = () => {
    setOpenCancelar(!setOpenCancelar);
  };
  const affirmCancelar = (abono) => {
    try {
      if (turnoEnCurso || sesion.accesos.tesoreria.caja_principal.ver) {
        setOpenCancelar(true);

        setAbonoSelected(abono);
      } else {
        navigate("/ventas");
      }
    } catch (error) {}
  };

  const cancelAbono = async () => {
    try {
      setLoadingCancelar(true);

      const input = {
        tipo_movimiento: "CANCELACION_ABONO_PROVEEDOR",
        rol_movimiento: turnoEnCurso ? "CAJA" : "CAJA_PRINCIPAL",
        numero_caja: turnoEnCurso ? parseInt(turnoEnCurso.numero_caja) : 0,
        id_Caja: turnoEnCurso ? turnoEnCurso.id_caja : "",
        monto_abono: abonoSelected.monto_total_abonado,
        horario_turno: turnoEnCurso ? turnoEnCurso.horario_en_turno : "",
        concepto: "CANCELACION_ABONO_PROVEEDOR",
        id_usuario: sesion._id,
        numero_usuario_creador: sesion.numero_usuario,
        nombre_usuario_creador: sesion.nombre,
        id_proveedor: props.proveedor.id_proveedor._id,
        clave_proveedor: props.proveedor.id_proveedor.clave_cliente,
        nombre_proveedor: props.proveedor.id_proveedor.nombre_cliente,
        telefono_proveedor: props.proveedor.id_proveedor.telefono_cliente,
        email_proveedor: props.proveedor.id_proveedor.email_cliente,
        id_abono: abonoSelected._id,
        id_compra: props.cuenta._id,
        metodo_de_pago: abonoSelected.metodo_de_pago,
        caja_principal: turnoEnCurso
          ? false
          : sesion.accesos.tesoreria.caja_principal.ver,
      };

      await cancelarAbonoProveedor({
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          input: input,
        },
      });
      //if(ventaToCloud) getData(sesion.empresa, sesion.sucursal);
      setReload(true);
      setOpenCancelar(false);
      setLoadingCancelar(false);
      handleOpen();
      setAlert({
        message: "Abono cancelado con éxito.",
        status: "success",
        open: true,
      });
    } catch (error) {
      setLoadingCancelar(false);
      handleOpen();
      setAlert({
        message: "La cancelación ha fallado.",
        status: "success",
        open: true,
      });
    }
  };

  return (
    <div>
      <Paper className={classes.root}>
        <Dialog
          open={openCancelar}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => handleOpen()}
          aria-labelledby="alert-eliminar-abono"
        >
          <DialogTitle id="alert-eliminar-abono">
            {"¿Está seguro de eliminar este abono?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => handleOpen()}
              color="inherit"
              disabled={loadingCancelar}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => cancelAbono()}
              color="secondary"
              disabled={loadingCancelar}
              startIcon={
                loadingCancelar ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <TableContainer className={classes.container}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {abonos?.map((row, index) => {
                return (
                  <ListaAbonosRealizados
                    key={index}
                    row={row}
                    affirmCancelar={affirmCancelar}
                    cuenta={props.cuenta}
                    setAlert={setAlert}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {abonos?.length > 0 ? (
        <Box
          m={1}
          mb={1}
          mrstyle={{ backgroundColor: "red", alignContent: "flex-end" }}
          justifyContent="flex-end"
        >
          <ExcelExportButton data={dataExcel} name="Historial Abonos" button />
        </Box>
      ) : (
        <div />
      )}
    </div>
  );
}

const ListaAbonosRealizados = ({ row, affirmCancelar, cuenta, setAlert }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = useState(false);

  const rePrint = async () => {
    setLoading(true);
    try {
      const dataImp = {
        turno: {
          usuario: row.nombre_usuario_creador,
          numero_usuario: row.numero_usuario_creador,
          caja: row.numero_caja,
          turno: row.horario_turno,
        },
        folio: cuenta.folio,
        numero_cliente: cuenta.proveedor.numero_cliente,
        clave_cliente: cuenta.proveedor.clave_cliente,
        nombre_cliente: cuenta.proveedor.nombre_cliente,
        saldo_inicial: cuenta.total,
        total_anterior: row.saldo_credito_pendiente + row.monto_total_abonado,
        su_pago: row.monto_total_abonado,
        efectivo_recibido: row.montos_en_caja.monto_efectivo * -1,
        su_cambio: 0,
        total_restante: row.saldo_credito_pendiente,
        metodo_pago: row.metodo_de_pago ? row.metodo_de_pago.metodo : "",
        descuento: row.descuento ? row.descuento.dinero_descontado : 0,
        fecha_movimiento: row.fecha_movimiento.completa,
      };
      const result = await imprimirTicketAbonos(
        sesion,
        dataImp,
        "PROVEEDOR",
        false,
        true
      ); //sesion, datosAbonos, tipoAbono, liquidar, reimpresion
      if (result.error) {
        setAlert({
          message: "Error de conexión con la impresora",
          status: "error",
          open: true,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlert({ message: "Hubo un error", status: "error", open: true });
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell align="center">
        {moment(row.fecha_movimiento.completa)
          .tz("America/Mexico_City")
          .format("D MMMM YYYY")}
      </TableCell>
      <TableCell align="center">{row.nombre_cliente}</TableCell>
      <TableCell align="right">
        <b>${row.monto_total_abonado}</b>
      </TableCell>
      <TableCell padding="checkbox" align="center">
        <IconButton size="small" onClick={() => rePrint()}>
          {loading ? <CircularProgress size={20} /> : <DescriptionOutlined />}
        </IconButton>
      </TableCell>
      {row.status === "CANCELADO" ? (
        <TableCell>{row.status}</TableCell>
      ) : (
        <TableCell padding="checkbox">
          <Button
            variant="text"
            color="primary"
            onClick={() => affirmCancelar(row)}
            size="large"
          >
            <CloseIcon style={{ fontSize: 22 }} />
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export default TablaAbonos;
