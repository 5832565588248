import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { RegProductoContext } from "../../../../../context/Catalogos/CtxRegProducto";
import { useDebounce } from "use-debounce";
import { unitCodes, unitCodes_granel } from "../unidades";

const useStyles = makeStyles((theme) => ({
  required: {
    color: "red",
  },
  titulos: {
    fontWeight: 500,
  },
}));

export default function UnidadCostoCompra() {
  const classes = useStyles();
  const {
    precios,
    setPrecios,
    validacion,
    update,
    unidadVentaXDefecto,
    setUnidadVentaXDefecto,
    unidadVentaSecundaria,
    setUnidadVentaSecundaria,
  } = useContext(RegProductoContext);

  const [precioConImpuesto, setPrecioConImpuesto] = useState(
    precios.precio_de_compra.precio_con_impuesto
  );

  const [precioConImpuestoVariable, setPrecioConImpuestoVariable] = useState(
    precios.precio_de_compra.precio_con_impuesto
  );

  const [PCI] = useDebounce(precioConImpuestoVariable, 800);

  /* ARMAR OBJETO DE PRECIOS DE COMPRA */
  const obtenerPrecioConImpuesto = (value) => {
    let precio_con_impuesto = 0;
    let precio_unitario_con_impuesto = 0;

    //Precio con impuesto
    precio_con_impuesto = parseFloat(value);
    precio_unitario_con_impuesto =
      precio_con_impuesto / precios.unidad_de_compra.cantidad;

    if (isNaN(precio_unitario_con_impuesto)) precio_unitario_con_impuesto = 0;

    setPrecios({
      ...precios,
      precio_de_compra: {
        ...precios.precio_de_compra,
        precio_con_impuesto: parseFloat(precio_con_impuesto.toFixed(4)),
      },
      unidad_de_compra: {
        ...precios.unidad_de_compra,
        precio_unitario_con_impuesto: parseFloat(
          precio_unitario_con_impuesto.toFixed(4)
        ),
      },
    });
  };

  /* ARMAR OBJETO DE UNIDAD DE COMPRA */
  const obtenerUnidadCompra = (e, child) => {
    if (e.target.name === "unidad") {
      const { codigo_unidad, unidad } = child.props.unidad;
      let precio =
        precios.unidad_de_compra.cantidad * unidadVentaXDefecto.precio;
      if (unidad === "Caja" || unidad === "Costal") {
        setUnidadVentaSecundaria({
          ...unidadVentaSecundaria,
          precio: parseFloat(precio.toFixed(4)),
          codigo_unidad,
          unidad,
          unidad_activa: true,
          unidad_principal: true,
        });
        setUnidadVentaXDefecto({
          ...unidadVentaXDefecto,
          unidad: unidad === "Caja" ? "Pz" : "Kg",
          codigo_unidad: unidad === "Caja" ? "H87" : "KGM",
          cantidad: 1,
          precio: unidadVentaXDefecto.precio,
          unidad_principal: false,
        });
        /* setPrecios({
          ...precios,
          unidad_de_compra: {
            ...precios.unidad_de_compra,
            unidad,
            codigo_unidad,
          },
          inventario: {
            ...precios.inventario,
            unidad_de_inventario: unidad,
            codigo_unidad,
          },
        }); */
      } else {
        setUnidadVentaXDefecto({
          ...unidadVentaXDefecto,
          unidad: unidad === "Pz" ? "Pz" : unidad === "Lt" ? "Lt " : "Kg",
          codigo_unidad:
            unidad === "Pz" ? "H87" : unidad === "Lt" ? "LTR" : "KGM",
          cantidad: 1,
          precio: unidadVentaXDefecto.precio,
          unidad_principal: true,
        });
        setUnidadVentaSecundaria({
          ...unidadVentaSecundaria,
          precio: parseFloat(precio.toFixed(4)),
          codigo_unidad:
            unidad === "Pz" ? "XBX" : unidad === "Lt" ? "LTR" : "KGM",
          unidad:
            unidad === "Pz" ? "Caja" : unidad === "Caja" ? "LTR" : "Costal",
          unidad_activa: false,
          unidad_principal: false,
        });
      }
      setPrecios({
        ...precios,
        unidad_de_compra: {
          ...precios.unidad_de_compra,
          unidad,
          codigo_unidad,
          cantidad: 1,
        },
        inventario: {
          ...precios.inventario,
          unidad_de_inventario: unidad,
          codigo_unidad,
        },
      });
    } else {
      setPrecios({
        ...precios,
        unidad_de_compra: {
          ...precios.unidad_de_compra,
          [e.target.name]: parseFloat(e.target.value),
          precio_unitario_con_impuesto: parseFloat(
            (
              precios.precio_de_compra.precio_con_impuesto / e.target.value
            ).toFixed(4)
          ),
        },
      });
      if (
        precios.unidad_de_compra.unidad === "Caja" ||
        precios.unidad_de_compra.unidad === "Costal"
      ) {
        setUnidadVentaSecundaria({
          ...unidadVentaSecundaria,
          cantidad: parseFloat(e.target.value),
        });
      }
      setUnidadVentaXDefecto({
        ...unidadVentaXDefecto,
        cantidad: 1,
      });
    }
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count === 0) return;
    obtenerPrecioConImpuesto(PCI);
  }, [PCI]);

  return (
    <Box>
      <div /* className={classes.input} */>
        <Box mb={1}>
          <Typography>
            <b>Precios y unidad de compra</b>
          </Typography>
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid item sm={4} md={2} xs={12}>
            <Box>
              <Typography noWrap className={classes.titulos}>
                Unidad Compra
              </Typography>
              <Box display="flex">
                <FormControl
                  variant="outlined"
                  fullWidth
                  size="small"
                  error={validacion.error && !precios.unidad_de_compra.unidad}
                >
                  {precios.granel ? (
                    <Select
                      disabled={update}
                      id="form-producto-categoria"
                      name="unidad"
                      value={precios.unidad_de_compra.unidad}
                      onChange={obtenerUnidadCompra}
                    >
                      {unitCodes_granel.map((res, index) => (
                        <MenuItem key={index} unidad={res} value={res.unidad}>
                          {res.unidad}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      disabled={update}
                      id="form-producto-categoria"
                      name="unidad"
                      value={precios.unidad_de_compra.unidad}
                      onChange={obtenerUnidadCompra}
                    >
                      {unitCodes.map((res, index) => (
                        <MenuItem key={index} unidad={res} value={res.unidad}>
                          {res.unidad}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  <FormHelperText>{validacion.message}</FormHelperText>
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={4} md={2} xs={12}>
            <Box>
              <Typography noWrap align="center" className={classes.titulos}>
                Unidad Conversion
              </Typography>
              <Typography align="center" variant="h6">
                <b>
                  {precios.unidad_de_compra.unidad === "Caja"
                    ? "Pz"
                    : precios.unidad_de_compra.unidad === "Costal"
                    ? "Kg"
                    : precios.unidad_de_compra.unidad}
                </b>
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={4} md={2} xs={12}>
            <Box>
              <Typography noWrap className={classes.titulos}>
                <span className={classes.required}>* </span>Factor por Unidad
              </Typography>
              <TextField
                fullWidth
                disabled={
                  precios.unidad_de_compra.unidad === "Caja" ||
                  precios.unidad_de_compra.unidad === "Costal"
                    ? false
                    : true
                }
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                size="small"
                error={validacion.error && !precios.unidad_de_compra.cantidad}
                name="cantidad"
                id="form-producto-cantidad"
                variant="outlined"
                value={precios.unidad_de_compra.cantidad}
                helperText={validacion.message}
                onChange={obtenerUnidadCompra}
              />
            </Box>
          </Grid>
          <Grid item sm={6} md={3} xs={12}>
            <Box>
              <Typography noWrap className={classes.titulos}>
                <span className={classes.required}>* </span>Precio de Compra
              </Typography>
              <TextField
                fullWidth
                //disabled={!precios.iva_activo && !precios.ieps_activo}
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                size="small"
                error={validacion.error && !precioConImpuesto}
                name="precio_con_impuesto"
                id="form-producto-precio_con_impuesto"
                variant="outlined"
                value={precioConImpuesto}
                helperText={validacion.message}
                onChange={(e) => {
                  setCount(1);
                  setPrecioConImpuesto(e.target.value);
                  setPrecioConImpuestoVariable(e.target.value);
                }}
                /* onChange={(e) => obtenerPrecioConImpuesto(e.target.value)} */
                onFocus={(e) => {
                  const { value } = e.target;
                  if (parseFloat(value) === 0) {
                    setPrecioConImpuesto("");
                    setPrecioConImpuestoVariable("");
                  }
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  if (!value) {
                    setPrecioConImpuesto(0);
                    setPrecioConImpuestoVariable(0);
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
