import React, { useState, useEffect } from "react";
import { Button, Box } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { RiFileExcel2Line } from "react-icons/ri";
import ModalProductosAgregar from "./ModalProductosAgregar";
import SnackBarMessages from "../../../../../components/SnackBarMessages";
import ExcelExportButton from "../../../../../components/ExcelExportButton";
const ExcelJS = require("exceljs");

function ImportXlsModal(props) {
  const { openMenu, setOpenMenu } = props;
  const [modalProductos, setModalProductos] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const [productos, setProductos] = useState([]);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const handleClickClose = () => {
    setOpenMenu(null);
  };
  const onCloseModal = () => {
    setModalProductos(false);
    setProductos([]);
  };

  useEffect(() => {
    if (productos.length === 0 && modalProductos) {
      setModalProductos(false);
      setAlert({
        message: "No fué posible agregar los datos del documento seleccionado.",
        status: "error",
        open: true,
      });
    }
    setLoading(false);
  }, [productos]);

  const getFile = (file, reader) => {
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };
  const importarXlsPlantillaProductos = async (e) => {
    try {
      setOpenMenu(false);
      setLoading(true);
      const file = e.target.files[0];
      const wb = new ExcelJS.Workbook();
      const reader = new FileReader();
      const productos_pushed = [];

      reader.readAsArrayBuffer(file);

      let buffer = await getFile(file, reader);

      wb.xlsx.load(buffer).then((workbook) => {
        workbook.eachSheet((sheet, id) => {
          sheet.eachRow((row, rowIndex) => {
            if (rowIndex > 1) {
              let object_producto = getObjectToSend(row.values);

              if (object_producto.selected) {
                productos_pushed.push(object_producto);
              }
            }
          });
        });
        setModalProductos(true);
        setProductos(productos_pushed);
      });
      reader.abort();
    } catch (error) {
      console.log(error);
    }
  };

  const get_codigo_unidad = (val) => {
    try {
      let isMinima = false;
      let minima = "";
      let maxima = "";
      let codigo_minima = "";
      let codigo_maxima = "";
      switch (val) {
        case "Pz":
          codigo_minima = "H87";
          codigo_maxima = "XBX";
          isMinima = true;
          minima = "Pz";
          maxima = "Caja";
          break;
        case "Caja":
          codigo_maxima = "XBX";
          codigo_minima = "H87";
          minima = "Pz";
          maxima = "Caja";
          break;
        case "Kg":
          codigo_minima = "KGM";
          codigo_maxima = "KGM";
          minima = "Kg";
          maxima = "Costal";
          isMinima = true;
          break;
        case "Costal":
          codigo_minima = "KGM";
          codigo_maxima = "KGM";
          minima = "Kg";
          maxima = "Costal";
          break;
        case "Lt":
          codigo_minima = "LTR";
          codigo_maxima = "LTR";
          minima = "Lt";
          maxima = "Lt";
          isMinima = true;
          break;
        default:
          codigo_minima = "";
          codigo_maxima = "";
          minima = "";
          maxima = "";
          isMinima = true;
          break;
      }

      return { codigo_minima, codigo_maxima, isMinima, minima, maxima, val };
    } catch (error) {
      console.log(error);
    }
  };
  const getObjectToSend = (row) => {
    try {
      let selected = true;

      if (
        !row[2] ||
        !row[3] ||
        !row[4] ||
        !row[6] ||
        !row[7] ||
        !row[8] ||
        !row[9]
      ) {
        return {};
      }
      let unidad_de_compra = row[7].trim();
      let precio_con_impuesto = row[9] ? parseFloat(row[9]) : 0;
      let factor_por_unidad = row[8];
      let codigo_unidad = get_codigo_unidad(unidad_de_compra);
      let valor_base = row[12] ? parseFloat(row[12]) : 0;
      let utilidad = valor_base > 0 ? valor_base / 100 : 0;

      const PCU = precio_con_impuesto;
      const CANT = factor_por_unidad;
      const IVA = row[10] ? parseFloat(row[10]) : 0;
      const IEPS = row[11] ? parseFloat(row[11]) : 0;

      const PRECIO_VENTA = parseFloat(PCU * utilidad + PCU);
      let ieps_precio = PRECIO_VENTA * parseFloat(IEPS / 100);
      let iva_precio = (PRECIO_VENTA + ieps_precio) * parseFloat(IVA / 100);
      const PRECIO_NETO = PRECIO_VENTA + iva_precio + ieps_precio;

      let unidades_de_venta = [
        {
          cantidad: 1,
          default: true,
          precio: !codigo_unidad.isMinima
            ? parseFloat((PRECIO_NETO / CANT).toFixed(4))
            : parseFloat(PRECIO_NETO.toFixed(4)),
          precio_unidad: {
            numero_precio: 1,
            precio_neto: !codigo_unidad.isMinima
              ? parseFloat((PRECIO_NETO / CANT).toFixed(4))
              : parseFloat(PRECIO_NETO.toFixed(4)),
            precio_venta: !codigo_unidad.isMinima
              ? parseFloat((PRECIO_VENTA / CANT).toFixed(4))
              : parseFloat(PRECIO_VENTA.toFixed(4)),
            iva_precio: !codigo_unidad.isMinima
              ? parseFloat((iva_precio / CANT).toFixed(4))
              : parseFloat(iva_precio.toFixed(4)),
            ieps_precio: !codigo_unidad.isMinima
              ? parseFloat((ieps_precio / CANT).toFixed(4))
              : parseFloat(ieps_precio.toFixed(4)),
            unidad_mayoreo: 0,
            utilidad: valor_base,
            unidad_maxima: false,
          },
          unidad: codigo_unidad.minima,
          codigo_unidad: codigo_unidad.codigo_minima,
          unidad_principal: codigo_unidad.isMinima,
          codigo_barras: row[1].toString(),
        },
      ];

      unidades_de_venta.push({
        cantidad: CANT,
        default: false,
        precio: parseFloat(PRECIO_NETO.toFixed(4)),
        precio_unidad: {
          numero_precio: 1,
          precio_neto: parseFloat(PRECIO_NETO.toFixed(4)),
          precio_venta: parseFloat(PRECIO_VENTA.toFixed(4)),
          iva_precio: parseFloat(iva_precio.toFixed(4)),
          ieps_precio: parseFloat(ieps_precio.toFixed(4)),
          unidad_mayoreo: 0,
          utilidad: valor_base,
          unidad_maxima: false,
        },
        unidad: codigo_unidad.maxima,
        codigo_unidad: codigo_unidad.codigo_maxima,
        unidad_principal: !codigo_unidad.isMinima,
      });

      let objectReturn = {
        almacen_inicial: {
          id_almacen: "",
          almacen: "PRINCIPAL",
          fecha_de_expiracion: "",
          cantidad: 0,
        },
        centro_de_costos: {},
        empresa: sesion.empresa._id,
        imagenes: [],
        imagenes_eliminadas: [],
        precio_plazos: {
          precio_cajas: [],
          precio_costales: [],
          precio_piezas: [],
        },
        datos_generales: {
          codigo_barras: row[1].toString(),
          clave_alterna: row[2].toString(),
          nombre_comercial: row[3].toString(),
          nombre_generico: row[4].toString(),
          descripcion: row[5].toString(),
          tipo_producto: row[6].toString().trim(),
          clave_producto_sat: {
            Name: "",
            Value: "",
          },
        },
        precios: {
          ieps: row[11],
          ieps_activo: row[11] ? true : false,
          inventario: {
            inventario_maximo: 0,
            inventario_minimo: 0,
            unidad_de_inventario: row[7].toString().trim(),
            codigo_unidad: codigo_unidad.isMinima
              ? codigo_unidad.codigo_minima
              : codigo_unidad.codigo_maxima,
          },
          iva: row[10],
          iva_activo: row[10] ? true : false,
          monedero: false,
          monedero_electronico: 0,
          precio_de_compra: {
            ieps: 0,
            iva: 0,
            precio_con_impuesto: parseFloat(row[9]),
            precio_sin_impuesto: 0,
          },
          precios_producto: [
            {
              numero_precio: 1,
              precio_neto: parseFloat(PRECIO_NETO.toFixed(4)),
              precio_venta: parseFloat(PRECIO_VENTA.toFixed(4)),
              iva_precio: parseFloat(iva_precio.toFixed(4)),
              ieps_precio: parseFloat(ieps_precio.toFixed(4)),
              unidad_mayoreo: 0,
              utilidad: parseFloat(valor_base.toFixed(4)),
            },
            {
              numero_precio: 2,
              precio_neto: 0,
              precio_venta: 0,
              iva_precio: 0,
              ieps_precio: 0,
              unidad_mayoreo: 0,
              utilidad: 0,
            },
            {
              numero_precio: 3,
              precio_neto: 0,
              precio_venta: 0,
              iva_precio: 0,
              ieps_precio: 0,
              unidad_mayoreo: 0,
              utilidad: 0,
            },
            {
              numero_precio: 4,
              precio_neto: 0,
              precio_venta: 0,
              iva_precio: 0,
              ieps_precio: 0,
              unidad_mayoreo: 0,
              utilidad: 0,
            },
            {
              numero_precio: 5,
              precio_neto: 0,
              precio_venta: 0,
              iva_precio: 0,
              ieps_precio: 0,
              unidad_mayoreo: 0,
              utilidad: 0,
            },
            {
              numero_precio: 6,
              precio_neto: 0,
              precio_venta: 0,
              iva_precio: 0,
              ieps_precio: 0,
              unidad_mayoreo: 0,
              utilidad: 0,
            },
          ],
          unidad_de_compra: {
            cantidad: row[8],
            precio_unitario_con_impuesto: row[9],
            precio_unitario_sin_impuesto: 0,
            codigo_unidad: codigo_unidad.isMinima
              ? codigo_unidad.codigo_minima
              : codigo_unidad.codigo_maxima,
            unidad: row[7].toString().trim(),
          },
          granel: false,
        },
        unidades_de_venta: unidades_de_venta,
        sucursal: sesion.sucursal._id,
        usuario: sesion._id,
        selected,
      };

      return objectReturn;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const dataPlantilla = [
    {
      codigo_barras: "",
      clave_alterna: "",
      nombre_comercial: "",
      nombre_generico: "",
      descripcion: "",
      tipo_producto: "",
      unidad_de_compra_unidad: "",
      factor_por_unidad: "",
      precio_compra: "",
      precio_de_compra_iva: "",
      precio_de_compra_ieps: "",
      utilidad: "",
    },
  ];

  return (
    <Box>
      <SnackBarMessages alert={alert} setAlert={setAlert} />

      <Menu
        id="menu-xls"
        anchorEl={openMenu}
        keepMounted
        open={openMenu}
        onClose={handleClickClose}
        style={{ zIndex: 9999, marginTop: 40 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <ExcelExportButton
            data={dataPlantilla}
            name="Plantilla Xls productos"
            button
          />
        </MenuItem>
        <MenuItem>
          <label htmlFor="contained-button-file">
            <Button
              variant="text"
              type="file"
              color="primary"
              startIcon={<RiFileExcel2Line />}
              component="span"
              disableElevation
            >
              Importar productos excel
            </Button>
          </label>
          <input
            id="contained-button-file"
            multiple
            type="file"
            accept=".xlsx"
            style={{ display: "none" }}
            name="archivo"
            onChange={importarXlsPlantillaProductos}
          />
        </MenuItem>
      </Menu>
      <ModalProductosAgregar
        modalProductos={modalProductos}
        onCloseModal={onCloseModal}
        productos={productos}
        setLoading={setLoading}
        loading={loading}
        setProductos={setProductos}
        setAlert={setAlert}
      />
    </Box>
  );
}

export default ImportXlsModal;
