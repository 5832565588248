import React from "react";
import Box from "@material-ui/core/Box";
import CardBanner from "./CardBanner";
import CrearBanner from "./CrearBanner";
import SizeBanner from "./SizeBanner";

export default function BannerPrincipal() {
  return (
    <Box>
      <SizeBanner />
      <Box my={1} display="flex" justifyContent="center">
        <CrearBanner />
      </Box>
      <CardBanner />
    </Box>
  );
}
