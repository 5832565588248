
import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { CloudDownload } from "@material-ui/icons";
//import { ACTUALIZAR_BD_LOCAL } from "../../gql/Catalogos/usuarios";
//import { useMutation } from "@apollo/client";
/* import {
    VentasContext,
  } from "../../context/Ventas/ventasContext"; */
import { AccesosContext } from "../../context/Accesos/accesosCtx";

export default function ComponentGetDataDBCloud ({isOnline, classes, empresa, sucursal}) {
   // const [alert, setAlert] = useState({ message: "", status: "", open: false });
    //const [actualizarBDLocal] = useMutation(ACTUALIZAR_BD_LOCAL);
    const { getData } = useContext(AccesosContext);
    
    return (
      <Box display="flex" alignItems={'center'}  >
      {
        (isOnline) ?
        <Button
          onClick={() => getData(empresa, sucursal)}
          startIcon={<CloudDownload htmlColor="black" style={{fontSize: 20 }} />}
          className={classes.buttonIcon}
          style={
            { color: "black", 
            borderColor: "white",
            
            }
          }
        >
          Sincronizar datos
        </Button> 
        :
        <div />
      }
      </Box> 
    );
  };