import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { FacturacionCtx } from "../../../../context/Facturacion/facturacionCtx.js";
import { useApolloClient } from "@apollo/client";
import { OBTENER_SERIE_DEFAULT } from "../../../../gql/Facturacion/Facturacion";
import CustomLoader from "../../../../components/CustomLoader.jsx";
import ErrorPage from "../../../../components/ErrorPage";
import { Alert } from "@material-ui/lab";
import EmisorForm from "./CfdiForm/Emisor.jsx";
import ReceptorForm from "./CfdiForm/Receptor.jsx";
import CfdiFormDetail from "./CfdiForm/CfdiDetails.jsx";
import OtrosDatosCfdi from "./CfdiForm/OtrosDatosCfdi.jsx";

export default function FacturaForm({ setAlert, open }) {
  const client = useApolloClient();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const { datosFactura, setDatosFactura } = React.useContext(FacturacionCtx);

  const getSerieDefault = async () => {
    try {
      const response = await client.query({
        query: OBTENER_SERIE_DEFAULT,
        variables: {
          empresa: sesion.empresa._id,
        },
        fetchPolicy: "network-only",
      });
      if (response.data) {
        const { folio, serie } = response.data.obtenerSerieDefault;
        setDatosFactura({ ...datosFactura, folio: folio.toString(), serie });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(error);
      setAlert({
        message: "Hubo un error al cargar los productos",
        status: "error",
        open: true,
      });
    }
  };

  React.useEffect(() => {
    if (open) getSerieDefault();
  }, [open]);

  if (loading) return <CustomLoader height="60vh" />;
  if (error) return <ErrorPage error={error} />;

  return (
    <React.Fragment>
      {!datosFactura.folio || !datosFactura.serie ? (
        <Alert severity="warning">No tienes Series CFDI registradas</Alert>
      ) : null}
      <Box>
        <Grid
          container
          spacing={2}
          style={{
            minHeight: 180,
            marginBottom: 8,
          }}
        >
          <Grid item xs={3}>
            <EmisorForm />
          </Grid>
          <Grid item xs={4}>
            <ReceptorForm />
          </Grid>
          <Grid item xs={5} setAlert={setAlert}>
            <OtrosDatosCfdi />
          </Grid>
        </Grid>
        <CfdiFormDetail />
      </Box>
    </React.Fragment>
  );
}
