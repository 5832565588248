import { gql } from "@apollo/client";

export const CREATE_TRANSPORTE = gql`
  mutation CrearTransporte(
    $empresa: ID!
    $sucursal: ID!
    $input: AutoTransporteInput
  ) {
    crearTransporte(empresa: $empresa, sucursal: $sucursal, input: $input) {
      message
    }
  }
`;
export const UPDATE_TRANSPORTE = gql`
  mutation ActualizarTransporte(
    $idTransporte: ID!
    $input: AutoTransporteInput
  ) {
    actualizarTransporte(idTransporte: $idTransporte, input: $input) {
      message
    }
  }
`;
export const DELETE_TRANSPORTE = gql`
  mutation EliminarTransporte($idTransporte: ID!) {
    eliminarTransporte(idTransporte: $idTransporte) {
      message
    }
  }
`;
export const GET_TRANSPORTES = gql`
  query ObtenerAutoTransportes($empresa: ID!, $sucursal: ID) {
    obtenerAutoTransportes(empresa: $empresa, sucursal: $sucursal) {
      _id
      tagIdentificador
      PermSCT
      NumPermisoSCT
      IdentificacionVehicular {
        ConfigVehicular
        PlacaVM
        AnioModeloVM
        PesoBrutoVehicular
      }
      Seguros {
        AseguraRespCivil
        PolizaRespCivil
      }
      Remolques {
        SubTipoRem
        Placa
      }
    }
  }
`;

export const CREATE_TRANSPORTISTA = gql`
  mutation CrearTransportista(
    $empresa: ID!
    $sucursal: ID!
    $input: TransportistaInput
  ) {
    crearTransportista(empresa: $empresa, sucursal: $sucursal, input: $input) {
      message
    }
  }
`;
export const UPDATE_TRANSPORTISTA = gql`
  mutation ActualizarTransportista(
    $idTransportista: ID!
    $input: TransportistaInput
  ) {
    actualizarTransportista(idTransportista: $idTransportista, input: $input) {
      message
    }
  }
`;
export const DELETE_TRANSPORTISTA = gql`
  mutation EliminarTransportista($idTransportista: ID!) {
    eliminarTransportista(idTransportista: $idTransportista) {
      message
    }
  }
`;
export const GET_TRANSPORTISTAS = gql`
  query ObtenerTransportistas($empresa: ID!, $sucursal: ID) {
    obtenerTransportistas(empresa: $empresa, sucursal: $sucursal) {
      _id
      TipoFigura
      RFCFigura
      NumLicencia
      NombreFigura
      Domicilio {
        Calle
        NumeroExterior
        NumeroInterior
        Colonia
        Localidad
        Referencia
        Municipio
        Estado
        Pais
        CodigoPostal
      }
    }
  }
`;
