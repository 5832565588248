import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { Box, Container, IconButton } from "@material-ui/core";
import RegistroDepartamentos from "./RegistroDepartamento";
import { DepartamentosProvider } from "../../../../context/Catalogos/Departamentos";
import DescripcionCatalogo from "../../../../components/DescripcionCatalogo";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { ArrowBack } from "@material-ui/icons";
import { FcFaq } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  icon: {
    width: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Departamentos() {
  const { isOnline } = useContext(AccesosContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const descripcion =
    "En este apartado se registran los departamentos que se le podrá asignar a un producto.";
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DepartamentosProvider>
        <Button fullWidth onClick={handleClickOpen}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <FcFaq style={{ fontSize: 100 }} />
            </Box>
            Departamentos
          </Box>
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Typography variant="h6" className={classes.title}>
            Departamentos
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            position="absolute"
            top={5}
            right={10}
          >
            <DescripcionCatalogo texto={descripcion} />
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleClose}
            >
              <ArrowBack />
            </IconButton>
          </Box>
          <Box>
            <Container maxWidth="sm">
              <RegistroDepartamentos isOnline={isOnline} accion="registrar" />
            </Container>
          </Box>
        </Dialog>
      </DepartamentosProvider>
    </div>
  );
}
