import React from "react";
import { InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { TraspasosContext } from "../../Context/TraspasosContext";
import { CallMade, CallReceived, NotInterested } from "@material-ui/icons";
import { GetAlmacenes } from "../../graphql/queries";
import { CustomTableSelect } from "../../../../../../components/CustomMuiComponents";

export default function Destino() {
  const { traspaso, setTraspaso } = React.useContext(TraspasosContext);

  const { loading, data, error } = useQuery(GetAlmacenes, {
    variables: {
      id: traspaso?.sucursalDestino || "",
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <FormControl size="small" variant="outlined" fullWidth disabled>
        <InputLabel id="loading">Almacen destino</InputLabel>
        <CustomTableSelect labelId="loading" label="Almacen destino" value="loading">
          <MenuItem value="loading">Cargando...</MenuItem>
        </CustomTableSelect>
      </FormControl>
    );
  }

  if (error) {
    return (
      <FormControl size="small" variant="outlined" fullWidth disabled>
        <InputLabel id="error">Almacen destino</InputLabel>
        <CustomTableSelect labelId="error" label="Almacen destino" value="error">
          <MenuItem value="error">Error</MenuItem>
        </CustomTableSelect>
      </FormControl>
    );
  }

  const almacenes = data?.obtenerAlmacenesCloud || [];
  const concepto = traspaso.concepto_traspaso?.nombre_concepto;

  const handleChange = (e) => {
    const { value } = e.target;
    setTraspaso({
      ...traspaso,
      almacen_destino: value,
    });
  };

  const GetIcon = () => {
    const destino = traspaso.concepto_traspaso?.destino || "";
    switch (destino) {
      case "SUMA":
        return <CallMade fontSize="small" />;
      case "RESTA":
        return <CallReceived fontSize="small" />;
      default:
        return <NotInterested fontSize="small" />;
    }
  };

  return (
    <>
      <FormControl size="small" variant="outlined" fullWidth>
        <InputLabel id="almacen-destino-label">Almacén destino</InputLabel>
        <CustomTableSelect
          labelId="almacen-destino-label"
          label="Almacén destino"
          value={traspaso.almacen_destino || ""}
          onChange={handleChange}
          disabled={
            !traspaso.concepto_traspaso ||
            traspaso.productos.length ||
            traspaso.concepto_traspaso?.destino === "N/A" ||
            (concepto === "SALIDAS POR TRASPASOS" && !traspaso.sucursalDestino)
          }
          startAdornment={<GetIcon />}
        >
          {almacenes.map((almacen) => (
            <MenuItem key={almacen._id} value={almacen}>
              {almacen.nombre_almacen}
            </MenuItem>
          ))}
        </CustomTableSelect>
      </FormControl>
    </>
  );
}
