import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Tab,
  Tabs,
} from "@material-ui/core";
import React, { useState } from "react";
import PropTypes from "prop-types";
import ReportesAbonosProveedores from "./Proveedores/ReportesAbonosProveedores";
import ReportesAbonos from "./Abono/ReportesAbonos";
import { FcRatings } from "react-icons/fc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportesProveedores() {
  const [val, setVal] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
    setVal(0);
  };

  const handleChange = (event, newValue) => {
    setVal(newValue);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClickOpen}
      >
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ height: 100 }}
          >
            <FcRatings style={{ fontSize: 100 }} />
          </Box>
          Cuentas proveedores
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
        hideBackdrop={true}
        PaperProps={{ elevation: 0 }}
      >
        <DialogTitle component="div" style={{ padding: "0 20px" }}>
          <Tabs
            value={val}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Estados de cuenta proveedor" {...a11yProps(0)} />
            <Tab label="Abonos" {...a11yProps(1)} />
          </Tabs>
        </DialogTitle>
        <DialogContent>
          <TabPanel value={val} index={0}>
            <ReportesAbonosProveedores handleClickOpen={handleClickOpen} />
          </TabPanel>
          <TabPanel value={val} index={1}>
            <ReportesAbonos handleClickOpen={handleClickOpen} />
          </TabPanel>
        </DialogContent>
      </Dialog>
    </>
  );
}
