import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Close, Search } from "@material-ui/icons";
import React from "react";
//import { useQuery } from "@apollo/client";
//import { OBTENER_CAJAS } from "../../../../../gql/Cajas/cajas";
import ErrorPage from "../../../../../components/ErrorPage";
import { CustomCheckboxCanceladas } from "../CustomCheckBoxsStyles";
import { CustomSearchTextField, CustomTableTextField } from "../../../../../components/CustomMuiComponents";

export default function FiltrosAbonos({
  datosFiltro,
  setDatosFiltro,
  limpiarDatos,
}) {
  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setDatosFiltro({
      ...datosFiltro,
      [name]: value,
    });
  };

  const obtenerChecks = (e) => {
    const { name, checked } = e.target;
    if (name === "canceladas") {
      setDatosFiltro({
        ...datosFiltro,
        [name]: checked,
        realizadas: false,
      });
    } else {
      setDatosFiltro({
        ...datosFiltro,
        [name]: checked,
        canceladas: false,
      });
    }
  };

  //const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  /* Queries */
  /* const { data, error, loading } = useQuery(OBTENER_CAJAS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
    },
  }); */

  /* if (loading) {
    return (
      <FormControl variant="outlined" fullWidth size="small">
        <Select>
          <MenuItem value="">
            <em>Cargando...</em>
          </MenuItem>
        </Select>
      </FormControl>
    );
  }

  if (error) {
    console.log(error.networkError.result);
    return <ErrorPage error={error} />;
  } */

  //const { obtenerCajasSucursal } = data;

  return (
    <Box my={1}>
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <CustomSearchTextField
            placeholder="Folio"
            onChange={(e) => obtenerCampos(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
            }}
            value={datosFiltro.folio}
            variant="outlined"
            size="small"
            name="folio"
            style={{
              width: 150,
            }}
          />
        </Grid>
        <Grid item>
          <CustomTableTextField
            fullWidth
            onChange={(e) => obtenerCampos(e)}
            type="date"
            value={datosFiltro.fecha_inicio}
            variant="outlined"
            size="small"
            name="fecha_inicio"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">De:</InputAdornment>
              ),
            }}
            style={{
              width: 150,
            }}
          />
        </Grid>
        <Grid item>
          <CustomTableTextField
            fullWidth
            onChange={(e) => obtenerCampos(e)}
            type="date"
            value={datosFiltro.fecha_fin}
            variant="outlined"
            size="small"
            name="fecha_fin"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">a:</InputAdornment>
              ),
            }}
            style={{
              width: 150,
            }}
          />
        </Grid>
        {/* <Grid item>
          <FormControl variant="outlined" fullWidth size="small" placeholder="Forma de Pago">
            <CustomTableSelect
              name="forma_pago"
              onChange={(e) => obtenerCampos(e)}
              value={datosFiltro.forma_pago}
              placeholder="Forma de Pago"
              style={{
                width: 150
              }}
            >
              <MenuItem value="">
                <em>Cualquier forma</em>
              </MenuItem>
              {formaPago.map((res, index) => (
                <MenuItem
                  key={index}
                  value={res.Value}
                >{`${res.Value} - ${res.Name}`}</MenuItem>
              ))}
            </CustomTableSelect>
          </FormControl>
        </Grid> */}
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={datosFiltro.realizadas}
                onChange={(e) => obtenerChecks(e)}
                name="realizadas"
                color="default"
              />
            }
            label="Realizadas"
          />
        </Grid>

        <Grid item>
          <FormControlLabel
            control={
              <CustomCheckboxCanceladas
                checked={datosFiltro.canceladas}
                onChange={(e) => obtenerChecks(e)}
                name="canceladas"
                color="default"
              />
            }
            label="Canceladas"
          />
        </Grid>
        <Grid item>
          <IconButton onClick={limpiarDatos} size="small">
            <Close />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
