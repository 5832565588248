import React, { Fragment } from "react";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  FaMoneyBillWaveAlt,
  FaCreditCard,
  FaMoneyCheckAlt,
  FaIdCard,
} from "react-icons/fa";
import { BsBank2 } from "react-icons/bs";
import { deepPurple, blueGrey } from "@material-ui/core/colors";

export default function ObtenerMontos({
  montos,
  setMontos,
  totales,
  setTotales,
  venta_credito,
  montoEnCaja,
  setMontoEnCaja,
  datosCliente,
  setEfectivoConstante,
}) {
  //const classes = useStyles();

  let venta_original = JSON.parse(localStorage.getItem("DatosVentas"));
  const total_venta = venta_original
    ? parseFloat(venta_original.total.toFixed(2))
    : 0;

  const handlerChangeValue = (e, location) => {
    const { value } = e.target;
    switch (location) {
      case "EFECTIVO":
        setMontos({ ...montos, efectivo: value });
        setEfectivoConstante(value);
        break;
      case "TARJETA":
        setMontos({ ...montos, tarjeta: value });
        break;
      case "PUNTOS":
        const valor = value !== "" ? parseInt(value) : 0;
        if (
          valor <= datosCliente.monedero_electronico &&
          valor <= total_venta
        ) {
          setMontos({
            ...montos,
            puntos: value,
            efectivo: total_venta - valor, //efectivoConstante
          });
          setTotales({ ...totales, total: total_venta - valor });
        }
        break;
      case "TRANSFERENCIA":
        setMontos({ ...montos, transferencia: value });
        break;
      case "CHEQUE":
        setMontos({ ...montos, cheque: value });
        break;
      default:
        return alert("Error de pago");
    }
  };

  const initial_state_montos = {
    efectivo: 0,
    tarjeta: 0,
    puntos: 0,
    transferencia: 0,
    cheque: 0,
  };

  const handleSetAmount = (value) => {
    switch (value) {
      case "EFECTIVO":
        setMontos({ ...initial_state_montos, efectivo: total_venta });
        setTotales({ ...totales, total: total_venta });
        break;
      case "TARJETA":
        setMontos({ ...initial_state_montos, tarjeta: total_venta });
        setTotales({ ...totales, total: total_venta });
        break;
      case "PUNTOS":
        const { monedero_electronico } = datosCliente;
        if (monedero_electronico && montos.puntos < monedero_electronico) {
          let monto_monedero = monedero_electronico;
          if (monto_monedero > total_venta) monto_monedero = total_venta;
          setMontos({
            ...initial_state_montos,
            puntos: monto_monedero,
            efectivo: total_venta - monto_monedero /* efectivoConstante */,
          });
          setTotales({ ...totales, total: total_venta - monto_monedero });
        }
        break;
      case "TRANSFERENCIA":
        setMontos({ ...initial_state_montos, transferencia: total_venta });
        setTotales({ ...totales, total: total_venta });
        break;
      case "CHEQUE":
        setMontos({ ...initial_state_montos, cheque: total_venta });
        setTotales({ ...totales, total: total_venta });
        break;
      default:
        return alert("Error de pago");
    }
  };

  React.useEffect(() => {
    if (venta_credito) return;
    let monto_caja = 0;
    let monto_pagado =
      parseFloat(montos.efectivo) +
      parseFloat(montos.tarjeta) +
      parseFloat(montos.puntos) +
      parseFloat(montos.transferencia) +
      parseFloat(montos.cheque);

    let cambio = monto_pagado - totales.total - montos.puntos;
    /* let cambio_caja = parseFloat(monto_pagado) - total_venta; */

    monto_caja = parseFloat((montos.efectivo - cambio).toFixed(2));

    setMontoEnCaja(monto_caja);
    setTotales({
      ...totales,
      monto_pagado,
      cambio,
    });
    setEfectivoConstante(totales.total);
  }, [montos]);

  const clearFieldOnFocus = (e) => {
    const { name, value } = e.target;
    const val = parseFloat(value);
    switch (name) {
      case "EFECTIVO":
        if (val === 0) {
          setMontos({ ...montos, efectivo: 0 });
          setEfectivoConstante(0);
        }
        break;
      case "TARJETA":
        if (val === 0) {
          setMontos({ ...montos, tarjeta: 0 });
        }
        break;
      case "PUNTOS":
        if (val === 0) {
          setMontos({ ...montos, puntos: 0 });
        }
        break;
      case "TRANSFERENCIA":
        if (val === 0) {
          setMontos({ ...montos, transferencia: 0 });
        }
        break;
      case "CHEQUE":
        if (val === 0) {
          setMontos({ ...montos, cheque: 0 });
        }
        break;
      default:
        break;
    }
  };

  const validateCantidadesCorrectas = (e) => {
    const { name, value } = e.target;
    const monto = parseFloat(montoEnCaja);
    switch (name) {
      case "EFECTIVO":
        if (value === "") {
          setMontos({ ...montos, efectivo: 0 });
          setEfectivoConstante(0);
        }
        break;
      case "TARJETA":
        if (value === "") {
          setMontos({ ...montos, tarjeta: 0 });
        } else if (monto < 0) {
          setMontos({ ...montos, tarjeta: 0 });
        }
        break;
      case "PUNTOS":
        if (value === "") {
          setMontos({ ...montos, puntos: 0 });
        } else if (
          monto < 0 &&
          parseFloat(montos.puntos) !== parseFloat(total_venta)
        ) {
          setMontos({ ...montos, puntos: 0 });
        }
        break;
      case "TRANSFERENCIA":
        if (value === "") {
          setMontos({ ...montos, transferencia: 0 });
        } else if (monto < 0) {
          setMontos({ ...montos, transferencia: 0 });
        }
        break;
      case "CHEQUE":
        if (value === "") {
          setMontos({ ...montos, cheque: 0 });
        } else if (monto < 0) {
          setMontos({ ...montos, cheque: 0 });
        }
        break;
      default:
        break;
    }
  };

  return (
    <Fragment>
      <Box
        display="grid"
        gridTemplateColumns="repeat(5, minmax(100px, 1fr))"
        gridGap={10}
      >
        <Button
          className="button-panel-venta"
          disableFocusRipple
          fullWidth
          onClick={() => handleSetAmount("EFECTIVO")}
          style={{
            padding: 0,
          }}
        >
          <Box>
            <FaMoneyBillWaveAlt
              style={{
                color: montos.efectivo ? deepPurple[300] : blueGrey[100],
                fontSize: 40,
              }}
            />
            <Typography style={{ fontSize: 13, textTransform: "none" }}>
              Efectivo
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="EFECTIVO"
              id="form-producto-efectivo"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onClick={(e) => e.stopPropagation()}
              value={montos.efectivo}
              onChange={(e) => handlerChangeValue(e, "EFECTIVO")}
              onBlur={validateCantidadesCorrectas}
              onFocus={clearFieldOnFocus}
              autoFocus
              disabled={venta_credito}
            />
          </Box>
        </Button>
        <Button
          className="button-panel-venta"
          disableFocusRipple
          fullWidth
          onClick={() => handleSetAmount("TARJETA")}
          style={{
            padding: 0,
          }}
        >
          <Box>
            <FaCreditCard
              style={{
                color: montos.tarjeta ? deepPurple[300] : blueGrey[100],
                fontSize: 40,
              }}
            />
            <Typography style={{ fontSize: 13, textTransform: "none" }}>
              Tarjeta
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="TARJETA"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={montos.tarjeta}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handlerChangeValue(e, "TARJETA")}
              onBlur={validateCantidadesCorrectas}
              onFocus={clearFieldOnFocus}
              disabled={venta_credito}
            />
          </Box>
        </Button>
        <Button
          className="button-panel-venta"
          disableFocusRipple
          fullWidth
          onClick={() => handleSetAmount("PUNTOS")}
          style={{
            padding: 0,
          }}
        >
          <Box>
            <FaIdCard
              style={{
                color: montos.puntos ? deepPurple[300] : blueGrey[100],
                fontSize: 40,
              }}
            />
            <Typography style={{ fontSize: 13, textTransform: "none" }}>
              Dinero electrónico
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="PUNTOS"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={montos.puntos}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handlerChangeValue(e, "PUNTOS")}
              onBlur={validateCantidadesCorrectas}
              onFocus={clearFieldOnFocus}
              disabled={venta_credito}
            />
          </Box>
        </Button>
        <Button
          className="button-panel-venta"
          disableFocusRipple
          fullWidth
          onClick={() => handleSetAmount("TRANSFERENCIA")}
          style={{
            padding: 0,
          }}
        >
          <Box>
            <BsBank2
              style={{
                color: montos.transferencia ? deepPurple[300] : blueGrey[100],
                fontSize: 40,
              }}
            />
            <Typography style={{ fontSize: 13, textTransform: "none" }}>
              Transferencia
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="TRANSFERENCIA"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onClick={(e) => e.stopPropagation()}
              value={montos.transferencia}
              onChange={(e) => handlerChangeValue(e, "TRANSFERENCIA")}
              onBlur={validateCantidadesCorrectas}
              onFocus={clearFieldOnFocus}
              disabled={venta_credito}
            />
          </Box>
        </Button>
        <Button
          className="button-panel-venta"
          disableFocusRipple
          fullWidth
          onClick={() => handleSetAmount("CHEQUE")}
          style={{
            padding: 0,
          }}
        >
          <Box>
            <FaMoneyCheckAlt
              style={{
                color: montos.cheque ? deepPurple[300] : blueGrey[100],
                fontSize: 40,
              }}
            />
            <Typography style={{ fontSize: 13, textTransform: "none" }}>
              Cheque
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="CHEQUE"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={montos.cheque}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handlerChangeValue(e, "CHEQUE")}
              onBlur={validateCantidadesCorrectas}
              onFocus={clearFieldOnFocus}
              disabled={venta_credito}
            />
          </Box>
        </Button>
      </Box>
    </Fragment>
  );
}
