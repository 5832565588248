import React, { useContext } from "react";
import SnackBarMessages from "../../../components/SnackBarMessages";
import { IconButton } from "@material-ui/core";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import moment from "moment-timezone";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { ClienteCtx } from "../../../context/Catalogos/crearClienteCtx";

export default function TomarPedido({
  cotizacion,
  handleClose,
  setOpenMessage,
  openMessage,
}) {
  //const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  let datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));
  const { updateTablaVentas, setUpdateTablaVentas } = useContext(VentasContext);
  const { updateClientVenta, setUpdateClientVenta } = useContext(ClienteCtx);

  const AgregarVentaDeNuevo = (e) => {
    try {
      if(cotizacion.status === "REALIZADO")return
      //	(_cotizacion.fecha_vencimiento_cotizacion
      e.stopPropagation();
      let objectToSave = JSON.stringify({
        cliente:
          cotizacion.cliente.nombre_cliente === null ? {} : cotizacion.cliente,
        descuento: cotizacion.descuento,
        ieps: cotizacion.ieps,
        impuestos: cotizacion.impuestos,
        iva: cotizacion.iva,
        monedero: cotizacion.monedero,
        productos: cotizacion.productos,
        subtotal_base: cotizacion.subtotal_base,
        subTotal: cotizacion.subTotal,
        total_base: cotizacion.total_base,
        total: cotizacion.total,
        cotizacion: true,
        pedido: true,
        id_pedido: cotizacion._id,
        venta_cliente: true,
        tipo_emision: cotizacion.tipo_emision
          ? cotizacion.tipo_emision
          : "TICKET",
      });

      //Veriricar si hay una
      if (datosVenta === null) {
        //setOpenMessage({message: 'agregarVentaDeNuevo', open:true});
        localStorage.setItem("DatosVentas", objectToSave);
        handleClose();
        updateDataStorage();
      } else {
        setOpenMessage({
          message: "No puedes agregar una venta cuando ya está una en curso.",
          status: "error",
          open: true,
        });
      }
    } catch (error) {
      setOpenMessage({ message: error, status: "error", open: true });
    }
  };

  const updateDataStorage = () => {
    setUpdateTablaVentas(!updateTablaVentas);
    setUpdateClientVenta(!updateClientVenta);
  };

  try {
    return (
      <>
        <IconButton
          aria-label="regresar"
          color="primary"
          size="small"
          onClick={AgregarVentaDeNuevo}
          disabled={cotizacion.status === "REALIZADO"}
        >
          <AutorenewIcon fontSize="medium" />
        </IconButton>
        <SnackBarMessages alert={openMessage} setAlert={setOpenMessage} />
      </>
    );
  } catch (error) {}
}
