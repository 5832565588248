import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TablePagination from "@material-ui/core/TablePagination";
import { CropOriginal } from "@material-ui/icons";
import { formatoMexico } from "../../../config/reuserFunctions";
import { Img } from "react-image";
import DetalleProductoVentas from "./DetalleProducto";
import CustomLoader from "../../../components/CustomLoader";
import { Button, withStyles } from "@material-ui/core";

const useStyles = makeStyles({
  colorContainer: {
    height: 18,
    borderRadius: 3,
    fontSize: 12,
  },
});

const CustomButtonList = withStyles((theme) => ({
  root: {
    padding: "8px 4px",
    "& .MuiButton-label": {
      display: "flex",
      gap: theme.spacing(1),
    },
  },
}))(Button);

export default function ListaProductos({
  productosBusqueda,
  loading,
  agregarProductos,
  page,
  setPage,
  limit,
}) {
  if (loading) return <CustomLoader height="40vh" />;

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <React.Fragment>
      <DialogContent>
        {productosBusqueda.docs.map((row, index) => {
          return (
            <ItemsProductos
              key={index}
              producto={row}
              agregarProductos={agregarProductos}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={productosBusqueda.totalDocs}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
        />
      </DialogActions>
    </React.Fragment>
  );
}

function ItemsProductos({ producto, agregarProductos }) {
  const classes = useStyles();
  const theme = useTheme();

  let ComponenteMedidaColor = () => {
    if (producto.color && producto.medida) {
      return (
        <Box display="flex" gridGap={3}>
          <Chip
            size="small"
            label={`T${producto.medida.talla}`}
            className={classes.colorContainer}
          />
          <Chip
            size="small"
            label={`${producto.color.nombre}`}
            className={classes.colorContainer}
            style={{
              backgroundColor: producto.color.hex,
              color: theme.palette.getContrastText(producto.color.hex),
            }}
          />
        </Box>
      );
    } else {
      return null;
    }
  };

  let ComponentCantidad = () => {
    let cantidad = "";
    //console.log(producto)
    if (producto.id_producto.datos_generales.tipo_producto === "OTROS") {
      cantidad =
        producto.unidad === "Costal" || producto.unidad === "Caja"
          ? producto.inventario_general[0].cantidad_existente_maxima
          : producto.inventario_general[0].cantidad_existente;
    } else {
      cantidad = producto.cantidad;
    }
    return cantidad;
  };

  function MyImageComponent() {
    let imagen = "";
    if (
      producto.id_producto.imagenes &&
      producto.id_producto.imagenes.length > 0
    ) {
      imagen = producto.id_producto.imagenes[0].url_imagen;
    }
    return imagen ? (
      <Img
        src={[imagen]}
        style={{ maxHeight: "100%", maxWidth: "100%", borderRadius: 5 }}
      />
    ) : (
      <CropOriginal fontSize="large" color="disabled" />
    );
  }

  return (
    <Box mb={1} style={{ borderBottom: "1px solid", borderColor: "#e9e9e9" }}>
      <CustomButtonList
        fullWidth
        component={Box}
        onClick={() => agregarProductos(producto)}
      >
        <Box
          height={48}
          width={48}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={5}
        >
          <MyImageComponent />
        </Box>
        <Box>
          <Box display="flex" gridGap={10}>
            <Typography variant="subtitle2">
              {producto.id_producto.datos_generales.nombre_comercial}
            </Typography>
            <ComponenteMedidaColor />
          </Box>
          <Typography variant="caption">{`${
            producto.id_producto.datos_generales.clave_alterna
          } ${producto?.codigo_barras || ""}`}</Typography>
          <Typography
            style={{ marginLeft: 8 }}
            variant="caption"
          >{` ${ComponentCantidad()} ${producto.unidad}`}</Typography>
        </Box>
        <Box flexGrow={1} />
        <Box>
          <Typography>${formatoMexico(producto?.precio)}</Typography>
        </Box>
        <Box onClick={(e) => e.stopPropagation()}>
          <DetalleProductoVentas producto={producto} />
        </Box>
      </CustomButtonList>
    </Box>
  );
}
