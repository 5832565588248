import * as React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import { yellow } from "@material-ui/core/colors";

export default function ProdAlmTableBody({ data, almacenes }) {
  return (
    <TableBody>
      {data.map((prodAlm) => (
        <RenderRows key={prodAlm._id} prodAlm={prodAlm} almacenes={almacenes} />
      ))}
    </TableBody>
  );
}

const RenderRows = ({ prodAlm, almacenes }) => {
  const { datos_generales, precios } = prodAlm.productoData;

  const name = () => {
    const { color, medida } = prodAlm;

    if (color || medida) {
      if ((color.nombre || color.color) && (medida.talla || medida.medida)) {
        return `${datos_generales.nombre_comercial} Talla ${
          medida.talla || medida.medida
        } ${color.nombre || color.color}`;
      } else {
        return datos_generales.nombre_comercial;
      }
    } else {
      return datos_generales.nombre_comercial;
    }
  };

  const getExistencia = ({ nombre_almacen }) => {
    const prodAlmacenes = prodAlm.almacenes;
    const alm = prodAlmacenes.find(
      (item) => item.nombre_almacen === nombre_almacen
    );
    if (alm && alm.existencia.length > 0) {
      return alm.existencia[0].cantidad_existente;
    }
    return 0;
  };

  return (
    <TableRow role="checkbox" tabIndex={-1}>
      <TableCell>{prodAlm.codigo_barras}</TableCell>
      <TableCell>{name()}</TableCell>
      <TableCell>{datos_generales.categoria}</TableCell>
      <TableCell>{datos_generales.subtcategoria}</TableCell>
      <TableCell>{datos_generales.departamento}</TableCell>
      <TableCell>{datos_generales.marca}</TableCell>
      <TableCell>{prodAlm.unidad}</TableCell>
      <TableCell>
        ${formatoMexico(precios.precio_de_compra.precio_con_impuesto)}
      </TableCell>
      <TableCell>${formatoMexico(prodAlm?.costoTotal)}</TableCell>
      {almacenes.map((alm) => (
        <TableCell style={{backgroundColor: yellow[50]}}>{getExistencia(alm)}</TableCell>
      ))}
      <TableCell style={{backgroundColor: yellow[50]}}>
        {prodAlm.cantidadTotalAlmacenes?.cantidad_existente || 0}
      </TableCell>
    </TableRow>
  );
};
