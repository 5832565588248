import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  colorContainer: {
    borderRadius: "3px",
    padding: '1px 4px',
  },
}));

const ComponenteMedidaColor = ({ producto }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (producto.color?.color && producto.medida?.medida) {
    return (
      <TableCell align="center">
        <Box display="flex" alignItems="center" gridGap={10}>
          <Typography variant="subtitle2">{producto.medida.medida}</Typography>
          <Box
            className={classes.colorContainer}
            style={{
              backgroundColor: producto.color.hex,
              color: theme.palette.getContrastText(producto.color.hex),
            }}
          >
            <Typography variant="subtitle2">{producto.color.color}</Typography>
          </Box>
        </Box>
      </TableCell>
    );
  } else {
    return <TableCell align="center">{"N/A"}</TableCell>;
  }
};

export default function TableProdcutos({ row, open }) {

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ border: "none", paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Artículos traspasados
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Clave</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Cantidad</TableCell>
                    <TableCell>Unidad</TableCell>
                    <TableCell>Talla</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.productos?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {item.producto.datos_generales.codigo_barras}
                      </TableCell>
                      <TableCell>
                        {item.producto.datos_generales.clave_alterna}
                      </TableCell>
                      <TableCell>
                        {item.producto.datos_generales.nombre_comercial}
                      </TableCell>
                      <TableCell>{item.cantidad}</TableCell>
                      <TableCell>{item.unidad}</TableCell>
                      <ComponenteMedidaColor producto={item} />
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
