import { Box, Button, CircularProgress } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import React from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import { useQuery } from "@apollo/client";
import { OBTENER_HISTORIAL_CAJA } from "../../../../../gql/Cajas/cajas";
import { useDebounce } from "use-debounce";
import moment from "moment";
import ExcelExportButton from "../../../../../components/ExcelExportButton";

export default function ExportarReportesCajas({ cajaSelected, datosBuscar }) {
  const [value] = useDebounce(datosBuscar, 500);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  /* Queries */
  const { data, refetch, loading, error } = useQuery(OBTENER_HISTORIAL_CAJA, {
    variables: {
      id_Caja: cajaSelected._id,
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: {
        tipo_movimiento: datosBuscar.tipo_movimiento
          ? datosBuscar.tipo_movimiento
          : "",
        fecha_incio: datosBuscar.fecha_incio ? datosBuscar.fecha_incio : "",
        fecha_fin: datosBuscar.fecha_fin ? datosBuscar.fecha_fin : "",
        usuario: value.usuario ? value.usuario : "",
      },
    },
    fetchPolicy: "network-only",
  });

  const handleClickOpen = () => {
    refetch();
  };

  if (loading) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        Excel
      </Button>
    );
  }

  if (error) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        Excel
      </Button>
    );
  }

  return (
    <Box mt={1}>
      {data ? (
        <ExportarExcelAction
          datosExcel={data.obtenerHistorialCaja}
          cajaSelected={cajaSelected}
        />
      ) : (
        <Button
          variant="text"
          color="primary"
          startIcon={<RiFileExcel2Line />}
          onClick={handleClickOpen}
        >
          Excel
        </Button>
      )}
    </Box>
  );
}

const ExportarExcelAction = ({ datosExcel, cajaSelected }) => {
  const reporteData = datosExcel?.docs.map((res) => {
    const concepto = res.concepto
      ? res.concepto
      : res.concepto_cuenta
      ? res.concepto_cuenta
      : "";
    const arrayExcel = {
      fecha: moment(res.fecha_movimiento.completa)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY"),
      hora_movimiento: moment(res.fecha_movimiento.completa)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY, hh:mm a"),
      nombre: res.nombre_usuario_creador,
      horario_turno: res.horario_turno,
      tipo_movimiento: res.tipo_movimiento,
      concepto: concepto,
      caja: res.numero_caja,
      monto_efectivo: res.montos_en_caja.monto_efectivo.monto,
      monto_creditos: res.montos_en_caja.monto_creditos.monto,
      monto_tarjeta_debito: res.montos_en_caja.monto_tarjeta_debito.monto,
      monto_tarjeta_credito: res.montos_en_caja.monto_tarjeta_credito.monto,
      monto_monedero: res.montos_en_caja.monto_monedero.monto,
      monto_cheques: res.montos_en_caja.monto_cheques.monto,
      monto_transferencia: res.montos_en_caja.monto_transferencia.monto,
      monto_vales_despensa: res.montos_en_caja.monto_vales_despensa.monto,
    };
    return arrayExcel;
  });

  return (
    <ExcelExportButton data={reporteData} name={`Reporte de Historial de Caja ${cajaSelected.numero_caja}`} button />
  );
};
