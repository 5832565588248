import React from "react";
import { Box } from "@material-ui/core";
import TablaCajas from "./ListaCajas";
import SnackBarMessages from "../../../../components/SnackBarMessages";

export default function RegistroCajas({
  isOnline,
  obtenerCajasSucursal,
  refetch,
  setAlert,
}) {
  return (
    <Box>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <TablaCajas
        obtenerCajasSucursal={obtenerCajasSucursal}
        setAlert={setAlert}
        refetch={refetch}
        isOnline={isOnline}
      />
    </Box>
  );
}
