import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { formatoMexico } from "../../../config/reuserFunctions";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";

export default function InfoClienteVenta({
  montos,
  //totales,
  datosCliente,
  monedero,
  setVentaCredito,
  setEditarCliente,
  venta_base,
  venta_credito,
  credito_disponible,
  //monederoTotal,
}) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="caption">
          <b>Cliente:</b>
        </Typography>
        <Box mr={1} />
        <Typography variant="caption">
          {datosCliente.nombre_cliente
            ? datosCliente.nombre_cliente
            : "Público General"}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          <b>Dinero elec. disponible:</b>
        </Typography>
        <Box mr={1} />
        <Typography variant="caption">
          $
          {!datosCliente.monedero_electronico
            ? 0.0
            : formatoMexico(datosCliente.monedero_electronico - montos.puntos)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          <b>Dinero elec. a generar:</b>
        </Typography>
        <Box mr={1} />
        <Typography variant="caption">
          {` $${monedero ? formatoMexico(monedero) : 0.0}`}
        </Typography>
      </Grid>
      <Grid item>
        {venta_credito ? (
          <Button
            size="large"
            color="primary"
            onClick={() => {
              setVentaCredito(false);
              setEditarCliente(false);
            }}
            startIcon={<CheckBox />}
            style={{ textTransform: "none" }}
          >
            Venta a credito
          </Button>
        ) : (
          <Button
            size="large"
            color="primary"
            onClick={() => {
              setVentaCredito(true);
            }}
            startIcon={<CheckBoxOutlineBlank />}
            disabled={!credito_disponible || !venta_base}
            style={{ textTransform: "none" }}
          >
            Venta a credito
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
