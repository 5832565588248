import React, { forwardRef } from "react";
import {
  Typography,
  Slide,
  Button,
  Box,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FcSurvey } from "react-icons/fc";
import ContainerRegistroAlmacen from "./ContainerRegistroAlmacen";
import ListaAlmacen from "./ListaAlmacen";

import { AlmacenProvider } from "../../../../context/Almacenes/crearAlmacen";
import { ArrowBack } from "@material-ui/icons";
import { indigo } from "@material-ui/core/colors";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(4),
    marginBottom: "10px",
    marginTop: "5px",
  },
  icon: {
    fontSize: 100,
    color: indigo[300],
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RegistroAlmacen() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <AlmacenProvider>
        <Button fullWidth onClick={handleClickOpen}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <FcSurvey style={{ fontSize: 100 }} />
            </Box>
            Almacenes
          </Box>
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <Box mt={2}>
            <Typography variant="h6" className={classes.title}>
              Agregar almacen
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              position="absolute"
              top={15}
              right={15}
            >
              {sesion.accesos.almacenes.almacen.agregar === false ? null : (
                <ContainerRegistroAlmacen accion="registrar" />
              )}
              <Box mx={1} />
              <IconButton
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                <ArrowBack />
              </IconButton>
            </Box>
            <Box mx={4} mt={5}>
              <ListaAlmacen />
            </Box>
          </Box>
        </Dialog>
      </AlmacenProvider>
    </div>
  );
}
