import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import PrintIcon from "@material-ui/icons/Print";
import SelectPrinter from "./SelectPrinter";

export default function TicketPrinterComponent() {
  const [open, setOpen] = React.useState(false);
  const [print, setPrint] = React.useState(
    localStorage.getItem("cafiTicketPrint")
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <ListItem button onClick={() => handleClickOpen()}>
        <ListItemIcon>
          <Badge
            overlap="rectangular"
            badgeContent={1}
            variant="dot"
            color={print ? "default" : "error"}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <PrintIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary="Impresora" />
      </ListItem>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="select-printer"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="select-printer">
          Configuración impresora de tickets
        </DialogTitle>
        <SelectPrinter
          handleClose={handleClose}
          print={print}
          setPrint={setPrint}
        />
      </Dialog>
    </div>
  );
}
