import React, { useState } from "react";
import {
  Button,
  Box,
  Dialog,
  Slide,
  Typography,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MisDatos from "./Datos/MisDatos";
import InformacionFiscal from "./InformacionFiscal/InformacionFiscal";
import { ArrowBack } from "@material-ui/icons";
import { FcAutomatic } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  icon: {
    fontSize: 100,
  },
  subtitle: {
    marginLeft: "10px",
    width: "100%",
  },
  require: {
    "& span": {
      color: "red",
    },
  },
  avatarContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    border: "dashed 2px black",
    borderRadius: "100%",
  },
  avatar: {
    width: "90%",
    height: "90%",
    "& > .icon": {
      fontSize: 100,
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function MiEmpresa() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const RenderViews = () => {
    switch (value) {
      case 1:
        return <InformacionFiscal setOpen={setOpen} open={open} />;
      default:
        return <MisDatos setOpen={setOpen} />;
    }
  };

  return (
    <div>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcAutomatic className={classes.icon} />
          </Box>
          Empresa
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        scroll="body"
      >
        <DialogTitle component="div" style={{ padding: "0px 10px" }}>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleClose} size="large" color="primary">
              <ArrowBack />
            </IconButton>
            <Box mx={1} />
            <Tabs
              indicatorColor="primary"
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              centered
            >
              <Tab label="Datos empresa" value={0} />
              <Tab
                label="Datos fiscales"
                value={1}
                disabled={
                  sesion.accesos.mi_empresa.informacion_fiscal.ver === false
                    ? true
                    : false
                }
              />
            </Tabs>
          </Box>
        </DialogTitle>
        <RenderViews />
      </Dialog>
    </div>
  );
}
