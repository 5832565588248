import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import TablaVentasFiltradas from "./TablaPorVentasFiltradas";
import { formaPago } from "../../../Facturacion/catalogos";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import ErrorPage from "../../../../../components/ErrorPage";
import { ArrowBack, ClearOutlined, Close, Done } from "@material-ui/icons";
import ExportarVentas from "./ExportarPorVentas";
import { OBTENER_REPORTE_VENTAS_VENTA } from "../../../../../gql/Ventas/ventas_generales";
import ExportarRVPDF from "./ExportarPorRVPDF";
import CampoCajas from "./CampoCajas";
import {
  CustomCheckboxCanceladas,
  CustomCheckboxNotas,
} from "../CustomCheckBoxsStyles";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import "moment/locale/es-mx";
import ComponentOnline from "../../../../../components/Connection/ComponentOnline";

const initial_state_filtros = {
  fecha_inicio: "",
  fecha_fin: "",
  metodo_pago: "",
  forma_pago: "",
  cliente: "",
  usuario: "",
  folio: "",
  caja: "",
  tipo_emision: "",
  canceladas: false,
  facturadas: false,
  notas_credito: false,
  publico_general: false,
  vencidas: false,
  vigentes: false,
  liquidadas: false,
  concepto: "",
  cliente_id: "",
};

export default function FiltrosPorVenta({ handleCloseMain }) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [page, setPage] = useState(0);
  const [filtro, setFiltro] = useState(initial_state_filtros);

  const limit = 20;
  const [filtros] = useDebounce(filtro, 500);

  /* Queries */
  const { loading, data, error, refetch } = useQuery(
    OBTENER_REPORTE_VENTAS_VENTA,
    {
      variables: {
        empresa: sesion.empresa._id,
        sucursal: sesion.sucursal._id,
        filtros,
        limit,
        offset: page,
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      //pollInterval: 5000
    }
  );

  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="80vh"
      >
        <CircularProgress />
        <Typography variant="h6">Cargando...</Typography>
      </Box>
    );
  }

  if (error) {
    console.log(error);
    return <ErrorPage error={error} />;
  }

  const { obtenerVentasByVentaReportes } = data;

  return (
    <Fragment>
      <DialogContent>
        <CapturaDeDatos
          filtro={filtro}
          setFiltro={setFiltro}
          handleCloseMain={handleCloseMain}
          initial_state_filtros={initial_state_filtros}
        />
        <TablaVentasFiltradas
          data={obtenerVentasByVentaReportes}
          page={page}
          setPage={setPage}
          filtro={filtro}
          refetch={refetch}
          limit={limit}
        />
      </DialogContent>
    </Fragment>
  );
}

const CapturaDeDatos = ({
  filtro,
  setFiltro,
  handleCloseMain,
  initial_state_filtros,
}) => {
  const [fechas, setFechas] = useState({
    fecha_inicio: filtro.fecha_inicio,
    fecha_fin: filtro.fecha_fin,
  });
  const limpiarFiltros = () => {
    setFiltro(initial_state_filtros);
    setFechas({
      fecha_inicio: "",
      fecha_fin: "",
    });
  };

  const obtenerFechas = (name, value) => {
    setFechas({
      ...fechas,
      [name]: value,
    });
  };

  const cleanFechas = (e) => {
    e.stopPropagation();
    setFiltro({
      ...filtro,
      fecha_inicio: "",
      fecha_fin: "",
    });
  };

  const onFechaOK = (e) => {
    e.stopPropagation();
    const { fecha_inicio, fecha_fin } = fechas;
    setFiltro({
      ...filtro,
      fecha_inicio,
      fecha_fin,
    });
  };

  const obtenerCamposFiltro = (e) => {
    const { name, value } = e.target;
    setFiltro({
      ...filtro,
      [name]: value,
    });
  };

  const obtenerChecksFiltro = (e) => {
    const { name, checked } = e.target;
    if (name === "publico_general") {
      setFiltro({
        ...filtro,
        [name]: checked,
        cliente: "",
      });
      return;
    }
    setFiltro({
      ...filtro,
      [name]: checked,
    });
  };

  const obtenerChecksVencidas = (e) => {
    const { name, checked } = e.target;
    if (name === "vencidas") {
      setFiltro({
        ...filtro,
        [name]: checked,
        vigentes: false,
        liquidadas: false,
      });
    } else if (name === "liquidadas") {
      setFiltro({
        ...filtro,
        [name]: checked,
        vencidas: false,
        vigentes: false,
      });
    } else {
      setFiltro({
        ...filtro,
        [name]: checked,
        vencidas: false,
        liquidadas: false,
      });
    }
  };

  return (
    <Fragment>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={4} md={6} lg={4} style={{ display: "flex" }}>
          <Box width="80%">
            <Typography>Fecha desde:</Typography>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale="es-mx"
            >
              <DateTimePicker
                style={{ width: "100%" }}
                InputProps={{
                  style: {
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 0,
                  },
                }}
                inputVariant="outlined"
                placeholder="yyyy/MM/dd hh:mm a"
                variant="inline"
                size="small"
                name="fecha_inicio"
                value={fechas.fecha_inicio ? fechas.fecha_inicio : null}
                //onChange={(e) => setFechaVenta(e.format())}
                onChange={(e) => obtenerFechas("fecha_inicio", e.format())}
                format="yyyy/MM/dd hh:mm a"
                clearable="true"
              />
            </MuiPickersUtilsProvider>
            {/* <TextField
            fullWidth
            size="small"
            name="fecha_inicio"
            variant="outlined"
            type="date"
            onChange={obtenerCamposFiltro}
            value={filtro.fecha_inicio}
          /> */}
          </Box>
          <Box width="100%">
            <Typography>Fecha hasta:</Typography>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale="es-mx"
            >
              <DateTimePicker
                fullWidth
                variant="inline"
                placeholder="yyyy/MM/dd hh:mm a"
                inputVariant="outlined"
                size="small"
                name="fecha_fin"
                value={fechas.fecha_fin ? fechas.fecha_fin : null}
                //onChange={(e) => setFechaVenta(e.format())}
                onChange={(e) => obtenerFechas("fecha_fin", e.format())}
                format="yyyy/MM/dd hh:mm a"
                clearable="true"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={cleanFechas}>
                        <Close />
                      </IconButton>
                      <IconButton size="small" onClick={onFechaOK}>
                        <Done />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderBottomLeftRadius: 0, borderTopLeftRadius: 0 },
                }}
              />
            </MuiPickersUtilsProvider>
            {/* <TextField
            fullWidth
            size="small"
            name="fecha_fin"
            variant="outlined"
            type="date"
            onChange={obtenerCamposFiltro}
            value={filtro.fecha_fin}
          /> */}
          </Box>
        </Grid>
        <Grid item md={3} lg={2}>
          <Typography>Método de pago:</Typography>
          <FormControl variant="outlined" fullWidth size="small">
            <Select
              name="metodo_pago"
              onChange={obtenerCamposFiltro}
              value={filtro.metodo_pago}
            >
              <MenuItem value="">
                <em>Cualquier metodo</em>
              </MenuItem>
              <MenuItem value="PPD">{"Crédito"}</MenuItem>
              <MenuItem value="PUE">{"Contado"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} lg={2}>
          <Typography>Forma de pago:</Typography>
          <FormControl variant="outlined" fullWidth size="small">
            <Select
              name="forma_pago"
              onChange={obtenerCamposFiltro}
              value={filtro.forma_pago}
            >
              <MenuItem value="">
                <em>Cualquier forma</em>
              </MenuItem>
              {formaPago.map((res, index) => (
                <MenuItem key={index} value={res.Value}>
                  {res.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} lg={2}>
          <Typography>Cliente:</Typography>
          <TextField
            fullWidth
            size="small"
            name="cliente"
            variant="outlined"
            placeholder="Nombre cliente, clave cliente..."
            onChange={obtenerCamposFiltro}
            value={filtro.cliente}
          />
        </Grid>
        <Grid item md={3} lg={2}>
          <Typography>Usuario en caja:</Typography>
          <TextField
            fullWidth
            size="small"
            name="usuario"
            variant="outlined"
            placeholder="Nombre usuario, número usuario..."
            onChange={obtenerCamposFiltro}
            value={filtro.usuario}
          />
        </Grid>
        <Grid item md={3} lg={2}>
          <Typography>Folio:</Typography>
          <TextField
            fullWidth
            size="small"
            name="folio"
            variant="outlined"
            placeholder="folio venta"
            onChange={obtenerCamposFiltro}
            value={filtro.folio}
          />
        </Grid>
        <Grid item md={3} lg={1}>
          <CampoCajas setFiltro={setFiltro} filtro={filtro} />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <CustomCheckboxCanceladas
                checked={filtro.canceladas}
                onChange={obtenerChecksFiltro}
                name="canceladas"
                color="default"
              />
            }
            label="Canceladas"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <CustomCheckboxNotas
                checked={filtro.notas_credito}
                onChange={obtenerChecksFiltro}
                name="notas_credito"
                color="default"
              />
            }
            label="Notas crédito"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={filtro.vencidas}
                onChange={obtenerChecksVencidas}
                name="vencidas"
                color="default"
              />
            }
            label="Vencidas"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={filtro.vigentes}
                onChange={obtenerChecksVencidas}
                name="vigentes"
                color="default"
              />
            }
            label="Vigentes"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={filtro.liquidadas}
                onChange={obtenerChecksFiltro}
                name="liquidadas"
                color="default"
              />
            }
            label="Pagadas"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={filtro.facturadas}
                onChange={obtenerChecksFiltro}
                name="facturadas"
                color="default"
              />
            }
            label="Facturadas"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={filtro.publico_general}
                onChange={obtenerChecksFiltro}
                name="publico_general"
                color="default"
              />
            }
            label="Público general"
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        top={5}
        right={10}
      >
        {/* <Box display="flex" alignItems="center">
          <Box
            border={1}
            borderColor="#FF8A8A"
            bgcolor="#FFF4F4"
            height="20px"
            width="20px"
          />
          <Box mx={0.5} />
          <Typography>
            <b>Canceladas</b>
          </Typography>
          <Box mx={1} />
          <Box
            border={1}
            borderColor="#FCCF53"
            bgcolor="#FFFAEC"
            height="20px"
            width="20px"
          />
          <Box mx={0.5} />
          <Typography>
            <b>Notas de crédito</b>
          </Typography>
        </Box>
        <Box mx={1} /> */}
        <ComponentOnline classes />
        <Box mx={1} />
        <Button
          color="primary"
          startIcon={<ClearOutlined />}
          onClick={() => limpiarFiltros()}
        >
          Limpiar filtros
        </Button>
        <Box mx={1} />
        <ExportarVentas filtros={filtro} />
        <Box mx={1} />
        <ExportarRVPDF
          filtros={filtro}
          initial_state_filtros={initial_state_filtros}
        />
        <Box mx={1} />
        <IconButton
          variant="contained"
          color="primary"
          onClick={handleCloseMain}
        >
          <ArrowBack />
        </IconButton>
      </Box>
    </Fragment>
  );
};
