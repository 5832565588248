import React from "react";
import { ProdAlmContext } from "../Context/ProdAlmContext";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { RiFileExcel2Line } from "react-icons/ri";
import { Button } from "@material-ui/core";

export default function ExcelExport() {
  const { tableRef } = React.useContext(ProdAlmContext);

  return (
    <div>
      <DownloadTableExcel
        filename="Productos almacen CAFI"
        sheet="produtos almacen"
        currentTableRef={tableRef.current}
      >
        <Button
          variant="contained"
          size="small"
          disableElevation
          style={{ textTransform: "none" }}
          startIcon={
            <RiFileExcel2Line style={{ fontSize: 19, margin: "2px 0" }} />
          }
        >
          Exportar
        </Button>
      </DownloadTableExcel>
    </div>
  );
}
