import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { RiFileExcel2Line } from "react-icons/ri";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../../config/reuserFunctions";
import { formaPago, metodoPago } from "../../../Facturacion/catalogos";
import { useApolloClient } from "@apollo/client";
import { OBTENER_REPORTE_VENTAS_VENTA } from "../../../../../gql/Ventas/ventas_generales";
import { InfoOutlined } from "@material-ui/icons";
import moment from "moment";
import ExcelExportButton from "../../../../../components/ExcelExportButton";

export default function ExportarVentas({ filtros }) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState(false);
  const client = useApolloClient();

  /* Queries */
  const getProductos = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: OBTENER_REPORTE_VENTAS_VENTA,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          filtros,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoading(false);
      if (response.data) {
        setData(response.data.obtenerVentasByVentaReportes.docs);
        document.getElementById("export-excel-button-ventas").click();
      }
      if (response.error) setError(true);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    getProductos();
  };

  if (loading) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        Excel
      </Button>
    );
  }

  if (error) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        Excel
      </Button>
    );
  }

  return (
    <div>
      {data ? (
        <ExportarExcelAction datosExcel={data} />
      ) : (
        <Button
          variant="text"
          color="primary"
          startIcon={<RiFileExcel2Line />}
          onClick={handleClickOpen}
        >
          Excel
        </Button>
      )}
    </div>
  );
}

const ExportarExcelAction = ({ datosExcel }) => {
  const ventas = datosExcel.map((venta) => {
    const {
      folio,
      fecha_registro,
      cliente,
      id_caja,
      tipo_emision,
      saldo_credito_pendiente,
      credito,
      fecha_de_vencimiento_credito,
      usuario,
      status,
      factura,
      nota_credito,
      descuento,
      iva,
      ieps,
      subTotal,
      impuestos,
      total,
    } = venta;
    let forma_pago = "";
    let metodo_pago = "";
    if (venta.forma_pago && venta.metodo_pago) {
      const forma_pago_filtrada = formaPago.filter(
        (forma) => forma.Value === venta.forma_pago
      );
      const metodo_pago_filtrada = metodoPago.filter(
        (metodo) => metodo.Value === venta.metodo_pago
      );
      forma_pago = forma_pago_filtrada[0];
      metodo_pago = metodo_pago_filtrada[0];
    }

    const compra_realizada = {
      folio_venta: folio,
      fecha_registro: moment(fecha_registro)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY"),
      nombre_cliente:
        cliente !== null ? cliente.nombre_cliente : "Publico general",
      clave_cliente: cliente !== null ? cliente.clave_cliente : "-",
      numero_cliente: cliente !== null ? cliente.numero_cliente : "-",
      caja: id_caja.numero_caja,
      usuario_caja: usuario.nombre,
      tipo_emision: tipo_emision,
      venta_credito: credito ? "SI " : "NO",
      saldo_credito_pendiente: credito
        ? `$${
            saldo_credito_pendiente ? formatoMexico(saldo_credito_pendiente) : 0
          }`
        : "N/A",
      fecha_de_vencimiento_credito: moment(fecha_de_vencimiento_credito)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY"),
      forma_pago: `${forma_pago.Value} - ${forma_pago.Name}`,
      metodo_pago: credito ? "Crédito" : "Contado",
      nota_credito: nota_credito.length ? "SI" : "NO",
      facturada: factura.length ? "SI" : "NO",
      status,
      descuento: `$${descuento ? formatoMexico(descuento) : 0}`,
      iva: `$${iva ? formatoMexico(iva) : 0}`,
      ieps: `$${ieps ? formatoMexico(ieps) : 0}`,
      subtotal: `$${subTotal ? formatoMexico(subTotal) : 0}`,
      impuestos: `$${impuestos ? formatoMexico(impuestos) : 0}`,
      total: `$${total ? formatoMexico(total) : 0}`,
    };

    return compra_realizada;
  });
  return <ExcelExportButton data={ventas} name="Reporte de ventas" button id="export-excel-button-ventas"/>;
};
