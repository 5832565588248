import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Snackbar from "@material-ui/core/Snackbar";
import {
  Button,
  DialogActions,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import { Add, ArrowBack, ErrorOutline } from "@material-ui/icons";
import ErrorPage from "../../../../../../components/ErrorPage";
import { useQuery } from "@apollo/client";
import { OBTENER_CLIENTES } from "../../../../../../gql/Catalogos/clientes";
import { FacturacionCtx } from "../../../../../../context/Facturacion/facturacionCtx";
import CrearCliente from "../../../../Catalogos/Cliente/CrearCliente";
import { useDebounce } from "use-debounce";
import {
  getAddressCodes,
  getAddressString,
  getIdOrigenDestino,
} from "../../../NuevaFactura/initial_factura_states";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "45vh",
  },
}));

export default function Destinos() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filtro, setFiltro] = useState("");
  const [page, setPage] = React.useState(0);
  const [value] = useDebounce(filtro, 500);
  const { setComplementCartaPorte } = useContext(FacturacionCtx);
  const limit = 20;
  const [selected, setSelected] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /* Queries */
  const query = useQuery(OBTENER_CLIENTES, {
    variables: {
      tipo: "CLIENTE",
      filtros: { cliente: value },
      empresa: sesion.empresa._id,
      eliminado: false,
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  const handleSelectDestino = async () => {
    if (!selected.rfc || !selected.regimen_fiscal || !selected.razon_social) {
      setOpenAlert(true);
      return;
    }
    setLoading(true);
    const Domicilio = await getAddressCodes(selected.direccion);
    setComplementCartaPorte((st) => ({
      ...st,
      Complemento: {
        CartaPorte30: {
          ...st.Complemento.CartaPorte30,
          Ubicaciones: [
            ...st.Complemento.CartaPorte30.Ubicaciones,
            {
              Nombre: selected.nombre_cliente,
              TipoUbicacion: "Destino",
              IDUbicacion: getIdOrigenDestino("DE"),
              FechaHoraSalidaLlegada: "",
              DistanciaRecorrida: "",
              RFCRemitenteDestinatario: selected.rfc,
              Domicilio,
              AddresWithNames: selected.direccion,
            },
          ],
        },
      },
    }));
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <Button
        onClick={() => handleOpen()}
        size="small"
        startIcon={<Add />}
        style={{ textTransform: "none" }}
        color="primary"
      >
        Añadir
      </Button>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center" gridGap={10}>
            <IconButton color="primary" onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Selecionar destino</Typography>
            <Box flexGrow={1} />
            <TextField
              fullWidth
              size="small"
              placeholder="Buscar"
              variant="outlined"
              onChange={(e) => setFiltro(e.target.value)}
              value={filtro}
              style={{ width: 360 }}
            />
            <CrearCliente
              tipo="CLIENTE"
              accion="registrar"
              refetch={query.refetch}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            message="El cliente debe de tener RFC, RAZÓN SOCIAL y Régimen fiscal."
            autoHideDuration={2000}
          />
          <RenderListClientes
            query={query}
            page={page}
            setPage={setPage}
            limit={limit}
            selected={selected}
            setSelected={setSelected}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSelectDestino}
            size="small"
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading || !selected}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Seleccionar destino
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const RenderListClientes = ({
  query,
  page,
  setPage,
  limit,
  selected,
  setSelected,
}) => {
  const classes = useStyles();
  const { loading, data, error, refetch } = query;

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) {
    return <ErrorPage />;
  }

  const { obtenerClientes } = data;

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <Paper variant="outlined">
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 250 }}>Nombre</TableCell>
              <TableCell style={{ minWidth: 250 }}>Razon Social</TableCell>
              <TableCell style={{ minWidth: 150 }}>RFC</TableCell>
              <TableCell style={{ minWidth: 200 }}>Domicilio</TableCell>
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {obtenerClientes.docs.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  onClick={(e) => setSelected(row)}
                  selected={row._id === selected._id}
                >
                  <TableCell>{row.nombre_cliente}</TableCell>
                  <TableCell>
                    {row.razon_social ? (
                      row.razon_social
                    ) : (
                      <ErrorOutline fontSize="small" color="action" />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.rfc ? (
                      row.rfc
                    ) : (
                      <ErrorOutline fontSize="small" color="action" />
                    )}
                  </TableCell>
                  <TableCell>{getAddressString(row.direccion)}</TableCell>
                  <TableCell>
                    <CrearCliente
                      tipo="CLIENTE"
                      accion="actualizar"
                      datos={row}
                      refetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={obtenerClientes.totalDocs}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};
