import React from "react";
import Grid from "@material-ui/core/Grid";
import PreviewBanner from "./PreviewBanner";
import BannerTabs from "./BannerTabs";

export default function BannerPrincipal() {
  return (
    <Grid container spacing={2} style={{marginTop: 8}}>
      <Grid item md={8}>
        <PreviewBanner />
      </Grid>
      <Grid item md={4}>
        <BannerTabs />
      </Grid>
    </Grid>
  );
}
