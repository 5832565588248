import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import FiltrosFactura from "./FiltrosFactura";
import { DialogTitle, Divider, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { FcInspection } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: "0px 20px",
  },
  title: {
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FacturasRealizadas() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <FcInspection style={{fontSize: 100}} />
            </Box>
          </Box>
          CFDis realizados
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.appBar}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className={classes.title}>
              CFDis realizados
            </Typography>
            <IconButton color="primary" onClick={handleClose} size="large">
              <ArrowBack />
            </IconButton>
          </Box>
          <Divider />
        </DialogTitle>
        <FiltrosFactura />
      </Dialog>
    </div>
  );
}
