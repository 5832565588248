import React from "react";
import { Link, useRouteError } from "react-router-dom";
import { Box, Typography, Button } from "@material-ui/core";
import { ReplayOutlined } from "@material-ui/icons";

export default function NoMatch() {
  const error = useRouteError();
  console.error(error);

  return (
    <Box
      id="error-page"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div>
        <Typography variant="h1" align="center">
          Oops!
        </Typography>
        <Typography align="center">
          Lo sentimos, ha ocurrido un error.
        </Typography>
        <Typography align="center">
          <i>{error.statusText || error.message}</i>
        </Typography>
        <Box my={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<ReplayOutlined />}
            disableElevation
            component={Link}
            to="/ventas"
          >
            Recargar
          </Button>
        </Box>
      </div>
    </Box>
  );
}
