import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Barcode from "react-barcode";
import { Typography, Box, Divider } from "@material-ui/core";
import { formatoMexico } from "../../../../../config/reuserFunctions";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const EtiquetaBrotherDoble = ({ datosEtiqueta }) => {
  const options = {
    width: 1.1,
    height: 20,
    format: "CODE128",
    displayValue: true,
    fontOptions: "",
    font: "monospace",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 0,
    fontSize: 10,
    background: "#ffffff",
    lineColor: "#000000",
    margin: 1,
    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined,
  };
  const {
    size,
    nombre,
    medida,
    cantidad,
    unidad,
    precio,
    codigo,
  } = datosEtiqueta;
  const sizes = size.split("x");

  let newArray = [1, 2];

  const styles = {
    minWidth: "31mm",
    minHeight: sizes[1],
    padding: "9px 4px 9px 4px",
    margin: `0 ${0.1}mm 0 ${0.1}mm`,
  };

  const render_etiquetas = newArray.map((res) => {
    return (
      <Card variant="outlined" style={styles} key={res}>
        <CardContent style={{ padding: 0 }}>
          <Typography
            noWrap
            style={{
              lineHeight: 1,
              fontSize: 9,
            }}
          >
            {nombre}
          </Typography>
          <Box display="flex" justifyContent="center" my={0.3}>
            <Barcode value={codigo} {...options} />
          </Box>
          <Box display="flex" justifyContent="space-between">
            {medida ? (
              <Typography
                variant="button"
                style={{
                  lineHeight: "normal",
                  fontSize: 9,
                }}
              >
                {`${cantidad} ${unidad} Talla: ${medida.talla} ${medida.color}`}
              </Typography>
            ) : (
              <Typography
                variant="button"
                style={{
                  lineHeight: "normal",
                  fontSize: 9,
                }}
              >
                {`${cantidad} ${unidad}`}
              </Typography>
            )}
            <Typography
              variant="button"
              style={{
                lineHeight: "normal",
                fontSize: 9,
              }}
            >
              ${formatoMexico(precio)}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "62mm",
        height: sizes[1],
      }}
    >
      {render_etiquetas}
    </div>
  );
};
