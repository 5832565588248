import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { abrirCajonQuery } from "../../../components/Perifericos/Cajon/AbrirCajon";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { FaInbox } from "react-icons/fa";
import { CustomButton } from "../../../components/CustomMuiComponents";

const AbrirCajon = (props) => {
  const impresora = localStorage.getItem("cafiTicketPrint");
  const { ventaRapida, setAlert } = React.useContext(VentasContext);

  const abrirCajon = async () => {
    const result = await abrirCajonQuery(impresora);
    if (result.success === true) {
      setAlert({
        message: `Cajon abierto`,
        status: "success",
        open: true,
      });
    } else {
      setAlert({
        message: `Error al abrir cajon: ${result.message}`,
        status: "error",
        open: true,
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.keyCode === 119) {
        abrirCajon();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, []);

  if (ventaRapida) {
    return (
      <CustomButton
        {...props}
        className="button-panel-venta b-secondary"
        fullWidth
        size="large"
        variant="outlined"
        startIcon={<FaInbox className="icon-btn-secondary" />}
        onClick={abrirCajon}
      >
        Cajon
      </CustomButton>
    );
  } else {
    return (
      <Button
        {...props}
        className="button-panel-venta b-secondary"
        fullWidth
        variant="outlined"
        onClick={abrirCajon}
      >
        <Box>
          <FaInbox className="icon-btn-secondary" />
          <Typography className="text-btn-panel">Abrir Cajon</Typography>
          <Typography className="text-btn-panel-sec">F8</Typography>
        </Box>
      </Button>
    );
  }
};
AbrirCajon.muiName = Button.muiName;
export default AbrirCajon;
