import React from "react";
import { FormControl, Select, MenuItem, Typography } from "@material-ui/core";
import facturamaApi from "../../../../billing/Facturama/facturama.api";

export default function RegimenFiscalInput({ state, setState }) {
  const [loading, setLoading] = React.useState(false);
  const [regimens, setRegimens] = React.useState([]);
  const obtenerCampos = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    const obtenerRegimenFiscales = async () => {
      try {
        setLoading(true);
        const result = await facturamaApi.Catalogs.FiscalRegimens(function (
          result
        ) {
          return result;
        });
        setLoading(false);
        if (result) setRegimens(result);
      } catch (error) {
        setLoading(false);
      }
    };
    obtenerRegimenFiscales();
  }, []);

  if (loading)
    return (
      <div>
        <Typography>
          <b>Régimen fiscal</b>
        </Typography>
        <FormControl variant="outlined" fullWidth size="small">
          <Select value="loading" fullWidth>
            <MenuItem value="loading">
              <em>Cargando...</em>
            </MenuItem>
          </Select>
        </FormControl>
      </div>
    );

  return (
    <div>
      <Typography>
        <b>Régimen fiscal</b>
      </Typography>
      <FormControl
        variant="outlined"
        fullWidth
        size="small"
        name="regimen_fiscal"
      >
        <Select
          value={state.regimen_fiscal ? state.regimen_fiscal : ""}
          name="regimen_fiscal"
          onChange={obtenerCampos}
          fullWidth
        >
          <MenuItem value="">
            <em>Selecciona uno</em>
          </MenuItem>
          {regimens.map((res, index) => (
            <MenuItem
              key={index}
              value={res.Value}
            >{`${res.Value} - ${res.Name}`}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
