import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Barcode from "react-barcode";
import { Typography, Box } from "@material-ui/core";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import { EtiquetaMultiple } from "./EtiquetaMultiple";
import { EtiquetaBrotherDoble } from "./EtiquetaBrotherDoble";

// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)
// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const PreviewEtiqueta = React.forwardRef((props, ref) => {
  const { producto, datosEtiqueta } = props;

  const options = {
    width: datosEtiqueta.size === "27mmx13mm" ? 0.8 : 1.8,
    height: datosEtiqueta.size === "27mmx13mm" ? 13 : 40,
    format: "CODE128",
    displayValue: true,
    fontOptions: "",
    font: "monospace",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 0,
    fontSize: datosEtiqueta.size === "27mmx13mm" ? 10 : 16,
    background: "#ffffff",
    lineColor: "#000000",
    margin: datosEtiqueta.size === "27mmx13mm" ? 2 : 6,
    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined,
  };

  const sizes = datosEtiqueta.size.split("x");

  const styles = {
    width: sizes[0],
    height: sizes[1],
    padding: 0,
  };

  if (datosEtiqueta.size !== "27mmx13mm") {
    if (sizes.length > 2) {
      return (
        <div id={`id-etiqueta-${producto._id}`} ref={ref}>
          <EtiquetaBrotherDoble datosEtiqueta={datosEtiqueta} />
        </div>
      );
    } else {
      return (
        <div id={`id-etiqueta-${producto._id}`} ref={ref}>
          <Card variant="outlined" style={styles}>
            <CardContent style={{ padding: 4 }}>
              <Typography
                variant="button"
                noWrap
                style={{
                  lineHeight: 0,
                  fontSize: 13,
                }}
              >
                {datosEtiqueta.nombre}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                {datosEtiqueta.medida ? (
                  <Typography
                    variant="button"
                    style={{
                      lineHeight: "normal",
                      fontSize: 13,
                    }}
                  >
                    {`${datosEtiqueta.cantidad} ${datosEtiqueta.unidad} Talla: ${datosEtiqueta.medida.talla} ${datosEtiqueta.medida.color}`}
                  </Typography>
                ) : (
                  <Typography
                    variant="button"
                    style={{
                      lineHeight: "normal",
                      fontSize: 13,
                    }}
                  >
                    {`${datosEtiqueta.cantidad} ${datosEtiqueta.unidad}`}
                  </Typography>
                )}
                <Typography
                  variant="button"
                  style={{
                    lineHeight: "normal",
                    fontSize: 13,
                  }}
                >
                  ${formatoMexico(datosEtiqueta.precio)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Barcode value={datosEtiqueta.codigo} {...options} />
              </Box>
            </CardContent>
          </Card>
        </div>
      );
    }
  } else {
    return (
      <div id={`id-etiqueta-${producto._id}`} ref={ref}>
        <EtiquetaMultiple datosEtiqueta={datosEtiqueta} />
      </div>
    );
  }
});
