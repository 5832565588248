import React from "react";
import { useApolloClient } from "@apollo/client";
import { TraspasosContext } from "../Context/TraspasosContext";
import { formatoFechaCorta } from "../../../../../config/reuserFunctions";
import { GetTraspasos } from "../graphql/queries";
import { MainContext } from "../../../../../context/MainCtx";
import ExcelExportButton from "../../../../../components/ExcelExportButton";

const formatTable = (data = []) => {
  const dataFormat = [];
  data.forEach((trasp) => {
    const {
      _id,
      productos,
      fecha_registro,
      almacen_origen,
      almacen_destino,
      sucursalOrigen,
      sucursalDestino,
      concepto_traspaso,
      usuario,
      traspasoEntreSucursales,
      aceptado,
      ...obj
    } = trasp;
    dataFormat.push({
      ...obj,
      FechaRegistro: formatoFechaCorta(fecha_registro),
      Usuario: usuario?.nombre  || "",
      //EntreSucursales: traspasoEntreSucursales ? "SI" : "NO",
      EntreSucursales: traspasoEntreSucursales ? aceptado ? "SI" : "NO" : "N/A",
      Concepto: concepto_traspaso?.nombre_concepto || "",
      AlmacenOrigen: almacen_origen?.nombre_almacen || "",
      SucursalOrigen: sucursalOrigen?.nombre_sucursal || "",
      AlmacenDestino: almacen_destino?.nombre_almacen || "",
      SucursalDestino: sucursalDestino?.nombre_sucursal || "",
    });
  });
  return dataFormat;
};

export default function ExcelExport() {
  const { traspasosQuery, filter } = React.useContext(TraspasosContext);
  const { snackMessage } = React.useContext(MainContext);
  const excelData = formatTable(traspasosQuery?.data?.getTraspasos?.docs);
  const [loading, setLoading] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getTraspasos = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetTraspasos,
        variables: {
          input: filter,
        },
        fetchPolicy: "network-only",
      });
      setLoading(false);
      //console.log(response)
      if (response.data) {
        return formatTable(response.data.getTraspasos.docs);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  return (
    <div>
      <ExcelExportButton
        data={excelData}
        name="Traspasos"
        getQueryData={getTraspasos}
        loading={loading}
      />
    </div>
  );
}
