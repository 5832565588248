import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TraspasosCtxProvider, {
  TraspasosContext,
} from "./Context/TraspasosContext";
import SearchComponent from "./Actions/Search";
import TraspasosTable from "./Table";
import Export from "./Actions/Export";
import CreateTraspaso from "./Actions/Create";
import { ArrowBack } from "@material-ui/icons";
//import { FcExport } from "react-icons/fc";
import { IconButton } from "@material-ui/core";
import CustomLoader from "../../../../components/CustomLoader";
import ErrorMessage from "../../../../components/ErrorMessage";
import SyncDB from "../../../../components/SyncDB";
import { FcWorkflow } from "react-icons/fc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TraspasosIndex() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Button fullWidth onClick={handleClickOpen}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <FcWorkflow style={{ fontSize: 100 }} />
            </Box>
            Traspasos
          </Box>
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle component="div">
            <Box display="flex">
              <IconButton color="primary" size="small" onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Box m={1} />
              <Typography variant="h6">
                Traspasos
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <TraspasosCtxProvider>
              <TraspasosContent />
            </TraspasosCtxProvider>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

const TraspasosContent = () => {
  const { traspasosQuery } = React.useContext(TraspasosContext);
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          position: { sm: "absolute" },
          top: 16,
          right: 24,
          gridGap: 10,
        }}
        bgcolor="background.paper"
      >
        <Box mx={2}>
          <SyncDB refetch={traspasosQuery.refetch} />
        </Box>
        <SearchComponent />
        <CreateTraspaso />
        <Export />
      </Box>
      <RenderTable />
    </Box>
  );
};

const RenderTable = () => {
  const { traspasosQuery } = React.useContext(TraspasosContext);

  if (traspasosQuery.loading) return <CustomLoader height="60vh" />;
  if (traspasosQuery.error)
    return <ErrorMessage message={traspasosQuery.error?.message} />;

  return <TraspasosTable />;
};
