import { Impresora } from "../../../components/Perifericos/TicketPrinter/Impresora";
import { formatoMexico } from "../../../config/reuserFunctions";
import { formaPago } from "../../sucursales/Facturacion/catalogos";
import moment from "moment-timezone";
import table from "text-table";
const RUTA_API = "http://localhost:8000";

export const rePrintTicketVenta = async (datos) => {

  try {
    const { venta, sesion } = datos;
    //sacar la forma de pago
    let forma_pago = "";
    let monto_pagado = 0;
    formaPago.forEach((element) => {
      if (venta.forma_pago === element.Value) forma_pago = element.Name;
    });
    if (!venta.credito) {
      Object.keys(venta.montos_en_caja).forEach((key) => {
        if (venta.montos_en_caja[key].monto > monto_pagado) {
          monto_pagado = venta.montos_en_caja[key].monto;
        }
      });
    }
    // obtener direccion
    let address = "";
    let municipio = "";
    let pais = "";
    const { direccion } = sesion.sucursal;
    if (
      direccion &&
      direccion.calle &&
      direccion.no_ext &&
      direccion.municipio &&
      direccion.estado &&
      direccion.pais
    ) {
      address = `${direccion.calle} #${direccion.no_ext}`;
      municipio = `${direccion.municipio}, ${direccion.estado}`;
      pais = direccion.pais;
    }

    let tableHead = table(
      [["Articulo      ", "Cantidad", "Precio", "Importe"]],
      { align: ["l", "r"] }
    );

    let tableBody = [{ align: ["l", "r"] }];

    let tableTotal = table(
      [
        [`SUBTOTAL:  `, `${formatoMexico(venta.subTotal)}`],
        [`IMPUESTOS:  `, `${formatoMexico(venta.impuestos)}`],
        [`TOTAL:  `, `${formatoMexico(venta.total)}`],
      ],
      { align: ["l", "r"] }
    );
    let tablePago = table(
      [
        [`Pago con: ${forma_pago}`, `${formatoMexico(monto_pagado)}`],
        [`Su cambio:  `, `${formatoMexico(venta.cambio)}`],
      ],
      { align: ["l", "r"] }
    );

    let tableVentaCredito = table(
      [
        [`Venta a credito`],
        [
          `Fecha Vencimiento credito: ${moment(venta.fecha_de_vencimiento_credito)
            .tz("America/Mexico_City")
            .format("DD/MM/YYYY")}`,
        ],
        [`Abono minimo: ${formatoMexico(venta.abono_minimo)}`],
      ],
      { align: ["l", "r"] }
    );

    venta.productos.forEach((res) => {
      const { nombre_comercial } = res.id_producto.datos_generales;
      const { color, medida } = res;
      let nombre = nombre_comercial;
      if (color || medida) {
        if ((color.nombre || color.color) && (medida.talla || medida.medida)) {
          nombre = `${nombre_comercial} T-${medida.talla || medida.medida} ${color.nombre || color.color}`;
        }
      }
      tableBody.push([
        `${nombre}`,
        `${res.cantidad_venta} ${res.unidad}`,
        ` ${formatoMexico(res.precio_actual_object.precio_neto)}`,
        `${formatoMexico(res.total)}`,
      ]);
    });

    tableBody = table(tableBody);

    let impresora = new Impresora(RUTA_API);
    impresora.cutPartial();
    impresora.setFontSize(1, 1);
    impresora.setEmphasize(0);
    impresora.write("Punto de venta CAFI\n");
    impresora.write(`RFC ${sesion.empresa.rfc}\n`);
    impresora.write(`Regimen fiscal ${sesion.empresa.regimen_fiscal}\n`);
    impresora.write(`Domicilio: ${address}\n`);
    impresora.write(`${municipio}\n`);
    impresora.write(`${pais}\n`);
    impresora.write(
      `Fecha: ${moment()
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY")} Hora: ${moment()
          .tz("America/Mexico_City")
        .format("LTS")}\n`
    );
    impresora.feed(1);
    impresora.write("****Ticket de venta****\n");
    impresora.write("***Este ticket es una reimpresion*** \n");
    impresora.write(`Caja ${venta.id_caja.numero_caja} Cajero: ${venta.usuario.nombre}\n`);
    impresora.write(`Folio de venta: ${venta.folio}\n`);
    impresora.write(
      `Fecha de venta: ${moment(venta.fecha_registro)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY")}\n`
    );
    impresora.feed(1);
    impresora.write("=======================================\n");
    impresora.setAlign("right");
    impresora.write(`${tableHead}\n`);
    impresora.write("_______________________________________\n");
    impresora.write(`${tableBody}\n`);
    impresora.write("----------------------------------------\n");
    impresora.write(`${tableTotal}\n`);
    impresora.write("----------------------------------------\n");
    impresora.write(`${tablePago}\n`);
    if (venta.credito) {
      impresora.feed(1);
      impresora.write(`${tableVentaCredito}\n`);
    }
    impresora.write("========================================\n");
    impresora.feed(1);
    impresora.setAlign("center");
    impresora.write("***Gracias por su compra***");
    impresora.feed(3);
    impresora.cut();
    /* impresora.cutPartial(); // Pongo este y también cut porque en ocasiones no funciona con cut, solo con cutPartial */

    await impresora.end();
    return { success: true, error: false, message: "OK" };
  } catch (error) {
    console.log(error);
    return { success: false, error: true, message: error };
  }
};
