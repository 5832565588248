import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import SnackBarMessages from "../../../../../../components/SnackBarMessages";
import { DELETE_TRANSPORTISTA } from "../../../../../../gql/Facturacion/CartaPorte";

export default function EliminarTransportista({ idTransportista, refetch }) {
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [eliminarTransportista] = useMutation(DELETE_TRANSPORTISTA);

  const deleteTransportista = async () => {
    try {
      setLoading(true);
      await eliminarTransportista({
        variables: {
          idTransportista,
        },
      });
      refetch();
      setAlert({ message: "¡Listo!", status: "success", open: true });
      setLoading(false);
      handleClose();
    } catch (error) {
      setLoading(false);
      handleClose();
      if (error.message) {
        setAlert({ message: error.message, status: "error", open: true });
        return;
      }
      setAlert({ message: "Hubo un error", status: "error", open: true });
    }
  };

  return (
    <div>
      <IconButton
        size="small"
        onClick={() => handleClickOpen()}
        color="secondary"
      >
        <Delete />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <SnackBarMessages alert={alert} setAlert={setAlert} />
        <DialogTitle>{"¿Seguro que quieres eliminar esto?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button
            color="secondary"
            autoFocus
            variant="contained"
            disableElevation
            disabled={loading}
            onClick={() => deleteTransportista()}
            startIcon={
              loading ? <CircularProgress color="inherit" size={20} /> : null
            }
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
