import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { Close, Search } from "@material-ui/icons";
import ExportarExcelAbonos from "./ExportarExcelClientes";
import {
  OBTENER_ABONOS_ESTADO_DE_CUENTA,
  OBTENER_CLIENTES_ESTADO_DE_CUENTA,
  OBTENER_VENTAS_ESTADO_DE_CUENTA,
} from "../../../../../gql/Tesoreria/abonos";
import ErrorPage from "../../../../../components/ErrorPage";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import TablaEstadoDeCuentaClientes from "./TablaEstadoDeCuentaClientes";
import ExportarPDF from "./ExportPDF";
import ComponentOnline from "../../../../../components/Connection/ComponentOnline";
import { CustomSearchTextField } from "../../../../../components/CustomMuiComponents";

const initial_state_filtro = {
  cliente: "",
};

const initial_state_filtro_venta = {
  fecha_inicio: "",
  fecha_fin: "",
  folio: "",
};

export default function ReportesAbonos({ handleClickOpen }) {
  const [openVista, setOpenVista] = useState("Clientes");
  const [datosFiltro, setDatosFiltro] = useState(initial_state_filtro);
  const [value] = useDebounce(datosFiltro, 1000);
  const [datosCliente, setDatosCliente] = useState([]);
  const [datosVenta, setDatosVenta] = useState([]);
  const [loadingCliente, setLoadingCliente] = useState(false);
  const [errorCliente, setErrorCliente] = useState(false);
  const [loadingVenta, setLoadingVenta] = useState(false);
  const [errorVenta, setErrorVenta] = useState(false);
  const [loadingAbono, setLoadingAbono] = useState(false);
  const [errorAbono, setErrorAbono] = useState(false);
  const [clienteID, setClienteID] = useState("");
  let arrayExcel = {};
  const [filtrosVenta, setFiltrosVenta] = useState(initial_state_filtro_venta);
  const [filtros] = useDebounce(filtrosVenta, 1000);
  const [cliente, setCliente] = useState([]);
  const [venta, setVenta] = useState([]);
  const [abonos, setAbonos] = useState([]);
  const [clienteNombre, setClienteNombre] = useState("");
  const [ventaID, setVentaID] = useState("");
  const [datosExcel, setDatosExcel] = useState([]);
  const [offsetClientes, setOffsetClientes] = useState(0);
  const [offsetVentas, setOffsetVentas] = useState(0);
  const [docsCliente, setDocsCliente] = useState(0);
  const [docsVenta, setDocsVenta] = useState(0);
  const [saldoTotalPendiente, setSaldoTotalPendiente] = useState(0);
  const limitClientes = 25;
  const limitVentas = 25;

  const limpiarDatos = () => {
    setDatosFiltro(initial_state_filtro);
    setFiltrosVenta(initial_state_filtro_venta);
    setOffsetClientes(0);
    setOffsetVentas(0);
  };

  useEffect(() => {
    limpiarDatos();
  }, [openVista]);

  useEffect(() => {
    getClientesEstadoDeCuenta();
  }, [offsetClientes]);

  useEffect(() => {
    if (clienteID !== "") {
      getVentasEstadoDeCuenta();
    }
  }, [offsetVentas]);

  const handleClickOpenCliente = () => {
    setOpenVista("Clientes");
    setDatosExcel([]);
  };

  const handleClickOpenVenta = (cliente) => {
    setOpenVista("Venta");
    if (cliente._id) {
      setDatosCliente(cliente);
      setClienteID(cliente._id);
      setClienteNombre(cliente.nombre_cliente);
    }
    setDatosExcel([]);
  };

  const handleClickOpenAbonos = (venta) => {
    setOpenVista("Abonos");
    if (venta._id) {
      setDatosVenta(venta);
      setVentaID(venta._id);
    }
    setDatosExcel([]);
  };

  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setDatosFiltro({
      ...datosFiltro,
      [name]: value,
    });
  };

  const obtenerCamposVenta = (e) => {
    const { name, value } = e.target;
    setFiltrosVenta({
      ...filtrosVenta,
      [name]: value,
    });
  };

  const client = useApolloClient();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const getClientesEstadoDeCuenta = async () => {
    try {
      setLoadingCliente(true);
      const response = await client.query({
        query: OBTENER_CLIENTES_ESTADO_DE_CUENTA,
        variables: {
          empresa: sesion.empresa._id,
          filtro: datosFiltro.cliente,
          limit: limitClientes,
          offset: offsetClientes,
        },
        fetchPolicy: "network-only",
      });
      setLoadingCliente(false);

      if (response.data) {
        setCliente(response.data.getClientesEstadoCuenta.docs);
        setDocsCliente(response.data.getClientesEstadoCuenta.totalDocs);
      }

      if (response.error) setErrorCliente(true);
    } catch (error) {
      setLoadingCliente(false);
      setErrorCliente(true);
      console.log(error.networkError.result);
    }
  };

  const getVentasEstadoDeCuenta = async () => {
    try {
      setLoadingVenta(true);
      const response = await client.query({
        query: OBTENER_VENTAS_ESTADO_DE_CUENTA,
        variables: {
          cliente: clienteID,
          filtro: filtros,
          limit: limitVentas,
          offset: offsetVentas,
        },
        fetchPolicy: "network-only",
      });
      setLoadingVenta(false);

      if (response.data) {
        setVenta(response.data.getVentasEstadoCuenta.docs);
        setDocsVenta(response.data.getVentasEstadoCuenta.totalDocs);
        setSaldoTotalPendiente(
          response.data.getVentasEstadoCuenta.saldoPendienteTotal
        );
      }

      if (response.error) setErrorVenta(true);
    } catch (error) {
      setLoadingVenta(false);
      setErrorVenta(true);
      console.log(error);
    }
  };

  const getAbonosEstadoDeCuenta = async () => {
    try {
      setLoadingAbono(true);
      const response = await client.query({
        query: OBTENER_ABONOS_ESTADO_DE_CUENTA,
        variables: {
          id: ventaID,
          tipo: "id_venta",
        },
        fetchPolicy: "network-only",
      });
      setLoadingAbono(false);

      if (response.data) {
        setAbonos(response.data.getAbonosEstadoCuenta);
        setDatosExcel(response.data.getAbonosEstadoCuenta);
      }

      if (response.error) setErrorAbono(true);
    } catch (error) {
      setLoadingAbono(false);
      setErrorAbono(true);
      console.log(error);
    }
  };

  useEffect(() => {
    setOffsetClientes(0);
    setOffsetVentas(0);
    getClientesEstadoDeCuenta();
  }, [value]);

  useEffect(() => {
    if (clienteID !== "") {
      getVentasEstadoDeCuenta();
      setOffsetClientes(0);
      setOffsetVentas(0);
    }
  }, [clienteID, filtros]);

  useEffect(() => {
    if (ventaID !== "") {
      getAbonosEstadoDeCuenta();
    }
  }, [ventaID]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        top={10}
        right={20}
        gridGap={10}
      >
        <ComponentOnline />
        {openVista === "Clientes" ? (
          <CustomSearchTextField
            placeholder="Cliente"
            onChange={(e) => obtenerCampos(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={limpiarDatos}>
                    <Close fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={datosFiltro.cliente}
            variant="outlined"
            size="small"
            name="cliente"
          />
        ) : null}
        <ExportarExcelAbonos
          openVista={openVista}
          arrayExcel={arrayExcel}
          datosExcel={datosExcel}
          setDatosExcel={setDatosExcel}
          datosFiltro={datosFiltro}
          clienteID={clienteID}
          filtros={filtros}
        />
        {openVista === "Venta" ? (
          <ExportarPDF
            datosCliente={datosCliente}
            clienteID={clienteID}
            filtros={filtros}
            filtrosVenta={filtrosVenta}
          />
        ) : null}
        <Button
          variant="contained"
          color="default"
          disableElevation
          size="small"
          onClick={handleClickOpen}
          startIcon={<Close />}
        >
          Cerrar
        </Button>
      </Box>
      <ClientesRender
        openVista={openVista}
        datosCliente={datosCliente}
        datosVenta={datosVenta}
        handleClickOpenCliente={handleClickOpenCliente}
        handleClickOpenVenta={handleClickOpenVenta}
        handleClickOpenAbonos={handleClickOpenAbonos}
        cliente={cliente}
        loadingCliente={loadingCliente}
        loadingVenta={loadingVenta}
        errorCliente={errorCliente}
        errorVenta={errorVenta}
        venta={venta}
        clienteNombre={clienteNombre}
        abonos={abonos}
        loadingAbono={loadingAbono}
        errorAbono={errorAbono}
        limitClientes={limitClientes}
        offsetClientes={offsetClientes}
        setOffsetClientes={setOffsetClientes}
        limitVentas={limitVentas}
        offsetVentas={offsetVentas}
        setOffsetVentas={setOffsetVentas}
        docsVenta={docsVenta}
        docsCliente={docsCliente}
        saldoTotalPendiente={saldoTotalPendiente}
        obtenerCamposVenta={obtenerCamposVenta}
        filtrosVenta={filtrosVenta}
        limpiarDatos={limpiarDatos}
      />
    </>
  );
}

const ClientesRender = ({
  openVista,
  datosCliente,
  datosVenta,
  handleClickOpenCliente,
  handleClickOpenVenta,
  handleClickOpenAbonos,
  cliente,
  loadingCliente,
  loadingVenta,
  errorCliente,
  errorVenta,
  venta,
  clienteNombre,
  abonos,
  loadingAbono,
  errorAbono,
  limitClientes,
  offsetClientes,
  setOffsetClientes,
  limitVentas,
  offsetVentas,
  setOffsetVentas,
  docsVenta,
  docsCliente,
  saldoTotalPendiente,
  obtenerCamposVenta,
  filtrosVenta,
  limpiarDatos,
}) => {
  if (loadingCliente || loadingVenta || loadingAbono) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (errorCliente || errorVenta || errorAbono) {
    return (
      <ErrorPage
        error={
          errorCliente ? errorCliente : errorVenta ? errorVenta : errorAbono
        }
      />
    );
  }

  return (
    <>
      <TablaEstadoDeCuentaClientes
        openVista={openVista}
        datosCliente={datosCliente}
        datosVenta={datosVenta}
        handleClickOpenCliente={handleClickOpenCliente}
        handleClickOpenVenta={handleClickOpenVenta}
        handleClickOpenAbonos={handleClickOpenAbonos}
        cliente={cliente}
        venta={venta}
        clienteNombre={clienteNombre}
        abonos={abonos}
        limitClientes={limitClientes}
        offsetClientes={offsetClientes}
        setOffsetClientes={setOffsetClientes}
        limitVentas={limitVentas}
        offsetVentas={offsetVentas}
        setOffsetVentas={setOffsetVentas}
        docsVenta={docsVenta}
        docsCliente={docsCliente}
        saldoTotalPendiente={saldoTotalPendiente}
        obtenerCamposVenta={obtenerCamposVenta}
        filtrosVenta={filtrosVenta}
        limpiarDatos={limpiarDatos}
      />
    </>
  );
};
