import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/CardMedia";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  ArrowBack,
  ArrowForward,
  HomeOutlined,
  Menu,
  Replay,
} from "@material-ui/icons";
import { TiendaCtx } from "../../../../../context/Empresa/tiendaCtx";
import Banner from "./BannerPrincipal/BannerItems";
import PresetsCards from "./CustomBanner/Presets/PresetsCards";
import { Container, ThemeProvider, createTheme } from "@material-ui/core";

export default function PreviewBanner() {
  const { data, color } = React.useContext(TiendaCtx);
  const theme = createTheme({
    palette: {
      primary: {
        main: color.primary.hex,
      },
      secondary: {
        main: color.secondary.hex,
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Paper>
        <Box style={{ height: "45px" }}>
          <Box display="flex">
            <Box
              m={0.4}
              height={12}
              width={12}
              borderRadius="50%"
              bgcolor="orangered"
            />
            <Box
              m={0.4}
              height={12}
              width={12}
              borderRadius="50%"
              bgcolor="orange"
            />
            <Box
              m={0.4}
              height={12}
              width={12}
              borderRadius="50%"
              bgcolor="lightGreen"
            />
          </Box>
          <Box display="flex" p={0.4} alignItems="center">
            <ArrowBack
              color="action"
              style={{ fontSize: 18, margin: "0px 4px" }}
            />
            <ArrowForward
              color="action"
              style={{ fontSize: 18, margin: "0px 4px" }}
            />
            <Replay
              color="action"
              style={{ fontSize: 18, margin: "0px 4px" }}
            />
            <HomeOutlined
              color="action"
              style={{ fontSize: 18, margin: "0px 4px" }}
            />
            <Box
              height={20}
              width="80%"
              border={1}
              borderColor="#d6d6d6"
              style={{
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
              }}
            />
          </Box>
        </Box>
        <Divider />
        <AppBar position="static">
          <Toolbar variant="dense">
            <Menu style={{ fontSize: 15, marginRight: 8 }} />
            <Typography variant="subtitle2" style={{ flexGrow: 1 }}>
              Mi tienda
            </Typography>
            <Typography variant="button" style={{ fontSize: 13 }}>
              LOGIN
            </Typography>
          </Toolbar>
        </AppBar>
        <Box position="relative">
          <Banner />
        </Box>
        {data && data.customBanners ? (
          <Box my={2} px={4}>
            <Container maxWidth="md">
              {data.customBanners.map((res, index) => (
                <PresetsCards key={index} banner={res} preview={true} />
              ))}
            </Container>
          </Box>
        ) : null}
      </Paper>
    </ThemeProvider>
  );
}
