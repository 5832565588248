import React from "react";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { GetApp } from "@material-ui/icons";
import moment from "moment";
import DOMPurify from "dompurify";
import { CircularProgress } from "@material-ui/core";

let electron = undefined;
if (window.require) {
  electron = window.require("electron");
}

export default function ItemDownload({
  datos,
  setProgress,
  clicked,
  setClicked,
}) {
  const [status, setStatus] = React.useState({
    downloaded: "0",
    downloadedBytes: 0,
    progress: 0,
    remaining: "0",
    remainingBytes: 0,
    speed: "0 Bytes/sec",
    speedBytes: 0,
    total: "0",
    totalBytes: 0,
  });
  const [loading, setLoading] = React.useState(false);
  const { downloaded, progress, speed, total } = status;

  if (!electron) return null;

  const download = () => {
    try {
      setLoading(true);
      setClicked(datos._id);
      electron.ipcRenderer.send("download-button", datos.link);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  electron.ipcRenderer.on("progress-file", (event, progress) => {
    if (clicked === datos._id) {
      setStatus(progress);
      setProgress(progress.progress);
      if(loading && progress.progress) setLoading(false);
    }
  });

  return (
    <Box my={2} width={450} p={2}>
      <Box display="flex" justifyContent="space-between">
        <Typography style={{ fontSize: 18 }}>
          <strong>{datos.title}</strong>
        </Typography>
        <Typography color="textSecondary">
          {moment(datos.launch_date).format("L")}
        </Typography>
      </Box>
      <Typography
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(datos.description),
        }}
      />
      <Box my={2}>
        <Button
          startIcon={loading ? (<CircularProgress size={20} color="inherit" />) : <GetApp />}
          color="primary"
          size="small"
          onClick={() => download()}
        >
          Descargar instalador
        </Button>
      </Box>
      {progress ? (
        <Box>
          <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(
                progress
              )}%`}</Typography>
            </Box>
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
          >{`${downloaded}/${total} - ${speed}`}</Typography>
        </Box>
      ) : null}{" "}
      <Divider />
    </Box>
  );
}
