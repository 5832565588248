import React from "react";
import { useApolloClient } from "@apollo/client";
import { GetLastSesion } from "../../../gql/Cajas/cajas";
import { Typography } from "@material-ui/core";
import { MainContext } from "../../../context/MainCtx";
import { io } from "socket.io-client";

export default function GetLastTurno({ setLoading, loading }) {
  let sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const client = useApolloClient();
  const {
    snackMessage,
    setTurnoEnCurso,
    socketioServer,
    socketioLocal,
    isWeb
  } = React.useContext(MainContext);

  const getLastSesion = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: GetLastSesion,
        variables: { usuario: sesion._id, sucursal: sesion.sucursal._id },
        fetchPolicy: "network-only",
      });
      setLoading(false);
      const { getLastSesion } = response.data;
      if (getLastSesion === null) return;
      if (getLastSesion.concepto === "ABRIR TURNO") {
        //if es abrir turno, setear turno obtenido
        const turno = {
          ...getLastSesion,
          usuario_en_turno: {
            numero_usuario: getLastSesion.usuario_en_turno.numero_usuario,
            nombre: getLastSesion.usuario_en_turno.nombre,
          },
          onCloud: true,
        };

        setTurnoEnCurso(turno);
        localStorage.setItem(
          "sesionCafi",
          JSON.stringify({ ...sesion, turno_en_caja_activo: true })
        );
      } else {
        //if es cerrar turno, setear turno a null (remover turno)
        setTurnoEnCurso(null);
        localStorage.removeItem("ListaEnEspera");
        localStorage.removeItem("DatosVentas");
        localStorage.removeItem("VentaOriginal");
        localStorage.setItem(
          "sesionCafi",
          JSON.stringify({ ...sesion, turno_en_caja_activo: false })
        );
      }
    } catch (error) {
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  React.useEffect(() => {
    getLastSesion();
  }, []);

  React.useEffect(() => {
    const socket1 = io(socketioServer);
    socket1.on("updateTurno", () => getLastSesion());
    return () => socket1.disconnect();
  }, []);
  React.useEffect(() => {
    if(isWeb) return
    const socket2 = io(socketioLocal);
    socket2.on("updateTurno", () => getLastSesion());
    return () => socket2.disconnect();
  }, []);

  return (
    <Typography variant="caption">
      {loading ? "Buscando turno abierto" : null}
    </Typography>
  );
}
