import React from "react";
import { Tooltip, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import { Done } from "@material-ui/icons";
import { TraspasosContext } from "../../../../Context/TraspasosContext";
import { GenCodigoBarras } from "../../../../../../../../config/reuserFunctions";

const useStyles = makeStyles((theme) => ({
  colorContainer: {
    border: "1px solid rgba(0,0,0, .2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    width: 40,
    margin: 1,
    borderRadius: "50%",
    cursor: "pointer",
  },
}));

export default function SelectColor({
  color,
  coloresSeleccionados,
  setColoresSeleccionados,
  medidasSeleccionadas,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { item, setItem } = React.useContext(TraspasosContext);
  const { datos_generales, precios } = item.product_selected;

  const [selected, setSelected] = React.useState(false);

  const seleccionarColores = React.useCallback(() => {
    coloresSeleccionados.forEach((res) => {
      if (res._id === color._id) setSelected(true);
    });
  }, [color._id, coloresSeleccionados]);

  React.useEffect(() => {
    seleccionarColores();
  }, [seleccionarColores]);

  const obtenerColores = (value) => {
    if (!selected) {
      coloresSeleccionados.push(color);
      setSelected(value);
    } else {
      let deleted = [];
      coloresSeleccionados.forEach((res, index) => {
        if (res._id === color._id) {
          item.new_medidas.forEach((presentacion) => {
            if (!presentacion.nuevo) {
              if (presentacion.color._id === res._id) {
                deleted.push(presentacion);
              }
            }
          });
          if (deleted.length > 0) return;
          coloresSeleccionados.splice(index, 1);
          setSelected(value);
        }
      });
    }
    let presentacion_temp = [];
    const array_medidad_finales = [...item.new_medidas];

    const newObj = {
      _id: "",
      unidad: "Pz",
      codigo_unidad: "H87",
      existencia: false,
      codigo_barras: GenCodigoBarras(),
      nombre_comercial: datos_generales.nombre_comercial,
      medida: {},
      precio: precios.precios_producto[0].precio_neto,
      cantidad: 0,
      cantidad_nueva: 0,
      nuevo: true,
      precio_unidad: precios.precios_producto[0],
    };

    if (!medidasSeleccionadas.length && !array_medidad_finales.length) {
      /* SI NO HAY COLORES NI VALORES EN EL ARRAY FINAL SE AGREGA EL PRIMER ELEMENTO */
      for (let i = 0; i < coloresSeleccionados.length; i++) {
        const producto_color = coloresSeleccionados[i];

        presentacion_temp.push({
          ...newObj,
          color: producto_color,
        });
      }
    } else if (
      !medidasSeleccionadas.length &&
      array_medidad_finales.length > 0
    ) {
      /* SI YA HAY COLORES REGISTRADOS SE AGREGAN MAS */
      for (let i = 0; i < coloresSeleccionados.length; i++) {
        const producto_color = coloresSeleccionados[i];
        const result = array_medidad_finales.filter(
          (res) => res.color._id === producto_color._id
        );
        if (result.length) {
          presentacion_temp.push(result[0]);
        } else {
          presentacion_temp.push({
            ...newObj,
            color: producto_color,
          });
        }
      }
    } else if (
      medidasSeleccionadas.length > 0 &&
      coloresSeleccionados.length === 1 &&
      value
    ) {
      /* SI YA HAY TALLAS SE LE AGREGA EL COLOR POR PRIMERA VEZ */
      for (let i = 0; i < array_medidad_finales.length; i++) {
        for (let k = 0; k < coloresSeleccionados.length; k++) {
          presentacion_temp.push({
            ...newObj,
            existencia: array_medidad_finales[i].existencia,
            codigo_barras: array_medidad_finales[i].codigo_barras,
            nombre_comercial: array_medidad_finales[i].nombre_comercial,
            medida: array_medidad_finales[i].medida,
            color: coloresSeleccionados[k],
            precio: array_medidad_finales[i].precio,
            cantidad: array_medidad_finales[i].cantidad,
            cantidad_nueva: array_medidad_finales[i].cantidad_nueva,
            precio_unidad: array_medidad_finales[i].precio_unidad,
          });
        }
      }
    } else if (
      medidasSeleccionadas.length > 0 &&
      coloresSeleccionados.length > 0
    ) {
      /* YA HAY TALLAS Y MEIDAS EN LAS PRESENTACIONES, SE REGISTRAN NORMAL */
      for (let i = 0; i < coloresSeleccionados.length; i++) {
        const producto_color = coloresSeleccionados[i];
        for (let k = 0; k < medidasSeleccionadas.length; k++) {
          const producto_medida = medidasSeleccionadas[k];
          const presentacion_existente = array_medidad_finales.filter(
            (producto_array_final) =>
              producto_array_final.medida._id === producto_medida._id &&
              producto_color._id === producto_array_final.color._id
          );
          if (!presentacion_existente.length) {
            presentacion_temp.push({
              ...newObj,
              medida: producto_medida,
              color: producto_color,
            });
          } else {
            presentacion_temp.push(presentacion_existente[0]);
          }
        }
      }
    } else if (
      medidasSeleccionadas.length > 0 &&
      !coloresSeleccionados.length
    ) {
      /* SI NO HAY COLORES SE VUELVE A LISTAR LAS TALLAS QUE YA ESTABAN EN PRESENTACIONES */
      const presentaciones_existentes = array_medidad_finales.filter(
        (producto) => producto.color._id
      );
      if (presentaciones_existentes.length) {
        for (let x = 0; x < array_medidad_finales.length; x++) {
          const objeto_presentaciones_final = array_medidad_finales[x];
          presentacion_temp.push({
            ...newObj,
            _id: objeto_presentaciones_final._id,
            existencia: objeto_presentaciones_final.existencia,
            codigo_barras: objeto_presentaciones_final.codigo_barras,
            nombre_comercial: objeto_presentaciones_final.nombre_comercial,
            medida: objeto_presentaciones_final.medida,
            color: { nombre: "", hex: "" },
            precio: objeto_presentaciones_final.precio,
            cantidad: objeto_presentaciones_final.cantidad,
            cantidad_nueva: objeto_presentaciones_final.cantidad_nueva,
            precio_unidad: objeto_presentaciones_final.precio_unidad,
          });
        }
      }
    }

    setColoresSeleccionados([...coloresSeleccionados]);
    setItem({ ...item, new_medidas: presentacion_temp });
  };

  return (
    <Grid item>
      <Tooltip
        title={color.nombre}
        placement="top"
        arrow
        TransitionComponent={Zoom}
      >
        <div
          className={classes.colorContainer}
          style={{
            backgroundColor: color.hex,
            color: theme.palette.getContrastText(color.hex),
          }}
          onClick={() => obtenerColores(!selected)}
        >
          {selected ? <Done /> : null}
        </div>
      </Tooltip>
    </Grid>
  );
}
