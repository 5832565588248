import React from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import { useQuery } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { GetProducts } from "../../../graphql/queries";
import { Done } from "@material-ui/icons";
import {
  CustomTableAutocomplete,
  CustomTableTextField,
} from "../../../../../../../components/CustomMuiComponents";
import { TraspasosContext } from "../../../Context/TraspasosContext";
import { MainContext } from "../../../../../../../context/MainCtx";
import Medidas from "./Medidas";

export default function InputRow() {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const { tokenDecoded } = React.useContext(MainContext);
  const { traspaso, item, setItem, initialItem } = React.useContext(
    TraspasosContext
  );
  const { almacen_origen, almacen_destino } = traspaso;
  const disabled = almacen_origen || almacen_destino ? false : true;
  const idAlmacen = almacen_origen ? almacen_origen._id : almacen_destino?._id;

  const { loading, data } = useQuery(GetProducts, {
    variables: {
      empresa: tokenDecoded.empresa._id,
      sucursal: tokenDecoded.sucursal._id,
      filtros: { producto: debouncedSearchTerm },
      almacen: idAlmacen || "",
      existencias: false,
      limit: 20,
      offset: 0,
    },
    fetchPolicy: "network-only",
  });

  const options = data?.obtenerProductos?.docs || [];

  const selectItem = (item) => {
    if (!item) {
      setItem(initialItem);
      return;
    }
    setItem({
      cantidad_total: 1,
      new_medidas: [],
      product_selected: item,
      unidad_maxima: item.inventario_general[0]?.unidad_maxima && true,
    });
  };

  return (
    <TableRow style={{ height: 48 }}>
      <TableCell style={{ padding: "0px 4px", width: 220 }}>
        <CustomTableAutocomplete
          id="search-producto-nombre"
          size="small"
          fullWidth
          autoHighlight
          openOnFocus
          loading={loading}
          options={options}
          disabled={disabled}
          forcePopupIcon={false}
          getOptionLabel={(option) =>
            option.datos_generales.nombre_comercial
              ? option.datos_generales.nombre_comercial
              : "N/A"
          }
          renderInput={(params) => (
            <CustomTableTextField
              {...params}
              variant="outlined"
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <InputAdornment position="start">
                        <CircularProgress color="primary" size={20} />
                      </InputAdornment>
                    ) : null}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(_, value) => selectItem(value)}
          getOptionSelected={(option, value) =>
            option.datos_generales.nombre_comercial ===
            value.datos_generales.nombre_comercial
          }
          value={item.product_selected}
        />
      </TableCell>
      <TableCell style={{ padding: "0px 4px", width: 160 }}>
        <CustomTableAutocomplete
          id="search-producto-codigo"
          size="small"
          fullWidth
          loading={loading}
          options={options}
          disabled={disabled}
          autoHighlight
          openOnFocus
          forcePopupIcon={false}
          getOptionLabel={(option) =>
            option.datos_generales.codigo_barras
              ? option.datos_generales.codigo_barras
              : "N/A"
          }
          renderInput={(params) => (
            <CustomTableTextField
              {...params}
              variant="outlined"
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <InputAdornment position="start">
                        <CircularProgress color="primary" size={20} />
                      </InputAdornment>
                    ) : null}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(_, value) => selectItem(value)}
          getOptionSelected={(option, value) =>
            option.datos_generales.codigo_barras ===
            value.datos_generales.codigo_barras
          }
          value={item.product_selected}
        />
      </TableCell>
      <TableCell style={{ padding: "0px 4px", width: 160 }}>
        <CustomTableTextField
          size="small"
          variant="outlined"
          fullWidth
          disabled={disabled}
          type="number"
          value={item?.cantidad_total || ""}
          onChange={(e) =>
            setItem({ ...item, cantidad_total: parseFloat(e.target.value) })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {item.product_selected?.inventario_general?.length > 0
                  ? `${item.product_selected.inventario_general[0].cantidad_existente} ${item.product_selected.inventario_general[0].unidad_inventario}`
                  : ""}
              </InputAdornment>
            ),
          }}
          inputProps={{
            min: 1,
          }}
        />
      </TableCell>
      <TableCell style={{ padding: "0px 4px" }}>
        <Medidas />
      </TableCell>
      <TableCell align="right" padding="checkbox">
        <IconButton type="submit" color="primary" disabled={disabled}>
          <Done />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}