import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import {
  findProductArray,
  verifiPrising,
  calculatePrices2,
} from "../../../config/reuserFunctions";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#e6e6e6",
      },
      "&:hover fieldset": {
        borderColor: "#e3e3e3",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #e3e3e3",
      },
      "&.Mui-disabled fieldset": {
        border: "1px solid #F0F0F0",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "6px 0px 6px 0px",
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
  buttons: {
    minWidth: "20px!important",
    borderColor: "#e6e6e6",
  },
}));

export default function QuantityInput({
  producto,
  withUnidad = false,
  width = 120,
}) {
  const classes = useStyles();
  const [newCantidadProduct, setNewCantidadProduct] = useState(
    producto.cantidad_venta
  );
  const {
    updateTablaVentas,
    setUpdateTablaVentas,
    setDatosVentasActual,
  } = React.useContext(VentasContext);
  const [tempAmount, setTempAmount] = useState(producto.cantidad_venta);
  let datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [value] = useDebounce(newCantidadProduct, 500);

  useEffect(() => {
    setTempAmount(producto.cantidad_venta);
  }, [producto.cantidad_venta]);

  const [count, setCount] = useState(false);

  useEffect(() => {
    if (count) {
      CalculeDataPricing(value);
    }
  }, [value]);

  const without_inventary = sesion.empresa.vender_sin_inventario;
  const isNota = datosVentas?.nota_credito && true;
  const { cantidad_venta_original, concepto, inventario_general } = producto;

  const calculateNewPricingAmount = (cantidad) => {
    try {
      setCount(true);
      if (without_inventary) {
        setTempAmount(cantidad);
        setNewCantidadProduct(cantidad);
        return;
      }
      if (cantidad === "0") return;
      if (isNota && cantidad > cantidad_venta_original) return;
      if (concepto === "medidas" && cantidad > producto.cantidad) return;
      if (cantidad > inventario_general[0].cantidad_existente) return;
      setTempAmount(cantidad);
      setNewCantidadProduct(cantidad);
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const CalculeDataPricing = async (new_cant) => {
    if (new_cant === "" || new_cant === 0 || new_cant === "0") {
      setTempAmount(producto.cantidad_venta);
    } else if (producto.codigo_unidad !== "KGM" && new_cant.includes(".")) {
      setTempAmount(producto.cantidad_venta);
    } else {
      let venta = JSON.parse(localStorage.getItem("DatosVentas"));
      let productosVentas = venta === null ? [] : venta.productos;
      const venta_actual = venta === null ? [] : venta;
      let productosVentasTemp = productosVentas;
      let venta_existente =
        venta === null
          ? {
              subTotal: 0,
              total: 0,
              impuestos: 0,
              iva: 0,
              ieps: 0,
              descuento: 0,
              monedero: 0,
            }
          : venta;

      let CalculosData = {};

      //Buscar y obtener ese producto en el array de ventas
      const producto_encontrado = await findProductArray(producto);

      if (producto_encontrado.found) {
        const {
          cantidad_venta,
          ...newP
        } = producto_encontrado.producto_found.producto;

        newP.cantidad_venta = parseFloat(new_cant);
        const verify_prising = await verifiPrising(newP);
        const newPrising = verify_prising.found
          ? verify_prising.object_prising
          : newP.precio_actual_object;

        const new_resta = await calculatePrices2({
          newP,
          cantidad: newP.granel_producto.granel ? 1 : cantidad_venta,
          precio_boolean: true,
          precio: newP.precio_actual_object,
          granel: newP.granel_producto,
          origen: "Tabla",
        });

        if (newP.granel_producto.granel) {
          newP.granel_producto = {
            granel: true,
            valor: parseFloat(new_cant),
          };
        }

        const new_suma = await calculatePrices2({
          newP,
          cantidad: newP.granel_producto.granel ? 1 : parseFloat(new_cant),
          precio_boolean: true,
          precio: newPrising,
          granel: newP.granel_producto,
          origen: "Tabla",
        });

        newP.iva_total_producto = parseFloat(new_suma.ivaCalculo);
        newP.ieps_total_producto = parseFloat(new_suma.iepsCalculo);
        newP.impuestos_total_producto = parseFloat(new_suma.impuestoCalculo);
        newP.subtotal_total_producto = parseFloat(new_suma.subtotalCalculo);
        newP.total_total_producto = parseFloat(new_suma.totalCalculo);

        if (verify_prising.found) {
          newP.precio_a_vender = new_suma.totalCalculo;
          newP.precio_anterior = newP.precio_actual_porducto;
          newP.precio_actual_producto = verify_prising.pricing;
          newP.precio_actual_object = verify_prising.object_prising;

          newP.precio_actual_object = {
            cantidad_unidad: verify_prising.object_prising.cantidad_unidad
              ? verify_prising.object_prising.cantidad_unidad
              : null,
            numero_precio: verify_prising.object_prising.numero_precio
              ? verify_prising.object_prising.numero_precio
              : null,
            unidad_maxima: verify_prising.object_prising.unidad_maxima
              ? verify_prising.object_prising.unidad_maxima
              : null,
            precio_general: verify_prising.object_prising.precio_general
              ? verify_prising.object_prising.precio_general
              : null,
            precio_neto: verify_prising.object_prising.precio_neto
              ? verify_prising.object_prising.precio_neto
              : null,
            precio_venta: verify_prising.object_prising.precio_venta
              ? verify_prising.object_prising.precio_venta
              : null,
            iva_precio: verify_prising.object_prising.iva_precio
              ? verify_prising.object_prising.iva_precio
              : null,
            ieps_precio: verify_prising.object_prising.ieps_precio
              ? verify_prising.object_prising.ieps_precio
              : null,
            utilidad: verify_prising.object_prising.utilidad
              ? verify_prising.object_prising.utilidad
              : null,
            porciento: verify_prising.object_prising.porciento
              ? verify_prising.object_prising.porciento
              : null,
            dinero_descontado: verify_prising.object_prising.dinero_descontado
              ? verify_prising.object_prising.dinero_descontado
              : null,
          };
        } else {
          newP.cantidad_venta = parseFloat(new_cant);
          newP.precio_anterior = newP.precio_actual_producto;
          newP.precio_a_vender = new_suma.totalCalculo;
        }

        productosVentasTemp.splice(
          producto_encontrado.producto_found.index,
          1,
          newP
        );

        CalculosData = {
          subTotal:
            parseFloat(venta_existente.subTotal) -
            parseFloat(new_resta.subtotalCalculo) +
            new_suma.subtotalCalculo,
          total:
            parseFloat(venta_existente.total) -
            parseFloat(new_resta.totalCalculo) +
            new_suma.totalCalculo,
          impuestos:
            parseFloat(venta_existente.impuestos) -
            parseFloat(new_resta.impuestoCalculo) +
            new_suma.impuestoCalculo,
          iva:
            parseFloat(venta_existente.iva) -
            parseFloat(new_resta.ivaCalculo) +
            new_suma.ivaCalculo,
          ieps:
            parseFloat(venta_existente.ieps) -
            parseFloat(new_resta.iepsCalculo) +
            new_suma.iepsCalculo,
          descuento:
            parseFloat(venta_existente.descuento) -
            parseFloat(new_resta.descuentoCalculo) +
            new_suma.descuentoCalculo,
          monedero:
            parseFloat(venta_existente.monedero) -
            parseFloat(new_resta.monederoCalculo) +
            new_suma.monederoCalculo,
        };
      } else {
        console.log("El producto no existe");
      }

      let saldo_favor = 0;
      if (venta.nota_credito) {
        saldo_favor = venta.total_original - CalculosData.total;
      }

      CalculosData.subtotal_base = CalculosData.subTotal;
      CalculosData.total_base = CalculosData.total;

      localStorage.setItem(
        "DatosVentas",
        JSON.stringify({
          ...venta,
          ...CalculosData,
          saldo_favor,
          cliente:
            venta_actual.venta_cliente === true ? venta_actual.cliente : {},
          venta_cliente:
            venta_actual.venta_cliente === true
              ? venta_actual.venta_cliente
              : false,
          productos: productosVentasTemp,
          tipo_emision: venta_actual.tipo_emision
            ? venta_actual.tipo_emision
            : "TICKET",
        })
      );
      setDatosVentasActual(CalculosData);
      //Recargar la tabla de los productos
      setUpdateTablaVentas(!updateTablaVentas);
    }
  };

  const maxCant = () => {
    if (without_inventary) {
      return 9999;
    }
    if (datosVentas && datosVentas.nota_credito) {
      return producto.cantidad_venta_original;
    } else {
      if (producto.concepto === "medidas") {
        return producto.cantidad;
      } else {
        return producto.inventario_general.map(
          (existencia) =>
            `${
              producto.unidad === "Caja" || producto.unidad === "Costal"
                ? existencia.cantidad_existente_maxima
                : existencia.cantidad_existente
            }`
        );
      }
    }
  };

  const handleChange = (quantity) => {
    const value = parseFloat(tempAmount) + quantity;
    if (value <= 0 || value > maxCant()) return;
    setTempAmount(value);
    calculateNewPricingAmount(`${value}`);
  };

  return (
    <Box display="flex" gridGap={2}>
      <TextField
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => calculateNewPricingAmount(e.target.value)}
        value={tempAmount}
        type="number"
        name="cantidad_venta"
        variant="outlined"
        className={classes.root}
        InputProps={{
          style: { padding: 0, width },
          startAdornment: (
            <InputAdornment position="start">
              <Button
                size="small"
                //variant="outlined"
                className={classes.buttons}
                onClick={() => handleChange(-1)}
              >
                <Remove color="action" fontSize="small" />
              </Button>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {withUnidad && (
                <Typography variant="caption" color="textSecondary">
                  {producto.unidad}
                </Typography>
              )}
              <Button
                size="small"
                //variant="outlined"
                className={classes.buttons}
                onClick={() => handleChange(+1)}
              >
                <Add color="action" fontSize="small" />
              </Button>
            </InputAdornment>
          ),
        }}
        inputProps={{
          min: 1,
          max: maxCant(),
        }}
      />
    </Box>
  );
}
