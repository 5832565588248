import React from "react";
import Box from "@material-ui/core/Box";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import { CropOriginal } from "@material-ui/icons";
import { Img } from "react-image";
import QuantityInput from "./CuantityInput";
import { formatoMexico } from "../../../config/reuserFunctions";
import EliminarProductoNota from "../../ventas/EliminarProductoNota";
import EliminarProductoVenta from "../../ventas/EliminarProductoVenta";

const useStyles = makeStyles((theme) => ({
  paper: {
    "-webkit-box-shadow": "0px 10px 21px -8px rgba(235,235,235,1)",
    "-moz-box-shadow": "0px 10px 21px -8px rgba(235,235,235,1)",
    boxShadow: "0px 10px 21px -8px rgba(235,235,235,1)",
    backgroundColor: theme.palette.background.default,
    border: "1px solid #f0f0f0",
  },
  imgContainer: {
    height: "50px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxHeight: "100%",
    maxWidth: "100%",
  },
}));

export default function ListItemsVentaRapida({ data, height }) {
  const classes = useStyles();
  let datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));

  function MyImageComponent({ producto }) {
    let imagen = "";
    if (
      producto.id_producto.imagenes &&
      producto.id_producto.imagenes.length > 0
    ) {
      imagen = producto.id_producto.imagenes[0].url_imagen;
    }
    return imagen ? (
      <Img src={[imagen]} className={classes.image} />
    ) : (
      <CropOriginal fontSize="large" color="disabled" />
    );
  }

  const getName = (producto) => {
    const { nombre_comercial } = producto.id_producto?.datos_generales;
    const { color, medida } = producto;

    if (color || medida) {
      if ((color.nombre || color.color) && (medida.talla || medida.medida)) {
        return `${nombre_comercial} Talla ${medida.talla || medida.medida} ${
          color.nombre || color.color
        }`;
      } else {
        return nombre_comercial;
      }
    } else {
      return nombre_comercial;
    }
  };
  /*  const getQuantity = (producto) => {
    let cantidad = "";
    //console.log(producto)
    if (producto.id_producto?.datos_generales?.tipo_producto === "OTROS") {
      cantidad =
        producto.unidad === "Costal" || producto.unidad === "Caja"
          ? producto.inventario_general[0].cantidad_existente_maxima
          : producto.inventario_general[0].cantidad_existente;
    } else {
      cantidad = producto.cantidad;
    }
    return cantidad;
  }; */

  return (
    <Box p={1} height={height} overflow="auto">
      {data.map((res, index) => {
        return (
          <Box key={index} my={1}>
            <Paper className={classes.paper}>
              <Box p={1} display="flex" alignItems="center" gridGap={10}>
                <Box className={classes.imgContainer}>
                  <MyImageComponent producto={res} />
                </Box>
                <Box flexGrow={1}>
                  <Typography
                    noWrap
                    style={{ fontSize: 14, textTransform: "lowercase" }}
                  >
                    {getName(res)}
                  </Typography>
                  <Typography variant="button" style={{ fontSize: 16 }}>
                    ${formatoMexico(res?.total_total_producto)}
                  </Typography>
                </Box>
                <Box>
                  <Box display="flex" alignItems="center" gridGap={10}>
                    <QuantityInput producto={res} width={100} />
                    {datosVentas && datosVentas.nota_credito ? (
                      <EliminarProductoNota producto={res} index={index} />
                    ) : (
                      <EliminarProductoVenta producto={res} />
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        );
      })}
    </Box>
  );
}
