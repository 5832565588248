import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import moment from "moment";
import { CheckCircleTwoTone, HighlightOffTwoTone } from "@material-ui/icons";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  containerVenta: {
    height: "75vh",
  },
  cancelada: {
    borderLeft: " 6px solid #FF8A8A",
  },
}));

export default function TablaAbonos({
  data,
  limit,
  offset,
  setOffset,
  totalDocs,
}) {
  const classes = useStyles();

  const handleChangePage = (_, nextPage) => {
    setOffset(nextPage);
  };

  return (
    <Box>
      <Paper className={classes.root} variant="outlined">
        <TableContainer className={classes.containerVenta}>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">Status</TableCell>
                <TableCell>Folio</TableCell>
                <TableCell>Fecha del abono</TableCell>
                <TableCell>Nombre del cliente</TableCell>
                <TableCell>Monto</TableCell>
                <TableCell>Forma de pago</TableCell>
                <TableCell>Caja</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.obtenerHistorialAbonos?.docs.map((abono, index) => {
                const cancel = abono.status === "CANCELADO";
                return (
                  <TableRow key={index} role="checkbox" tabIndex={-1}>
                    <TableCell>
                      {cancel ? (
                        <HighlightOffTwoTone
                          fontSize="small"
                          htmlColor={red[400]}
                        />
                      ) : (
                        <CheckCircleTwoTone
                          fontSize="small"
                          htmlColor={green[400]}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      {abono.folio_venta}
                    </TableCell>
                    <TableCell>
                      {moment(abono.fecha_movimiento.completa)
                        .tz("America/Mexico_City")
                        .format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{abono.nombre_cliente}</TableCell>
                    <TableCell>
                      {"$" + formatoMexico(abono.monto_total_abonado)}
                    </TableCell>
                    <TableCell>{abono.metodo_de_pago.metodo}</TableCell>
                    <TableCell>{abono.numero_caja}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={totalDocs}
          rowsPerPage={limit}
          page={offset}
          onPageChange={handleChangePage}
        />
      </Paper>
    </Box>
  );
}
