import React from "react";
import { Box, makeStyles, Divider } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import cafiLogo from "../../../../img/logo_cafi_vertical.png";
import {
  formatoFechaCorta,
  formatoMexico,
  numberToLetters,
} from "../../../../config/reuserFunctions";
import { grey } from "@material-ui/core/colors";
import moment from "moment";
import {
  formaPago,
  metodoPago,
} from "../../../sucursales/Facturacion/catalogos";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      fontSize: 13,
      padding: "2px 10px",
    },
  },
  tableFooter: {
    "& .MuiTableCell-root": {
      padding: "0px 10px",
      border: "none",
    },
    "& .headertop": {
      //borderTop: "2px solid",
      borderColor: grey[600],
      paddingTop: theme.spacing(1),
    },
  },
  firmaBottom: {
    display: "none",
    [`@media print`]: {
      display: "flex",
    },
  },
  body: {
    [`@media print`]: {
      minHeight: "27cm",
      display: "flex",
      flexDirection: "column",
    },
  },
}));

export default function ContentDocNotaVenta({ venta, refcomp }) {
  const classes = useStyles();
  return (
    <Box ref={refcomp} p={4} className={classes.body}>
      <HeaderDoc venta={venta} />
      <VentaDoc venta={venta} />
      <Box flexGrow={1} />
      <Firma venta={venta} />
      <FooterPage />
    </Box>
  );
}

const HeaderDoc = ({ venta }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  // obtener direccion
  let address = "";
  let municipio = "";
  let pais = "";
  const { direccion } = sesion.sucursal;
  if (
    direccion &&
    direccion.calle &&
    direccion.no_ext &&
    direccion.municipio &&
    direccion.estado &&
    direccion.pais
  ) {
    address = `${direccion.calle} #${direccion.no_ext}`;
    municipio = `${direccion.municipio}, ${direccion.estado}`;
    pais = direccion.pais;
  }

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography style={{ fontSize: "22pt" }}>
            <b>Nota de venta</b>
          </Typography>
        </Box>
        <Box mx={2}>
          <Typography style={{ fontSize: "12pt" }} align="center">
            <b>Folio</b>
          </Typography>
          <Typography style={{ fontSize: "12pt" }} align="center">
            {venta.folio}
          </Typography>
        </Box>
        <Box mx={2}>
          <Typography style={{ fontSize: "12pt" }} align="center">
            <b>Fecha</b>
          </Typography>
          <Typography style={{ fontSize: "12pt" }} align="center">
            {formatoFechaCorta(venta.fecha_registro)}
          </Typography>
        </Box>
        {venta.credito ? (
          <Box mx={2}>
            <Typography style={{ fontSize: "12pt" }} align="center">
              <b>Vencimiento crédito</b>
            </Typography>
            <Typography style={{ fontSize: "12pt" }} align="center">
              {formatoFechaCorta(venta.fecha_de_vencimiento_credito)}
            </Typography>
          </Box>
        ) : null}
      </Box>
      <Divider style={{ margin: "4px 0", height: "2px" }} />
      <Box display="flex" gridGap={10} mb={1}>
        <Box
          style={{
            width: 150,
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={sesion.empresa.imagen ? sesion.empresa.imagen : cafiLogo}
            alt="cafi punto de venta"
            style={{ maxWidth: "100%", minWidth: "100%" }}
          />
        </Box>
        <Box flexGrow={1}>
          <Typography style={{ fontSize: "10pt" }}>
            <b>{`${sesion.empresa.nombre_empresa} • Sucursal ${sesion.sucursal.nombre_sucursal}`}</b>
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Dirección: </b>
            {` ${address}, ${municipio}, ${pais}`}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>RFC: </b> {sesion.empresa.rfc}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Tel: </b> {sesion.empresa.telefono_dueno}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Vendedor: </b> {sesion.nombre}
          </Typography>
        </Box>
        <ClienteDoc venta={venta} />
      </Box>
    </>
  );
};
const ClienteDoc = ({ venta }) => {
  if (!venta.cliente?._id) {
    return (
      <Box>
        <Typography variant="body2">Público General</Typography>
      </Box>
    );
  }

  const { direccion } = venta.cliente;

  return (
    <Box>
      <Box>
        <Typography style={{ fontSize: "10pt" }} align="right">
          <b>{venta.cliente?.nombre_cliente}</b>
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {`${direccion?.calle} #${direccion?.no_ext} col. ${direccion?.colonia} CP${direccion?.codigo_postal}`}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {`${direccion?.municipio}, ${direccion?.estado}, ${direccion?.pais}`}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {venta.cliente?.telefono}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {venta.cliente?.email}
        </Typography>
      </Box>
    </Box>
  );
};
const VentaDoc = ({ venta }) => {
  const classes = useStyles();
  const items = venta?.productos || [];

  const name = (item) => {
    const { nombre_comercial } = item.id_producto.datos_generales;
    const { color, medida } = item;

    if (color.color && medida.medida) {
      return `${nombre_comercial} T${medida.medida} ${color.color}`;
    } else {
      return nombre_comercial;
    }
  };

  const descuento = (item) => {
    if (item.descuento_general_activo === true) {
      const { dinero_descontado, porciento } = item.descuento_general;
      return `$${formatoMexico(dinero_descontado)} - %${porciento}`;
    } else {
      return "$0.00";
    }
  };

  let forma_pago = "";
  let metodo_pago = "";
  formaPago.forEach(({ Value, Name }) => {
    if (venta.forma_pago === Value) forma_pago = Name;
  });
  metodoPago.forEach(({ Value, Name }) => {
    if (venta.metodo_pago === Value) metodo_pago = Name;
  });

  return (
    <>
      <Divider style={{ margin: "4px 0", height: "2px" }} />
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>PRODUCTO/SERVICIO</TableCell>
              <TableCell align="right">CANT</TableCell>
              <TableCell align="right">UNITARIO</TableCell>
              <TableCell align="right">DESCUENTO</TableCell>
              <TableCell align="right">IMPORTE</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{name(item)}</TableCell>
                  <TableCell align="right">{`${item.cantidad_venta} ${item.unidad}`}</TableCell>
                  <TableCell align="right">
                    ${formatoMexico(item.precio_a_vender)}
                  </TableCell>
                  <TableCell align="right">{descuento(item)}</TableCell>
                  <TableCell align="right">
                    ${formatoMexico(item.total)}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow className={classes.tableFooter}>
            <TableCell className="headertop" colSpan={3}>
                <b>Venta a crédito: </b> {venta.credito ? "SI" : "NO"}
              </TableCell>
              <TableCell className="headertop" align="right">
                <b>SUBTOTAL</b>
              </TableCell>
              <TableCell className="headertop" align="right">
                ${formatoMexico(venta.subTotal)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableFooter}>
            <TableCell colSpan={3}>
                <b>Forma de pago: </b> {venta.forma_pago} • {forma_pago}
              </TableCell>
              <TableCell align="right">
                <b>DESCUENTO</b>
              </TableCell>
              <TableCell align="right">
                ${formatoMexico(venta.descuento)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableFooter}>
              <TableCell colSpan={3}>
                <b>Método de pago:</b> {venta.metodo_pago} • {metodo_pago}
              </TableCell>
              <TableCell align="right">
                <b>TOTAL</b>
              </TableCell>
              <TableCell align="right">${formatoMexico(venta.total)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
const Firma = ({ venta }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const classes = useStyles();
  return (
    <Box className={classes.firmaBottom} justifyContent="center">
      {venta.credito === true ? (
        <Box display="flex" width="100%" id="pagare" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontStyle="italic"
            style={{
              border: "1px solid",
              marginTop: "20px",
              padding: "15px",
              borderRadius: "5px",
            }}
            flexDirection="column"
          >
            <Typography variant="h6" style={{ marginBottom: "5px" }}>
              PAGARÉ
            </Typography>
            <Typography
              component="div"
              style={{
                fontSize: "13px",
                textAlign: "justify",
              }}
            >
              Yo{" "}
              {venta.cliente === null
                ? null
                : venta.cliente.nombre_cliente.toUpperCase()}{" "}
              me comprometo a que pagaré incondicionalmente a la orden de{" "}
              {sesion.empresa.nombre_dueno === null
                ? null
                : sesion.empresa.nombre_dueno.toUpperCase()}{" "}
              previo a la fecha{" "}
              {formatoFechaCorta(venta.fecha_de_vencimiento_credito)} la
              cantidad de ${formatoMexico(venta.saldo_credito_pendiente)} (
              {numberToLetters(venta.saldo_credito_pendiente)}) valor de las
              mercancias y/o servicios a mi entera conformidad. Este pagaré es
              mercantil y está regido por la ley general de títulos y
              operaciones de crédito en sus artículos 172 y 173 parte final por
              no ser pagaré domiciliado y artículos correlativos. Queda
              convenido que en caso de mora, el presente título causará un
              interés del _______ % mensual
            </Typography>
            <br></br>
            <Box justifyContent="center">
              <Typography style={{ textAlign: "center" }}>
                ____________________________________________________
              </Typography>
            </Box>
            <Typography style={{ textAlign: "center" }}>Firma</Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};
const FooterPage = () => {
  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      <Typography align="center" variant="caption" color="textSecondary">
        Creado desde CAFI punto de venta
      </Typography>
      <Typography align="right" variant="caption" color="textSecondary">
        {moment().tz("America/Mexico_City").format("lll")}
      </Typography>
    </Box>
  );
};
