import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core";
import { TraspasosContext } from "../../../../Context/TraspasosContext";
import { GenCodigoBarras } from "../../../../../../../../config/reuserFunctions";

const useStyles = makeStyles((theme) => ({
  colorContainer: {
    border: "1px solid rgba(0,0,0, .2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 40,
    padding: "0 10px",
    margin: 2,
    borderRadius: "10%",
    cursor: "pointer",
  },
}));

export default function SelectTalla({
  talla,
  coloresSeleccionados,
  medidasSeleccionadas,
  setMedidasSeleccionadas,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { item, setItem } = React.useContext(TraspasosContext);
  const { datos_generales, precios } = item.product_selected;
  const [selected, setSelected] = React.useState(false);

  const seleccionarMedidas = React.useCallback(() => {
    medidasSeleccionadas.forEach((res) => {
      if (res._id === talla._id) setSelected(true);
    });
  }, [talla._id, medidasSeleccionadas]);

  React.useEffect(() => {
    seleccionarMedidas();
  }, [seleccionarMedidas]);

  const handleAddTallas = (value) => {
    const medidas_seleccionadas_temp = [...medidasSeleccionadas];

    if (!selected) {
      medidas_seleccionadas_temp.push(talla);
      setSelected(value);
    } else {
      let deleted = [];
      medidas_seleccionadas_temp.forEach((res, index) => {
        if (res._id === talla._id) {
          item.new_medidas.forEach((presentacion) => {
            if (!presentacion.nuevo) {
              if (presentacion.medida._id === res._id) {
                deleted.push(presentacion);
              }
            }
          });
          if (deleted.length > 0) return;
          medidas_seleccionadas_temp.splice(index, 1);
          setSelected(value);
        }
      });
    }

    let presentacion_temp = [];
    const array_medidad_finales = [...item.new_medidas];

    const newObj = {
      _id: "",
      unidad: "Pz",
      codigo_unidad: "H87",
      existencia: false,
      codigo_barras: GenCodigoBarras(),
      nombre_comercial: datos_generales.nombre_comercial,
      color: { nombre: "", hex: "" },
      precio: precios.precios_producto[0].precio_neto,
      cantidad: 0,
      cantidad_nueva: 0,
      nuevo: true,
      precio_unidad: precios.precios_producto[0],
    };

    if (!coloresSeleccionados.length && !array_medidad_finales.length) {
      /* SI NO HAY COLORES NI VALORES EN EL ARRAY FINAL SE AGREGA EL PRIMER ELEMENTO */
      for (let i = 0; i < medidas_seleccionadas_temp.length; i++) {
        const producto_medida = medidas_seleccionadas_temp[i];
        presentacion_temp.push({ ...newObj, medida: producto_medida });
      }
    } else if (
      !coloresSeleccionados.length &&
      array_medidad_finales.length > 0
    ) {
      /* SI NO HAY COLORES REGISTRADOS PERO YA HAY TALLAS SE AGREGAN MAS */
      for (let i = 0; i < medidas_seleccionadas_temp.length; i++) {
        const producto_medida = medidas_seleccionadas_temp[i];
        const result = array_medidad_finales.filter(
          (res) => res.medida._id === producto_medida._id
        );

        if (result.length) {
          presentacion_temp.push(result[0]);
        } else {
          presentacion_temp.push({ ...newObj, medida: producto_medida });
        }
      }
    } else if (
      coloresSeleccionados.length > 0 &&
      medidas_seleccionadas_temp.length === 1 &&
      value
    ) {
      /* SI HAY COLORES SE LE AGREGA TALLA POR PRIMERA VEZ */
      for (let i = 0; i < array_medidad_finales.length; i++) {
        for (let k = 0; k < medidas_seleccionadas_temp.length; k++) {
          presentacion_temp.push({
            ...newObj,
            existencia: array_medidad_finales[i].existencia,
            codigo_barras: array_medidad_finales[i].codigo_barras,
            nombre_comercial: array_medidad_finales[i].nombre_comercial,
            medida: medidas_seleccionadas_temp[k],
            color: array_medidad_finales[i].color,
            precio: array_medidad_finales[i].precio,
            cantidad: array_medidad_finales[i].cantidad,
            cantidad_nueva: array_medidad_finales[i].cantidad_nueva,
            precio_unidad: array_medidad_finales[i].precio_unidad,
          });
        }
      }
    } else if (
      coloresSeleccionados.length > 0 &&
      medidas_seleccionadas_temp.length > 0
    ) {
      /* YA HAY COLORES Y MEDIDAS EN LAS PRESENTACIONES, SE AGREGAN NORMAL */
      for (let i = 0; i < medidas_seleccionadas_temp.length; i++) {
        const producto_medida = medidas_seleccionadas_temp[i];
        for (let k = 0; k < coloresSeleccionados.length; k++) {
          const producto_color = coloresSeleccionados[k];
          const presentacion_existente = array_medidad_finales.filter(
            (producto_array_final) =>
              producto_array_final.medida._id === producto_medida._id &&
              producto_color._id === producto_array_final.color._id
          );
          if (!presentacion_existente.length) {
            presentacion_temp.push({
              ...newObj,
              medida: producto_medida,
              color: producto_color,
            });
          } else {
            presentacion_temp.push(presentacion_existente[0]);
          }
        }
      }
    } else if (
      coloresSeleccionados.length > 0 &&
      !medidas_seleccionadas_temp.length
    ) {
      /* SI NO HAY TALLAS SE VUELVE A LISTAR LOS COLORES QUE YA ESTABAN EN PRESENTACIONES */
      const presentaciones_existentes = array_medidad_finales.filter(
        (producto) => producto.medida._id
      );
      if (presentaciones_existentes.length) {
        for (let x = 0; x < array_medidad_finales.length; x++) {
          const objeto_presentaciones_final = array_medidad_finales[x];
          presentacion_temp.push({
            ...newObj,
            _id: objeto_presentaciones_final._id,
            existencia: objeto_presentaciones_final.existencia,
            codigo_barras: objeto_presentaciones_final.codigo_barras,
            nombre_comercial: objeto_presentaciones_final.nombre_comercial,
            medida: {},
            color: objeto_presentaciones_final.color,
            precio: objeto_presentaciones_final.precio,
            cantidad: objeto_presentaciones_final.cantidad,
            cantidad_nueva: objeto_presentaciones_final.cantidad_nueva,
            precio_unidad: objeto_presentaciones_final.precio_unidad,
          });
        }
      }
    }

    setMedidasSeleccionadas([...medidas_seleccionadas_temp]);
    setItem({ ...item, new_medidas: presentacion_temp });
  };

  return (
    <Grid item>
      <div
        className={classes.colorContainer}
        onClick={() => handleAddTallas(!selected)}
        style={
          selected
            ? {
                backgroundColor: theme.palette.primary.main,
              }
            : null
        }
      >
        <Typography
          variant="button"
          noWrap
          style={{
            color: theme.palette.getContrastText(
              selected ? theme.palette.primary.main : "#FFFFFF"
            ),
            fontSize: 16,
          }}
        >
          {talla.talla}
        </Typography>
      </div>
    </Grid>
  );
}
