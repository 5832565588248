import React from "react";
import { Box, CssBaseline, ThemeProvider } from "@material-ui/core";
import routes from "./config/routes";
import theme from "./config/colors";
import ConexionDetect from "./components/Connection/ConexionDetect";
//import client from "./config/apollo";
import { ApolloProvider } from "@apollo/client";
import { AccesosProvider } from "./context/Accesos/accesosCtx";
import { ClienteProvider } from "./context/Catalogos/crearClienteCtx";
import { RouterProvider } from "react-router-dom";
import MainCtxProvider, { MainContext } from "./context/MainCtx";
import CloudCtxProvider from "./context/CloudCtx";
import { SnackbarProvider } from "notistack";
import config from "../package.json"

export default function App() {
  window.oncontextmenu = function () {
    return false;
  };

  return (
    <Box height="100vh" className="App">
      <CssBaseline />
      <SnackbarProvider maxSnack={3} autoHideDuration={2500}>
        <MainCtxProvider>
          <RenderApp />
        </MainCtxProvider>
      </SnackbarProvider>
    </Box>
  );
}

const RenderApp = () => {
  const { client } = React.useContext(MainContext);
  return (
    <ApolloProvider client={client}>
      <CloudCtxProvider>
        <AccesosProvider>
          <ClienteProvider>
            <ThemeProvider theme={theme}>
              <ConexionDetect />
              <RouterProvider router={routes} />
              <Box position="fixed" zIndex={9999} bottom={6} left={8} color="gray">
                v{config.version} CAFI punto de venta
              </Box>
            </ThemeProvider>
          </ClienteProvider>
        </AccesosProvider>
      </CloudCtxProvider>
    </ApolloProvider>
  );
};
