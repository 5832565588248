import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useDebounce } from "use-debounce";
import { ClienteCtx } from "../../../../context/Catalogos/crearClienteCtx";
import { OBTENER_CLIENTES } from "../../../../gql/Catalogos/clientes";
import ExcelExportButton from "../../../../components/ExcelExportButton";

function ExportarClientes({ filtro, tipo }) {
  const { update } = useContext(ClienteCtx);
  const [value] = useDebounce(filtro, 1000);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  /* Queries */
  const { data, refetch } = useQuery(OBTENER_CLIENTES, {
    variables: {
      tipo,
      filtros: value,
      empresa: sesion.empresa._id,
      eliminado: false,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
  }, [update, refetch]);

  let clientes = {};
  if (data) {
    clientes = data.obtenerClientes.docs.map((cliente) => {
      const lista_clientes = {
        clave_cliente: cliente.clave_cliente,
        numero_cliente: cliente.numero_cliente,
        nombre_cliente: cliente.nombre_cliente,
        fecha_registro: moment(cliente.fecha_registro)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY"),
        curp: cliente.curp !== null ? cliente.curp : "-",
        telefono: cliente.telefono,
        correo: cliente.email,
        direccion: cliente.direccion.calle + " #" + cliente.direccion.no_ext,
        estado_cliente: cliente.estado_cliente === true ? "ACTIVO" : "INACTIVO",
        tipo: cliente.tipo_cliente,
        credito_disponible: cliente.credito_disponible
          ? "$" + cliente.credito_disponible
          : "-",
        limite_credito: cliente.credito_disponible
          ? "$" + cliente.limite_credito
          : "-",
        monedero_electronico: cliente.monedero_electronico
          ? "$" + cliente.monedero_electronico
          : "-",

        //OBLIGATORIOS Nombre, clave, email, telefono, calle,
        //numero exterior, codigo postal, colonia, municipio, estado, país

        //NO OBLIGATORIOS  Numero_cliente, fecha_registro, curp, estado_cliente,
        //tipo_cliente, credito_disponible, limite_credito, monedero_electronico
      };

      //   console.log("Clientes", lista_clientes);
      return lista_clientes;
    });
  }

  return <ExcelExportButton data={clientes} name="Lista de clientes" button />;
}

export default ExportarClientes;
