import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx.js";
import facturamaApi from "../../../../../billing/Facturama/facturama.api.js";

export default function UsosCfdi() {
  const [cfdiUses, setCfdiUses] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const {
    datosFactura,
    setDatosFactura,
    error_validation,
    isPublicoGeneral,
  } = React.useContext(FacturacionCtx);

  const obtenerUsoCfdi = (value) => {
    setDatosFactura({
      ...datosFactura,
      receiver: {
        ...datosFactura.receiver,
        CfdiUse: value,
      },
    });
  };

  React.useEffect(() => {
    const obtenerCfdiUses = async () => {
      try {
        setLoading(true);
        const result = await facturamaApi.Catalogs.CfdiUses("", function (
          result
        ) {
          return result;
        });
        setLoading(false);
        if (result) setCfdiUses(result);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    obtenerCfdiUses();
  }, []);

  if (loading)
    return (
      <FormControl fullWidth size="small" variant="outlined" margin="dense">
        <Select
          value="loading"
          style={{
            paddingLeft: 4,
            height: 32,
          }}
          startAdornment={
            <InputAdornment position="start">Uso de CFDI</InputAdornment>
          }
        >
          <MenuItem value="loading">
            <em>Cargando...</em>
          </MenuItem>
        </Select>
      </FormControl>
    );

  return (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
      error={error_validation.status && !datosFactura.receiver.CfdiUse}
      disabled={isPublicoGeneral}
      margin="dense"
    >
      <Select
        value={datosFactura.receiver.CfdiUse}
        name="CfdiUse"
        onChange={(e) => obtenerUsoCfdi(e.target.value)}
        style={{
          paddingLeft: 4,
          height: 32,
        }}
        startAdornment={
          <InputAdornment position="start">Uso de CFDI</InputAdornment>
        }
      >
        <MenuItem value="">
          <em>Selecciona uno</em>
        </MenuItem>
        {cfdiUses.map((res, index) => (
          <MenuItem
            key={index}
            value={res.Value}
          >{`${res.Value} - ${res.Name}`}</MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {error_validation.status && !datosFactura.receiver.CfdiUse
          ? error_validation.message
          : ""}
      </FormHelperText>
    </FormControl>
  );
}
