import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import { Print } from "@material-ui/icons";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import SelectMedida from "./SelectMedida";
import ReactToPrint from "react-to-print";
import { PreviewEtiqueta } from "./PreviewEtiqueta";
import MultiplesEtiquetas from "./MultiplesEtiquetas";

export default function GenerarEtiquetaBarcode({ producto }) {
  const [open, setOpen] = React.useState(false);
  const [datosEtiqueta, setDatosEtiqueta] = React.useState({
    unidad: "",
    medida: null,
    nombre: producto.datos_generales.nombre_comercial,
    size: "57mmx33mm",
    precio: "",
    cantidad: 0,
    codigo:
      producto.datos_generales.tipo_producto !== "OTROS"
        ? ""
        : producto.datos_generales.codigo_barras,
    clave: producto.datos_generales.clave_alterna,
    multiple: false,
    cantidad_etiquetas: 1,
    margen: 1.5
  });

  const componentRef = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const obtenerTamEtiqueta = (size) => {
    setDatosEtiqueta({
      ...datosEtiqueta,
      size,
    });
  };

  useEffect(() => {
    const unidadVenta = producto.unidades_de_venta.filter(
      (res) => res.unidad_principal
    );
    setDatosEtiqueta((state) => ({
      ...state,
      unidad: producto.medidas_producto.length ? "Pz" : unidadVenta[0].unidad,
      cantidad: unidadVenta[0].cantidad,
      precio:
        producto.datos_generales.tipo_producto !== "OTROS"
          ? null
          : unidadVenta[0].precio,
    }));
  }, []);

  /* const imprimir = () => {
    let contenido = document.getElementById(`id-etiqueta-${producto._id}`)
      .innerHTML;
    let contenidoOriginal = document.body.innerHTML;

    document.body.innerHTML = contenido;
    window.print();
    document.body.innerHTML = contenidoOriginal;
  }; */

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        size="small"
        disabled={!producto.datos_generales.codigo_barras}
        variant="outlined"
        color="default"
        onClick={handleClickOpen}
      >
        <Print />
      </IconButton>
      <Box mx={1} />
      <Typography>{producto.datos_generales.codigo_barras}</Typography>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogContent>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="unidad-label">Unidad</InputLabel>
            <Select
              labelId="unidad-label"
              id="demo-simple-select-outlined"
              value={datosEtiqueta.unidad}
              disabled={producto.datos_generales.tipo_producto !== "OTROS"}
              onChange={(e) =>
                setDatosEtiqueta({ ...datosEtiqueta, unidad: e.target.value })
              }
              label="Unidad"
            >
              {producto.unidades_de_venta.map((res) => (
                <MenuItem
                  key={`etiqueta-unidades-${res._id}`}
                  value={res.unidad}
                >
                  {res.unidad}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box my={1} />
          <SelectMedida
            unidades={producto.medidas_producto}
            setDatosEtiqueta={setDatosEtiqueta}
          />
          <Box my={1} />
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="tam-etiqueta">Tamaño de la etiqueta</InputLabel>
            <Select
              labelId="tam-etiqueta"
              value={datosEtiqueta.size}
              label="Tamano de la etiqueta"
              onChange={(e) => obtenerTamEtiqueta(e.target.value)}
            >
              <MenuItem value="57mmx33mm">57mm x 33mm</MenuItem>
              <MenuItem value="27mmx13mm">27mm x 13mm</MenuItem>
              <MenuItem value="62mmx29mm">62mm x 29mm(brother ql-800)</MenuItem>
              <MenuItem value="62mmx18mmxdoble">62mm x 18mm(brother ql-800) doble</MenuItem>
            </Select>
          </FormControl>
          <Box my={1} />
          <MultiplesEtiquetas datosEtiqueta={datosEtiqueta} setDatosEtiqueta={setDatosEtiqueta} />
          <Box my={2} />
          <Box display="flex" justifyContent="center" alignItems="center">
            <PreviewEtiqueta
              ref={componentRef}
              producto={producto}
              datosEtiqueta={datosEtiqueta}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                //onClick={() => imprimir()}
                color="primary"
                startIcon={<Print />}
              >
                Imprimir
              </Button>
            )}
            content={() => componentRef.current}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}
