import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import RemoveCircleTwoToneIcon from "@material-ui/icons/RemoveCircleTwoTone";
import { useDebounce } from "use-debounce";
import { Done } from "@material-ui/icons";

export default function DescuentoVenta({
  venta_base,
  setVentaBase,
  totales,
  setTotales,
  montos,
  setMontos,
}) {
  const [descuentoAplicarVenta, setDescuentoAplicarVenta] = useState(0);
  const [descuentoPorsentajeVenta, setDescuentoPorsentajeVenta] = useState(0);
  const [variables, setVariables] = useState({
    productos: venta_base.productos,
    total: totales.total,
    subTotal: totales.subtotal,
    impuestos: totales.impuestos,
    iva: venta_base.iva,
    ieps: venta_base.ieps,
    descuento: totales.descuento,
    descuento_general_activo: false,
    descuento_general: {
      cantidad_descontado: 0,
      porciento: 0,
      precio_con_descuento: 0,
    },
  });

  const textfieldRef = React.useRef(null);
  let venta_original = JSON.parse(localStorage.getItem("DatosVentas"));
  const TOTAL = venta_original ? parseFloat(venta_original.total) : 0;
  const SUBTOTAL = venta_original ? parseFloat(venta_original.subTotal) : 0;
  //states para calcular con estos y no se recalcule cuando se ejecuten las funciones
  /*    const [precioVariable, setPrecioVariable] = useState(0);
   const [porcentVariable, setPorcentVariable] = useState(0);
 
   const [PRICE] = useDebounce(precioVariable, 700);
   const [PERCENT] = useDebounce(porcentVariable, 700); */

  const handlerChangeDiscountVenta = (PRICE) => {
    if (PRICE && PRICE !== "0") {
      let porcentaje = Math.abs((PRICE / TOTAL) * 100 - 100).toFixed(4);
      setDescuentoPorsentajeVenta(porcentaje);
      handleCalculateNewDiscountVenta(PRICE);
      return;
    }
    setVariables({
      productos: venta_original.productos,
      total: venta_original.total,
      subTotal: venta_original.subTotal,
      impuestos: venta_original.impuestos,
      iva: venta_original.iva,
      ieps: venta_original.ieps,
      descuento: venta_original.descuento,
      descuento_general_activo: false,
      descuento_general: {
        cantidad_descontado: 0,
        porciento: 0,
        precio_con_descuento: 0,
      },
    });
    aplicarDescuento();
  };

  const handleChangePorsentDiscount = (PERCENT) => {
    if (PERCENT && PERCENT !== "0") {
      let porcentaje = 100 - PERCENT;
      let descuento = Math.round((TOTAL * porcentaje) / 100);
      setDescuentoAplicarVenta(descuento.toFixed(4));
      handleCalculateNewDiscountVenta(descuento.toFixed(4));
      return;
    }

    setVariables({
      productos: venta_original.productos,
      total: venta_original.total,
      subTotal: venta_original.subTotal,
      impuestos: venta_original.impuestos,
      iva: venta_original.iva,
      ieps: venta_original.ieps,
      descuento: venta_original.descuento,
      descuento_general_activo: false,
      descuento_general: {
        cantidad_descontado: 0,
        porciento: 0,
        precio_con_descuento: 0,
      },
    });
  };

  useEffect(() => {
    aplicarDescuento();
  }, [variables]);

  const handleCalculateNewDiscountVenta = (NEW_PRICE) => {
    let venta = { ...venta_original };
    const productStorage = venta.productos;
    //Declarar la variables necesarias (total, subTotal, impuestos, iva ieps, productosFinal)
    let total = 0,
      subTotal = 0,
      impuestos = 0,
      iva = 0,
      ieps = 0,
      descuento = 0;
    let productosFinal = [];

    const NEW_SUBTOTAL_G = (NEW_PRICE * SUBTOTAL) / TOTAL;
    const DESCONTADO = parseFloat(SUBTOTAL - NEW_SUBTOTAL_G);
    const PORCENTAJE = parseFloat(DESCONTADO / SUBTOTAL);

    //Mapearlos
    for (let i = 0; i < productStorage.length; i++) {
      const product = productStorage[i];
      const { cantidad_venta, id_producto } = product;
      const { precio_venta } = product.precio_actual_object;
      const IVA = id_producto.precios.iva;
      const IEPS = id_producto.precios.ieps;
      const PCCI = id_producto.precios.precio_de_compra.precio_con_impuesto;
      let precio_actual_object = {};

      const PVSI = precio_venta * cantidad_venta; //precio de venta sin impuestos
      const DESCUENTO_PRODUCTO = PVSI * PORCENTAJE;
      const NEW_SUB = parseFloat(PVSI - DESCUENTO_PRODUCTO); //aqui no se multiplicaba => * product.cantidad_venta
      const NEW_IEPS = parseFloat(NEW_SUB * parseFloat(IEPS / 100));
      const NEW_IVA = parseFloat((NEW_SUB + NEW_IEPS) * parseFloat(IVA / 100));
      const NEW_TOTAL = parseFloat(NEW_SUB + NEW_IVA + NEW_IEPS);
      const NEW_UTILIDAD = parseFloat(((NEW_SUB - PCCI) / PCCI) * 100);

      precio_actual_object = {
        cantidad_unidad: 1,
        dinero_descontado: parseFloat(DESCUENTO_PRODUCTO.toFixed(4)),
        ieps_precio: NEW_IEPS,
        iva_precio: NEW_IVA,
        numero_precio: product.precio_actual_object.numero_precio,
        porciento: product.precio_actual_object.porciento
          ? parseFloat(
              parseFloat(product.precio_actual_object.porciento) +
                parseFloat(descuentoPorsentajeVenta)
            )
          : parseFloat(descuentoPorsentajeVenta),
        precio_general: 0,
        precio_neto: NEW_TOTAL,
        precio_venta: NEW_SUB,
        unidad_maxima: false,
        utilidad: parseFloat(NEW_UTILIDAD.toFixed(4)),
      };

      if (product.precio_actual_object.unidad_maxima) {
        //Aqui se calcula la unidad por mayoreo (Cajas y costales)
        precio_actual_object.cantidad_unidad =
          product.precio_actual_object.cantidad_unidad;
        precio_actual_object.precio_general =
          NEW_TOTAL * parseFloat(product.precio_actual_object.cantidad_unidad);
        precio_actual_object.unidad_maxima = true;
      }
      const valorGranel =
        product.granel_producto.granel === true
          ? parseFloat(product.granel_producto.valor)
          : 1;
      //Guardar el nuevo producto en el arreglo
      const ieps_total_producto = parseFloat(
        precio_actual_object.ieps_precio * valorGranel //aqui se multiplicaba por cantidad => * product.cantidad_venta
      );
      const impuestos_total_producto = parseFloat(
        (precio_actual_object.ieps_precio + precio_actual_object.iva_precio) *
          valorGranel //aqui se multiplicaba por cantidad => * product.cantidad_venta
      );
      const iva_total_producto = parseFloat(
        precio_actual_object.iva_precio * valorGranel //aqui se multiplicaba por cantidad => * product.cantidad_venta
      );
      const subtotal_total_producto = parseFloat(
        precio_actual_object.precio_venta * valorGranel //aqui se multiplicaba por cantidad => * product.cantidad_venta
      );
      const total_total_producto = parseFloat(
        precio_actual_object.precio_neto * valorGranel //aqui se multiplicaba por cantidad => * product.cantidad_venta
      );
      const descuentoProducto = parseFloat(
        parseFloat(precio_actual_object.dinero_descontado) * valorGranel //aqui se multiplicaba por cantidad => * product.cantidad_venta
      );
      //toFixed prices
      precio_actual_object.ieps_precio = parseFloat(NEW_IEPS.toFixed(4));
      precio_actual_object.iva_precio = parseFloat(NEW_IVA.toFixed(4));
      precio_actual_object.precio_neto = parseFloat(NEW_TOTAL.toFixed(4));
      precio_actual_object.precio_venta = parseFloat(NEW_SUB.toFixed(4));

      productosFinal.push({
        ...product,
        precio_actual_object,
        ieps_total_producto: parseFloat(ieps_total_producto.toFixed(4)),
        impuestos_total_producto: parseFloat(
          impuestos_total_producto.toFixed(4)
        ),
        iva_total_producto: parseFloat(iva_total_producto.toFixed(4)),
        subtotal_total_producto: parseFloat(subtotal_total_producto.toFixed(4)),
        total_total_producto: parseFloat(total_total_producto.toFixed(4)),
        descuento_activo: true,
        descuento: {
          cantidad_unidad: product.precio_actual_object.cantidad_unidad,
          numero_precio: product.precio_actual_object.numero_precio,
          unidad_maxima: product.precio_actual_object.unidad_maxima,
          precio_general: product.precio_actual_object.precio_general,
          precio_neto: precio_actual_object.precio_neto,
          precio_venta: precio_actual_object.precio_venta,
          iva_precio: precio_actual_object.ieps_precio,
          ieps_precio: precio_actual_object.ieps_precio,
          utilidad: precio_actual_object.utilidad,
          dinero_descontado: descuentoProducto,
          porciento: product.precio_actual_object.porciento
            ? parseFloat(
                parseFloat(product.precio_actual_object.porciento) +
                  parseFloat(descuentoPorsentajeVenta)
              )
            : parseFloat(descuentoPorsentajeVenta),
        },
      });
      //Sumar los valores
      total += parseFloat(total_total_producto.toFixed(4));
      subTotal += parseFloat(subtotal_total_producto.toFixed(4));
      impuestos += parseFloat(impuestos_total_producto.toFixed(4));
      iva += parseFloat(iva_total_producto.toFixed(4));
      ieps += parseFloat(ieps_total_producto.toFixed(4));
      descuento += parseFloat(descuentoProducto.toFixed(4));
    }

    setVariables({
      productos: productosFinal,
      total: parseFloat(total.toFixed(4)),
      subTotal: parseFloat(subTotal.toFixed(4)),
      impuestos: parseFloat(impuestos.toFixed(4)),
      iva: parseFloat(iva.toFixed(4)),
      ieps: parseFloat(ieps.toFixed(4)),
      descuento: parseFloat(descuento.toFixed(4)),
      descuento_general_activo: true,
      descuento_general: {
        cantidad_descontado: parseFloat(descuento.toFixed(4)),
        porciento: parseFloat(descuentoPorsentajeVenta),
        precio_con_descuento: parseFloat(total.toFixed(4)),
      },
    });
  };

  const aplicarDescuento = () => {
    let venta = { ...venta_original };
    const {
      productos,
      total,
      subTotal,
      impuestos,
      iva,
      ieps,
      descuento,
      descuento_general_activo,
      descuento_general,
    } = variables;

    /* localStorage.setItem(
      "DatosVentas",
      JSON.stringify({
        ...venta,
        productos: productosFinal,
        total,
        subTotal,
        impuestos,
        iva,
        ieps,
        descuento,
      })
    ); */
    setVentaBase({
      ...venta,
      productos,
      total,
      subTotal,
      subtotal_base: venta_original.subTotal,
      total_base: venta_original.total,
      impuestos,
      iva,
      ieps,
      descuento,
      descuento_general_activo,
      descuento_general,
    });

    setTotales({ ...totales, total, subTotal: subTotal, impuestos, descuento });
    //setMontos({ ...montos, efectivo: total });
  };

  const handleInvalidInput = (e) => {
    const { value, name } = e.target;
    if (
      !value ||
      value === "0" ||
      parseFloat(value) === 0 ||
      (name === "dinero-descuento" && descuentoPorsentajeVenta > 100)
    ) {
      setVariables({
        productos: venta_original.productos,
        total: venta_original.total,
        subTotal: venta_original.subTotal,
        impuestos: venta_original.impuestos,
        iva: venta_original.iva,
        ieps: venta_original.ieps,
        descuento: venta_original.descuento,
        descuento_general_activo: false,
        descuento_general: {
          cantidad_descontado: 0,
          porciento: 0,
          precio_con_descuento: 0,
        },
      });
      setDescuentoAplicarVenta(0);
      setDescuentoPorsentajeVenta(0);
    }
  };

  const [precioVariable, setPrecioVariable] = useState(0);
  const [porcentVariable, setPorcentVariable] = useState(0);

  const [PRICE] = useDebounce(precioVariable, 700);
  const [PERCENT] = useDebounce(porcentVariable, 700);

  useEffect(() => {
    handlerChangeDiscountVenta(descuentoAplicarVenta);
  }, [PRICE]);

  useEffect(() => {
    handleChangePorsentDiscount(descuentoPorsentajeVenta);
  }, [PERCENT]);

  useEffect(() => {
    if (textfieldRef.current) textfieldRef.current.focus();
  }, [textfieldRef.current]);

  const eliminarDescuento = () => {
    try {
      setDescuentoAplicarVenta(0);
      setDescuentoPorsentajeVenta(0);
      handleCalculateNewDiscountVenta(venta_original.total);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Grid item md={6} xs={12}>
        <Box mt={4}>
          <Paper elevation={3}>
            <Box textAlign={"center"}>
              <Typography style={{ fontSize: 17 }}>
                <b>Agregar descuento</b>
              </Typography>
            </Box>
            <Box p={2}>
              <Typography style={{ fontSize: "18px", textAlign: "center" }}>
                <b>Total a pagar:</b>
              </Typography>
              <Grid container justifyContent="center">
                <Grid item xs={8}>
                  <TextField
                    inputRef={textfieldRef}
                    autoFocus
                    fullWidth
                    size="medium"
                    name="dinero-descuento"
                    id="form-venta-dinero-descuento"
                    value={descuentoAplicarVenta}
                    onChange={(e) => {
                      if (
                        parseFloat(e.target.value) > TOTAL ||
                        parseFloat(e.target.value) < 0
                      )
                        return;
                      setDescuentoAplicarVenta(e.target.value);
                      setPrecioVariable(e.target.value);
                    }}
                    variant="standard"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="h6" color="textSecondary">
                            $
                          </Typography>
                        </InputAdornment>
                      ),
                      style: {
                        borderBottomRightRadius: 0,
                        borderTopRightRadius: 0,
                        fontSize: 22,
                      },
                    }}
                    onBlur={handleInvalidInput}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    size="medium"
                    id="form-venta-porsentaje-descuento"
                    variant="standard"
                    value={descuentoPorsentajeVenta}
                    onChange={(e) => {
                      if (
                        parseFloat(e.target.value) > 100 ||
                        parseFloat(e.target.value) < 0
                      )
                        return;
                      setDescuentoPorsentajeVenta(e.target.value);
                      setPorcentVariable(e.target.value);
                    }}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant="h6" color="textSecondary">
                            %
                          </Typography>
                        </InputAdornment>
                      ),
                      inputProps: { min: 0, max: 100 },
                      style: {
                        borderBottomLeftRadius: 0,
                        borderTopLeftRadius: 0,
                        fontSize: 22,
                      },
                    }}
                    onBlur={handleInvalidInput}
                  />
                </Grid>
              </Grid>
              {/* <Grid container spacing={2}>
                <Grid item md={6}>
                    <Typography>
                    <b>Porcentaje:</b>
                    </Typography>
                    <TextField
                    fullWidth
                    size="small"
                    name="descuento"
                    id="form-venta-porsentaje-descuento"
                    variant="outlined"
                    //value={porcentaje}
                   //onChange={(e) => obtenerPorciento(e)}
                    type="number"
                    disabled={false}
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                        ),
                        inputProps: { min: 0, max: 100 },
                    }}
                    onBlur={handleInvalidInput}
                    />
                </Grid>
                <Grid item md={6}>
                    <Typography>
                    <b>Dinero a descontar</b>
                    </Typography>
                    <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="precioConDescuento"
                    //onChange={obtenerPrecioText}
                    //value={totalDescount}
                    className={classes.input}
                    variant="outlined"
                    onBlur={handleInvalidInput}
                    />
                </Grid>
            </Grid> */}
              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="secondary"
                  size="medium"
                  onClick={eliminarDescuento}
                  startIcon={<RemoveCircleTwoToneIcon />}
                >
                  Quitar descuento
                </Button>
                {/*   <Button
                          onClick={() => aplicarDescuento()}
                          variant="contained"
                          size="large"
                          color="primary"
                          autoFocus
                          startIcon={<Done />}
                          disableElevation
                        >
                            Aplicar Descuento
                        </Button>  */}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Grid>
    </>
  );
}
