import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import InputAdornment from "@material-ui/core/InputAdornment";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx.js";
import ListaClientesFacturas from "../Catalogos/ClientesSelect";
import { Box, makeStyles } from "@material-ui/core";
import { CustoTextField } from "../CustomTextField.jsx";
import UsosCfdi from "../Catalogos/UsosCfdi.jsx";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(1),
    height: "100%"
  },
  title: {
    position: "absolute",
    top: -30,
    left: -18,
    backgroundColor: "white",
    padding: "0 8px",
  },
}));

export default function ReceptorForm() {
  const {
    datosFactura,
    setDatosFactura,
    error_validation,
    isPublicoGeneral,
  } = React.useContext(FacturacionCtx);
  const classes = useStyles();

  const obtenerUsoCfdi = (e) => {
    const { name, value } = e.target;
    setDatosFactura({
      ...datosFactura,
      receiver: {
        ...datosFactura.receiver,
        [name]: value,
      },
    });
  };

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box position="relative">
        <Typography className={classes.title}>
          <b>Receptor</b>
        </Typography>
      </Box>
      <CustoTextField
        value={isPublicoGeneral ? "" : datosFactura.receiver.Name}
        size="small"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography>Cliente</Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <ListaClientesFacturas />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        margin="dense"
        error={error_validation.status && !datosFactura.receiver.Name}
        helperText={!datosFactura.receiver.Name ? error_validation.message : ""}
      />
      <CustoTextField
        size="small"
        variant="outlined"
        value={datosFactura.receiver.Rfc}
        disabled
        error={error_validation.status && !datosFactura.receiver.Rfc}
        helperText={
          error_validation.status && !datosFactura.receiver.Rfc
            ? error_validation.message
            : ""
        }
        margin="dense"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography color="textPrimary">RFC</Typography>
            </InputAdornment>
          ),
        }}
      />
      <Box item md={3} xs={12}>
        {/* <FormControl
          fullWidth
          size="small"
          variant="outlined"
          error={error_validation.status && !datosFactura.receiver.CfdiUse}
          disabled={isPublicoGeneral}
          margin="dense"
        >
          <Select
            value={datosFactura.receiver.CfdiUse}
            name="CfdiUse"
            onChange={obtenerUsoCfdi}
            style={{
              paddingLeft: 4,
              height: 32,
            }}
            startAdornment={
              <InputAdornment position="start">Uso de CFDI</InputAdornment>
            }
          >
            <MenuItem value="">
              <em>Selecciona uno</em>
            </MenuItem>
            {usosCfdi.map((res, index) => (
              <MenuItem
                key={index}
                value={res.Value}
              >{`${res.Value} - ${res.Name}`}</MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {error_validation.status && !datosFactura.receiver.CfdiUse
              ? error_validation.message
              : ""}
          </FormHelperText>
        </FormControl> */}
        <UsosCfdi />
      </Box>
    </Paper>
  );
}
