import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { FcBusinesswoman } from "react-icons/fc";
import {
  Box,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { ArrowBack, ClearOutlined, Search } from "@material-ui/icons";
import ListaClientes from "./ListaClientes";
import CrearCliente from "./CrearCliente";
import ImportarClientes from "./ImportXLS/ImportXlsButton";
import ClientesInactivosComponent from "./ClientesInactivos";
import ExportarClientes from "./ExportarClientes";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  icon: {
    fontSize: 100,
  },
  root: {
    display: "flex",
    paddingLeft: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initial_state_filtro = {
  cliente: "",
  fecha_inicio: "",
  fecha_fin: "",
  activos: false,
  inactivos: false,
};

export default function Cliente() {
  const classes = useStyles();
  const permisosUsuario = JSON.parse(localStorage.getItem("sesionCafi"));
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(initial_state_filtro);
  const [page, setPage] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setPage(0);
  };

  const obtenerChecks = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
    setPage(0);
  };

  const limpiarFiltros = () => {
    setValues(initial_state_filtro);
    setPage(0);
  };

  const setCheckActivos = (e) => {
    obtenerChecks(e);
    setValues({ ...values, activos: e.target.checked, inactivos: false });
  };

  const setCheckInactivos = (e) => {
    obtenerChecks(e);
    setValues({ ...values, inactivos: e.target.checked, activos: false });
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcBusinesswoman style={{ fontSize: 100 }} />
          </Box>
          Cliente
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Typography variant="h6" className={classes.title}>
          Cliente
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <Button
            color="primary"
            startIcon={<ClearOutlined />}
            onClick={() => limpiarFiltros()}
          >
            Limpiar filtros
          </Button>

          <Box mx={1} />

          {permisosUsuario.accesos.catalogos.clientes.agregar ===
          false ? null : (
            <>
              <ClientesInactivosComponent tipo="CLIENTE" />
              <Box mx={1} />
            </>
          )}

          <ExportarClientes filtro={values} tipo="CLIENTE" />
          <Box mx={1} />

          {permisosUsuario.accesos.catalogos.clientes.agregar ===
          false ? null : (
            <>
              <ImportarClientes />
              <Box mx={1} />
            </>
          )}

          {permisosUsuario.accesos.catalogos.clientes.agregar ===
          false ? null : (
            <>
              <CrearCliente tipo="CLIENTE" accion="registrar" />
              <Box mx={1} />
            </>
          )}

          <IconButton variant="contained" color="primary" onClick={handleClose}>
            <ArrowBack />
          </IconButton>
        </Box>
        <DialogContent>
          <Box mb={1}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item md={6}>
                <Typography>Buscar cliente:</Typography>
                <TextField
                  fullWidth
                  placeholder="Clave, No., Nombre, Teléfono, Correo..."
                  onChange={(e) => obtenerCampos(e)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  value={values.cliente}
                  variant="outlined"
                  size="small"
                  name="cliente"
                />
              </Grid>
              <Grid item md={3}>
                <Typography>Desde:</Typography>
                <TextField
                  fullWidth
                  onChange={(e) => obtenerCampos(e)}
                  type="date"
                  value={values.fecha_inicio}
                  variant="outlined"
                  size="small"
                  name="fecha_inicio"
                />
              </Grid>
              <Grid item md={3}>
                <Typography>Hasta:</Typography>
                <TextField
                  fullWidth
                  onChange={(e) => obtenerCampos(e)}
                  type="date"
                  value={values.fecha_fin}
                  variant="outlined"
                  size="small"
                  name="fecha_fin"
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.activos}
                      onChange={setCheckActivos}
                      name="activos"
                      color="default"
                    />
                  }
                  label="Activos"
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.inactivos}
                      onChange={setCheckInactivos}
                      name="inactivos"
                      color="default"
                    />
                  }
                  label="Inactivos"
                />
              </Grid>
            </Grid>
          </Box>
          <ListaClientes
            tipo="CLIENTE"
            page={page}
            setPage={setPage}
            filtro={values}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
