import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment-timezone";
import useStyles from "../../styles";
import { useApolloClient } from "@apollo/client";
import { OBTENER_PRE_CORTE_CAJA } from "../../../../gql/Cajas/cajas";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { formatoMexico } from "../../../../config/reuserFunctions";
import { imprimirTicketPrecorte } from "./PrintTicketPrecorte";
import Receipt from "@material-ui/icons/Receipt";
import { LocalAtm } from "@material-ui/icons";
import { MainContext } from "../../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreCorteCaja() {
  const { turnoEnCurso } = React.useContext(MainContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [query, setQuery] = React.useState({
    data: undefined,
    error: undefined,
    loading: false,
  });
  const { data, error, loading } = query;
  const client = useApolloClient();

  const {
    reloadVerPreCorte,
    setReloadVerPreCorte,
    setAbrirPanelAcceso,
    abrirPanelAcceso,
    setDepartamentos,
  } = useContext(AccesosContext);

  const input = {
    horario_en_turno: "ABRIR TURNO",
    id_caja: turnoEnCurso ? turnoEnCurso.id_caja : "",
    id_usuario: sesion._id,
    token_turno_user: turnoEnCurso ? turnoEnCurso.token_turno_user : "",
  };

  const getPrecorte = async () => {
    try {
      const response = await client.query({
        query: OBTENER_PRE_CORTE_CAJA,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          input: input,
        },
        fetchPolicy: "network-only",
      });
      setQuery(response);
    } catch (error) {
      console.log(error);
    }
  };

  /* const { loading, error, data, refetch } = useQuery(OBTENER_PRE_CORTE_CAJA, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: input,
    },
    fetchPolicy: "network-only",
  }); */

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loadingTicket, setLoadingTicket] = useState(false);

  const handleClickOpen = () => {
    if (sesion.accesos.ventas.pre_corte.ver === true) {
      setOpen(!open);
      getPrecorte();
    } else {
      setAbrirPanelAcceso(!abrirPanelAcceso);
      setDepartamentos({
        departamento: "ventas",
        subDepartamento: "pre_corte",
        tipo_acceso: "ver",
      });
    }
  };

  useEffect(() => {
    if (reloadVerPreCorte === true) {
      setOpen(!open);
      setReloadVerPreCorte(false);
    }
  }, [reloadVerPreCorte]);

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 79) {
        handleClickOpen();
        //refetch();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open]);

  if (error || loading) {
    return (
      <ListItem button disabled>
        <ListItemIcon>
          <LocalAtm />
        </ListItemIcon>
        <ListItemText primary="PreCorte de Caja" />
      </ListItem>
    );
  }

  const printTicket = async () => {
    setLoadingTicket(true);
    const datos = {
      turno: turnoEnCurso,
      sesion,
      monto: data?.obtenerPreCorteCaja?.monto_efectivo_precorte
        ? data?.obtenerPreCorteCaja?.monto_efectivo_precorte
        : 0.0,
    };
    await imprimirTicketPrecorte(datos);
    setLoadingTicket(true);
    setOpen(!open);
  };

  return (
    <>
      <ListItem
        button
        onClick={() => {
          handleClickOpen();
        }}
        disabled={!turnoEnCurso}
      >
        <ListItemIcon>
          <LocalAtm />
        </ListItemIcon>
        <ListItemText primary="PreCorte de Caja" />
      </ListItem>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={() => setOpen(!open)}
        TransitionComponent={Transition}
      >
        <DialogTitle component="div">
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Pre-Corte de Caja</Typography>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(!open)}
              size="large"
            >
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box p={2}>
              {sesion.turno_en_caja_activo === true && turnoEnCurso ? (
                <>
                  <Box>
                    <Typography variant="subtitle1">
                      <b>Usuario: </b> {sesion.nombre}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">
                      <b>Caja: </b>{" "}
                      {turnoEnCurso ? turnoEnCurso.numero_caja : ""}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="subtitle1">
                      <b>Fecha y hora al precorte: </b>
                    </Typography>
                    <Typography variant="subtitle1">
                      {moment()
                        .tz("America/Mexico_City")
                        .format("MMMM D YYYY, h:mm:ss a")}
                    </Typography>
                  </Box>
                  <Box textAlign="center" p={2}>
                    <Typography variant="h6">
                      <b>Monto total en efectivo: </b>
                    </Typography>
                    <Typography variant="h3" style={{ color: "green" }}>
                      <b>
                        $
                        {data?.obtenerPreCorteCaja?.monto_efectivo_precorte
                          ? formatoMexico(
                              data?.obtenerPreCorteCaja?.monto_efectivo_precorte
                            )
                          : 0.0}
                      </b>
                    </Typography>
                  </Box>
                </>
              ) : (
                <Box textAlign="center" p={2}>
                  <Typography variant="h6">
                    <b>Por el momento no hay un turno en sesión</b>
                  </Typography>
                </Box>
              )}
            </Box>
        </DialogContent>

        <DialogActions>
          {sesion.turno_en_caja_activo === true && turnoEnCurso ? (
            <Button
              onClick={() => printTicket()}
              color="primary"
              variant="contained"
              size="large"
              autoFocus
              disabled={loadingTicket}
              startIcon={
                loadingTicket ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Receipt />
                )
              }
            >
              Imprimir
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
}