import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import moment from "moment-timezone";
import { verificarDatosFactura } from "./validacion_factura";
import { factura_initial_state } from "./initial_factura_states";
import { FacturacionCtx } from "../../../../context/Facturacion/facturacionCtx";
import { CREAR_FACTURA } from "../../../../gql/Facturacion/Facturacion";
import { useMutation } from "@apollo/client";
import { Done } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";

const customFixed = (number) => {
  if (!number) return "0";
  if (number % 1 === 0) {
    return parseFloat(number).toFixed(2).toString();
  } else {
    return parseFloat(number).toFixed(2).toString();
  }
};

export default function RealizarFactura({ setAlert }) {
  const { isOnline } = useContext(AccesosContext);
  const [loading, setLoading] = useState(false);
  const {
    datosFactura,
    setDatosFactura,
    codigo_postal,
    productos,
    setProductos,
    venta_factura,
    setVentaFactura,
    setError,
    isPublicoGeneral,
    setIsCfdiRelated
  } = useContext(FacturacionCtx);
  const [CrearFactura] = useMutation(CREAR_FACTURA);

  const limpiarCampos = (folio = "", serie = "") => {
    try {
      setDatosFactura({ ...factura_initial_state, folio, serie });
      setVentaFactura(null);
      setProductos([]);
      setIsCfdiRelated(false);
    } catch (error) {
      console.log(error);
    }
  };

  const crearFactura = async () => {
    try {
      if (!venta_factura) return;
      setLoading(true);
      let nuevo_obj = { ...datosFactura };
      const items = [];

      productos.forEach((producto) => {
        const { iva, ieps } = producto.id_producto.precios;
        const { subtotal, ieps_total, iva_total } = producto;
        let Taxes = [];
        let baseIva = subtotal;

        if (ieps_total) {
          baseIva += ieps_total;
          Taxes.push({
            Total: customFixed(ieps_total),
            Name: "IEPS",
            Base: customFixed(subtotal),
            Rate: customFixed(ieps / 100),
            IsRetention: "false",
          });
        }

        if (iva_total) {
          Taxes.push({
            Total: customFixed(iva_total),
            Name: "IVA",
            Base: customFixed(baseIva),
            Rate: customFixed(iva / 100),
            IsRetention: "false",
          });
        }

        let item = {
          ProductCode:
            producto.id_producto.datos_generales.clave_producto_sat.Value,
          IdentificationNumber: producto.id_producto._id,
          Description: producto.id_producto.datos_generales.nombre_comercial,
          UnitCode: producto.codigo_unidad,
          UnitPrice: customFixed(producto.precio_unidad.precio_venta),
          Quantity: producto.cantidad_venta.toString(),
          Subtotal: customFixed(producto.subtotal_antes_de_impuestos),
          Discount: customFixed(
            producto?.descuento_producto?.dinero_descontado
          ),
          Total: customFixed(producto.total),
        };
        if (Taxes.length > 0) {
          item.Taxes = Taxes;
          item.TaxObject = "02";
        }
        items.push(item);
      });
      //poner la fecha de facturacion
      if (datosFactura.date === "1") {
        nuevo_obj.date = moment()
          .tz("America/Mexico_City")
          .subtract(1, "d")
          .format("YYYY-MM-DDTHH:mm:ss");
      } else if (datosFactura.date === "2") {
        nuevo_obj.date = moment()
          .tz("America/Mexico_City")
          .subtract(2, "d")
          .format("YYYY-MM-DDTHH:mm:ss");
      } else {
        nuevo_obj.date = moment()
          .tz("America/Mexico_City")
          .format("YYYY-MM-DDTHH:mm:ss");
      }

      nuevo_obj.items = items;
      nuevo_obj.expedition_place = codigo_postal;
      nuevo_obj.id_venta = venta_factura._id;
      nuevo_obj.folio_venta = venta_factura.folio;

      //si es publico en general se adjunta este objeto
      if (isPublicoGeneral) {
        nuevo_obj.GlobalInformation = {
          Periodicity: datosFactura.periodicity,
          Months: moment().tz("America/Mexico_City").format("MM"),
          Year: moment().tz("America/Mexico_City").format("YYYY"),
        };
        nuevo_obj.receiver.TaxZipCode = codigo_postal;
      }

      /* validar todos los datos */
      const validate = verificarDatosFactura(nuevo_obj, isPublicoGeneral);
      if (validate.length) {
        setError({ status: true, message: validate[0].message });
        setLoading(false);
        return;
      }
      setError({ status: false, message: "" });

      //console.log(nuevo_obj);
      /* setLoading(false)
      return */

      delete nuevo_obj.periodicity;
      await CrearFactura({
        variables: {
          input: nuevo_obj,
        },
      });

      //console.log(result);

      setLoading(false);
      setAlert({
        message: `¡Listo! `,
        status: "success",
        open: true,
      });
      const folio = (parseFloat(nuevo_obj.folio) + 1).toString();
      const serie = nuevo_obj.serie;
      limpiarCampos(folio, serie);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error.message) {
        setAlert({
          message: error.message,
          status: "error",
          open: true,
        });
      } else if (error.networkError) {
        console.log(error.networkError.result);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors);
      }
    }
  };

  return (
    <Button
      startIcon={
        loading ? <CircularProgress color="inherit" size={20} /> : <Done />
      }
      onClick={() => crearFactura()}
      variant="contained"
      disableElevation
      style={{ textTransform: "none" }}
      color="primary"
      disabled={
        loading ||
        !venta_factura ||
        !isOnline ||
        !datosFactura.issuer.Rfc ||
        !datosFactura.issuer.FiscalRegime
      }
    >
      Guardar
    </Button>
  );
}
