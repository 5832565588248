export const verificarDatosCartaP = (CartaPorte30) => {
  const { Ubicaciones, Mercancias, FiguraTransporte } = CartaPorte30;

  //validar Ubicaciones
  if(Ubicaciones.length < 2) return {error: true, message: "No hay destinos"};

  let origen = Ubicaciones[0];
  let destino = Ubicaciones[Ubicaciones.length - 1];

  if(origen.TipoUbicacion !== "Origen") return {error: true, message: "No hay origen"};
  if(destino.TipoUbicacion !== "Destino") return {error: true, message: "No hay destino"};

  //Distancias de destinos
  const whitoutDistance = Ubicaciones.filter(ubi => ubi.DistanciaRecorrida === "");
  if(whitoutDistance.length > 0) return {error: true, message: "Distancias en los destinos son obligatorias"};
  
  //Validar Fechas salida llegada
  if(!origen.FechaHoraSalidaLlegada) return {error: true, message: "No hay fecha salida"};
  if(!destino.FechaHoraSalidaLlegada) return {error: true, message: "No hay fecha llegada"};

  //Validar Tranporte
  if(!Mercancias.Autotransporte) return {error: true, message: "No hay transporte seleccionado"};

  //Validar Transportista
  if(!FiguraTransporte.length) return {error: true, message: "No hay transportista seleccionado"};

  //Validar Peso de items
  const SinPeso = Mercancias.Mercancia?.filter((ubi) => !ubi.PesoEnKg);
  if (SinPeso.length > 0) return {error: true, message: "Artículos sin peso"};


  return {error: false, message: ""}
};
