import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { RiFileExcel2Line } from "react-icons/ri";
import { exportToCSV } from "react-easy-export";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export default function ExcelExportButton({
  name = "export",
  data = [],
  getQueryData = null,
  loading = false,
  button = false,
  id = "",
  textButton = "Exportar"
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportPage = () => {
    if(!data.length) return
    exportToCSV(data, `${name}.csv`);
    handleClose();
  };
  const exportAllData = async () => {
    const response = await getQueryData();
    if (response) exportToCSV(response, `${name}.csv`);
    handleClose();
  };

  if (button) {
    return (
      <Button
        id={id}
        variant="contained"
        size="small"
        disableElevation
        onClick={exportPage}
        startIcon={
          loading ? (
            <CircularProgress size={20} color="inherit" />
          ) : (
            <RiFileExcel2Line style={{ fontSize: 19, margin: "2px 0" }} />
          )
        }
      >{textButton}</Button>
    );
  }

  return (
    <div>
      <Button
        id={id}
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        size="small"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <RiFileExcel2Line style={{ fontSize: 19, margin: "2px 0" }} />
        )}
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={exportPage} disableRipple>
          Exportal actual
        </MenuItem>
        <MenuItem onClick={exportAllData} disableRipple>
          Exportar todo
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
