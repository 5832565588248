import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SnackBarMessages from "../../../components/SnackBarMessages";
import { useQuery } from "@apollo/client";
import { OBTENER_PEDIDOS } from "../../../gql/Ventas/cotizaciones";
import DetallePedido from "./DetallePedido";
import CustomLoader from "../../../components/CustomLoader";
import { formatoMexico } from "../../../config/reuserFunctions";
import TomarPedido from "./TomarPedido";

const columns = [
  { id: "status", label: "Estado", minWidth: 20, align: "center" },
  { id: "folio", label: "Folio", minWidth: 20, align: "center" },
  { id: "cliente", label: "Cliente", minWidth: 100 },
  { id: "fecha", label: "Fecha", minWidth: 80, align: "center" },
  { id: "productos", label: "Productos", minWidth: 50, align: "center" },
  { id: "total", label: "Total", minWidth: 50, align: "center" },
];

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "70vh",
  },
});

export default function PedidosPendientes({ handleClose }) {
  const classes = useStyles();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [view, setView] = useState(false);
  const [selected, setSelected] = useState("");
  const [openMessage, setOpenMessage] = useState({
    message: "",
    status: "",
    open: false,
  });

  const { data, refetch, loading } = useQuery(OBTENER_PEDIDOS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
    },
    fetchPolicy: "network-only",
  });

  let rows = [];
  const handleCloseView = (e) => {
    e.stopPropagation();
    setView(false);
  };

  if (data) rows = data.obtenerPedidosVentas;
  if (loading) return <CustomLoader height="60vh" />;
  return (
    <Paper className={classes.root}>
      <SnackBarMessages alert={openMessage} setAlert={setOpenMessage} />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ width: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell align="center" padding="checkbox">
                Retomar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <CotizacionRow
                  key={index}
                  cotizacion={row}
                  handleClose={handleClose}
                  setView={setView}
                  setSelected={setSelected}
                  refetch={refetch}
                  openMessage={openMessage}
                  setOpenMessage={setOpenMessage}
                />
              );
            })}
          </TableBody>
        </Table>
        <DetallePedido
          cotizacion={selected}
          open={view}
          handleClose={handleCloseView}
          refetch={refetch}
        />
      </TableContainer>
    </Paper>
  );
}

const CotizacionRow = ({
  cotizacion,
  handleClose,
  setSelected,
  setView,
  setOpenMessage,
  openMessage,
}) => {
  const openDetail = (cotizacion) => {
    try {
      setSelected(cotizacion);
      setView(true);
    } catch (error) {}
  };

  try {
    return (
      <>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={cotizacion.code}
          onClick={() => openDetail(cotizacion)}
        >
          <TableCell align="center" style={{
            borderLeft: cotizacion.status === "REALIZADO" ? "7px solid #4caf50" : "none"
          }}>{cotizacion.status}</TableCell>
          <TableCell align="center">{cotizacion.folio}</TableCell>
          <TableCell>
            {cotizacion.cliente.nombre_cliente
              ? cotizacion.cliente.nombre_cliente
              : "Público general"}
          </TableCell>
          <TableCell align="center">{cotizacion.fecha_registro}</TableCell>
          <TableCell align="center">{cotizacion.productos.length}</TableCell>
          <TableCell align="center">
            {formatoMexico(cotizacion.total)}
          </TableCell>
          <TableCell align="center">
            <TomarPedido
              cotizacion={cotizacion}
              handleClose={handleClose}
              setOpenMessage={setOpenMessage}
              openMessage={openMessage}
            />
          </TableCell>
        </TableRow>
      </>
    );
  } catch (error) {}
};
