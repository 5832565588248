import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Image } from "@material-ui/icons";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function CardTest() {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={80}
        >
          <Image color="disabled" style={{ fontSize: 40 }} />
        </Box>
        <CardContent>
          <Typography variant="body1">Item</Typography>
          <Typography variant="caption">$1,234</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size="small"
          fullWidth
          color="secondary"
          variant="contained"
          style={{
            height: 20,
            fontSize: 10,
          }}
        >
          comprar
        </Button>
      </CardActions>
    </Card>
  );
}
