import React, { Fragment } from "react";
import { Box, makeStyles, Divider } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import cafiLogo from "../../../../../../img/logo_cafi_vertical.png";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../../../config/reuserFunctions";
import { deepPurple, grey } from "@material-ui/core/colors";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  table: {
    "& .MuiTableCell-root": {
      fontSize: 13,
      padding: "2px 10px",
    },
  },
  tableFooter: {
    "& .MuiTableCell-root": {
      padding: "0px 10px",
      border: "none",
    },
    "& .headertop": {
      //borderTop: "2px solid",
      borderColor: grey[600],
      paddingTop: theme.spacing(1),
    },
  },
  firmaBottom: {
    display: "none",
    [`@media print`]: {
      display: "flex",
    },
  },
  body: {
    [`@media print`]: {
      minHeight: "25cm",
      display: "flex",
      flexDirection: "column",
    },
  },
}));

export default function ContentDataDoc({ venta, cliente, refcomp, filter }) {
  const classes = useStyles();

  return (
    <Box ref={refcomp} p={4} className={classes.body}>
      <HeaderDoc cliente={cliente} filter={filter} />
      <VentaDoc venta={venta} cliente={cliente} />
      <Box flexGrow={1} />
      <FooterPage />
    </Box>
  );
}

const HeaderDoc = ({ cliente, filter }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  // obtener direccion
  let address = "";
  let municipio = "";
  let pais = "";
  const { direccion } = sesion.sucursal;
  if (
    direccion &&
    direccion.calle &&
    direccion.no_ext &&
    direccion.municipio &&
    direccion.estado &&
    direccion.pais
  ) {
    address = `${direccion.calle} #${direccion.no_ext}`;
    municipio = `${direccion.municipio}, ${direccion.estado}`;
    pais = direccion.pais;
  }

  const fechaBusqueda =
    filter.fecha_inicio !== "" && filter.fecha_fin !== ""
      ? `(Del ${formatoFechaCorta(
          filter.fecha_inicio
        ).toUpperCase()} al ${formatoFechaCorta(
          filter.fecha_fin
        ).toUpperCase()})`
      : "";

  return (
    <>
      <Box display="flex">
        <Box flexGrow={1}>
          <Typography style={{ fontSize: "22pt" }}>
            <b>Estado de cuenta</b>
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>{fechaBusqueda}</Typography>
        </Box>
        <Box mx={2}>
          <Typography style={{ fontSize: "12pt" }} align="right">
            <b>Fecha</b>
          </Typography>
          <Typography style={{ fontSize: "12pt" }} align="right">
            {formatoFechaCorta(new Date())}
          </Typography>
        </Box>
      </Box>
      <Divider style={{ margin: "4px 0", height: "2px" }} />
      <Box display="flex" gridGap={10} mb={1}>
        <Box
          style={{
            width: 150,
            height: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={sesion.empresa.imagen ? sesion.empresa.imagen : cafiLogo}
            alt="cafi punto de venta"
            style={{ maxWidth: "100%", minWidth: "100%" }}
          />
        </Box>
        <Box flexGrow={1}>
          <Typography style={{ fontSize: "10pt" }}>
            <b>{`${sesion.empresa.nombre_empresa} • Sucursal ${sesion.sucursal.nombre_sucursal}`}</b>
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Dirección: </b>
            {` ${address}, ${municipio}, ${pais}`}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>RFC: </b> {sesion.empresa.rfc}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Tel: </b> {sesion.empresa.telefono_dueno}
          </Typography>
          <Typography style={{ fontSize: "10pt" }}>
            <b>Vendedor: </b> {sesion.nombre}
          </Typography>
        </Box>
        <ClienteDoc cliente={cliente} />
      </Box>
    </>
  );
};
const ClienteDoc = ({ cliente }) => {
  if (!cliente) return null;

  const { direccion } = cliente;

  return (
    <Box>
      <Box>
        <Typography style={{ fontSize: "10pt" }} align="right">
          <b>{cliente?.nombre_cliente}</b>
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {`${direccion?.calle} #${direccion?.no_ext} col. ${direccion?.colonia} CP${direccion?.codigo_postal}`}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {`${direccion?.municipio}, ${direccion?.estado}, ${direccion?.pais}`}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {cliente?.telefono}
        </Typography>
        <Typography style={{ fontSize: "10pt" }} align="right">
          {cliente?.email}
        </Typography>
      </Box>
    </Box>
  );
};
const VentaDoc = ({ venta = [], cliente }) => {
  const classes = useStyles();

  let TOTAL = 0;
  let PAGADO = 0;
  const ventas = venta.filter(res => res.status !== "CANCELADO" )

  return (
    <>
      <Divider style={{ margin: "4px 0", height: "2px" }} />
      <TableContainer>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>FOLIO</TableCell>
              <TableCell>FECHA</TableCell>
              <TableCell>VENCIMIENTO</TableCell>
              <TableCell align="right">IMPORTE</TableCell>
              <TableCell align="right">POR PAGAR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ventas.map((item, index) => {
              TOTAL += item.total;
              PAGADO += item.total - item.saldo_credito_pendiente;
              const abonos = item.abonos.filter(res => res.status !== "CANCELADO" )
              return (
                <Fragment key={index}>
                  <TableRow style={{ backgroundColor: deepPurple[50] }}>
                    <TableCell>{item.folio}</TableCell>
                    <TableCell>
                      {moment(item.fecha_registro).format("l")}
                    </TableCell>
                    <TableCell>
                      {moment(item.fecha_de_vencimiento_credito).format("l")}
                    </TableCell>
                    <TableCell align="right">
                      ${formatoMexico(item.total)}
                    </TableCell>
                    <TableCell align="right">
                      <b>${formatoMexico(item.saldo_credito_pendiente)}</b>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box pb={1} display="flex" justifyContent="flex-end">
                        <Box>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell>Fecha</TableCell>
                                <TableCell align="right">Monto</TableCell>
                                <TableCell align="right">
                                  Método de pago
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {abonos?.map((abono, index) => {
                                return (
                                  <TableRow
                                    key={index}
                                    className={classes.tableFooter}
                                  >
                                    <TableCell>Pago {index + 1}</TableCell>
                                    <TableCell>
                                      {moment(
                                        abono.fecha_movimiento.completa
                                      ).format("l")}
                                    </TableCell>
                                    <TableCell align="right">
                                      $
                                      {formatoMexico(abono.monto_total_abonado)}
                                    </TableCell>
                                    <TableCell align="right">
                                      {abono.metodo_de_pago.metodo}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
            <TableRow className={classes.tableFooter}>
              <TableCell colSpan={4} className="headertop" align="right">
                <b>TOTAL CUENTA</b>
              </TableCell>
              <TableCell className="headertop" align="right">
                ${formatoMexico(TOTAL)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableFooter}>
              <TableCell colSpan={4} align="right">
                <b>PAGADO</b>
              </TableCell>
              <TableCell align="right">${formatoMexico(PAGADO)}</TableCell>
            </TableRow>
            <TableRow className={classes.tableFooter}>
              <TableCell colSpan={4} align="right">
                <b>POR PAGAR</b>
              </TableCell>
              <TableCell align="right">
                ${formatoMexico(cliente?.saldo_pendiente.total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
const FooterPage = () => {
  return (
    <Box display="flex" justifyContent="space-between" mt={1}>
      <Typography align="center" variant="caption" color="textSecondary">
        Creado desde CAFI punto de venta
      </Typography>
      <Typography align="right" variant="caption" color="textSecondary">
        {moment().tz("America/Mexico_City").format("lll")}
      </Typography>
    </Box>
  );
};
