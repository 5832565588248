import React from "react";
import { Container, Grid, Box } from "@material-ui/core";
import ReportesCompras from "./Compras/ReportesCompras";
import ReportesVentas from "./Ventas/ReportesVentas";
import ReportesAlmacen from "./Almacenes/ReportesAlmacen";
import ReportesCortes from "./Cortes/ReportesCortes";
import CajaIndex from "./Caja/CajaIndex";
import ReportesAbonos from "./Abonos/ReportesAbonos";
import ReportesAbonosProveedores from "./AbonosProveedores/ReportesProveedores";

export default function Reportes() {
  const permisosUsuario = JSON.parse(localStorage.getItem("sesionCafi"));

  return (
    <Container>
      <Grid container spacing={3} justifyContent="center">
        {permisosUsuario.accesos.reportes.reporte_historial_cajas.ver ===
        false ? null : (
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center">
              {/* <Cajas />
              <Caja /> */}
              <CajaIndex />
            </Box>
          </Grid>
        )}

        {permisosUsuario.accesos.reportes.reporte_abonos?.ver ? (
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ReportesAbonos />
            </Box>
          </Grid>
        ) : null}

        {permisosUsuario.accesos.reportes.reporte_abonos?.ver ? (
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ReportesAbonosProveedores />
            </Box>
          </Grid>
        ) : null}

        {/* {permisosUsuario.accesos.reportes.reporte_turnos.ver ===
        false ? null : (
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ReportesTurnosUsuarios />
            </Box>
          </Grid>
        )} */}
        {permisosUsuario.accesos.reportes.reporte_compras.ver ===
        false ? null : (
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ReportesCompras />
            </Box>
          </Grid>
        )}
        {permisosUsuario.accesos.reportes.reporte_ventas.ver ===
        false ? null : (
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ReportesVentas />
            </Box>
          </Grid>
        )}
        {permisosUsuario.accesos.reportes.rerporte_almacen.ver ===
        false ? null : (
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ReportesAlmacen />
            </Box>
          </Grid>
        )}
        {permisosUsuario.accesos.reportes.reporte_corte_caja.ver ===
        false ? null : (
          <Grid item>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ReportesCortes />
            </Box>
          </Grid>
        )}
        {/* {permisosUsuario.accesos.reportes.reporte_tesoreria.ver === false ? (null) : (
					<Grid item >
						<Box display="flex" justifyContent="center" alignItems="center">
							<ReportesTesoreria />
						</Box>
					</Grid>
				)} */}
      </Grid>
    </Container>
  );
}
