import React from "react";
import Button from "@material-ui/core/Button";
import { PowerSettingsNew } from "@material-ui/icons";
import { MainContext } from "../../../context/MainCtx";
import { useNavigate } from "react-router-dom";
import { VentasContext } from "../../../context/Ventas/ventasContext";

export default function CerrarSesion() {
  const { setTokenDecoded, turnoEnCurso, setTurnoEnCurso } = React.useContext(MainContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const navigate = useNavigate();
  const {
    abrirTurnosDialog,
    setAbrirTurnosDialog,
    setUbicacionTurno,
  } = React.useContext(VentasContext);

  const signOut = () => {
    if (sesion.turno_en_caja_activo === true && turnoEnCurso) {
      setAbrirTurnosDialog(!abrirTurnosDialog);
      setUbicacionTurno("SESION");
    } else {
      setTokenDecoded(null);
      localStorage.removeItem("sesionCafi");
      localStorage.removeItem("tokenCafi");
      localStorage.removeItem("DatosVentas");
      localStorage.removeItem("VentaOriginal");
      localStorage.removeItem("ListaEnEspera");
      setTurnoEnCurso(null)
      navigate("/");
    }
  };

  return (
    <Button
      color="inherit"
      onClick={signOut}
      startIcon={<PowerSettingsNew />}
      style={{ textTransform: "none" }}
    >
      Salir
    </Button>
  );
}
