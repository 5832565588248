import React from "react";
import CancelarVenta from "../../../ventas/Operaciones/CancelarVenta";
import CerrarVenta from "../../../ventas/CerrarVenta/CerrarVenta";
import { Box } from "@material-ui/core";
import VentaTotales from "./Totales";
import { VentasContext } from "../../../../context/Ventas/ventasContext";
import VentaEnEspera from "../../../ventas/Operaciones/VentaEnEspera";
import NotaCredito from "../../../ventas/NotaCredito/NotaCredito";

export default function BottomActions() {
  const { ventaRapida } = React.useContext(VentasContext);
  const datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));

  return (
    <Box>
      <VentaTotales />
      <Box
        display={ventaRapida ? "flex" : "none"}
        gridGap={10}
        justifyContent="flex-end"
        px={2}
        py={1}
      >
        <VentaEnEspera />
        <CancelarVenta />
        {datosVenta && datosVenta.nota_credito ? (
            <NotaCredito />
          ) : (
            <CerrarVenta />
          )}
      </Box>
    </Box>
  );
}
