import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { grey } from "@material-ui/core/colors";

export const CustoTextField = withStyles((theme) => ({
  root: {
    "& label.Mui-focused": {
      borderColor: grey[300],
    },
    "& .MuiInput-underline:after": {
      borderColor: grey[300],
    },
    "& .MuiOutlinedInput-root": {
      paddingLeft: 4,
      height: 32,
      "& fieldset": {
        borderColor: grey[300],
      },
      "& fieldset": {
        borderColor: grey[300],
      },
      "&:hover fieldset": {
        borderColor: grey[400],
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${grey[400]}`,
      },
    },
  },
}))(TextField);

