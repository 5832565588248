import React from "react";
import { Grid } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";
import EliminarCustomBanner from "./EliminarCustomBanner";
import PresetsCards from "./Presets/PresetsCards";

export default function ItemCustomBanner({ banner, provided }) {
  const { typeBanner } = banner;
  return (
    <Grid container spacing={1}>
      <Grid
        item
        xs={2}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "grab",
        }}
        {...provided.dragHandleProps}
      >
        <DragIndicator color="action" style={{ marginLeft: 4 }} />
      </Grid>
      <Grid item xs={8}>
        <PresetsCards banner={banner} type={typeBanner} />
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EliminarCustomBanner banner={banner} />
      </Grid>
    </Grid>
  );
}
