import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { ArrowBack, ClearOutlined, Search } from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContent from "@material-ui/core/DialogContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import { AbonosCtx } from "../../../../../context/Tesoreria/abonosCtx";
import TablaVentasCredito from "./Components/TablaVentasCredito";
import ExportarCuentasPorCobrar from "./Components/ExportarCuentasPorCobrar";
import { useQuery } from "@apollo/client";
import { OBTENER_HISTORIAL_ABONOS_CLIENTE } from "../../../../../gql/Tesoreria/abonos";
import { useDebounce } from "use-debounce";
import TicketPrinterComponent from "../../../../../components/Perifericos/TicketPrinter";
import { MdPayments } from "react-icons/md";
import { MainContext } from "../../../../../context/MainCtx";
import { FcDonate } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  titleSelect: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: 18,
  },
  icon: {
    width: 100,
  },
  formInputFlex: {
    display: "flex",
    "& > *": {
      margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  },
  formInput: {
    margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  iconSize: {
    width: 32,
  },
  borderBotonChico: {
    minWidth: "100%",
    minHeight: "100%",
    border: ".6px solid #ededed",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const initial_state_filtro = {
  cliente: "",
  fecha_inicio: "",
  fecha_fin: "",
  folio: "",
  vencidas: false,
  vigentes: false,
  pagadas: false,
  canceladas: false,
};

export default function AbonosClientes(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const context = React.useContext(AbonosCtx);
  const [loading, setLoading] = React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState("");
  const [pageHistorial, setPageHistorial] = React.useState(0);
  const limit = 20;
  const [values, setValues] = React.useState(initial_state_filtro);
  const { turnoEnCurso } = React.useContext(MainContext);
  //const searchfilter = useRef(null);
  const [value] = useDebounce(values, 1000);
  let historialVentasACredito = { docs: [], totalDocs: 0 };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.keyCode === 117 && props.fromVentas) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open]);

  const obtenerVentasCredito = useQuery(OBTENER_HISTORIAL_ABONOS_CLIENTE, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: "",
      //idCliente: selectedClient._id,
      filtro: value,
      limit,
      offset: pageHistorial,
    },
    fetchPolicy: "network-only",
  });

  const handleClickOpen = () => {
    setOpen(!open);
    if (!open) {
      obtenerVentasCredito.refetch();
    }
  };

  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const obtenerChecks = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
  };

  const setCheckVencidas = (e) => {
    obtenerChecks(e);

    setValues({
      ...values,
      vencidas: e.target.checked,
      vigentes: false,
      pagadas: false,
    });
  };

  const setCheckVigentes = (e) => {
    obtenerChecks(e);
    setValues({
      ...values,
      vencidas: false,
      vigentes: e.target.checked,
      canceladas: false,
      pagadas: false,
    });
  };

  const setCheckPagadas = (e) => {
    obtenerChecks(e);
    setValues({
      ...values,
      vencidas: false,
      vigentes: false,
      canceladas: false,
      pagadas: e.target.checked,
    });
  };
  const setCheckCanceladas = (e) => {
    obtenerChecks(e);
    setValues({
      ...values,
      vencidas: false,
      vigentes: false,
      pagadas: false,
      canceladas: e.target.checked,
    });
  };
  const limpiarFiltros = () => {
    setValues(initial_state_filtro);
  };

  React.useEffect(() => {
    setLoading(obtenerVentasCredito.loading);
  }, [obtenerVentasCredito.loading]);

  React.useEffect(() => {
    try {
      if (obtenerVentasCredito.data) {
        let arrayToset = [];

        let total_ventas = 0;
        if (obtenerVentasCredito.data.historialVentasACredito.docs.length > 0) {
          obtenerVentasCredito.data.historialVentasACredito.docs.forEach(
            (element) => {
              total_ventas += element.saldo_credito_pendiente;

              arrayToset.push({
                id_venta: element._id,
                monto_total_abonado: 0,
                saldo_credito_pendiente: parseFloat(
                  element.saldo_credito_pendiente
                ),
              });
            }
          );
          context.setAbonos(arrayToset);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [obtenerVentasCredito.data]);

  if (obtenerVentasCredito.data) {
    historialVentasACredito = obtenerVentasCredito.data.historialVentasACredito;
  }


  const enviarCantidad = (cantidad, index, _id) => {
    let copyArray = [...context.abonos];

    if (cantidad > 0) {
      copyArray.splice(index, 1, {
        id_venta: _id,
        monto_total_abonado: cantidad,
      });
    } else {
      copyArray.splice(index, 1);
    }
    context.setAbonos(copyArray);
  };

  return (
    <React.Fragment>
      {props.fromVentas ? (
        <Button
          className="button-panel-venta b-secondary"
          fullWidth
          variant="outlined"
          onClick={handleClickOpen}
          disabled={!turnoEnCurso}
        >
          <Box>
            <MdPayments className="icon-btn-secondary" />
            <Typography className="text-btn-panel">Abonos</Typography>
            <Typography className="text-btn-panel-sec">F6</Typography>
          </Box>
        </Button>
      ) : (
        <Button fullWidth onClick={handleClickOpen}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <FcDonate style={{ fontSize: 100 }} />
            </Box>
            Cuentas por cobrar
          </Box>
        </Button>
      )}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
      >
        <Typography variant="h6" className={classes.title}>
          Cuentas por cobrar
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <Button
            color="primary"
            startIcon={<ClearOutlined />}
            onClick={() => limpiarFiltros()}
          >
            Limpiar filtros
          </Button>
          <Box mx={1} />
          <ExportarCuentasPorCobrar
            empresa={sesion.empresa._id}
            sucursal={sesion.sucursal._id}
            value={values}
          />
          <Box mx={1} />
          <TicketPrinterComponent turnoEnCurso={true} icon="config" />
          <Box mx={1} />
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <DialogContent>
          <Box>
            <Grid container>
              <Grid item md={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item md={3}>
                    <Typography>Buscar cliente:</Typography>
                    <TextField
                      fullWidth
                      placeholder="Clave, No., Nombre, Teléfono, Correo..."
                      onChange={(e) => obtenerCampos(e)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                      value={values.cliente}
                      variant="outlined"
                      size="small"
                      name="cliente"
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Typography>Desde:</Typography>
                    <TextField
                      fullWidth
                      onChange={(e) => obtenerCampos(e)}
                      type="date"
                      value={values.fecha_inicio}
                      variant="outlined"
                      size="small"
                      name="fecha_inicio"
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Typography>Hasta:</Typography>
                    <TextField
                      fullWidth
                      onChange={(e) => obtenerCampos(e)}
                      type="date"
                      value={values.fecha_fin}
                      variant="outlined"
                      size="small"
                      name="fecha_fin"
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Typography>Folio:</Typography>
                    <TextField
                      fullWidth
                      onChange={(e) => obtenerCampos(e)}
                      type="text"
                      value={values.folio}
                      variant="outlined"
                      size="small"
                      name="folio"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.vencidas}
                        onChange={setCheckVencidas}
                        name="vencidas"
                        color="default"
                      />
                    }
                    label="Vencidas"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.vigentes}
                        onChange={setCheckVigentes}
                        name="vigentes"
                        color="default"
                      />
                    }
                    label="Vigentes"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.pagadas}
                        onChange={setCheckPagadas}
                        name="pagadas"
                        color="default"
                      />
                    }
                    label="Pagadas"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.canceladas}
                        onChange={setCheckCanceladas}
                        name="canceladas"
                        color="default"
                      />
                    }
                    label="Canceladas"
                  />
                </Grid>
                <Box mx={1} />
                <Grid item>
                  <Box my={1} display="flex">
                    <React.Fragment>
                      <Box
                        border={1}
                        mt={0.5}
                        borderColor="#58ff8f"
                        bgcolor="#EDFFF3"
                        height="18px"
                        width="18px"
                      />
                      <Box mx={1} />
                      <Typography>
                        <b>Pagadas</b>
                      </Typography>
                      <Box mx={1} />
                    </React.Fragment>

                    <Box
                      border={1}
                      mt={0.5}
                      borderColor="#FFCB77"
                      bgcolor="#FFF4E1"
                      height="18px"
                      width="18px"
                    />
                    <Box mx={1} />
                    <Typography>
                      <b>Vencidas</b>
                    </Typography>
                    <Box mx={1} />
                    <Box
                      border={1}
                      mt={0.5}
                      borderColor="#FF8A8A"
                      bgcolor="#FFF4F4"
                      height="18px"
                      width="18px"
                    />
                    <Box mx={1} />
                    <Typography>
                      <b>Canceladas</b>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box mt={2}>
            {loading ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="50vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <TablaVentasCredito
                rows={historialVentasACredito}
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
                setOpen={setOpen}
                enviarCantidad={enviarCantidad}
                setLoading={setLoading}
                recargar={obtenerVentasCredito.refetch}
                limit={limit}
                page={pageHistorial}
                setPage={setPageHistorial}
              />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
