import * as React from "react";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { formatoFechaCorta } from "../../../../../config/reuserFunctions";
import RecibirTraspaso from "../Actions/GetTraspaso";
import TableProdcutos from "./TableProductos";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

export default function TraspasosTableBody({ traspasos }) {
  return (
    <TableBody>
      {traspasos.map((traspaso) => (
        <RenderRows key={traspaso._id} traspaso={traspaso} />
      ))}
    </TableBody>
  );
}

const RenderRows = ({ traspaso }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow role="checkbox" tabIndex={-1}>
        <TableCell padding="checkbox" style={{padding: "0px 8px"}}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox" style={{padding: 0}}>
          <RecibirTraspaso traspaso={traspaso} />
        </TableCell>
        <TableCell>{formatoFechaCorta(traspaso.fecha_registro)}</TableCell>
        <TableCell>{traspaso.usuario?.nombre || ""}</TableCell>
        <TableCell>{traspaso.traspasoEntreSucursales ? "SI" : "NO"}</TableCell>
        <TableCell>
          {traspaso.traspasoEntreSucursales
            ? traspaso.aceptado
              ? "SI"
              : "NO"
            : "N/A"}
        </TableCell>
        <TableCell>
          {traspaso.concepto_traspaso?.nombre_concepto || ""}
        </TableCell>
        <TableCell>
          {traspaso.almacen_origen?.nombre_almacen
            ? `${traspaso.sucursalOrigen?.nombre_sucursal || ""}, Alm. ${
                traspaso.almacen_origen?.nombre_almacen || ""
              }`
            : "N/A"}
        </TableCell>
        <TableCell>
          {traspaso.almacen_destino?.nombre_almacen
            ? `${traspaso.sucursalDestino?.nombre_sucursal || ""}, Alm. ${
                traspaso.almacen_destino?.nombre_almacen || ""
              }`
            : "N/A"}
        </TableCell>
      </TableRow>
      <TableProdcutos row={traspaso} open={open} />
    </>
  );
};
