import { blue, grey, pink } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	palette: {
		navbar: grey[50],
		primary: {
			main: "#2222A8"
		},
		secondary: {
			//main: '#f50057'
			main: '#f50057'
		},
		cafiPaper: "#3F3F3F",
		cafiPrimary: "#6B17CC",
		cafiSecondary: {
			main: "#2222A8"
		},
		original_primary: {
			main: blue[500]
		},
		original_secondary: {
			main: pink[500]
		},
	}
});

export default theme;
