import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Add, ArrowBack, Close, Done, Edit } from "@material-ui/icons";
import SnackBarMessages from "../../../../../../components/SnackBarMessages";
import { useMutation } from "@apollo/client";
import { CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { confAutotransporte, tipoPermiso, tipoRemolque } from "./catalogs";
import {
  CREATE_TRANSPORTE,
  UPDATE_TRANSPORTE,
} from "../../../../../../gql/Facturacion/CartaPorte";

const initial_transporte = {
  tagIdentificador: "",
  PermSCT: "",
  NumPermisoSCT: "",
  IdentificacionVehicular: {
    ConfigVehicular: "",
    PlacaVM: "",
    AnioModeloVM: "",
    PesoBrutoVehicular: "",
  },
  Seguros: {
    AseguraRespCivil: "",
    PolizaRespCivil: "",
  },
  Remolques: [],
};

export default function CrearTransporte({ edit, datos, refetch }) {
  const [data, setData] = React.useState(initial_transporte);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    message: "",
    status: "",
    open: false,
  });
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const handleClickOpen = () => {
    setOpen(true);
    if (edit && datos) setData(datos);
  };

  const handleClose = () => {
    setOpen(false);
    setData(initial_transporte);
  };

  /* Mutations */
  const [crearTransporte] = useMutation(CREATE_TRANSPORTE);
  const [actualizarTransporte] = useMutation(UPDATE_TRANSPORTE);

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (edit) {
        const { _id, ...input } = data;
        const res = await actualizarTransporte({
          variables: {
            idTransporte: data._id,
            input,
          },
        });
        setAlert({
          message: res.data.actualizarTransporte.message,
          status: "success",
          open: true,
        });
      } else {
        const res = await crearTransporte({
          variables: {
            empresa: sesion.empresa._id,
            sucursal: sesion.sucursal._id,
            input: data,
          },
        });
        setAlert({
          message: res.data.crearTransporte.message,
          status: "success",
          open: true,
        });
      }
      setLoading(false);
      refetch();
      handleClose();
    } catch (error) {
      setAlert({ message: error.message, status: "error", open: true });
      setLoading(false);
      console.log(error);
      if (error.networkError) {
        console.log(error.networkError.result);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors);
      }
    }
  };

  const handleChange = (name, value) => {
    setData((st) => ({
      ...st,
      [name]: value,
    }));
  };
  const getIdVehicular = (name, value) => {
    setData((st) => ({
      ...st,
      IdentificacionVehicular: {
        ...st.IdentificacionVehicular,
        [name]: value,
      },
    }));
  };
  const getSeguros = (name, value) => {
    setData((st) => ({
      ...st,
      Seguros: {
        ...st.Seguros,
        [name]: value,
      },
    }));
  };
  const getRemolques = (name, value, index) => {
    const Remolques = [...data.Remolques];
    const newData = {
      ...data.Remolques[index],
      [name]: value,
    };
    Remolques.splice(index, 1, newData);
    setData((st) => ({
      ...st,
      Remolques,
    }));
  };

  const addRemolque = () => {
    const Remolques = [...data.Remolques];
    Remolques.push({
      SubTipoRem: "",
      Placa: "",
    });
    setData((st) => ({
      ...st,
      Remolques,
    }));
  };
  const removeRemolque = (index) => {
    const Remolques = [...data.Remolques];
    Remolques.splice(index, 1);
    setData((st) => ({
      ...st,
      Remolques,
    }));
  };

  return (
    <Fragment>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      {edit ? (
        <IconButton size="small" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      ) : (
        <Button
          size="small"
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleClickOpen}
          startIcon={<Add />}
        >
          Nuevo
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box display="flex" alignItems="center" gridGap={10}>
            <IconButton color="primary" onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Nuevo</Typography>
            <Box flexGrow={1} />
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={loading}
              type="submit"
              form="transporte-form"
              disableElevation
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ marginBottom: 20 }}>
          <form id="transporte-form" onSubmit={handleSave}>
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              label="Nombre identificador"
              onChange={(e) => handleChange("tagIdentificador", e.target.value)}
              value={data.tagIdentificador}
              required
              fullWidth
            />
            <Box display="flex" gridGap={10}>
              <Autocomplete
                fullWidth
                options={tipoPermiso}
                getOptionLabel={(option) => option?.value || option}
                getOptionSelected={(option, value) => option?.value === value}
                onChange={(_, option) => handleChange("PermSCT", option?.value)}
                value={data.PermSCT || null}
                renderOption={(option) => (
                  <li key={option?.value}>
                    <b>{option?.value}</b> - {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Permiso SCT"
                    margin="dense"
                    variant="outlined"
                    required
                  />
                )}
              />
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="Número de permiso"
                onChange={(e) => handleChange("NumPermisoSCT", e.target.value)}
                value={data.NumPermisoSCT}
                required
                fullWidth
              />
            </Box>
            <Box mt={2}>
              <Typography>
                <b>Identificación vehicular</b>
              </Typography>
              <Box display="flex" gridGap={10}>
                <Autocomplete
                  fullWidth
                  options={confAutotransporte}
                  getOptionLabel={(option) => option?.value || option}
                  getOptionSelected={(option, value) => option?.value === value}
                  onChange={(_, option) =>
                    getIdVehicular("ConfigVehicular", option?.value)
                  }
                  value={data.IdentificacionVehicular.ConfigVehicular || null}
                  renderOption={(option) => (
                    <li key={option?.value}>
                      <b>{option?.value}</b> - {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Configuración vehicular"
                      margin="dense"
                      variant="outlined"
                      required
                    />
                  )}
                />
                <TextField
                  variant="outlined"
                  size="small"
                  margin="dense"
                  placeholder="sin guiones"
                  label="Placas"
                  onChange={(e) => getIdVehicular("PlacaVM", e.target.value)}
                  value={data.IdentificacionVehicular.PlacaVM}
                  required
                />
                <TextField
                  variant="outlined"
                  size="small"
                  margin="dense"
                  label="Modelo"
                  onChange={(e) =>
                    getIdVehicular("AnioModeloVM", e.target.value)
                  }
                  value={data.IdentificacionVehicular.AnioModeloVM}
                  required
                />
              </Box>
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="Peso Bruto (Tn)"
                onChange={(e) => getIdVehicular("PesoBrutoVehicular", e.target.value)}
                value={data.IdentificacionVehicular.PesoBrutoVehicular}
                required
              />
            </Box>
            <Box mt={2}>
              <Typography>
                <b>Seguro</b>
              </Typography>
              <Box display="flex" gridGap={10}>
                <TextField
                  variant="outlined"
                  size="small"
                  margin="dense"
                  label="Nombre aseguradora"
                  onChange={(e) =>
                    getSeguros("AseguraRespCivil", e.target.value)
                  }
                  value={data.Seguros.AseguraRespCivil}
                  required
                />
                <TextField
                  variant="outlined"
                  size="small"
                  margin="dense"
                  label="Póliza"
                  name="PolizaRespCivil"
                  onChange={(e) =>
                    getSeguros("PolizaRespCivil", e.target.value)
                  }
                  value={data.Seguros.PolizaRespCivil}
                  required
                />
              </Box>
            </Box>
            <Box mt={2}>
              <Typography>
                <b>Remolques</b>
              </Typography>
              {data.Remolques.map((res, index) => (
                <Box display="flex" gridGap={10} alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    gridGap={5}
                    width={250}
                  >
                    <IconButton
                      size="small"
                      onClick={() => removeRemolque(index)}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                    <Typography>
                      <b>Remolque #{index + 1}</b>
                    </Typography>
                  </Box>
                  <Autocomplete
                    fullWidth
                    options={tipoRemolque}
                    getOptionLabel={(option) => option?.value || option}
                    getOptionSelected={(option, value) =>
                      option?.value === value
                    }
                    onChange={(_, option) =>
                      getRemolques("SubTipoRem", option.value, index)
                    }
                    value={res.SubTipoRem || null}
                    renderOption={(option) => (
                      <li key={option?.value}>
                        <b>{option?.value}</b> - {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Subtipo remolque"
                        margin="dense"
                        variant="outlined"
                        required
                      />
                    )}
                  />
                  <TextField
                    variant="outlined"
                    size="small"
                    margin="dense"
                    label="Placa"
                    placeholder="sin guiones"
                    onChange={(e) =>
                      getRemolques("Placa", e.target.value, index)
                    }
                    value={res.Placa}
                    required
                  />
                </Box>
              ))}
              <Box>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<Add />}
                  onClick={addRemolque}
                >
                  Añadir
                </Button>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
