import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import { CropOriginal, LocalOffer, Menu } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { Img } from "react-image";
import { formatoMexico } from "../../../config/reuserFunctions";
import { Chip, useTheme } from "@material-ui/core";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "40vw",
    height: "calc(100vh - 72px)",
    top: 58,
    right: 20,
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    }
  },
  colorContainer: {
    height: 18,
    borderRadius: 3,
    fontSize: 12,
  },
}));

export default function DetalleProductoVentas({ producto }) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { datos_generales, precios } = producto.id_producto;
  const { inventario_general, unidad, cantidad } = producto;

  const handleDrawerToogle = () => {
    setOpen(!open);
  };

  const ComponenteMedidaColor = () => {
    if (producto.color && producto.medida) {
      return (
        <Box display="flex" gridGap={3}>
          <Chip
            size="small"
            label={`T${producto.medida.talla}`}
            className={classes.colorContainer}
          />
          <Chip
            size="small"
            label={`${producto.color.nombre}`}
            className={classes.colorContainer}
            style={{
              backgroundColor: producto.color.hex,
              color: theme.palette.getContrastText(producto.color.hex),
            }}
          />
        </Box>
      );
    } else {
      return null;
    }
  };

  const getCantidad = () => {
    if (datos_generales.tipo_producto === "OTROS") {
      return unidad === "Costal" || unidad === "Caja"
        ? inventario_general[0].cantidad_existente_maxima
        : inventario_general[0].cantidad_existente;
    } else {
      return cantidad;
    }
  };

  const getDescuento = () => {
    if (producto.descuento_activo) {
      const { dinero_descontado, porciento } = producto.descuento;
      return `-$${formatoMexico(dinero_descontado)} %${porciento.toFixed(2)}`;
    } else {
      return "$0.00";
    }
  };

  const getPrecioDescuento = () => {
    if (producto.descuento_activo) {
      const { precio_neto } = producto.descuento;
      return `$${formatoMexico(precio_neto)}`;
    } else {
      return `$${formatoMexico(producto.precio)}`;
    }
  };

  function MyImageComponent() {
    let imagen = "";
    if (
      producto.id_producto.imagenes &&
      producto.id_producto.imagenes.length > 0
    ) {
      imagen = producto.id_producto.imagenes[0].url_imagen;
    }
    return imagen ? (
      <Img
        src={[imagen]}
        style={{ maxHeight: "100%", maxWidth: "100%", borderRadius: 5 }}
      />
    ) : (
      <CropOriginal fontSize="large" color="disabled" />
    );
  }

  return (
    <React.Fragment>
      <IconButton onClick={handleDrawerToogle} size="small">
        <Menu />
      </IconButton>
      <Drawer
        className={classes.drawer}
        anchor="right"
        open={open}
        classes={{ paper: classes.drawerPaper }}
        elevation={0}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0)",
          },
        }}
        onClose={handleDrawerToogle}
      >
        <DialogTitle component="div">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              {datos_generales.nombre_comercial}
            </Typography>
            <Button
              size="small"
              color="inherit"
              variant="contained"
              disableElevation
              style={{ textTransform: "none" }}
              onClick={handleDrawerToogle}
              startIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" gridGap={10}>
            <Box
              height={100}
              width={100}
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius={5}
            >
              <MyImageComponent />
            </Box>
            <Box py={1}>
              <Box display="flex" gridGap={5}>
                <Typography variant="subtitle2">{`${datos_generales.nombre_comercial}`}</Typography>
                <ComponenteMedidaColor />
              </Box>
              <Typography variant="caption">{`${datos_generales.clave_alterna} ${datos_generales.codigo_barras}`}</Typography>

              <Box display="flex" gridGap={5}>
                <Typography>{getPrecioDescuento()}</Typography>
                {producto.descuento_activo ? (
                  <Chip
                    icon={
                      <LocalOffer
                        style={{ color: "white", fontSize: "8px!important" }}
                      />
                    }
                    style={{ backgroundColor: green[400], color: "white" }}
                    size="small"
                    label={`-${formatoMexico(
                      producto.descuento?.porciento,
                      0
                    )}%`}
                  />
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gridGap={10}>
            <Box p={1}>
              <Typography variant="subtitle2">Información adicional</Typography>
              <Divider />
              <Box display="flex" gridGap={10}>
                <Box>
                  <Typography variant="body2">Categoria</Typography>
                  <Typography variant="caption">
                    {datos_generales.categoria || "No especificado"}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">Subcategoria</Typography>
                  <Typography variant="caption">
                    {datos_generales.subcategoria || "No especificado"}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">Departamento</Typography>
                  <Typography variant="caption">
                    {datos_generales.departamento || "No especificado"}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">Marca</Typography>
                  <Typography variant="caption">
                    {datos_generales.marca || "No especificado"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant="body2">Clave SAT</Typography>
                <Typography variant="caption">
                  {datos_generales.clave_producto_sat.Value || ""}
                </Typography>
                <Typography variant="caption">
                  {`${datos_generales.clave_producto_sat.Name || ""}`}
                </Typography>
              </Box>
            </Box>
            <Box p={1}>
              <Typography variant="subtitle2">Existencias</Typography>
              <Divider />
              <Box display="flex" gridGap={10}>
                <Box>
                  <Typography variant="body2">Unidad</Typography>
                  <Typography variant="caption">{unidad}</Typography>
                </Box>
                <Box>
                  <Typography variant="body2">Existencias</Typography>
                  <Typography variant="caption">{`${getCantidad()} unidades`}</Typography>
                </Box>
              </Box>
            </Box>
            <Box p={1}>
              <Typography variant="subtitle2">Precios y descuentos</Typography>
              <Divider />
              <Box display="flex" gridGap={10}>
                <Box>
                  <Typography variant="body2">Original</Typography>
                  <Typography variant="caption">
                    ${formatoMexico(producto.precio)}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">Con descuento</Typography>
                  <Typography variant="caption">
                    {getPrecioDescuento()}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2">Descontado</Typography>
                  <Typography variant="caption">{getDescuento()}</Typography>
                </Box>
              </Box>
              <RenderPrecios precios={precios} />
            </Box>
          </Box>
        </DialogContent>
      </Drawer>
    </React.Fragment>
  );
}

const RenderPrecios = ({ precios }) => {
  const prices = precios.precios_producto || [];
  return (
    <Box mt={1} display="flex" gridGap={10}>
      {prices.map((res, index) => {
        return res.precio_neto ? (
          <Box key={index}>
            <Typography variant="subtitle2">
              Precio {res.numero_precio}
            </Typography>
            <Typography variant="caption">
              ${formatoMexico(res.precio_neto)}
            </Typography>
            <Typography variant="caption" style={{ display: "block" }}>
              desde {res.unidad_mayoreo} unidades
            </Typography>
          </Box>
        ) : null;
      })}
    </Box>
  );
};
