import { gql } from "@apollo/client";

export const GetProductAlmacens = gql`
  query getProductAlmacens(
    $input: InputProductosAlmacenes
    $limit: Int
    $offset: Int
  ) {
    getProductAlmacens(input: $input, limit: $limit, offset: $offset) {
      totalDocs
      almacenes {
        _id
        nombre_almacen
        default_almacen
      }
      docs {
        _id
        almacenes {
          _id
          nombre_almacen
          existencia {
            _id
            cantidad_existente
          }
        }
        codigo_barras
        color {
          _id
          hex
          nombre
        }
        medida {
          _id
          talla
          tipo
        }
        concepto
        id_producto
        unidad
        precio_unidad {
          precio_neto
          precio_venta
        }
        productoData {
          _id
          datos_generales {
            codigo_barras
            clave_alterna
            tipo_producto
            nombre_comercial
            nombre_generico
            descripcion
            categoria
            subcategoria
            departamento
            marca
            receta_farmacia
          }
          precios {
            granel
            ieps
            ieps_activo
            iva
            iva_activo
            litros
            monedero
            monedero_electronico
            precio_de_compra {
              ieps
              iva
              precio_con_impuesto
              precio_sin_impuesto
            }
            unidad_de_compra {
              cantidad
              precio_unitario_con_impuesto
              precio_unitario_sin_impuesto
              unidad
              codigo_unidad
            }
            inventario {
              inventario_minimo
              inventario_maximo
              unidad_de_inventario
              codigo_unidad
            }
          }
          withoutInventory
        }
        cantidadTotalAlmacenes {
          _id
          cantidad_existente
        }
        costoTotal
      }
    }
  }
`;