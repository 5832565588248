import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputAdornment from "@material-ui/core/InputAdornment";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import { Search } from "@material-ui/icons";
import ListaProductos from "./ListaProductos";
import { useQuery } from "@apollo/client";
import { CONSULTA_PRODUCTOS_VENTAS } from "../../../gql/Ventas/ventas_generales";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import {
  calculatePrices2,
  findProductArray,
  verifiPrising,
} from "../../../config/reuserFunctions";
import { HiSearchCircle } from "react-icons/hi";
import { MainContext } from "../../../context/MainCtx";
import { CustomSearchVentaRapidaTF } from "../../../components/CustomMuiComponents";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "40vw",
    height: "calc(100vh - 72px)",
    top: 58,
    right: 20,
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      width: "50vw",
    },
  },
}));

const inital_state_filtros = { producto: "", cat: "" };

export default function BuscarProducto() {
  const classes = useStyles();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  let datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
  const {
    updateTablaVentas,
    setUpdateTablaVentas,
    setDatosVentasActual,
    openBuscarProducto,
    setOpenBuscarProducto,
    reloadProductos,
    priceSelected,
  } = React.useContext(VentasContext);
  const { snackMessage, turnoEnCurso } = React.useContext(MainContext);
  const [searchProducto, setSearchProducto] = React.useState(
    inital_state_filtros
  );
  //const [productoSeleccionado, setProductoSeleccionado] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const limit = 20;
  const inputProductos = React.useRef(null);
  const { cat, producto } = searchProducto;
  //const timer = (cat && !producto) || (!cat && !producto) ? 0 : 500;
  //const [value] = useDebounce(searchProducto, timer);

  const { data, refetch, loading, error } = useQuery(
    CONSULTA_PRODUCTOS_VENTAS,
    {
      variables: {
        empresa: sesion.empresa._id,
        sucursal: sesion.sucursal._id,
        input: inital_state_filtros,
        limit,
        offset: page,
      },
      fetchPolicy: "network-only",
    }
  );

  let productosBusqueda = null;

  if (data) productosBusqueda = data.obtenerProductosVentas;

  const obtenerDatos = (name, value) => {
    setSearchProducto({ ...searchProducto, [name]: value });
    if(!value){
      refetch({
        empresa: sesion.empresa._id,
        sucursal: sesion.sucursal._id,
        input: inital_state_filtros,
        limit,
        offset: 0,
      });
    }
  };

  const cleanFiltros = (e) => {
    setSearchProducto(inital_state_filtros);
  };

  const handleClickOpen = () => {
    //setProductoSeleccionado([]);
    //productosBusqueda = [];
    setOpenBuscarProducto(!openBuscarProducto);
    setSearchProducto(inital_state_filtros);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    refetch({
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: {
        producto,
        cat,
      },
      limit,
      offset: 0,
    });
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 80) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [openBuscarProducto]);

  React.useEffect(() => {
    cleanFiltros();
    refetch({
      variables: {
        empresa: sesion.empresa._id,
        sucursal: sesion.sucursal._id,
        input: {
          producto: "",
          cat: "",
        },
        limit,
        offset: 0,
      },
    });
  }, [reloadProductos]);

  const agregarProductos = async (producto, value = 1) => {
    let unidad = producto.unidad.toUpperCase();
    if (!producto.id_producto.withoutInventory) {
      let productoVenta = null;
      const inventario = producto.inventario_general[0];
      if (datosVentas && datosVentas.productos.length > 0) {
        const prod = datosVentas.productos.filter(
          (res) => res._id === producto._id
        );
        if (prod.length > 0) productoVenta = prod[0];
      }
      //console.log({cantidad: producto.cantidad, inventario: producto.inventario_general[0]});
      //console.log(productoVenta);
      if (producto.concepto === "medidas") {
        //cantidad solo en Pz
        if (!productoVenta) {
          if (value > producto.cantidad) {
            snackMessage({
              message: "No hay existencias",
              variant: "info",
            });
            return;
          }
        } else {
          if (productoVenta.cantidad_venta + value > producto.cantidad) {
            snackMessage({
              message: "No hay existencias",
              variant: "info",
            });
            return;
          }
        }
      } else {
        //inventario_general
        if (unidad === "CAJA" || unidad === "COSTAL") {
          //valida existencia en productoAlmacen
          if (!productoVenta) {
            if (value > inventario.cantidad_existente_maxima) {
              snackMessage({
                message: "No hay existencias",
                variant: "info",
              });
              return;
            }
          } else {
            if (
              productoVenta.cantidad_venta + value >
              inventario.cantidad_existente_maxima
            ) {
              snackMessage({
                message: "No hay existencias",
                variant: "info",
              });
              return;
            }
          }
        } else if (unidad === "PZ" || unidad === "LT" || unidad === "KG") {
          //valida existencia en productoAlmacen
          if (!productoVenta) {
            if (value > inventario.cantidad_existente) {
              snackMessage({
                message: "No hay existencias",
                variant: "info",
              });
              return;
            }
          } else {
            if (
              productoVenta.cantidad_venta + value >
              inventario.cantidad_existente
            ) {
              snackMessage({
                message: "No hay existencias",
                variant: "info",
              });
              return;
            }
          }
        }
      }
    }

    let granel_base = {
      granel: false,
      valor: 0,
    };
    if (unidad === "KG" || unidad === "COSTAL") {
      granel_base = {
        granel: true,
        valor: value,
      };
    }

    let venta = JSON.parse(localStorage.getItem("DatosVentas"));
    let productosVentas = venta === null ? [] : venta.productos;
    let venta_actual = venta === null ? [] : venta;
    let venta_existente =
      venta === null
        ? {
            subTotal: 0,
            total: 0,
            impuestos: 0,
            iva: 0,
            ieps: 0,
            descuento: 0,
            monedero: 0,
          }
        : venta;

    let productosVentasTemp = productosVentas;

    let CalculosData = {
      subTotal: 0,
      total: 0,
      impuestos: 0,
      iva: 0,
      ieps: 0,
      descuento: 0,
      monedero: 0,
    };

    const producto_encontrado = await findProductArray(producto);

    if (!producto_encontrado.found && producto._id) {
      if (value === -1) return;
      const newP = { ...producto };
      //Tomar el precio con descuento o normal
      let productoPrecioFinal = newP.descuento_activo
        ? newP.descuento.precio_neto
        : newP.precio_unidad.precio_neto;

      let new_prices = await calculatePrices2({
        newP,
        cantidad: 0,
        granel: granel_base,
        origen: "Ventas1",
        precio_boolean: false,
      });

      //vericiar si se eligio uno de los 6 precios
      const { precios_producto } = producto.id_producto.precios;

      if (priceSelected > 1) {
        const price_found = precios_producto.filter(
          ({ precio_neto, numero_precio }) =>
            priceSelected === numero_precio && precio_neto > 0
        );
        if (price_found.length) {
          productoPrecioFinal = price_found[0].precio_neto;
          newP.precio = price_found[0].precio_neto;
          newP.precio_a_vender = price_found[0].precio_neto;
          newP.precio_actual_producto = price_found[0].precio_neto;
          newP.precio_actual_object = price_found[0];
          new_prices = await calculatePrices2({
            newP,
            cantidad: 0,
            granel: granel_base,
            origen: "Tabla",
            precio_boolean: true,
            precio: price_found[0],
          });
        }
      }

      new_prices.newP.precio_anterior = productoPrecioFinal;

      new_prices.newP.iva_total_producto = parseFloat(new_prices.ivaCalculo);
      new_prices.newP.ieps_total_producto = parseFloat(new_prices.iepsCalculo);
      new_prices.newP.impuestos_total_producto = parseFloat(
        new_prices.impuestoCalculo
      );
      new_prices.newP.subtotal_total_producto = parseFloat(
        new_prices.subtotalCalculo
      );
      new_prices.newP.total_total_producto = parseFloat(
        new_prices.totalCalculo
      );

      //agregar producto al inicio del arreglo
      productosVentasTemp.splice(0, 0, new_prices.newP);

      CalculosData = {
        subTotal:
          parseFloat(venta_existente.subTotal) +
          parseFloat(new_prices.subtotalCalculo),
        total:
          parseFloat(venta_existente.total) +
          parseFloat(new_prices.totalCalculo),
        impuestos:
          parseFloat(venta_existente.impuestos) +
          parseFloat(new_prices.impuestoCalculo),
        iva:
          parseFloat(venta_existente.iva) + parseFloat(new_prices.ivaCalculo),
        ieps:
          parseFloat(venta_existente.ieps) + parseFloat(new_prices.iepsCalculo),
        descuento:
          parseFloat(venta_existente.descuento) +
          parseFloat(new_prices.descuentoCalculo),
        monedero:
          parseFloat(venta_existente.monedero) +
          parseFloat(new_prices.monederoCalculo),
      };
    } else if (producto_encontrado.found && producto._id) {
      const {
        cantidad_venta,
        ...newP
      } = producto_encontrado.producto_found.producto;

      let new_cant = cantidad_venta + value;

      newP.cantidad_venta = parseFloat(new_cant);
      const verify_prising = await verifiPrising(newP);
      const newPrising = verify_prising.found
        ? verify_prising.object_prising
        : newP.precio_actual_object;

      const new_resta = await calculatePrices2({
        newP,
        cantidad: newP.granel_producto.granel ? value : cantidad_venta,
        precio_boolean: true,
        precio: newP.precio_actual_object,
        granel: newP.granel_producto,
        origen: "Tabla",
      });

      if (newP.granel_producto.granel) {
        newP.granel_producto = {
          granel: true,
          valor: parseFloat(new_cant),
        };
      }

      const new_suma = await calculatePrices2({
        newP,
        cantidad: newP.granel_producto.granel ? value : parseFloat(new_cant),
        precio_boolean: true,
        precio: newPrising,
        granel: newP.granel_producto,
        origen: "Tabla",
      });

      newP.iva_total_producto = parseFloat(new_suma.ivaCalculo);
      newP.ieps_total_producto = parseFloat(new_suma.iepsCalculo);
      newP.impuestos_total_producto = parseFloat(new_suma.impuestoCalculo);
      newP.subtotal_total_producto = parseFloat(new_suma.subtotalCalculo);
      newP.total_total_producto = parseFloat(new_suma.totalCalculo);

      if (verify_prising.found) {
        newP.precio_a_vender = new_suma.totalCalculo;
        newP.precio_anterior = newP.precio_actual_porducto;
        newP.precio_actual_producto = verify_prising.pricing;
        newP.precio_actual_object = verify_prising.object_prising;

        newP.precio_actual_object = {
          cantidad_unidad: verify_prising.object_prising.cantidad_unidad
            ? verify_prising.object_prising.cantidad_unidad
            : null,
          numero_precio: verify_prising.object_prising.numero_precio
            ? verify_prising.object_prising.numero_precio
            : null,
          unidad_maxima: verify_prising.object_prising.unidad_maxima
            ? verify_prising.object_prising.unidad_maxima
            : null,
          precio_general: verify_prising.object_prising.precio_general
            ? verify_prising.object_prising.precio_general
            : null,
          precio_neto: verify_prising.object_prising.precio_neto
            ? verify_prising.object_prising.precio_neto
            : null,
          precio_venta: verify_prising.object_prising.precio_venta
            ? verify_prising.object_prising.precio_venta
            : null,
          iva_precio: verify_prising.object_prising.iva_precio
            ? verify_prising.object_prising.iva_precio
            : null,
          ieps_precio: verify_prising.object_prising.ieps_precio
            ? verify_prising.object_prising.ieps_precio
            : null,
          utilidad: verify_prising.object_prising.utilidad
            ? verify_prising.object_prising.utilidad
            : null,
          porciento: verify_prising.object_prising.porciento
            ? verify_prising.object_prising.porciento
            : null,
          dinero_descontado: verify_prising.object_prising.dinero_descontado
            ? verify_prising.object_prising.dinero_descontado
            : null,
        };
      } else {
        newP.cantidad_venta = parseFloat(new_cant);
        newP.precio_anterior = newP.precio_actual_producto;
        newP.precio_a_vender = new_suma.totalCalculo;
      }

      productosVentasTemp.splice(
        producto_encontrado.producto_found.index,
        1,
        newP
      );

      if (newP.cantidad_venta === 0) return;

      CalculosData = {
        subTotal:
          parseFloat(venta_existente.subTotal) -
          parseFloat(new_resta.subtotalCalculo) +
          new_suma.subtotalCalculo,
        total:
          parseFloat(venta_existente.total) -
          parseFloat(new_resta.totalCalculo) +
          new_suma.totalCalculo,
        impuestos:
          parseFloat(venta_existente.impuestos) -
          parseFloat(new_resta.impuestoCalculo) +
          new_suma.impuestoCalculo,
        iva:
          parseFloat(venta_existente.iva) -
          parseFloat(new_resta.ivaCalculo) +
          new_suma.ivaCalculo,
        ieps:
          parseFloat(venta_existente.ieps) -
          parseFloat(new_resta.iepsCalculo) +
          new_suma.iepsCalculo,
        descuento:
          parseFloat(venta_existente.descuento) -
          parseFloat(new_resta.descuentoCalculo) +
          new_suma.descuentoCalculo,
        monedero:
          parseFloat(venta_existente.monedero) -
          parseFloat(new_resta.monederoCalculo) +
          new_suma.monederoCalculo,
      };
    }

    CalculosData.subtotal_base = CalculosData.subTotal;
    CalculosData.total_base = CalculosData.total;

    localStorage.setItem(
      "DatosVentas",
      JSON.stringify({
        ...CalculosData,
        cliente:
          venta_actual.venta_cliente === true ? venta_actual.cliente : {},
        venta_cliente:
          venta_actual.venta_cliente === true
            ? venta_actual.venta_cliente
            : false,
        productos: productosVentasTemp,
        tipo_emision: venta_actual.tipo_emision
          ? venta_actual.tipo_emision
          : "TICKET",
      })
    );
    setDatosVentasActual({
      ...CalculosData,
    });
    setUpdateTablaVentas(!updateTablaVentas);
    /* handleClickOpen(); */
    inputProductos?.current?.focus();
  };

  if (error) {
    return (
      <Button
        className="button-panel-venta b-primary"
        fullWidth
        variant="outlined"
        disabled
      >
        <Box>
          <HiSearchCircle className="icon-btn-primary" />
          <Typography className="text-btn-panel">Productos</Typography>
          <Typography className="text-btn-panel-sec">Alt + P</Typography>
        </Box>
      </Button>
    );
  }

  return (
    <>
      <Button
        className="button-panel-venta b-primary"
        fullWidth
        variant="outlined"
        onClick={handleClickOpen}
        disabled={!turnoEnCurso || (datosVentas && datosVentas.nota_credito)}
      >
        <Box>
          <HiSearchCircle className="icon-btn-primary" />
          <Typography className="text-btn-panel">Productos</Typography>
          <Typography className="text-btn-panel-sec">Alt + P</Typography>
        </Box>
      </Button>
      <Drawer
        anchor="right"
        open={openBuscarProducto}
        classes={{ paper: classes.drawerPaper }}
        BackdropProps={{
          style: { backgroundColor: "rgba(0,0,0,0)" },
        }}
        onClose={handleClickOpen}
      >
        <DialogTitle component="div">
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Productos</Typography>
            <Button
              size="small"
              color="inherit"
              variant="contained"
              disableElevation
              style={{ textTransform: "none" }}
              onClick={handleClickOpen}
              startIcon={<CloseIcon />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <Box px={2} pb={0.5}>
          <form onSubmit={handleSubmit}>
            <CustomSearchVentaRapidaTF
              variant="outlined"
              placeholder="Buscar producto"
              size="small"
              fullWidth
              value={searchProducto.producto}
              onChange={(e) => obtenerDatos("producto", e.target.value)}
              autoFocus
              inputRef={inputProductos}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" />
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      startIcon={<Search color="action" />}
                      size="small"
                      type="submit"
                      style={{textTransform: "none"}}
                    >
                      Buscar
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </Box>
        <ListaProductos
          productosBusqueda={productosBusqueda}
          loading={loading}
          agregarProductos={agregarProductos}
          page={page}
          setPage={setPage}
          limit={limit}
        />
      </Drawer>
    </>
  );
}
