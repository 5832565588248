import * as React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTheme } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";

export default function ProductosTraspaso({ productos }) {
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Artículo</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Unidad</TableCell>
            <TableCell align="right">Medidas</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {productos.map((res) => (
            <TableRow key={res._id}>
              <TableCell component="th" scope="row">
                {res.producto?.datos_generales?.nombre_comercial}
              </TableCell>
              <TableCell align="right">{res.cantidad}</TableCell>
              <TableCell align="right">{res.unidad}</TableCell>
              <TableCell align="right">
                <RenderMedidas data={res} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const RenderMedidas = ({ data }) => {
  const theme = useTheme();
  const { medida, color, cantidad } = data;

  if (!medida || !color) return null;

  return (
    <Badge badgeContent={cantidad} color="secondary">
      <Chip
        style={{
          backgroundColor: color.hex,
          color: theme.palette.getContrastText(color.hex),
          height: 24
        }}
        label={medida.medida}
      />
    </Badge>
  );
};
