import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import RenderTableRows from "./RenderRowsIndex";
import TableHeadArticulos from "./TableHeadArticulos";
import ListItemsVentaRapida from "../../venta_rapida/TablaComponent/ListItemsVentaRapida";

export default function TablaArticulosIndex() {
  let datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
  const { updateTablaVentas, ventaRapida } = React.useContext(VentasContext);
  const [datosTabla, setDatosTabla] = React.useState([]);

  React.useEffect(() => {
    datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
    setDatosTabla(datosVentas === null ? [] : datosVentas.productos);
  }, [updateTablaVentas]);

  const height = ventaRapida ? `calc(100vh - 210px)` : `calc(100vh - 198px)`;

  return (
    <div>
      {ventaRapida ? (
        <ListItemsVentaRapida height={height} data={datosTabla} />
      ) : (
        <TableContainer style={{ height }}>
          <Table
            stickyHeader
            size="small"
            aria-labelledby="tableTitle"
            aria-label="a dense table"
          >
            <TableHeadArticulos datosVentas={datosVentas} />
            <TableBody>
              {datosTabla.map((producto, index) => {
                return (
                  <RenderTableRows
                    key={index}
                    index={index}
                    producto={producto}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
