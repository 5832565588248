import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import TabsSucursals from "./TabsSucursals";
import { useDebounce } from "use-debounce";
import { FaClipboardCheck } from "react-icons/fa";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { CustomButton } from "../../../components/CustomMuiComponents";
import { MainContext } from "../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ExistenciasSucursals() {
  const { turnoEnCurso } = React.useContext(MainContext);
  const { ventaRapida } = React.useContext(VentasContext);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const [VALUE] = useDebounce(search, 500);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      {ventaRapida ? (
        <CustomButton
          className="button-panel-venta b-secondary"
          fullWidth
          size="large"
          variant="outlined"
          onClick={handleClickOpen}
          disabled={!turnoEnCurso}
          startIcon={<FaClipboardCheck className="icon-btn-secondary" />}
        >
          Existencias
        </CustomButton>
      ) : (
        <Button
          className="button-panel-venta b-secondary"
          fullWidth
          variant="outlined"
          onClick={handleClickOpen}
          disabled={!turnoEnCurso}
        >
          <Box>
            <FaClipboardCheck className="icon-btn-secondary" />
            <Typography className="text-btn-panel">Existencias</Typography>
            <Typography className="text-btn-panel-sec">&nbsp;</Typography>
          </Box>
        </Button>
      )}

      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
      >
        <DialogTitle component="div" style={{ padding: "16px 18px 0px 18px" }}>
          <Box display="flex" justifyContent="space-between">
            <TextField
              fullWidth
              placeholder="Buscar producto por cod. barras, Nombre o clave..."
              size="small"
              variant="outlined"
              style={{ width: 500 }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button color="primary" onClick={handleClickOpen}>
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: "0px 10px 10px 10px" }}>
          <Box mt={1} height="70vh">
            <TabsSucursals producto={VALUE} />
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
