import { Impresora } from "../../../../components/Perifericos/TicketPrinter/Impresora";
import { formatoMexico } from "../../../../config/reuserFunctions";
import table from "text-table";
import moment from "moment-timezone";
const RUTA_API = "http://localhost:8000";

export const imprimirTicketAbonos = async (
  sesion,
  data,
  tipo_cliente,
  liquidacion = false,
  reimpresion = false
) => {
  //console.log({ sesion, data, liquidacion, tipo_cliente, reimpresion });

  let tableArray = [
    ["SALDO INICIAL ", `$${formatoMexico(data.saldo_inicial)}`],
    ["TOTAL ANTERIOR", `$${formatoMexico(data.total_anterior)}`],
    ["SU PAGO", `$${formatoMexico(data.su_pago)}`],
    ["EFECTIVO RECIBIDO", `$${formatoMexico(data.efectivo_recibido)}`],
    ["METODO DE PAGO", `${data.metodo_pago}`],
    ["SU CAMBIO", `$${formatoMexico(data.su_cambio)}`],
    ["TOTAL RESTANTE", `$${formatoMexico(data.total_restante)}`],
  ];

  if (liquidacion || data.descuento) {
    tableArray.splice(2, 0, ["DESCUENTO", `$${formatoMexico(data.descuento)}`]);
  }

  let tableSaldos = table(tableArray);

  try {
    const { direccion } = sesion.sucursal;
    const address = `${direccion.calle} #${direccion.no_ext}, Col. ${direccion.colonia}`;
    const location = `${direccion.municipio}, ${direccion.estado}, ${direccion.pais}`;

    let impresora = new Impresora(RUTA_API);
    impresora.cut();
    impresora.setFontSize(1, 1);
    impresora.setEmphasize(0);
    impresora.setAlign("center");
    impresora.write("Punto de venta CAFI\n");
    impresora.feed(1);
    impresora.write(`${sesion.empresa.nombre_empresa}\n`);
    impresora.write(`Sucursal: ${sesion.sucursal.nombre_sucursal}\n`);
    impresora.write(`RFC: ${sesion.empresa.rfc}\n`);
    impresora.write(`${address}\n`);
    impresora.write(`${location}\n`);
    impresora.feed(1);
    impresora.write(
      `Fecha: ${moment()
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY")} Hora: ${moment()
        .tz("America/Mexico_City")
        .format("LTS")}\n`
    );
    impresora.write(`Usuario: ${data.turno.usuario}\n`);
    impresora.write(
      `No. usuario: ${data.turno.numero_usuario} Caja: ${data.turno.caja}\n`
    );
    impresora.write(`Turno: ${data.turno.turno}\n`);
    impresora.feed(1);
    impresora.setFontSize(2, 1);
    impresora.write(`ABONO ${tipo_cliente}\n`);
    impresora.feed(1);
    impresora.setFontSize(1, 1);
    impresora.write(`Numero de cliente: ${data.numero_cliente}\n`);
    impresora.write(`Clave cliente: ${data.clave_cliente}\n`);
    impresora.write(`Nombre de cliente: ${data.nombre_cliente}\n`);
    impresora.feed(1);
    impresora.write(`Folio: ${data.folio}\n`);
    if (reimpresion)
      impresora.write(
        `Fecha de movimiento: ${moment(data.fecha_movimiento)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY")}\n`
      );
    impresora.setAlign("left");
    impresora.write(`${tableSaldos}\n`);
    impresora.feed(3);
    impresora.cut();
    impresora.cutPartial(); // Pongo este y también cut porque en ocasiones no funciona con cut, solo con cutPartial
    await impresora.end();
    return { succes: true, error: false, message: "OK" };
  } catch (error) {
    console.log(error);
    return { succes: false, error: true, message: error };
  }
};
