import React, { createContext } from "react";
import { MainContext } from "../../../../../context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetTraspasos } from "../graphql/queries";
import moment from "moment-timezone";

export const TraspasosContext = createContext();

const initialItem = {
  cantidad_total: 0,
  new_medidas: [],
  product_selected: null,
  unidad_maxima: false,
};


const TraspasosCtxProvider = ({ children }) => {
  const { tokenDecoded } = React.useContext(MainContext);

  const initialState = {
    concepto_traspaso: null,
    sucursalOrigen: tokenDecoded.sucursal._id,
    sucursalDestino: tokenDecoded.sucursal._id,
    almacen_origen: null,
    almacen_destino: null,
    productos: [],
    fecha_registro: moment().tz("America/Mexico_City").format(),
  };

  /* obtener traspasos */
  const initialFilter = {
    empresa: tokenDecoded?.empresa._id,
    sucursal: tokenDecoded?.sucursal._id,
    sucursalDestino: "",
    filter: "",
  };
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const { page, limit } = pageConfig;

  /* crear traspaso */
  const [traspaso, setTraspaso] = React.useState(initialState);
  const [item, setItem] = React.useState(initialItem);

  const traspasosQuery = useQuery(GetTraspasos, {
    variables: {
      input: FILTER,
      page,
      limit,
    },
    fetchPolicy: "network-only",
  });

  return (
    <TraspasosContext.Provider
      value={{
        filter,
        setFilter,
        initialFilter,
        traspasosQuery,
        pageConfig,
        setPageConfig,
        traspaso,
        setTraspaso,
        initialState,
        item,
        setItem,
        initialItem,
      }}
    >
      {children}
    </TraspasosContext.Provider>
  );
};

export default TraspasosCtxProvider;
