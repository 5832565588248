import React from "react";
import { Box, TextField, FormControlLabel, Checkbox } from "@material-ui/core";

function MultiplesEtiquetas({ datosEtiqueta, setDatosEtiqueta }) {
  const getDataTextFields = (e) => {
    const { name, value } = e.target;
    setDatosEtiqueta({
      ...datosEtiqueta,
      [name]: parseFloat(value),
    });
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            disabled={datosEtiqueta.size !== "27mmx13mm"}
            checked={datosEtiqueta.multiple}
            onChange={(e) =>
              setDatosEtiqueta({
                ...datosEtiqueta,
                multiple: e.target.checked,
                cantidad_etiquetas: 1,
                margin: 1.5
              })
            }
            name="multiple"
          />
        }
        label="Multiples etiquetas"
      />
      {datosEtiqueta.multiple ? (
        <Box display="flex">
          <Box m={1}>
            <TextField
              variant="outlined"
              value={datosEtiqueta.cantidad_etiquetas}
              size="small"
              type="number"
              label="Cantidad"
              InputProps={{ inputProps: { min: 1, max: 3 } }}
              onChange={getDataTextFields}
              name="cantidad_etiquetas"
              style={{ width: 110 }}
            />
          </Box>
          <Box m={1}>
            <TextField
              variant="outlined"
              value={datosEtiqueta.margen}
              size="small"
              type="number"
              label="Margen en mm"
              InputProps={{ inputProps: { min: 1.5, max: 10 } }}
              onChange={getDataTextFields}
              name="margen"
              style={{ width: 120 }}
            />
          </Box>
        </Box>
      ) : null}
    </div>
  );
}

export default MultiplesEtiquetas;
