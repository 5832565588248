import React, { forwardRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  Slide,
  Typography,
} from "@material-ui/core";
import RegistroSeries from "./RegistroSeries";
import ListaSeriesCDFI from "./ListaSeries";
import { useQuery } from "@apollo/client";
import { OBTENER_SERIES } from "../../../../gql/Facturacion/Facturacion";
import ErrorPage from "../../../../components/ErrorPage";
import { ArrowBack } from "@material-ui/icons";
import { FcNumericalSorting12 } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: "0px 20px",
  },
  title: {
    flex: 1,
  },
  icon: {
    width: 100,
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SeriesCDFI() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  return (
    <div>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <FcNumericalSorting12 style={{ fontSize: 100 }} />
          </Box>
          Series CFDI
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
      >
        <DialogTitle className={classes.appBar}>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" className={classes.title}>
              Series para CFDI
            </Typography>
            <IconButton color="primary" onClick={handleClickOpen} size="large">
              <ArrowBack />
            </IconButton>
          </Box>
          <Divider />
        </DialogTitle>
        <ContentSeriesCFDI />
      </Dialog>
    </div>
  );
}

const ContentSeriesCFDI = () => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const { loading, data, error, refetch } = useQuery(OBTENER_SERIES, {
    variables: {
      sucursal: "", //sesion.sucursal._id,
      empresa: sesion.empresa._id,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="30vh"
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <ErrorPage error={error} />;
  }

  const { obtenerSeriesCdfi } = data;

  return (
    <>
      <Box position="absolute" right={90} top={3}>
        {sesion.accesos.facturacion.registro_series_cdfi.agregar ===
        false ? null : (
          <RegistroSeries refetch={refetch} />
        )}
      </Box>
      <Container maxWidth="md">
        <Box my={2}>
          <ListaSeriesCDFI
            obtenerSeriesCdfi={obtenerSeriesCdfi}
            refetch={refetch}
          />
        </Box>
      </Container>
    </>
  );
};
