import React, { useContext, useState, Fragment, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import Slide from "@material-ui/core/Slide";
import CrearCliente from "./CrearCliente";
import ErrorPage from "../../../../components/ErrorPage";
import { Delete } from "@material-ui/icons";
import { ClienteCtx } from "../../../../context/Catalogos/crearClienteCtx";
import { useDebounce } from "use-debounce";
import { useQuery, useMutation } from "@apollo/client";
import {
  OBTENER_CLIENTES,
  ACTUALIZAR_CLIENTE,
  ELIMINAR_CLIENTE,
} from "../../../../gql/Catalogos/clientes";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import { TablePagination } from "@material-ui/core";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import moment from "moment-timezone";
import { formatoMexico } from "../../../../config/reuserFunctions";
// import { VentasContext } from "../../../../context/Ventas/ventasContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "70vh",
  },
  tableCellLong: {
    minWidth: "300px",
  },
  tableCellNormal: {
    minWidth: "200px",
  },
  tableCellShort: {
    minWidth: "100px",
  },
  colFixedSt: {
    posision: "sticky",
    right: 161,
  },
  colFixedEdit: {
    posision: "sticky",
    right: 84,
  },
  colFixedDel: {
    posision: "sticky",
    right: 0,
  },
});

export default function ListaClientes({
  user,
  tipo,
  filtro,
  ventas,
  handleClickOpen,
  page,
  setPage,
}) {
  const classes = useStyles();
  const { setUpdateClientVenta, updateClientVenta, update } = useContext(
    ClienteCtx
  );
  const [shadow, setShadow] = useState("leftShadowTable");
  const [value] = useDebounce(filtro, 1000);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const tableEl = React.useRef();
  const limit = 20;
  /* Queries */
  const { loading, data, error, refetch } = useQuery(OBTENER_CLIENTES, {
    variables: {
      tipo,
      filtros: value,
      empresa: sesion.empresa._id,
      eliminado: false,
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
  }, [update, refetch]);

  //show modal accept client
  const [showModal, setShowModal] = useState(false);
  //const [dataSelectRowClient, setDataSelectRowClient] = useState({});
  const [alert, setAlert] = useState({ message: "", status: "", open: false });

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === realWidth) {
      setShadow("removeShadowTable");
      return;
    }
    setShadow("leftShadowTable");
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };

  /* const handleClickOpenModal = (data, e) => {
    if (ventas) {
      setShowModal(true);
      setDataSelectRowClient(data);
    }
  }; */

  const handleClickSelectClient = (data) => {
    //clear object
    const {
      representante,
      estado_cliente,
      tipo_cliente,
      empresa,
      sucursal,
      fecha_nacimiento,
      fecha_registro,
      eliminado,
      ...clean_cliente
    } = data;
    try {
      const venta = JSON.parse(localStorage.getItem("DatosVentas"));
      let venta_actual = venta === null ? {} : venta;
      localStorage.setItem(
        "DatosVentas",
        JSON.stringify({
          ...venta_actual,
          subtotal_base:
            venta_actual.subTotal === undefined ? 0 : venta_actual.subTotal,
          total_base: venta_actual.total === undefined ? 0 : venta_actual.total,
          subTotal:
            venta_actual.subTotal === undefined ? 0 : venta_actual.subTotal,
          total: venta_actual.total === undefined ? 0 : venta_actual.total,
          impuestos:
            venta_actual.impuestos === undefined ? 0 : venta_actual.impuestos,
          iva: venta_actual.iva === undefined ? 0 : venta_actual.iva,
          ieps: venta_actual.ieps === undefined ? 0 : venta_actual.ieps,
          descuento:
            venta_actual.descuento === undefined ? 0 : venta_actual.descuento,
          monedero:
            venta_actual.monedero === undefined ? 0 : venta_actual.monedero,
          // tipo_cambio: venta_actual.tipo_cambio ? venta_actual.tipo_cambio : {},
          cliente: clean_cliente ? clean_cliente : {},
          venta_cliente: true,
          productos:
            venta_actual.productos?.length > 0 ? venta_actual.productos : [],
          tipo_emision: venta_actual.tipo_emision
            ? venta_actual.tipo_emision
            : "TICKET",
        })
      );
      setUpdateClientVenta(!updateClientVenta);
      setShowModal(!showModal);
      handleClickOpen();
    } catch (error) {
      console.log(error);
    }
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50vh"
        width="120vh"
      >
        <CircularProgress />
      </Box>
    );
  if (error) {
    return <ErrorPage error={error} />;
  }

  const { obtenerClientes } = data;
  // console.log(data);

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <Fragment>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <Paper className={classes.root} variant="outlined">
        <TableContainer
          className={classes.container}
          ref={tableEl}
          onScroll={handleOnScroll}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellShort}>Clave</TableCell>
                <TableCell padding="checkbox">Número</TableCell>
                <TableCell className={classes.tableCellLong}>Nombre</TableCell>
                <TableCell className={classes.tableCellNormal}>
                  Fecha de Alta
                </TableCell>
                <TableCell className={classes.tableCellNormal}>CURP</TableCell>
                <TableCell className={classes.tableCellShort}>
                  Teléfono
                </TableCell>
                <TableCell className={classes.tableCellLong}>Correo</TableCell>
                <TableCell className={classes.tableCellLong}>
                  Dirección
                </TableCell>
                <TableCell className={classes.tableCellShort}>Tipo</TableCell>
                <TableCell className={classes.tableCellNormal}>
                  Crédito disponible
                </TableCell>
                <TableCell className={classes.tableCellNormal}>
                  Límite de crédito
                </TableCell>
                <TableCell align="center" className={classes.tableCellNormal}>
                  Monedero electrónico
                </TableCell>
                {user === "EMPLEADO" || ventas ? null : (
                  <TableCell
                    align="center"
                    paddding="checkbox"
                    className={`${classes.colFixedSt} ${shadow}`}
                  >
                    Estado
                  </TableCell>
                )}
                <TableCell
                  align="center"
                  paddding="checkbox"
                  className={classes.colFixedEdit}
                  style={{ right: ventas ? 0 : 84 }}
                />
                {user === "EMPLEADO" || ventas ? null : (
                  <TableCell
                    align="center"
                    paddding="checkbox"
                    className={classes.colFixedDel}
                  >
                    Eliminar
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {obtenerClientes.docs.map((row, index) => {
                return (
                  <RowsRender
                    user={user}
                    key={index}
                    datos={row}
                    //handleClickOpenModal={handleClickOpenModal}
                    handleClickSelectClient={handleClickSelectClient}
                    setAlert={setAlert}
                    refetch={refetch}
                    ventas={ventas}
                    //dataSelectRowClient={dataSelectRowClient}
                    shadow={shadow}
                    //setShadow={setShadow}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={obtenerClientes.totalDocs}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
        />
        {/* <Dialog
          open={showModal}
          onClose={() => setShowModal(false)}
          aria-labelledby="select-dialog-cliente-venta"
          aria-describedby="select-cliente-venta-description"
        >
          <DialogTitle id="select-dialog-cliente-venta">
            {"¿Estas seguro que desea seleccionar este cliente?"}
          </DialogTitle>
          <DialogContent>
            <Typography>
              {`Cliente: ${dataSelectRowClient.nombre_cliente}`}
            </Typography>
            <Typography>
              {`Número de clave: ${dataSelectRowClient.clave_cliente}`}
            </Typography>
            <DialogContentText id="select-cliente-venta-description">
              Al seleccionar el cliente se colocara en la venta.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowModal(false)}
              color="primary"
              autoFocus
            >
              cancelar
            </Button>
            <Button onClick={() => handleClickSelectClient()} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Dialog> */}
      </Paper>
    </Fragment>
  );
}

const RowsRender = ({
  datos,
  user,
  //handleClickOpenModal,
  handleClickSelectClient,
  setAlert,
  refetch,
  ventas,
  //dataSelectRowClient,
  shadow,
  //setShadow,
}) => {
  const permisosUsuario = JSON.parse(localStorage.getItem("sesionCafi"));
  const { isOnline } = useContext(AccesosContext);
  const { update, setUpdate } = useContext(ClienteCtx);
  const [loading, setLoading] = useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  /* console.log(datos); */

  const [actualizarCliente] = useMutation(ACTUALIZAR_CLIENTE);

  const cambiarEstado = async (e) => {
    setLoading(true);

    try {
      await actualizarCliente({
        variables: {
          input: {
            estado_cliente: e.target.checked,
          },
          id: datos._id,
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
        },
      });
      setUpdate(!update);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <TableRow
        onClick={(e) => handleClickSelectClient(datos)}
        hover={ventas}
        style={ventas ? { cursor: "pointer" } : null}
      >
        <TableCell>
          <Typography>{datos.clave_cliente}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{datos.numero_cliente}</Typography>
        </TableCell>
        <TableCell align="justify">
          <Typography>{datos.nombre_cliente}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {moment(datos.fecha_registro)
              .tz("America/Mexico_City")
              .format("DD/MM/YYYY")}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{datos.curp}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{datos.telefono}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{datos.email}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {datos.direccion.calle + " #" + datos.direccion.no_ext}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>{datos.tipo_cliente}</Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {datos.credito_disponible === ""
              ? ""
              : "$" + formatoMexico(datos.credito_disponible)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {datos.limite_credito === ""
              ? ""
              : "$" + formatoMexico(datos.limite_credito)}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography>
            {datos.monedero_electronico === ""
              ? ""
              : "$" + formatoMexico(datos.monedero_electronico)}
          </Typography>
        </TableCell>
        {user === "EMPLEADO" || ventas ? null : (
          <TableCell
            onClick={(e) => e.stopPropagation()}
            align="center"
            paddding="checkbox"
            style={{ position: "sticky", right: 161, backgroundColor: "white" }}
            className={shadow}
          >
            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <Switch
                checked={datos.estado_cliente}
                disabled={!isOnline}
                onChange={cambiarEstado}
                color="primary"
                size="small"
              />
            )}
          </TableCell>
        )}
        <TableCell
          onClick={(e) => e.stopPropagation()}
          align="center"
          paddding="checkbox"
          style={{
            position: "sticky",
            right: ventas ? 0 : 84,
            backgroundColor: "white",
          }}
        >
          {permisosUsuario.accesos.catalogos.clientes.editar ===
          false ? null : (
            <CrearCliente
              tipo="CLIENTE"
              accion="actualizar"
              datos={datos}
              refetch={refetch}
            />
          )}
        </TableCell>
        {ventas ? null : (
          <TableCell
            onClick={(e) => e.stopPropagation()}
            align="center"
            paddding="checkbox"
            style={{ position: "sticky", right: 0, backgroundColor: "white" }}
          >
            {permisosUsuario.accesos.catalogos.clientes.eliminar ===
            false ? null : (
              <EliminarCliente
                datos={datos}
                setAlert={setAlert}
                refetch={refetch}
              />
            )}
          </TableCell>
        )}
      </TableRow>
    </Fragment>
  );
};

const EliminarCliente = ({ datos, setAlert, refetch }) => {
  const [open, setOpen] = useState(false);
  const [eliminarCliente] = useMutation(ELIMINAR_CLIENTE);
  const [loading, setLoading] = useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { update, setUpdate } = useContext(ClienteCtx);
  const { isOnline } = useContext(AccesosContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const eliminarClienteBD = async () => {
    setLoading(true);
    try {
      const result = await eliminarCliente({
        variables: {
          id: datos._id,
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
        },
      });
      if (result) {
        const { message } = result.data.eliminarCliente;
        setAlert({ message, status: "success", open: true });
        refetch();
      }
      setLoading(false);
      setUpdate(!update);
      handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      handleClose();
      if (error.message) {
        setAlert({ message: error.message, status: "error", open: true });
        return;
      }
      setAlert({ message: "Hubo un error", status: "error", open: true });
    }
  };

  return (
    <div>
      <IconButton
        disabled={!isOnline}
        size="small"
        color="secondary"
        onClick={() => handleClickOpen()}
      >
        <Delete />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => handleClose()}
        aria-labelledby="alert-eliminar-cliente"
      >
        <DialogTitle id="alert-eliminar-cliente">
          {"¿Está seguro de eliminar esto?"}
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            color="inherit"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => eliminarClienteBD()}
            variant="contained"
            disableElevation
            color="secondary"
            disabled={loading}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
