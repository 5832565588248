import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import ContentDoc from "./ContentDocument";
import { Box, DialogTitle } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import { Close, DescriptionOutlined } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetalleCotizacion({ venta, open, handleClose }) {
  let ref = React.useRef();
  if (!venta) return null;

  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const empresa = sesion.empresa.nombre_empresa;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      hideBackdrop
    >
      <DialogTitle component="div">
        <Box display="flex" justifyContent="space-between">
          <ReactToPrint
            trigger={() => (
              <Button
                startIcon={<DescriptionOutlined />}
                color="primary"
                size="small"
              >
                Descargar o imprimir PDF
              </Button>
            )}
            content={() => ref.current}
            documentTitle={`${empresa} Cotización - ${venta.folio}`}
          />
          <Button
            startIcon={<Close />}
            color="default"
            size="small"
            variant="contained"
            disableElevation
            onClick={handleClose}
          >
            Cerrar
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <ContentDoc cotizacion={venta} refcomp={ref} />
      </DialogContent>
    </Dialog>
  );
}
