import { createBrowserRouter, createHashRouter } from "react-router-dom";
//layouts
import LayoutLogin from "../components/Layouts/LayoutLogin";
import LayoutAdmin from "../components/Layouts/LayoutAdmin";
import LayoutVentas from "../components/Layouts/LayoutVenta";
import NoMatch from "../components/Layouts/NoMatch";

const isWeb = process.env.REACT_APP_IS_WEB === "true" ? true : false;

const ROUTES = [
  {
    path: "/",
    element: <LayoutLogin />,
    errorElement: <NoMatch />,
  },
  {
    path: "/ventas",
    element: <LayoutVentas />,
    errorElement: <NoMatch />,
  },
  {
    path: "/admin",
    element: <LayoutAdmin />,
    errorElement: <NoMatch />,
  },
];

const routes = isWeb ? createBrowserRouter(ROUTES) : createHashRouter(ROUTES);

export default routes;
