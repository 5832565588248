import React from "react";
import { Box } from "@material-ui/core";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
//import "swiper/swiper.css";
//import "swiper/swiper-element.css";
//import "swiper/swiper-bundle.css";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
//import "./styles.css";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { TiendaCtx } from "../../../../../../context/Empresa/tiendaCtx";

export default function Banner() {
  const { data } = React.useContext(TiendaCtx);
  return (
    <Swiper
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation
      loop={true}
      modules={[Autoplay, Pagination, Navigation]}
      style={{
        height:
          data && data.bannerSize
            ? data.bannerSize === "small"
              ? "25vh"
              : data.bannerSize === "medium"
              ? "40vh"
              : "60vh"
            : "40vh",
      }}
    >
      {data?.banners?.map((banner, index) => {
        const { preview, url_imagen } = banner;
        return (
          <SwiperSlide key={index}>
            <Box
              sx={{
                width: "100%",
                backgroundImage: `url(${preview ? preview : url_imagen})`,
                height: "100%",
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
