import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import NuevaCotizacion from "./NuevaCotizacion";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import "moment/locale/es";
import { AccesosContext } from "../../../context/Accesos/accesosCtx";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { MdOutlinePriceCheck } from "react-icons/md";
import { CustomButton } from "../../../components/CustomMuiComponents";
import { MainContext } from "../../../context/MainCtx";
import { DialogTitle } from "@material-ui/core";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Cotizacion({ type }) {
  moment.locale("es");

  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { turnoEnCurso } = React.useContext(MainContext);
  const datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));
  const [tipoVentana, setTipoVentana] = React.useState(type);

  const {
    reloadCrearCotizacion,
    setReloadCrearCotizacion,
    setAbrirPanelAcceso,
    abrirPanelAcceso,
    setDepartamentos,
  } = React.useContext(AccesosContext);

  const { setAlert, ventaRapida } = React.useContext(VentasContext);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (tipo) => {
    if (!datosVentas) {
      setAlert({
        message: `Lo sentimos no hay productos que cotizar`,
        status: "error",
        open: true,
      });
      return null;
    } else {
      if (sesion?.accesos.ventas.cotizaciones.ver === true) {
        setOpen(!open);
      } else {
        setAbrirPanelAcceso(!abrirPanelAcceso);
        setDepartamentos({
          departamento: "ventas",
          subDepartamento: "cotizaciones",
          tipo_acceso: "ver",
        });
      }
    }
  };

  React.useEffect(() => {
    if (reloadCrearCotizacion === true) {
      setReloadCrearCotizacion(false);
      setTipoVentana("GENERAR");
      setOpen(!open);
    }
  }, [reloadCrearCotizacion]);

  return (
    <>
      {ventaRapida ? (
        <CustomButton
          className="button-panel-venta b-secondary"
          fullWidth
          size="large"
          variant="outlined"
          onClick={() => handleClickOpen(tipoVentana)}
          startIcon={<MdOutlinePriceCheck className="icon-btn-secondary" />}
          disabled={
            !turnoEnCurso ||
            !datosVentas ||
            (datosVentas && datosVentas.nota_credito) ||
            datosVentas.cotizacion
          }
        >
          Cotización
        </CustomButton>
      ) : (
        <Button
          className="button-panel-venta b-secondary"
          fullWidth
          variant="outlined"
          onClick={() => handleClickOpen(tipoVentana)}
          disabled={
            !turnoEnCurso ||
            !datosVentas ||
            (datosVentas && datosVentas.nota_credito) ||
            datosVentas.cotizacion
          }
        >
          <Box>
            <MdOutlinePriceCheck className="icon-btn-secondary" />
            <Typography className="text-btn-panel">Cotización</Typography>
            <Typography className="text-btn-panel-sec">&nbsp;</Typography>
          </Box>
        </Button>
      )}

      <Dialog
        fullWidth
        open={open}
        maxWidth={tipoVentana === "GENERAR" ? "md" : "lg"}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle component="div">
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Nueva Cotización</Typography>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(!open)}
              size="large"
            >
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <NuevaCotizacion setOpen={setOpen} />
      </Dialog>
    </>
  );
}
