import { gql } from "@apollo/client";

export const GetTraspasos = gql`
  query GetTraspasos($input: getTraspasosInput, $limit: Int, $offset: Int) {
    getTraspasos(input: $input, limit: $limit, offset: $offset) {
      docs {
        _id
        almacen_destino {
          _id
          default_almacen
          nombre_almacen
        }
        almacen_origen {
          _id
          default_almacen
          nombre_almacen
        }
        concepto_traspaso {
          _id
          destino
          editable
          nombre_concepto
          origen
          sucursal {
            _id
            nombre_sucursal
          }
          usuario {
            _id
            nombre
            numero_usuario
          }
        }
        fecha_registro
        usuario {
          _id
          nombre
          numero_usuario
        }
        productos {
          _id
          cantidad
          color {
            color
            hex
            id_color
          }
          concepto
          id_producto
          id_traspaso
          medida {
            id_medida
            medida
            tipo
          }
          producto {
            datos_generales {
              categoria
              clave_alterna
              codigo_barras
              descripcion
              marca
              nombre_comercial
              subcategoria
              tipo_producto
            }
            precios {
              granel
              ieps
              ieps_activo
              inventario {
                codigo_unidad
                inventario_maximo
                inventario_minimo
                unidad_de_inventario
              }
              iva
              iva_activo
              monedero
              monedero_electronico
              precio_de_compra {
                ieps
                iva
                precio_con_impuesto
                precio_sin_impuesto
              }
              precios_producto {
                cantidad_unidad
                ieps_precio
                iva_precio
                numero_precio
                precio_general
                precio_neto
                precio_venta
                unidad_maxima
                unidad_mayoreo
                utilidad
              }
              unidad_de_compra {
                cantidad
                codigo_unidad
                precio_unitario_con_impuesto
                precio_unitario_sin_impuesto
                unidad
              }
            }
            unidades_de_venta {
              cantidad
              codigo_barras
              precio
              unidad
              codigo_unidad
              unidad_principal
            }
          }
          unidad
        }
        traspasoEntreSucursales
        aceptado
        sucursal
        sucursalOrigen {
          _id
          nombre_sucursal
        }
        sucursalDestino {
          _id
          nombre_sucursal
        }
      }
      totalDocs
    }
  }
`;
export const GetProducts = gql`
  query obtenerProductos(
    $empresa: ID!
    $sucursal: ID!
    $filtros: filtrosObtenerProductos
    $almacen: ID
    $existencias: Boolean
    $limit: Int
    $offset: Int
  ) {
    obtenerProductos(
      empresa: $empresa
      sucursal: $sucursal
      filtros: $filtros
      almacen: $almacen
      existencias: $existencias
      limit: $limit
      offset: $offset
    ) {
      docs {
        _id
        unidades_de_venta {
          _id
          precio
          cantidad
          unidad
          codigo_unidad
          unidad_principal
          codigo_barras
          id_producto
          descuento_activo
          descuento {
            cantidad_unidad
            numero_precio
            unidad_maxima
            precio_general
            precio_neto
            precio_venta
            iva_precio
            ieps_precio
            utilidad
            porciento
            dinero_descontado
          }
          default
          precio_unidad {
            numero_precio
            precio_neto
            precio_venta
            iva_precio
            ieps_precio
            unidad_mayoreo
            utilidad
            precio_general
            cantidad_unidad
            unidad_maxima
          }
        }
        datos_generales {
          codigo_barras
          clave_alterna
          tipo_producto
          nombre_comercial
          nombre_generico
          descripcion
          id_categoria
          categoria
          subcategoria
          id_subcategoria
          id_departamento
          departamento
          id_marca
          marca

          clave_producto_sat {
            Name
            Value
          }
          receta_farmacia
        }
        empresa
        precios {
          ieps
          ieps_activo
          iva
          iva_activo
          monedero
          monedero_electronico
          precio_de_compra {
            ieps
            iva
            precio_con_impuesto
            precio_sin_impuesto
          }
          precios_producto {
            numero_precio
            precio_neto
            precio_venta
            iva_precio
            ieps_precio
            unidad_mayoreo
            utilidad
            precio_general
            cantidad_unidad
            unidad_maxima
          }
          unidad_de_compra {
            cantidad
            precio_unitario_con_impuesto
            precio_unitario_sin_impuesto
            unidad
            codigo_unidad
          }
          inventario {
            inventario_minimo
            inventario_maximo
            unidad_de_inventario
            codigo_unidad
          }
          granel
        }
        medidas_producto {
          _id
          cantidad
          codigo_unidad
          unidad
          almacen
          descuento_activo
          descuento {
            cantidad_unidad
            numero_precio
            unidad_maxima
            precio_general
            precio_neto
            precio_venta
            iva_precio
            ieps_precio
            utilidad
            porciento
            dinero_descontado
          }
          codigo_barras
          color {
            _id
            nombre
            hex
          }
          existencia
          medida {
            _id
            talla
            tipo
          }
          nombre_comercial
          precio
          precio_unidad {
            numero_precio
            precio_neto
            precio_venta
            iva_precio
            ieps_precio
            unidad_mayoreo
            utilidad
            precio_general
            cantidad_unidad
            unidad_maxima
          }
        }
        inventario_general {
          _id
          cantidad_existente
          unidad_inventario
          codigo_unidad
          cantidad_existente_maxima
          unidad_maxima
          id_almacen_general
          eliminado
        }
        sucursal
        usuario
      }
      totalDocs
    }
  }
`;
export const GetAlmacenes = gql`
  query obtenerAlmacenesCloud($id: ID!) {
    obtenerAlmacenesCloud(id: $id) {
      _id
      nombre_almacen
      default_almacen
    }
  }
`;
export const GetConceptos = gql`
  query ObtenerConceptosAlmacen($empresa: ID!, $sucursal: ID!) {
    obtenerConceptosAlmacen(empresa: $empresa, sucursal: $sucursal) {
      _id
      nombre_concepto
      destino
      origen
      editable
    }
  }
`;
export const GetQueryMedidas = gql`
  query obtenerConsultasProducto($empresa: ID!, $sucursal: ID!) {
    obtenerConsultasProducto(empresa: $empresa, sucursal: $sucursal) {
      colores {
        _id
        nombre
        hex
      }
      tallas {
        _id
        talla
        tipo
      }
      calzados {
        _id
        talla
        tipo
      }
    }
  }
`;
export const GetSucursales = gql`
  query ObtenerSucursalesEmpresa($id: ID!) {
    obtenerSucursalesEmpresa(id: $id) {
      _id
      nombre_sucursal
    }
  }
`;
export const GetProdMovTrasp = gql`
  query GetProductosTraspaso($id_traspaso: ID!) {
    getProductosTraspaso(id_traspaso: $id_traspaso) {
      _id
      id_traspaso
      id_producto
      id_almacen
      almacen {
        id_almacen
        nombre_almacen
        default_almacen
      }
      producto {
        almacen_inicial {
          almacen
          cantidad
          fecha_de_expiracion
          id_almacen
          default_almacen
        }
        datos_generales {
          codigo_barras
          clave_alterna
          tipo_producto
          nombre_comercial
          nombre_generico
          descripcion
          id_categoria
          categoria
          subcategoria
          id_subcategoria
          id_departamento
          departamento
          id_marca
          marca
          clave_producto_sat {
            Name
            Value
          }
          receta_farmacia
        }
        precios {
          ieps
          ieps_activo
          inventario {
            inventario_minimo
            inventario_maximo
            unidad_de_inventario
            codigo_unidad
          }
          iva
          iva_activo
          monedero
          monedero_electronico
          precio_de_compra {
            precio_con_impuesto
            precio_sin_impuesto
            iva
            ieps
          }
          precios_producto {
            numero_precio
            precio_neto
            precio_venta
            iva_precio
            ieps_precio
            unidad_mayoreo
            utilidad
            precio_general
            cantidad_unidad
            unidad_maxima
          }
          unidad_de_compra {
            cantidad
            precio_unitario_con_impuesto
            precio_unitario_sin_impuesto
            unidad
            codigo_unidad
          }
          granel
          litros
        }
        unidades_de_venta {
          cantidad
          codigo_barras
          id_producto
          precio
          unidad
          codigo_unidad
          unidad_principal
          _id
        }
      }
      concepto
      cantidad
      medida {
        id_medida
        medida
        tipo
      }
      color {
        id_color
        color
        hex
      }
      unidad
      codigo_unidad
      empresa
      sucursal
      usuario
    }
  }
`;
