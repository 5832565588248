import * as React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core";
import { CustomEditableTextField, CustomTableAutocomplete } from "./CustomMuiComponents";
import { RiFilterFill } from "react-icons/ri";

export default function FilterSelectTable({
  query = [],
  value = "",
  filterCats = "",
  setFilter = null,
  setFilterCats = null,
  filterName = "",
  filterBy = "",
  loading = false,
  error = null,
  label = "Buscar"
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [classN, setClassN] = React.useState("button-filter");
  const open = Boolean(anchorEl);
  const theme = useTheme()

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setClassN("");
  };
  const handleClose = () => {
    setAnchorEl(null);
    setClassN("button-filter");
  };

  const onchangeInput = (_, value) => {
    if (!setFilterCats) return;
    setFilterCats({ ...filterCats, [filterName]: value });
  };

  if (error) return null;

  return (
    <>
      <IconButton
        id="filter-date"
        aria-controls={open ? "filter-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        size="small"
        className={classN}
        style={{
          //display: open || value[filterName] ? "inline-flex!important" : "none",
          color: value[filterName]
            ? theme.palette.primary.main
            : theme.palette.action.disabled,
          padding: '0 2px',
        }}
      >
        <RiFilterFill style={{fontSize: 16}} />
      </IconButton>
      <Menu
        id="filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "filter-date",
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          elevation: 2
        }}
      >
        <Box px={1} width={250}>
          <CustomTableAutocomplete
            autoHighlight
            openOnFocus
            isOptionEqualToValue={(option, value) => option[filterBy] === value}
            getOptionLabel={(option) => option[filterBy] || option || ""}
            options={query}
            value={value[filterName]}
            inputValue={filterCats[filterName]}
            loading={loading}
            renderInput={(params) => (
              <CustomEditableTextField
                {...params}
                size="small"
                label={label}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            onChange={(_, option) => {
              if (!option) {
                setFilter((state) => ({
                  ...state,
                  [filterName]: "",
                }));
                return;
              }
              setFilter((state) => ({
                ...state,
                [filterName]: option[filterBy] || "",
              }));
            }}
            onInputChange={onchangeInput}
          />
        </Box>
      </Menu>
    </>
  );
}
