import React, { Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { Close, Done } from "@material-ui/icons";
import { BsFillCartCheckFill } from "react-icons/bs";
import {
  formatoMexico,
  numberToLetters,
  numerosRandom,
} from "../../../config/reuserFunctions";
import { formaPago } from "../../sucursales/Facturacion/catalogos";
import { calcularMonedero } from "../../ventas/CerrarVenta/calcularMonedero";
import { CREAR_VENTA } from "../../../gql/Ventas/ventas_generales";
import { useMutation } from "@apollo/client";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { ClienteCtx } from "../../../context/Catalogos/crearClienteCtx";
import { AccesosContext } from "../../../context/Accesos/accesosCtx";
import ObtenerMontos from "../../ventas/CerrarVenta/Montos";
import InfoClienteVenta from "./InfoClienteVenta";
import { Alert } from "@material-ui/lab";
import { imprimirTicketVenta } from "../../../components/Perifericos/TicketPrinter/PrintTicketVenta";
import moment from "moment-timezone";
import MomentUtils from "@date-io/moment";
import { abrirCajonQuery } from "../../../components/Perifericos/Cajon/AbrirCajon";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MontosRapidos from "../../venta_rapida/CerrarVenta/MontosRapidos";
import {
  blue,
  blueGrey,
  deepPurple,
  green,
  red,
} from "@material-ui/core/colors";
import CreditoVenta from "../../ventas/CerrarVenta/Credito";
import DescuentoVenta from "./Descuento";
import { DialogActions, makeStyles } from "@material-ui/core";
import NotaDeVenta from "./NotaDeVenta";
import { MainContext } from "../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "50vw",
    height: "calc(100vh - 72px)",
    top: 58,
    //bottom: "auto",
    right: 20,
    borderRadius: 16,
    [theme.breakpoints.down("md")]: {
      width: "70vw",
    },
  },
}));

const PagarButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.cafiPrimary),
    backgroundColor: theme.palette.cafiPrimary,
    //fontWeight: 900,
    textTransform: "none",
    height: 40,
    "&:hover": {
      backgroundColor: deepPurple[500],
    },
  },
}))(Button);
const CancelarButton = withStyles((theme) => ({
  root: {
    //color: theme.palette.getContrastText(red[700]),
    //backgroundColor: red[500],
    /* "&:hover": {
      backgroundColor: red[700],
    }, */
  },
}))(Button);

const initial_state_montos = {
  efectivo: 0,
  tarjeta: 0,
  puntos: 0,
  transferencia: 0,
  cheque: 0,
};

const initial_state_totales = {
  subtotal: 0,
  impuestos: 0,
  total: 0,
  descuento: 0,
  cambio: 0,
  monto_pagado: 0,
};

const CerrarVenta = (props) => {
  const classes = useStyles();
  const [cambio, setCambio] = React.useState(false);
  const sesionUsuario = JSON.parse(localStorage.getItem("sesionCafi"));
  const datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));
  const impresora = localStorage.getItem("cafiTicketPrint");
  const [open, setOpen] = React.useState(false);
  const [notaVenta, setNotaVenta] = React.useState(null);
  //Datos del context
  const {
    updateTablaVentas,
    setUpdateTablaVentas,
    setDatosVentasActual,
    setOpenBackDrop,
    setVentaRetomada,
    ventaRapida,
    reloadProductos,
    setReloadProductos,
    setPriceSelected,
  } = React.useContext(VentasContext);
  const { updateClientVenta, setUpdateClientVenta } = React.useContext(
    ClienteCtx
  );
  const { isOnline, saveVentasToCloud } = React.useContext(AccesosContext);
  const { snackMessage, turnoEnCurso } = React.useContext(MainContext);
  //States de los montos a pagar
  const [montos, setMontos] = React.useState(initial_state_montos);
  const [totales, setTotales] = React.useState(initial_state_totales);
  const [venta_credito, setVentaCredito] = React.useState(false);
  const [credito_disponible, setCreditoDisponible] = React.useState(false);
  const [fechaVencimientoDate, setfechaVencimientoDate] = React.useState("");
  const [monederoTotal, setMonederoTotal] = React.useState(0);
  const [montoEnCaja, setMontoEnCaja] = React.useState(0);
  const [venta_base, setVentaBase] = React.useState(0);
  const [editarCliente, setEditarCliente] = React.useState(false);
  const [datosCliente, setDatosCliente] = React.useState({});
  const [monedero, setMonedero] = React.useState(0);
  const [abono_minimo, setAbonoMinimo] = React.useState(0);
  const [fecha_venta, setFechaVenta] = React.useState(
    moment().tz("America/Mexico_City").format()
  );
  const [efectivoConstante, setEfectivoConstante] = React.useState(
    totales.total
  );

  const [createVenta] = useMutation(CREAR_VENTA);

  const handleClickOpen = () => {
    const venta = JSON.parse(localStorage.getItem("DatosVentas"));
    setVentaBase(venta);
    const { cliente, subTotal, impuestos, total, descuento } = venta;

    const datos_cliente = venta && cliente ? cliente : {};
    if (datos_cliente.credito_disponible) {
      setCreditoDisponible(true);
    } else {
      setCreditoDisponible(false);
    }
    setDatosCliente(datos_cliente);
    setMontoEnCaja(venta === null ? 0 : parseFloat(total.toFixed(2)));
    setTotales({
      subtotal: venta === null ? 0 : parseFloat(subTotal.toFixed(2)),
      impuestos: venta === null ? 0 : parseFloat(impuestos.toFixed(2)),
      total: venta === null ? 0 : parseFloat(total.toFixed(2)),
      descuento: venta.cotizacion ? parseFloat(descuento.toFixed(2)) : 0,
      cambio: 0,
      monto_pagado: venta === null ? 0 : parseFloat(total.toFixed(2)),
    });
    setMontos({
      efectivo: venta === null ? 0 : parseFloat(total.toFixed(2)),
      tarjeta: 0,
      puntos: 0,
      transferencia: 0,
      cheque: 0,
    });
    const monedero_generado = calcularMonedero(venta);
    setMonedero(parseFloat(monedero_generado));
    setMonederoTotal(
      !cliente?.monedero_electronico
        ? parseFloat(monedero_generado)
        : parseFloat(cliente?.monedero_electronico) +
            parseFloat(monedero_generado)
    );
    setOpen(true);
  };

  const handleCloseModalVenta = () => setOpen(false);

  const hancleClickCerrarVentaCambio = () => {
    setCambio(true);

    //Limpiar el storage de ventas
    localStorage.removeItem("DatosVentas");
    localStorage.removeItem("VentaOriginal");
    //Actualizar la tabla de ventas
    setUpdateTablaVentas(!updateTablaVentas);
    setUpdateClientVenta(!updateClientVenta);
    setDatosVentasActual({
      subTotal: 0,
      total: 0,
      impuestos: 0,
      iva: 0,
      ieps: 0,
      descuento: 0,
      monedero: 0,
    });
  };

  const handleCloseCambio = () => {
    //limpiar states
    setCambio(false);
    resetStates();
    setNotaVenta(null);
  };

  const resetStates = () => {
    setDatosCliente({});
    setMonedero(0);
    setfechaVencimientoDate("");
    setMonederoTotal(0);
    setVentaBase(0);
    setEditarCliente(false);
    setVentaCredito(false);

    //States de los montos a pagar
    setMontoEnCaja(0);
    setTotales(initial_state_totales);
    setMontos(initial_state_montos);
    setVentaRetomada(null);
    setAbonoMinimo(0);
    setFechaVenta(moment().tz("America/Mexico_City").format());
  };

  /* const handleResetMontos = () => {
    setMontos({ ...initial_state_montos, efectivo: datosVenta.total });
    setTotales({
      subtotal:
        datosVenta === null ? 0 : parseFloat(datosVenta.subTotal.toFixed(2)),
      impuestos:
        datosVenta === null ? 0 : parseFloat(datosVenta.impuestos.toFixed(2)),
      total: datosVenta === null ? 0 : parseFloat(datosVenta.total.toFixed(2)),
      descuento: 0,
      cambio: 0,
      monto_pagado:
        datosVenta === null ? 0 : parseFloat(datosVenta.total.toFixed(2)),
    });
    setEfectivoConstante(datosVenta.total);
  }; */

  const ventaFinal = { ...venta_base };

  const handleClickFinishVenta = async () => {
    if (venta_credito && venta_base.total > datosCliente.credito_disponible) {
      snackMessage({
        message:
          "La cantidad total de la venta supera el crédito disponible del cliente",
        variant: "info",
      });
      return;
    }
    if (totales.cambio < 0) return;
    try {
      setOpenBackDrop(true);
      //Obtener datos del storage
      const usuario = JSON.parse(localStorage.getItem("sesionCafi"));
      //Generar folio
      const folio = numerosRandom(100000000000, 999999999999);
      //Agregar folio venta
      ventaFinal.folio = `${folio}`;
      //Agregar los montos en caja
      const montosEnCaja = {
        monto_efectivo: {
          monto: montoEnCaja,
          metodo_pago: formaPago[0].Value,
        },
        monto_tarjeta_debito: {
          monto: parseFloat(montos.tarjeta),
          metodo_pago: formaPago[7].Value,
        },
        monto_tarjeta_credito: {
          monto: 0,
          metodo_pago: formaPago[3].Value,
        },
        monto_creditos: {
          monto: venta_credito ? ventaFinal.total : 0,
          metodo_pago: "99",
        },
        monto_monedero: {
          monto: parseFloat(montos.puntos),
          metodo_pago: formaPago[4].Value,
        },
        monto_transferencia: {
          monto: parseFloat(montos.transferencia),
          metodo_pago: formaPago[2].Value,
        },
        monto_cheques: {
          monto: parseFloat(montos.cheque),
          metodo_pago: formaPago[1].Value,
        },
        monto_vales_despensa: {
          monto: 0,
          metodo_pago: formaPago[6].Value,
        },
      };

      const abonoMinimo = parseFloat(abono_minimo);
      ventaFinal.montos_en_caja = montosEnCaja;
      //Colocamos si es venta a credito
      ventaFinal.credito = venta_credito;
      ventaFinal.abono_minimo = venta_credito && abonoMinimo ? abonoMinimo : 0;
      //Agregar descuentos de ventas
      ventaFinal.descuento_general_activo = ventaFinal.descuento_general_activo
        ? ventaFinal.descuento_general_activo
        : false;
      ventaFinal.descuento_general = ventaFinal.descuento_general
        ? ventaFinal.descuento_general
        : null;
      //Declarar dias de credito como false
      ventaFinal.dias_de_credito_venta = datosCliente
        ? datosCliente.dias_credito
        : null;
      ventaFinal.fecha_de_vencimiento_credito = fechaVencimientoDate;
      ventaFinal.fecha_vencimiento_cotizacion = null;
      ventaFinal.cambio = totales.cambio;
      //Editar cliente
      ventaFinal.editar_cliente = editarCliente;
      //Agregando los puntos finales
      ventaFinal.monedero = parseFloat(montos.puntos);
      //si pago con puntos y su compra genero puntos, hacer que no genere esos puntos
      ventaFinal.puntos_totales_venta =
        parseFloat(montos.puntos) > 0
          ? monederoTotal - monedero
          : monederoTotal;
      //poner metodo y forma de pago
      ventaFinal.metodo_pago = venta_credito ? "PPD" : "PUE";
      let numero_mayor = 0;
      let forma_pago;

      if (venta_credito) {
        forma_pago = "99";
      } else {
        Object.keys(montosEnCaja).forEach((key) => {
          if (montosEnCaja[key].monto > numero_mayor) {
            numero_mayor = montosEnCaja[key].monto;
            forma_pago = montosEnCaja[key].metodo_pago;
          }
        });
      }

      //if (fecha_venta) ventaFinal.fecha_venta = fecha_venta; // OLD

      /* start. fix no se registra primera venta */

      const fechaSeleccionada = moment(fecha_venta).format("YYYY-MM-DD");
      const fechaActual = moment().tz("America/Mexico_City");
      const fechaVentaFinal = moment(fechaSeleccionada)
        .set({
          hour: fechaActual.hour(),
          minute: fechaActual.minute(),
          second: fechaActual.second(),
        })
        .tz("America/Mexico_City")
        .format();

      ventaFinal.fecha_venta = fechaVentaFinal;

      /* end. fix no se registra primera venta */

      ventaFinal.forma_pago = forma_pago;
      ventaFinal.turno = turnoEnCurso.horario_en_turno;
      //ventaFinal.subtotal_base = datosVenta.subTotal;
      setNotaVenta(ventaFinal);
      //Enviar los datos

      let ventaTo = await createVenta({
        variables: {
          input: ventaFinal,
          empresa: turnoEnCurso.empresa,
          sucursal: turnoEnCurso.sucursal,
          usuario: usuario._id,
          caja: turnoEnCurso.id_caja,
          isOnline: isOnline,
        },
      });

      //Quitar loading
      setPriceSelected(1);
      setReloadProductos(!reloadProductos);
      setOpenBackDrop(false);
      setOpen(false);
      hancleClickCerrarVentaCambio();
      saveVentasToCloud({
        ...ventaTo.data.createVenta.datos_to_save_storage,
        empresa: turnoEnCurso.empresa,
        sucursal: turnoEnCurso.sucursal,
        cliente: ventaFinal.cliente,
        productos: ventaFinal.productos,
        puntos_totales_venta: ventaFinal.puntos_totales_venta,
        usuario: usuario._id,
        caja: ventaFinal.caja,
        credito: ventaFinal.credito,
        forma_pago: ventaFinal.forma_pago,
        tipo_venta: ventaFinal.tipo_venta,
        montos_en_caja: ventaFinal.montos_en_caja,
      });

      //print ticket
      if (ventaFinal.tipo_emision === "TICKET") {
        let datos = {
          ventaFinal,
          monto_pagado: numero_mayor,
          turno: turnoEnCurso,
          sesion: sesionUsuario,
        };
        await imprimirTicketVenta(datos);
      }
      const resultCajon = await abrirCajonQuery(impresora);
      if (resultCajon.success !== true) {
        console.log(resultCajon);
      }
    } catch (error) {
      setOpenBackDrop(false);
      if (error.message) {
        snackMessage({ message: error.message, variant: "error" });
      } else {
        snackMessage({ message: "Hubo un error", variant: "error" });
      }
      console.log(error);
      if (error.networkError) {
        console.log(error.networkError.result?.errors);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors?.message);
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      const tecla_escape = e.keyCode;
      if (tecla_escape === 27 && datosVenta !== null) {
        handleClickOpen();
      } else if (tecla_escape === 13 && cambio && !open) {
        handleCloseCambio();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [datosVenta]);

  return (
    <Fragment>
      {ventaRapida ? (
        <PagarButton
          {...props}
          fullWidth
          disableElevation
          variant="contained"
          size="large"
          startIcon={<BsFillCartCheckFill />}
          onClick={() => handleClickOpen()}
          disabled={
            !turnoEnCurso ||
            !datosVenta ||
            (datosVenta && !datosVenta.productos.length)
          }
        >
          Pagar
        </PagarButton>
      ) : (
        <Button
          {...props}
          className="button-panel-venta b-primary"
          fullWidth
          variant="outlined"
          onClick={() => handleClickOpen()}
          disabled={
            !turnoEnCurso ||
            !datosVenta ||
            (datosVenta && !datosVenta.productos.length)
          }
        >
          <Box>
            <BsFillCartCheckFill className="icon-btn-primary" />
            <Typography className="text-btn-panel">Pagar</Typography>
            <Typography className="text-btn-panel-sec">ESC</Typography>
          </Box>
        </Button>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => handleCloseModalVenta()}
        classes={{ paper: classes.drawerPaper }}
        BackdropProps={{
          style: {
            backgroundColor: "rgba(0,0,0,0.1)",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" noWrap color="textSecondary">
              {`Venta ${datosCliente?.nombre_cliente || "Público General"}`}
            </Typography>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale="es-mx"
            >
              <DatePicker
                value={fecha_venta}
                onChange={(e) => setFechaVenta(e.format())}
                disableFuture={true}
                disabled={!sesionUsuario.accesos.ventas.administrador.ver}
                format="DD/MM/YYYY"
              />
            </MuiPickersUtilsProvider>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            mb={3}
          >
            <Typography style={{ fontSize: "18px" }}>
              <b>{venta_credito ? "Total a crédito" : "Total a pagar"}</b>
            </Typography>

            <Typography variant="h2" style={{ color: green[300] }}>
              <b>$ {formatoMexico(totales.total)}</b>{" "}
              <b style={{ fontSize: 30 }}>MXN</b>
            </Typography>
            <Typography style={{ color: green[300] }}>
              <b>{numberToLetters(totales.total)} MXN</b>
            </Typography>
          </Box>
          <ObtenerMontos
            montos={montos}
            setMontos={setMontos}
            totales={totales}
            setTotales={setTotales}
            venta_credito={venta_credito}
            montoEnCaja={montoEnCaja}
            setMontoEnCaja={setMontoEnCaja}
            datosCliente={datosCliente}
            efectivoConstante={efectivoConstante}
            setEfectivoConstante={setEfectivoConstante}
          />
          <Box my={1}>
            <MontosRapidos
              montos={montos}
              setMontos={setMontos}
              totales={totales}
              setEfectivoConstante={setEfectivoConstante}
              setTotales={setTotales}
              venta={datosVenta}
              ventaCredito={venta_credito}
            />
          </Box>
          {datosCliente?.nombre_cliente ? (
            <Box
              my={1}
              p={1}
              style={{
                borderRadius: 5,
                border: `1px solid ${blueGrey[100]}`,
              }}
            >
              <InfoClienteVenta
                montos={montos}
                totales={totales}
                datosCliente={datosCliente}
                monedero={monedero}
                monederoTotal={monederoTotal}
                setVentaCredito={setVentaCredito}
                setEditarCliente={setEditarCliente}
                venta_base={venta_base}
                venta_credito={venta_credito}
                credito_disponible={credito_disponible}
              />
              <Box my={2} display={venta_credito ? "flex" : "none"}>
                <CreditoVenta
                  montos={montos}
                  setMontos={setMontos}
                  totales={totales}
                  setTotales={setTotales}
                  venta_credito={venta_credito}
                  venta_base={venta_base}
                  setMontoEnCaja={setMontoEnCaja}
                  datosCliente={datosCliente}
                  setDatosCliente={setDatosCliente}
                  editarCliente={editarCliente}
                  setEditarCliente={setEditarCliente}
                  fechaVencimientoDate={fechaVencimientoDate}
                  setfechaVencimientoDate={setfechaVencimientoDate}
                  setAbonoMinimo={setAbonoMinimo}
                  abono_minimo={abono_minimo}
                  fecha_venta={fecha_venta}
                />
              </Box>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Box width="100%" display="flex" flexDirection="column" gridGap={10}>
            <Box
              display="flex"
              justifyContent="flex-end"
              p={1}
              px={1.5}
              style={{
                borderRadius: 5,
                backgroundColor: blue[50],
              }}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <DescuentoVenta
                  venta_base={venta_base}
                  setVentaBase={setVentaBase}
                  montos={montos}
                  setMontos={setMontos}
                  totales={totales}
                  setTotales={setTotales}
                />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ color: blue[600] }}
                >
                  <Typography align="left" style={{ fontSize: "25px" }}>
                    <b>
                      {Math.sign(totales.cambio) === 1
                        ? "Cambio:"
                        : "Faltante:"}
                    </b>
                  </Typography>
                  <Box mx={1} />
                  <Typography
                    style={
                      Math.sign(totales.cambio) === 1
                        ? { fontSize: 25, color: blue[600] }
                        : Math.sign(totales.cambio) === 0
                        ? { fontSize: 25, color: blue[600] }
                        : { fontSize: 25, color: red[300] }
                    }
                  >
                    <b>
                      $ {totales.cambio ? formatoMexico(totales.cambio) : 0}
                    </b>
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" gridGap={10}>
              <CancelarButton
                fullWidth
                size="large"
                variant="contained"
                startIcon={<Close />}
                disableElevation
                onClick={() => handleCloseModalVenta()}
              >
                Cancelar
              </CancelarButton>
              <PagarButton
                fullWidth
                onClick={() => {
                  handleClickFinishVenta();
                }}
                size="large"
                variant="contained"
                autoFocus
                disableElevation
                disabled={!venta_base || totales.cambio < 0}
                startIcon={<Done />}
              >
                Terminar
              </PagarButton>
            </Box>
          </Box>
        </DialogActions>
      </Drawer>
      <Dialog
        open={cambio}
        onClose={() => handleCloseCambio()}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle style={{ textAlign: "center" }}>
          <Alert
            severity="success"
            action={[
              <NotaDeVenta dataVenta={notaVenta} />,
              <IconButton
                onClick={() => handleCloseCambio()}
                size="small"
                color="inherit"
                autoFocus
              >
                <Close style={{ fontSize: "25px" }} />
              </IconButton>,
            ]}
          >
            Venta finalizada
          </Alert>
        </DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Typography variant="h6" align="center">
              Su cambio
            </Typography>
            <Typography variant="h3" align="center">
              ${formatoMexico(totales.cambio)}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

CerrarVenta.muiName = Button.muiName;
export default CerrarVenta;
