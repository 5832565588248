import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, DialogActions, makeStyles } from "@material-ui/core";
import { Add, ArrowBack } from "@material-ui/icons";
import ErrorPage from "../../../../../../components/ErrorPage";
import { useApolloClient } from "@apollo/client";
import { FacturacionCtx } from "../../../../../../context/Facturacion/facturacionCtx";
import CrearTransporte from "./CrearTransporte";
import { GET_TRANSPORTES } from "../../../../../../gql/Facturacion/CartaPorte";
import CustomLoader from "../../../../../../components/CustomLoader";
import EliminarTransporte from "./EliminarTransporte";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "45vh",
  },
}));

const initial_query = {
  data: undefined,
  loading: true,
  error: undefined,
};

export default function Transportes() {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState(initial_query);
  const [loading, setLoading] = useState(false);
  const { setComplementCartaPorte } = useContext(FacturacionCtx);
  const [selected, setSelected] = useState("");
  const client = useApolloClient();

  const handleOpen = () => {
    setOpen(true);
    getTransportes();
  };
  const handleClose = () => {
    setOpen(false);
    setSelected("");
  };

  const getTransportes = async () => {
    try {
      const response = await client.query({
        query: GET_TRANSPORTES,
        variables: {
          sucursal: sesion.sucursal._id,
          empresa: sesion.empresa._id,
        },
        fetchPolicy: "network-only",
      });
      if (response.data) {
        setQuery({
          error: undefined,
          loading: false,
          data: response.data.obtenerAutoTransportes,
        });
      }
    } catch (error) {
      console.log(error);
      setQuery({
        error,
        loading: false,
        data: undefined,
      });
    }
  };

  const handleSelect = async () => {
    setLoading(true);
    const { _id, tagIdentificador, ...Autotransporte } = selected;
    setComplementCartaPorte((st) => ({
      ...st,
      Complemento: {
        CartaPorte30: {
          ...st.Complemento.CartaPorte30,
          Mercancias: {
            ...st.Complemento.CartaPorte30.Mercancias,
            Autotransporte,
          },
        },
      },
    }));
    setLoading(false);
    handleClose();
  };

  return (
    <div>
      <Button
        onClick={() => handleOpen()}
        size="small"
        startIcon={<Add />}
        style={{ textTransform: "none" }}
        color="primary"
      >
        Añadir
      </Button>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>
          <Box display="flex" alignItems="center" gridGap={10}>
            <IconButton color="primary" onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Seleccionar transporte</Typography>
            <Box flexGrow={1} />
            <CrearTransporte refetch={getTransportes} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <RenderTransportes
            query={query}
            selected={selected}
            setSelected={setSelected}
            getTransportes={getTransportes}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSelect}
            size="small"
            variant="contained"
            color="primary"
            disableElevation
            disabled={loading || !selected}
            startIcon={
              loading ? <CircularProgress size={20} color="inherit" /> : null
            }
          >
            Seleccionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const RenderTransportes = ({ query, selected, setSelected, getTransportes }) => {
  const classes = useStyles();
  const { loading, data, error } = query;

  if (loading) return <CustomLoader height="45vh" />;
  if (error) return <ErrorPage />;

  return (
    <Paper variant="outlined">
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: 150 }}>Nombre</TableCell>
              <TableCell style={{ minWidth: 250 }}>
                Tipo y número de permiso SCT
              </TableCell>
              <TableCell style={{ minWidth: 300 }}>
                Configuración vehicular
              </TableCell>
              <TableCell style={{ minWidth: 300 }}>Seguro</TableCell>
              <TableCell style={{ minWidth: 360 }}>Remolques</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              const idVehicular = row.IdentificacionVehicular;
              const seguro = row.Seguros;
              return (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  onClick={(e) => setSelected(row)}
                  selected={row._id === selected._id}
                >
                  <TableCell>{row.tagIdentificador}</TableCell>
                  <TableCell>{`Tipo ${row.PermSCT} No. ${row.NumPermisoSCT}`}</TableCell>
                  <TableCell>{`Conf. ${idVehicular.ConfigVehicular} Placa ${idVehicular.PlacaVM} Mod: ${idVehicular.AnioModeloVM}`}</TableCell>
                  <TableCell>{`${seguro.AseguraRespCivil} Poliza ${seguro.PolizaRespCivil}`}</TableCell>
                  <TableCell>
                    {row.Remolques.map((rem, ind) => (
                      <Typography key={`${ind}-${rem.Placa}`}>
                        {`#${ind + 1}: SubTipoRem ${rem.SubTipoRem} Placa ${
                          rem.Placa
                        }`}
                      </Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    <CrearTransporte edit datos={row} refetch={getTransportes} />
                  </TableCell>
                  <TableCell>
                    <EliminarTransporte
                      idTransporte={row._id}
                      refetch={getTransportes}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
