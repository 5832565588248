import React from "react";
import { Grid, Paper, makeStyles } from "@material-ui/core";
import { TiendaCtx } from "../../../../../../context/Empresa/tiendaCtx";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ItemCustomBanner from "./ItemCustomBanner";

const useStyles = makeStyles((theme) => ({
  root: {
    with: "100%",
  },
}));

export default function ItemsContainer() {
  const classes = useStyles();
  const { data, setData } = React.useContext(TiendaCtx);

  if(!data || !data.customBanners){
    return null
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    const newState = [...data.customBanners];
    const [removed] = newState.splice(sourceIndex, 1);
    newState.splice(destinationIndex, 0, removed);

    setData((state) => ({
      ...state,
      customBanners: newState,
      customOrderChanged: newState.filter((res) => res._id),
    }));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="custom-banners">
        {(provided) => (
          <Grid
            container
            spacing={2}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {data.customBanners.map((res, index) => (
              <Draggable key={res._id} draggableId={res._id} index={index}>
                {(provided) => (
                  <Grid
                    item
                    xs={12}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <Paper className={classes.root}>
                      <ItemCustomBanner
                        banner={res}
                        provided={provided}
                      />
                    </Paper>
                  </Grid>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
}

