import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import {
  Box,
  CircularProgress,
  Container,
  IconButton,
} from "@material-ui/core";
import RegistroCajas from "./RegistrarCajas";
import DescripcionCatalogo from "../../../../components/DescripcionCatalogo";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { Add, ArrowBack } from "@material-ui/icons";
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { CREAR_CAJA, OBTENER_CAJAS } from "../../../../gql/Cajas/cajas";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import { FcPodiumWithSpeaker } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  icon: {
    width: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Cajas() {
  const { isOnline } = useContext(AccesosContext);
  const classes = useStyles();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const [crearCaja] = useMutation(CREAR_CAJA);

  /* Queries */
  const { data, refetch } = useQuery(OBTENER_CAJAS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
    },
    fetchPolicy: "network-only",
  });

  let obtenerCajasSucursal = [];
  /* Mutation */

  if (data) {
    obtenerCajasSucursal = data.obtenerCajasSucursal;
  }

  const [open, setOpen] = React.useState(false);
  const descripcion =
    "En este apartado se registran las cajas necesarias para realizar los movimientos operativos de ventas.";
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const nuevaCaja = async () => {
    try {
      setLoading(true);
      await crearCaja({
        variables: {
          input: {
            usuario_creador: sesion._id,
            numero_usuario_creador: sesion.numero_usuario,
            nombre_usuario_creador: sesion.nombre,
          },
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
        },
      });
      refetch();
      setAlert({ message: "¡Listo!", status: "success", open: true });
      setLoading(false);
    } catch (error) {
      setAlert({ message: "Hubo un error", status: "error", open: true });
      setLoading(false);
    }
  };

  return (
    <div>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcPodiumWithSpeaker style={{ fontSize: 100 }} />
          </Box>
          Cajas
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Typography variant="h6" className={classes.title}>
          Cajas
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <Box display="flex" justifyContent="flex-end">
            {sesion.accesos.catalogos.cajas.agregar === false ? null : (
              <Button
                color="primary"
                variant="contained"
                size="large"
                disableElevation
                onClick={() => nuevaCaja()}
                disabled={loading || !isOnline}
                startIcon={
                  loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    <Add />
                  )
                }
              >
                Agregar
              </Button>
            )}
          </Box>
          <Box mx={0.5} />
          <DescripcionCatalogo texto={descripcion} />
          <IconButton variant="contained" color="primary" onClick={handleClose}>
            <ArrowBack />
          </IconButton>
        </Box>
        <Box mt={7}>
          <Container maxWidth="sm">
            <RegistroCajas
              isOnline={isOnline}
              obtenerCajasSucursal={obtenerCajasSucursal}
              setAlert={setAlert}
              refetch={refetch}
            />
          </Container>
        </Box>
      </Dialog>
    </div>
  );
}
