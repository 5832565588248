import React, { useState, useContext } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { Alert, AlertTitle } from "@material-ui/lab";
import DoneAll from "@material-ui/icons/DoneAll";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  Grid,
  DialogTitle,
  Slide,
  TextField,
  Typography,
  MenuItem,
  makeStyles,
  IconButton,
  Select,
  FormControl,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { CREAR_ABONO_CLIENTE } from "../../../../../../gql/Tesoreria/abonos";
import { AbonosCtx } from "../../../../../../context/Tesoreria/abonosCtx";
import { formaPago } from "../../../../Facturacion/catalogos";
import BackdropComponent from "../../../../../../components/Layouts/BackDrop";
import RemoveCircleTwoToneIcon from "@material-ui/icons/RemoveCircleTwoTone";
import moment from "moment";
import { useDebounce } from "use-debounce";
import { imprimirTicketAbonos } from "../../ImpTicketAbono";
import { formatoMexico } from "../../../../../../config/reuserFunctions";
import { AccesosContext } from "../../../../../../context/Accesos/accesosCtx";
import { MainContext } from "../../../../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  title: {
    fontSize: 16,
  },
});
function Liquidar(props) {
  //listo
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { abonos } = useContext(AbonosCtx);
  const { getData, ventaToCloud } = React.useContext(AccesosContext);
  const { snackMessage, turnoEnCurso } = React.useContext(MainContext);
  const [metodoPago, setMetodoPago] = useState("");
  const [value, setValue] = useState(0);
  //const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [cuentaTotalDescuento, setCuentaTotalDescuento] = useState(0);
  const [dineroDescontado, setDineroDescontado] = useState(0);
  const [crearAbonoVentaCredito] = useMutation(CREAR_ABONO_CLIENTE);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [nameMetodo, setNameMetodo] = useState("");
  const [efectivoRecibido, setEfectivoRecibido] = useState(0);
  const [cambioCliente, setCambioCliente] = useState(0);
  const navigate = useNavigate();

  const handleClick = () => {
    setEfectivoRecibido(0);
    setNameMetodo("");
    setMetodoPago("");
    setCambioCliente(0);
    setValue(0);
    setCuentaTotalDescuento(0);
    setDineroDescontado(0);
    if (turnoEnCurso || sesion.accesos.tesoreria.caja_principal.ver) {
      setOpen(!open);
    } else {
      navigate("/ventas");
    }
  };

  const obtenerPrecioText = (e) => {
    let valorText = parseFloat(e.target.value);
    if (valorText >= 0 && valorText <= props.total_ventas) {
      /* let porcentaje = parseFloat(
        ((valorText * 100) / props.total_ventas).toFixed(4)
      );
      let dineroDescontado = parseFloat(
        (props.total_ventas - valorText).toFixed(4)
      );
      setCuentaTotalDescuento(valorText);
      setDineroDescontado(dineroDescontado);
      setValue(porcentaje);
      setTotal(dineroDescontado); */

      let percent = parseFloat(
        ((valorText * 100) / props.venta.saldo_credito_pendiente).toFixed(4)
      );
      let porcentaje = parseFloat(100 - percent);
      let cuenta_con_descuento = parseFloat(
        ((props.venta.saldo_credito_pendiente * porcentaje) / 100).toFixed(4)
      );
      setCuentaTotalDescuento(cuenta_con_descuento);
      setDineroDescontado(valorText);
      setValue(percent);
    }
  };

  const obtenerPorcientoSlide = (newValue) => {
    /* let porcentaje = parseFloat(e.target.value); */
    if (newValue >= 0 && newValue <= 100) {
      /* setValue(porcentaje);
      //let porcentaje  =  parseFloat((100 - newValue).toFixed(4));//Porcentaje para calculos de descuento

      let cuenta_con_descuento = parseFloat(
        ((props.total_ventas * porcentaje) / 100).toFixed(4)
      );
      let dineroDescontado = parseFloat(
        (props.total_ventas - cuenta_con_descuento).toFixed(4)
      );
      setCuentaTotalDescuento(cuenta_con_descuento);
      setDineroDescontado(dineroDescontado);
      setTotal(dineroDescontado); */

      setValue(newValue);
      let porcentaje = parseFloat((100 - newValue).toFixed(4)); //Porcentaje para calculos de descuento
      let cuenta_con_descuento = parseFloat(
        ((props.venta.saldo_credito_pendiente * porcentaje) / 100).toFixed(4)
      );
      let dineroDescontado = parseFloat(
        (props.venta.saldo_credito_pendiente - cuenta_con_descuento).toFixed(4)
      );
      setCuentaTotalDescuento(cuenta_con_descuento);
      setDineroDescontado(dineroDescontado);
    }
  };

  const quitarDescuento = () => {
    setValue("");
    setCuentaTotalDescuento("");
    setDineroDescontado("");
    //setTotal(props.total_ventas);
  };

  const obtenerCambio = (pago) => {
    if (efectivoRecibido) {
      setCambioCliente(parseFloat(efectivoRecibido - pago).toFixed(4));
    }
  };

  const [TOTAL_PAGO] = useDebounce(
    cuentaTotalDescuento
      ? cuentaTotalDescuento
      : props.venta.saldo_credito_pendiente,
    500
  );

  React.useEffect(() => {
    obtenerCambio(TOTAL_PAGO);
  }, [TOTAL_PAGO]);

  const dataImp = {
    turno: {
      usuario: sesion.nombre,
      numero_usuario: sesion.numero_usuario,
      caja: turnoEnCurso ? parseInt(turnoEnCurso.numero_caja) : 1,
      turno: turnoEnCurso ? turnoEnCurso.horario_en_turno : "",
    },
    folio: props.venta.folio,
    numero_cliente: props.cliente.numero_cliente,
    clave_cliente: props.cliente.clave_cliente,
    nombre_cliente: props.cliente.nombre_cliente,
    saldo_inicial: props.venta.total,
    total_anterior: props.venta.saldo_credito_pendiente,
    su_pago: cuentaTotalDescuento
      ? cuentaTotalDescuento
      : props.venta.saldo_credito_pendiente,
    efectivo_recibido: efectivoRecibido,
    su_cambio: cambioCliente,
    total_restante: 0,
    metodo_pago: nameMetodo,
    descuento: dineroDescontado,
  };

  const liquidarVentas = async () => {
    try {
      setLoading(true);
      let ObjectMetodoPago = {};
      formaPago.forEach((val) => {
        if (metodoPago === val.Value) {
          ObjectMetodoPago = val;
        }
      });

      const input = {
        tipo_movimiento: "ABONO_CLIENTE",
        rol_movimiento: turnoEnCurso ? "CAJA" : "CAJA_PRINCIPAL",
        numero_caja: turnoEnCurso ? parseInt(turnoEnCurso.numero_caja) : 0,
        id_Caja: turnoEnCurso ? turnoEnCurso.id_caja : "",
        concepto: "ABONO_CLIENTE",
        monto_total: props.total_ventas,
        descuento: {
          porciento_descuento: value,
          dinero_descontado: dineroDescontado,
          total_con_descuento: cuentaTotalDescuento,
        },
        horario_turno: turnoEnCurso ? turnoEnCurso.horario_en_turno : "",
        metodo_de_pago: {
          clave: metodoPago,
          metodo: nameMetodo,
        },

        id_usuario: sesion._id,
        numero_usuario_creador: sesion.numero_usuario,
        nombre_usuario_creador: sesion.nombre,
        id_cliente: props.cliente._id,
        credito_disponible: props.cliente.credito_disponible,
        numero_cliente: props.cliente.numero_cliente,
        nombre_cliente: props.cliente.nombre_cliente,
        telefono_cliente: props.cliente.telefono_cliente,
        email_cliente: props.cliente.email_cliente,
        ventas: props.isIcon
          ? [
              {
                monto_total_abonado: 0,
                id_venta: abonos[props.index].id_venta,
                saldo_credito_pendiente:
                  abonos[props.index].saldo_credito_pendiente,
              },
            ]
          : abonos,
        liquidar: true,
        caja_principal: turnoEnCurso
          ? false
          : sesion.accesos.tesoreria.caja_principal.ver,
      };
      if (metodoPago) {
        await crearAbonoVentaCredito({
          variables: {
            empresa: sesion.empresa._id,
            sucursal: sesion.sucursal._id,
            input,
          },
        });
        await imprimirTicketAbonos(sesion, dataImp, "CLIENTE", true);
        if (ventaToCloud) getData(sesion.empresa, sesion.sucursal);
        setEfectivoRecibido(0);
        setNameMetodo("");
        setMetodoPago("");
        setCambioCliente(0);
        setValue(0);
        setCuentaTotalDescuento(0);
        setDineroDescontado(0);
        props.recargar();
        setOpen(false);
        setLoading(false);
        snackMessage({
          message: "Venta liquidada con éxito.",
          variant: "success",
        });
      } else {
        setLoading(false);
        snackMessage({
          message: "Elige un método de pago.",
          variant: "error",
        });
      }
    } catch (error) {
      if (error.networkError) {
        console.log(error.networkError.result);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors);
      }
    }
  };

  const handleGetRecibido = (efectivo) => {
    const pago = cuentaTotalDescuento
      ? cuentaTotalDescuento
      : props.venta.saldo_credito_pendiente;
    setEfectivoRecibido(efectivo);
    if (pago) {
      setCambioCliente(parseFloat(efectivo - pago).toFixed(4));
    }
    if (!efectivo) {
      setCambioCliente(0);
    }
  };

  return (
    <Box>
      {props.isIcon ? (
        <IconButton
          aria-label="liquidar"
          onClick={handleClick}
          disabled={props.estatus_credito === "PAGADA"}
          size="small"
        >
          <DoneAll />
        </IconButton>
      ) : (
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleClick}
        >
          Liquidar TODAS
        </Button>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClick}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <BackdropComponent loading={loading} setLoading={setLoading} />
        <DialogTitle id="alert-dialog-slide-title">
          <Box display="flex">
            <Box width="100%" display="flex" justifyContent="flex-start">
              {props.isIcon ? "Liquidar cuenta" : "Liquidar todas las cuentas"}
            </Box>
            <Box width="42%" display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClick}
                size="large"
              >
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box>
            <Alert severity="info">
              <AlertTitle>Agregar descuento</AlertTitle>
              Aplica un descuento por pronto pago a tu cuenta
            </Alert>
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Typography>
                    <b>Porcentaje:</b>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    name="descuento"
                    id="form-venta-porsentaje-descuento"
                    variant="outlined"
                    value={value}
                    onChange={(e) =>
                      obtenerPorcientoSlide(parseFloat(e.target.value))
                    }
                    type="number"
                    disabled={false}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography>
                    <b>Dinero a descontar</b>
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    type="number"
                    name="precioConDescuento"
                    onChange={obtenerPrecioText}
                    value={dineroDescontado ? dineroDescontado : 0}
                    className={classes.input}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  color="secondary"
                  size="medium"
                  onClick={quitarDescuento}
                  startIcon={<RemoveCircleTwoToneIcon />}
                >
                  Quitar descuento
                </Button>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography>
              <b>Método de pago:</b>
            </Typography>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                width="100%"
                name="metodo_pago"
                variant="outlined"
                value={metodoPago ? metodoPago : ""}
                onChange={(e, child) => {
                  setMetodoPago(e.target.value);
                  setNameMetodo(
                    child.props.metodo ? child.props.metodo.Name : ""
                  );
                  if (e.target.value !== "01") {
                    setEfectivoRecibido(0);
                  }
                }}
              >
                <MenuItem value="">
                  <em>Selecciona uno</em>
                </MenuItem>
                {formaPago.map((metodo, index) => {
                  return (
                    <MenuItem key={index} value={metodo.Value} metodo={metodo}>
                      {metodo.Name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Typography>
              <b>Efectivo recibido:</b>
            </Typography>
            <TextField
              fullWidth
              className={classes.input}
              onChange={(e) => handleGetRecibido(parseFloat(e.target.value))}
              onBlur={(e) => {
                if (!e.target.value) {
                  setEfectivoRecibido(0);
                }
              }}
              value={efectivoRecibido}
              size="small"
              variant="outlined"
              type="number"
              disabled={metodoPago !== "01"}
            />
          </Box>
          <Box mt={2}>
            <Typography>
              <b>Nombre de Cliente:</b> {props.cliente.nombre_cliente}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="h6">
              <b>Total</b>
            </Typography>
            <Box mx={1} />
            <Typography variant="h6" style={{ color: "green" }}>
              <b>
                $
                {formatoMexico(
                  cuentaTotalDescuento
                    ? cuentaTotalDescuento
                    : props.venta.saldo_credito_pendiente
                )}
              </b>
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="h6">
              <b>Cambio</b>
            </Typography>
            <Box mx={1} />
            <Typography variant="h6" style={{ color: "green" }}>
              <b>${formatoMexico(cambioCliente)}</b>
            </Typography>
          </Box>
          {/* <Box p={1}>
                        <Alert severity="info">Si desea editar el abono del cliente, <br/>
                        procure este sea mayor al establecido</Alert>
                    </Box> */}
        </DialogContent>
        <Box
          display="flex"
          justifyContent="flex-end"
          alignContent="center"
          p={2}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{ fontSize: 16 }}
            onClick={liquidarVentas}
          >
            Liquidar
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
}
export default Liquidar;
/* 
<Box width="100%">
<Typography>Cuenta No. 2501265</Typography>
<TextField
    fullWidth
    size="small"
    /* error 
    name="nombre_comercial"
    id="form-producto-nombre-comercial"
    variant="outlined"
    value="$150,000.00 Mx" 
    /* helperText="Incorrect entry." 
    /* onChange={obtenerCampos} 
/>
</Box>
<Box width="100%">
<Typography>Cuenta No. 2501265</Typography>
<TextField
    fullWidth
    size="small"
    /* error 
    name="nombre_comercial"
    id="form-producto-nombre-comercial"
    variant="outlined"
    value="$150,000.00 Mx" 
    /* helperText="Incorrect entry." 
    /* onChange={obtenerCampos} 
/>
</Box> */
