import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import TablePagination from "@material-ui/core/TablePagination";
import { CropOriginal } from "@material-ui/icons";
import { ClienteCtx } from "../../../context/Catalogos/crearClienteCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { OBTENER_CLIENTES } from "../../../gql/Catalogos/clientes";
import CrearCliente from "../../sucursales/Catalogos/Cliente/CrearCliente";
import ErrorMessage from "../../../components/ErrorMessage";
import CustomLoader from "../../../components/CustomLoader";
import { Img } from "react-image";
import DetalleClienteVentas from "./DetalleCliente";
import { Button, withStyles } from "@material-ui/core";

const CustomButtonList = withStyles((theme) => ({
  root: {
    padding: "8px 0",
    "& .MuiButton-label": {
      display: "flex",
      gap: theme.spacing(1),
    },
  },
}))(Button);

export default function ListaClientes({
  tipo,
  filtro,
  handleClickOpen,
  page,
  setPage,
}) {
  const { setUpdateClientVenta, updateClientVenta, update } = React.useContext(
    ClienteCtx
  );
  const [value] = useDebounce(filtro, 1000);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const limit = 20;

  const { loading, data, error, refetch } = useQuery(OBTENER_CLIENTES, {
    variables: {
      tipo,
      filtros: "",
      empresa: sesion.empresa._id,
      eliminado: false,
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    refetch();
  }, [update, refetch]);

  const handleClickSelectClient = (data) => {
    //clear object
    const {
      representante,
      estado_cliente,
      tipo_cliente,
      empresa,
      sucursal,
      fecha_nacimiento,
      fecha_registro,
      eliminado,
      ...clean_cliente
    } = data;
    try {
      const venta = JSON.parse(localStorage.getItem("DatosVentas"));
      let venta_actual = venta === null ? {} : venta;
      localStorage.setItem(
        "DatosVentas",
        JSON.stringify({
          ...venta_actual,
          subtotal_base:
            venta_actual.subTotal === undefined ? 0 : venta_actual.subTotal,
          total_base: venta_actual.total === undefined ? 0 : venta_actual.total,
          subTotal:
            venta_actual.subTotal === undefined ? 0 : venta_actual.subTotal,
          total: venta_actual.total === undefined ? 0 : venta_actual.total,
          impuestos:
            venta_actual.impuestos === undefined ? 0 : venta_actual.impuestos,
          iva: venta_actual.iva === undefined ? 0 : venta_actual.iva,
          ieps: venta_actual.ieps === undefined ? 0 : venta_actual.ieps,
          descuento:
            venta_actual.descuento === undefined ? 0 : venta_actual.descuento,
          monedero:
            venta_actual.monedero === undefined ? 0 : venta_actual.monedero,
          // tipo_cambio: venta_actual.tipo_cambio ? venta_actual.tipo_cambio : {},
          cliente: clean_cliente ? clean_cliente : {},
          venta_cliente: true,
          productos:
            venta_actual.productos?.length > 0 ? venta_actual.productos : [],
          tipo_emision: venta_actual.tipo_emision
            ? venta_actual.tipo_emision
            : "TICKET",
        })
      );
      setUpdateClientVenta(!updateClientVenta);
      handleClickOpen();
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    refetch({
      tipo,
      filtros: filtro,
      empresa: sesion.empresa._id,
      eliminado: false,
      limit,
      offset: 0,
    });
  }, [filtro]);

  if (loading) return <CustomLoader height="40vh" />;
  if (error) return <ErrorMessage error={error?.message} />;

  const { obtenerClientes } = data;

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  function MyImageComponent({ imagen }) {
    return imagen ? (
      <Img
        src={[imagen]}
        style={{ maxHeight: "100%", maxWidth: "100%", borderRadius: 5 }}
      />
    ) : (
      <CropOriginal fontSize="large" color="disabled" />
    );
  }

  return (
    <React.Fragment>
      <DialogContent>
        {obtenerClientes.docs.map((row, index) => {
          return (
            <Box
              key={index}
              mb={1}
              style={{ borderBottom: "1px solid", borderColor: "#e9e9e9" }}
            >
              <CustomButtonList
                component={Box}
                fullWidth
                onClick={() => handleClickSelectClient(row)}
              >
                <Box
                  height={48}
                  width={48}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius={5}
                >
                  <MyImageComponent imagen={row.imagen} />
                </Box>
                <Box>
                  <Typography variant="subtitle2">
                    {row.nombre_cliente}
                  </Typography>
                  <Typography variant="caption">{`Clave ${
                    row.clave_cliente
                  } RFC ${row.rfc ? row.rfc : ""}`}</Typography>
                </Box>
                <Box flexGrow={1} />
                <Box onClick={(e) => e.stopPropagation()}>
                  <DetalleClienteVentas data={row} />
                </Box>
                {sesion.accesos.catalogos.clientes.editar === false ? null : (
                  <Box onClick={(e) => e.stopPropagation()}>
                    <CrearCliente
                      tipo="CLIENTE"
                      accion="actualizar"
                      datos={row}
                      refetch={refetch}
                    />
                  </Box>
                )}
              </CustomButtonList>
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={obtenerClientes.totalDocs}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
        />
      </DialogActions>
    </React.Fragment>
  );
}
