import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import {
  formatoFechaCorta,
  formatoMexico,
  numberToLetters,
} from "../../../../../config/reuserFunctions";
import { ArrowBack, GetApp, PostAdd } from "@material-ui/icons";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  textTable: {
    fontSize: "12px",
  },
  filtros: {
    color: "#2980ba",
    fontSize: "12px",
  },
  encabezado: {
    backgroundColor: "#2980ba",
    color: "#fff",
    fontSize: "12px",
  },
  size: {
    width: "22.5cm",
    overflowX: "auto",
  },
  tabla: {
    width: "100%",
  },
}));

export default function NotaDeVenta({ dataVenta }) {
  const [openNotas, setOpenNotas] = useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  // const [loading, setLoading] = useState(false);
  //const [error, setError] = useState(false);
  //const [data, setData] = useState([]);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const fechaVenta = moment().tz("America/Mexico_City").format("ll");
  let height = 6.773333333333332;
  // /* Queries */
  // const productosDeUnaVenta = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await client.query({
  //       query: OBTENER_PRODUCTOS_DE_VENTA,
  //       variables: {
  //         id_venta: dataVenta._id,
  //       },
  //       fetchPolicy: "network-only",
  //     });
  //     setLoading(false);
  //     if (response.data) {
  //       setData(response.data.obtenerProductosDeUnaVenta);
  //     }
  //     if (response.error) setError(true);
  //   } catch (error) {
  //     setLoading(false);
  //     setError(true);
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   productosDeUnaVenta();
  // }, []);

  const classes = useStyles();
  const handleClickNota = () => {
    setOpenNotas(!openNotas);
  };

  const getImageCanva = async (id_div, doc) => {
    const canvas = await html2canvas(id_div);
    const imgData = canvas.toDataURL("image/png");
    const imgProps = doc.getImageProperties(imgData);
    const pdfWidth = doc.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    return { imgData, pdfWidth, pdfHeight };
  };

  const printDocument = async () => {
    try {
      setLoadingPdf(true);
      const divInformation = document.getElementById("encabezado-nota");
      const pagarediv = document.getElementById("pagare");
      let doc = new jsPDF("p", "mm", "letter");

      const encabezado = await getImageCanva(divInformation, doc);
      const pagare = await getImageCanva(pagarediv, doc);
      doc.addImage(
        encabezado.imgData,
        "JPEG",
        0,
        15,
        encabezado.pdfWidth,
        encabezado.pdfHeight
      );
      doc.autoTable({
        html: "#tabla-nota-venta",
        theme: "striped",
        startY: 40,
        margin: { left: 15, right: 15, top: 10 },
        styles: {
          fontSize: 8,
        },
        didDrawPage: function (data) {
          let footerStr = `Página ${data.pageNumber}`;
          doc.setFontSize(10);
          doc.text(footerStr, 185, 265);
        },
        didParseCell: function (data) {
          var rows = data.table.body;

          if (data.row.index === rows.length - 1) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });
      doc.addImage(
        pagare.imgData,
        "JPEG",
        0,

        height + 35 + 10,
        pagare.pdfWidth,
        pagare.pdfHeight
      );
      doc.save("Nota de venta.pdf");
      setLoadingPdf(false);
      handleClickNota();
    } catch (error) {
      console.log(error);
      setLoadingPdf(false);
    }
  };
  /* 
  if (loading) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        PDF
      </Button>
    );
  } */

  /* if (error) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        PDF
      </Button>
    );
  } */
  if (!dataVenta) return null;

  return (
    <>
      <Button
        color="primary"
        size="small"
        disableElevation
        style={{ marginRight: "16px" }}
        onClick={handleClickNota}
        startIcon={<PostAdd />}
      >
        Nota de venta
      </Button>
      <Dialog
        open={openNotas}
        onClose={handleClickNota}
        PaperProps={{ style: { minWidth: "1050px" } }}
      >
        <DialogTitle>
          {" "}
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} />
            <Button
              color="primary"
              startIcon={
                loadingPdf ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <GetApp />
                )
              }
              onClick={() => printDocument()}
            >
              Descargar
            </Button>
            <Box mx={1} />
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleClickNota}
            >
              <ArrowBack />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box id="encabezado-nota" px={8.5}>
            <Box
              display="flex"
              style={{ height: 15 }}
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Typography variant="h6" style={{ fontWeight: "800" }}>
                NOTA DE VENTA
              </Typography>
            </Box>
            <Box display="flex">
              <Box display="flex" alignItems="center">
                <Grid container spacing={2}>
                  <Grid item>
                    <Box display="flex">
                      <Typography className={classes.filtros}>
                        <b>EMPRESA:</b>
                      </Typography>
                      <Box mx={1} />
                      <Typography className={classes.textTable}>
                        {sesion
                          ? sesion.empresa.nombre_empresa.toUpperCase()
                          : "TODOS"}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography className={classes.filtros}>
                        <b>SUCURSAL:</b>
                      </Typography>
                      <Box mx={1} />
                      <Typography className={classes.textTable}>
                        {sesion
                          ? sesion.sucursal.nombre_sucursal.toUpperCase()
                          : "TODOS"}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography className={classes.filtros}>
                        <b>RFC:</b>
                      </Typography>
                      <Box mx={1} />
                      <Typography className={classes.textTable}>
                        {sesion ? sesion.empresa.rfc?.toUpperCase() : "TODOS"}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography className={classes.filtros}>
                        <b>FECHA DE VENTA:</b>
                      </Typography>
                      <Box mx={1} />
                      <Typography className={classes.textTable}>
                        {fechaVenta}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box display="flex">
                      <Typography className={classes.filtros}>
                        <b>NOMBRE DEL CLIENTE:</b>
                      </Typography>
                      <Box mx={1} />
                      <Typography className={classes.textTable}>
                        {dataVenta.cliente === null
                          ? "PÙBLICO GENERAL"
                          : dataVenta?.cliente?.nombre_cliente?.toUpperCase()}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography className={classes.filtros}>
                        <b>DOMICILIO:</b>
                      </Typography>
                      <Box mx={1} />
                      <Typography className={classes.textTable}>
                        {dataVenta.cliente && dataVenta?.cliente?.direccion
                          ? dataVenta?.cliente?.direccion.calle +
                            " #" +
                            dataVenta?.cliente?.direccion.no_ext
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography className={classes.filtros}>
                        <b>CIUDAD:</b>
                      </Typography>
                      <Box mx={1} />
                      <Typography className={classes.textTable}>
                        {dataVenta.cliente && dataVenta?.cliente?.direccion
                          ? dataVenta?.cliente?.direccion.municipio
                          : "N/A"}
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Typography className={classes.filtros}>
                        <b>TELÉFONO:</b>
                      </Typography>
                      <Box mx={1} />
                      <Typography className={classes.textTable}>
                        {dataVenta.cliente === null
                          ? "N/A"
                          : dataVenta?.cliente?.telefono}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box flexGrow={1} />
              <Box>
                <Typography>
                  <b>Folio:</b> {dataVenta.folio}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box my={1} />
          <Box display="flex" justifyContent="center">
            <TableContainer className={classes.size}>
              <Table
                id="tabla-nota-venta"
                size="small"
                className={classes.tabla}
              >
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.encabezado}>
                      NOMBRE DEL PRODUCTO
                    </TableCell>
                    <TableCell className={classes.encabezado}>
                      CANTIDAD
                    </TableCell>
                    <TableCell className={classes.encabezado}>
                      DESCUENTO
                    </TableCell>
                    <TableCell className={classes.encabezado}>
                      IMPUESTO
                    </TableCell>
                    <TableCell className={classes.encabezado}>
                      SUBTOTAL
                    </TableCell>
                    <TableCell className={classes.encabezado}>TOTAL</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataVenta?.productos.map((datos, index) => {
                    height = height + 6.773333333333332;
                    return (
                      <TableRow key={index}>
                        <TableCell className={classes.textTable}>
                          {datos.id_producto.datos_generales.nombre_comercial}
                        </TableCell>
                        <TableCell className={classes.textTable}>
                          {datos.cantidad_venta}
                        </TableCell>
                        <TableCell className={classes.textTable}>
                          $
                          {datos.descuento_activo === true
                            ? formatoMexico(datos.descuento.dinero_descontado)
                            : "0"}
                        </TableCell>
                        <TableCell className={classes.textTable}>
                          ${formatoMexico(datos.impuestos_total_producto)}
                        </TableCell>
                        <TableCell className={classes.textTable}>
                          ${formatoMexico(datos.subtotal_total_producto)}
                        </TableCell>
                        <TableCell className={classes.textTable}>
                          ${formatoMexico(datos.total_total_producto)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box px={10} my={1}>
            <Box
              display="flex"
              justifyContent="flex-end"
            >
              <Box minWidth={200}>
                <Box display="flex" justifyContent="space-between">
                  <Typography>
                    <b>SUBTOTAL:</b>
                  </Typography>
                  <Typography>
                    $
                    {dataVenta.subTotal
                      ? formatoMexico(dataVenta.subTotal)
                      : null}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>
                    <b>DESCUENTO:</b>
                  </Typography>
                  <Typography>${formatoMexico(dataVenta.descuento)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>
                    <b>IMPUESTOS:</b>
                  </Typography>
                  <Typography>
                    $
                    {dataVenta.impuestos
                      ? formatoMexico(dataVenta.impuestos)
                      : null}
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Typography>
                    <b>TOTAL:</b>
                  </Typography>
                  <Typography>
                    ${dataVenta.total ? formatoMexico(dataVenta.total) : null}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          {dataVenta.credito === true ? (
            <Box display="flex" width="100%" id="pagare" flexDirection="column">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontStyle="italic"
                style={{
                  border: "2px solid",
                  marginTop: "20px",
                  marginBottom: "20px",
                  marginLeft: "75px",
                  marginRight: "75px",
                  padding: "15px",
                  borderRadius: "30px",
                }}
                flexDirection="column"
              >
                <Typography variant="h6" style={{ marginBottom: "5px" }}>
                  PAGARÉ
                </Typography>
                <Typography
                  component="div"
                  style={{
                    fontSize: "13px",
                    textAlign: "justify",
                  }}
                >
                  Yo{" "}
                  {dataVenta.cliente === null
                    ? null
                    : dataVenta?.cliente?.nombre_cliente.toUpperCase()}{" "}
                  me comprometo a que pagaré incondicionalmente a la orden de{" "}
                  {sesion.empresa.nombre_dueno === null
                    ? null
                    : sesion.empresa.nombre_dueno.toUpperCase()}{" "}
                  previo a la fecha{" "}
                  {formatoFechaCorta(dataVenta.fecha_de_vencimiento_credito)} la
                  cantidad de $
                  {formatoMexico(dataVenta.saldo_credito_pendiente)} (
                  {numberToLetters(dataVenta.saldo_credito_pendiente)}) valor de
                  las mercancias y/o servicios a mi entera conformidad. Este
                  pagaré es mercantil y está regido por la ley general de
                  títulos y operaciones de crédito en sus artículos 172 y 173
                  parte final por no ser pagaré domiciliado y artículos
                  correlativos. Queda convenido que en caso de mora, el presente
                  título causará un interés del{" "}
                  <TextField
                    variant="standard"
                    size="small"
                    InputProps={{ style: { height: "20px", padding: "0" } }}
                    style={{ width: "30px" }}
                  ></TextField>
                  % mensual
                </Typography>
                <br></br>
                <Box justifyContent="center">
                  <Typography style={{ textAlign: "center" }}>
                    _________________________________________________________________
                  </Typography>
                </Box>
                <Typography style={{ textAlign: "center" }}>Firma</Typography>
              </Box>
            </Box>
          ) : null}
        </DialogContent>
      </Dialog>
    </>
  );
}
