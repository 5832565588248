import React from "react";
import Box from "@material-ui/core/Box";
import VentasRealizadas from "../../ventas/VentasRealizadas/VentasRealizadas";
import Cotizacion from "../../ventas/Cotizacion/Cotizacion";
import AbrirCajon from "../../ventas/Operaciones/AbrirCajon";
import FacturaEnVenta from "../../ventas/Operaciones/FacturaEnVenta";
import ExistenciasSucursals from "../../ventas/ExistenciasSucursals";
import ProductosVentaRapida from "../../venta_rapida/Productos";

export default function PanelRapidoMenu() {

  return (
    <Box p={2} py={1}>
      <ProductosVentaRapida />
      <Box display="flex" gridGap={10}>
        <VentasRealizadas />
        <AbrirCajon />
        <ExistenciasSucursals />
        <Cotizacion />
        <FacturaEnVenta />
      </Box>
    </Box>
  );
}
