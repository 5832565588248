import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Portal from "@material-ui/core/Portal";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Typography from "@material-ui/core/Typography";
import { WarningOutlined } from "@material-ui/icons";
import { AccesosContext } from "../../context/Accesos/accesosCtx";

function ProximoVencerAlert() {
  const { status, status_codes, diasRestantes } = React.useContext(
    AccesosContext
  );
  const [visibiliy, setVisivility] = React.useState("flex");

  if (status === status_codes[1]) {
    return (
      <Portal>
        <Box
          position="absolute"
          bottom={8}
          left={8}
          minWidth={530}
          zIndex={9999}
          display={visibiliy}
        >
          <SnackbarContent
            message={
              <Box display="flex" alignItems="center">
                <WarningOutlined style={{ marginRight: 16 }} />
                <Typography>
                  {diasRestantes === 0
                    ? ` Tu licencia vence hoy`
                    : ` Tu licencia vencerá en ${diasRestantes} días`}
                </Typography>
              </Box>
            }
            action={
              <Button color="secondary" onClick={() => setVisivility("none")}>
                Cerrar
              </Button>
            }
          />
        </Box>
      </Portal>
    );
  } else {
    return null;
  }
}

export default ProximoVencerAlert;
