import React from "react";
import { Box } from "@material-ui/core";
import UnidadCostoCompra from "./UnidadCostoCompra";
import Impuestos from "./Impuestos";
import PreciosVenta from "./PreciosVenta";
import UnidadesVenta from "./UnidadesVenta";

export default function PreciosIndex() {
  return (
    <Box>
      <UnidadCostoCompra />
      <Impuestos />
      <PreciosVenta />
      <UnidadesVenta />
    </Box>
  );
}
