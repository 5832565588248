import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import TableMedidas from "./TableMedidas";
import CrearColorProducto from "../../../../../../Catalogos/Producto/TallasColores/crearColor";
import CrearTallasProducto from "../../../../../../Catalogos/Producto/TallasColores/crearTalla";
import { TraspasosContext } from "../../../../Context/TraspasosContext";
import SelectColor from "./SelectColor";
import SelectTalla from "./SelectTalla";

export default function Medidas({ queryMedidas }) {
  const { item, setItem } = React.useContext(TraspasosContext);
  const { datos_generales, medidas_producto } = item.product_selected;
  const [medidasSeleccionadas, setMedidasSeleccionadas] = React.useState([]);
  const [coloresSeleccionados, setColoresSeleccionados] = React.useState([]);

  const { colores, tallas, calzados } = queryMedidas.data;

  const medidas =
    datos_generales.tipo_producto === "ROPA" ? [...tallas] : [...calzados];

  const obtenerColoresSeleccinados = React.useCallback(() => {
    let colors = [];
    let medidas = [];
    //separar colores y medidas
    [...medidas_producto].forEach((element) => {
      if (element.color._id) colors.push(element.color);
      if (element.medida._id) medidas.push(element.medida);
    });

    //verificar coincidencias
    var hashColor = {};
    var hashMedida = {};
    const colores_existentes = colors.filter((color) => {
      var existColor = !hashColor[color._id];
      hashColor[color._id] = true;
      return existColor;
    });
    const medidas_existentes = medidas.filter((medida) => {
      var existMedida = !hashMedida[medida._id];
      hashMedida[medida._id] = true;
      return existMedida;
    });

    //setear colores y medidas
    setColoresSeleccionados([...colores_existentes]);
    setMedidasSeleccionadas([...medidas_existentes]);
    setItem({ ...item, new_medidas: [...medidas_producto] });
  }, [medidas_producto]);

  React.useEffect(() => {
    obtenerColoresSeleccinados();
  }, [obtenerColoresSeleccinados]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Box width="100%">
            <Box display="flex" alignItems="center" gridGap={10} mb={1}>
              <Typography>
                <b>Talla/Número</b>
              </Typography>
              <CrearTallasProducto
                setMedidasSeleccionadas={setMedidasSeleccionadas}
                refetch={queryMedidas.refetch}
              />
            </Box>
            <Grid container>
              {medidas.map((talla, index) => (
                <SelectTalla
                  key={index}
                  talla={talla}
                  coloresSeleccionados={coloresSeleccionados}
                  medidasSeleccionadas={medidasSeleccionadas}
                  setMedidasSeleccionadas={setMedidasSeleccionadas}
                />
              ))}
            </Grid>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Box width="100%">
            <Box display="flex" alignItems="center" gridGap={10} mb={1}>
              <Typography>
                <b>Colores</b>
              </Typography>
              <CrearColorProducto refetch={queryMedidas.refetch} />
            </Box>
            <Grid container>
              {colores.map((color, index) => (
                <SelectColor
                  key={index}
                  color={color}
                  coloresSeleccionados={coloresSeleccionados}
                  setColoresSeleccionados={setColoresSeleccionados}
                  medidasSeleccionadas={medidasSeleccionadas}
                />
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box mt={3} />
      <TableMedidas />
    </Box>
  );
}
