import React, { createContext, useState, useEffect } from "react";
import { UP_TURNO } from "../../gql/Ventas/abrir_cerrar_turno";
import { useMutation } from "@apollo/client";
import { SUBIR_VENTAS_CLOUD } from "../../gql/Ventas/ventas_generales";
import { ACTUALIZAR_BD_LOCAL } from "../../gql/Catalogos/usuarios";
import SnackBarMessages from "../../components/SnackBarMessages";
import { MainContext } from "../MainCtx";
export const AccesosContext = createContext();

export const AccesosProvider = ({ children }) => {
  //status licence

  const status_codes = ["OK", "TO_EXPIRE", "EXPIRED"];
  const [status, setStatus] = useState(status_codes[0]);
  const [sucursalData, setSucursalData] = useState(null);
  const [diasRestantes, setDiasRestantes] = useState(0);

  const [isOnline, setIsOnline] = useState(false);
  const [ventasToCloud, setVentasToCloud] = useState([]);
  const [abrirPanelAcceso, setAbrirPanelAcceso] = useState(false);
  const [departamentos, setDepartamentos] = useState({
    departamento: "",
    subDepartamento: "",
    tipo_acceso: "",
  });

  const [reloadAdministrador, setReloadAdministrador] = useState(false);
  const [reloadProductoRapido, setReloadProductoRapido] = useState(false);
  const [reloadCancelarVenta, setReloadCancelarVenta] = useState(false);
  const [reloadVerPrecios, setReloadVerPrecios] = useState(false);
  const [reloadVerPreCorte, setReloadVerPreCorte] = useState(false);
  const [reloadEliminarVentaEspera, setReloadEliminarVentaEspera] = useState(
    false
  );
  const [reloadCrearCotizacion, setReloadCrearCotizacion] = useState(false);
  const [loadingPage, setLoadingPage] = React.useState(false);
  const [alert, setAlert] = useState({ message: "", status: "", open: false }); //sync
  const [subirTurnoCloud] = useMutation(UP_TURNO);
  const [subirVentasCloud] = useMutation(SUBIR_VENTAS_CLOUD);
  const [actualizarBDLocal] = useMutation(ACTUALIZAR_BD_LOCAL);
  //states de switches del nav
  const [ventaToCloud, setVentaToCloud] = useState(
    JSON.parse(localStorage.getItem("ventaToCloud"))
  );
  const { turnoEnCurso, setTurnoEnCurso, isWeb } = React.useContext(MainContext);

  const saveVentasToCloud = (objectVenta) => {
    try {
      /* let oldItems = JSON.parse(localStorage.getItem("ventasToCloud"))
        ? JSON.parse(localStorage.getItem("ventasToCloud"))
        : [];
      oldItems.push(objectVenta);
      localStorage.setItem("ventasToCloud", JSON.stringify(oldItems)); */

      if (isOnline && ventaToCloud) {
        getData({ _id: objectVenta.empresa }, { _id: objectVenta.sucursal });
        //doBackgroundVenta(objectVenta);
      }
      //setVentasToCloud(objectVenta);
    } catch (error) {
      console.log(error);
    }
  };

  const saveVentasToCloudFail = (arrayVentas) => {
    try {
      localStorage.removeItem("ventasToCloud");
      setVentasToCloud(arrayVentas);
      localStorage.setItem("ventasToCloud", JSON.stringify(arrayVentas));
    } catch (error) {
      console.log(error);
    }
  };
  const removeStorageVentas = (objetcVenta) => {
    try {
      localStorage.removeItem("ventasToCloud");
      setVentasToCloud([]);
    } catch (error) {
      console.log(error);
    }
  };

  const doBackgroundVenta = async (objectVenta) => {
    try {
      if(isWeb) return
      setLoadingPage(true);

      await subirVentasCloud({
        variables: { arrayVentasCloud: [objectVenta] },
      });
      /*     let {message, done, ventas_fail} = up_ventas.data.subirVentasCloud;
  
      if(done){
        removeStorageVentas();
      }else{
      
        saveVentasToCloudFail(ventasToCloud);
      } */

      setLoadingPage(false);
      //setAlert({ message: message, status: (done) ? 'success' : 'error'  ,open: true })
      //localStorage.removeItem('ventasToCloud')
    } catch (error) {
      //saveVentasToCloudFail(ventasToCloud);
      setLoadingPage(false);
      console.log(error);
      if (error.networkError.result) {
        console.log(error.networkError.result.errors);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors.message);
      }
    }
  };
  useEffect(() => {
    if (isOnline && ventasToCloud.length > 0 && ventaToCloud) {
      console.log("doBackgroundVenta");
      doBackgroundVenta();
    }
  }, [ventasToCloud]);

  useEffect(() => {
    let ventasCloud = JSON.parse(localStorage.getItem("ventasToCloud"));
    setVentasToCloud(ventasCloud ? ventasCloud : []);
  }, []);

  /* useEffect(() => {
    if (isOnline !== undefined && isOnline === true) {
     
      if (turno) {
        if (!turno.OnCloud) {
          upTurno(turno, isOnline);
        }
      }
    }
  }, [isOnline]); */

  const upTurno = async () => {
    try {
      if (!isOnline) return;

      if (turnoEnCurso !== null && !turnoEnCurso.onCloud) {
        const variableTurnoAbierto = await subirTurnoCloud({
          variables: {
            activa: true,
            input: turnoEnCurso,
            isOnline: isOnline,
          },
        });

        setTurnoEnCurso({
          ...turnoEnCurso,
          onCloud: variableTurnoAbierto.data.subirTurnoCloud.done,
        });
      }
    } catch (error) {
      if (error.networkError.result) {
        console.log(error.networkError.result.errors);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors.message);
      }
    }
  };

  const getData = async (empresa, sucursal, from = "") => {
    try {
      if(isWeb) return
      setLoadingPage(true);
      const resp = await actualizarBDLocal({
        variables: {
          empresa: empresa._id,
          sucursal: sucursal._id,
          from: from,
        },
      });
      let { message } = resp.data.actualizarBDLocal;

      //console.log(resp)
      setLoadingPage(false);
      setAlert({ message: message, status: "success", open: true });
    } catch (error) {
      setAlert({ message: error.message, status: "error", open: true });
      setLoadingPage(false);
      console.log(error);
    }
  };

  return (
    <AccesosContext.Provider
      value={{
        departamentos,
        setDepartamentos,
        reloadProductoRapido,
        setReloadProductoRapido,
        reloadAdministrador,
        setReloadAdministrador,
        abrirPanelAcceso,
        setAbrirPanelAcceso,
        reloadCancelarVenta,
        setReloadCancelarVenta,
        reloadVerPrecios,
        setReloadVerPrecios,
        reloadVerPreCorte,
        setReloadVerPreCorte,
        reloadEliminarVentaEspera,
        setReloadEliminarVentaEspera,
        reloadCrearCotizacion,
        setReloadCrearCotizacion,
        doBackgroundVenta,
        isOnline,
        setIsOnline,
        upTurno,
        ventasToCloud,
        saveVentasToCloud,
        removeStorageVentas,
        saveVentasToCloudFail,
        loadingPage,
        setLoadingPage,
        sucursalData,
        setSucursalData,
        status,
        setStatus,
        status_codes,
        diasRestantes,
        setDiasRestantes,
        ventaToCloud,
        setVentaToCloud,
        getData,
      }}
    >
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      {children}
    </AccesosContext.Provider>
  );
};
