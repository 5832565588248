import React from "react";
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

export default function NoMatchPage({message = "Esta pagina no existe."}) {
  return (
    <div>
      <Box
        id="error-page"
        sx={{
          height: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <Typography variant="h2" align="center">
            Oops, lo sentimos!
          </Typography>
          <Typography align="center">
            {message}
          </Typography>
        </div>
      </Box>
    </div>
  );
}
