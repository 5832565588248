import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { formatoMexico } from "../../../../../../config/reuserFunctions";
import moment from "moment-timezone";
import CustomLoader from "../../../../../../components/CustomLoader";
import ErrorPage from "../../../../../../components/ErrorPage";
import { FacturacionCtx } from "../../../../../../context/Facturacion/facturacionCtx";
import { red } from "@material-ui/core/colors";

const columns = [
  { label: "Fecha", minWidth: 90 },
  { label: "Folio Venta", minWidth: 90 },
  { label: "Serie", padding: "checkbox" },
  { label: "Folio", padding: "checkbox" },
  { label: "Tipo CDFI", minWidth: 110 },
  { label: "Receptor", minWidth: 100 },
  { label: "RFC", minWidth: 100 },
  { label: "Subtotal", minWidth: 90 },
  { label: "Total", minWidth: 100 },
];

const useStyles = makeStyles((theme) => ({
  container: {
    height: "65vh",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  canceled: {
    borderLeft: "6px solid",
    borderColor: red["A100"],
  },
}));

export default function CfdisTable({
  cfdisQuery,
  page,
  setPage,
  limit,
  handleClose,
}) {
  const classes = useStyles();
  const { data, loading, error } = cfdisQuery;
  const { datosFactura, setDatosFactura } = React.useContext(FacturacionCtx);

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  const handleSelectFactura = (e, factura) => {
    if (e.detail === 2) {
      const Uuid = factura.complement?.TaxStamp?.Uuid;
      setDatosFactura({
        ...datosFactura,
        Relations: {
          ...datosFactura.Relations,
          Cfdis: [{ Uuid }],
        },
      });
      handleClose();
    }
  };

  if (loading) return <CustomLoader height="30vh" />;
  if (error) return <ErrorPage error={error} />;

  return (
    <Paper variant="outlined">
      <TableContainer className={classes.container}>
        <Table size="small" stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  padding={column.padding}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.docs.map((data, index) => {
              return (
                <RenderListFacturas
                  key={index}
                  data={data}
                  handleSelectFactura={handleSelectFactura}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.totalDocs}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
}

const statusCanceled = ["canceled", "acepted", "expired"];

const RenderListFacturas = ({ data, handleSelectFactura }) => {
  const disabled = (data.complementos.length > 0 || data.active) && true;
  const canceled = statusCanceled.some((state) => data.status === state);
  const classes = useStyles();

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      onClick={(e) => handleSelectFactura(e, data)}
      className={disabled || canceled ? classes.disabled : null}
      style={{ cursor: "pointer" }}
    >
      <TableCell className={canceled ? classes.canceled : null}>
        {moment(data.fecha_registro)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY")}
      </TableCell>
      <TableCell>{data.folio_venta}</TableCell>
      <TableCell>{data.serie}</TableCell>
      <TableCell>{data.folio}</TableCell>
      <TableCell>{data.cfdi_type}</TableCell>
      <TableCell>{data.receiver.Name}</TableCell>
      <TableCell>{data.receiver.Rfc}</TableCell>
      <TableCell>${formatoMexico(data.sub_total)}</TableCell>
      <TableCell>${formatoMexico(data.total)}</TableCell>
    </TableRow>
  );
};
