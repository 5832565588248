import { Box, Button, IconButton } from "@material-ui/core";
import { ArrowBack, ClearOutlined } from "@material-ui/icons";
import React from "react";
import ExportarReportesCajas from "../ReportesHistorialCajas/ExportarReportesCajas";
import ComponentOnline from "../../../../../components/Connection/ComponentOnline";

export default function HeadIconsReportesCajas({
  handleClickOpen,
  cajaSelected,
  datosBuscar,
  setDatosBuscar,
}) {
  const limpiarFiltros = () => {
    setDatosBuscar([]);
  };
  return (
    <Box
      display="flex"
      // alignItems="center"
      // position="absolute"
      // mt={5}
      mr={2}
    >
      <ComponentOnline classes />
      <Box mx={1} />
      <Box mt={1}>
        <Button
          color="primary"
          startIcon={<ClearOutlined />}
          onClick={() => limpiarFiltros()}
        >
          Limpiar filtros
        </Button>
      </Box>
      <Box mx={1} />
      <ExportarReportesCajas
        cajaSelected={cajaSelected}
        datosBuscar={datosBuscar}
        // datos={obtenerHistorialCaja.docs}
        // refetch={refetch}
      />
      <Box mx={1} />
      <IconButton variant="contained" color="primary" onClick={handleClickOpen}>
        <ArrowBack />
      </IconButton>
    </Box>
  );
}
