import { useQuery } from "@apollo/client";
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { OBTENER_HISTORIAL_CAJA } from "../../../../../gql/Cajas/cajas";
import { useEffect } from "react";
import TablaReportesCajas from "./TablaReportesCajas";

const tipos = [
  "TODOS",
  "COMPRA",
  "VENTA",
  "DEPOSITO",
  "RETIRO",
  "CUENTA_RETIRO",
  "CUENTA_DEPOSITO",
  "ABONO_CLIENTE",
  "ABONO_PROVEEDOR",
  "TRANSFERENCIA",
  "CANCELACION_ABONO_CLIENTE",
  "CANCELACION_ABONO_PROVEEDOR",
  "ABRIR_TURNO",
  "CERRAR_TURNO",
  "CANCELACION_CUENTA_RETIRO",
  "CANCELACION_CUENTA_DEPOSITO",
];

export default function FiltrosReportesCajas({
  cajaSelected,
  obtenerHistorialCaja,
  page,
  setPage,
  datosBuscar,
  setDatosBuscar,
}) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [value] = useDebounce(datosBuscar, 500);

  /* Queries */
  const { data, refetch, loading } = useQuery(OBTENER_HISTORIAL_CAJA, {
    variables: {
      id_Caja: cajaSelected._id,
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: {
        tipo_movimiento: datosBuscar.tipo_movimiento
          ? datosBuscar.tipo_movimiento
          : "",
        fecha_incio: datosBuscar.fecha_incio ? datosBuscar.fecha_incio : "",
        fecha_fin: datosBuscar.fecha_fin ? datosBuscar.fecha_fin : "",
        usuario: value.usuario ? value.usuario : "",
      },
      limit: 20,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (data) {
    obtenerHistorialCaja = data.obtenerHistorialCaja;
    console.log(data);
  }

  const handleDatos = (e) => {
    setDatosBuscar({ ...datosBuscar, [e.target.name]: e.target.value });
  };

  return (
    <Box mx={3} mt={1}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <Typography>Movimiento</Typography>
          <FormControl variant="outlined" fullWidth size="small">
            <Select
              id="form-producto-tipo"
              name="tipo_movimiento"
              onChange={handleDatos}
              value={
                datosBuscar.tipo_movimiento ? datosBuscar.tipo_movimiento : ""
              }
            >
              <MenuItem value="">
                <em>Selecciona uno</em>
              </MenuItem>
              {tipos.map((tipo, index) => {
                return (
                  <MenuItem key={index} value={tipo}>
                    {tipo}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography>Fecha inicio:</Typography>
          <TextField
            fullWidth
            size="small"
            name="fecha_incio"
            variant="outlined"
            type="date"
            value={datosBuscar.fecha_incio ? datosBuscar.fecha_incio : ""}
            onChange={handleDatos}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography>Fecha fin:</Typography>
          <TextField
            fullWidth
            size="small"
            name="fecha_fin"
            variant="outlined"
            type="date"
            value={datosBuscar.fecha_fin ? datosBuscar.fecha_fin : ""}
            onChange={handleDatos}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Typography>Usuario:</Typography>
          <TextField
            fullWidth
            size="small"
            name="usuario"
            variant="outlined"
            value={datosBuscar.usuario ? datosBuscar.usuario : ""}
            onChange={handleDatos}
          />
        </Grid>
      </Grid>
      <Box my={1} />
      <TablaReportesCajas
        obtenerHistorialCaja={obtenerHistorialCaja}
        page={page}
        setPage={setPage}
        loading={loading}
      />
    </Box>
  );
}
