import { withSnackbar } from "notistack";
import React, { createContext } from "react";
import jwtDecode from "jwt-decode";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

export const MainContext = createContext();

const isWeb = process.env.REACT_APP_IS_WEB === "true" ? true : false;
const endpoint = isWeb
  ? process.env.REACT_APP_ENDPOINT_WEB
  : process.env.REACT_APP_ENDPOINT;
const respoint = isWeb
  ? process.env.REACT_APP_RESTPOINT_WEB
  : process.env.REACT_APP_RESTPOINT;
const socketioServer = process.env.REACT_APP_SOCKET_ENDPOINT_WEB;
const socketioLocal = process.env.REACT_APP_SOCKET_ENDPOINT

const MainCtxProvider = ({ children, enqueueSnackbar }) => {
  const tokenSesion = localStorage.getItem("tokenCafi");
  const conexionLS = JSON.parse(localStorage.getItem("conexionCafi"));
  const decoded = tokenSesion ? jwtDecode(tokenSesion) : null;
  const [tokenDecoded, setTokenDecoded] = React.useState(decoded);
  const [conexion, setConexion] = React.useState(conexionLS);
  const [turnoEnCurso, setTurnoEnCurso] = React.useState(null);

  const snackMessage = React.useCallback(
    ({
      message = "No Message",
      variant = "default",
      preventDuplicate = true,
      anchorOrigin = { horizontal: "center", vertical: "top" },
    }) => {
      enqueueSnackbar(message, {
        variant,
        preventDuplicate,
        anchorOrigin,
        style: { top: -5 },
        className: "csnackbar",
      });
    },
    [enqueueSnackbar]
  );

  //console.log({isWeb, endpoint, respoint, socketio})

  const httpLink = createUploadLink({
    uri: conexion ? `http://${conexion.ip}:4000/graphql` : endpoint,
  });

  const client = new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache({
      addTypename: false,
    }),
    link: httpLink,
  });

  return (
    <MainContext.Provider
      value={{
        client,
        snackMessage,
        tokenDecoded,
        tokenSesion,
        setTokenDecoded,
        conexion,
        setConexion,
        turnoEnCurso,
        setTurnoEnCurso,
        endpoint,
        respoint,
        socketioServer,
        socketioLocal,
        isWeb,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default withSnackbar(MainCtxProvider);
