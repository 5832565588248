import { useTheme } from "@material-ui/core";
import React, { createContext, useState } from "react";

export const TiendaCtx = createContext();

export const TiendaProvider = ({ children }) => {
  const theme = useTheme();
  const inital_color_state = {
    primary: {
      hex: theme.palette.original_primary.main,
      shade: "500",
      name: "blue",
      value_shade: 5,
    },
    secondary: {
      hex: theme.palette.original_secondary.main,
      shade: "500",
      name: "blue",
      value_shade: 5,
    },
  };
  const initial_alert_state = { message: "", status: "", open: false };
  const [tienda, setTienda] = useState({ data: null, refetch: null });
  const [data, setData] = React.useState(null);
  const [color, setColor] = useState(inital_color_state);
  const [alert, setAlert] = useState(initial_alert_state);

  return (
    <TiendaCtx.Provider
      value={{
        tienda,
        setTienda,
        color,
        setColor,
        inital_color_state,
        alert,
        setAlert,
        data,
        setData
      }}
    >
      {children}
    </TiendaCtx.Provider>
  );
};
