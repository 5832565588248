import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Info } from "@material-ui/icons";

export default function ErrorMessage({
  height = "50vh",
  message = "Hubo un problema desconocido",
  fontSize = 80,
}) {

  return (
    <div>
      <Box
        sx={{
          height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Info color="disabled" style={{ fontSize }} />
        </Box>
        <Box sx={{ my: 1 }} />
        <Typography color="gray" align="center">
          {message}
        </Typography>
      </Box>
    </div>
  );
}
