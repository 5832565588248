import React from "react";
import Box from "@material-ui/core/Box";
import CrearCustomBanner from "./CrearCustomBanner/CrearCustomBanner";
import ItemsContainer from "./ItemsContainer";

export default function CustomBanners() {
  return (
    <Box>
      <CrearCustomBanner />
      <ItemsContainer />
    </Box>
  );
}
