import React from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import TemaTienda from "./Theming/TemaTienda";
import { Button, CircularProgress } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { TiendaCtx } from "../../../../context/Empresa/tiendaCtx";
import { EDITAR_TIENDA } from "../../../../gql/Empresa/tienda";
import { useMutation } from "@apollo/client";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";

export default function PrincipalData() {
  const { tienda, color, setAlert, data, setData } = React.useContext(TiendaCtx);
  const { isOnline } = React.useContext(AccesosContext);
  const [loading, setLoading] = React.useState(false);
  const [editarTienda] = useMutation(EDITAR_TIENDA);

  const handleChange = (id_name) => {
    setData((state) => ({ ...state, id_name }));
  };

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    setData((state) => ({ ...state, [name]: checked }));
  };

  const verifyName = (value) => {
    if (!value) {
      setData((state) => ({ ...state, id_name: tienda.data.id_name }));
    }
  };
//console.log(data)
  const handleSaveDB = async () => {
    try {
      setLoading(true);
      //get new order
      const orderChanged = [];
      const customOrderChanged = [];
      data.orderChanged?.forEach((element, index) => {
        if(element){
          orderChanged.push({...element, order: index+1});
        }
      });
      data.customOrderChanged?.forEach((element, index) => {
        if(element){
          customOrderChanged.push({...element, order: index+1});
        }
      });
      //create input
      const input = {
        //id_name: data.id_name,
        categories: data.categories,
        theming: color,
        bannerSize: data.bannerSize ? data.bannerSize : "medium",
        deletedBanners: data.deletedBanners ? data.deletedBanners : [],
        orderChanged,
        customOrderChanged
      };
      const resp = await editarTienda({
        variables: {
          input,
          tienda: tienda.data._id,
        },
      });
      setLoading(false);
      tienda.refetch();
      setAlert({ message: resp.data.editarTienda.message, status: "success", open: true });
    } catch (error) {
      console.log(error)
      if(error.networkError)console.log(error.networkError.result);
      setLoading(false);
      setAlert({ message: error.message, status: "error", open: true });
    }
  };

  React.useEffect(() => {
    setData({...tienda.data});
  }, [tienda.data])
  
  return (
    <Box>
      <Grid container spacing={1} alignItems="center">
        <Grid item md={3}>
          <TextField
            fullWidth
            label="ID nombre tienda"
            variant="outlined"
            disabled
            size="small"
            placeholder={tienda.data ? tienda.data.id_name : "ID tienda"}
            value={data ? data.id_name : ""}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={(e) => verifyName(e.target.value)}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={data ? data.categories : ""}
                onChange={handleCheckChange}
                color="primary"
                name="categories"
              />
            }
            label="Categorias"
          />
        </Grid>
        {/* <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.last_products ? data.last_products : ""}
                onChange={handleCheckChange}
                color="primary"
                name="last_products"
              />
            }
            label="Mostrar Productos"
          />
        </Grid> */}
        <Grid item>
          <TemaTienda />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="small"
            startIcon={
              loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <Done />
              )
            }
            onClick={() => handleSaveDB()}
            disabled={!isOnline || loading}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
