import * as React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Drawer from "@material-ui/core/Drawer";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ArrowBack, Add } from "@material-ui/icons";
import { Done } from "@material-ui/icons";
import { MainContext } from "../../../../../../context/MainCtx";
import { TraspasosContext } from "../../Context/TraspasosContext";
import ConceptosTraspaso from "./Conceptos";
import { Grid } from "@material-ui/core";
import Origen from "./Origen";
import Destino from "./Destino";
import Productos from "./Productos";
import { CreateTrasEntreSuc, CrearTraspaso } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import SucursalDestino from "./SucursalDestino";
import { CloudContext } from "../../../../../../context/CloudCtx";

export default function CrearNuevoTraspaso() {
  const {
    traspasosQuery,
    setTraspaso,
    initialState,
    traspaso,
  } = React.useContext(TraspasosContext);
  const { snackMessage, tokenDecoded } = React.useContext(MainContext);
  const { syncDataBases } = React.useContext(CloudContext);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [crearTraspaso] = useMutation(CrearTraspaso);
  const [crearTrasEntreSuc] = useMutation(CreateTrasEntreSuc);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTraspaso(initialState);
  };

  const handleSave = async () => {
    try {
      let input = { ...traspaso };
      const concepto = input.concepto_traspaso.nombre_concepto;
      setLoading(true);

      if (concepto === "SALIDAS POR TRASPASOS") {
        const response = await crearTrasEntreSuc({
          variables: {
            input,
            usuario: tokenDecoded._id,
            empresa: tokenDecoded.empresa._id,
          },
        });
        snackMessage({
          message: response.data.crearTrasEntreSuc.message,
          variant: "success",
        });
      } else {
        const response = await crearTraspaso({
          variables: {
            input,
            usuario: tokenDecoded._id,
            empresa: tokenDecoded.empresa._id,
          },
        });
        snackMessage({
          message: response.data.crearTraspaso.message,
          variant: "success",
        });
      }
      syncDataBases();
      traspasosQuery.refetch();
      setLoading(false);
      handleClose();
    } catch (err) {
      console.log(err.networkError?.result);
      setLoading(false);
      snackMessage({
        message: err.message ? err.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const disabled = () => {
    const {
      concepto_traspaso,
      almacen_origen,
      almacen_destino,
      productos,
    } = traspaso;
    if (!concepto_traspaso) return true;
    if (concepto_traspaso.origen === "N/A" && !almacen_destino) return true;
    if (concepto_traspaso.destino === "N/A" && !almacen_origen) return true;
    if (concepto_traspaso.origen !== "N/A" && !almacen_origen) return true;
    if (concepto_traspaso.destino !== "N/A" && !almacen_destino) return true;
    if (!productos.length) return true;
    return false;
  };

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        startIcon={<Add />}
        onClick={handleOpen}
        size="small"
        disableElevation
      >
        Nuevo
      </Button>
      <Drawer
        anchor={"right"}
        open={open}
        onClose={handleClose}
        ModalProps={{
          BackdropProps: {
            style: {
              background: "rgba(0,0,0, 0.1)",
            },
          },
        }}
        PaperProps={{
          elevation: 1,
          style: {
            width: "60vw",
          },
        }}
      >
        <DialogTitle component="div">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display="flex" alignItems="center">
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Box mx={1} />
              <Typography variant="h6" color="gray">
                Crear traspaso
              </Typography>
            </Box>
            <Button
              size="small"
              variant="contained"
              disableElevation
              color="primary"
              sx={{ textTransform: "none" }}
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
              disabled={disabled()}
              onClick={handleSave}
              type="submit"
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <ConceptosTraspaso />
            </Grid>
            <Grid item md={4}>
              <Origen />
            </Grid>
            <SucursalDestino />
            <Grid item md={4}>
              <Destino />
            </Grid>
            <Grid item md={12}>
              <Productos />
            </Grid>
          </Grid>
        </DialogContent>
      </Drawer>
    </div>
  );
}
