import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Badge from "@material-ui/core/Badge";
import { Done, Settings } from "@material-ui/icons";
import { Button, CircularProgress, InputAdornment } from "@material-ui/core";
import { useApolloClient } from "@apollo/client";
import { TEST_CONEXION } from "../gql/Catalogos/usuarios";
import { MainContext } from "../context/MainCtx";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledBadge = withStyles((theme) => ({
  badge: {
    color: ({ props }) => props.color,
    background: ({ props }) => props.color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

/* 
    conexion tendria los siguendes datos
    ip: ip al padre
    status: boolean saber si esta conectado o no,
*/
export default function NodeConfig() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ip, setIp] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [checkedPrimary, setCheckedPrimary] = React.useState(false);
  const [checkedNodo, setCheckedNodo] = React.useState(false);
  const { snackMessage, conexion, setConexion, isWeb } = React.useContext(MainContext);
  const client = useApolloClient();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const styleProps = {
    color: conexion?.status || checkedPrimary ? "#44b700" : "red",
  };

  React.useEffect(() => {
    if (conexion) {
      testConexion();
      setCheckedPrimary(false);
      setCheckedNodo(true);
      if (conexion.ip) setIp(conexion.ip);
    } else {
      setCheckedPrimary(true);
      setCheckedNodo(false);
    }
  }, []);

  const testConexion = async () => {
    try {
      setLoading(true);
      await client.query({
        query: TEST_CONEXION,
        fetchPolicy: "network-only",
      });
      setLoading(false);
      const data = { ...conexion, status: true };
      localStorage.setItem("conexionCafi", JSON.stringify(data));
      setConexion(data);
      snackMessage({
        message: "Conexión establecida",
        variant: "success",
      });
    } catch (error) {
      const dataErr = { ...conexion, status: false };
      localStorage.setItem("conexionCafi", JSON.stringify(dataErr));
      setConexion(dataErr);
      setLoading(false);
      snackMessage({
        message: error.message ? error.message : "Error Desconocido",
        variant: "error",
      });
    }
  };

  const saveIP = () => {
    const data = { ip, status: false };
    localStorage.setItem("conexionCafi", JSON.stringify(data));
    setConexion(data);
  };

  if(isWeb) return null

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        gridGap={10}
        style={{ position: "absolute", right: 8 }}
      >
        <StyledBadge
          overlap="circular"
          props={styleProps}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          variant="dot"
        />
        <Typography>
          {conexion ? `Nodo (${conexion?.ip})` : "Principal (localhost)"}
        </Typography>
        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
        >
          {loading ? <CircularProgress size={18} /> : <Settings />}
        </IconButton>
      </Box>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box p={2} width={250}>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedPrimary}
                  onChange={(e) => {
                    if (!e.target.checked) return;
                    setCheckedPrimary(e.target.checked);
                    setCheckedNodo(false);
                    localStorage.removeItem("conexionCafi");
                    setConexion(null);
                    setLoading(false);
                  }}
                />
              }
              label="Principal"
            />
          </Box>
          <Divider />
          <Box gridGap={5}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedNodo}
                  onChange={(e) => {
                    setCheckedNodo(e.target.checked);
                    setCheckedPrimary(false);
                    localStorage.setItem(
                      "conexionCafi",
                      JSON.stringify({
                        ip: "",
                        status: false,
                      })
                    );
                    setConexion({
                      ip: "",
                      status: false,
                    });
                  }}
                />
              }
              label="Nodo"
            />
            <Box hidden={checkedPrimary}>
              <TextField
                fullWidth
                placeholder="192.168.1.1"
                variant="outlined"
                size="small"
                value={ip}
                style={{ display: "block" }}
                onChange={(e) => setIp(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton size="small" onClick={saveIP}>
                        <Done />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Box my={1} />
              <Button
                fullWidth
                size="small"
                onClick={testConexion}
                disabled={loading || !conexion?.ip}
              >
                probar conexion
              </Button>
            </Box>
          </Box>
        </Box>
      </StyledMenu>
    </div>
  );
}
