import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Facturacion from "../../sucursales/Facturacion/Facturacion";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { CustomButton } from "../../../components/CustomMuiComponents";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { MainContext } from "../../../context/MainCtx";

export default function FacturaEnVenta() {
  const [open, setOpen] = React.useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { turnoEnCurso } = React.useContext(MainContext);
  const { ventaRapida } = React.useContext(VentasContext);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 70) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open]);

  return (
    <>
      {ventaRapida ? (
        <CustomButton
          className="button-panel-venta b-secondary"
          fullWidth
          size="large"
          variant="outlined"
          onClick={handleClickOpen}
          startIcon={<FaFileInvoiceDollar className="icon-btn-secondary" />}
          disabled={
            !turnoEnCurso || !sesion.accesos.facturacion.generar_cdfi.ver
          }
        >
          Facturación
        </CustomButton>
      ) : (
        <Button
          className="button-panel-venta b-secondary"
          fullWidth
          variant="outlined"
          onClick={handleClickOpen}
          disabled={
            !turnoEnCurso || !sesion.accesos.facturacion.generar_cdfi.ver
          }
        >
          <Box>
            <FaFileInvoiceDollar className="icon-btn-secondary" />
            <Typography className="text-btn-panel">Facturación</Typography>
            <Typography className="text-btn-panel-sec">alt + F</Typography>
          </Box>
        </Button>
      )}

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickOpen}>
        <DialogTitle component="div">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6">Facturación</Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClickOpen}
              disableElevation
            >
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Facturacion sizeCol={4} />
        </DialogContent>
      </Dialog>
    </>
  );
}
