import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Close,
  DescriptionOutlined,
  Done,
  PostAdd,
  PrintOutlined,
} from "@material-ui/icons";
import CancelarFolio from "../CancelarFolio";
import { ClienteCtx } from "../../../../context/Catalogos/crearClienteCtx";
import { VentasContext } from "../../../../context/Ventas/ventasContext";
import { rePrintTicketVenta } from "../rePrintTicketVenta";
import { MainContext } from "../../../../context/MainCtx";
import ReactToPrint from "react-to-print";
import ContentDocNotaVenta from "./ContentDoc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetalleVenta({
  venta,
  handleClose,
  precorte,
  refetch,
  handleCloseListaVentas,
}) {
  const { snackMessage } = React.useContext(MainContext);
  const { updateClientVenta, setUpdateClientVenta } = React.useContext(
    ClienteCtx
  );
  const {
    updateTablaVentas,
    setUpdateTablaVentas,
    setAlert,
  } = React.useContext(VentasContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const empresa = sesion.empresa.nombre_empresa;

  const updateDataStorage = () => {
    setUpdateTablaVentas(!updateTablaVentas);
    setUpdateClientVenta(!updateClientVenta);
  };

  const restaurarVenta = () => {
    if (venta.nota_credito.length) return;
    let datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));
    if (datosVenta) {
      snackMessage({
        message: "Ya tienes una venta en curso",
        variant: "info",
      });
      return;
    }
    //armar array de productos
    let productos = [];

    venta.productos.forEach((res) => {
      productos.push({
        cantidad: res.cantidad,
        cantidad_venta: res.cantidad_venta,
        cantidad_venta_original: res.cantidad_venta,
        codigo_barras: res.id_producto.datos_generales.codigo_barras,
        codigo_unidad: res.codigo_unidad,
        color: res.color,
        concepto: res.concepto,
        default: res.default,
        descuento: res.id_unidad_venta.descuento,
        descuento_activo: res.id_unidad_venta.descuento_activo,
        granel_producto: res.granel_producto,
        id_producto: res.id_producto,
        id_unidad_venta: res.id_unidad_venta,
        ieps_total_producto: res.ieps_total,
        impuestos_total_producto: res.impuestos,
        inventario_general: res.inventario_general,
        iva_total_producto: res.iva_total,
        medida: res.medida,
        precio: res.precio,
        precio_a_vender: res.precio_a_vender,
        precio_actual_object: res.precio_actual_object,
        precio_actual_producto: res.precio_actual_producto,
        precio_anterior: res.precio_actual_producto,
        precio_unidad: res.precio_unidad,
        subtotal_total_producto: res.subtotal,
        total_total_producto: res.total,
        unidad: res.unidad,
        unidad_principal: res.id_unidad_venta.unidad_principal,
        _id: res.id_unidad_venta._id,
      });
    });

    //armar objeto para Storage

    let newDatosVenta = {
      _id: venta._id,
      cliente: venta.cliente,
      descuento: venta.descuento,
      ieps: venta.ieps,
      impuestos: venta.impuestos,
      iva: venta.iva,
      monedero: venta.monedero,
      productos,
      subTotal: venta.subTotal,
      total: venta.total,
      venta_cliente: venta.venta_cliente,
      usuario: venta.usuario,
      tipo_emision: venta.tipo_emision,
      folio: venta.folio,
      montos_en_caja: venta.montos_en_caja,
      credito: venta.credito,
      abono_minimo: venta.abono_minimo,
      descuento_general_activo: venta.descuento_general_activo,
      dias_de_credito_venta: venta.dias_de_credito_venta,
      fecha_de_vencimiento_credito: venta.fecha_de_vencimiento_credito,
      cambio: venta.cambio,
      metodo_pago: venta.metodo_pago,
      id_caja: venta.id_caja,
      forma_pago: venta.forma_pago,
      factura: venta.factura,
      empresa: venta.empresa,
      sucursal: venta.sucursal,
      fecha_registro: venta.fecha_registro,
      status: venta.status,
      nota_credito: true,
      total_original: venta.total,
      saldo_favor: 0,
    };

    //se agregan la venta a localStorage
    localStorage.setItem("DatosVentas", JSON.stringify(newDatosVenta));
    updateDataStorage();
    handleClose();
    handleCloseListaVentas();
  };

  const rePrintTicket = async () => {
    let data = {
      sesion,
      venta,
    };
    const result = await rePrintTicketVenta(data);
    if (result.error) {
      setAlert({
        message: "Error de conexión con la impresora",
        status: "error",
        open: true,
      });
    }
  };

  let ref = React.useRef();
  if (!venta) return null;

  return (
    <Dialog
      open={venta && true}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle component="div">
        <Box display="flex" alignItems="center" gridGap={10}>
          {venta.nota_credito.length ? (
            <Button color="primary" size="small" disabled startIcon={<Done />}>
              Nota de crédito
            </Button>
          ) : venta.status === "CANCELADO" ? null : (
            <Button
              color="primary"
              size="small"
              startIcon={<PostAdd />}
              onClick={() => restaurarVenta()}
            >
              Nota de crédito
            </Button>
          )}
          <ReactToPrint
            trigger={() => (
              <Button
                startIcon={<DescriptionOutlined />}
                color="primary"
                size="small"
              >
                Nota de venta
              </Button>
            )}
            content={() => ref.current}
            documentTitle={`${empresa} Nota de venta - ${venta.folio}`}
          />
          <Button
            startIcon={<PrintOutlined />}
            size="small"
            onClick={rePrintTicket}
          >
            ticket
          </Button>
          <CancelarFolio
            venta={venta}
            handleCloseInfoVenta={handleClose}
            dinero_disponible={precorte}
            refetch={refetch}
            //selected={venta}
          />
          <Box flexGrow={1} />
          <Button
            onClick={handleClose}
            color="default"
            variant="contained"
            disableElevation
            size="small"
            startIcon={<Close />}
          >
            Cerrar
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ContentDocNotaVenta venta={venta} refcomp={ref} />
      </DialogContent>
    </Dialog>
  );
}
