import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Chip, makeStyles, useTheme } from "@material-ui/core";
import { formatoMexico } from "../../../../config/reuserFunctions";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    width: "100%",
    textAlign: "center",
  },
  chip: {
    marginTop: 2,
    backgroundColor: "rgba(255,255,255, 0.7)",
    border: "1px solid rgba(0,0,0, 0.05)",
    backdropFilter: "blur(2px)",
  },
  image: {
    height: "150px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function InfoProducto({ producto, getImagen }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const { datos_generales } = producto.id_producto;

  const handleToggle = () => {
    setOpen(!open);
  };

  const getCantidad = () => {
    let cantidad = "";
    //console.log(producto)
    if (datos_generales.tipo_producto === "OTROS") {
      cantidad =
        producto.unidad === "Costal" || producto.unidad === "Caja"
          ? producto.inventario_general[0].cantidad_existente_maxima
          : producto.inventario_general[0].cantidad_existente;
    } else {
      cantidad = producto.cantidad;
    }
    return cantidad;
  };

  let ComponenteMedidaColor = () => {
    if (producto.color && producto.medida) {
      return (
        <Box display="flex">
          <Typography>
            Talla: <strong>{producto.medida.talla}</strong>
          </Typography>
          <Box mx={1} />
          <Typography>{`Color: `} </Typography>
          <div
            style={{
              height: 20,
              width: "auto",
              marginLeft: 4,
              padding: "2px 4px",
              borderRadius: 5,
              backgroundColor: producto.color.hex,
              color: theme.palette.getContrastText(producto.color.hex),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="button">{producto.color.nombre}</Typography>
          </div>
        </Box>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={classes.container}>
      <Chip
        clickable
        onClick={handleToggle}
        label={
          <Typography
            noWrap
            style={{ marginLeft: 4, textTransform: "lowercase", fontSize: 13 }}
          >
            {producto.id_producto.datos_generales.nombre_comercial}
          </Typography>
        }
        className={classes.chip}
        size="small"
      />

      <Dialog
        open={open}
        onClose={handleToggle}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container>
            <Grid
              item
              md={4}
              style={{ display: !getImagen() ? "none" : "flex" }}
            >
              <Box className={classes.image}>
                <img
                  src={getImagen()}
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                />
              </Box>
            </Grid>
            <Grid item md={8}>
              <Typography variant="h6">
                {datos_generales.nombre_comercial}
              </Typography>
              <ComponenteMedidaColor />
              <Typography>
                {`Código de barras: ${datos_generales.codigo_barras} `}
              </Typography>
              <Typography>
                {`Clave: ${datos_generales.clave_alterna} `}
              </Typography>
              <Typography>
                {`Existencia: ${getCantidad()} ${producto.unidad}`}
              </Typography>
              <Typography variant="h4">
                ${formatoMexico(producto.precio)}
              </Typography>
            </Grid>
          </Grid>
          <PreciosDeVentaCompras
            precios={producto.id_producto.precios.precios_producto}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function PreciosDeVentaCompras({ precios }) {
  const head = [
    { name: "numero_precio", label: "No. Precio" },
    { name: "precio_neto", label: "Precio" },
    { name: "unidad_mayoreo", label: "Cant. Mayoreo" },
  ];

  return (
    <Box mt={2}>
      <Typography variant="subtitle2">Precios a mayoreo</Typography>
      <Box mb={1} />
      <TableContainer>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              {head.map(({ label }, index) => (
                <TableCell padding="checkbox" key={index}>
                  {label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {precios?.map((data, index) =>
              data.precio_neto ? (
                <TableRow key={index}>
                  {head.map(({ name }, index) => (
                    <TableCell key={index} style={{ border: 0 }}>
                      {name === "precio_neto"
                        ? `$${formatoMexico(data[name])}`
                        : data[name]}
                    </TableCell>
                  ))}
                </TableRow>
              ) : null
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
