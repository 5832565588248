import React, { Fragment, useCallback, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { CheckCircle, Done, VerifiedUser } from "@material-ui/icons";
import { Box, CircularProgress } from "@material-ui/core";
import { useApolloClient } from "@apollo/client";
import { OBTENER_PRODUCTOS } from "../../../../gql/Catalogos/productos";
import ErrorPage from "../../../../components/ErrorPage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VerificarProductosCompras({
  compra,
  setNuevoArray,
  setVerificado,
}) {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [query, setQuery] = useState({
    data: undefined,
    error: undefined,
    loading: false,
  });
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const client = useApolloClient();

  const getProductos = useCallback(async () => {
    try {
      const response = await client.query({
        query: OBTENER_PRODUCTOS,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          almacen: compra.almacen.id_almacen,
          filtros: { producto: "" },
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setQuery(response);
    } catch (error) {
      console.log(error);
    }
  }, [client, setQuery]);

  const handleClickOpen = () => {
    setOpen(true);
    getProductos();
  };

  const handleClose = () => {
    if (success) {
      setVerificado(true);
    }
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={() => handleClickOpen()}
        startIcon={<VerifiedUser />}
      >
        Verificar y continuar compra
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-labelledby="dialog-verificarcompra-title"
        aria-describedby="dialog-verificarcompra-description"
        fullWidth
        maxWidth="sm"
      >
        <ModalVerificacion
          handleClose={handleClose}
          compra={compra}
          setNuevoArray={setNuevoArray}
          setSuccess={setSuccess}
          query={query}
        />
      </Dialog>
    </div>
  );
}

const ModalVerificacion = ({
  handleClose,
  compra,
  setNuevoArray,
  setSuccess,
  query,
}) => {
  const [loadingVerificacion, setLoadingVerificacion] = useState(false);
  const { data, loading, error } = query;

  useEffect(() => {
    if (data) {
      verificarProductos();
    }
  }, [data]);

  if (loading) {
    return (
      <Fragment>
        <DialogContent
          style={{
            height: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <DialogContentText id="dialog-verificarcompra-description">
            Si ya no hay existencias en tu producto se marcaran en la tabla y se
            quitaran al retomar la compra para no causar conflictos, podras
            editar tu compra mas adelante
          </DialogContentText>
          <Box display="flex" alignItems="center">
            <CircularProgress
              size={18}
              color="primary"
              style={{ marginRight: 8 }}
            />
            Obteniendo productos de la Base de datos...
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
        </DialogActions>
      </Fragment>
    );
  }

  if (error) {
    return (
      <Fragment>
        <DialogContent>
          <ErrorPage error={error} altura={130} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cerrar
          </Button>
        </DialogActions>
      </Fragment>
    );
  }

  let DB_PRODUCTS = [];
  if (data) DB_PRODUCTS = data.obtenerProductos.docs;

  function verificarProductos() {
    setLoadingVerificacion(true);
    let PRODUCTS = [...compra.productos];
    let NEW_PRODUCTS = [...compra.productos];
    let conflicto = false;

    for (let i = 0; i < PRODUCTS.length; i++) {
      let ITEM_COMPRA = { ...PRODUCTS[i] };
      let ITEM_PRODUCTO = { ...PRODUCTS[i].producto };

      //Filtrar los productos
      const filtering = DB_PRODUCTS.filter(
        (res) => res._id === PRODUCTS[i].id_producto
      );
      if (!filtering.length) return;
      const productoDB = filtering[0];

      const { precios, unidades_de_venta, presentaciones } = ITEM_PRODUCTO;

      const precios_base = productoDB.precios;
      const unidades_base = productoDB.unidades_de_venta;
      const presentaciones_base = productoDB.medidas_producto;

      //verificacion de Precios y cantidad de COMPRA
      if (
        precios.unidad_de_compra.cantidad !==
          precios_base.unidad_de_compra.cantidad ||
        precios.unidad_de_compra.precio_unitario_con_impuesto !==
          precios_base.unidad_de_compra.precio_unitario_con_impuesto ||
        precios.unidad_de_compra.unidad !== precios_base.unidad_de_compra.unidad
      ) {
        //si son diferentes se agrega los precios_base al compra_nueva
        ITEM_PRODUCTO.precios = precios_base;
        ITEM_COMPRA.producto = ITEM_PRODUCTO;
        ITEM_COMPRA.conflicto = true;
        NEW_PRODUCTS[i] = ITEM_COMPRA;
        conflicto = true;
      }
      //verificacion de Unidades de venta
      const u_default = unidades_de_venta.filter((res) => res.default === true);
      const u_default_base = unidades_base.filter(
        (res) => res.default === true
      );
      if (
        u_default[0].cantidad !== u_default_base[0].cantidad ||
        u_default[0].precio !== u_default_base[0].precio ||
        u_default[0].unidad !== u_default_base[0].unidad
      ) {
        //si son diferentes se agrega los precios_base al compra_nueva
        ITEM_PRODUCTO.unidades_de_venta = unidades_base;
        ITEM_COMPRA.producto = ITEM_PRODUCTO;
        NEW_PRODUCTS[i] = ITEM_COMPRA;
      }

      //verificacion de Presentaciones si tienen modificaciones despues de crear la  compra en espera
      if (presentaciones.length > 0 && presentaciones_base.length > 0) {
        //si son diferentes, poner las presentaciones que coicidan con las presentaciones de productos
        let new_presentaciones = [
          ...presentaciones.filter((p) =>
            presentaciones_base.find(
              (pb) => p._id === pb._id || p.cantidad_nueva
            )
          ),
        ];
        //verificar precios
        let new_arr = [...new_presentaciones];
        new_presentaciones.forEach((NP, index) => {
          presentaciones_base.forEach((PB) => {
            if (NP._id && NP._id === PB._id && NP.precio !== PB.precio) {
              new_arr.splice(index, 1, {
                ...PB,
                cantidad_nueva: NP.cantidad_nueva,
              });
            }
          });
        });
        ITEM_PRODUCTO.presentaciones = new_arr;
        ITEM_COMPRA.producto = ITEM_PRODUCTO;
        NEW_PRODUCTS[i] = ITEM_COMPRA;
      }
    }

    //modificar las cantidades y precios generales de la compra en base de los productos actualizados
    const nuevo_producto_compra = [];
    let subtotal = 0;
    let impuestos = 0;
    let total = 0;
    if (conflicto) {
      for (let k = 0; k < NEW_PRODUCTS.length; k++) {
        const datosProducto = { ...NEW_PRODUCTS[k] };

        if (datosProducto.producto.datos_generales.tipo_producto !== "OTROS") {
          //si es calzado o ropa
          //CANTIDADES
          if (datosProducto.producto.presentaciones.length > 0) {
            datosProducto.cantidad = 0;
            datosProducto.producto.presentaciones.forEach((presentacion) => {
              const { cantidad_nueva } = presentacion;
              let nueva = cantidad_nueva ? cantidad_nueva : 0;
              datosProducto.cantidad += nueva;
            });
            if (isNaN(datosProducto.cantidad)) datosProducto.cantidad = 0;
            datosProducto.cantidad_total = datosProducto.cantidad;
          } else {
            datosProducto.cantidad = 0;
            datosProducto.cantidad_total = datosProducto.cantidad;
          }
        } else {
          //Si es una unidad normal
          //CANTIDADES
          const { cantidad_regalo, cantidad, unidad_regalo } = datosProducto;
          //convertir todo a la unidad media y sumar (Pz, Kg, Lt)
          const factor =
            datosProducto.producto.precios.unidad_de_compra.cantidad;
          const cantiad_media = cantidad * factor;
          let cantidad_regalo_media =
            unidad_regalo === "Pz" ||
            unidad_regalo === "Kg" ||
            unidad_regalo === "Lt"
              ? cantidad_regalo
              : cantidad_regalo * factor;
          let cantidad_total_media = cantiad_media + cantidad_regalo_media;

          //si factor es > 1 es caja o costal y dividir unidad media entre factor y si no mandar la cantidad total media;
          if (factor > 1) {
            datosProducto.cantidad_regalo = cantidad_regalo_media / factor;
            datosProducto.cantidad_total = cantidad_total_media / factor;
          } else {
            datosProducto.cantidad_regalo = cantidad_regalo_media;
            datosProducto.cantidad_total = cantidad_total_media;
          }
        }

        //PRECIOS
        const {
          iva,
          ieps,
          precio_con_impuesto,
          precio_sin_impuesto,
        } = datosProducto.producto.precios.precio_de_compra;

        datosProducto.total_descuento = precio_con_impuesto;
        datosProducto.costo = precio_con_impuesto;
        datosProducto.iva_total = iva * datosProducto.cantidad_total;
        datosProducto.ieps_total = ieps * datosProducto.cantidad_total;
        datosProducto.impuestos = (iva + ieps) * datosProducto.cantidad_total;
        datosProducto.subtotal =
          precio_sin_impuesto * datosProducto.cantidad_total;
        datosProducto.total =
          precio_con_impuesto * datosProducto.cantidad_total;

        if (datosProducto.descuento_porcentaje > 0) {
          datosProducto.descuento_precio = Math.round(
            (precio_con_impuesto * datosProducto.descuento_porcentaje) / 100
          );
          datosProducto.total_descuento =
            precio_con_impuesto - datosProducto.descuento_precio;
        }

        subtotal += datosProducto.subtotal;
        impuestos += datosProducto.impuestos;
        total += datosProducto.total_descuento * datosProducto.cantidad_total;

        nuevo_producto_compra.push(datosProducto);
      }
      setNuevoArray({
        _id: compra._id,
        almacen: compra.almacen,
        proveedor: compra.proveedor,
        productos: nuevo_producto_compra,
        en_espera: compra.en_espera,
        fecha_registro: compra.fecha_registro,
        impuestos,
        subtotal,
        total,
      });
    } else {
      setNuevoArray(compra);
    }

    setLoadingVerificacion(false);
      setSuccess(true);
  }

  return (
    <Fragment>
      <DialogContent
        style={{
          height: 150,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <DialogContentText id="dialog-verificarcompra-description">
          Si tu producto cambió de precio de compra, o cantidad, se marcará en
          la tabla como cambio en la compra y se actualizarán los totales de la
          compra.
        </DialogContentText>
        {loadingVerificacion ? (
          <Box display="flex" alignItems="center">
            <CircularProgress
              size={18}
              color="primary"
              style={{ marginRight: 8 }}
            />
            Verificando existencias en tus productos...
          </Box>
        ) : null}
        {!loadingVerificacion ? (
          <Box display="flex" alignItems="center">
            <CheckCircle color="primary" style={{ marginRight: 8 }} />
            Listo, producto verificados
          </Box>
        ) : null}
      </DialogContent>
      <DialogActions>
        {loadingVerificacion ? (
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
        ) : (
          <Button onClick={handleClose} color="primary" startIcon={<Done />}>
            Aceptar
          </Button>
        )}
      </DialogActions>
    </Fragment>
  );
};
