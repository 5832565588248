import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Typography, Paper } from "@material-ui/core";
import { useContext } from "react";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx";
import { CustoTextField } from "../../NuevaFactura/CustomTextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-root": {
      border: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
  },
  title: {
    position: "absolute",
    top: -30,
    left: -18,
    backgroundColor: "white",
    padding: "0 8px",
  },
}));

export default function DetallesFactura() {
  const classes = useStyles();
  const { venta_factura, complementCartaPorte } = useContext(FacturacionCtx);
  const {
    Mercancia,
  } = complementCartaPorte.Complemento.CartaPorte30.Mercancias;

  if (!venta_factura) {
    return null;
  }

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box position="relative">
        <Typography className={classes.title}>
          <b>Bienes transportados</b>
        </Typography>
      </Box>
      <TableContainer>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell padding="checkbox">Código(SAT)</TableCell>
              <TableCell style={{ width: 200 }}>Producto</TableCell>
              <TableCell padding="checkbox">Cantidad</TableCell>
              <TableCell padding="checkbox">Unidad</TableCell>
              <TableCell style={{ width: 100 }}>Peso (Kg)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Mercancia.map((producto, index) => {
              return (
                <RowTableProductos
                  index={index}
                  producto={producto}
                  key={index}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const RowTableProductos = ({ producto, index }) => {
  const { complementCartaPorte, setComplementCartaPorte } = React.useContext(
    FacturacionCtx
  );
  const {
    Mercancia,
  } = complementCartaPorte.Complemento.CartaPorte30.Mercancias;

  const handleChangePeso = (item, value, index) => {
    const items = [...Mercancia];
    const newItem = { ...item, PesoEnKg: value.toString() };
    items.splice(index, 1, newItem);
    setComplementCartaPorte((st) => ({
      ...st,
      Complemento: {
        CartaPorte30: {
          ...st.Complemento.CartaPorte30,
          Mercancias: {
            ...st.Complemento.CartaPorte30.Mercancias,
            Mercancia: items,
          },
        },
      },
    }));
  };

  return (
    <>
      <TableRow tabIndex={-1}>
        <TableCell>{producto.BienesTransp}</TableCell>
        <TableCell>{producto.Descripcion}</TableCell>
        <TableCell>{producto.Cantidad}</TableCell>
        <TableCell>{producto.ClaveUnidad}</TableCell>
        <TableCell>
          <CustoTextField
            variant="outlined"
            size="small"
            value={producto.PesoEnKg}
            onChange={(e) => handleChangePeso(producto, e.target.value, index)}
            error={!producto.PesoEnKg}
            type="number"
          />
        </TableCell>
      </TableRow>
    </>
  );
};
