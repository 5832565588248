import { gql } from "@apollo/client";
export const OBTENER_TIENDA = gql`
  query ObtenerTiendaModel($empresa: ID!) {
    obtenerTiendaModel(empresa: $empresa) {
      _id
      id_name
      empresa
      categories
      last_products
      bannerSize
      banners {
        _id
        url_imagen
        location_imagen
        key_imagen
        extencion_imagen
        order
      }
      customBanners {
        _id
        typeBanner
        banner {
          url_imagen
          location_imagen
          key_imagen
          extencion_imagen
          order
        }
        sides {
          url_imagen
          location_imagen
          key_imagen
          extencion_imagen
          order
        }
        title
        order
        getFrom
        filter
      }
      fecha_registro
      theming {
        primary {
          hex
          name
          value_shade
          shade
        }
        secondary {
          hex
          name
          value_shade
          shade
        }
      }
    }
  }
`;

export const GET_ATTRIBUTES_PRODUCT = gql`
  query GetProductAttributs($empresa: ID!) {
    getProductAttributs(empresa: $empresa) {
      categorias {
        categoria
      }
      subcategorias {
        subcategoria
      }
      departamentos {
        nombre_departamentos
      }
      marcas {
        nombre_marca
      }
    }
  }
`;

export const EDITAR_TIENDA = gql`
  mutation EditarTienda($tienda: ID!, $input: EditarTiendaInput) {
    editarTienda(tienda: $tienda, input: $input) {
      message
    }
  }
`;

export const CREAR_BANNER = gql`
  mutation CrearBanner($input: CrearBanner, $tienda: ID) {
    crearBanner(input: $input, tienda: $tienda) {
      message
    }
  }
`;

export const ELIMINAR_BANNER = gql`
  mutation EliminarBanner($tienda: ID!, $id_banner: ID!, $location: String) {
    eliminarBanner(
      tienda: $tienda
      id_banner: $id_banner
      location: $location
    ) {
      message
    }
  }
`;

export const CREAR_CUSTOM_BANNER = gql`
  mutation CrearCustomBanner($input: CrearCustomBanner, $tienda: ID) {
    crearCustomBanner(input: $input, tienda: $tienda) {
      message
    }
  }
`;

export const ELIMINAR_CUSTOM_BANNER = gql`
  mutation EliminarCustomBanner(
    $tienda: ID!
    $id_banner: ID!
    $location: String
  ) {
    eliminarCustomBanner(
      tienda: $tienda
      id_banner: $id_banner
      location: $location
    ) {
      message
    }
  }
`;
