import React, { Fragment, useState, useEffect, useContext } from "react";
import { makeStyles, Button, Grid } from "@material-ui/core";
import { ClearOutlined } from "@material-ui/icons";
import { useMutation } from "@apollo/client";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Slide from "@material-ui/core/Slide";
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  TablePagination,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Close, Edit } from "@material-ui/icons";

import moment from "moment-timezone";
import ErrorPage from "../../../../components/ErrorPage";
import BackdropComponent from "../../../../components/Layouts/BackDrop";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import { useQuery } from "@apollo/client";
import {
  OBTENER_HISTORIAL_CUENTAS,
  CANCELAR_MOVIMIENTO_CUENTA,
} from "../../../../gql/Empresa/sucursales";
//import { useDebounce } from "use-debounce/lib";
import { formatoMexico } from "../../../../config/reuserFunctions";
import RetiroDeposito from "./RetiroDeposito";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";

const columns = [
  { id: "fecha", label: "Fecha", minWidth: 160 },
  { id: "tipo", label: "Tipo Movimiento", minWidth: 150 },
  { id: "concepto_cuenta", label: "Concepto cuenta", minWidth: 200 },
  { id: "concepto_subCuenta", label: "Concepto sub-cuenta", minWidth: 200 },
  { id: "cantidad", label: "Cantidad", minWidth: 150 },
  { id: "name", label: "Usuario", minWidth: 180 },
  { id: "movimiento_origen", label: "Origen", minWidth: 100 },
  { id: "movimiento_destino", label: "Destino", minWidth: 100 },
  { id: "usuario_recibe", label: "Recibió", minWidth: 180 },
  { id: "usuario_entrega", label: "Entregó", minWidth: 180 },
  { id: "fecha_movimiento", label: "Fecha movimiento", minWidth: 160 },
  { id: "comentarios", label: "Comentarios", minWidth: 320 },
  { id: "editar", label: "Editar", minWidth: 40 },
  { id: "cancelar", label: "Cancelar", minWidth: 40 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "50vh",
  },
  container: {
    height: "58vh",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  formComboBox: {
    height: "50%",
  },
  tableCellNormal: {
    minWidth: "200px",
  },
  colFixedEdit: {
    posision: "sticky",
    right: 65,
    backgroundColor: "white",
    maxWidth: "88px",
  },
  colFixedDel: {
    posision: "sticky",
    right: 0,
    backgroundColor: "white",
  },
  canceladas: {
    backgroundColor: "#FFF4F4",
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
  realizadas: {
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#F5F5F5",
    },
  },
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HistorialCuentas({
  tipo,
  reload,
  setReload,
  cajas,
  refetchCuentas,
  cuentas,

  datosFiltro,
  cuentaConcepto,
  page,
  setPage,
  setDatosFiltro,
  setTipoMovimiento,
  setOrigen,
  setDestino,
  setCuentaConcepto,
  tipoMovimiento,
  origen,
  destino,
  setOpenDoMove,
  setRowSelectedToEdit,
}) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const classes = useStyles();
  /* const [open, setOpen] = useState(false); */

  const limit = 10;

  const [saldoCaja, setSaldoCaja] = useState(0);
  //const [concepto, setConcepto] = useState('');

  const [historialCuentas, setHistorialCuentas] = useState({ totalDocs: 0 });
  const tableEl = React.useRef();
  const [shadow, setShadow] = useState("leftShadowTable");

  //const [value] = useDebounce(datosFiltro, 500);

  /* const handleClickOpen = () => {
        setOpen(!open);
        setDatosFiltro([]);
        refetch();
    }; */
  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft - 0.5 === realWidth) {
      setShadow("removeShadowTable");
      return;
    }
    setShadow("leftShadowTable");
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };
  const { data, loading, refetch, error } = useQuery(
    OBTENER_HISTORIAL_CUENTAS,
    {
      variables: {
        input: {
          fecha_inicio: datosFiltro.fecha_inicio
            ? datosFiltro.fecha_inicio
            : "",
          tipo_movimiento: datosFiltro.tipo_movimiento
            ? datosFiltro.tipo_movimiento
            : "",

          destino: datosFiltro.destino ? datosFiltro.destino._id : "",

          origen: datosFiltro.origen ? datosFiltro.origen._id : "",
          concepto_cuenta: cuentaConcepto.cuenta.cuenta
            ? cuentaConcepto.cuenta.cuenta
            : "",

          concepto_subcuenta: cuentaConcepto.subCuenta.subcuenta
            ? cuentaConcepto.subCuenta.subcuenta
            : "",
        },
        empresa: sesion.empresa._id,
        sucursal: sesion.sucursal._id,
        tipo: tipo,
        limit,
        offset: page,
      },
      fetchPolicy: "network-only",
    }
  );

  const obtenerDatos = (e) => {
    setPage(0);
    setDatosFiltro({ ...datosFiltro, [e.target.name]: e.target.value });
  };
  const obtenerDatosSelect = (name, value) => {
    if (name === "tipo_movimiento") setTipoMovimiento(value);
    //if(name === 'concepto') setConcepto(value);
    if (name === "origen") setOrigen(value);
    if (name === "destino") setDestino(value);
    setPage(0);
    setDatosFiltro({ ...datosFiltro, [name]: value });
  };
  useEffect(() => {
    if (data) {
      setSaldoCaja(data.obtenerHistorialCuenta.saldo_en_caja);
      setHistorialCuentas(data.obtenerHistorialCuenta);
    }
  }, [data]);

  useEffect(() => {
    if (reload) {
      refetch({
        empresa: sesion.empresa._id,
        sucursal: sesion.sucursal._id,
        tipo: tipo,
        limit,
        offset: page,
      });

      setReload(false);
    }
  }, [reload]);

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
    refetch({
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      offset: nextPage,
    });
  };

  const obtenerCuenta = (_, child) => {
    const { cuenta } = child.props;
    setPage(0);
    setCuentaConcepto({
      cuenta: cuenta ? cuenta : {},
      subcuentas: cuenta ? cuenta.subcuentas : [],
      subCuenta: {},
    });
  };
  const obtenerSubcuenta = (_, child) => {
    const { subCuenta } = child.props;
    setPage(0);
    setCuentaConcepto({
      ...cuentaConcepto,
      subCuenta: subCuenta ? subCuenta : {},
    });
  };
  if (error) {
    return <ErrorPage error={error} altura={300} />;
  }

  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid item md={2}>
          <Typography>Fecha:</Typography>
          <TextField
            fullWidth
            size="small"
            type="date"
            name="fecha_inicio"
            onChange={obtenerDatos}
            variant="outlined"
            value={datosFiltro.fecha_inicio}
          />
        </Grid>
        <Grid item md={2}>
          <Typography>Tipo Movimiento:</Typography>
          <Select
            className={classes.formComboBox}
            size="small"
            variant="outlined"
            id="tipo_movimiento"
            value={tipoMovimiento !== "" ? tipoMovimiento : ""}
            onChange={(e) =>
              obtenerDatosSelect("tipo_movimiento", e.target.value)
            }
            fullWidth
          >
            <MenuItem key={1} value="CUENTA_DEPOSITO">
              Deposito
            </MenuItem>
            <MenuItem key={2} value="CUENTA_RETIRO">
              Retiro
            </MenuItem>
          </Select>
        </Grid>
        <Grid item md={2}>
          <Typography>Cuenta</Typography>
          <Select
            className={classes.formComboBox}
            size="small"
            variant="outlined"
            id="concepto-cuenta"
            onChange={obtenerCuenta}
            name="cuenta"
            labelId="label-select-cuenta"
            label="Cuenta"
            fullWidth
            value={
              cuentaConcepto.cuenta.cuenta ? cuentaConcepto.cuenta.cuenta : ""
            }
          >
            <MenuItem value="">
              <em>Selecciona uno</em>
            </MenuItem>
            {cuentas.map((cuenta, index) => {
              return (
                <MenuItem key={index} value={cuenta.cuenta} cuenta={cuenta}>
                  {cuenta.cuenta}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item md={2}>
          <Typography>Subcuenta</Typography>
          <Select
            className={classes.formComboBox}
            size="small"
            variant="outlined"
            id="concepto-subcuenta"
            onChange={obtenerSubcuenta}
            name="subCuenta"
            labelId="label-select-subcuenta"
            label="Subcuenta"
            fullWidth
            value={
              cuentaConcepto.subCuenta.subcuenta
                ? cuentaConcepto.subCuenta.subcuenta
                : ""
            }
          >
            <MenuItem value="">
              <em>Selecciona uno</em>
            </MenuItem>
            {cuentaConcepto.subcuentas.map((subcuenta, index) => {
              return (
                <MenuItem
                  key={index}
                  value={subcuenta.subcuenta}
                  subCuenta={subcuenta}
                >
                  {subcuenta.subcuenta}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid item md={2}>
          <Typography>Origen</Typography>
          <Select
            className={classes.formComboBox}
            size="small"
            variant="outlined"
            onChange={(e) => obtenerDatosSelect("origen", e.target.value)}
            id="origen"
            value={origen.numero_caja ? origen : ""}
            fullWidth
          >
            {cajas.map((caja) => {
              if (!caja.principal) {
                return (
                  <MenuItem key={caja._id} value={caja}>
                    Caja {caja.numero_caja}
                  </MenuItem>
                );
              } else {
                return <div key={caja._id} />;
              }
            })}
          </Select>
        </Grid>
        <Grid item md={2}>
          <Typography>Destino</Typography>
          <Select
            className={classes.formComboBox}
            size="small"
            fullWidth
            variant="outlined"
            id="destino"
            value={destino.numero_caja ? destino : ""}
            onChange={(e) => obtenerDatosSelect("destino", e.target.value)}
          >
            {cajas.map((caja) => {
              if (!caja.principal) {
                return (
                  <MenuItem key={caja._id} value={caja}>
                    Caja {caja.numero_caja}
                  </MenuItem>
                );
              } else {
                return <div key={caja._id} />;
              }
            })}
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item style={{ flexGrow: 1 }}>
          <Box display="flex">
            <Typography variant="h5">Efectivo actual</Typography>
            <Box ml={2}>
              <Typography variant="h5">${formatoMexico(saldoCaja)}</Typography>
            </Box>
            <Box
              ml={2}
              border={1}
              borderColor="#FF8A8A"
              bgcolor="#FFF4F4"
              height="24px"
              width="24px"
            />
            <Box ml={1} />
            <Typography>
              <b>Movimientos cancelados </b>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {loading ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={3}>
          <Paper className={classes.paper}>
            <TableContainer
              className={classes.container}
              ref={tableEl}
              onScroll={handleOnScroll}
            >
              <Table stickyHeader size="small" aria-label="enhanced table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => {
                      let edit = column.id === "editar" ? true : false;
                      if (column.id === "editar" || column.id === "cancelar") {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            className={
                              edit ? classes.colFixedEdit : classes.colFixedDel
                            }
                          >
                            {column.label}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={classes.tableCellNormal}
                          >
                            {column.label}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historialCuentas?.docs?.map((row, index) => {
                    let isDeposito =
                      row?.tipo_movimiento === "CUENTA_DEPOSITO" ||
                      row?.tipo_movimiento === "ABONO_CLIENTE" ||
                      row?.tipo_movimiento === "CANCELACION_ABONO_PROVEEDOR"
                        ? true
                        : false;
                    let tipo_movimiento = row?.tipo_movimiento
                      ? row.tipo_movimiento
                      : "";
                    return (
                      <RowsHistorial
                        key={index}
                        index={index}
                        row={row}
                        loading={loading}
                        isDeposito={isDeposito}
                        tipo_movimiento={tipo_movimiento}
                        shadow={shadow}
                        setShadow={setShadow}
                        setOpenDoMove={setOpenDoMove}
                        setRowSelectedToEdit={setRowSelectedToEdit}
                        sesion={sesion}
                        status={row?.status}
                        setReload={setReload}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={historialCuentas.totalDocs}
              rowsPerPage={limit}
              page={page}
              onPageChange={handleChangePage}
            />
          </Paper>
        </Box>
      )}
    </Fragment>
  );
}

function RowsHistorial({
  index,
  row,
  loading,
  isDeposito,
  shadow,
  setReload,
  setShadow,
  setOpenDoMove,
  setRowSelectedToEdit,
  sesion,
  tipo_movimiento,
  status,
}) {
  const classes = useStyles();

  const { isOnline } = useContext(AccesosContext);
  let showOrigenCaja = "",
    showDestinoCaja = "",
    showUsuarioDestino = "",
    showUsuarioOrigen = "";
  showOrigenCaja = row.origen_caja
    ? isDeposito === true
      ? `Caja ${row.origen_caja.numero_caja}`
      : "N/A"
    : "";
  showDestinoCaja = row.destino_caja
    ? isDeposito === true
      ? `N/A`
      : `Caja ${row.destino_caja.numero_caja}`
    : "";
  showUsuarioDestino = row.usuario_entrega
    ? isDeposito === true
      ? row.usuario_entrega.nombre
      : "N/A"
    : "";
  showUsuarioOrigen = row.usuario_recibe
    ? isDeposito === true
      ? "N/A"
      : row.usuario_recibe.nombre
    : "";

  const permisosUsuario = JSON.parse(localStorage.getItem("sesionCafi"));

  const fecha_movimiento_entrega = row.fecha_movimiento_entrega
    ? moment(row.fecha_movimiento_entrega)
        .tz("America/Mexico_City")
        .format("D MMMM YYYY")
    : moment(row?.fecha_movimiento.completa)
        .tz("America/Mexico_City")
        .format("D MMMM YYYY");

  return (
    <TableRow
      key={index}
      hover
      role="checkbox"
      tabIndex={-1}
      className={
        row.status === "CANCELADO" ? classes.canceladas : classes.realizadas
      }
    >
      <TableCell>
        {moment(row?.fecha_movimiento.completa)
          .tz("America/Mexico_City")
          .format("D MMMM YYYY")}
      </TableCell>
      <TableCell>{tipo_movimiento}</TableCell>
      <TableCell>{row?.concepto_cuenta}</TableCell>
      <TableCell>{row?.concepto_subcuenta}</TableCell>
      <TableCell>
        <b style={{ fontSize: 15 }}>
          ${formatoMexico(row.montos_en_caja.monto_efectivo.monto)}
        </b>
      </TableCell>
      <TableCell>{row?.nombre_usuario_creador}</TableCell>
      <TableCell>{showOrigenCaja}</TableCell>
      <TableCell>{showDestinoCaja}</TableCell>
      <TableCell>{showUsuarioDestino}</TableCell>
      <TableCell>{showUsuarioOrigen}</TableCell>
      <TableCell>{fecha_movimiento_entrega}</TableCell>
      <TableCell>{row.comentarios}</TableCell>
      <TableCell
        align="center"
        paddding="checkbox"
        className={shadow}
        style={{ position: "sticky", right: 66, backgroundColor: "white" }}
      >
        {permisosUsuario.accesos.tesoreria.caja_principal.ver ===
        false ? null : (
          <EditarMovimiento
            isOnline={isOnline}
            setOpen={setOpenDoMove}
            row={row}
            tipo_movimiento={tipo_movimiento}
            setRowSelectedToEdit={setRowSelectedToEdit}
            setReload={setReload}
            status={status}
          />
        )}
      </TableCell>
      <TableCell
        align="center"
        paddding="checkbox"
        style={{ position: "sticky", right: 0, backgroundColor: "white" }}
      >
        {permisosUsuario.accesos.tesoreria.caja_principal.ver ===
        false ? null : (
          <CancelarMovimiento
            isOnline={isOnline}
            empresa={sesion.empresa._id}
            sucursal={sesion.sucursal._id}
            id_movimiento={row._id}
            id_usuario={sesion._id}
            tipo_movimiento={tipo_movimiento}
            status={status}
            setReload={setReload}
          />
        )}
      </TableCell>
    </TableRow>
  );
}
const EditarMovimiento = ({
  isOnline,
  setOpen,
  row,
  setRowSelectedToEdit,
  tipo_movimiento,
  setReload,
  status,
}) => {
  const handleClickOpen = () => {
    setRowSelectedToEdit(row);
    setOpen(true);
  };
  const isAbono =
    status === "CANCELADO" ||
    tipo_movimiento === "ABONO_PROVEEDOR" ||
    tipo_movimiento === "ABONO_CLIENTE" ||
    tipo_movimiento === "CANCELACION_ABONO_PROVEEDOR" ||
    tipo_movimiento === "CANCELACION_ABONO_CLIENTE" ||
    tipo_movimiento === "CANCELACION_CUENTA_DEPOSITO" ||
    tipo_movimiento === "CANCELACION_CUENTA_RETIRO"
      ? true
      : false;
  return (
    <Fragment>
      <IconButton
        disabled={isAbono || !isOnline}
        size="small"
        onClick={() => handleClickOpen()}
      >
        <Edit />
      </IconButton>
    </Fragment>
  );
};

const CancelarMovimiento = ({
  isOnline,
  empresa,
  sucursal,
  id_movimiento,
  id_usuario,
  tipo_movimiento,
  status,
  setReload,
}) => {
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const [loading, setLoading] = useState(false);
  const [CancelarMovimientoCuenta] = useMutation(CANCELAR_MOVIMIENTO_CUENTA);

  const handleClickOpen = () => {
    setOpen(!open);
  };
  const cancelarMovimiento = async () => {
    try {
      setLoading(true);

      let movimiento = await CancelarMovimientoCuenta({
        variables: {
          id_movimiento: id_movimiento,
          id_usuario: id_usuario,
          empresa: empresa,
          sucursal: sucursal,
        },
      });

      let message = movimiento.data.cancelarMovimientoCuenta.message;
      setLoading(false);
      setReload(true);
      handleClickOpen();
      setAlert({
        message: message,
        status: "success",
        open: true,
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setAlert({
        message: error.message,
        status: "error",
        open: true,
      });
      handleClickOpen();
    }
  };
  let disabled =
    status === "CANCELADO" ||
    tipo_movimiento === "CANCELACION_ABONO_PROVEEDOR" ||
    tipo_movimiento === "CANCELACION_ABONO_CLIENTE" ||
    tipo_movimiento === "CANCELACION_CUENTA_DEPOSITO" ||
    tipo_movimiento === "CANCELACION_CUENTA_RETIRO";

  return (
    <Fragment>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <IconButton
        disabled={disabled || !isOnline}
        size="small"
        color="secondary"
        onClick={() => handleClickOpen()}
      >
        <Close />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClickOpen}
      >
        <BackdropComponent loading={loading} setLoading={setLoading} />
        <DialogTitle>¿Estás seguro de cancelar este movimiento?</DialogTitle>
        <DialogActions>
          <Button onClick={handleClickOpen} color="inherit">
            Cerrar
          </Button>
          <Button
            onClick={cancelarMovimiento}
            color="secondary"
            variant="contained"
            disabled={!isOnline}
          >
            Cancelar mov.
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
