import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  styled,
  Typography,
  Toolbar,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React from "react";

export const ModalSeleccion = ({
  openModal,
  setOpenModal,
  values,
  setValues,
}) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  const obtenerChecks = (e) => {
    const { name, checked } = e.target;
    setValues({
      ...values,
      [name]: checked,
    });
  };

  return (
    <Drawer open={openModal} onClose={handleClose} anchor="right">
      <Box
        display="flex"
        alignItems="center"
        position="fixed"
        sx={{
          ml: 2,
          mr: 2,
          minWidth: "150px",
          bgcolor: "#fff",
          zIndex: 10,
        }}
      >
        <IconButton variant="contained" color="primary" onClick={handleClose}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" style={{ margin: "15px" }}>
          Personalizar tabla
        </Typography>
      </Box>

      <Toolbar />

      <Box sx={{ ml: 5, mr: 5, mb: 2 }}>
        <Box>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.folio}
                onChange={obtenerChecks}
                name="folio"
                color="primary"
              />
            }
            label="Folio"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.fecha_registro}
                onChange={obtenerChecks}
                name="fecha_registro"
                color="primary"
              />
            }
            label="Fecha"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.cliente}
                onChange={obtenerChecks}
                name="cliente"
                color="primary"
              />
            }
            label="Cliente"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.clave_cliente}
                onChange={obtenerChecks}
                name="clave_cliente"
                color="primary"
              />
            }
            label="Clave cliente"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.usuario}
                onChange={obtenerChecks}
                name="usuario"
                color="primary"
              />
            }
            label="Usuario"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.caja}
                onChange={obtenerChecks}
                name="caja"
                color="primary"
              />
            }
            label="Caja"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.nombre_comercial}
                onChange={obtenerChecks}
                name="nombre_comercial"
                color="primary"
              />
            }
            label="Nombre comercial"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.medida}
                onChange={obtenerChecks}
                name="medida"
                color="primary"
              />
            }
            label="Medida"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.color}
                onChange={obtenerChecks}
                name="color"
                color="primary"
              />
            }
            label="Color"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.metodo_pago}
                onChange={obtenerChecks}
                name="metodo_pago"
                color="primary"
              />
            }
            label="Método de pago"
          />
        </Box>
        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.forma_pago}
                onChange={obtenerChecks}
                name="forma_pago"
                color="primary"
              />
            }
            label="Forma de pago"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.cantidad_venta}
                onChange={obtenerChecks}
                name="cantidad_venta"
                color="primary"
              />
            }
            label="Cantidad venta"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.unidad}
                onChange={obtenerChecks}
                name="unidad"
                color="primary"
              />
            }
            label="Unidad"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.precio_con_impuesto}
                onChange={obtenerChecks}
                name="precio_con_impuesto"
                color="primary"
              />
            }
            label="Precio con impuesto"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.numero_precio}
                onChange={obtenerChecks}
                name="numero_precio"
                color="primary"
              />
            }
            label="Numero Precio"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.precio_neto}
                onChange={obtenerChecks}
                name="precio_neto"
                color="primary"
              />
            }
            label="Precio neto"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.descuento}
                onChange={obtenerChecks}
                name="descuento"
                color="primary"
              />
            }
            label="Descuento"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.subtotal}
                onChange={obtenerChecks}
                name="subtotal"
                color="primary"
              />
            }
            label="Subtotal"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.impuestos}
                onChange={obtenerChecks}
                name="impuestos"
                color="primary"
              />
            }
            label="Impuestos"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.total}
                onChange={obtenerChecks}
                name="total"
                color="primary"
              />
            }
            label="Total"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.cantidad_devuelta}
                onChange={obtenerChecks}
                name="cantidad_devuelta"
                color="primary"
              />
            }
            label="Cantidad devuelta"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.cantidad_vendida}
                onChange={obtenerChecks}
                name="cantidad_vendida"
                color="primary"
              />
            }
            label="Cantidad vendida"
          />
        </Box>

        <Box role="group" aria-labelledby="sandwich-group">
          <FormControlLabel
            control={
              <Checkbox
                checked={values.nota_credito_total}
                onChange={obtenerChecks}
                name="nota_credito_total"
                color="primary"
              />
            }
            label="Nota crédito total"
          />
        </Box>
      </Box>
    </Drawer>
  );
};
