import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, useTheme } from "@material-ui/core";
import { CropOriginal } from "@material-ui/icons";
import InfoProducto from "./InfoProducto";
import { Img } from "react-image";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    backgroundColor: "#fff",
    height: "100%",
    border: "1px solid",
    borderColor: "rgb(0,0,0,0.1)",
    borderRadius: 5,
  },
  imgContainer: {
    cursor: "pointer",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxHeight: "80%",
    maxWidth: "80%",
  },
}));

export default function ItemProducto({ producto, handleTableSelect }) {
  const classes = useStyles();
  const theme = useTheme();

  const getImagen = () => {
    let imagen = "";
    try {
      if (
        producto.id_producto.imagenes &&
        producto.id_producto.imagenes.length > 0
      ) {
        imagen = producto.id_producto.imagenes[0].url_imagen;
      }
      return imagen;
    } catch (error) {
      console.log(error);
      return imagen;
    }
  };

  function MyImageComponent() {
    let imagen = "";
    if (
      producto.id_producto.imagenes &&
      producto.id_producto.imagenes.length > 0
    ) {
      imagen = producto.id_producto.imagenes[0].url_imagen;
    }

    return imagen ? (
      <Img src={[imagen]} className={classes.image} />
    ) : (
      <CropOriginal fontSize="large" color="disabled" />
    );
  }

  let ComponenteMedidaColor = () => {
    if (producto.color && producto.medida) {
      return (
        <div
          style={{
            position: "absolute",
            height: 20,
            width: "auto",
            padding: "2px 4px",
            borderRadius: 5,
            bottom: 3,
            right: 3,
            backgroundColor: producto.color.hex,
            color: theme.palette.getContrastText(producto.color.hex),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="button">{producto.medida.talla}</Typography>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Box className={classes.container}>
      <InfoProducto producto={producto} getImagen={getImagen} />
      <Box
        className={classes.imgContainer}
        onClick={(e) => handleTableSelect(e, producto, 1)}
      >
        <MyImageComponent />
      </Box>
      <ComponenteMedidaColor />
    </Box>
  );
}
