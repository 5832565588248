import React from "react";
import Box from "@material-ui/core/Box";
import { VentasProvider } from "../../context/Ventas/ventasContext";
import { useNavigate } from "react-router-dom";
import PanelVentas from "../../pages/PanelVenta";

export default function LayoutVentas() {
  const sesion = localStorage.getItem("sesionCafi");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!sesion) {
      navigate("/");
    }
  }, [sesion, navigate]);

  if (!sesion) return null;

  return (
    <Box height="100%">
      <VentasProvider>
        <PanelVentas />
      </VentasProvider>
    </Box>
  );
}
