import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import TableUbicaciones from "./TableUbicaciones.jsx";
import Destinos from "./Destinos.jsx";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(1),
    height: "100%",
    minHeight: 100,
    borderColor: grey[200],
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 10,
    position: "absolute",
    top: -30,
    left: -18,
    backgroundColor: "white",
    padding: "0 8px",
  },
}));

export default function UbicacionesCartaPorte() {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box position="relative" pb={1}>
        <Box className={classes.title}>
          <Typography><b>Destinos</b></Typography>
          <Destinos />
        </Box>
      </Box>
      <TableUbicaciones />
    </Paper>
  );
}
