import React, { useState, useEffect } from "react";
import { Button, Box } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { RiFileExcel2Line } from "react-icons/ri";
import ModalProveedoresAgregar from "./ModalProveedoresAgregar";
import SnackBarMessages from "../../../../../components/SnackBarMessages";
import ExcelExportButton from "../../../../../components/ExcelExportButton";
const ExcelJS = require("exceljs");

function ImportXlsModal(props) {
  const { openMenu, setOpenMenu } = props;
  const [modalProveedores, setModalProveedores] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const [proveedores, setProveedores] = useState([]);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const handleClickClose = () => {
    setOpenMenu(null);
  };
  const onCloseModal = () => {
    setModalProveedores(false);
    setProveedores([]);
  };

  useEffect(() => {
    if (proveedores.length === 0 && modalProveedores) {
      setModalProveedores(false);
      setAlert({
        message: "No fué posible agregar los datos del documento seleccionado.",
        status: "error",
        open: true,
      });
    }
    setLoading(false);
  }, [proveedores]);

  const getFile = (file, reader) => {
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException("Problem parsing input file."));
      };
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const importarXlsPlantillaProveedores = async (e) => {
    try {
      setOpenMenu(false);
      setLoading(true);
      const file = e.target.files[0];
      const wb = new ExcelJS.Workbook();
      const reader = new FileReader();
      const proveedores_pushed = [];

      reader.readAsArrayBuffer(file);

      let buffer = await getFile(file, reader);

      wb.xlsx.load(buffer).then((workbook) => {
        workbook.eachSheet((sheet, id) => {
          sheet.eachRow((row, rowIndex) => {
            if (rowIndex > 1) {
              let object_proveedor = getObjectToSend(row.values);

              if (object_proveedor.selected) {
                proveedores_pushed.push(object_proveedor);
              }
            }
          });
        });
        setModalProveedores(true);
        setProveedores(proveedores_pushed);
      });
      reader.abort();
    } catch (error) {
      console.log(error);
    }
  };

  const getObjectToSend = (row) => {
    try {
      let selected = true;

      if (!row[1] || !row[2] || !row[3] || !row[4]) {
        return {};
      }

      let objectReturn = {
        clave_cliente: "",
        representante: row[2].toString(),
        nombre_cliente: row[1].toString(),
        telefono: row[4].toString(),
        email: row[3].toString(),
        direccion: {
          calle: row[5].toString(),
          no_ext: row[6].toString(),
          no_int: "",
          codigo_postal: row[7].toString(),
          colonia: row[8].toString(),
          municipio: row[9].toString(),
          localidad: "",
          estado: row[10].toString(),
          pais: row[11].toString(),
        },
        estado_cliente: true,
        tipo_cliente: "PROVEEDOR",
        empresa: sesion.empresa._id,
        sucursal: sesion.sucursal._id,
        selected: selected,
      };

      return objectReturn;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const dataPlantilla = [
    {
      nombre_proveedor: "",
      representante: "",
      email: "",
      telefono: "",
      calle: "",
      num_ext: "",
      codigo_postal: "",
      colonia: "",
      municipio: "",
      estado: "",
      pais: "",
    },
  ];

  return (
    <Box>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <Menu
        id="menu-xls"
        anchorEl={openMenu}
        keepMounted
        open={openMenu}
        onClose={handleClickClose}
        style={{ zIndex: 9999, marginTop: 40 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem>
          <ExcelExportButton
            data={dataPlantilla}
            name="Plantilla Xls proveedores"
            button
          />
        </MenuItem>
        <MenuItem>
          <label htmlFor="contained-button-file">
            <Button
              variant="text"
              type="file"
              color="primary"
              startIcon={<RiFileExcel2Line />}
              component="span"
              disableElevation
            >
              Importar proveedores excel
            </Button>
          </label>
          <input
            id="contained-button-file"
            multiple
            type="file"
            accept=".xlsx"
            style={{ display: "none" }}
            name="archivo"
            onChange={importarXlsPlantillaProveedores}
          />
        </MenuItem>
      </Menu>
      <ModalProveedoresAgregar
        modalProveedores={modalProveedores}
        onCloseModal={onCloseModal}
        proveedores={proveedores}
        setLoading={setLoading}
        loading={loading}
        setProveedores={setProveedores}
        setAlert={setAlert}
      />
    </Box>
  );
}

export default ImportXlsModal;
