import { Box, Button, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import { InfoOutlined } from "@material-ui/icons";
import moment from "moment-timezone";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import { useApolloClient } from "@apollo/client";
import {
  OBTENER_COMPRAS_ESTADO_DE_CUENTA,
  OBTENER_PROVEEDORES_ESTADO_DE_CUENTA,
} from "../../../../../gql/Tesoreria/abonos";
import ExcelExportButton from "../../../../../components/ExcelExportButton";

export default function ExportarExcelAbonosProveedores({
  openVista,
  arrayExcel,
  datosExcel,
  setDatosExcel,
  datosFiltro,
  proveedorID,
  filtros,
}) {
  const [loadingExcelProveedor, setLoadingExcelProveedor] = useState(false);
  const [errorExcelProveedor, setErrorExcelProveedor] = useState(false);
  const [loadingExcelCompra, setLoadingExcelCompra] = useState(false);
  const [errorExcelCompra, setErrorExcelCompra] = useState(false);
  const client = useApolloClient();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const getProveedoresEstadoDeCuentaExcel = async () => {
    try {
      setLoadingExcelProveedor(true);
      const response = await client.query({
        query: OBTENER_PROVEEDORES_ESTADO_DE_CUENTA,
        variables: {
          empresa: sesion.empresa._id,
          filtro: datosFiltro.proveedor,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoadingExcelProveedor(false);

      if (response.data.getProveedoresEstadoCuenta.docs.length) {
        setDatosExcel(response.data.getProveedoresEstadoCuenta.docs);
        document.getElementById("excel-button-reportes2").click();
      }
      if (response.error) setErrorExcelProveedor(true);
    } catch (error) {
      setLoadingExcelProveedor(false);
      setErrorExcelProveedor(true);
      console.log(error);
    }
  };

  const getComprasEstadoDeCuentaExcel = async () => {
    try {
      setLoadingExcelCompra(true);
      const response = await client.query({
        query: OBTENER_COMPRAS_ESTADO_DE_CUENTA,
        variables: {
          cliente: proveedorID,
          filtro: filtros,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoadingExcelCompra(false);

      if (response.data.getComprasEstadoCuenta.docs.length) {
        setDatosExcel(response.data.getComprasEstadoCuenta.docs);

        document.getElementById("excel-button-reportes2").click();
      }

      if (response.error) setErrorExcelCompra(true);
    } catch (error) {
      setLoadingExcelCompra(false);
      setErrorExcelCompra(true);
      console.log(error);
    }
  };

  const ExcelDownload = () => {
    if (openVista === "Proveedores") {
      getProveedoresEstadoDeCuentaExcel();
    } else if (openVista === "Compras") {
      getComprasEstadoDeCuentaExcel();
    }
  };

  if (loadingExcelProveedor || loadingExcelCompra) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        Excel
      </Button>
    );
  }

  if (errorExcelProveedor || errorExcelCompra) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        Excel
      </Button>
    );
  }

  return (
    <Box>
      {datosExcel.length ? (
        <ExportarExcelAction
          arrayExcel={arrayExcel}
          openVista={openVista}
          datosExcel={datosExcel}
        />
      ) : (
        <Button
          variant="text"
          color="primary"
          onClick={() => ExcelDownload()}
          size="large"
          startIcon={<RiFileExcel2Line />}
        >
          Excel
        </Button>
      )}
    </Box>
  );
}

const ExportarExcelAction = ({ datosExcel, openVista, arrayExcel }) => {
  const reporteData = datosExcel?.map((res) => {
    if (openVista === "Proveedores") {
      arrayExcel = {
        numero_cliente: res.numero_cliente,
        nombre_cliente: res.nombre_cliente.toString(),
        telefono: res.telefono,
        saldo_pendiente: "$" + formatoMexico(res.saldo_pendiente.total),
      };
      return arrayExcel;
    } else if (openVista === "Compras") {
      arrayExcel = {
        folio: res.folio,
        fecha_compra: moment(res.fecha_registro)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY"),
        total: "$" + formatoMexico(res.total),
        saldo_credito_pendiente:
          "$" + formatoMexico(res.saldo_credito_pendiente),
        status: res.status === null ? "REALIZADO" : res.status,
      };
      return arrayExcel;
    } else {
      arrayExcel = {
        fecha_abono: moment(res.fecha_movimiento.completa)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY"),
        monto: "$" + formatoMexico(res.monto_total_abonado),
        numero_caja: res.numero_caja.toString(),
        turno: res.horario_turno,
        metodo_de_pago: res.metodo_de_pago.metodo,
        status: res.status,
      };
      return arrayExcel;
    }
  });

  return (
    <ExcelExportButton
      data={reporteData}
      name={
        openVista === "Proveedores"
          ? `Reporte de Estado de cuenta Proveedores`
          : openVista === "Compras"
          ? `Reporte de Estado de cuenta Proveedores - Compras`
          : `Reporte de Estado de cuenta Proveedores - Compras - Abonos`
      }
      button
    />
  );
};
