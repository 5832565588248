import React from "react";
import { useTheme } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Badge from "@material-ui/core/Badge";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import InputRow from "./InputRow";
import { TraspasosContext } from "../../../Context/TraspasosContext";
import { Close } from "@material-ui/icons";

export default function Productos() {
  const {
    traspaso,
    setTraspaso,
    item,
    setItem,
    initialItem,
  } = React.useContext(TraspasosContext);

  const addItemTable = (e) => {
    e.preventDefault();
    const productos = [...traspaso.productos];
    //verificar si ya esta en la tabla
    if (productos.length) {
      let repetido = false;
      productos.forEach((elm, i) => {
        if (elm.product_selected?._id === item.product_selected?._id) {
          repetido = true;
          productos.splice(i, 1, item);
          return;
        }
      });
      if (!repetido) productos.unshift(item);
    } else {
      productos.unshift(item);
    }

    setTraspaso({
      ...traspaso,
      productos,
    });
    setItem(initialItem);
  };

  const removeItemTable = (index) => {
    const productos = [...traspaso.productos];
    productos.splice(index, 1);
    setTraspaso({
      ...traspaso,
      productos,
    });
  };

  return (
    <form onSubmit={addItemTable}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Código</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Medidas</TableCell>
              <TableCell padding="checkbox" />
            </TableRow>
          </TableHead>
          <TableBody>
            <InputRow />
            {traspaso.productos.map((row, indx) => (
              <TableRow key={indx} style={{ height: 48 }}>
                <TableCell>
                  {row.product_selected?.datos_generales.nombre_comercial}
                </TableCell>
                <TableCell>
                  {row.product_selected?.datos_generales.codigo_barras}
                </TableCell>
                <TableCell>{row.cantidad_total}</TableCell>
                <TableCell>
                  <RenderMedidas data={row.new_medidas} />
                </TableCell>
                <TableCell align="right" padding="checkbox">
                  <IconButton onClick={() => removeItemTable(indx)}>
                    <Close />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </form>
  );
}

const RenderMedidas = ({ data }) => {
  const theme = useTheme();
  if (!data.length) return null;

  return (
    <Grid container spacing={1}>
      {data.map((res, indx) => {
        const { medida, nuevaCantidad } = res;
        return (
          <Grid item key={indx}>
            <Badge badgeContent={nuevaCantidad} color="secondary">
              <Chip
                style={{
                  backgroundColor: medida.color.hex,
                  color: theme.palette.getContrastText(medida.color.hex),
                }}
                label={medida.medida._id ? medida.medida.talla : ""}
              />
            </Badge>
          </Grid>
        );
      })}
    </Grid>
  );
};
