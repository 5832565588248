import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PedidosPendientes from "./PedidosPendientes";
import { LocalMall, Close, Storefront } from "@material-ui/icons";
import { AccesosContext } from "../../../context/Accesos/accesosCtx";
import { MainContext } from "../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PedidosVenta() {
  const [open, setOpen] = useState(false);
  const { turnoEnCurso } = React.useContext(MainContext);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { isOnline } = React.useContext(AccesosContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (!sesion || !sesion.empresa.tienda) return null;

  return (
    <>
      <ListItem
        button
        onClick={() => handleClickOpen()}
        disabled={!turnoEnCurso || !isOnline}
      >
        <ListItemIcon>
          <Storefront />
        </ListItemIcon>
        <ListItemText primary="Pedidos Tienda" />
      </ListItem>
      <Dialog
        fullWidth
        open={open}
        maxWidth={"md"}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle component="div">
          <Box display="flex">
            <LocalMall
              color="primary"
              style={{ fontSize: 30, marginRight: 8 }}
            />
            <Typography variant="h6">Pedidos Tienda</Typography>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClose}
              size="medium"
            >
              <Close />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <PedidosPendientes handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
}
