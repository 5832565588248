import React from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { useQuery } from "@apollo/client";
import { TraspasosContext } from "../../Context/TraspasosContext";
import { CallMade } from "@material-ui/icons";
import { GetSucursales } from "../../graphql/queries";
import { CustomTableSelect } from "../../../../../../components/CustomMuiComponents";
import { MainContext } from "../../../../../../context/MainCtx";

export default function SucursalDestino() {
  const { tokenDecoded } = React.useContext(MainContext);
  const { traspaso, setTraspaso } = React.useContext(TraspasosContext);

  const { loading, data, error } = useQuery(GetSucursales, {
    variables: {
      id: tokenDecoded.empresa._id,
    },
    fetchPolicy: "network-only",
  });

  const { concepto_traspaso } = traspaso;
  if (concepto_traspaso?.nombre_concepto !== "SALIDAS POR TRASPASOS")
    return null;

  if (loading) {
    return (
      <Grid item md={4}>
        <FormControl size="small" variant="outlined" fullWidth disabled>
          <InputLabel id="loading">Sucursal destino</InputLabel>
          <CustomTableSelect
            labelId="loading"
            label="Sucursal destino"
            value="loading"
          >
            <MenuItem value="loading">Cargando...</MenuItem>
          </CustomTableSelect>
        </FormControl>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid item md={4}>
        <FormControl size="small" variant="outlined" fullWidth disabled>
          <InputLabel id="error">Sucursal destino</InputLabel>
          <CustomTableSelect
            labelId="error"
            label="Sucursal destino"
            value="error"
          >
            <MenuItem value="error">Error</MenuItem>
          </CustomTableSelect>
        </FormControl>
      </Grid>
    );
  }
  const DATA = data?.obtenerSucursalesEmpresa || [];
  const sucursales = DATA.filter(
    (res) => res._id !== tokenDecoded.sucursal._id
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setTraspaso({
      ...traspaso,
      sucursalDestino: value,
    });
  };

  return (
    <Grid item md={4}>
      <FormControl size="small" variant="outlined" fullWidth>
        <InputLabel id="sucursal-destino-label">Sucursal destino</InputLabel>
        <CustomTableSelect
          labelId="sucursal-destino-label"
          label="Sucursal destino"
          value={traspaso.sucursalDestino || ""}
          onChange={handleChange}
          disabled={
            !traspaso.concepto_traspaso ||
            traspaso.productos.length ||
            traspaso.concepto_traspaso?.destino === "N/A"
          }
          startAdornment={<CallMade fontSize="small" />}
        >
          {sucursales.map((sucursal) => (
            <MenuItem key={sucursal._id} value={sucursal._id}>
              {sucursal.nombre_sucursal}
            </MenuItem>
          ))}
        </CustomTableSelect>
      </FormControl>
    </Grid>
  );
}
