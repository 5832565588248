import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { formatoMexico } from "../../../config/reuserFunctions";
import { Button, DialogTitle } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetallePedido({ cotizacion, open, handleClose }) {
  if (!cotizacion) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle component="div">
        <Box display="flex" justifyContent="flex-end">
          <Button color="secondary" variant="contained" onClick={handleClose}>
            <Close />
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box my={1}>
          <Box display="flex" alignItems="center">
            <Avatar>
              <AccountBoxIcon size={12} />
            </Avatar>
            <Box mx={1} />
            <Box>
              <Typography size={18}>Cliente</Typography>
            </Box>
          </Box>
          <Box my={1}>
            <Paper variant="outlined">
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Nombre</TableCell>
                      <TableCell>E-mail</TableCell>
                      <TableCell>Domicilio</TableCell>
                      <TableCell>Colonia</TableCell>
                      <TableCell>Ciudad</TableCell>
                      <TableCell>Estado</TableCell>
                      <TableCell>Teléfono</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        {cotizacion.cliente.nombre_cliente !== null
                          ? cotizacion.cliente.nombre_cliente
                          : " Publico General"}
                      </TableCell>
                      <TableCell>
                        {cotizacion.cliente.nombre_cliente !== null
                          ? cotizacion.cliente.email
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {cotizacion.cliente.nombre_cliente !== null
                          ? ` ${cotizacion.cliente.direccion.calle} ${cotizacion.cliente.direccion.no_ext}`
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {cotizacion.cliente.nombre_cliente !== null
                          ? cotizacion.cliente.direccion.colonia
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {cotizacion.cliente.nombre_cliente !== null
                          ? cotizacion.cliente.direccion.municipio
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {cotizacion.cliente.nombre_cliente !== null
                          ? cotizacion.cliente.direccion.estado
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {cotizacion.cliente.nombre_cliente !== null
                          ? cotizacion.cliente.telefono
                          : "-"}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </Box>
        <Box my={2}>
          <Box display="flex" alignItems="center">
            <Avatar>
              <ShoppingCartIcon size={12} />
            </Avatar>
            <Box mx={1} />
            <Box>
              <Typography size={18}>Artículos</Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={2} pb={5}>
          <TableContainer>
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              id="tabla-reporte-cotizacion"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Código de barras</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Cantidad</TableCell>
                  <TableCell>Unidad</TableCell>
                  <TableCell>P.Unit</TableCell>
                  <TableCell>Descuento</TableCell>
                  <TableCell>Importe</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cotizacion.productos.map((row, index) => (
                  <ProductosRender key={index} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Table
              size="small"
              stickyHeader
              aria-label="a dense table"
              id="tabla-totales"
              style={{ width: "40%" }}
            >
              <TableBody>
                <TableRow key={0}>
                  <TableCell>
                    <b>{`Subtotal: `}</b>
                  </TableCell>
                  <TableCell>{`$${cotizacion.subTotal}`}</TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell>
                    <b>{`Descuento: `}</b>
                  </TableCell>
                  <TableCell>{`$ ${cotizacion.descuento}`}</TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell>
                    <b>{`Total: `}</b>
                  </TableCell>
                  <TableCell>{`$ ${cotizacion.total}`}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

const ProductosRender = ({ row }) => {
  const name = () => {
    const { nombre_comercial } = row.id_producto.datos_generales;
    const { color, medida } = row;

    if (color || medida) {
      if ((color.nombre || color.color) && (medida.talla || medida.medida)) {
        return `${nombre_comercial} Talla ${medida.talla || medida.medida} ${
          color.nombre || color.color
        }`;
      } else {
        return nombre_comercial;
      }
    } else {
      return nombre_comercial;
    }
  };
  return (
    <TableRow>
      <TableCell width="14%">
        {row.id_producto.datos_generales.codigo_barras}
      </TableCell>
      <TableCell>{name()}</TableCell>
      <TableCell>{row.cantidad_venta}</TableCell>
      {/*   <ComponenteMedidaColor producto={row} /> */}
      <TableCell>{row.unidad ? row.unidad : "Pz"}</TableCell>
      <TableCell>${formatoMexico(row.precio_a_vender)}</TableCell>
      <ComponenteDescuento producto={row} />
      <TableCell>${formatoMexico(row.total_total_producto)}</TableCell>
    </TableRow>
  );
};

const ComponenteDescuento = ({ producto }) => {
  if (producto.descuento_general_activo === true) {
    const { dinero_descontado, porciento } = producto.descuento_general;
    return (
      <TableCell>
        {`$${formatoMexico(dinero_descontado)} - %${porciento}`}
      </TableCell>
    );
  } else {
    return <TableCell>{"$0.00"}</TableCell>;
  }
};
/* const ComponenteMedidaColor = ({ producto }) => {
  const classes = useStyles();
  const theme = useTheme();

  if (producto.color.nombre && producto.medida.talla) {
    return (
      <Box>
        <Tooltip title={producto.color.nombre} placement="top" arrow>
          <div
            className={classes.colorContainer}
            style={{
              backgroundColor: producto.color.hex,
              color: theme.palette.getContrastText(producto.color.hex),
            }}
          >
            {producto.medida.talla}
          </div>
        </Tooltip>
      </Box>
    );
  } else {
    return <TableCell align="center">{"N/A"}</TableCell>;
  }
}; */
