import Catalogs from "../facturamaCatalogs";

const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

const getIdOrigenDestinoLocal = (acronimo = "OR") => {
  return `${acronimo}${Math.floor(
    Math.random() * (999999 - 100000 + 1) + 100000
  )}`;
};

export const factura_initial_state = {
  serie: "",
  currency: "MXN",
  expedition_place: "",
  folio: "",
  cfdi_type: "I",
  payment_form: "",
  payment_method: "",
  logo_url: sesion && sesion.empresa.imagen ? sesion.empresa.imagen : "",
  date: "0",
  issuer: {
    FiscalRegime: sesion ? sesion.empresa.regimen_fiscal : "",
    Rfc: sesion ? sesion.empresa.rfc : "",
    Name: sesion ? sesion.empresa.nombre_fiscal : "",
  },
  receiver: {
    Rfc: "",
    Name: "",
    CfdiUse: "",
  },
  items: [],
  empresa: sesion ? sesion.empresa._id : "",
  sucursal: sesion ? sesion.sucursal._id : "",
  periodicity: "",
};

export const complement_initial_state = {
  NameId: "35",
  Exportation: "01", //No aplica
  Complemento: {
    CartaPorte30: {
      TranspInternac: "No",
      Ubicaciones: [
        {
          Nombre: sesion ? sesion.empresa.nombre_fiscal : "",
          TipoUbicacion: "Origen",
          IDUbicacion: getIdOrigenDestinoLocal("OR"),
          FechaHoraSalidaLlegada: "",
          RFCRemitenteDestinatario: sesion ? sesion.empresa.rfc : "",
          Domicilio: {
            Calle: "",
            Pais: "MEX",
            CodigoPostal: "",
            Estado: "",
            Municipio: "",
            Localidad: "",
          },
          AddresWithNames: sesion ? sesion.empresa.direccion : null,
        },
      ],
      Mercancias: {
        UnidadPeso: "KGM",
        LogisticaInversaRecoleccionDevolucion: "Sí",
        Mercancia: [],
        Autotransporte: null,
        NumTotalMercancias: "0",
      },
      FiguraTransporte: [],
    },
  },
};

export const getAddressCodes = async (address) => {
  const ZipCode = address.codigo_postal || address.ZipCode;
  let addressWithCodes = {
    Calle: address.calle || address.Street,
    Pais: "MEX",
    CodigoPostal: ZipCode,
    Estado: "",
    Municipio: "",
    Localidad: "",
  };
  await Catalogs.PostalCodes(ZipCode, function (err, response) {
    if (response && response.length > 0) {
      const { LocationCode, MunicipalityCode, StateCode } = response[0];
      addressWithCodes = {
        ...addressWithCodes,
        Estado: StateCode,
        Municipio: MunicipalityCode,
        Localidad: LocationCode,
      };
    }
  });
  return addressWithCodes;
};

export const getIdOrigenDestino = (acronimo = "OR") => {
  return `${acronimo}${Math.floor(
    Math.random() * (999999 - 100000 + 1) + 100000
  )}`;
};

export const getAddressString = (address) => {
  let newAddress = "";
  if (!address) return newAddress;
  let calle = address.calle || address.Street || address.Calle;
  let numero =
    address.no_ext || address.ExteriorNumber || address.NumeroExterior;
  let municipio =
    address.municipio || address.Municipality || address.Municipio;
  let colonia = address.colonia || address.Neighborhood || address.Colonia;
  let cp = address.codigo_postal || address.ZipCode || address.CodigoPostal;
  let estado = address.estado || address.State || address.Estado;
  let pais = address.pais || address.Country || address.Pais;

  if (calle) newAddress = calle;
  if (numero) newAddress = newAddress + " #" + numero;
  if (municipio) newAddress = newAddress + ", " + municipio;
  if (colonia) newAddress = newAddress + " " + colonia;
  if (cp) newAddress = newAddress + " CP" + cp;
  if (estado) newAddress = newAddress + ", " + estado;
  if (pais) newAddress = newAddress + ", " + pais;

  return newAddress;
};
