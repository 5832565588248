import React, { useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { VentasContext } from "../../context/Ventas/ventasContext";
import LicenceVerificate from "../../components/VerificateLicence/LicenceVerificate";
import ProximoVencerAlert from "../../components/VerificateLicence/ProximoVencerAlert";
import NavbarVentas from "./Navbar";
import TicketPanel from "./TicketPanel";
import { Backdrop, CircularProgress, Portal, Toolbar } from "@material-ui/core";
import PanelMenu from "./Navbar/Panel";
import PanelRapidoMenu from "./Navbar/PanelRapido";

export default function PanelVentas() {
  const { ventaRapida, openBackDrop } = useContext(VentasContext);
  const flexDirection = ventaRapida ? "row-reverse" : "row";

  return (
    <Box height="100%">
      <LicenceVerificate />
      <ProximoVencerAlert />
      <Box height="100vh" display="flex">
        <NavbarVentas />
        <Box flexGrow={1}>
          <Toolbar variant="dense" />
          <Grid container style={{ flexDirection }}>
            <Grid item xs={ventaRapida ? 4 : 9}>
              <TicketPanel />
            </Grid>
            <Grid item xs={ventaRapida ? 8 : 3}>
              {ventaRapida ? <PanelRapidoMenu /> : <PanelMenu />}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Portal>
        <Backdrop style={{ zIndex: "99999" }} open={openBackDrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Portal>
    </Box>
  );
}
