import React, { useState, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CrearNota from "./CrearNota";
import { BsFillCartCheckFill } from "react-icons/bs";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { withStyles } from "@material-ui/core";
import { red, yellow } from "@material-ui/core/colors";
import { Done } from "@material-ui/icons";
import { MainContext } from "../../../context/MainCtx";

const PagarButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[100]),
    backgroundColor: red[100],
    //fontWeight: 900,
    textTransform: "none",
    height: 40,
    "&:hover": {
      backgroundColor: red[100],
    },
  },
}))(Button);

export default function NotaCredito() {
  const { turnoEnCurso } = React.useContext(MainContext);
  const datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));
  const [open, setOpen] = useState(false);
  const { ventaRapida } = React.useContext(VentasContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function funcion_tecla(event) {
    const tecla_escape = event.keyCode;
    if (tecla_escape === 27 && datosVenta) {
      handleClickOpen();
    }
  }
  window.onkeydown = funcion_tecla;

  return (
    <Fragment>
      {ventaRapida ? (
        <PagarButton
          fullWidth
          disableElevation
          variant="contained"
          size="large"
          startIcon={<Done fontSize="small" />}
          onClick={() => handleClickOpen()}
          disabled={!turnoEnCurso || !datosVenta}
        >
          Nota
        </PagarButton>
      ) : (
        <Button
          className="button-panel-venta b-nota"
          fullWidth
          variant="outlined"
          onClick={() => handleClickOpen()}
          disabled={!turnoEnCurso || !datosVenta}
        >
          <Box>
            <BsFillCartCheckFill className="icon-btn-nota" />
            <Typography className="text-btn-panel">Crear Nota</Typography>
            <Typography className="text-btn-panel-sec">ESC</Typography>
          </Box>
        </Button>
      )}
      <CrearNota open={open} handleClose={handleClose} />
    </Fragment>
  );
}
