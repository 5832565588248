import React from "react";
import Menu from "@material-ui/core/Menu";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { InfoOutlined, Update } from "@material-ui/icons";
import { Badge, Box, CircularProgress, Portal } from "@material-ui/core";
import { GET_UPDATES } from "../../gql/Updates/Updates";
import { useQuery } from "@apollo/client";
import ItemDownload from "./ItemDownload";
import moment from "moment-timezone";

let electron = undefined;
if (window.require) {
  electron = window.require("electron");
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function DownloadInstaller() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [progress, setProgress] = React.useState(0);
  const [clicked, setClicked] = React.useState("");

  const { data, loading, error } = useQuery(GET_UPDATES);

  if (!electron) return null;

  if (loading) return <CircularProgress style={{ marginLeft: 1 }} size={15} />;
  if (error) return <InfoOutlined style={{ marginLeft: 1 }} />;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let isNew = 0;
  if (data && data.getUpdatesModel.length > 0) {
    let firstItem = data.getUpdatesModel[0];
    const restante = moment(firstItem.launch_date).diff(
      moment().tz("America/Mexico_City"),
      "days"
    );
    if (restante === 0) isNew = 1;
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleClick}
        color="inherit"
      >
        <Badge color="secondary" variant="dot" badgeContent={isNew}>
          <Update />
        </Badge>
      </IconButton>
      <Typography>
        {progress ? `${Math.round(progress)}%` : ""}
      </Typography>
      <Portal>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Typography align="center">
            <strong>Últimas actualizaciones</strong>
          </Typography>
          {data && data.getUpdatesModel.length > 0 ? (
            data?.getUpdatesModel.map((res, index) => (
              <ItemDownload
                key={index}
                datos={res}
                setProgress={setProgress}
                clicked={clicked}
                setClicked={setClicked}
              />
            ))
          ) : (
            <Box
              p={3}
              width={450}
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="textSecondary">
                No hay actualizaciones
              </Typography>
              <Update color="action" />
            </Box>
          )}
        </Menu>
      </Portal>
    </div>
  );
}
