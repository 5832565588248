import React, { Fragment } from "react";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Add, ArrowBack, Done, Edit } from "@material-ui/icons";
import SnackBarMessages from "../../../../../../components/SnackBarMessages";
import { useMutation } from "@apollo/client";
import { CircularProgress, TextField } from "@material-ui/core";
import {
  CREATE_TRANSPORTISTA,
  UPDATE_TRANSPORTISTA,
} from "../../../../../../gql/Facturacion/CartaPorte";

const initial_user = {
  TipoFigura: "01",
  RFCFigura: "",
  NumLicencia: "",
  NombreFigura: "",
  Domicilio: {
    Calle: "",
    NumeroExterior: "",
    NumeroInterior: "",
    Colonia: "",
    Localidad: "",
    Referencia: "",
    Municipio: "",
    Estado: "",
    Pais: "",
    CodigoPostal: "",
  },
};

export default function CrearTransportista({ edit, datos, refetch }) {
  const [data, setData] = React.useState(initial_user);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    message: "",
    status: "",
    open: false,
  });
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const handleClickOpen = () => {
    setOpen(true);
    if (edit && datos) setData(datos);
  };

  const handleClose = () => {
    setOpen(false);
    setData(initial_user);
  };

  /* Mutations */
  const [crearTransportista] = useMutation(CREATE_TRANSPORTISTA);
  const [actualizarTransportista] = useMutation(UPDATE_TRANSPORTISTA);

  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (edit) {
        const { _id, ...input } = data;
        const res = await actualizarTransportista({
          variables: {
            idTransportista: data._id,
            input,
          },
        });
        setAlert({
          message: res.data.actualizarTransportista.message,
          status: "success",
          open: true,
        });
      } else {
        const res = await crearTransportista({
          variables: {
            empresa: sesion.empresa._id,
            sucursal: sesion.sucursal._id,
            input: data,
          },
        });
        setAlert({
          message: res.data.crearTransportista.message,
          status: "success",
          open: true,
        });
      }
      setLoading(false);
      refetch();
      handleClose();
    } catch (error) {
      setAlert({ message: error.message, status: "error", open: true });
      setLoading(false);
      console.log(error);
      if (error.networkError) {
        console.log(error.networkError.result);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors);
      }
    }
  };

  const handleChange = (name, value) => {
    setData((st) => ({
      ...st,
      [name]: value,
    }));
  };
  const handleChangeDom = (name, value) => {
    setData((st) => ({
      ...st,
      Domicilio: {
        ...st.Domicilio,
        [name]: value,
      },
    }));
  };

  return (
    <Fragment>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      {edit ? (
        <IconButton size="small" onClick={handleClickOpen}>
          <Edit />
        </IconButton>
      ) : (
        <Button
          size="small"
          color="primary"
          variant="contained"
          disableElevation
          onClick={handleClickOpen}
          startIcon={<Add />}
        >
          Nuevo
        </Button>
      )}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>
          <Box display="flex" alignItems="center" gridGap={10}>
            <IconButton color="primary" onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Nuevo</Typography>
            <Box flexGrow={1} />
            <Button
              color="primary"
              variant="contained"
              size="small"
              disabled={loading}
              type="submit"
              form="transportista-form"
              disableElevation
              startIcon={
                loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <Done />
                )
              }
            >
              Guardar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent style={{ marginBottom: 20 }}>
          <form id="transportista-form" onSubmit={handleSave}>
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              label="Nombre"
              onChange={(e) => handleChange("NombreFigura", e.target.value)}
              value={data.NombreFigura}
              required
              fullWidth
            />
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              label="RFC"
              onChange={(e) => handleChange("RFCFigura", e.target.value)}
              value={data.RFCFigura}
              required
              fullWidth
            />
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              label="Número de licencia"
              onChange={(e) => handleChange("NumLicencia", e.target.value)}
              value={data.NumLicencia}
              required
              fullWidth
            />
            <Box mt={2}>
              <Typography>
                <b>Domicilio</b>
              </Typography>
            </Box>
            <Box display="flex" gridGap={10}>
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="Calle"
                onChange={(e) => handleChangeDom("Calle", e.target.value)}
                value={data.Domicilio.Calle}
                required
                fullWidth
              />
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="# Ext"
                onChange={(e) =>
                  handleChangeDom("NumeroExterior", e.target.value)
                }
                value={data.Domicilio.NumeroExterior}
              />
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="# Int"
                onChange={(e) =>
                  handleChangeDom("NumeroInterior", e.target.value)
                }
                value={data.Domicilio.NumeroInterior}
              />
            </Box>
            <Box display="flex" gridGap={10}>
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="Colonia"
                onChange={(e) => handleChangeDom("Colonia", e.target.value)}
                value={data.Domicilio.Colonia}
                fullWidth
              />
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="Localidad"
                onChange={(e) => handleChangeDom("Localidad", e.target.value)}
                value={data.Domicilio.Localidad}
              />
              <TextField
                variant="outlined"
                size="small"
                margin="dense"
                label="CP"
                onChange={(e) =>
                  handleChangeDom("CodigoPostal", e.target.value)
                }
                value={data.Domicilio.CodigoPostal}
              />
            </Box>
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              label="Referencia"
              onChange={(e) => handleChangeDom("Referencia", e.target.value)}
              value={data.Domicilio.Referencia}
              fullWidth
            />
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              label="Municipio"
              onChange={(e) => handleChangeDom("Municipio", e.target.value)}
              value={data.Domicilio.Municipio}
              fullWidth
            />
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              label="Estado"
              onChange={(e) => handleChangeDom("Estado", e.target.value)}
              value={data.Domicilio.Estado}
              fullWidth
            />
            <TextField
              variant="outlined"
              size="small"
              margin="dense"
              label="Pais"
              onChange={(e) => handleChangeDom("Pais", e.target.value)}
              value={data.Domicilio.Pais}
              fullWidth
            />
          </form>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
