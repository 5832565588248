import { Impresora } from "../../../../components/Perifericos/TicketPrinter/Impresora";
import { formatoMexico } from "../../../../config/reuserFunctions";
import moment from "moment-timezone";
const RUTA_API = "http://localhost:8000";

export const ImpDepositosRetiros = async (sesion, data) => {
  try {
    const { direccion } = sesion.sucursal;
    const address = `${direccion.calle} #${direccion.no_ext}, Col. ${direccion.colonia}`;
    const location = `${direccion.municipio}, ${direccion.estado}, ${direccion.pais}`;

    let impresora = new Impresora(RUTA_API);
    impresora.cut();
    impresora.setFontSize(1, 1);
    impresora.setEmphasize(0);
    impresora.setAlign("center");
    impresora.write("Punto de venta CAFI\n");
    impresora.feed(1);
    impresora.write(`${sesion.empresa.nombre_empresa}\n`);
    impresora.write(`Sucursal: ${sesion.sucursal.nombre_sucursal}\n`);
    impresora.write(`RFC: ${sesion.empresa.rfc}\n`);
    impresora.write(`${address}\n`);
    impresora.write(`${location}\n`);
    impresora.feed(1);
    impresora.write(
      `Fecha: ${moment()
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY")} Hora: ${moment()
        .tz("America/Mexico_City")
        .format("LTS")}\n`
    );
    impresora.write(`Usuario: ${data.nombre_usuario_creador}\n`);
    impresora.write(
      `No. usuario: ${data.numero_usuario_creador} Caja: ${data.numero_caja}\n`
    );
    impresora.write(`Turno: ${data.horario_turno}\n`);
    impresora.feed(1);
    impresora.setFontSize(2, 1);
    impresora.write(`${data.tipo_movimiento} en caja\n`);
    impresora.feed(1);
    impresora.setFontSize(1, 1);
    impresora.write(`Concepto: ${data.concepto}\n`);
    impresora.setFontSize(2, 1);
    impresora.write(
      `Monto: ${formatoMexico(data.montos_en_caja.monto_efectivo.monto)}\n`
    );
    impresora.feed(3);
    impresora.cut();
    impresora.cutPartial(); // Pongo este y también cut porque en ocasiones no funciona con cut, solo con cutPartial
    await impresora.end();
    return { succes: true, error: false, message: "OK" };
  } catch (error) {
    console.log(error);
    return { succes: false, error: true, message: error };
  }
};
