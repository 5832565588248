import {
  amber,
  blue,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@material-ui/core/colors";

export const colors = [
  { name: "red", color: red },
  { name: "pink", color: pink },
  { name: "purple", color: purple },
  { name: "deepPurple", color: deepPurple },
  { name: "indigo", color: indigo },
  { name: "blue", color: blue },
  { name: "cyan", color: cyan },
  { name: "lightBlue", color: lightBlue },
  { name: "teal", color: teal },
  { name: "green", color: green },
  { name: "lightGreen", color: lightGreen },
  { name: "lime", color: lime },
  { name: "yellow", color: yellow },
  { name: "amber", color: amber },
  { name: "orange", color: orange },
  { name: "deepOrange", color: deepOrange },
];

export const shades = [
  { value: 1, label: "50" },
  { value: 2, label: "100" },
  { value: 3, label: "A100" },
  { value: 4, label: "200" },
  { value: 5, label: "A200" },
  { value: 6, label: "300" },
  { value: 7, label: "400" },
  { value: 8, label: "A400" },
  { value: 9, label: "500" },
  { value: 10, label: "600" },
  { value: 11, label: "700" },
  { value: 12, label: "A700" },
  { value: 13, label: "800" },
  { value: 14, label: "900" },
];
