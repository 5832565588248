export const tipoRemolque = [
  { value: "CTR001", name: "Caballete" },
  { value: "CTR002", name: "Caja" },
  { value: "CTR003", name: "Caja Abierta" },
  { value: "CTR004", name: "Caja Cerrada" },
  { value: "CTR005", name: "Caja De Recolección Con Cargador Frontal" },
  { value: "CTR006", name: "Caja Refrigerada" },
  { value: "CTR007", name: "Caja Seca" },
  { value: "CTR008", name: "Caja Transferencia" },
  { value: "CTR009", name: "Cama Baja o Cuello Ganso" },
  { value: "CTR010", name: "Chasis Portacontenedor" },
  { value: "CTR011", name: "Convencional De Chasis" },
  { value: "CTR012", name: "Equipo Especial" },
  { value: "CTR013", name: "Estacas" },
  { value: "CTR014", name: "Góndola Madrina" },
  { value: "CTR015", name: "Grúa Industrial" },
  { value: "CTR016", name: "Grúa" },
  { value: "CTR017", name: "Integral" },
  { value: "CTR018", name: "Jaula" },
  { value: "CTR019", name: "Media Redila" },
  { value: "CTR020", name: "Pallet o Celdillas" },
  { value: "CTR021", name: "Plataforma" },
  { value: "CTR022", name: "Plataforma Con Grúa" },
  { value: "CTR023", name: "Plataforma Encortinada" },
  { value: "CTR024", name: "Redilas" },
  { value: "CTR025", name: "Refrigerador" },
  { value: "CTR026", name: "Revolvedora" },
  { value: "CTR027", name: "STanque" },
  { value: "CTR029", name: "Tolva" },
  { value: "CTR031", name: "Volteo" },
  { value: "CTR032", name: "Volteo Desmontable" },
];

export const confAutotransporte = [
  {
    value: "VL",
    name:
      "Vehículo ligero de carga (2 llantas en el eje delantero y 2 llantas en el eje trasero)",
  },
  {
    value: "C2",
    name:
      "Camión Unitario (2 llantas en el eje delantero y 4 llantas en el eje trasero)",
  },
  {
    value: "C3",
    name:
      "Camión Unitario (2 llantas en el eje delantero y 6 o 8 llantas en los dos ejes traseros)",
  },
  {
    value: "C2R2",
    name: "Camión-Remolque (6 llantas en el camión y 8 llantas en remolque)",
  },
  {
    value: "C3R2",
    name: "Camión-Remolque (10 llantas en el camión y 8 llantas en remolque)",
  },
  {
    value: "C2R3",
    name: "Camión-Remolque (6 llantas en el camión y 12 llantas en remolque)",
  },
  {
    value: "C3R3",
    name: "Camión-Remolque (10 llantas en el camión y 12 llantas en remolque)",
  },
  {
    value: "T2S1",
    name:
      "Tractocamión Articulado (6 llantas en el tractocamión, 4 llantas en el semirremolque)",
  },
  {
    value: "T2S2",
    name:
      "Tractocamión Articulado (6 llantas en el tractocamión, 8 llantas en el semirremolque)",
  },
  {
    value: "T2S3",
    name:
      "Tractocamión Articulado (6 llantas en el tractocamión, 12 llantas en el semirremolque)",
  },
  {
    value: "T3S1",
    name:
      "Tractocamión Articulado (10 llantas en el tractocamión, 4 llantas en el semirremolque)",
  },
  {
    value: "T3S2",
    name:
      "Tractocamión Articulado (10 llantas en el tractocamión, 8 llantas en el semirremolque)",
  },
  {
    value: "T3S3",
    name:
      "Tractocamión Articulado (10 llantas en el tractocamión, 12 llantas en el semirremolque)",
  },
  {
    value: "T2S1R2",
    name:
      "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)",
  },
  {
    value: "T2S2R2",
    name:
      "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)",
  },
  {
    value: "T2S1R3",
    name:
      "Tractocamión Semirremolque-Remolque (6 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)",
  },
  {
    value: "T3S1R2",
    name:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 8 llantas en el remolque)",
  },
  {
    value: "T3S1R3",
    name:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 4 llantas en el semirremolque y 12 llantas en el remolque)",
  },
  {
    value: "T3S2R2",
    name:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 8 llantas en el remolque)",
  },
  {
    value: "T3S2R3",
    name:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 12 llantas en el remolque)",
  },
  {
    value: "T3S2R4",
    name:
      "Tractocamión Semirremolque-Remolque (10 llantas en el tractocamión, 8 llantas en el semirremolque y 16 llantas en el remolque)",
  },
  {
    value: "T2S2S2",
    name:
      "Tractocamión Semirremolque-Semirremolque (6 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
  },
  {
    value: "T3S2S2",
    name:
      "Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 8 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
  },
  {
    value: "T3S3S2",
    name:
      "Tractocamión Semirremolque-Semirremolque (10 llantas en el tractocamión, 12 llantas en el semirremolque delantero y 8 llantas en el semirremolque trasero)",
  },
  {
    value: "OTROEVGP",
    name: "Especializado de carga Voluminosa y/o Gran Peso",
  },
  { value: "OTROSG", name: "Servicio de Grúas" },
  { value: "GPLUTA", name: "Grúa de Pluma Tipo" },
  { value: "GPLUTB", name: "Grúa de Pluma Tipo" },
  { value: "GPLUTC", name: "Grúa de Pluma Tipo" },
  { value: "GPLUTD", name: "Grúa de Pluma Tipo" },
  { value: "GPLATA", name: "Grúa de Plataforma Tipo" },
  { value: "GPLATB", name: "Grúa de Plataforma Tipo" },
  { value: "GPLATC", name: "Grúa de Plataforma Tipo" },
  { value: "GPLATD", name: "Grúa de Plataforma Tipo" },
];

export const tipoPermiso = [
  { value: "TPAF01", name: "Autotransporte Federal de carga general." },
  { value: "TPAF02", name: "Transporte privado de carga." },
  {
    value: "TPAF03",
    name:
      "Autotransporte Federal de Carga Especializada de materiales y residuos peligrosos.",
  },
  {
    value: "TPAF04",
    name: "Transporte de automóviles sin rodar en vehículo tipo góndola.",
  },
  {
    value: "TPAF05",
    name: "Transporte de carga de gran peso y/o volumen de hasta 90 toneladas.",
  },
  {
    value: "TPAF06",
    name:
      "Transporte de carga especializada de gran peso y/o volumen de más 90 toneladas.",
  },
  {
    value: "TPAF07",
    name: "Transporte Privado de materiales y residuos peligrosos.",
  },
  {
    value: "TPAF08",
    name: "Autotransporte internacional de carga de largo recorrido.",
  },
  {
    value: "TPAF09",
    name:
      "Autotransporte internacional de carga especializada de materiales y residuos peligrosos de largo recorrido.",
  },
  {
    value: "TPAF10",
    name:
      "Autotransporte Federal de Carga General cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.",
  },
  {
    value: "TPAF11",
    name:
      "Autotransporte Federal de Carga Especializada cuyo ámbito de aplicación comprende la franja fronteriza con Estados Unidos.",
  },
  {
    value: "TPAF12",
    name:
      "Servicio auxiliar de arrastre en las vías generales de comunicación.",
  },
  {
    value: "TPAF13",
    name:
      "Servicio auxiliar de servicios de arrastre, arrastre y salvamento, y depósito de vehículos en las vías generales de comunicación.",
  },
  {
    value: "TPAF14",
    name:
      "Servicio de paquetería y mensajería en las vías generales de comunicación.",
  },
  {
    value: "TPAF15",
    name:
      "Transporte especial para el tránsito de grúas industriales con peso máximo de 90 toneladas.",
  },
  {
    value: "TPAF16",
    name:
      "Servicio federal para empresas arrendadoras servicio público federal.",
  },
  { value: "TPAF17", name: "Empresas trasladistas de vehículos nuevos." },
  {
    value: "TPAF18",
    name: "Empresas fabricantes o distribuidoras de vehículos nuevos.",
  },
  {
    value: "TPAF19",
    name:
      "Autorización expresa para circular en los caminos y puentes de jurisdicción federal con configuraciones de tractocamión doblemente articulado.",
  },
  {
    value: "TPAF20",
    name: "Autotransporte Federal de Carga Especializada de fondos y valores.",
  },
  { value: "TPTM01", name: "Permiso temporal para navegación de cabotaje" },
  {
    value: "TPTA01",
    name:
      "Concesión y/o autorización para el servicio regular nacional y/o internacional para empresas mexicanas",
  },
  {
    value: "TPTA02",
    name: "Permiso para el servicio aéreo regular de empresas extranjeras",
  },
  {
    value: "TPTA03",
    name:
      "Permiso para el servicio nacional e internacional no regular de fletamento",
  },
  {
    value: "TPTA04",
    name:
      "Permiso para el servicio nacional e internacional no regular de taxi aéreo",
  },
  { value: "TPXX00", name: "Permiso no contemplado en el catálogo." },
];
