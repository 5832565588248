import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

export default function SelectArticulos({
  tipoArticulo,
  setTipoArticulo,
  attr,
}) {
  const handleChange = (tipo) => setTipoArticulo({ tipo, filtro: "" });
  const handleFiltro = (filtro) => setTipoArticulo((st) => ({ ...st, filtro }));

  return (
    <div>
      <FormControl variant="outlined" size="small" fullWidth margin="dense">
        <InputLabel id="tipp-banner-select">Artículos a mostrar</InputLabel>
        <Select
          labelId="tipp-banner-select"
          value={tipoArticulo.tipo}
          onChange={(e) => handleChange(e.target.value)}
          label="articulos a mostrar"
        >
          <MenuItem value="">
            <em>Selecciona una opción</em>
          </MenuItem>
          <MenuItem value="DESCUENTO">Artículos con descuento</MenuItem>
          <MenuItem value="CATEGORIA">Artículos por categoria</MenuItem>
          <MenuItem value="SUBCATEGORIA">Artículos por subcategoria</MenuItem>
          <MenuItem value="DEPARTAMENTO">Artículos por departamento</MenuItem>
          <MenuItem value="MARCA">Artículos por marca</MenuItem>
          <MenuItem value="TIPO">Artículos por tipo de producto</MenuItem>
        </Select>
      </FormControl>
      {tipoArticulo.tipo && tipoArticulo.tipo !== "DESCUENTO" ? (
        attr.data ? (
          <CustomSelect
            tipo={tipoArticulo}
            attr={attr.data}
            handleFiltro={handleFiltro}
          />
        ) : null
      ) : null}
    </div>
  );
}

const CustomSelect = ({ tipo, attr, handleFiltro }) => {
  switch (tipo.tipo) {
    case "CATEGORIA":
      return (
        <FormControl variant="outlined" size="small" fullWidth margin="dense">
          <InputLabel id="tipp-banner-select-second">{tipo.tipo}</InputLabel>
          <Select
            labelId="tipp-banner-select-second"
            value={tipo.filtro}
            onChange={(e) => handleFiltro(e.target.value)}
            label={tipo.tipo}
          >
            <MenuItem value="">
              <em>Selecciona una opción</em>
            </MenuItem>
            {attr.categorias.map((res, index) => (
              <MenuItem key={index} value={res.categoria}>
                {res.categoria}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "SUBCATEGORIA":
      return (
        <FormControl variant="outlined" size="small" fullWidth margin="dense">
          <InputLabel id="tipp-banner-select-second">{tipo.tipo}</InputLabel>
          <Select
            labelId="tipp-banner-select-second"
            value={tipo.filtro}
            onChange={(e) => handleFiltro(e.target.value)}
            label={tipo.tipo}
          >
            <MenuItem value="">
              <em>Selecciona una opción</em>
            </MenuItem>
            {attr.subcategorias.map((res, index) => (
              <MenuItem key={index} value={res.subcategoria}>
                {res.subcategoria}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "MARCA":
      return (
        <FormControl variant="outlined" size="small" fullWidth margin="dense">
          <InputLabel id="tipp-banner-select-second">{tipo.tipo}</InputLabel>
          <Select
            labelId="tipp-banner-select-second"
            value={tipo.filtro}
            onChange={(e) => handleFiltro(e.target.value)}
            label={tipo.tipo}
          >
            <MenuItem value="">
              <em>Selecciona una opción</em>
            </MenuItem>
            {attr.marcas.map((res, index) => (
              <MenuItem key={index} value={res.nombre_marca}>
                {res.nombre_marca}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "DEPARTAMENTO":
      return (
        <FormControl variant="outlined" size="small" fullWidth margin="dense">
          <InputLabel id="tipp-banner-select-second">{tipo.tipo}</InputLabel>
          <Select
            labelId="tipp-banner-select-second"
            value={tipo.filtro}
            onChange={(e) => handleFiltro(e.target.value)}
            label={tipo.tipo}
          >
            <MenuItem value="">
              <em>Selecciona una opción</em>
            </MenuItem>
            {attr.departamentos.map((res, index) => (
              <MenuItem key={index} value={res.nombre_departamentos}>
                {res.nombre_departamentos}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    case "TIPO":
      return (
        <FormControl variant="outlined" size="small" fullWidth margin="dense">
          <InputLabel id="tipp-banner-select-second">{tipo.tipo}</InputLabel>
          <Select
            labelId="tipp-banner-select-second"
            value={tipo.filtro}
            onChange={(e) => handleFiltro(e.target.value)}
            label={tipo.tipo}
          >
            <MenuItem value="">
              <em>Selecciona una opción</em>
            </MenuItem>
            {["OTROS", "ROPA", "CALZADO"].map((res, index) => (
              <MenuItem key={index} value={res}>
                {res}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    default:
      return null;
  }
};
