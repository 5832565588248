import React, { useEffect, useState } from "react";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../../../config/reuserFunctions";
import { useQuery } from "@apollo/client";
import { OBTENER_HISTORIAL_ABONOS_CLIENTE } from "../../../../../../gql/Tesoreria/abonos";
import ExcelExportButton from "../../../../../../components/ExcelExportButton";

function ExportarCuentasPorCobrar(props) {
  const [dataExcel, setDataExcel] = useState([]);
  const obtenerVentasCredito = useQuery(OBTENER_HISTORIAL_ABONOS_CLIENTE, {
    variables: {
      empresa: props.empresa,
      sucursal: "",
      //idCliente: selectedClient._id,
      filtro: props.value,
      page: 0,
      offset: 0,
    },
    fetchPolicy: "network-only",
  });

  if (obtenerVentasCredito.error) {
    console.log(obtenerVentasCredito.error);

    if (obtenerVentasCredito.error.networkError.result) {
      console.log(obtenerVentasCredito.error.networkError.result.errors);
    } else if (obtenerVentasCredito.error.graphQLErrors) {
      console.log(obtenerVentasCredito.error.graphQLErrors.message);
    }
  }
  useEffect(() => {
    try {
      if (obtenerVentasCredito.data) {
        let arrayToExcel = [];

        if (obtenerVentasCredito.data.historialVentasACredito.docs.length > 0) {
          obtenerVentasCredito.data.historialVentasACredito.docs.forEach(
            (element) => {
              arrayToExcel.push({
                folio: element.folio,
                fecha: formatoFechaCorta(element.fecha_registro),
                fecha_vencimiento: formatoFechaCorta(
                  element.fecha_de_vencimiento_credito
                ),
                cliente: element.cliente.nombre_cliente,
                total_venta: "$" + formatoMexico(element.total),
                falta_pagar:
                  "$" + formatoMexico(element.saldo_credito_pendiente),
              });
            }
          );

          setDataExcel(arrayToExcel);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [obtenerVentasCredito.data]);

  return (
    <ExcelExportButton
      data={dataExcel}
      name="Lista de cuentas por cobrar"
      button
    />
  );
}

export default ExportarCuentasPorCobrar;
