import * as React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TableSortLabel } from "@material-ui/core";

const headCells = [
  {
    id: "fecha_registro",
    padding: "normal",
    align: "left",
    label: "Fecha",
    sortable: true,
    style: { minWidth: 180 },
  },
  {
    id: "nombre",
    padding: "normal",
    align: "left",
    label: "Usuario",
    sortable: false,
    style: { minWidth: 230 },
  },
  {
    id: "traspasoEntreSucursales",
    padding: "normal",
    align: "left",
    label: "Entre sucursales",
    sortable: false,
    style: { minWidth: 160 },
  },
  {
    id: "aceptado",
    padding: "normal",
    align: "left",
    label: "Aceptado",
    sortable: false,
    style: { minWidth: 100 },
  },
  {
    id: "nombre_concepto",
    padding: "normal",
    align: "left",
    label: "Concepto",
    sortable: false,
    style: { minWidth: 210 },
  },
  {
    id: "origen",
    padding: "normal",
    align: "left",
    label: "Origen",
    sortable: false,
    style: { minWidth: 250 },
  },
  {
    id: "destino",
    padding: "normal",
    align: "left",
    label: "Destino",
    sortable: false,
    style: { minWidth: 250 },
  },
];

export default function TraspasoTableHead({ handleSort, order, orderBy }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            style={headCell.style}
            sortDirection={orderBy === headCell.id ? order : false}
            padding={headCell.padding}
            align={headCell.align}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={() => handleSort(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
