import React, { useState, Fragment } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/styles";
import { OBTENER_USUARIOS } from "../../../../gql/Catalogos/usuarios";
import { ArrowBack, ClearOutlined } from "@material-ui/icons";
import ExportarTraspasos from "./ExportarTraspasos";
import TablaAlmacenFiltradas from "./TablaAlmacenFiltradas";
import { useQuery } from "@apollo/client";

import {
  OBTENER_ALMACENES,
  OBTENER_TRASPASOS,
} from "../../../../gql/Almacenes/Almacen";
import { useDebounce } from "use-debounce";
import ComponentOnline from "../../../../components/Connection/ComponentOnline";
import { FcSurvey } from "react-icons/fc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "15px",
    marginTop: "5px",
  },
  icon: {
    width: 100,
  },
  CircularProgress: {
    width: "100%",
    height: 200,
    marginTop: 100,
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    alignItems: "flex-end",
  },
  fullScreen: {
    height: "100vh",
  },
  marginT: {
    marginTop: "30px",
  },
}));

export default function ReportesAlmacen() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = useState(false);
  const [inputFilter, setFilter] = useState({
    empresa: sesion.empresa._id,
    sucursal: sesion.sucursal._id,
    fecha_inicio: "",
    fecha_final: "",
    producto: "",
    usuario: "",
    almacen_origen: "",
    almacen_destino: "",
  });
  const [value] = useDebounce(inputFilter, 1000);
  const [page, setPage] = useState(0);
  const limit = 20;
  const [filterUser, setFilterUser] = useState(null);
  let traspasos = { docs: [], totalDocs: 0 };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const traspasosAlmacenes = useQuery(OBTENER_TRASPASOS, {
    variables: {
      input: inputFilter,
      limit,
      offset: page,
    },
    fetchPolicy: "network-only",
  });

  const limpiarFiltros = () => {
    try {
      let filt = {
        empresa: sesion.empresa._id,
        sucursal: sesion.sucursal._id,
        producto: "",
        fecha_inicio: "",
        fecha_final: "",
        usuario: "",
        almacen_origen: "",
        almacen_destino: "",
      };

      setFilter(filt);
      setLoading(true);
      traspasosAlmacenes.refetch({
        input: filt,
      });
      setLoading(false);
      setPage(0);
      setFilterUser(null);
    } catch (error) {}
  };

  return (
    <>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcSurvey style={{ fontSize: 100 }} />
          </Box>
          Reportes Almacen
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
        classes={{
          scrollPaper: classes.paper,
          paperFullScreen: classes.fullScreen,
        }}
        hideBackdrop={true}
        PaperProps={{ elevation: 0 }}
      >
        <Typography variant="h6" className={classes.title}>
          Reportes Almacen
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <ComponentOnline classes />
          <Box mx={1} />
          <Button
            color="primary"
            startIcon={<ClearOutlined />}
            onClick={() => limpiarFiltros()}
          >
            Limpiar filtros
          </Button>
          <Box mx={1} />
          <ExportarTraspasos datos={inputFilter} />
          <Box mx={1} />
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <RenderComponentQuery
          sesion={sesion}
          inputFilter={inputFilter}
          setFilter={setFilter}
          loading={loading}
          setLoading={setLoading}
          value={value}
          limit={limit}
          page={page}
          setPage={setPage}
          traspasos={traspasos}
          traspasosAlmacenes={traspasosAlmacenes}
          setFilterUser={setFilterUser}
          filterUser={filterUser}
        />
      </Dialog>
    </>
  );
}

const RenderComponentQuery = ({
  sesion,
  inputFilter,
  setFilter,
  loading,
  setLoading,
  value,
  limit,
  page,
  setPage,
  traspasos,
  traspasosAlmacenes,
  setFilterUser,
  filterUser,
}) => {
  const classes = useStyles();

  let almacenes = [];
  let usuarios = [];

  const queryObtenerAlmacenes = useQuery(OBTENER_ALMACENES, {
    variables: {
      id: sesion.sucursal._id,
    },
    fetchPolicy: "network-only",
  });

  const queryObtenerUsuarios = useQuery(OBTENER_USUARIOS, {
    variables: {
      empresa: `${sesion.empresa._id}`,
      sucursal: `${sesion.sucursal._id}`,
      eliminado: false,
    },
    fetchPolicy: "network-only",
  });

  if (queryObtenerAlmacenes.data) {
    almacenes = queryObtenerAlmacenes.data.obtenerAlmacenes;
  }

  const setDatosInput = (e) => {
    const { name, value } = e.target;
    let fil = {
      ...inputFilter,
      [name]: value,
    };
    setFilter(fil);
    setPage(0);
  };

  const handleSelectUser = (e, value) => {
    setFilterUser(value);
    let fil = {
      ...inputFilter,
      usuario: value === null ? "" : value._id,
    };
    setFilter(fil);
    setPage(0);
  };

  if (traspasosAlmacenes.data) {
    try {
      traspasos = traspasosAlmacenes.data.obtenerTraspasos;
    } catch (error) {}
  }

  if (queryObtenerUsuarios.data) {
    try {
      usuarios = queryObtenerUsuarios.data.obtenerUsuarios;
    } catch (error) {}
  }

  return (
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item md={2} xs={12}>
          <Typography>Fecha inicio:</Typography>
          <TextField
            fullWidth
            size="small"
            name="fecha_inicio"
            variant="outlined"
            value={inputFilter.fecha_inicio}
            type="date"
            onChange={(e) => setDatosInput(e)}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography>Fecha fin:</Typography>
          <TextField
            fullWidth
            size="small"
            name="fecha_final"
            variant="outlined"
            type="date"
            onChange={(e) => setDatosInput(e)}
            value={inputFilter.fecha_final}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography>Encargado</Typography>
          <Box display="flex">
            <Autocomplete
              id="usuario"
              size="small"
              options={usuarios}
              getOptionLabel={(option) =>
                option.nombre ? `${option.nombre}` : ""
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>{params.InputProps.endAdornment}</Fragment>
                    ),
                  }}
                />
              )}
              renderOption={(option) => (
                <Fragment>{`${option.nombre}`}</Fragment>
              )}
              onChange={handleSelectUser}
              getOptionSelected={(option, value) =>
                option.nombre === value.nombre
              }
              value={filterUser}
            />
          </Box>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography>Producto:</Typography>
          <TextField
            fullWidth
            size="small"
            name="producto"
            variant="outlined"
            placeholder="Nombre, código, clave..."
            onChange={(e) => setDatosInput(e)}
            value={inputFilter.producto}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography>Almacen origen:</Typography>
          <FormControl variant="outlined" fullWidth size="small">
            <Select
              id="form-almacen-origen"
              name="almacen_origen"
              onChange={(e) => setDatosInput(e)}
              value={
                inputFilter.almacen_origen !== null
                  ? inputFilter.almacen_origen
                  : ""
              }
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {almacenes.map((almacen) => (
                <MenuItem key={almacen._id} value={almacen._id}>
                  {almacen.nombre_almacen}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2} xs={12}>
          <Typography>Almacen destino:</Typography>
          <FormControl variant="outlined" fullWidth size="small">
            <Select
              id="form-almacen-destino"
              name="almacen_destino"
              onChange={(e) => setDatosInput(e)}
              value={
                inputFilter.almacen_destino !== null
                  ? inputFilter.almacen_destino
                  : ""
              }
            >
              <MenuItem value="">
                <em>Ninguno</em>
              </MenuItem>
              {almacenes.map((almacen) => (
                <MenuItem key={almacen._id} value={almacen._id}>
                  {almacen.nombre_almacen}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box className={classes.marginT}>
        {traspasosAlmacenes.loading || loading ? (
          <div className={classes.CircularProgress}>
            <CircularProgress />
          </div>
        ) : (
          <TablaAlmacenFiltradas
            data={traspasos}
            page={page}
            setPage={setPage}
            limit={limit}
          />
        )}
      </Box>
    </DialogContent>
  );
};
