import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { ReceiptOutlined } from "@material-ui/icons";

export default function AgregarTipoVenta() {
  const { updateTablaVentas, setUpdateTablaVentas } = useContext(VentasContext);
  const venta = JSON.parse(localStorage.getItem("DatosVentas"));
  const [tipo_emision, setTipoEmision] = useState("TICKET");

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const obtenerTipoEmision = (value) => {
    setTipoEmision(value);
    localStorage.setItem(
      "DatosVentas",
      JSON.stringify({ ...venta, tipo_emision: value })
    );
    setUpdateTablaVentas(!updateTablaVentas);
    handleClose();
  };

  return (
    <>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-ticket" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        startIcon={<ReceiptOutlined fontSize="small" />}
        disabled={!venta || venta === null}
        disableElevation
        size="small"
        variant="contained"
        style={{textTransform: "none", marginLeft: 8}}
        color="default"
      >
        {tipo_emision}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-ticket"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem onClick={() => obtenerTipoEmision("SIN TICKET")}>
                    No ticket
                  </MenuItem>
                  <MenuItem onClick={() => obtenerTipoEmision("TICKET")}>
                    Ticket
                  </MenuItem>
                  <MenuItem onClick={() => obtenerTipoEmision("FACTURA")}>
                    Factura
                  </MenuItem>
                  <MenuItem onClick={() => obtenerTipoEmision("NOTA REMISION")}>
                    Nota remisión
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
