import React, { useState } from "react";
import { Button, Box } from "@material-ui/core";
import { RiFileExcel2Line } from "react-icons/ri";
import ImportXlsButtonMenu from "./ImportXlsButtonMenu";
import { AccesosContext } from "../../../../../context/Accesos/accesosCtx";

function ImportXlsButton() {
    const { isOnline } = React.useContext(AccesosContext);
  const [openMenu, setOpenMenu] = useState(null);
  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };
  return (
    <Box display="flex" alignItems={"center"}>
      <Button
        variant="text"
        color="primary"
        startIcon={<RiFileExcel2Line />}
        onClick={handleClick}
        disabled={!isOnline}
      >
        Importar
      </Button>
      <ImportXlsButtonMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
    </Box>
  );
}

export default ImportXlsButton;
