import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { useDropzone } from "react-dropzone";

const useStyles = makeStyles((theme) => ({
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    border: "dashed 2px #000000",
    textAlign: "center",
  },
  img: {
    width: "100%",
    height: "100%",
  },
}));

export default function PresetsCardsEditable({
  type,
  bigBanner,
  setBigBanner,
  sideBanner,
  setSideBanner,
}) {
  //dropzone
  const onDrop = React.useCallback(
    (acceptedFiles) => {
      let reader = new FileReader();
      reader.readAsDataURL(acceptedFiles[0]);
      reader.onload = function () {
        let image = reader.result;
        if (type === "CARDS_IMAGE_LEFT" || type === "CARDS_IMAGE_RIGHT") {
          setSideBanner({
            image: acceptedFiles[0],
            preview: image,
          });
        } else {
          setBigBanner({
            image: acceptedFiles[0],
            preview: image,
          });
        }
      }; 
    },
    [sideBanner, bigBanner, setSideBanner, setSideBanner]
  );

  switch (type) {
    case "ONLY_BANNER":
      return <OnlyBanner onDrop={onDrop} bigBanner={bigBanner} />;
    case "BANNER_WITH_CARDS":
      return <BannerWithCards onDrop={onDrop} bigBanner={bigBanner} />;
    case "ONLY_CARDS":
      return <OnlyCards />;
    case "CARDS_IMAGE_LEFT":
      return <CardsImageLeft onDrop={onDrop} sideBanner={sideBanner} />;
    case "CARDS_IMAGE_RIGHT":
      return <CardsImageRight onDrop={onDrop} sideBanner={sideBanner} />;
    default:
      return null;
  }
}

export const OnlyBanner = ({ onDrop, bigBanner }) => {
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop,
  });
  return (
    <Box width="100%" px={3} py={1}>
      <Box
        className={classes.imgContainer}
        borderRadius={5}
        height={120}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        {bigBanner.preview ? (
          <img
            alt="banner principal"
            className={classes.img}
            src={`${bigBanner.preview}`}
          />
        ) : isDragActive ? (
          <p>Suelta tu imagen aqui ...</p>
        ) : (
          <p>
            Arrastra y suelta tu imagen aquí, o haz clic para seleccionar alguna
          </p>
        )}
      </Box>
    </Box>
  );
};
export const BannerWithCards = ({ onDrop, bigBanner }) => {
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop,
  });
  return (
    <Box width="100%" px={3} py={1}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box
            className={classes.imgContainer}
            borderRadius={5}
            height={120}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {bigBanner.preview ? (
              <img
                alt="banner principal"
                className={classes.img}
                src={`${bigBanner.preview}`}
              />
            ) : isDragActive ? (
              <p>Suelta tu imagen aqui ...</p>
            ) : (
              <p>
                Arrastra y suelta tu imagen aquí, o haz clic para seleccionar
                alguna
              </p>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box width="100%" height={75} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={3}>
          <Box width="100%" height={75} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={3}>
          <Box width="100%" height={75} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={3}>
          <Box width="100%" height={75} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
      </Grid>
    </Box>
  );
};
export const OnlyCards = () => (
  <Box width="100%" px={3} py={1}>
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Box width="100%" height={75} borderRadius={5} bgcolor="#0000001C" />
      </Grid>
      <Grid item xs={3}>
        <Box width="100%" height={75} borderRadius={5} bgcolor="#0000001C" />
      </Grid>
      <Grid item xs={3}>
        <Box width="100%" height={75} borderRadius={5} bgcolor="#0000001C" />
      </Grid>
      <Grid item xs={3}>
        <Box width="100%" height={75} borderRadius={5} bgcolor="#0000001C" />
      </Grid>
    </Grid>
  </Box>
);
export const CardsImageLeft = ({ onDrop, sideBanner }) => {
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop,
  });
  return (
    <Box width="100%" px={3} py={1}>
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Box
            className={classes.imgContainer}
            borderRadius={5}
            height={140}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {sideBanner.preview ? (
              <img
                alt="banner principal"
                className={classes.img}
                src={`${sideBanner.preview}`}
              />
            ) : isDragActive ? (
              <p>Suelta tu imagen aqui ...</p>
            ) : (
              <p>
                Arrastra y suelta tu imagen aquí, o haz clic para seleccionar
                alguna
              </p>
            )}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box width="100%" height={120} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={2}>
          <Box width="100%" height={120} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={2}>
          <Box width="100%" height={120} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={1}>
          <Box width="100%" height={120} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
      </Grid>
    </Box>
  );
};
export const CardsImageRight = ({ onDrop, sideBanner }) => {
  const classes = useStyles();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/jpeg, image/png",
    noKeyboard: true,
    onDrop,
  });
  return (
    <Box width="100%" px={3} py={1}>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Box width="100%" height={120} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={2}>
          <Box width="100%" height={120} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={2}>
          <Box width="100%" height={120} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={2}>
          <Box width="100%" height={120} borderRadius={5} bgcolor="#0000001C" />
        </Grid>
        <Grid item xs={5}>
          <Box
            className={classes.imgContainer}
            borderRadius={5}
            height={140}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {sideBanner.preview ? (
              <img
                alt="banner principal"
                className={classes.img}
                src={`${sideBanner.preview}`}
              />
            ) : isDragActive ? (
              <p>Suelta tu imagen aqui ...</p>
            ) : (
              <p>
                Arrastra y suelta tu imagen aquí, o haz clic para seleccionar
                alguna
              </p>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
