import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ArrowBack, Search } from "@material-ui/icons";
import { useApolloClient } from "@apollo/client";
import { FacturacionCtx } from "../../../../../../context/Facturacion/facturacionCtx";
import { useDebounce } from "use-debounce";
import { OBTENER_FACTURAS_REALIZADAS } from "../../../../../../gql/Facturacion/Facturacion";
import CfdisTable from "./CfdisTable";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initial_query = {
  data: undefined,
  loading: true,
  error: undefined,
};

const initial_filtros = {
  fecha: "",
  metodo_pago: "",
  forma_pago: "",
  busqueda: "",
};

export default function CfdisList({ setAlert }) {
  const client = useApolloClient();
  const [open, setOpen] = React.useState(false);
  const [cfdisQuery, setCfdisQuery] = React.useState(initial_query);
  const { isCfdiRelated } = React.useContext(FacturacionCtx);
  const [offset, setOffset] = React.useState(0);
  const limit = 20;
  const [filtro, setFiltro] = React.useState(initial_filtros);
  const [filtros] = useDebounce(filtro, 500);

  const getCfdis = async () => {
    try {
      const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
      const response = await client.query({
        query: OBTENER_FACTURAS_REALIZADAS,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: "",
          filtros,
          limit,
          offset,
        },
        fetchPolicy: "network-only",
      });
      if (response.data) {
        setCfdisQuery({
          loading: false,
          data: response.data.obtenerFacturas,
          error: undefined,
        });
      }
    } catch (error) {
      setCfdisQuery({
        loading: false,
        data: undefined,
        error,
      });
      setAlert({
        message: "Hubo un error al cargar los productos",
        status: "error",
        open: true,
      });
    }
  };

  const handleOpen = () => {
    setOpen(true);
    getCfdis();
  };
  const handleClose = () => setOpen(false);

  const obtenerCamposFiltro = (e) => {
    const { name, value } = e.target;
    setFiltro({
      ...filtro,
      [name]: value,
    });
  };

  const limpiarFiltros = () => {
    setOffset(0);
    setFiltro(initial_filtros);
  };

  React.useEffect(() => {
    getCfdis();
  }, [filtros, offset]);

  React.useEffect(() => {
    setOffset(0);
  }, [filtros]);

  return (
    <div>
      <IconButton
        onClick={() => handleOpen()}
        size="small"
        disabled={!isCfdiRelated}
      >
        <Search />
      </IconButton>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Últimos CFDIs</Typography>
            <Box flexGrow={1} />
            <Box display="flex" gridColumnGap={10}>
              <Button
                size="small"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={limpiarFiltros}
              >
                Borrar filtros
              </Button>
              <TextField
                style={{ width: 300 }}
                size="small"
                name="busqueda"
                variant="outlined"
                placeholder="Folio CFDi, Folio de venta, Cliente..."
                onChange={obtenerCamposFiltro}
                value={filtro.busqueda}
              />
              <TextField
                size="small"
                name="fecha"
                variant="outlined"
                type="date"
                onChange={obtenerCamposFiltro}
                value={filtro.fecha}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CfdisTable
            cfdisQuery={cfdisQuery}
            page={offset}
            setPage={setOffset}
            limit={limit}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
