import React from "react";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { deepPurple } from "@material-ui/core/colors";

export default function FilterChips({
  context = null,
  style = null,
  noShowValue = "",
}) {
  const { filter, setFilter } = React.useContext(context);
  const data = Object.keys(filter) || [];

  const handleDelete = (key) => {
    setFilter({
      ...filter,
      [key]: "",
    });
  };

  return (
    <Grid
      container
      spacing={1}
      style={{ ...style, alignItems: "center", padding: "4px 0px" }}
    >
      {data.map((key, indx) =>
        filter[key] ? (
          noShowValue !== key ? (
            <Grid item key={indx}>
              <Chip
                key={indx}
                label={filter[key]}
                size="small"
                onDelete={() => handleDelete(key)}
                style={{ backgroundColor: deepPurple[50] }}
              />
            </Grid>
          ) : null
        ) : null
      )}
    </Grid>
  );
}
