import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ArrowBack, Search } from "@material-ui/icons";
import { useApolloClient } from "@apollo/client";
import { useDebounce } from "use-debounce";
import { OBTENER_FACTURAS_REALIZADAS } from "../../../../../gql/Facturacion/Facturacion";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import moment from "moment-timezone";
import CustomLoader from "../../../../../components/CustomLoader";
import ErrorPage from "../../../../../components/ErrorPage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { label: "Fecha", minWidth: 90 },
  { label: "Folio Venta", minWidth: 90 },
  { label: "Serie", padding: "checkbox" },
  { label: "Folio", padding: "checkbox" },
  { label: "Tipo CDFI", minWidth: 110 },
  { label: "Receptor", minWidth: 100 },
  { label: "RFC", minWidth: 100 },
  { label: "Subtotal", minWidth: 90 },
  { label: "Total", minWidth: 100 },
];

const initial_query = {
  data: undefined,
  loading: true,
  error: undefined,
};

const initial_filtros = {
  fecha: "",
  metodo_pago: "",
  forma_pago: "",
  busqueda: "",
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: "45vh",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
}));

export default function CancelarCFDI({ setCanceledData, setAlert }) {
  const client = useApolloClient();
  const [open, setOpen] = React.useState(false);
  const [cfdisQuery, setCfdisQuery] = React.useState(initial_query);
  const [offset, setOffset] = React.useState(0);
  const limit = 20;
  const [filtro, setFiltro] = React.useState(initial_filtros);
  const [filtros] = useDebounce(filtro, 500);
  

  const getCfdis = async () => {
    try {
      const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
      const response = await client.query({
        query: OBTENER_FACTURAS_REALIZADAS,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: "",
          filtros,
          limit,
          offset,
        },
        fetchPolicy: "network-only",
      });
      if (response.data) {
        setCfdisQuery({
          loading: false,
          data: response.data.obtenerFacturas,
          error: undefined,
        });
      }
    } catch (error) {
      setCfdisQuery({
        loading: false,
        data: undefined,
        error,
      });
      setAlert({
        message: "Hubo un error al cargar los productos",
        status: "error",
        open: true,
      });
    }
  };

  const handleOpen = () => {
    setOpen(true);
    getCfdis();
  };
  const handleClose = () => setOpen(false);

  const obtenerCamposFiltro = (e) => {
    const { name, value } = e.target;
    setFiltro({
      ...filtro,
      [name]: value,
    });
  };

  const limpiarFiltros = () => {
    setOffset(0);
    setFiltro(initial_filtros);
  };

  const handleSelectFactura = (e, factura) => {
    if (e.detail === 2) {
      const uuidReplacement = factura.complement?.TaxStamp?.Uuid;
      setCanceledData((state) => ({
        ...state,
        uuidReplacement,
      }));
      handleClose();
    }
  };

  React.useEffect(() => {
    getCfdis();
  }, [filtros, offset]);

  React.useEffect(() => {
    setOffset(0);
  }, [filtros]);

  return (
    <div>
      <IconButton onClick={() => handleOpen()} size="small">
        <Search />
      </IconButton>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" gridColumnGap={10}>
            <IconButton onClick={() => handleClose()}>
              <ArrowBack />
            </IconButton>
            <Typography variant="h6">Últimos CFDIs</Typography>
            <Box flexGrow={1} />
            <Box display="flex" gridColumnGap={10}>
              <Button
                size="small"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={limpiarFiltros}
              >
                Borrar filtros
              </Button>
              <TextField
                style={{ width: 300 }}
                size="small"
                name="busqueda"
                variant="outlined"
                placeholder="Folio CFDi, Folio de venta, Cliente..."
                onChange={obtenerCamposFiltro}
                value={filtro.busqueda}
              />
              <TextField
                size="small"
                name="fecha"
                variant="outlined"
                type="date"
                onChange={obtenerCamposFiltro}
                value={filtro.fecha}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CfdisTable
            cfdisQuery={cfdisQuery}
            page={offset}
            setPage={setOffset}
            limit={limit}
            handleSelectFactura={handleSelectFactura}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const CfdisTable = ({
  cfdisQuery,
  page,
  setPage,
  limit,
  handleSelectFactura,
}) => {
  const classes = useStyles();
  const { data, loading, error } = cfdisQuery;

  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  if (loading) return <CustomLoader height="30vh" />;
  if (error) return <ErrorPage error={error} />;

  return (
    <Paper variant="outlined">
      <TableContainer className={classes.container}>
        <Table size="small" stickyHeader aria-label="a dense table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  padding={column.padding}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.docs.map((data, index) => {
              return (
                <RenderListFacturas
                  key={index}
                  data={data}
                  handleSelectFactura={handleSelectFactura}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={data.totalDocs}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
      />
    </Paper>
  );
};

const RenderListFacturas = ({ data, handleSelectFactura }) => {
  const disabled = (data.complementos.length > 0 || data.active) && true;
  const classes = useStyles();

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      onClick={(e) => handleSelectFactura(e, data)}
      className={disabled ? classes.disabled : null}
      style={{ cursor: "pointer" }}
    >
      <TableCell>
        {moment(data.fecha_registro)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY")}
      </TableCell>
      <TableCell>{data.folio_venta}</TableCell>
      <TableCell>{data.serie}</TableCell>
      <TableCell>{data.folio}</TableCell>
      <TableCell>{data.cfdi_type}</TableCell>
      <TableCell>{data.receiver.Name}</TableCell>
      <TableCell>{data.receiver.Rfc}</TableCell>
      <TableCell>${formatoMexico(data.sub_total)}</TableCell>
      <TableCell>${formatoMexico(data.total)}</TableCell>
    </TableRow>
  );
};
