import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Close, Done, LocalOfferOutlined } from "@material-ui/icons";
import { formatoMexico } from "../../../config/reuserFunctions";
import { useDebounce } from "use-debounce";
import { makeStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "50vw",
    height: "calc(100vh - 280px)",
    bottom: 15,
    right: 20,
    borderRadius: 16,

    //width: "50vw",
    //height: "calc(100vh - 200px)",
    left: "unset",
    [theme.breakpoints.down("md")]: {
      width: "70vw",
    },
  },
}));

export default function DescuentoVenta({
  venta_base,
  setVentaBase,
  totales,
  setTotales,
  montos,
  setMontos,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [descuentoAplicarVenta, setPrecioDescuento] = useState(0);
  const [porcentajeDescuento, setPorcentajeDescuento] = useState(0);
  const [variables, setVariables] = useState({
    productos: venta_base.productos,
    total: totales.total,
    subTotal: totales.subtotal,
    impuestos: totales.impuestos,
    iva: venta_base.iva,
    ieps: venta_base.ieps,
    descuento: totales.descuento,
    descuento_general_activo: false,
    descuento_general: {
      cantidad_descontado: 0,
      porciento: 0,
      precio_con_descuento: 0,
    },
  });
  const [PRICE] = useDebounce(descuentoAplicarVenta, 700);
  const textfieldRef = React.useRef(null);

  let venta_original = JSON.parse(localStorage.getItem("DatosVentas"));

  const total = venta_original?.total || 0;
  const total_base = venta_original?.total_base || 0;
  const subTotal = venta_original?.subTotal || 0;
  const subtotal_base = venta_original?.subtotal_base || 0;

  const TOTAL = total_base || total;
  const SUBTOTAL = subtotal_base || subTotal;

  const toggleDrawer = () => setOpen(!open);

  const handleChangePrecio = (value) => {
    if (parseFloat(value) > TOTAL || parseFloat(value) < 0) return;
    let porcentaje = Math.abs((value / TOTAL) * 100 - 100).toFixed(2);
    setPrecioDescuento(value);
    setPorcentajeDescuento(porcentaje);
  };

  const handleChangeDescuento = (value) => {
    if (parseFloat(value) > 100 || parseFloat(value) < 0) return;
    let percent = 100 - value;
    let discount = ((TOTAL * percent) / 100).toFixed(2);
    setPrecioDescuento(discount);
    setPorcentajeDescuento(value);
  };

  const calularDescuento = (NEW_PRICE, trunc = true) => {
    if (!NEW_PRICE || !Number(NEW_PRICE)) return;
    let venta = { ...venta_original };
    const productStorage = venta.productos;
    //Declarar la variables necesarias (total, subTotal, impuestos, iva ieps, productosFinal)
    let total = 0,
      subTotal = 0,
      impuestos = 0,
      iva = 0,
      ieps = 0,
      descuento = 0;
    let productosFinal = [];

    //caculos para sacar descuento;
    //Subtotal con descuento => Total a pagar * (Subtotal) / Total Venta
    //Porcentaje => Descuento / subtotal

    const NEW_SUBTOTAL_G = (NEW_PRICE * SUBTOTAL) / TOTAL;
    const DESCONTADO = parseFloat(SUBTOTAL - NEW_SUBTOTAL_G);
    const PORCENTAJE = parseFloat(DESCONTADO / SUBTOTAL);

    const truncate = (num) => Math.trunc(num * 100) / 100;

    //Mapearlos
    for (let i = 0; i < productStorage.length; i++) {
      const product = productStorage[i];
      const { cantidad_venta, id_producto } = product;
      const { precio_venta } = product.precio_actual_object;
      const IVA = id_producto.precios.iva;
      const IEPS = id_producto.precios.ieps;
      const PCCI = id_producto.precios.precio_de_compra.precio_con_impuesto;
      let precio_actual_object = {};

      const PVSI = precio_venta * cantidad_venta;
      const DESCUENTO_PRODUCTO = PVSI * PORCENTAJE;
      const NEW_SUB = parseFloat((PVSI - DESCUENTO_PRODUCTO).toFixed(2));
      let NEW_IEPS = truncate((NEW_SUB * (IEPS / 100)).toFixed(2));
      let NEW_IVA = parseFloat(((NEW_SUB + NEW_IEPS) * (IVA / 100)).toFixed(2));
      let NEW_TOTAL = parseFloat(NEW_SUB + NEW_IVA + NEW_IEPS);
      const NEW_UTILIDAD = parseFloat(((NEW_SUB - PCCI) / PCCI) * 100);

      if (NEW_TOTAL > parseFloat(PRICE)) {
        NEW_IEPS = truncate(NEW_SUB * (IEPS / 100));
        NEW_IVA = parseFloat(((NEW_SUB + NEW_IEPS) * (IVA / 100)).toFixed(2));
        NEW_TOTAL = parseFloat(NEW_SUB + NEW_IVA + NEW_IEPS);
      }

      precio_actual_object = {
        cantidad_unidad: 1,
        dinero_descontado: parseFloat(DESCUENTO_PRODUCTO.toFixed(2)),
        ieps_precio: NEW_IEPS,
        iva_precio: NEW_IVA,
        numero_precio: product.precio_actual_object.numero_precio,
        porciento: product.precio_actual_object.porciento
          ? parseFloat(
              parseFloat(product.precio_actual_object.porciento) +
                parseFloat(porcentajeDescuento)
            )
          : parseFloat(porcentajeDescuento),
        precio_general: 0,
        precio_neto: NEW_TOTAL,
        precio_venta: NEW_SUB,
        unidad_maxima: false,
        utilidad: parseFloat(NEW_UTILIDAD.toFixed(2)),
      };

      if (product.precio_actual_object.unidad_maxima) {
        //Aqui se calcula la unidad por mayoreo (Cajas y costales)
        precio_actual_object.cantidad_unidad =
          product.precio_actual_object.cantidad_unidad;
        precio_actual_object.precio_general = parseFloat(
          NEW_TOTAL * parseFloat(product.precio_actual_object.cantidad_unidad)
        );
        precio_actual_object.unidad_maxima = true;
      }
      const { granel, valor } = product.granel_producto;
      const valorGranel = granel === true ? parseFloat(valor) : 1;

      //Guardar el nuevo producto en el arreglo
      const IEPS_TOTAL = parseFloat(NEW_IEPS * valorGranel);
      const IVA_TOTAL = parseFloat(NEW_IVA * valorGranel);
      const IMPUESTOS_TOTAL = parseFloat((NEW_IEPS + NEW_IVA) * valorGranel);
      const SUBTOTAL_TOTAL = parseFloat(NEW_SUB * valorGranel);
      const TOTAL_TOTAL = parseFloat(NEW_TOTAL * valorGranel);
      const DescProd = parseFloat(DESCUENTO_PRODUCTO.toFixed(2)) * valorGranel;

      precio_actual_object.ieps_precio = parseFloat(NEW_IEPS.toFixed(2));
      precio_actual_object.iva_precio = parseFloat(NEW_IVA.toFixed(2));
      precio_actual_object.precio_neto = parseFloat(NEW_TOTAL.toFixed(2));
      precio_actual_object.precio_venta = parseFloat(NEW_SUB.toFixed(2));

      productosFinal.push({
        ...product,
        precio_actual_object,
        ieps_total_producto: parseFloat(IEPS_TOTAL.toFixed(2)),
        impuestos_total_producto: parseFloat(IMPUESTOS_TOTAL.toFixed(2)),
        iva_total_producto: parseFloat(IVA_TOTAL.toFixed(2)),
        subtotal_total_producto: parseFloat(SUBTOTAL_TOTAL.toFixed(2)),
        total_total_producto: parseFloat(TOTAL_TOTAL.toFixed(2)),
        descuento_activo: true,
        descuento: {
          cantidad_unidad: product.precio_actual_object.cantidad_unidad,
          numero_precio: product.precio_actual_object.numero_precio,
          unidad_maxima: product.precio_actual_object.unidad_maxima,
          precio_general: product.precio_actual_object.precio_general,
          precio_neto: precio_actual_object.precio_neto,
          precio_venta: precio_actual_object.precio_venta,
          iva_precio: precio_actual_object.ieps_precio,
          ieps_precio: precio_actual_object.ieps_precio,
          utilidad: precio_actual_object.utilidad,
          dinero_descontado: DescProd,
          porciento: product.precio_actual_object.porciento
            ? parseFloat(
                parseFloat(product.precio_actual_object.porciento) +
                  parseFloat(porcentajeDescuento)
              )
            : parseFloat(porcentajeDescuento),
        },
      });
      //Sumar los valores
      total += parseFloat(TOTAL_TOTAL.toFixed(2));
      subTotal += parseFloat(SUBTOTAL_TOTAL.toFixed(2));
      impuestos += parseFloat(IMPUESTOS_TOTAL.toFixed(2));
      iva += parseFloat(IVA_TOTAL.toFixed(2));
      ieps += parseFloat(IEPS_TOTAL.toFixed(2));
      descuento += parseFloat(DescProd.toFixed(2));
    }

    setVariables({
      productos: productosFinal,
      total: parseFloat(total.toFixed(2)),
      subTotal: parseFloat(subTotal.toFixed(2)),
      impuestos: parseFloat(impuestos.toFixed(2)),
      iva: parseFloat(iva.toFixed(2)),
      ieps: parseFloat(ieps.toFixed(2)),
      descuento: parseFloat(descuento.toFixed(2)),
      descuento_general_activo: true,
      descuento_general: {
        cantidad_descontado: parseFloat(descuento.toFixed(2)),
        porciento: parseFloat(porcentajeDescuento),
        precio_con_descuento: parseFloat(total.toFixed(2)),
      },
    });
  };

  const aplicarDescuento = () => {
    let venta = { ...venta_original };
    const { total, subTotal, impuestos, descuento } = variables;
    setVentaBase({
      ...venta,
      productos: variables.productos,
      total,
      subTotal,
      impuestos,
      iva: variables.iva,
      ieps: variables.ieps,
      descuento,
      descuento_general_activo: variables.descuento_general_activo,
      descuento_general: variables.descuento_general,
    });
    setTotales({ ...totales, total, subtotal: subTotal, impuestos, descuento });
    setMontos({ ...montos, efectivo: total });
    toggleDrawer();
  };

  const handleInvalidInput = (e) => {
    const { value, name } = e.target;
    if (
      !value ||
      value === "0" ||
      parseFloat(value) === 0 ||
      (name === "dinero-descuento" && porcentajeDescuento > 100)
    ) {
      setVariables({
        productos: venta_original.productos,
        total: venta_original.cotizacion
          ? venta_original.total_base
          : venta_original.total,
        subTotal: venta_original.cotizacion
          ? venta_original.subtotal_base
          : venta_original.subTotal,
        impuestos: venta_original.impuestos,
        iva: venta_original.iva,
        ieps: venta_original.ieps,
        descuento: venta_original.cotizacion ? 0 : venta_original.descuento,
        descuento_general_activo: false,
        descuento_general: {
          cantidad_descontado: 0,
          porciento: 0,
          precio_con_descuento: 0,
        },
      });
      setPrecioDescuento(0);
      setPorcentajeDescuento(0);
    }
  };

  useEffect(() => {
    calularDescuento(PRICE);
  }, [PRICE]);

  useEffect(() => {
    if (textfieldRef.current) textfieldRef.current.focus();
  }, [textfieldRef.current]);

  useEffect(() => {
    if (venta_original.cotizacion) {
      setPrecioDescuento(venta_original.total);
    }
  }, []);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        disableElevation
        startIcon={<LocalOfferOutlined fontSize="large" />}
        onClick={toggleDrawer}
        disabled={!venta_base || venta_base.cotizacion}
        style={{
          //textTransform: "none",
          color: blue[50],
          backgroundColor: blue[500],
        }}
      >
        <b>
          {`Descuento: `}
          <span style={{ fontSize: 17 }}>
            -${formatoMexico(totales.descuento)}
          </span>
        </b>
      </Button>
      <Drawer
        anchor="bottom"
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        elevation={0}
        classes={{ paper: classes.drawerPaper }}
        hideBackdrop
      >
        <Box p={3}>
          <Container maxWidth="xs">
            <Typography style={{ fontSize: "18px", textAlign: "center" }}>
              <b>Total a pagar:</b>
            </Typography>
            <Grid container justifyContent="center">
              <Grid item xs={8}>
                <TextField
                  inputRef={textfieldRef}
                  autoFocus
                  fullWidth
                  size="medium"
                  name="dinero-descuento"
                  disabled={venta_base.cotizacion}
                  id="form-venta-dinero-descuento"
                  type="number"
                  value={descuentoAplicarVenta}
                  onChange={(e) => handleChangePrecio(e.target.value)}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    style: {
                      borderBottomRightRadius: 0,
                      borderTopRightRadius: 0,
                      fontSize: 22,
                    },
                  }}
                  onBlur={handleInvalidInput}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  size="medium"
                  id="form-venta-porsentaje-descuento"
                  variant="standard"
                  value={porcentajeDescuento}
                  disabled={venta_base.cotizacion}
                  onChange={(e) => handleChangeDescuento(e.target.value)}
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h6" color="textSecondary">
                          %
                        </Typography>
                      </InputAdornment>
                    ),
                    inputProps: { min: 0, max: 100 },
                    style: {
                      borderBottomLeftRadius: 0,
                      borderTopLeftRadius: 0,
                      fontSize: 22,
                    },
                  }}
                  onBlur={handleInvalidInput}
                />
              </Grid>
            </Grid>
          </Container>
          <Box my={3} />
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Typography variant="h6" align="center">
                Subtotal
              </Typography>
              <Typography variant="h6" align="center">
                <b>${formatoMexico(variables.subTotal)}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                iva
              </Typography>
              <Typography variant="h6" align="center">
                <b>${formatoMexico(variables.iva)}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                ieps
              </Typography>
              <Typography variant="h6" align="center">
                <b>${formatoMexico(variables.ieps)}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" align="center">
                Impuestos
              </Typography>
              <Typography variant="h6" align="center">
                <b>${formatoMexico(variables.impuestos)}</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" align="center">
                Total
              </Typography>
              <Typography
                variant="h4"
                align="center"
                style={{ color: "#4caf50" }}
              >
                <b>${formatoMexico(variables.total)}</b>
              </Typography>
            </Grid>
          </Grid>
          <Box mt={5} display="flex" justifyContent="center">
            <Button
              onClick={() => toggleDrawer()}
              variant="outlined"
              size="large"
              color="primary"
              startIcon={<Close />}
              disableElevation
            >
              Cancelar
            </Button>
            <Box mx={1} />
            <Button
              onClick={() => aplicarDescuento()}
              variant="contained"
              size="large"
              color="primary"
              autoFocus
              startIcon={<Done />}
              disableElevation
            >
              Aplicar Descuento
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
