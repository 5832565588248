import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ProdAlmCtxProvider, { ProdAlmContext } from "./Context/ProdAlmContext";
import SearchComponent from "./Actions/Search";
import ProductosAlmacenTable from "./Table";
import Export from "./Actions/Export";
import { ArrowBack } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import CustomLoader from "../../../../components/CustomLoader";
import ErrorMessage from "../../../../components/ErrorMessage";
import SyncDB from "../../../../components/SyncDB";
import FilterChips from "../../../../components/FilterChips";
import ExistenciasSelect from "./Actions/ExistenciaFilter";
import { FcInspection } from "react-icons/fc";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InvetariosAlmacen() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box style={{ width: "100%" }}>
        <Button fullWidth onClick={handleClickOpen}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <FcInspection style={{ fontSize: 100 }} />
            </Box>
            Inventarios
          </Box>
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle component="div" style={{ paddingBottom: "0px" }}>
            <Box display="flex">
              <IconButton color="primary" size="small" onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Box m={1} />
              <Typography variant="h6" >
                Invetarios almacen
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <ProdAlmCtxProvider>
              <ProdAlmacenContent />
            </ProdAlmCtxProvider>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
}

const ProdAlmacenContent = () => {
  const { almacensQuery } = React.useContext(ProdAlmContext);
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        sx={{
          position: { sm: "absolute" },
          top: 16,
          right: 24,
          gridGap: 10,
        }}
        bgcolor="background.paper"
      >
        <Box mx={2}>
          <SyncDB refetch={almacensQuery.refetch} />
        </Box>
        <SearchComponent />
        <ExistenciasSelect />
        <Export />
      </Box>
      <FilterChips context={ProdAlmContext} noShowValue="existencias" />
      <RenderTable />
    </Box>
  );
};

const RenderTable = () => {
  const { almacensQuery } = React.useContext(ProdAlmContext);
  const { loading, error } = almacensQuery;

  if (loading) return <CustomLoader height="60vh" />;
  if (error) return <ErrorMessage message={error?.message} />;

  return <ProductosAlmacenTable />;
};
