import React from "react";
import { Box } from "@material-ui/core";
import { CustomButton } from "../../../../components/CustomMuiComponents";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";

export default function PaginationProductos({
  productosBusqueda,
  loading,
  error,
  page,
  setPage,
  limit,
}) {
  if (loading || error)
    return (
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        gridGap={10}
      >
        <CustomButton size="small" variant="outlined" style={{ minWidth: 50 }}>
          <NavigateBefore />
        </CustomButton>
        <CustomButton size="small" variant="outlined" style={{ minWidth: 50 }}>
          <NavigateNext />
        </CustomButton>
      </Box>
    );

  const customHandleChangePage = (nextPage) => {
    setPage(nextPage);
  };

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      gridGap={10}
    >
      <CustomButton
        size="small"
        variant="outlined"
        color="primary"
        style={{ minWidth: 50 }}
        disabled={!page}
        onClick={() => customHandleChangePage(page - 1)}
      >
        <NavigateBefore />
      </CustomButton>
      <CustomButton
        size="small"
        variant="outlined"
        color="primary"
        style={{ minWidth: 50 }}
        disabled={(page + 1) * limit >= productosBusqueda.totalDocs}
        onClick={() => customHandleChangePage(page + 1)}
      >
        <NavigateNext />
      </CustomButton>
    </Box>
  );
}
