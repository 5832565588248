import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Crop75TwoToneIcon from "@material-ui/icons/Crop75TwoTone";
import Crop169TwoToneIcon from "@material-ui/icons/Crop169TwoTone";
import Crop54TwoToneIcon from "@material-ui/icons/Crop54TwoTone";
import { TiendaCtx } from "../../../../../../context/Empresa/tiendaCtx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginBottom: 4,
    width: "100%",
  },
  tab: {
    minWidth: 90,
    width: 90,
    padding: 4
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const { data, setData } = React.useContext(TiendaCtx);

  const handleChange = (event, bannerSize) => {
    setData({...data, bannerSize});
  };

  return (
    <div className={classes.root}>
      <Tabs
        value={data && data.bannerSize !== null ? data.bannerSize : "medium"}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab
          classes={{
            root: classes.tab,
          }}
          label="Pequeño"
          value="small"
          icon={<Crop75TwoToneIcon style={{ fontSize: 30 }} />}
        />
        <Tab
          classes={{
            root: classes.tab,
          }}
          label="Mediano"
          value="medium"
          icon={<Crop169TwoToneIcon style={{ fontSize: 33 }} />}
        />
        <Tab
          classes={{
            root: classes.tab,
          }}
          label="Grande"
          value="large"
          icon={<Crop54TwoToneIcon style={{ fontSize: 35 }} />}
        />
      </Tabs>
    </div>
  );
}
