import React, { Fragment, forwardRef, useRef, useState, useContext } from 'react'
import {
  Button,
  Dialog,
  Typography,
  Box,
  Tooltip,
  AppBar,
  Toolbar,
  Slide,
  Checkbox
} from "@material-ui/core";
import BackdropComponent from "../../../../../components/Layouts/BackDrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Close } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import {
  LOAD_PRODUCTOS_XLS,
} from "../../../../../gql/Catalogos/productos";
import { AccesosContext } from "../../../../../context/Accesos/accesosCtx";
import { useMutation } from "@apollo/client";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  tableFixed: {
    position: "sticky",
    top: 0,
  },

  tableCellLong: {
    minWidth: "320px",
  },
  tableCellNormal: {
    minWidth: "170px",
  },
  tableCellShort: {
    minWidth: "100px",
  },
  columnaFijaEditar: {
    position: "sticky",
    backgroundColor: "#fff",
    right: 1,
  },
  encabezadoEditar: {
    position: "sticky",
    right: 1,

  },
  root: {
    width: "100%",
  },
  container: {
    height: "70vh",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalProductosAgregar = props => {
  const classes = useStyles();
  const [shadow, setShadow] = useState("leftShadowTable");
  const tableEl = useRef();
  const { getData } = useContext(
    AccesosContext
  );
  const sesion = JSON.parse(localStorage.getItem('sesionCafi'));
  const [cargarProductos] = useMutation(LOAD_PRODUCTOS_XLS);

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
    if (scrollLeft === realWidth) {
      setShadow("removeShadowTable");
      return;
    }
    setShadow("leftShadowTable");
  }, []);

  const handleOnScroll = (ev) => {
    const { scrollLeft, scrollWidth, clientWidth } = ev.target;
    const realWidth = scrollWidth - clientWidth;
    changeClassName(scrollLeft, realWidth);
  };

  const changeState = (state, index) => {
    try {
      let productosHere = props.productos;
      productosHere[index].selected = state;
      props.setProductos(productosHere);
    } catch (error) {
      console.log(error)
    }
  };

  const sendProductos = async () => {
    try {
      props.setLoading(true);
      let productos = props.productos;

      let producto_to_send = [];
      for (const ind in productos) {

        if (productos[ind].selected) {


          delete productos[ind].selected
          if (productos[ind].message) {
            delete productos[ind].message

          }
          producto_to_send.push(productos[ind]);

        }


      }
      if (producto_to_send.length === 0) {
        props.setAlert({ message: "No hay productos seleccionados", status: "error", open: true });
        props.setLoading(false);
        return;

      }
      let action = await cargarProductos({
        variables: {
          input: { productos: producto_to_send }
        },
      });

      let messageShow = action.data.importarProductosXls.message.message;
      if (action.data.importarProductosXls.productos_failed.length > 0) {
        producto_to_send = [];
        let prod_failed = action.data.importarProductosXls.productos_failed;
        for (const ind in prod_failed) {
          let index = prod_failed[ind].index;
          props.productos[index].message = prod_failed[ind].error.message;
          props.productos[index].selected = true;

          producto_to_send.push(props.productos[index]);
        }
        props.setProductos(producto_to_send);
        messageShow = "Algunos productos no se pudieron agregar.";
      }
      props.setAlert({ message: messageShow, status: "error", open: true })
      props.setLoading(false);
      getData(sesion.empresa, sesion.sucursal, "PRODUCTOS");
    } catch (error) {
      console.log(error)
      /*  if (error.networkError.result) {
        console.log(error.networkError.result.errors);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors.message);
      } */
      props.setLoading(false);
    }
  }
  return (
    <Fragment>
      <Dialog open={props.modalProductos} onClose={props.onCloseModal} fullWidth maxWidth="lg" TransitionComponent={Transition}>
        <BackdropComponent loading={props.loading} />
        <Box sx={{ width: '100%', }}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Importar productos
              </Typography>
              <Box mx={1}>
                <Box >
                  <Button variant="contained" color="secondary" onClick={props.onCloseModal}>
                    <Close style={{ fontSize: 30 }} />
                  </Button>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
          <div className={classes.root}>
            <Box m={2} display="flex" justifyContent="flex-end">
              <Button
                color="primary"
                size="medium"
                variant="contained"
                type="submit"
                onClick={sendProductos}
              >
                Importar
              </Button>
            </Box>

            <TableContainer
              className={classes.container}
              ref={tableEl}
              onScroll={handleOnScroll}
            >
              <Table stickyHeader size="small" aria-label="sticky table">
                <TableHead className="tableFixed">
                  <TableRow>
                    <TableCell className={classes.tableCellNormal}>
                      Codigo de barras
                    </TableCell>
                    <TableCell className={classes.tableCellNormal}>
                      Clave alterna
                    </TableCell>
                    <TableCell className={classes.tableCellLong}>
                      Nombre comercial
                    </TableCell>
                    <TableCell className={classes.tableCellLong}>
                      Nombre génerico
                    </TableCell>
                    <TableCell className={classes.tableCellLong}>
                      Descripción
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      Tipo
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      Unidad de compra
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      Factor por unidad
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      Precio compra
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      IVA
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      IEPS
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      Utilidad
                    </TableCell>
                    <TableCell className={classes.tableCellShort}>
                      Precio
                    </TableCell>
                    <TableCell className={`${classes.encabezadoEditar} ${shadow}`}>  </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="hide-scroll">
                  {props.productos.map((producto, index) => {

                    return (
                      <RenderTableRows
                        key={index}
                        index={index}
                        producto={producto}
                        classes={classes}
                        shadow={shadow}
                        changeState={changeState}

                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {/*    <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={props.productos.length}
                    rowsPerPage={10}
                    page={0}
                    onPageChange={() => console.log('siguiente página')}
                /> */}
          </div>

        </Box>

      </Dialog>
    </Fragment>

  )
};

const RenderTableRows = ({ index, producto, classes, shadow, changeState }) => {

  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  //
  let unidad_principal = {};
console.log(producto)
  producto.unidades_de_venta.forEach(element => {
    if (element.unidad_principal) { unidad_principal = element }
  });

  let unidad_de_compra = unidad_principal.unidad;
  let factor_por_unidad = unidad_principal.cantidad;
  let iva = (unidad_principal.precio_unidad.iva_precio) ? unidad_principal.precio_unidad.iva_precio : 0;
  let ieps = (unidad_principal.precio_unidad.ieps_precio) ? unidad_principal.precio_unidad.ieps_precio : 0;
  let utilidad = (unidad_principal.precio_unidad.utilidad) ? unidad_principal.precio_unidad.utilidad : 0;
  let precio = unidad_principal.precio;

  const runChangeState = () => {

    changeState(!producto.selected, index);
  }

  return (
    <Fragment>
      <Tooltip placement="right-start" title={<Typography style={{ color: "lightblue", fontSize: 15 }}>{(producto.message) ? "Este producto no se pudo agregar por que " + producto.message.toLowerCase() : ''}</Typography>} >

        <TableRow >
          <TableCell>
            {producto.datos_generales.codigo_barras}
          </TableCell>
          <TableCell>
            {producto.datos_generales.clave_alterna}
          </TableCell>
          <TableCell>
            {producto.datos_generales.nombre_comercial}
          </TableCell>
          <TableCell>
            {producto.datos_generales.nombre_generico}
          </TableCell>
          <TableCell>
            {producto.datos_generales.descripcion}
          </TableCell>
          <TableCell>
            {producto.datos_generales.tipo_producto}
          </TableCell>
          <TableCell>
            {unidad_de_compra}
          </TableCell>
          <TableCell>
            {factor_por_unidad}
          </TableCell>
          <TableCell>
            {"$" + formatoMexico(producto.precios.precio_de_compra.precio_con_impuesto)}
          </TableCell>
          <TableCell>
            {"$" + formatoMexico(iva)}
          </TableCell>
          <TableCell>
            {"$" + formatoMexico(ieps)}
          </TableCell>
          <TableCell>
            {"$" + formatoMexico(utilidad)}
          </TableCell>
          <TableCell>
            {"$" + formatoMexico(precio)}
          </TableCell>

          <TableCell
            className={`${classes.columnaFijaEditar} 
              ${shadow}`}
            align="center"
            padding="checkbox"
          >
            <Checkbox defaultChecked={producto.selected} onChange={runChangeState} />
          </TableCell>

        </TableRow>
      </Tooltip>
    </Fragment>
  );
};


export default ModalProductosAgregar;