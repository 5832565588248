import React,{Fragment, forwardRef, useRef, useState} from 'react'
import {
    Button,
    Dialog,
    Typography,
    Box,
    Tooltip,
    AppBar, 
    Toolbar,
    Slide,
    Checkbox
  } from "@material-ui/core";
import BackdropComponent from "../../../../../components/Layouts/BackDrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {Close} from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import {
  LOAD_CLIENTES_XLS,
} from "../../../../../gql/Catalogos/clientes";
import { useMutation } from "@apollo/client";
    const useStyles = makeStyles((theme) => ({
        appBar: {
            position: 'relative'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1
        },
        tableFixed: {
          position: "sticky",
          top: 0,
        },
        
        tableCellLong: {
            minWidth: "320px",
        },
        tableCellNormal: {
            minWidth: "170px",
        },
        tableCellShort: {
            minWidth: "100px",
        },
        columnaFijaEditar: {
          position: "sticky",
          backgroundColor: "#fff",
          right: 1,
        },
        encabezadoEditar: {
          position: "sticky",
          right: 1,
         
        },
        root: {
          width: "100%",
        },
        container: {
          height: "70vh",
          "& ::-webkit-scrollbar": {
            display: "none",
          },
        },
    }));
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ModalProveedoresAgregar = props => {
  const classes = useStyles(); 
  const [shadow, setShadow] = useState("leftShadowTable");
  const tableEl = useRef();
  const [cargarClientes] = useMutation(LOAD_CLIENTES_XLS);

  const changeClassName = React.useCallback((scrollLeft, realWidth) => {
      if (scrollLeft === realWidth) {
        setShadow("removeShadowTable");
        return;
      }
      setShadow("leftShadowTable");
  }, []);

  const handleOnScroll = (ev) => {
      const { scrollLeft, scrollWidth, clientWidth } = ev.target;
      const realWidth = scrollWidth - clientWidth;
      changeClassName(scrollLeft, realWidth);
  }; 

  const changeState = (state, index) => {
    try {
      let proveedoresHere = props.proveedores;
      proveedoresHere[index].selected = state;
      props.setProveedores(proveedoresHere);
    } catch (error) {
      console.log(error)
    }
  }; 
  
  const sendProveedores =  async () => {
    try {
      props.setLoading(true);
      let proveedores = props.proveedores;
   
      let proveedor_to_send = [];
      for (const ind in proveedores) {
      
        if (proveedores[ind].selected) {
         
         
          delete proveedores[ind].selected
          if (proveedores[ind].message ) {
            delete proveedores[ind].message
           
          }
          proveedor_to_send.push(proveedores[ind] );
          
        }
       
       
      }
     if(proveedor_to_send.length === 0){
        props.setAlert({message: "No hay proveedores seleccionados", status: "error", open: true });
         props.setLoading(false);
        return;
        
     }
      let action = await cargarClientes({
        variables: {
          input:{clientes:proveedor_to_send}
        },
      });

      let messageShow = action.data.importarClientesXls.message;
      if(action.data.importarClientesXls.clientes_failed.length > 0 ){
        proveedor_to_send = [];
        let prod_failed = action.data.importarClientesXls.clientes_failed;
        for (const ind in prod_failed ) {
           let index  = prod_failed[ind].index;
            props.proveedores[index].message = prod_failed[ind].error.message;
            props.proveedores[index].selected = true;
         
            proveedor_to_send.push(props.proveedores[index] );
        }
        props.setProveedores(proveedor_to_send);
        messageShow = "Algunos proveedores no se pudieron agregar.";
      }
      props.setAlert({message: messageShow, status: "error", open: true })
      props.setLoading(false);
    } catch (error) {
     console.log(error)
    /*  if (error.networkError.result) {
      console.log(error.networkError.result.errors);
    } else if (error.graphQLErrors) {
      console.log(error.graphQLErrors.message);
    } */
      props.setLoading(false);
    }
  }
  return (
    <Fragment>
        <Dialog open={props.modalProveedores} onClose={props.onCloseModal} fullWidth maxWidth="lg" TransitionComponent={Transition}>
          <BackdropComponent loading={props.loading} />
            <Box sx={{ width: '100%',  }}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <Typography variant="h6" className={classes.title}>
                                Importar proveedores
                        </Typography>
                        <Box mx={1}>
                            <Box >
                                <Button  variant="contained" color="secondary"onClick={props.onCloseModal}>
                                    <Close style={{ fontSize: 30}}/>
                                </Button>
                            </Box>
                        </Box>      
                    </Toolbar>
                </AppBar>
                <div className={classes.root}>
                <Box m={2} display="flex" justifyContent="flex-end">
                  <Button
                      color="primary"
                      size="medium"
                      variant="contained"
                      type="submit"
                      onClick={sendProveedores}
                    >
                      Importar
                  </Button>
                </Box>
                   
                <TableContainer
                    className={classes.container}
                    ref={tableEl}
                    onScroll={handleOnScroll}
                >

                   
                    <Table stickyHeader size="small" aria-label="sticky table">
                    <TableHead className="tableFixed">
                        <TableRow>
                          <TableCell className={classes.tableCellNormal}>
                            Nombre cliente
                          </TableCell>
                          <TableCell className={classes.tableCellNormal}>
                            Representante 
                          </TableCell>
                          <TableCell className={classes.tableCellLong}>
                            Email
                          </TableCell>
                          <TableCell className={classes.tableCellLong}>
                            Teléfono
                          </TableCell>
                          <TableCell className={classes.tableCellLong}>
                            Calle
                          </TableCell>
                          <TableCell className={classes.tableCellShort}>
                            Num.Ext
                          </TableCell>
                          <TableCell className={classes.tableCellShort}>
                            Código postal
                          </TableCell>
                          <TableCell className={classes.tableCellShort}>
                            Colonia
                          </TableCell>
                          <TableCell className={classes.tableCellShort}>
                            Municipio
                          </TableCell>
                          <TableCell className={classes.tableCellShort}>
                            Estado
                          </TableCell>
                          <TableCell className={classes.tableCellShort}>
                            País
                          </TableCell>
                              
                          <TableCell className={`${classes.encabezadoEditar} ${shadow}`}>  </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="hide-scroll">
                          {props.proveedores.map((proveedor, index) => {
                           
                            return (
                                <RenderTableRows
                                  key={index}
                                  index={index}
                                  proveedor={proveedor}
                                  classes={classes}
                                  shadow={shadow}
                                  changeState={changeState}
                                  
                                />
                            );
                          })}
                      </TableBody> 
                    </Table>
                </TableContainer>
             {/*    <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={props.productos.length}
                    rowsPerPage={10}
                    page={0}
                    onPageChange={() => console.log('siguiente página')}
                /> */}
              </div>
                 
            </Box>
          
        </Dialog>
    </Fragment>
    
  )
};

const RenderTableRows = ({ index, proveedor, classes, shadow, changeState}) => {

    const sesion = JSON.parse(localStorage.getItem("sesionCafi"));


  const runChangeState = () =>{
   
    changeState(!proveedor.selected, index);
  }

    return (
      <Fragment>
        <Tooltip placement="right-start" title={<Typography style={{ color: "lightblue", fontSize:15 }}>{(proveedor.message) ? "Este proveedor no se pudo agregar por que " + proveedor.message.toLowerCase() : ''  }</Typography>} >
         
          <TableRow >
          <TableCell>
                {proveedor.nombre_cliente}
              </TableCell>
              <TableCell>
                {proveedor.representante}
              </TableCell>
            
              <TableCell>
                {proveedor.email}
              </TableCell>
              <TableCell>
                {proveedor.telefono}
              </TableCell>
              <TableCell>
                {proveedor.direccion.calle}
              </TableCell>
              <TableCell>
                {proveedor.direccion.no_ext}
              </TableCell>
              <TableCell>
                {proveedor.direccion.codigo_postal}
              </TableCell>
              <TableCell>
                {proveedor.direccion.colonia}
              </TableCell>
              <TableCell>
                {proveedor.direccion.municipio}
              </TableCell>
              <TableCell>
                {proveedor.direccion.estado}
              </TableCell>
              <TableCell>
                {proveedor.direccion.pais}
              </TableCell>
          
            <TableCell
              className={`${classes.columnaFijaEditar} 
              ${shadow}`}
              align="center"
              padding="checkbox"
            >
              <Checkbox  defaultChecked={proveedor.selected} onChange={runChangeState} />
            </TableCell>
          
          </TableRow>
        </Tooltip>
      </Fragment>
    );
  };


export default ModalProveedoresAgregar;