import { Impresora } from "./Impresora";
import { formatoMexico } from "../../../config/reuserFunctions";
import { formaPago } from "../../../pages/sucursales/Facturacion/catalogos";
import moment from "moment-timezone"
import table from "text-table";
const RUTA_API = "http://localhost:8000";

export const imprimirTicketVenta = async (datos) => {
  try {
    const { ventaFinal, monto_pagado, turno, sesion } = datos;
    //sacar la forma de pago
    let forma_pago = "";
    formaPago.forEach((element) => {
      if (ventaFinal.forma_pago === element.Value) forma_pago = element.Name;
    });
    // obtener direccion
    let address = "";
    let municipio = "";
    let pais = "";
    const { direccion } = sesion.sucursal;
    if (
      direccion &&
      direccion.calle &&
      direccion.no_ext &&
      direccion.municipio &&
      direccion.estado &&
      direccion.pais
    ) {
      address = `${direccion.calle} #${direccion.no_ext}`;
      municipio = `${direccion.municipio}, ${direccion.estado}`;
      pais = direccion.pais;
    }

    //console.log(ventaFinal)

    let tableHead = table(
      [["Articulo      ", "Cantidad", "Precio", "Importe"]],
      { align: ["l", "r"] }
    );

    let tableBody = [{ align: ["l", "r"] }];

    let tableTotal = table(
      [
        [`SUBTOTAL:  `, `${formatoMexico(ventaFinal.subTotal)}`],
        [`IMPUESTOS:  `, `${formatoMexico(ventaFinal.impuestos)}`],
        [`TOTAL:  `, `${formatoMexico(ventaFinal.total)}`],
      ],
      { align: ["l", "r"] }
    );
    let tablePago = table(
      [
        [`Pago con: ${forma_pago}`, `${formatoMexico(monto_pagado)}`],
        [`Su cambio:  `, `${formatoMexico(ventaFinal.cambio)}`],
      ],
      { align: ["l", "r"] }
    );

    let tableVentaCredito = table(
      [
        [`Venta a credito`],
        [
          `Fecha Vencimiento credito: ${moment(
            ventaFinal.fecha_de_vencimiento_credito
          )
            .tz("America/Mexico_City")
            .format("DD/MM/YYYY")}`,
        ],
        [`Abono minimo: ${formatoMexico(ventaFinal.abono_minimo)}`],
      ],
      { align: ["l", "r"] }
    );

    ventaFinal.productos.forEach((res) => {
      const { nombre_comercial } = res.id_producto.datos_generales;
      const { color, medida } = res;
      let nombre = nombre_comercial;
      if (color || medida) {
        if ((color.nombre || color.color) && (medida.talla || medida.medida)) {
          nombre = `${nombre_comercial} T-${medida.talla || medida.medida} ${color.nombre || color.color}`;
        }
      }

      tableBody.push([
        `${nombre}`,
        `${res.cantidad_venta} ${res.unidad}`,
        ` ${formatoMexico(res.precio_actual_object.precio_neto)}`,
        `${formatoMexico(res.precio_a_vender)}`,
      ]);
    });

    tableBody = table(tableBody);

    let impresora = new Impresora(RUTA_API);
    impresora.cutPartial();
    impresora.setFontSize(1, 1);
    impresora.setEmphasize(0);
    impresora.write(`${sesion.empresa.nombre_empresa}\n`);
    impresora.write(`RFC ${sesion.empresa.rfc}\n`);
    impresora.write(`Regimen fiscal ${sesion.empresa.regimen_fiscal}\n`);
    impresora.write(`Sucursal: ${sesion.sucursal.nombre_sucursal}\n`);
    impresora.write(`Domicilio: ${address}\n`);
    impresora.write(`${municipio}\n`);
    impresora.write(`${pais}\n`);
    impresora.feed(1);
    impresora.write("****Ticket de venta****\n");
    impresora.write(`Caja ${turno.numero_caja}\n`);
    impresora.write(`Folio: ${ventaFinal.folio}\n`);
    impresora.write(
      `Fecha: ${moment()
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY")} Hora: ${moment()
        .tz("America/Mexico_City")
        .format("LTS")}\n`
    );
    impresora.feed(1);
    impresora.write(
      `Cliente ${
        ventaFinal.cliente.nombre_cliente
          ? ventaFinal.cliente.nombre_cliente
          : "Público general"
      }\n`
    );
    impresora.write(
      `Clave Cliente: ${
        ventaFinal.cliente.clave_cliente ? ventaFinal.cliente.clave_cliente : ""
      }\n`
    );
    impresora.feed(1);
    impresora.write("=======================================\n");
    impresora.setAlign("right");
    impresora.write(`${tableHead}\n`);
    impresora.write("_______________________________________\n");
    impresora.write(`${tableBody}\n`);
    impresora.write("----------------------------------------\n");
    impresora.write(`${tableTotal}\n`);
    impresora.write("----------------------------------------\n");
    impresora.write(`${tablePago}\n`);
    if (ventaFinal.credito) {
      impresora.feed(1);
      impresora.write(`${tableVentaCredito}\n`);
    }
    impresora.write("========================================\n");
    impresora.feed(1);
    impresora.setAlign("center");
    impresora.write("***Gracias por su compra***");
    impresora.feed(1);
    impresora.write("Punto de venta CAFI\n");
    impresora.feed(3);
    impresora.cut();
    /* impresora.cutPartial(); // Pongo este y también cut porque en ocasiones no funciona con cut, solo con cutPartial */

    await impresora.end();
  } catch (error) {
    console.log(error);
  }
};

export const imprimirTicketPrueba = async () => {
  try {
    let impresora = new Impresora(RUTA_API);
    impresora.feed(1);
    impresora.setAlign("center");
    impresora.write("***Ticket de prueba***");
    impresora.feed(3);
    impresora.cut();
    /* impresora.cutPartial(); // Pongo este y también cut porque en ocasiones no funciona con cut, solo con cutPartial */

    await impresora.end();

  } catch (error) {
    console.log(error);
  }
};