import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { Box, Container, Grid, IconButton } from "@material-ui/core";
import RegistroTallas from "./RegistroTallas";
import DescripcionCatalogo from "../../../../components/DescripcionCatalogo";
import { ArrowBack } from "@material-ui/icons";
import { FcRuler } from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  iconSvg: {
    width: 100,
  },
  icon: {
    fontSize: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Tallas() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(!open);
  const descripcion =
    "En este apartado se registran las  tallas y/o números que se asignarán a un producto tipo Ropa o Calzado según sea el caso.";
  return (
    <div>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" justifyContent="center" alignItems="center">
            <FcRuler style={{ fontSize: 100 }} />
          </Box>
          Tallas y numeros
        </Box>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
      >
        <Typography variant="h6" className={classes.title}>
          Tallas y números
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <DescripcionCatalogo texto={descripcion} />
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <Container>
          <Box>
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}>
                <RegistroTallas tipo="ROPA" />
              </Grid>
              <Grid item md={6} xs={12}>
                <RegistroTallas tipo="CALZADO" />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Dialog>
    </div>
  );
}
