import React, { useCallback, useContext, useEffect, useState } from "react";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import {
  Button,
  Dialog,
  makeStyles,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import TablaPreciosDescuentos from "./ListaPrecios";
import { REGISTRAR_DESCUENTOS } from "../../../../../gql/Catalogos/descuentos";
import { useMutation } from "@apollo/client";
import SnackBarMessages from "../../../../../components/SnackBarMessages";
import BackdropComponent from "../../../../../components/Layouts/BackDrop";
import { RegProductoContext } from "../../../../../context/Catalogos/CtxRegProducto";
import { Done } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  avatarGroup: {
    "& > .MuiAvatarGroup-avatar": {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
  },
  root: {
    width: 400,
  },
  rootSlice: {
    width: 350,
  },
  margin: {
    height: theme.spacing(3),
  },
  rootTable: {
    height: 300,
  },
}));

export default function DescuentoProductos({ datos, isOnline }) {
  const [CrearDescuentoUnidad] = useMutation(REGISTRAR_DESCUENTOS);
  const {
    setDatosPreciosProducto,
    preciosDescuentos,
    setPreciosDescuentos,
    preciosProductos,
    setPreciosProductos,
    updateProductos,
    setUpdateProductos,
  } = useContext(RegProductoContext);

  let iva = datos.precios.iva;
  let ieps = datos.precios.ieps;
  // Precio compra sin impuestos y sin utilidad
  let PCSI = datos.precios.unidad_de_compra.precio_unitario_sin_impuesto;

  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const [openDescuento, setOpenDescuento] = useState(false);
  const [cleanList, setCleanList] = useState(false);
  const [validate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [precioPrueba, setPrecioPrueba] = useState(0);
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const handleCloseDescuentos = () => {
    if (datos.medidas_producto.length > 0) {
      setDatosPreciosProducto(datos.medidas_producto);
      setLoading(false);
    } else {
      setDatosPreciosProducto(datos.unidades_de_venta);
      setLoading(false);
    }
    setPrecioPrueba(0);
    setValue(0);
    preciosDescuentos.splice(0, preciosDescuentos.length);
  };

  const cerrarModal = () => {
    setOpenDescuento(!openDescuento);
    handleCloseDescuentos();
  };

  useEffect(() => {
    handleCloseDescuentos();
  }, [datos]);

  const verificarDatos = useCallback(
    (datos) => {
      for (let i = 0; i < datos.length; i++) {
        if (datos[i].descuento) {
          if (datos[i].descuento.porciento !== 0) {
            setValue(datos[i].descuento.porciento);
            if (datos.length === 1) {
              setPrecioPrueba(datos[i].descuento.precio_neto);
            }
          }
        }
      }
    },
    [datos]
  );

  let arrayDescuento = [];

  const obtenerPorciento = (event) => {
    const newValue = event.target.value;
    if (newValue > 100) return;
    setValue(newValue);
    preciosDescuentos.splice(0, preciosDescuentos.length);

    for (let i = 0; i < preciosProductos.length; i++) {
      let porcentaje = parseFloat(100 - newValue /* .toFixed(4) */); //Porcentaje para calculos de descuento
      let PVCDSI = 0; // Precio venta con descuento sin impuestos
      let dineroDescontado = 0;
      let cantidad_unidad = preciosProductos[i].precio_unidad.cantidad_unidad;

      PVCDSI = parseFloat(
        (preciosProductos[i].precio_unidad.precio_venta * porcentaje) / 100
      );
      dineroDescontado = parseFloat(
        preciosProductos[i].precio_unidad.precio_venta - PVCDSI
      );

      let ieps_precio = parseFloat(PVCDSI * (ieps / 100));
      let iva_precio = parseFloat((PVCDSI + ieps_precio) * (iva / 100));

      let utilidad = parseFloat(((PVCDSI - PCSI) / PCSI) * 100);
      let precio_neto = parseFloat(PVCDSI + iva_precio + ieps_precio);
      let precio_general = parseFloat(precio_neto * cantidad_unidad);

      arrayDescuento = {
        _id: preciosProductos[i]._id,
        descuento_activo: true,
        descuento: {
          cantidad_unidad: cantidad_unidad,
          numero_precio: preciosProductos[i].precio_unidad.numero_precio,
          unidad_maxima: preciosProductos[i].precio_unidad.unidad_maxima,
          precio_general: parseFloat(precio_general.toFixed(4)),
          precio_neto: parseFloat(precio_neto.toFixed(4)),
          precio_venta: parseFloat(PVCDSI.toFixed(4)),
          iva_precio: parseFloat(iva_precio.toFixed(4)),
          ieps_precio: parseFloat(ieps_precio.toFixed(4)),
          utilidad: parseFloat(utilidad.toFixed(4)),
          porciento: parseFloat(newValue),
          dinero_descontado: parseFloat(dineroDescontado.toFixed(4)),
        },
      };

      setPrecioPrueba(parseFloat(precio_neto.toFixed(4)));
      if (preciosProductos.length !== 1) {
        preciosDescuentos.push(arrayDescuento);
      } else {
        setPreciosDescuentos([arrayDescuento]);
      }
    }
  };

  const obtenerPrecioText = (e) => {
    let valorText = parseFloat(e.target.value);
    if (preciosProductos.length === 1) {
      setPrecioPrueba(valorText);

      let PV_SIN_IEPS = valorText / (ieps/100 + 1);
      const PRECIO_VENTA = PV_SIN_IEPS / (iva/100 + 1);

      let cantidad_unidad = preciosProductos[0].precio_unidad.cantidad_unidad;
      let dineroDescontado = 0;
      let porcentaje = parseFloat(
        (PRECIO_VENTA / preciosProductos[0].precio_unidad.precio_venta) * 100
      );
      let descuento = parseFloat(100 - porcentaje);
      dineroDescontado = parseFloat(
        preciosProductos[0].precio_unidad.precio_venta - PRECIO_VENTA
      );

      let ieps_precio = parseFloat(PRECIO_VENTA * (ieps / 100));
      let iva_precio = parseFloat((PRECIO_VENTA + ieps_precio) * (iva / 100));
      let utilidad = parseFloat(((PRECIO_VENTA - PCSI) / PCSI) * 100);
      let precio_neto = parseFloat(PRECIO_VENTA + iva_precio + ieps_precio);
      let precio_general = parseFloat(precio_neto * cantidad_unidad);

      arrayDescuento = {
        _id: preciosProductos[0]._id,
        descuento_activo: true,
        descuento: {
          cantidad_unidad: cantidad_unidad,
          numero_precio: preciosProductos[0].precio_unidad.numero_precio,
          unidad_maxima: preciosProductos[0].precio_unidad.unidad_maxima,
          precio_general: parseFloat(precio_general.toFixed(4)),
          precio_neto: parseFloat(precio_neto.toFixed(4)),
          precio_venta: parseFloat(PRECIO_VENTA.toFixed(4)),
          iva_precio: parseFloat(iva_precio.toFixed(4)),
          ieps_precio: parseFloat(ieps_precio.toFixed(4)),
          utilidad: parseFloat(utilidad.toFixed(4)),
          porciento: parseFloat(descuento.toFixed(4)),
          dinero_descontado: parseFloat(dineroDescontado.toFixed(4)),
        },
      };

      setValue(parseFloat(descuento.toFixed(4)));
      setPreciosDescuentos([arrayDescuento]);
    }
  };

  const saveData = async () => {
    setLoading(true);
    const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
    try {
      await CrearDescuentoUnidad({
        variables: {
          input: {
            descuentos: preciosDescuentos,
          },
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
        },
      });
      handleCloseDescuentos();
      setValue(0);
      setPreciosProductos([]);
      setPreciosDescuentos([]);
      setCleanList(!cleanList);
      setUpdateProductos(!updateProductos);
      setLoading(false);
      setAlert({
        message: "¡Listo descuentos realizados!",
        status: "success",
        open: true,
      });
    } catch (error) {
      //console.log(error.networkError.result)
      setLoading(false);
      setAlert({
        message: "¡Oh no, ocurrio un problema con el servidor!",
        status: "error",
        open: true,
      });
    }
  };

  const validacion = () => {
    if (datos.medidas_producto.length > 0) {
      for (let i = 0; i < datos.medidas_producto.length; i++) {
        if (datos.medidas_producto[i]?.descuento_activo === true) {
          return "primary";
        } else {
          return "default";
        }
      }
    } else {
      for (let i = 0; i < datos.unidades_de_venta.length; i++) {
        if (datos.unidades_de_venta[i]?.descuento_activo === true) {
          return "primary";
        } else {
          return "default";
        }
      }
    }
  };

  return (
    <div>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <IconButton
        color={validacion()}
        onClick={cerrarModal}
        size="small"
        disabled={
          !isOnline ||
          (datos.inventario_general &&
            datos.inventario_general.length > 0 &&
            datos.inventario_general[0].eliminado === true)
        }
      >
        <LocalOfferIcon />
      </IconButton>
      <Dialog
        open={openDescuento}
        onClose={handleCloseDescuentos}
        fullWidth
        maxWidth="md"
      >
        <BackdropComponent loading={loading} setLoading={setLoading} />
        <DialogTitle>
          <Box display="flex">
            <Box flexGrow={1} display="flex" alignItems="center">
              {`Descuento de ${
                datos.datos_generales.nombre_comercial
                  ? datos.datos_generales.nombre_comercial
                  : "-"
              }`}
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={cerrarModal}
              size="large"
            >
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex">
            <TextField
              type="number"
              InputProps={{
                inputProps: { min: 0, max: 100 },
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              label="Porcentaje de descuento"
              style={{ width: 180 }}
              size="small"
              variant="outlined"
              value={value}
              onChange={(e) => obtenerPorciento(e)}
              disabled={preciosProductos.length === 0 ? true : false}
            />
            <Box mx={1} />
            {preciosProductos.length === 1 ? (
              <TextField
                type="number"
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                label="Precio de descuento"
                style={{ width: 180 }}
                size="small"
                variant="outlined"
                value={precioPrueba}
                onChange={(e) => obtenerPrecioText(e)}
              />
            ) : null}
            <Box mx={1} />
            <Button
              disabled={
                !isOnline || preciosProductos.length === 0 || validate === true
                  ? true
                  : false
              }
              variant="contained"
              color="primary"
              size="large"
              onClick={saveData}
              disableElevation
              startIcon={<Done />}
            >
              Guardar
            </Button>
          </Box>
          <Box mt={1}>
            <TablaPreciosDescuentos
              verificarDatos={verificarDatos}
              datos={datos}
              value={value}
              cleanList={cleanList}
              setCleanList={setCleanList}
              setPrecioPrueba={setPrecioPrueba}
              setLoading={setLoading}
              loading={loading}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
