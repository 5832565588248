import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/client";
import { LOGEAR_USUARIO } from "../../gql/Catalogos/usuarios";
import { ACTUALIZAR_BD_LOCAL } from "../../gql/Catalogos/usuarios";
import SnackBarMessages from "../SnackBarMessages";
import jwt_decode from "jwt-decode";
import { FormControl } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { OutlinedInput } from "@material-ui/core";
import { InputAdornment } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import cafiLogo from "../../img/Cafi.svg";
import { AccesosContext } from "../../context/Accesos/accesosCtx";
import IMG_LOGIN from "../../img/login.png";
import { useNavigate } from "react-router-dom";
import NodoConfig from "../NodoConfig";
import { MainContext } from "../../context/MainCtx";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerImagen: {
    maxWidth: "100%",
    maxHeight: 150,
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  },
  imagen: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image_logo: {
    backgroundImage: `url(${IMG_LOGIN})`,
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
  },
}));

export default function LayoutLogin() {
  const classes = useStyles();
  const sesion = localStorage.getItem("sesionCafi");
  const { isOnline, setLoadingPage, ventaToCloud } = useContext(AccesosContext);
  const { setTokenDecoded, isWeb } = React.useContext(MainContext);

  const [datos, setDatos] = useState({
    numero_usuario: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  /* if (sesion) props.history.push("/ventas"); */

  const [logearUsuario] = useMutation(LOGEAR_USUARIO);
  const [actualizarBDLocal] = useMutation(ACTUALIZAR_BD_LOCAL);
  const signin = async (e) => {
    e.preventDefault();
    if (!datos.numero_usuario || !datos.password) {
      setAlert({
        message: "Los campos estan vacíos",
        status: "error",
        open: true,
      });
      return;
    }
    setLoading(true);

    try {
      const result = await logearUsuario({
        variables: {
          input: {
            numero_usuario: datos.numero_usuario,
            password: datos.password,
            isOnline: isOnline,
          },
        },
      });
      const { token } = result.data.logearUsuario;
      const decoded = jwt_decode(token);
      setLoading(false);
      localStorage.setItem("sesionCafi", JSON.stringify(decoded));
      localStorage.setItem("tokenCafi", JSON.stringify(token));
      setTokenDecoded(decoded);
      navigate("/ventas");

      if (ventaToCloud && !isWeb) {
        setLoadingPage(true);
        await actualizarBDLocal({
          variables: {
            empresa: decoded.empresa._id,
            sucursal: decoded.sucursal._id,
          },
        });
        setLoadingPage(false);
      }
    } catch (error) {
      setAlert({ message: error.message, status: "error", open: true });
      setLoading(false);
      setLoadingPage(false);
    }
  };

  const obtenerCampos = (name, value) => {
    setDatos({
      ...datos,
      [name]: value,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    if (sesion) {
      navigate("/ventas");
    }
  }, [sesion, navigate]);

  if (sesion) return null;

  return (
    <Box>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <NodoConfig />
      <Grid container>
        <Grid item sm={4} md={6} lg={7}>
          <div className={classes.image_logo} />
        </Grid>
        <Grid item sm={8} md={6} lg={5}>
          <Box
            height="100vh"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box padding={6}>
              <Box
                mb={5}
                display="flex"
                justifyContent="center"
                alignItems="center"
                justifyItems="center"
              >
                <Box className={classes.containerImagen} textAlign="center">
                  <img
                    className={classes.imagen}
                    src={cafiLogo}
                    alt="imagen no disponible"
                  />
                </Box>
              </Box>
              <form autocomplete="on" onSubmit={signin}>
                <TextField
                  label="Numero de usuario o Username"
                  variant="outlined"
                  name="numero_usuario"
                  fullWidth
                  value={datos.numero_usuario}
                  className={classes.margin}
                  onChange={(e) =>
                    obtenerCampos(
                      "numero_usuario",
                      e.target.value.toUpperCase()
                    )
                  }
                />

                <FormControl
                  variant="outlined"
                  name="password"
                  className={classes.margin}
                  fullWidth
                >
                  <InputLabel htmlFor="usuario-password">Contraseña</InputLabel>
                  <OutlinedInput
                    name="password"
                    id="usuario-password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => obtenerCampos("password", e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={70}
                  />
                </FormControl>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  disableElevation
                  className={classes.margin}
                  color="primary"
                  type="submit"
                  endIcon={
                    loading ? (
                      <CircularProgress size={25} color="inherit" />
                    ) : null
                  }
                >
                  Entrar
                </Button>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
