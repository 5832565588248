import React from "react";
import {
  SnackbarContent,
  Button,
  Box,
  Typography,
  Portal,
} from "@material-ui/core";
import { PowerOff } from "@material-ui/icons";

function NotServerConected() {
  return (
    <Portal>
      <Box
        position="absolute"
        bottom={8}
        right={8}
        minWidth={530}
        zIndex={9999}
      >
        <SnackbarContent
          message={
            <Box display="flex" alignItems="center">
              <PowerOff style={{ marginRight: 16 }} />
              <Box>
                <Typography>Error de conexión con el servidor.</Typography>
                <Typography>
                  Verifique el estado de cafi servidor y recarge
                </Typography>
              </Box>
            </Box>
          }
          action={
            <Button color="secondary" onClick={() => window.location.reload()}>
              Recargar
            </Button>
          }
        />
      </Box>
    </Portal>
  );
}

export default NotServerConected;
