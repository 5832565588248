import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { EmailOutlined } from "@material-ui/icons";
import {
  CLIENTE_FACTURA,
  ENVIAR_FACTURA,
} from "../../../../gql/Facturacion/Facturacion";
import { useApolloClient, useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import SnackBarMessages from "../../../../components/SnackBarMessages";

export default function SendInvoice({ factura }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingSend, setLoadingSend] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [alert, setAlert] = React.useState({
    message: "",
    status: "",
    open: false,
  });
  const client = useApolloClient();
  const [enviarFactura] = useMutation(ENVIAR_FACTURA);

  const getUserData = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: CLIENTE_FACTURA,
        variables: {
          ventaId: factura.id_venta,
        },
        fetchPolicy: "network-only",
      });
      setLoading(false);
      console.log(response.data);
      if (response.data) {
        setEmail(response.data.obtenerClienteVenta.email);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    getUserData();
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const sendEmail = async () => {
    try {
      if (!email) return;
      setLoadingSend(true);
      await enviarFactura({
        variables: {
          input: {
            cfdiType: 'issuedLite',
            cfdiId: factura.id_cfdi,
            email,
          },
        },
      });
      setLoadingSend(false);
      setAlert({
        message: "Factura enviada",
        status: "success",
        open: true,
      });
    } catch (error) {
      console.log(error);
      setLoadingSend(false);
      setAlert({
        message: error.message,
        status: "error",
        open: true,
      });
    }
  };

  return (
    <div>
      <Button
        color="primary"
        onClick={handleClickOpen}
        startIcon={<EmailOutlined />}
      >
        Enviar
      </Button>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enviar correo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enviar factura a la siguiente direccion de correo electrónico:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Correo electrónico"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            fullWidth
            placeholder={loading ? "Buscando Email..." : "Ingresa correo"}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={sendEmail}
            color="primary"
            disabled={!email || loadingSend}
            startIcon={
              loadingSend ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            }
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
