import React, { useContext, useState, Fragment } from "react";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core";
import { formaPago } from "../../../../Facturacion/catalogos";
import { formatoMexico } from "../../../../../../config/reuserFunctions";
import { useMutation } from "@apollo/client";
import { CREAR_ABONO } from "../../../../../../gql/Tesoreria/abonos";
import moment from "moment";
import BackdropComponent from "../../../../../../components/Layouts/BackDrop";
import { TesoreriaCtx } from "../../../../../../context/Tesoreria/tesoreriaCtx";
import { imprimirTicketAbonos } from "../../ImpTicketAbono";
import { useNavigate } from "react-router-dom";
import { AccesosContext } from "../../../../../../context/Accesos/accesosCtx";
import { Sync } from "@material-ui/icons";
import { MainContext } from "../../../../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  formInputFlex: {
    display: "flex",
    "& > *": {
      margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
    },
    "& .obligatorio": {
      color: "red",
    },
    paddingTop: 0,
    alignItems: "center",
    justifyItems: "center",
  },
}));

function AbonoaRecibir(props) {
  const [CrearAbono] = useMutation(CREAR_ABONO);
  const [efectivoRecibido, setEfectivoRecibido] = useState(0);
  const [cambioCliente, setCambioCliente] = useState(0);
  const { isOnline } = useContext(AccesosContext);
  const { setReload, setAlert } = useContext(TesoreriaCtx);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [abono, setAbono] = useState("");
  const [metodoPago, setMetodoPago] = useState("");
  const [metodoPagoNombre, setMetodoPagoNombre] = useState("");
  const { turnoEnCurso } = React.useContext(MainContext);
  const { loadingPage, getData, ventaToCloud } =
    React.useContext(AccesosContext);
  const navigate = useNavigate();

  const dataImp = {
    turno: {
      usuario: sesion.nombre,
      numero_usuario: sesion.numero_usuario,
      caja: turnoEnCurso ? parseInt(turnoEnCurso.numero_caja) : 1,
      turno: turnoEnCurso ? turnoEnCurso.horario_en_turno : "",
    },
    folio: props.cuenta.folio,
    numero_cliente: props.cuenta.proveedor.numero_cliente,
    clave_cliente: props.cuenta.proveedor.clave_cliente,
    nombre_cliente: props.cuenta.proveedor.nombre_cliente,
    saldo_inicial: props.cuenta.total,
    total_anterior: props.cuenta.total - props.cuenta.saldo_credito_pendiente,
    su_pago: abono,
    efectivo_recibido: efectivoRecibido,
    su_cambio: cambioCliente,
    total_restante: props.cuenta.saldo_credito_pendiente - abono,
    metodo_pago: metodoPagoNombre,
    descuento: 0,
  };

  const enviarDatos = async () => {
    setLoading(true);
    try {
      const input = {
        tipo_movimiento: "ABONO_PROVEEDOR",
        rol_movimiento: turnoEnCurso ? "CAJA" : "CAJA_PRINCIPAL",
        horario_turno: turnoEnCurso ? turnoEnCurso.horario_en_turno : "",
        monto_total_abonado: parseFloat(abono.toFixed(4)),
        montos_en_caja: {
          monto_efectivo: {
            monto: metodoPago === "01" ? parseFloat(abono * -1) : 0,
            metodo_pago: "01",
          },
          monto_tarjeta_debito: {
            monto: metodoPago === "28" ? parseFloat(abono * -1) : 0,
            metodo_pago: "28",
          },
          monto_tarjeta_credito: {
            monto: metodoPago === "04" ? parseFloat(abono * -1) : 0,
            metodo_pago: "04",
          },
          monto_creditos: {
            monto: metodoPago === "99" ? parseFloat(abono * -1) : 0,
            metodo_pago: "99",
          },
          monto_monedero: {
            monto: metodoPago === "05" ? parseFloat(abono * -1) : 0,
            metodo_pago: "05",
          },
          monto_transferencia: {
            monto: metodoPago === "03" ? parseFloat(abono * -1) : 0,
            metodo_pago: "03",
          },
          monto_cheques: {
            monto: metodoPago === "02" ? parseFloat(abono * -1) : 0,
            metodo_pago: "02",
          },
          monto_vales_despensa: {
            monto: metodoPago === "08" ? parseFloat(abono * -1) : 0,
            metodo_pago: "08",
          },
        },
        metodo_de_pago: {
          clave: metodoPago,
          metodo: metodoPagoNombre,
        },
        numero_caja: turnoEnCurso ? parseInt(turnoEnCurso.numero_caja) : 1,
        id_Caja: turnoEnCurso ? turnoEnCurso.id_caja : "",
        id_usuario: sesion._id,
        concepto: "ABONO_PROVEEDOR",
        numero_usuario_creador: sesion.numero_usuario,
        nombre_usuario_creador: sesion.nombre,
        id_cliente: props.cuenta.proveedor.id_proveedor._id,
        numero_cliente: props.cuenta.proveedor.numero_cliente,
        nombre_cliente: props.cuenta.proveedor.nombre_cliente,
        telefono_cliente: props.cuenta.proveedor.id_proveedor.telefono,
        email_cliente: props.cuenta.proveedor.id_proveedor.email,
        caja_principal: turnoEnCurso
          ? false
          : sesion.accesos.tesoreria.caja_principal.ver,
        id_compra: props.cuenta._id,
      };

      console.log(input);

      if (abono <= 0 || abono > props.cuenta.saldo_credito_pendiente) {
        setAlert({
          message:
            "Por favor digite una cantidad mayor a cero y menor al resto de su deuda",
          status: "error",
          open: true,
        });
        setLoading(false);
        return;
      }

      if (abono === "" || metodoPago === "") {
        setAlert({
          message: "Por favor complete los datos.",
          status: "error",
          open: true,
        });
        setLoading(false);
        return;
      }
      await CrearAbono({
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          input,
        },
      });
      await imprimirTicketAbonos(sesion, dataImp, "PROVEEDOR", false);
      if (ventaToCloud && isOnline) getData(sesion.empresa, sesion.sucursal);
      setReload(true);
      setAbono("");
      setMetodoPago("");
      setEfectivoRecibido(0);
      setMetodoPagoNombre("");
      setCambioCliente(0);
      setAlert({
        message: "Abono registrado con éxito",
        status: "success",
        open: true,
      });
      handleClick();
      setLoading(false);
    } catch (error) {
      handleClick();
      setLoading(false);
      if (error.networkError) {
        console.log(error.networkError.result);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors);
      }
      console.log(error);
      setAlert({
        message: "Ocurrio un problema en el servidor",
        status: "error",
        open: true,
      });
    }
  };

  const handleGetAbono = (value) => {
    setAbono(value);
    if (efectivoRecibido) {
      setCambioCliente(parseFloat(efectivoRecibido - value).toFixed(4));
    }
  };

  const handleGetRecibido = (efectivo) => {
    setEfectivoRecibido(efectivo);
    if (abono) {
      setCambioCliente(parseFloat(efectivo - abono).toFixed(4));
    }
    if (!efectivo) {
      setCambioCliente(0);
    }
  };

  const handleClick = () => {
    setAbono("");
    setMetodoPago("");
    setEfectivoRecibido(0);
    setMetodoPagoNombre("");
    setCambioCliente(0);
    if (turnoEnCurso || sesion.accesos.tesoreria.caja_principal.ver) {
      setReload(true);
      setOpen(!open);
    } else {
      navigate("/ventas");
    }
  };

  return (
    <Fragment>
      {props.cuenta.credito_pagado ? (
        <div />
      ) : (
        <Button
          size="large"
          variant="outlined"
          fullWidth
          disabled={loadingPage}
          color="primary"
          startIcon={
            loadingPage ? (
              <Sync htmlColor={"#6B17CC"} className="cloudSync" />
            ) : (
              <AddCircleOutlineIcon style={{ fontSize: 35 }} />
            )
          }
          onClick={handleClick}
        >
          <Typography variant="h6">Abonar</Typography>
        </Button>
      )}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClick}
        fullWidth
        maxWidth="xs"
      >
        <BackdropComponent loading={loading} setLoading={setLoading} />
        <DialogTitle id="alert-dialog-slide-title">
          <Box display="flex">
            <Box p={1} flexGrow={1}>
              Registrar nuevo abono
            </Box>
            <Box p={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClick}
                size="large"
              >
                <CloseIcon />
              </Button>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
        <Box>
            <Typography>
              <b>Metodo de pago:</b>
            </Typography>
            <FormControl variant="outlined" fullWidth size="small">
              <Select
                width="100%"
                name="metodo_pago"
                variant="outlined"
                value={metodoPago ? metodoPago : ""}
                onChange={(e, child) => {
                  setMetodoPago(e.target.value);
                  setMetodoPagoNombre(
                    child.props.metodo ? child.props.metodo.Name : ""
                  );
                  if (e.target.value !== "01") {
                    setEfectivoRecibido(0);
                  }
                }}
              >
                <MenuItem value="">
                  <em>Selecciona uno</em>
                </MenuItem>
                {formaPago.map((metodo, index) => {
                  return (
                    <MenuItem key={index} value={metodo.Value} metodo={metodo}>
                      {metodo.Name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Typography>
              <b>Cantidad a abonar:</b>
            </Typography>
            <TextField
              fullWidth
              className={classes.input}
              onChange={(e) => handleGetAbono(parseFloat(e.target.value))}
              value={abono}
              size="small"
              name="abono_recibir"
              variant="outlined"
              type="number"
            />
          </Box>
          <Box>
            <Typography>
              <b>Efectivo recibido:</b>
            </Typography>
            <TextField
              fullWidth
              className={classes.input}
              onChange={(e) => handleGetRecibido(parseFloat(e.target.value))}
              onBlur={(e) => {
                if (!e.target.value) {
                  setEfectivoRecibido(0);
                }
              }}
              value={efectivoRecibido}
              size="small"
              name="abono_recibir"
              variant="outlined"
              type="number"
              disabled={metodoPago !== "01"}
            />
          </Box>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Typography variant="h6">
              <b>Total de la cuenta</b>
            </Typography>
            <Typography style={{ color: "#9B9B9B" }} variant="h6">
              <b>${formatoMexico(props.cuenta.total)}</b>
            </Typography>
          </Box>
          {/* <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              <b>Total pagado</b>
            </Typography> 

            <Typography style={{ color: "green" }} variant="h6">
              <b>
                $
                {formatoMexico(
                  props.cuenta.total - props.cuenta.saldo_credito_pendiente
                )}
              </b>
            </Typography>
          </Box>
           <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              <b>Total restante</b>
            </Typography>
            <Typography variant="h6">
              <b>${formatoMexico(props.cuenta.saldo_credito_pendiente)}</b>
            </Typography>
          </Box> */}
          <Divider />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              <b>Nuevo abono</b>
            </Typography>
            <Typography style={{ color: "green" }} variant="h6">
              <b>${formatoMexico(abono)} </b>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              <b>Saldo pendiente</b>
            </Typography>
            <Typography style={{ color: "green" }} variant="h6">
              <b>
                $
                {formatoMexico(
                  props.cuenta.total -
                    props.cuenta.saldo_credito_pendiente +
                    abono
                )}
              </b>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              <b>Nuevo restante total</b>
            </Typography>

            <Typography variant="h6">
              <b>
                ${formatoMexico(props.cuenta.saldo_credito_pendiente - abono)}
              </b>
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">
              <b>Cambio</b>
            </Typography>

            <Typography variant="h6" style={{ color: "green" }}>
              <b>${formatoMexico(cambioCliente)}</b>
            </Typography>
          </Box>
        </DialogContent>
        <Box display="flex" justifyContent="center" alignContent="center" p={2}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{ fontSize: 18 }}
            onClick={enviarDatos}
          >
            Registrar Abono
          </Button>
        </Box>
      </Dialog>
    </Fragment>
  );
}

export default AbonoaRecibir;
