import React from "react";
import { SnackbarProvider, useSnackbar } from "notistack";
import { CircularProgress, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { TiendaCtx } from "../../../../../../context/Empresa/tiendaCtx";
import { ELIMINAR_CUSTOM_BANNER } from "../../../../../../gql/Empresa/tienda";
import { useMutation } from "@apollo/client";
import { AccesosContext } from "../../../../../../context/Accesos/accesosCtx";

function ButtonDel({ banner }) {
  const { setAlert, tienda } = React.useContext(TiendaCtx);
  const { enqueueSnackbar } = useSnackbar();
  const { isOnline } = React.useContext(AccesosContext);
  const [loading, setLoading] = React.useState(false);
  const [seconds, setSeconds] = React.useState(0);
  const [timming, setTimming] = React.useState({
    startTime: null,
    endTime: null,
    timeDiff: null,
  });
  const [eliminarCustomBanner] = useMutation(ELIMINAR_CUSTOM_BANNER);

  const handleMouseDown = () => {
    setTimming((state) => ({ ...state, startTime: new Date() }));
  };

  const handleMouseUp = async () => {
    let endTime = new Date();
    let timeDiff = endTime - timming.startTime; //en ms
    setTimming({ endTime, timeDiff, startTime: null });
    if (timeDiff < 1000) {
      enqueueSnackbar(
        "Para eliminar un banner, mantén presionado unos segundos y suelta cuando cambie de color"
      );
    }
    if (timeDiff >= 1000) {
      setSeconds(0);
      try {
        setLoading(true);
        const locationBig = banner.banner.location_imagen;
        const locationSide = banner.sides.location_imagen;
        const resp = await eliminarCustomBanner({
          variables: {
            id_banner: banner._id,
            tienda: tienda.data._id,
            location: locationBig
              ? locationBig
              : locationSide
                ? locationSide
                : "",
          },
        });
        setLoading(false);
        tienda.refetch();
        setAlert({
          message: resp.data.eliminarCustomBanner.message,
          status: "success",
          open: true,
        });
      } catch (error) {
        console.log(error);
        if (error.networkError) console.log(error.networkError.result);
        setLoading(false);
        setAlert({ message: error.message, status: "error", open: true });
      }
    }
  };

  React.useEffect(() => {
    let sampleInterval = setInterval(() => {
      if (timming.startTime) {
        setSeconds((sec) => sec + 1);
      } else {
        setSeconds(0);
      }
    }, 1000);

    return () => {
      clearInterval(sampleInterval);
    };
  }, [timming.startTime, seconds]);

  return (
    <React.Fragment>
      <IconButton
        size="small"
        style={{ marginRight: 4 }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        disabled={loading || !isOnline}
      >
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <Delete color={seconds >= 1 ? "error" : "action"} />
        )}
      </IconButton>
    </React.Fragment>
  );
}

export default function EliminarCustomBanner({ banner }) {
  return (
    <SnackbarProvider
      maxSnack={1}
      preventDuplicate
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <ButtonDel banner={banner} />
    </SnackbarProvider>
  );
}
