import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx.js";
import { CustoTextField } from "../../NuevaFactura/CustomTextField.jsx";

export default function FechaHoraSalidaLlegada() {
  const { complementCartaPorte, setComplementCartaPorte } = React.useContext(
    FacturacionCtx
  );
  const { Ubicaciones } = complementCartaPorte.Complemento.CartaPorte30;

  const getDateTimeSalida = (FechaHoraSalidaLlegada) => {
    const items = [...Ubicaciones];
    const itemOrigen = { ...Ubicaciones[0], FechaHoraSalidaLlegada };
    items.splice(0, 1, itemOrigen);
    setComplementCartaPorte((st) => ({
      ...st,
      Complemento: {
        CartaPorte30: {
          ...st.Complemento.CartaPorte30,
          Ubicaciones: items,
        },
      },
    }));
  };

  const getDateTimeLlegada = (FechaHoraSalidaLlegada) => {
    const items = [...Ubicaciones];
    const index = Ubicaciones.length - 1;
    const itemDestino = {
      ...Ubicaciones[index],
      FechaHoraSalidaLlegada,
    };
    items.splice(index, 1, itemDestino);
    setComplementCartaPorte((st) => ({
      ...st,
      Complemento: {
        CartaPorte30: {
          ...st.Complemento.CartaPorte30,
          Ubicaciones: items,
        },
      },
    }));
  };

  return (
    <Grid container spacing={2} style={{ paddingBottom: 8 }}>
      <Grid item>
        <Typography color="textSecondary">Fecha y hora salida</Typography>
        <CustoTextField
          id="datetime-local-salida"
          type="datetime-local"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          disabled={!Ubicaciones.length}
          onChange={(e) => getDateTimeSalida(e.target.value)}
        />
      </Grid>
      <Grid item>
        <Typography color="textSecondary">Fecha y hora llegada</Typography>
        <CustoTextField
          id="datetime-local-llegada"
          type="datetime-local"
          variant="outlined"
          disabled={Ubicaciones.length < 2}
          onChange={(e) => getDateTimeLlegada(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>
  );
}
