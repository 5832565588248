import React from "react";
import { IconButton } from "@material-ui/core";
import { FilterDrama, Sync } from "@material-ui/icons";
import { CloudContext } from "../context/CloudCtx";
import { MainContext } from "../context/MainCtx";

export default function SyncDB({refetch = null}) {
  const { querySync, syncDataBases } = React.useContext(CloudContext);
  const { isWeb } = React.useContext(MainContext);

  if(isWeb) return null

  return (
    <div>
      <IconButton
        disabled={querySync.loading}
        onClick={() => syncDataBases(refetch)}
        size="small"
      >
        {querySync.loading ? (
          <Sync className="cloudSync" fontSize="small" />
        ) : (
          <FilterDrama fontSize="small" />
        )}
      </IconButton>
    </div>
  );
}
