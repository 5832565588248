import React, { Fragment, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import SnackBarMessages from "../../../components/SnackBarMessages";
import { Box, CircularProgress, IconButton } from "@material-ui/core";
//import DeleteIcon from '@material-ui/icons/Delete';
import { AccesosContext } from "../../../context/Accesos/accesosCtx";
//import {formatoFecha} from '../../../config/reuserFunctions';
import moment from "moment-timezone";
import { useQuery, useMutation } from "@apollo/client";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { ClienteCtx } from "../../../context/Catalogos/crearClienteCtx";
import {
  CONSULTAR_COTIZACIONES,
  ELIMINAR_COTIZACION,
} from "../../../gql/Ventas/cotizaciones";
import DetalleCotizacion from "./DetalleCotizacion";
import { Delete, CallReceived } from "@material-ui/icons";
import { formatoMexico } from "../../../config/reuserFunctions";
import { MainContext } from "../../../context/MainCtx";

const columns = [
  { id: "fecha", label: "Fecha", minWidth: 80 },
  { id: "cliente", label: "Cliente", minWidth: 100 },
  { id: "fechaVencimiento", label: "Vence el", minWidth: 130 },
  { id: "productos", label: "Artículos", minWidth: 50, align: "center" },
  { id: "total", label: "Total", align: "right" },
];
/* 
function createData(folio, cliente, fecha, total) {
	return { folio, cliente, fecha, total};
}
 */

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    height: "70vh",
  },
});

export default function CotizacionesPendientes({ setOpen }) {
  const classes = useStyles();
  //const [ page, setPage ] = React.useState(0);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  //const [ rowsPerPage, setRowsPerPage ] = React.useState(10);
  const [view, setView] = useState(false);
  const [selected, setSelected] = useState("");
  const [openMessage, setOpenMessage] = useState({
    message: "",
    status: "",
    open: false,
  });

  const { data, refetch, loading } = useQuery(CONSULTAR_COTIZACIONES, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
    },
    fetchPolicy: "network-only",
  });

  let rows = [];
  const handleCloseView = (e) => {
    e.stopPropagation();
    setView(false);
  };
  /* 	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	}; */
  const handleModalCotizacion = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 84) {
        setOpen(false);
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, []);

  if (data) rows = data.obtenerCotizaciones;
  if (loading)
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="70vh"
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Paper variant="outlined">
      <SnackBarMessages alert={openMessage} setAlert={setOpenMessage} />
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="enhanced table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell align="right" padding="checkbox">
                Retomar
              </TableCell>
              <TableCell align="right" padding="checkbox">
                Eliminar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <CotizacionRow
                  key={index}
                  cotizacion={row}
                  handleModalCotizacion={handleModalCotizacion}
                  setView={setView}
                  setSelected={setSelected}
                  refetch={refetch}
                  openMessage={openMessage}
                  setOpenMessage={setOpenMessage}
                />
              );
            })}
          </TableBody>
        </Table>
        <DetalleCotizacion
          venta={selected}
          open={view}
          handleClose={handleCloseView}
          refetch={refetch}
        />
      </TableContainer>
      {/* 	<TablePagination
				rowsPerPageOptions={[ 10, 25, 100 ]}
				component="div"
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/> */}
    </Paper>
  );
}

const CotizacionRow = ({
  cotizacion,
  index,
  handleModalCotizacion,
  setSelected,
  setView,
  refetch,
  setOpenMessage,
  openMessage,
}) => {
  //const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  let datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));
  const { updateTablaVentas, setUpdateTablaVentas } = useContext(VentasContext);
  const { updateClientVenta, setUpdateClientVenta } = useContext(ClienteCtx);

  const AgregarVentaDeNuevo = (e) => {
    try {
      //	(_cotizacion.fecha_vencimiento_cotizacion
      e.stopPropagation();
      let objectToSave = JSON.stringify({
        cliente:
          cotizacion.cliente.nombre_cliente === null ? {} : cotizacion.cliente,
        descuento: cotizacion.descuento,
        ieps: cotizacion.ieps,
        impuestos: cotizacion.impuestos,
        iva: cotizacion.iva,
        monedero: cotizacion.monedero,
        productos: cotizacion.productos,
        subtotal_base: cotizacion.subtotal_base,
        subTotal: cotizacion.subTotal,
        total_base: cotizacion.total_base,
        total: cotizacion.total,
        cotizacion: true,
        venta_cliente: true,
        tipo_emision: cotizacion.tipo_emision
          ? cotizacion.tipo_emision
          : "TICKET",
      });

      //Veriricar si hay una
      if (datosVenta === null) {
        //setOpenMessage({message: 'agregarVentaDeNuevo', open:true});

        if (
          moment(cotizacion.fecha_vencimiento_cotizacion).isBefore(
            moment().tz("America/Mexico_City").format("YYYY MM DD")
          )
        ) {
          setOpenMessage({
            message: "La cotización seleccionada ya tiene la fecha vencida.",
            status: "error",
            open: true,
          });

          return;
        }
        localStorage.setItem("DatosVentas", objectToSave);
        handleModalCotizacion();
        updateDataStorage();
      } else {
        setOpenMessage({
          message: "No puedes agregar una venta cuando ya está una en curso.",
          status: "error",
          open: true,
        });
      }
    } catch (error) {
      setOpenMessage({ message: error, status: "error", open: true });
    }
  };

  const openDetail = (cotizacion) => {
    try {
      setSelected(cotizacion);

      setView(true);
    } catch (error) {}
  };
  const updateDataStorage = () => {
    setUpdateTablaVentas(!updateTablaVentas);
    setUpdateClientVenta(!updateClientVenta);
  };
  const handleClickOpen = (e) => {
    try {
      e.stopPropagation();
      setOpenMessage(!openMessage.open);
    } catch (error) {}
  };

  try {
    /* 	function borrarCotizacion() {
			if (sesion.accesos.ventas.eliminar_ventas.ver === true) {
			  let nueva_venta_espera = [...listaEnEspera];
			  nueva_venta_espera.splice(index, 1);
			  if (nueva_venta_espera.length === 0) {
				localStorage.removeItem("ListaEnEspera");
			  } else {
				localStorage.setItem(
				  "ListaEnEspera",
				  JSON.stringify(nueva_venta_espera)
				);
			  }
			  setUpdateTablaVentas(!updateTablaVentas);
			} else {
			  return null;
			}
		  }
		const verificarPermisos = () => {
			if (sesion.accesos.ventas.eliminar_ventas.ver === true) {
				borrarCotizacion();
			} else {
			  setAbrirPanelAcceso(!abrirPanelAcceso);
			  setDepartamentos({
				departamento: "ventas",
				subDepartamento: "eliminar_ventas",
				tipo_acceso: "ver",
			  });
			}
		  }; */
    return (
      <>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={cotizacion.code}
          onClick={() => openDetail(cotizacion)}
          style={{ cursor: "pointer" }}
        >
          <TableCell>{cotizacion.fecha_registro}</TableCell>
          <TableCell>
            {cotizacion.cliente.nombre_cliente
              ? cotizacion.cliente.nombre_cliente
              : "Público general"}
          </TableCell>
          <TableCell>{cotizacion.fecha_vencimiento_cotizacion}</TableCell>
          <TableCell align="center">{cotizacion.productos.length}</TableCell>
          <TableCell align="right">
            ${formatoMexico(cotizacion.total)}
          </TableCell>
          <TableCell align="right">
            <RegresarVenta
              AgregarVentaDeNuevo={AgregarVentaDeNuevo}
              openMessage={openMessage}
              setOpenMessage={setOpenMessage}
              handleClickOpen={handleClickOpen}
            />
          </TableCell>

          <TableCell align="center">
            <EliminarCotizacion
              setOpenMessage={setOpenMessage}
              handleClickOpen={handleClickOpen}
              id={cotizacion._id}
              refetch={refetch}
            />
          </TableCell>

          {/* <TableCell align='center' >
						<IconButton aria-label="delete" size='small'>
						    <AutorenewIcon fontSize="medium" />
						</IconButton>
					</TableCell> */}
          {/* 	<TableCell align='center' >
						<IconButton aria-label="delete" size='small'>
							<DeleteIcon fontSize="small" />
						</IconButton>
					</TableCell> */}
        </TableRow>
      </>
    );
  } catch (error) {}
};

const RegresarVenta = ({
  openMessage,
  setOpenMessage,
  handleClickOpen,
  AgregarVentaDeNuevo,
}) => {
  return (
    <Fragment>
      <IconButton
        aria-label="regresar"
        color="primary"
        size="small"
        onClick={AgregarVentaDeNuevo}
      >
        <CallReceived />
      </IconButton>
      <SnackBarMessages alert={openMessage} setAlert={setOpenMessage} />
    </Fragment>
  );
};

const EliminarCotizacion = ({
  setOpenMessage,
  handleClickOpen,
  id,
  refetch,
}) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { turnoEnCurso } = React.useContext(MainContext);
  const { getData, ventaToCloud } = useContext(AccesosContext);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickOpenDelete = (e) => {
    e.stopPropagation();
    setOpenModal(true);
  };
  const handleClose = (e) => {
    e.stopPropagation();
    setOpenModal(false);
  };

  const [eliminarCotizacion] = useMutation(ELIMINAR_COTIZACION);

  const deleteCotizacion = async (e) => {
    try {
      e.stopPropagation();
      setLoading(true);
      const result = await eliminarCotizacion({
        variables: {
          id: id,
          empresa: turnoEnCurso.empresa,
          sucursal: turnoEnCurso.sucursal,
        },
      });
      setLoading(false);
      handleClickOpen(e);

      if (ventaToCloud) getData(sesion.empresa, sesion.sucursal);
      refetch();
      handleClose(e);
      setOpenMessage({
        status: "success",
        message: result.data.eliminarCotizacion.message,
        open: true,
      });
    } catch (error) {
      setLoading(false);
      if (error.message) {
        setOpenMessage({
          status: "error",
          message: error.message,
          open: true,
        });
      } else {
        setOpenMessage({
          status: "error",
          message: "Hubo un error",
          open: true,
        });
      }
      console.log(error);
    }
  };

  return (
    <Fragment>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={handleClickOpenDelete}
        color="secondary"
      >
        <Delete fontSize="medium" />
      </IconButton>
      <Dialog onClose={handleClose} open={openModal}>
        <DialogTitle>Se eliminará esta cotizacion</DialogTitle>
        <DialogActions>
          <Button color="inherit" size="small" onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color="secondary"
            size="small"
            onClick={deleteCotizacion}
            startIcon={
              loading ? <CircularProgress color="inherit" size={18} /> : null
            }
            disabled={loading}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
