import React, { useContext } from 'react'
import { AccesosContext } from '../../../context/Accesos/accesosCtx';
import { RegProductoProvider } from '../../../context/Catalogos/CtxRegProducto'
import CrearProducto from '../../sucursales/Catalogos/Producto/crearProducto';
 
export default function ProductoRapidoIndex(){
    const { isOnline } = useContext(AccesosContext);

    return (
        <RegProductoProvider>
            <CrearProducto accion={false} isOnline={isOnline} fromVenta={true} />
        </RegProductoProvider>
    )
}