import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Close, DescriptionOutlined, InfoOutlined } from "@material-ui/icons";
import { FaRegFilePdf } from "react-icons/fa";
import ReactToPrint from "react-to-print";
import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { OBTENER_COMPRAS_ESTADO_DE_CUENTA } from "../../../../../../gql/Tesoreria/abonos";
import ContentDataDoc from "./ContentData";

export default function ExportarPDF({
  datosProveedor,
  proveedorID,
  filtros,
  filtrosCompra,
}) {
  const [open, setOpen] = useState(false);
  const [loadingPDFcompra, setLoadingPDFcompra] = useState(false);
  const [errorPDFcompra, setErrorPDFcompra] = useState(false);
  const [datosPDF, setDatosPDF] = useState([]);
  const client = useApolloClient();
  let ref = React.useRef();

  const handleClose = () => {
    setOpen(!open);
  };

  const getEstadoDeCuentaPDF = async () => {
    try {
      setLoadingPDFcompra(true);
      const response = await client.query({
        query: OBTENER_COMPRAS_ESTADO_DE_CUENTA,
        variables: {
          cliente: proveedorID,
          filtro: filtros,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoadingPDFcompra(false);

      if (response.data.getComprasEstadoCuenta.docs.length) {
        setDatosPDF(response.data.getComprasEstadoCuenta.docs);
      }

      if (response.error) setErrorPDFcompra(true);
    } catch (error) {
      setLoadingPDFcompra(false);
      setErrorPDFcompra(true);
      console.log(error);
    }
  };

  const handleExport = () => {
    getEstadoDeCuentaPDF();
    handleClose();
  };

  if (loadingPDFcompra) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        PDF
      </Button>
    );
  }

  if (errorPDFcompra) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        PDF
      </Button>
    );
  }

  return (
    <Box>
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<FaRegFilePdf />}
        onClick={() => handleExport()}
      >
        PDF
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle component="div">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gridGap={10}
          >
            <ReactToPrint
              trigger={() => (
                <Button
                  startIcon={<DescriptionOutlined />}
                  color="primary"
                  size="small"
                >
                  Descargar / imprimir
                </Button>
              )}
              content={() => ref.current}
            />
            <Button
              onClick={handleClose}
              color="default"
              variant="contained"
              disableElevation
              size="small"
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ContentDataDoc
            compra={datosPDF}
            refcomp={ref}
            proveedor={datosProveedor}
            filter={filtrosCompra}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
