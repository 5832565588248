import { Box, Button, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import { InfoOutlined } from "@material-ui/icons";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import moment from "moment-timezone";
import { useApolloClient } from "@apollo/client";
import {
  OBTENER_CLIENTES_ESTADO_DE_CUENTA,
  OBTENER_VENTAS_ESTADO_DE_CUENTA,
} from "../../../../../gql/Tesoreria/abonos";
import ExcelExportButton from "../../../../../components/ExcelExportButton";

export default function ExportarExcelAbonos({
  openVista,
  arrayExcel,
  datosExcel,
  setDatosExcel,
  datosFiltro,
  clienteID,
  filtros,
}) {
  const [loadingExcelCliente, setLoadingExcelCliente] = useState(false);
  const [errorExcelCliente, setErrorExcelCliente] = useState(false);
  const [loadingExcelVenta, setLoadingExcelVenta] = useState(false);
  const [errorExcelVenta, setErrorExcelVenta] = useState(false);
  const client = useApolloClient();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const getClientesEstadoDeCuentaExcel = async () => {
    try {
      setLoadingExcelCliente(true);
      const response = await client.query({
        query: OBTENER_CLIENTES_ESTADO_DE_CUENTA,
        variables: {
          empresa: sesion.empresa._id,
          filtro: datosFiltro.cliente,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoadingExcelCliente(false);

      if (response.data.getClientesEstadoCuenta.docs.length) {
        setDatosExcel(response.data.getClientesEstadoCuenta.docs);
        document.getElementById("excel-button-reportes").click();
      }

      if (response.error) setErrorExcelCliente(true);
    } catch (error) {
      setLoadingExcelCliente(false);
      setErrorExcelCliente(true);
      console.log(error);
    }
  };

  const getVentasEstadoDeCuentaExcel = async () => {
    try {
      setLoadingExcelVenta(true);
      const response = await client.query({
        query: OBTENER_VENTAS_ESTADO_DE_CUENTA,
        variables: {
          cliente: clienteID,
          filtro: filtros,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoadingExcelVenta(false);

      if (response.data.getVentasEstadoCuenta.docs.length) {
        setDatosExcel(response.data.getVentasEstadoCuenta.docs);

        document.getElementById("excel-button-reportes").click();
      }

      if (response.error) setErrorExcelVenta(true);
    } catch (error) {
      setLoadingExcelVenta(false);
      setErrorExcelVenta(true);
      console.log(error);
    }
  };

  const ExcelDownload = () => {
    if (openVista === "Clientes") {
      getClientesEstadoDeCuentaExcel();
    } else if (openVista === "Venta") {
      getVentasEstadoDeCuentaExcel();
    }
  };

  if (loadingExcelCliente || loadingExcelVenta) {
    return (
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        Excel
      </Button>
    );
  }

  if (errorExcelCliente || errorExcelVenta) {
    return (
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<InfoOutlined />}
      >
        Excel
      </Button>
    );
  }

  return (
    <Box>
      {datosExcel.length ? (
        <ExportarExcelAction
          arrayExcel={arrayExcel}
          openVista={openVista}
          datosExcel={datosExcel}
        />
      ) : (
        <Button
          variant="text"
          size="small"
          onClick={() => ExcelDownload()}
          color="primary"
          startIcon={<RiFileExcel2Line />}
        >
          Excel
        </Button>
      )}
    </Box>
  );
}

const ExportarExcelAction = ({ datosExcel, openVista, arrayExcel }) => {
  const reporteData = datosExcel.map((res) => {
    if (openVista === "Clientes") {
      arrayExcel = {
        numero_cliente: res.numero_cliente,
        nombre_cliente: res.nombre_cliente,
        clave_cliente: res.clave_cliente,
        telefono: res.telefono,
        credito_disponible: "$" + formatoMexico(res.credito_disponible),
        debe: "$" + formatoMexico(res.saldo_pendiente.total),
      };
      return arrayExcel;
    } else if (openVista === "Venta") {
      arrayExcel = {
        folio: res.folio,
        fecha_venta: moment(res.fecha_registro)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY"),
        fecha_de_vencimiento_credito: moment(res.fecha_de_vencimiento_credito)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY"),
        total: "$" + formatoMexico(res.total),
        saldo_credito_pendiente:
          "$" + formatoMexico(res.saldo_credito_pendiente),
        status: res.status === null ? "REALIZADO" : res.status,
      };
      return arrayExcel;
    } else {
      arrayExcel = {
        fecha_abono: moment(res.fecha_movimiento.completa)
          .tz("America/Mexico_City")
          .format("DD/MM/YYYY"),
        monto: "$" + formatoMexico(res.monto_total_abonado),
        numero_caja: res.numero_caja.toString(),
        turno: res.horario_turno,
        metodo_de_pago: res.metodo_de_pago.metodo,
        status: res.status,
      };
      return arrayExcel;
    }
  });

  return (
    <ExcelExportButton
      data={reporteData}
      name={
        openVista === "Clientes"
          ? `Reporte de Estado de cuenta Clientes`
          : openVista === "Venta"
          ? `Reporte de Estado de cuenta Clientes - Venta`
          : `Reporte de Estado de cuenta Clientes - Venta - Abonos`
      }
      button
    />
  );
};
