import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { formatoMexico } from "../../../config/reuserFunctions";
import { TablePagination } from "@material-ui/core";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  colorContainer: {
    height: 28,
    width: "auto",
    borderRadius: 5,
    fontSize: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

function Row({ data }) {
  const { producto, cantidad_existente, unidades_medidas } = data;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          {unidades_medidas.length ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell component="th" scope="row">
          {producto.datos_generales.nombre_comercial}
        </TableCell>
        <TableCell>{producto.datos_generales.codigo_barras}</TableCell>
        <TableCell>{producto.datos_generales.clave_alterna}</TableCell>
        <TableCell>{cantidad_existente}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Tallas Disponibles de{" "}
                {producto.datos_generales.nombre_comercial}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Código de barras</TableCell>
                    <TableCell>Medida</TableCell>
                    <TableCell>Color</TableCell>
                    <TableCell>Cantidad</TableCell>
                    <TableCell>Precio</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {unidades_medidas.map((unidad) => (
                    <ComponenteUnidades key={unidad._id} unidad={unidad} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ComponenteUnidades = ({ unidad }) => {
  const classes = useRowStyles();
  const theme = useTheme();
  return (
    <TableRow>
      <TableCell>{unidad.codigo_barras}</TableCell>
      <TableCell>{unidad.medida.talla}</TableCell>
      <TableCell>
        <div
          className={classes.colorContainer}
          style={{
            backgroundColor: unidad.color.hex,
            color: theme.palette.getContrastText(unidad.color.hex),
          }}
        >
          <Typography variant="button">{unidad.color.nombre}</Typography>
        </div>
      </TableCell>
      <TableCell>{unidad.cantidad}</TableCell>
      <TableCell>{formatoMexico(unidad.precio)}</TableCell>
    </TableRow>
  );
};

export default function TableProductos({ productos, limit, page, setPage }) {
  const handleChangePage = (_, nextPage) => {
    setPage(nextPage);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="sticky table" stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Artículo</TableCell>
              <TableCell>Código de barras</TableCell>
              <TableCell>Clave alterna</TableCell>
              <TableCell>Cantidad</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productos.docs.map((row) => (
              <Row key={row._id} data={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={productos.totalDocs.count}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}
