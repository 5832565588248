import React from "react";
import { styled } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { InputAdornment } from "@material-ui/core";
import { RiFilterFill } from "react-icons/ri";
import { ProdAlmContext } from "../Context/ProdAlmContext";

export const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: "8px 0px 4px 8px",
    height: 20,
    width: 200,
  },
  "&:hover fieldset": {
    borderColor: "#B2BAC2!important",
  },
  "&.Mui-focused fieldset": {
    border: "1px solid #B2BAC2!important",
  },
}));

export default function ExistenciasSelect() {
  const { filter, setFilter } = React.useContext(ProdAlmContext);

  const handleChange = (event) => {
    setFilter({ ...filter, existencias: event.target.value });
  };

  return (
    <FormControl variant="outlined">
      <CustomSelect
        labelId="existencia-sel"
        value={filter.existencias}
        onChange={handleChange}
        startAdornment={
          <InputAdornment position="start">
            <RiFilterFill style={{ fontSize: 16, color: "#c0c0c0" }} />
          </InputAdornment>
        }
      >
        <MenuItem value="ALL">
          <em>Todas Existencias</em>
        </MenuItem>
        <MenuItem value="NEGATIVE">Negativas</MenuItem>
        <MenuItem value="NO_STOCK">Sin existencias</MenuItem>
        <MenuItem value="STOCK">Con existencias</MenuItem>
      </CustomSelect>
    </FormControl>
  );
}
