import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
//import { BackspaceOutlined } from "@material-ui/icons";

const initial_state_montos = {
  efectivo: 0,
  tarjeta: 0,
  puntos: 0,
  transferencia: 0,
  cheque: 0,
};
const MontosRapidos = ({
  montos,
  setMontos,
  totales,
  setEfectivoConstante,
  setTotales,
  venta,
  ventaCredito,
}) => {
  const handleSum = (value, reset) => {
    const efectivo = parseFloat(montos.efectivo);
    if (efectivo === totales.total) {
      setMontos({ ...montos, efectivo: value });
      setEfectivoConstante(value);
    } else {
      setMontos({ ...montos, efectivo: efectivo + value });
      setEfectivoConstante(efectivo + value);
    }
    if (reset) {
      setMontos({ ...initial_state_montos, efectivo: value });
      setTotales({
        subtotal: venta === null ? 0 : parseFloat(venta.subTotal.toFixed(4)),
        impuestos: venta === null ? 0 : parseFloat(venta.impuestos.toFixed(4)),
        total: venta === null ? 0 : parseFloat(venta.total.toFixed(4)),
        descuento: 0,
        cambio: 0,
        monto_pagado: venta === null ? 0 : parseFloat(venta.total.toFixed(4)),
      });
      setEfectivoConstante(value);
    }
  };

  /* const handleResetMontos = () => {
    setMontos({ ...initial_state_montos, efectivo: venta.total });
    setTotales({
      subtotal: venta === null ? 0 : parseFloat(venta.subTotal.toFixed(2)),
      impuestos: venta === null ? 0 : parseFloat(venta.impuestos.toFixed(2)),
      total: venta === null ? 0 : parseFloat(venta.total.toFixed(2)),
      descuento: 0,
      cambio: 0,
      monto_pagado: venta === null ? 0 : parseFloat(venta.total.toFixed(2)),
    });
    setEfectivoConstante(venta.total);
  }; */

  return (
    <Box
      display="grid"
      gridTemplateColumns="repeat(5, minmax(100px, 1fr))"
      gridGap={10}
    >
      <Button
        variant="contained"
        disableElevation
        fullWidth
        size="large"
        disabled={ventaCredito}
        onClick={() => handleSum(20)}
      >
        $20
      </Button>
      <Button
        variant="contained"
        disableElevation
        fullWidth
        size="large"
        disabled={ventaCredito}
        onClick={() => handleSum(50)}
      >
        $50
      </Button>

      <Button
        variant="contained"
        disableElevation
        fullWidth
        size="large"
        disabled={ventaCredito}
        onClick={() => handleSum(100)}
      >
        $100
      </Button>

      <Button
        variant="contained"
        disableElevation
        fullWidth
        size="large"
        disabled={ventaCredito}
        onClick={() => handleSum(200)}
      >
        $200
      </Button>

      <Button
        variant="contained"
        disableElevation
        fullWidth
        size="large"
        disabled={ventaCredito}
        onClick={() => handleSum(500)}
      >
        $500
      </Button>

        {/* <Button
          variant="contained"
          disableElevation
          fullWidth
          size="large"
          onClick={() => handleResetMontos()}
          disabled={ventaCredito}
        >
          <BackspaceOutlined />
        </Button> */}
    </Box>
  );
};

export default MontosRapidos;
