import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Close } from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";
import { FacturacionCtx } from "../../../../../../context/Facturacion/facturacionCtx";
import { getAddressString } from "../../../NuevaFactura/initial_factura_states";
import { CustoTextField } from "../../../NuevaFactura/CustomTextField";

export default function TableUbicaciones() {
  const { complementCartaPorte, setComplementCartaPorte } = React.useContext(
    FacturacionCtx
  );

  const { Ubicaciones } = complementCartaPorte.Complemento.CartaPorte30;

  const removeItem = (index) => {
    const items = [...Ubicaciones];
    items.splice(index, 1);
    setComplementCartaPorte((st) => ({
      ...st,
      Complemento: {
        CartaPorte30: {
          ...st.Complemento.CartaPorte30,
          Ubicaciones: items,
        },
      },
    }));
  };

  const getDistance = (value, item, index) => {
    const items = [...Ubicaciones];
    const newItem = { ...item, DistanciaRecorrida: value.toString() };
    items.splice(index, 1, newItem);
    setComplementCartaPorte((st) => ({
      ...st,
      Complemento: {
        CartaPorte30: {
          ...st.Complemento.CartaPorte30,
          Ubicaciones: items,
        },
      },
    }));
  };

  return (
    <Box>
      {Ubicaciones.map((res, index) =>
        index > 0 ? (
          <Box key={index} mb={1} display="flex" alignItems="flex-start">
            <Box flexGrow={1}>
              <Typography
                variant="subtitle2"
                style={{ display: "flex", alignItems: "center" }}
              >
                {`${res.TipoUbicacion} ${index > 0 ? "#" + index : ""}: ${
                  res.Nombre
                }`}
              </Typography>
              <Typography variant="body2">{`${getAddressString(
                res.AddresWithNames
              )}`}</Typography>
              <CustoTextField
                placeholder={
                  index > 1
                    ? `Des. #${index - 1} a destino #${index}`
                    : `De origen a destino #${index}`
                }
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>Distancia:</Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">km</InputAdornment>
                  ),
                }}
                onChange={(e) => getDistance(e.target.value, res, index)}
                type="number"
                error={!res.DistanciaRecorrida}
                value={res.DistanciaRecorrida}
              />
            </Box>
            <Box display="flex" gridColumnGap={10}>
              <IconButton size="small" onClick={() => removeItem(index)}>
                <Close />
              </IconButton>
            </Box>
          </Box>
        ) : null
      )}
    </Box>
  );
}
