import * as React from "react";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import { TraspasosContext } from "../Context/TraspasosContext";
import TraspasoTableBody from "./TableBody";
import {
  getComparator,
  stableSort,
} from "../../../../../config/reuserFunctions";
import TraspasoTableHead from "./TableHead";

export default function TraspasoTable() {
  const { traspasosQuery, setPageConfig, pageConfig } = React.useContext(
    TraspasosContext
  );
  const { docs, totalDocs } = traspasosQuery?.data?.getTraspasos;
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("fecha_registro");
  const tableEl = React.useRef();

  const renderSorted = React.useMemo(() => {
    return stableSort(docs, getComparator(order, orderBy));
  }, [order, orderBy, docs]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, page) => {
    setPageConfig((st) => ({ ...st, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    setPageConfig({ limit: parseInt(event.target.value, 10), page: 0 });
  };

  return (
    <>
      <Paper variant="outlined">
        <TableContainer
          ref={tableEl}
          style={{
            height: "80vh",
            borderRadius: 2,
          }}
        >
          <Table stickyHeader size="small">
            <TraspasoTableHead
              handleSort={handleSort}
              order={order}
              orderBy={orderBy}
            />
            <TraspasoTableBody traspasos={renderSorted} />
          </Table>
        </TableContainer>
      </Paper>
      <TablePagination
        component="div"
        count={totalDocs}
        page={pageConfig.page}
        onPageChange={handleChangePage}
        rowsPerPage={pageConfig.limit}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
