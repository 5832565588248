import React, { createContext } from "react";
import { MainContext } from "../../../../../context/MainCtx";
import { useDebounce } from "use-debounce";
import { useQuery } from "@apollo/client";
import { GetProductAlmacens } from "../graphql/queries";
import { OBTENER_CONSULTAS } from "../../../../../gql/Catalogos/productos";

export const ProdAlmContext = createContext();

const ProdAlmCtxProvider = ({ children }) => {
  const { tokenDecoded } = React.useContext(MainContext);
  const initialFilter = {
    producto: "",
    nombre_marca: "",
    nombre_departamentos: "",
    categoria: "",
    almacen: "",
    existencias: "ALL"
  };
  const [filter, setFilter] = React.useState(initialFilter);
  const [pageConfig, setPageConfig] = React.useState({ page: 0, limit: 25 });
  const [FILTER] = useDebounce(filter, 500);
  const { page, limit } = pageConfig;
  const [tableRef, setTableRef] = React.useState(React.useRef(null))

  const almacensQuery = useQuery(GetProductAlmacens, {
    variables: {
      input: {
        empresa: tokenDecoded?.empresa._id,
        sucursal: tokenDecoded?.sucursal._id,
        filtro: {
          producto: FILTER.producto,
          marca: FILTER.nombre_marca,
          departamento: FILTER.nombre_departamentos,
          categoria: FILTER.categoria,
          existencias: FILTER.existencias,
        },
      },
      offset: page,
      limit,
    },
    fetchPolicy: "network-only",
  });

  const queryCats = useQuery(OBTENER_CONSULTAS, {
    variables: {
      empresa: tokenDecoded?.empresa._id,
      sucursal: tokenDecoded?.sucursal._id,
    },
    fetchPolicy: "network-only",
  });

  return (
    <ProdAlmContext.Provider
      value={{
        filter,
        setFilter,
        initialFilter,
        almacensQuery,
        pageConfig,
        setPageConfig,
        queryCats,
        tableRef,
        setTableRef
      }}
    >
      {children}
    </ProdAlmContext.Provider>
  );
};

export default ProdAlmCtxProvider;
