import { useApolloClient } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDebounce } from "use-debounce";
import { useEffect } from "react";
import { Close, Search } from "@material-ui/icons";
import ExportarExcelAbonosProveedores from "./ExportarExcelAbonosProveedores";
import TablaEstadoDeCuentaProveedores from "./TablaEstadoDeCuentaProveedores";
import ComponentOnline from "../../../../../components/Connection/ComponentOnline";
import ErrorPage from "../../../../../components/ErrorPage";
import {
  OBTENER_ABONOS_ESTADO_DE_CUENTA,
  OBTENER_COMPRAS_ESTADO_DE_CUENTA,
  OBTENER_PROVEEDORES_ESTADO_DE_CUENTA,
} from "../../../../../gql/Tesoreria/abonos";
import { CustomSearchTextField } from "../../../../../components/CustomMuiComponents";
import ExportarPDF from "./ExportPDF";

const initial_state_filtro = {
  proveedor: "",
};

const initial_state_filtro_compra = {
  fecha_inicio: "",
  fecha_fin: "",
  folio: "",
};

export default function ReportesAbonosProveedores({ handleClickOpen }) {
  const [openVista, setOpenVista] = useState("Proveedores");
  const [datosFiltro, setDatosFiltro] = useState(initial_state_filtro);
  const [value] = useDebounce(datosFiltro, 1000);
  const [datosProveedor, setDatosProveedor] = useState([]);
  const [datosCompra, setDatosCompra] = useState([]);
  const [compraID, setCompraID] = useState("");
  const [datosExcel, setDatosExcel] = useState([]);
  const [proveedor, setProveedor] = useState([]);
  const [docsProveedores, setDocsProveedores] = useState(0);
  const [loadingProveedor, setLoadingProveedor] = useState(false);
  const [errorProveedor, setErrorProveedor] = useState(false);
  const [offsetProveedores, setOffsetProveedores] = useState(0);
  const [proveedorID, setProveedorID] = useState("");
  const [proveedorNombre, setProveedorNombre] = useState("");
  const [compra, setCompra] = useState([]);
  const [docsCompra, setDocsCompra] = useState(0);
  const [loadingCompra, setLoadingCompra] = useState(false);
  const [errorCompra, setErrorCompra] = useState(false);
  const [loadingAbono, setLoadingAbono] = useState(false);
  const [errorAbono, setErrorAbono] = useState(false);
  const [abonos, setAbonos] = useState([]);
  const [saldoTotalPendiente, setSaldoTotalPendiente] = useState({});
  const [offsetCompras, setOffsetCompras] = useState(0);
  const [filtrosCompra, setFiltrosCompra] = useState(
    initial_state_filtro_compra
  );
  const [filtros] = useDebounce(filtrosCompra, 1000);
  let arrayExcel = {};
  const limitProveedores = 25;
  const limitCompras = 25;

  const limpiarDatos = () => {
    setDatosFiltro(initial_state_filtro);
    setFiltrosCompra(initial_state_filtro_compra);
    setOffsetProveedores(0);
    setOffsetCompras(0);
  };

  useEffect(() => {
    limpiarDatos();
  }, [openVista]);

  useEffect(() => {
    getProveedoresEstadoDeCuenta();
  }, [offsetProveedores]);

  useEffect(() => {
    if (proveedorID !== "") {
      getComprasEstadoDeCuenta();
    }
  }, [offsetCompras]);

  const handleClickOpenProveedores = () => {
    setOpenVista("Proveedores");
    setDatosExcel([]);
  };

  const handleClickOpenCompras = (proveedor) => {
    setOpenVista("Compras");
    if (proveedor._id) {
      setDatosProveedor(proveedor);
      setProveedorID(proveedor._id);
      setProveedorNombre(proveedor.nombre_cliente);
    }
    setDatosExcel([]);
  };

  const handleClickOpenAbonos = (compra) => {
    setOpenVista("Abonos");
    if (compra._id) {
      setDatosCompra(compra);
      setCompraID(compra._id);
    }
    setDatosExcel([]);
  };

  const obtenerCampos = (e) => {
    const { name, value } = e.target;
    setDatosFiltro({
      ...datosFiltro,
      [name]: value,
    });
  };

  const obtenerCamposCompra = (e) => {
    const { name, value } = e.target;
    setFiltrosCompra({
      ...filtrosCompra,
      [name]: value,
    });
  };

  const client = useApolloClient();
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const getProveedoresEstadoDeCuenta = async () => {
    try {
      setLoadingProveedor(true);
      const response = await client.query({
        query: OBTENER_PROVEEDORES_ESTADO_DE_CUENTA,
        variables: {
          empresa: sesion.empresa._id,
          filtro: datosFiltro.proveedor,
          limit: limitProveedores,
          offset: offsetProveedores,
        },
        fetchPolicy: "network-only",
      });
      setLoadingProveedor(false);

      if (response.data) {
        setProveedor(response.data.getProveedoresEstadoCuenta.docs);
        setDocsProveedores(response.data.getProveedoresEstadoCuenta.totalDocs);
      }

      if (response.error) setErrorProveedor(true);
    } catch (error) {
      setLoadingProveedor(false);
      setErrorProveedor(true);
      console.log(error.networkError.result);
    }
  };

  const getComprasEstadoDeCuenta = async () => {
    try {
      setLoadingCompra(true);
      const response = await client.query({
        query: OBTENER_COMPRAS_ESTADO_DE_CUENTA,
        variables: {
          cliente: proveedorID,
          limit: limitCompras,
          offset: offsetCompras,
          filtro: filtros,
        },
        fetchPolicy: "network-only",
      });
      setLoadingCompra(false);

      if (response.data) {
        setCompra(response.data.getComprasEstadoCuenta.docs);
        setDocsCompra(response.data.getComprasEstadoCuenta.totalDocs);
        setSaldoTotalPendiente(
          response.data.getComprasEstadoCuenta.saldoPendienteTotal
        );
      }

      if (response.error) setErrorCompra(true);
    } catch (error) {
      setLoadingCompra(false);
      setErrorCompra(true);
      console.log(error);
    }
  };

  const getAbonosEstadoDeCuenta = async () => {
    try {
      setLoadingAbono(true);
      const response = await client.query({
        query: OBTENER_ABONOS_ESTADO_DE_CUENTA,
        variables: {
          id: compraID,
          tipo: "id_compra",
        },
        fetchPolicy: "network-only",
      });
      setLoadingAbono(false);

      if (response.data) {
        setAbonos(response.data.getAbonosEstadoCuenta);
        setDatosExcel(response.data.getAbonosEstadoCuenta);
      }

      if (response.error) setErrorAbono(true);
    } catch (error) {
      setLoadingAbono(false);
      setErrorAbono(true);
      console.log(error.networkError.result);
    }
  };

  useEffect(() => {
    setOffsetProveedores(0);
    setOffsetCompras(0);
    getProveedoresEstadoDeCuenta();
  }, [value]);

  useEffect(() => {
    if (proveedorID !== "") {
      getComprasEstadoDeCuenta();
      setOffsetProveedores(0);
      setOffsetCompras(0);
    }
  }, [proveedorID, filtros]);

  useEffect(() => {
    if (compraID !== "") {
      getAbonosEstadoDeCuenta();
    }
  }, [compraID]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        top={10}
        right={20}
        gridGap={10}
      >
        <ComponentOnline classes />

        {openVista === "Proveedores" ? (
          <CustomSearchTextField
            placeholder="Buscar"
            onChange={(e) => obtenerCampos(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={limpiarDatos}>
                    <Close fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={datosFiltro.proveedor}
            variant="outlined"
            size="small"
            name="proveedor"
          />
        ) : null}

        <ExportarExcelAbonosProveedores
          openVista={openVista}
          arrayExcel={arrayExcel}
          datosExcel={datosExcel}
          setDatosExcel={setDatosExcel}
          datosFiltro={datosFiltro}
          proveedorID={proveedorID}
          filtros={filtros}
        />

        {openVista === "Compras" ? (
          <ExportarPDF
            datosProveedor={datosProveedor}
            proveedorID={proveedorID}
            filtros={filtros}
            filtrosCompra={filtrosCompra}
          />
        ) : null}
        <Button
          variant="contained"
          color="default"
          disableElevation
          size="small"
          onClick={handleClickOpen}
          startIcon={<Close />}
        >
          Cerrar
        </Button>
      </Box>
      <ClientesRender
        openVista={openVista}
        datosProveedor={datosProveedor}
        datosCompra={datosCompra}
        handleClickOpenProveedores={handleClickOpenProveedores}
        handleClickOpenCompras={handleClickOpenCompras}
        handleClickOpenAbonos={handleClickOpenAbonos}
        proveedor={proveedor}
        compra={compra}
        loadingProveedor={loadingProveedor}
        loadingCompra={loadingCompra}
        loadingAbono={loadingAbono}
        errorProveedor={errorProveedor}
        errorCompra={errorCompra}
        errorAbono={errorAbono}
        proveedorNombre={proveedorNombre}
        offsetProveedores={offsetProveedores}
        setOffsetProveedores={setOffsetProveedores}
        limitProveedores={limitProveedores}
        docsProveedores={docsProveedores}
        offsetCompras={offsetCompras}
        setOffsetCompras={setOffsetCompras}
        limitCompras={limitCompras}
        docsCompra={docsCompra}
        abonos={abonos}
        saldoTotalPendiente={saldoTotalPendiente}
        obtenerCamposCompra={obtenerCamposCompra}
        filtrosCompra={filtrosCompra}
        limpiarDatos={limpiarDatos}
      />
    </>
  );
}

const ClientesRender = ({
  openVista,
  datosProveedor,
  datosCompra,
  handleClickOpenProveedores,
  handleClickOpenCompras,
  handleClickOpenAbonos,
  proveedor,
  compra,
  loadingProveedor,
  loadingCompra,
  loadingAbono,
  errorProveedor,
  errorCompra,
  errorAbono,
  proveedorNombre,
  offsetProveedores,
  setOffsetProveedores,
  limitProveedores,
  docsProveedores,
  offsetCompras,
  setOffsetCompras,
  limitCompras,
  docsCompra,
  abonos,
  saldoTotalPendiente,
  obtenerCamposCompra,
  filtrosCompra,
  limpiarDatos,
}) => {
  if (loadingProveedor || loadingCompra || loadingAbono) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (errorProveedor || errorCompra || errorAbono) {
    <ErrorPage
      error={
        errorProveedor ? errorProveedor : errorCompra ? errorCompra : errorAbono
      }
    />;
  }

  return (
    <>
      <TablaEstadoDeCuentaProveedores
        openVista={openVista}
        datosProveedor={datosProveedor}
        datosCompra={datosCompra}
        handleClickOpenProveedores={handleClickOpenProveedores}
        handleClickOpenCompras={handleClickOpenCompras}
        handleClickOpenAbonos={handleClickOpenAbonos}
        proveedor={proveedor}
        compra={compra}
        proveedorNombre={proveedorNombre}
        offsetProveedores={offsetProveedores}
        setOffsetProveedores={setOffsetProveedores}
        limitProveedores={limitProveedores}
        docsProveedores={docsProveedores}
        offsetCompras={offsetCompras}
        setOffsetCompras={setOffsetCompras}
        limitCompras={limitCompras}
        docsCompra={docsCompra}
        abonos={abonos}
        saldoTotalPendiente={saldoTotalPendiente}
        obtenerCamposCompra={obtenerCamposCompra}
        filtrosCompra={filtrosCompra}
        limpiarDatos={limpiarDatos}
      />
    </>
  );
};
