import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FacturacionCtx } from "../../../../../../context/Facturacion/facturacionCtx.js";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import CfdisList from "./CfdisList.jsx";
import { CustoTextField } from "../../CustomTextField.jsx";

const tipoRelacion = [
  {
    code: "01",
    description: "Nota de crédito de los documentos relacionados.",
  },
  { code: "02", description: "Nota de débito de los documentos relacionados." },
  {
    code: "03",
    description: "Devolución de mercancía sobre facturas o traslados previos.",
  },
  { code: "04", description: "Sustitución de CFDI previos." },
  {
    code: "05",
    description: "Traslados de mercancías facturados previamente.",
  },
  { code: "06", description: "Factura generada por los traslados previos." },
  { code: "07", description: "CFDI por aplicación de anticipo." },
];

export default function CfdiRelacionado({ setAlert }) {
  const { datosFactura, setDatosFactura, isCfdiRelated } = React.useContext(
    FacturacionCtx
  );

  const obtenerMotivo = (Type) => {
    setDatosFactura({
      ...datosFactura,
      Relations: {
        ...datosFactura.Relations,
        Type,
      },
    });
  };

  const handleDeleteRelated = () => {
    setDatosFactura({
      ...datosFactura,
      Relations: {
        ...datosFactura.Relations,
        Cfdis: [{ Uuid: "" }],
      },
    });
  };

  let Relations = datosFactura?.Relations?.Cfdis[0]?.Uuid;

  return (
    <Box>
      <CustoTextField
        value={Relations ? Relations : ""}
        size="small"
        variant="outlined"
        fullWidth
        margin="dense"
        error={isCfdiRelated && !Relations}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Typography>CFDI relacionado</Typography>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                style={{
                  display: Relations ? "flex" : "none",
                }}
                size="small"
                onClick={handleDeleteRelated}
              >
                <Close />
              </IconButton>
              <CfdisList setAlert={setAlert} />
            </InputAdornment>
          ),
          readOnly: true,
          disabled: true,
        }}
      />
      <Box hidden={!isCfdiRelated}>
        <FormControl
          variant="outlined"
          size="small"
          error={isCfdiRelated && !datosFactura.Relations?.Type}
          fullWidth
          margin="dense"
        >
          <Select
            value={
              datosFactura.Relations?.Type ? datosFactura.Relations?.Type : ""
            }
            onChange={(e) => obtenerMotivo(e.target.value)}
            style={{
              paddingLeft: 4,
              height: 32,
            }}
            startAdornment={
              <InputAdornment position="start">Tipo de relación</InputAdornment>
            }
          >
            <MenuItem value="">
              <em>Selecciona uno</em>
            </MenuItem>
            {tipoRelacion.map((motivo, index) => (
              <MenuItem
                key={index}
                value={motivo.code}
              >{`${motivo.code} - ${motivo.description}`}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}
