import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Divider, Slide, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import ComponentOnline from "../../../../components/Connection/ComponentOnline";
import { FcTemplate } from "react-icons/fc";
import PrincipalData from "./PrincipalData";
import {
  TiendaCtx,
  TiendaProvider,
} from "../../../../context/Empresa/tiendaCtx";
import { useQuery } from "@apollo/client";
import { OBTENER_TIENDA } from "../../../../gql/Empresa/tienda";
import NoMatchPage from "../../../../components/NoMatchPage";
import CustomLoader from "../../../../components/CustomLoader";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import BannerPrincipal from "./Banners";

const useStyles = makeStyles((theme) => ({
  icon: {
    width: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TiendaEmpresa() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <TiendaProvider>
      <div>
        <Button fullWidth onClick={handleClickOpen}>
          <Box display="flex" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <FcTemplate style={{ fontSize: 100 }} />
            </Box>
            Tienda
          </Box>
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <DialogTitle component="div" style={{ padding: 0 }}>
            <Box display="flex" px={2} py={0.5}>
              <Typography variant="h6">Administrar Tienda</Typography>
              <Box flexGrow={1} />
              <ComponentOnline classes={classes} fromVentas={false} />
              <Box mx={1} />
              <Button
                color="primary"
                startIcon={<ArrowBack />}
                onClick={handleClose}
              >
                Regresar
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent>
            <RenderTienda />
          </DialogContent>
        </Dialog>
      </div>
    </TiendaProvider>
  );
}

const RenderTienda = () => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { setTienda, alert, setAlert } = React.useContext(TiendaCtx);

  const { data, loading, error, refetch } = useQuery(OBTENER_TIENDA, {
    variables: {
      empresa: sesion.empresa._id,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (data && data.obtenerTiendaModel)
      setTienda({ data: data.obtenerTiendaModel, refetch });
  }, [data, setTienda, refetch]);

  if (loading) return <CustomLoader />;
  if (error) return <NoMatchPage message={error.message} />;

  return (
    <Box>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <PrincipalData />
      <Box my={1} />
      <Divider />
      <BannerPrincipal />
    </Box>
  );
};
