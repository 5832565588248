import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import { FacturacionCtx } from "../../../../../../context/Facturacion/facturacionCtx.js";
import { makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Transportistas from "./Transportistas.jsx";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(1),
    height: "100%",
    minHeight: 100,
    borderColor: grey[200],
  },
  title: {
    display: "flex",
    alignItems: "center",
    columnGap: 10,
    position: "absolute",
    top: -30,
    left: -18,
    backgroundColor: "white",
    padding: "0 8px",
  },
}));

export default function TransportistaCartaPorte() {
  const { complementCartaPorte } = React.useContext(FacturacionCtx);
  const classes = useStyles();
  const { FiguraTransporte } = complementCartaPorte.Complemento.CartaPorte30;

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box position="relative">
        <Box className={classes.title}>
          <Typography><b>Transportista</b></Typography>
          <Transportistas />
        </Box>
        <Box hidden={!FiguraTransporte.length}>
          <Typography variant="subtitle2">
            <b>Nombre </b> {FiguraTransporte[0]?.NombreFigura}
          </Typography>
          <Typography variant="subtitle2">
            <b>RFC </b> {FiguraTransporte[0]?.RFCFigura}
          </Typography>
          <Typography variant="subtitle2">
            <b>Licencia </b> {FiguraTransporte[0]?.NumLicencia}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
}
