import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { Box, DialogContent, IconButton } from "@material-ui/core";
import HistorialCuentas from "./HistorialCuentas";
import { OBTENER_CUENTAS } from "../../../../gql/Catalogos/centroCostos";
import { OBTENER_CAJAS } from "../../../../gql/Cajas/cajas";
import { useQuery } from "@apollo/client";
import { ArrowBack, ClearOutlined } from "@material-ui/icons";
import RetiroDeposito from "./RetiroDeposito";
import { FcSalesPerformance } from "react-icons/fc";
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(3),
    marginBottom: "10px",
    marginTop: "5px",
  },
  icon: {
    width: 100,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CajaPrincipal() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tipoMovimiento, setTipoMovimiento] = useState("");
  const [cuentaConcepto, setCuentaConcepto] = useState({
    cuenta: {},
    subCuenta: {},
    subcuentas: [],
  });
  const [origen, setOrigen] = useState("");
  const [destino, setDestino] = useState({});
  const [page, setPage] = useState(0);
  const [datosFiltro, setDatosFiltro] = useState({});
  /* const sesion = JSON.parse(localStorage.getItem("sesionCafi")); */
  const handleClickOpen = () => setOpen(!open);

  const limpiarFiltros = () => {
    setTipoMovimiento("");
    setCuentaConcepto({ cuenta: {}, subCuenta: {}, subcuentas: [] });
    setOrigen({});
    setDestino({});
    setPage(0);
    setDatosFiltro({
      fecha_inicio: "",
      tipo_movimiento: "",
      concepto: "",
      destino: "",
      origen: "",
    });
  };

  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [reload, setReload] = useState(false);
  const [openDoMove, setOpenDoMove] = useState(false);
  const [rowSelectedToEdit, setRowSelectedToEdit] = useState({});

  const [cajas, setCajas] = useState([]);

  const queryObtenerCajas = useQuery(OBTENER_CAJAS, {
    variables: {
      sucursal: `${sesion.sucursal._id}`,
      empresa: `${sesion.empresa._id}`,
    },

    fetchPolicy: "network-only",
  });
  const queryObtenerCuentas = useQuery(OBTENER_CUENTAS, {
    variables: {
      empresa: sesion.empresa._id,
    },
  });

  useEffect(() => {
    try {
      if (queryObtenerCajas.data) {
        setCajas(queryObtenerCajas.data.obtenerCajasSucursal);
      }
    } catch (error) {}
  }, [queryObtenerCajas.data]);

  if (queryObtenerCajas.loading || queryObtenerCuentas.loading) {
    return <div />;
  }

  return (
    <div>
      <Button fullWidth onClick={handleClickOpen}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <FcSalesPerformance style={{ fontSize: 100 }} />
          </Box>
          Caja principal
        </Box>
      </Button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth="lg"
        fullScreen
      >
        <Typography variant="h6" className={classes.title}>
          Caja principal
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          position="absolute"
          top={5}
          right={10}
        >
          <Button
            color="primary"
            onClick={() => limpiarFiltros()}
            startIcon={<ClearOutlined />}
          >
            Limpiar filtros
          </Button>
          <Box mx={1} />
          <RetiroDeposito
            tipo={false}
            setReload={setReload}
            cajas={cajas}
            refetchCuentas={queryObtenerCuentas.refetch}
            cuentas={queryObtenerCuentas.data.obtenerCuentas}
            open={openDoMove}
            setOpen={setOpenDoMove}
            rowSelectedToEdit={rowSelectedToEdit}
            setRowSelectedToEdit={setRowSelectedToEdit}
          />
          <Box mx={1} />
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
          >
            <ArrowBack />
          </IconButton>
        </Box>
        <DialogContent>
          <CajaPrincipalContent
            reload={reload}
            setReload={setReload}
            cajas={cajas}
            refetchCuentas={queryObtenerCuentas.refetch}
            cuentas={queryObtenerCuentas.data.obtenerCuentas}
            datosFiltro={datosFiltro}
            cuentaConcepto={cuentaConcepto}
            page={page}
            setPage={setPage}
            setDatosFiltro={setDatosFiltro}
            setTipoMovimiento={setTipoMovimiento}
            setOrigen={setOrigen}
            setDestino={setDestino}
            setCuentaConcepto={setCuentaConcepto}
            tipoMovimiento={tipoMovimiento}
            origen={origen}
            destino={destino}
            setOpenDoMove={setOpenDoMove}
            setRowSelectedToEdit={setRowSelectedToEdit}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const CajaPrincipalContent = ({
  tipo,
  reload,
  setReload,
  cajas,
  refetchCuentas,
  cuentas,

  datosFiltro,
  cuentaConcepto,
  page,
  setPage,
  setDatosFiltro,
  setTipoMovimiento,
  setOrigen,
  setDestino,
  setCuentaConcepto,
  tipoMovimiento,
  origen,
  destino,
  setOpenDoMove,
  setRowSelectedToEdit,
}) => {
  return (
    <HistorialCuentas
      tipo={false}
      reload={reload}
      setReload={setReload}
      cajas={cajas}
      refetchCuentas={refetchCuentas}
      cuentas={cuentas}
      datosFiltro={datosFiltro}
      cuentaConcepto={cuentaConcepto}
      page={page}
      setPage={setPage}
      setDatosFiltro={setDatosFiltro}
      setTipoMovimiento={setTipoMovimiento}
      setOrigen={setOrigen}
      setDestino={setDestino}
      setCuentaConcepto={setCuentaConcepto}
      tipoMovimiento={tipoMovimiento}
      origen={origen}
      destino={destino}
      setOpenDoMove={setOpenDoMove}
      setRowSelectedToEdit={setRowSelectedToEdit}
    />
  );
};
