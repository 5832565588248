import { Box, Grid, Tab, Tabs } from "@material-ui/core";
import React, { useEffect } from "react";
import HeadIconsReportesCajas from "./HeadIconsReportesCajas";

export default function TabsReportesCajas({
  classes,
  value,
  handleChange,
  obtenerCajasSucursal,
  setCajaSelected,
  a11yProps,
  handleClickOpen,
  datosBuscar,
  setDatosBuscar,
  cajaSelected,
}) {
  return (
    <Box className={classes.root}>
      <Grid container mr={5}>
        <Grid item md={7} lg={8} style={{flexGrow: 1}}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="on"
          >
            {obtenerCajasSucursal?.map((caja, index) => {
              return (
                <Tab
                  label={`Caja ${caja.numero_caja}`}
                  {...a11yProps(index)}
                  onClick={() => {
                    setCajaSelected(caja);
                  }}
                />
              );
            })}
          </Tabs>
        </Grid>
        <Grid item xs={12} md={5} lg={4} style={{display: "flex", justifyContent: "flex-end"}}>
          <HeadIconsReportesCajas
            cajaSelected={cajaSelected}
            datosBuscar={datosBuscar}
            setDatosBuscar={setDatosBuscar}
            handleClickOpen={handleClickOpen}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
