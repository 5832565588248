import React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { RiFileExcel2Line } from "react-icons/ri";
import { useApolloClient } from "@apollo/client";
import { InfoOutlined } from "@material-ui/icons";
import { OBTENER_PRODUCTOS } from "../../../../gql/Catalogos/productos";
import ExcelExportButton from "../../../../components/ExcelExportButton";

export default function ExportarProductosExcel({ filtros, isOnline }) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getProductos = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: OBTENER_PRODUCTOS,
        variables: {
          sucursal: sesion.sucursal._id,
          empresa: sesion.empresa._id,
          filtros,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoading(false);
      if (response.data) {
        setData(response.data.obtenerProductos.docs);
        //document.getElementById("export-excel-button").click();
      }
      if (response.error) setError(true);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    getProductos();
  };

  if (loading) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        Exportar Excel
      </Button>
    );
  }

  if (error) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        Exportar
      </Button>
    );
  }

  return (
    <div>
      {data ? (
        <ExportarExcelAction datosExcel={data} />
      ) : (
        <Button
          variant="text"
          color="primary"
          startIcon={<RiFileExcel2Line />}
          onClick={handleClickOpen}
          disabled={!isOnline}
        >
          Exportar
        </Button>
      )}
    </div>
  );
}

const ExportarExcelAction = ({ datosExcel }) => {
  const productos = datosExcel.map((producto) => {
    const lista_productos = {
      codigo_barras: producto.datos_generales.codigo_barras
        ? producto.datos_generales.codigo_barras
        : "",
      clave_alterna: producto.datos_generales.clave_alterna,
      nombre_comercial: producto.datos_generales.nombre_comercial,
      cantidad_existente: producto.inventario_general[0]
        ? producto.inventario_general[0].cantidad_existente +
          " " +
          producto.inventario_general[0].unidad_inventario
        : "",
      tipo_producto: producto.datos_generales.tipo_producto,
      clave_producto_sat: producto.datos_generales.clave_producto_sat.Value
        ? producto.datos_generales.clave_producto_sat.Value
        : "",
      categoria: producto.datos_generales.categoria
        ? producto.datos_generales.categoria
        : "",
      subcategoria: producto.datos_generales.subcategoria
        ? producto.datos_generales.subcategoria
        : "",
      departamento: producto.datos_generales.departamento
        ? producto.datos_generales.departamento
        : "",
      marca: producto.datos_generales.marca
        ? producto.datos_generales.marca
        : "",
      receta_farmacia:
        producto.datos_generales.receta_farmacia === true ? "SI" : "NO",
      monedero_electronico: producto.precios.monedero_electronico
        ? producto.precios.monedero_electronico
        : "",
      unidad_de_compra_unidad: producto.precios.unidad_de_compra.unidad
        ? producto.precios.unidad_de_compra.unidad
        : "",
      unidad_de_compra_cantidad: producto.precios.unidad_de_compra.cantidad
        ? producto.precios.unidad_de_compra.cantidad
        : "",
      precio_con_impuesto:
        "$" + producto.precios.precio_de_compra.precio_con_impuesto,
      precio_de_compra_iva: producto.precios.precio_de_compra.iva
        ? "$" + producto.precios.precio_de_compra.iva
        : "",
      precio_de_compra_ieps: producto.precios.precio_de_compra.ieps
        ? "$" + producto.precios.precio_de_compra.ieps
        : "",
      precios_venta_1: "$" + producto.precios.precios_producto[0].precio_venta,
      precios_venta_2: "$" + producto.precios.precios_producto[1].precio_venta,
      precios_venta_3: "$" + producto.precios.precios_producto[2].precio_venta,
      precios_venta_4: "$" + producto.precios.precios_producto[3].precio_venta,
      precios_venta_5: "$" + producto.precios.precios_producto[4].precio_venta,
      precios_venta_6: "$" + producto.precios.precios_producto[5].precio_venta,
      descuento:
        (producto.unidades_de_venta[0] &&
          producto.unidades_de_venta[0].descuento_activo === true) ||
        (producto.medidas_producto[0] &&
          producto.medidas_producto[0].descuento_activo === true)
          ? "SI"
          : "NO",
      unidad_de_venta:
        producto.unidades_de_venta[0].unidad_principal &&
        producto.unidades_de_venta[0].unidad_principal === true
          ? producto.unidades_de_venta[0].unidad
          : producto.unidades_de_venta[1].unidad,

      unidad_de_venta_precio:
        producto.unidades_de_venta[0].unidad_principal &&
        producto.unidades_de_venta[0].unidad_principal === true
          ? "$" + producto.unidades_de_venta[0].precio
          : "$" + producto.unidades_de_venta[1].precio,
    };
    return lista_productos;
  });
  return (
    <ExcelExportButton
      data={productos}
      name="Lista de productos"
      button
      id="export-excel-button"
    />
  );
};
