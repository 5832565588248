import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import CotizacionesPendientes from "./CotizacionesPendientes";
import { Description } from "@material-ui/icons";
import { MainContext } from "../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListaCotizaciones() {
  const [open, setOpen] = useState(false);
  //const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { turnoEnCurso } = React.useContext(MainContext);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 84) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, []);

  return (
    <>
      <ListItem
        button
        onClick={() => handleClickOpen()}
        disabled={!turnoEnCurso}
      >
        <ListItemIcon>
          <Description />
        </ListItemIcon>
        <ListItemText primary="Cotizaciones" />
      </ListItem>
      <Dialog
        fullWidth
        open={open}
        maxWidth={"md"}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle component="div" style={{ padding: "0px 16px" }}>
          <Box display="flex" alignItems="center">
            <Box p={1} display={"flex"} alignItems={"center"}>
              <Typography variant="h6">Cotizaciones pendientes</Typography>
            </Box>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpen(!open)}
              size="medium"
            >
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <CotizacionesPendientes setOpen={setOpen} />
        </DialogContent>
      </Dialog>
    </>
  );
}
