import React from "react";
import { Box } from "@material-ui/core";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import { useNavigate } from "react-router-dom";
import Acceso from "../../../../components/AccesosPassword/Acceso";
import AgregarProductoVenta from "../../../ventas/InputsVentasGen.js/AgregarProducto";
import BuscarCliente from "../../../ventas/ClientesVentas/BuscarCliente";
import AgregarTipoVenta from "../../../ventas/InputsVentasGen.js/TipoVenta";

export default function NavbarTicketPanel() {
  const navigate = useNavigate();
  const { reloadAdministrador, setReloadAdministrador } = React.useContext(
    AccesosContext
  );

  React.useEffect(() => {
    if (reloadAdministrador === true) {
      navigate("/admin");
      setReloadAdministrador(false);
    }
  }, [reloadAdministrador]);

  return (
    <Box>
      <Acceso setReloadAdministrador={setReloadAdministrador} />
      <Box display="flex" p={1} py={1.5}>
        <AgregarProductoVenta hidden />
        <AgregarTipoVenta />
        <Box flexGrow={1} />
        <BuscarCliente />
      </Box>
    </Box>
  );
}
