import React, { useContext, useEffect } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { AccesosContext } from "../../context/Accesos/accesosCtx";
import { Cloud, CloudDone } from "@material-ui/icons";
import { MainContext } from "../../context/MainCtx";

const SwitchAutoVentaToCloud = () => {
  const { ventaToCloud, setVentaToCloud } = useContext(AccesosContext);
  const { isWeb } = React.useContext(MainContext);

  const handleChange = (value) => {
    localStorage.setItem("ventaToCloud", JSON.stringify(value));
    setVentaToCloud(value);
  };

  useEffect(() => {
    if (ventaToCloud === null) {
      localStorage.setItem("ventaToCloud", true);
      setVentaToCloud(true);
    }
  }, [ventaToCloud]);

  if (isWeb) return null;

  return (
    <ListItem button onClick={() => handleChange(!ventaToCloud)}>
      <ListItemIcon>
        {ventaToCloud ? <CloudDone color="primary" /> : <Cloud />}
      </ListItemIcon>
      <ListItemText primary={ventaToCloud ? "AutoSync On" : "AutoSync Off"} />
    </ListItem>
  );
};

export default SwitchAutoVentaToCloud;
