import React, { useEffect } from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { RiFileExcel2Line } from "react-icons/ri";
import {
  formatoFechaCorta,
  formatoMexico,
} from "../../../../../config/reuserFunctions";
import { formaPago } from "../../../Facturacion/catalogos";
import { useApolloClient } from "@apollo/client";
import { OBTENER_VENTAS_REPORTES } from "../../../../../gql/Ventas/ventas_generales";
import { InfoOutlined } from "@material-ui/icons";
import moment from "moment";
import ExcelExportButton from "../../../../../components/ExcelExportButton";

export default function ExportarVentas({ filtros }) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [data, setData] = React.useState(false);

  const client = useApolloClient();

  /* Queries */
  const getProductos = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: OBTENER_VENTAS_REPORTES,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          filtros,
          limit: 0,
          offset: 0,
        },
        fetchPolicy: "network-only",
      });
      setLoading(false);
      if (response.data) {
        setData(response.data.obtenerVentasReportes.docs);
        document.getElementById("export-excel-button-ventas-productos")?.click();
      }
      if (response.error) setError(true);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  const handleClickOpen = () => {
    getProductos();
  };

  if (loading) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        Excel
      </Button>
    );
  }

  if (error) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        Excel
      </Button>
    );
  }

  return (
    <div>
      {data ? (
        <ExportarExcelAction datosExcel={data} />
      ) : (
        <Button
          variant="text"
          color="primary"
          startIcon={<RiFileExcel2Line />}
          onClick={handleClickOpen}
        >
          Excel
        </Button>
      )}
    </div>
  );
}

const ExportarExcelAction = ({ datosExcel }) => {
  const ventas = datosExcel.map((compra) => {
    const {
      producto,
      fecha_registro,
      color,
      medida,
      cantidad_venta,
      unidad,
      venta_credito,
      descuento_producto,
      iva_total,
      ieps_total,
      subtotal,
      impuestos,
      total,
      nota_credito,
      precio_actual_object,
      factura,
      venta,
    } = compra;
    let forma_pago = "";
    if (compra.forma_pago) {
      const forma_pago_filtrada = formaPago.filter(
        (forma) => forma.Value === compra.forma_pago
      );
      forma_pago = forma_pago_filtrada[0];
    }

    const compra_realizada = {
      folio_venta: venta.folio,
      cliente_venta:
        venta.cliente !== null
          ? venta.cliente.nombre_cliente
          : "Publico general",
      clave_cliente: venta.cliente !== null ? venta.cliente.clave_cliente : "-",
      usuario: venta.usuario.nombre,
      caja: venta.id_caja.numero_caja,
      status: venta.status,
      tipo_emision: venta.tipo_emision,
      producto: producto.datos_generales.nombre_comercial,
      codigo_barras: producto.datos_generales.codigo_barras,
      clave_alterna: producto.datos_generales.clave_alterna,
      fecha: moment(fecha_registro)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY"),
      fecha_registro: moment(fecha_registro)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY, hh:mm a"),
      color: color.color ? color.color : "N/A",
      medida: medida.medida ? `${medida.medida}/${medida.tipo}` : "N/A",
      cantidad_venta: cantidad_venta,
      unidad,
      costo: `$${
        producto.precios.precio_de_compra.precio_con_impuesto
          ? formatoMexico(producto.precios.precio_de_compra.precio_con_impuesto)
          : 0
      }`,
      numero_precio: precio_actual_object.numero_precio,
      precio_vendido: `$${
        precio_actual_object.precio_neto
          ? formatoMexico(precio_actual_object.precio_neto)
          : 0
      }`,
      venta_credito: venta_credito === true ? "Credito" : "Contado",
      forma_pago: `${forma_pago.Value} - ${forma_pago.Name}`,
      nota_credito: nota_credito ? "SI" : "NO",
      facturada: factura && factura.length > 0 ? "SI" : "NO",
      descuento_porcentaje: `%${
        descuento_producto ? descuento_producto.porciento : 0
      }`,
      descuento_precio: `$${
        descuento_producto
          ? formatoMexico(descuento_producto.dinero_descontado)
          : 0
      }`,
      iva_total: `$${iva_total ? formatoMexico(iva_total) : 0}`,
      ieps_total: `$${ieps_total ? formatoMexico(ieps_total) : 0}`,
      subtotal: `$${subtotal ? formatoMexico(subtotal) : 0}`,
      impuestos: `$${impuestos ? formatoMexico(impuestos) : 0}`,
      total: `$${total ? formatoMexico(total) : 0}`,
      cantidad_regresada: nota_credito ? nota_credito.cantidad_devuelta : "",
      cantidad_vendida: nota_credito ? nota_credito.cantidad_vendida : "",
      total_nota: nota_credito ? formatoMexico(nota_credito.total) : "",
    };

    return compra_realizada;
  });
  return (
    <ExcelExportButton
      data={ventas}
      name="Reporte de ventas por producto"
      button
      id="export-excel-button-ventas-productos"
    />
  );
};
