import React, { useContext, useEffect, useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
  Slide,
  Badge,
  DialogTitle,
} from "@material-ui/core";
import ListaVentas from "./ListaVentas";
import CloseIcon from "@material-ui/icons/Close";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { BsCartDashFill } from "react-icons/bs";
import { MainContext } from "../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VentasEspera() {
  let listaEnEspera = JSON.parse(localStorage.getItem("ListaEnEspera"));
  const { turnoEnCurso } = React.useContext(MainContext);
  const datosVentas = JSON.parse(localStorage.getItem("DatosVentas"));

  const { updateTablaVentas } = useContext(VentasContext);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 76) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open]);

  useEffect(() => {
    if (listaEnEspera) {
      listaEnEspera = JSON.parse(localStorage.getItem("ListaEnEspera"));
    }
  }, [updateTablaVentas]);

  return (
    <>
      <ListItem
        button
        onClick={handleClickOpen}
        disabled={!turnoEnCurso || (datosVentas && datosVentas.nota_credito)}
      >
        <ListItemIcon>
          <Badge
            badgeContent={listaEnEspera ? listaEnEspera.length : 0}
            color="primary"
            style={{ fontSize: 45 }}
          >
            <BsCartDashFill style={{ fontSize: 24 }} />
          </Badge>
        </ListItemIcon>
        <ListItemText primary="Ventas en Espera" />
      </ListItem>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
      >
        <DialogTitle component="div">
          <Box display="flex" alignItems="center">
            <Typography variant="h6">Ventas en espera</Typography>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClickOpen}
              size="large"
            >
              <CloseIcon />
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ListaVentas handleModalEspera={handleClickOpen} />
        </DialogContent>
      </Dialog>
    </>
  );
}
