import { Box, Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import { formatoMexico } from "../../../../..//config/reuserFunctions";
import moment from "moment-timezone";
import ExcelExportButton from "../../../../../components/ExcelExportButton";

export default function ExportarExcelAbonos({
  data,
  loadingConsulta,
  error,
  getExport,
  banderaExcel,
  setBanderaExcel,
  setBanderaPDF,
  objetoAbonos,
}) {
  const ExcelDownload = () => {
    setBanderaPDF(false);
    setBanderaExcel(true);
    getExport();
  };

  if (loadingConsulta && banderaExcel) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        Excel
      </Button>
    );
  }

  if (error) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        Excel
      </Button>
    );
  }

  return (
    <Box>
      {data ? (
        <ExportarExcelAction
          objetoAbonos={objetoAbonos}
          datosExcel={data}
          ExcelDownload={ExcelDownload}
        />
      ) : (
        <Button
          variant="text"
          color="primary"
          size="large"
          startIcon={<CircularProgress size={20} color="inherit" />}
        >
          Excel
        </Button>
      )}
    </Box>
  );
}

const ExportarExcelAction = ({ datosExcel, objetoAbonos, ExcelDownload }) => {
  const reporteData = datosExcel?.map((res) => {
    objetoAbonos = {
      folio: res.folio_venta,
      fecha_abono: moment(res.fecha_movimiento.completa)
        .tz("America/Mexico_City")
        .format("DD/MM/YYYY"),
      nombre: res.nombre_cliente,
      monto: "$" + formatoMexico(res.monto_total_abonado),
      forma_pago: res.metodo_de_pago.metodo,
      caja: res.numero_caja,
      status: res.status,
    };
    return objetoAbonos;
  });

  return (
    <ExcelExportButton
      data={reporteData}
      name="Reporte de Estado de cuenta Abonos"
      button
    />
  );
};
