import React, { Fragment, useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide,
  Typography,
} from "@material-ui/core";
import { BsFillCartXFill } from "react-icons/bs";
import useStyles from "../styles";
import { VentasContext } from "../../../context/Ventas/ventasContext";
import { ClienteCtx } from "../../../context/Catalogos/crearClienteCtx";
import { RemoveCircle } from "@material-ui/icons";
import { CustomButton } from "../../../components/CustomMuiComponents";
import { MainContext } from "../../../context/MainCtx";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CancelarVenta = (props) => {
  const { turnoEnCurso } = React.useContext(MainContext);
  const datosVenta = JSON.parse(localStorage.getItem("DatosVentas"));
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const {
    updateTablaVentas,
    setUpdateTablaVentas,
    setDatosVentasActual,
    setVentaRetomada,
    setPrecioSelectProductoVenta,
    ventaRapida,
    setPriceSelected,
  } = useContext(VentasContext);
  const { updateClientVenta, setUpdateClientVenta } = useContext(ClienteCtx);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    window.addEventListener("keydown", KeyDownFunction);
    function KeyDownFunction(e) {
      if (e.altKey && e.keyCode === 88 && datosVenta) {
        handleClickOpen();
      }
    }
    return () => window.removeEventListener("keydown", KeyDownFunction);
  }, [open]);

  const cancelarVenta = () => {
    localStorage.removeItem("DatosVentas");
    localStorage.removeItem("VentaOriginal");
    setUpdateTablaVentas(!updateTablaVentas);
    setUpdateClientVenta(!updateClientVenta);
    setDatosVentasActual({
      subTotal: 0,
      total: 0,
      impuestos: 0,
      iva: 0,
      ieps: 0,
      descuento: 0,
      monedero: 0,
    });
    setPrecioSelectProductoVenta([]);
    setVentaRetomada(datosVenta);
    setPriceSelected(1);
    setOpen(!open);
  };

  const isNota = datosVenta && datosVenta.nota_credito;

  return (
    <Fragment>
      {ventaRapida ? (
        <CustomButton
          {...props}
          fullWidth
          className="button-panel-venta b-primary"
          variant="outlined"
          size="large"
          startIcon={<BsFillCartXFill className="icon-btn-primary" />}
          onClick={handleClickOpen}
          disabled={!turnoEnCurso || !datosVenta}
        >
          Cancelar
        </CustomButton>
      ) : (
        <Button
          {...props}
          className={`button-panel-venta ${isNota ? "b-nota" : "b-primary"}`}
          fullWidth
          variant="outlined"
          onClick={handleClickOpen}
          disabled={!turnoEnCurso || !datosVenta}
        >
          <Box>
            <BsFillCartXFill
              className={isNota ? "icon-btn-nota" : "icon-btn-primary"}
            />
            <Typography className="text-btn-panel">
              {isNota ? "Cancelar Nota" : "Cancelar Venta"}
            </Typography>
            <Typography className="text-btn-panel-sec">Alt + X</Typography>
          </Box>
        </Button>
      )}

      <Dialog
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClickOpen}
        TransitionComponent={Transition}
      >
        <DialogTitle>¿Esta seguro que desea cancelar?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => handleClickOpen()}
            variant="outlined"
            color="default"
            size="large"
            autoFocus
            fullWidth
          >
            No, Cerrar
          </Button>
          <Button
            onClick={() => cancelarVenta()}
            variant="contained"
            color="secondary"
            startIcon={<RemoveCircle />}
            size="large"
            autoFocus
            fullWidth
          >
            Si, cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

CancelarVenta.muiName = Button.muiName;
export default CancelarVenta;
