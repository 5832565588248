import React, { useEffect } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import moment from "moment-timezone";
import { formatoMexico } from "../../../config/reuserFunctions";

export default function CreditoVenta({
  montos,
  setMontos,
  totales,
  setTotales,
  venta_credito,
  setMontoEnCaja,
  datosCliente,
  //setDatosCliente,
  fechaVencimientoDate,
  setfechaVencimientoDate,
  setAbonoMinimo,
  abono_minimo,
  fecha_venta,
}) {
  let venta_original = JSON.parse(localStorage.getItem("DatosVentas"));
  const total_venta = venta_original
    ? parseFloat(venta_original.total.toFixed(2))
    : 0;

  useEffect(() => {
    setfechaVencimientoDate(
      moment(fecha_venta)
        .tz("America/Mexico_City")
        .add(
          datosCliente.dias_credito ? parseInt(datosCliente.dias_credito) : 0,
          "days"
        )
        .format("YYYY-MM-DD")
    );
  }, [datosCliente, datosCliente.dias_credito, fecha_venta]);

  useEffect(() => {
    if (venta_credito) {
      setMontos({
        efectivo: 0,
        tarjeta: 0,
        puntos: 0,
        transferencia: 0,
        cheque: 0,
      });
      setTotales({
        ...totales,

        cambio: 0,
        monto_pagado: total_venta,
      });
      setMontoEnCaja(0);
    } else {
      setMontos({
        ...montos,
        efectivo: total_venta,
      });
      setMontoEnCaja(total_venta);
    }
  }, [venta_credito]);

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="caption">
          <b>Días a Crédito:</b>
        </Typography>
        <Box mr={1} />
        <Typography variant="caption">
          {datosCliente?.dias_credito || 0}
        </Typography>
        {/* <TextField
          label="Días a Crédito"
          fullWidth
          size="small"
          name="codigo_barras"
          id="form-producto-codigo-barras"
          variant="standard"
          value={
            datosCliente.dias_credito === null ? 0 : datosCliente.dias_credito
          }
          onChange={(e) =>
            setDatosCliente({
              ...datosCliente,
              dias_credito: e.target.value,
            })
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EventAvailable />
              </InputAdornment>
            ),
          }}
          disabled
        /> */}
      </Grid>
      <Grid item>
        <Typography variant="caption">
          <b>Vencimiento:</b>
        </Typography>
        <Box mr={1} />
        <Typography variant="caption">{fechaVencimientoDate}</Typography>
        {/* <TextField
          label="Fecha de Vencimiento"
          fullWidth
          size="small"
          variant="standard"
          type="date"
          value={fechaVencimientoDate}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Today />
              </InputAdornment>
            ),
          }}
          disabled
        /> */}
      </Grid>
      <Grid item>
        <Typography variant="caption">
          <b>Límite:</b>
        </Typography>
        <Box mr={1} />
        <Typography variant="caption">
          ${formatoMexico(datosCliente?.limite_credito)}
        </Typography>
        {/* <TextField
          label="Límite"
          fullWidth
          size="small"
          name="codigo_barras"
          id="form-producto-codigo-barras"
          variant="standard"
          value={
            datosCliente.limite_credito === null
              ? 0
              : datosCliente.limite_credito
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          disabled
        /> */}
      </Grid>
      <Grid item>
        <Typography variant="caption">
          <b>Disponible:</b>
        </Typography>
        <Box mr={1} />
        <Typography variant="caption">
          ${formatoMexico(datosCliente?.credito_disponible)}
        </Typography>
        {/* <TextField
          label="Disponible"
          fullWidth
          size="small"
          name="codigo_barras"
          id="form-producto-codigo-barras"
          variant="standard"
          value={
            datosCliente.credito_disponible === null
              ? 0
              : datosCliente.credito_disponible
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          disabled
        /> */}
      </Grid>
      <Grid item>
        <TextField
          label="Abono mínimo"
          fullWidth
          size="small"
          name="abono_minimo"
          id="form-abono_minim"
          variant="standard"
          onChange={(e) => setAbonoMinimo(e.target.value)}
          value={abono_minimo}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  );
}
