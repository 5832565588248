import { useApolloClient, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { OBTENER_HISTORIAL_ABONOS } from "../../../../../gql/Tesoreria/abonos";
import { Box, Button, CircularProgress } from "@material-ui/core";
import ComponentOnline from "../../../../../components/Connection/ComponentOnline";
import { Close } from "@material-ui/icons";
import FiltrosAbonos from "./FiltrosAbonos";
import TablaAbonos from "./TablaAbonos";
import { useEffect } from "react";
import { useDebounce } from "use-debounce";
import ErrorPage from "../../../../../components/ErrorPage";
import ExportarExcelAbonos from "./ExportarExcelAbonos";
import ExportarPDF from "./ExportPDF";

const initial_state_filtro = {
  fecha_inicio: "",
  fecha_fin: "",
  usuario: "",
  id_cliente: "",
  id_egreso: "",
  rol_movimiento: "ABONO_PROVEEDOR",
  id_compra: "",
  folio: "",
  forma_pago: "",
  caja: 0,
  canceladas: false,
  realizadas: false,
};

export default function ReportesAbono({ handleClickOpen }) {
  const [datosFiltro, setDatosFiltro] = useState(initial_state_filtro);
  const [banderaPDF, setBanderaPDF] = useState(false);
  const [banderaExcel, setBanderaExcel] = useState(false);
  const [value] = useDebounce(datosFiltro, 1000);
  const [loadingConsulta, setLoadingConsulta] = useState(false);
  const [error, setError] = useState(false);
  const client = useApolloClient();
  const [obtenerAbonos, setObtenerAbonos] = useState([]);
  const [limit, setLimit] = useState(11);
  const [offset, setOffset] = useState(0);
  const [totalDocs, setTotalDocs] = useState(0);
  let objetoAbonos = {};

  const limpiarDatos = () => {
    setDatosFiltro(initial_state_filtro);
  };

  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));

  const getExport = async () => {
    try {
      setLoadingConsulta(true);
      const response = await client.query({
        query: OBTENER_HISTORIAL_ABONOS,
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          input: value,
        },
        fetchPolicy: "network-only",
      });
      setLoadingConsulta(false);

      if (response.data) {
        setObtenerAbonos(response.data.obtenerHistorialAbonos.docs);
      }
      if (response.error) setError(true);
    } catch (error) {
      setLoadingConsulta(false);
      setError(true);
      console.log(error.networkError.result);
    }
  };

  useEffect(() => {
    getExport();
  }, [offset]);

  useEffect(() => {
    setOffset(0);
    getExport();
  }, [value]);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        position="absolute"
        top={5}
        right={10}
        gridGap={10}
      >
        <ComponentOnline classes />
        
        <Button
          color="primary"
          variant="text"
          onClick={limpiarDatos}
          startIcon={<Close />}
        >
          Limpiar Filtro
        </Button>
        <ExportarExcelAbonos
          data={obtenerAbonos}
          loadingConsulta={loadingConsulta}
          error={error}
          getExport={getExport}
          banderaExcel={banderaExcel}
          setBanderaPDF={setBanderaPDF}
          setBanderaExcel={setBanderaExcel}
          objetoAbonos={objetoAbonos}
        /> 
        <ExportarPDF
          data={obtenerAbonos}
          loadingConsulta={loadingConsulta}
          error={error}
          getExport={getExport}
          banderaPDF={banderaPDF}
          setBanderaPDF={setBanderaPDF}
          setBanderaExcel={setBanderaExcel}
          datosFiltro={datosFiltro}
        />     
        <Button
          variant="contained"
          color="default"
          disableElevation
          size="small"
          onClick={handleClickOpen}
          startIcon={<Close />}
        >
          Cerrar
        </Button>
      </Box>
      <FiltrosAbonos
        datosFiltro={datosFiltro}
        setDatosFiltro={setDatosFiltro}
        limpiarDatos={limpiarDatos}
      />
      <ClientesRender
        value={value}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalDocs={totalDocs}
        setTotalDocs={setTotalDocs}
      />
    </>
  );
}

const ClientesRender = ({
  value,
  limit,
  offset,
  setOffset,
  totalDocs,
  setTotalDocs,
}) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const { data, loading, error } = useQuery(OBTENER_HISTORIAL_ABONOS, {
    variables: {
      empresa: sesion.empresa._id,
      sucursal: sesion.sucursal._id,
      input: value,
      limit,
      offset,
    },
    fetchPolicy: "network-only",
  });

  if (data) {
    setTotalDocs(data.obtenerHistorialAbonos.totalDocs);
  }

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    console.log(error);
    return <ErrorPage error={error} />;
  }

  return (
    <>
      <TablaAbonos
        data={data}
        limit={limit}
        offset={offset}
        setOffset={setOffset}
        totalDocs={totalDocs}
      />
    </>
  );
};
