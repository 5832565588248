import React from "react";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListaVentasFactura from "../ListaVentas/ListaVentasFactura";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx.js";
import { tipoCambio, tiposCfdi, formaPago, metodoPago } from "../../catalogos";
import { Box, Paper, makeStyles } from "@material-ui/core";
import { CustoTextField } from "../CustomTextField.jsx";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
    height: "100%",
  },
  title: {
    position: "absolute",
    top: -30,
    left: -18,
    backgroundColor: "white",
    padding: "0 8px",
  },
}));

export default function CfdiFormDetail() {
  const classes = useStyles();
  const {
    datosFactura,
    setDatosFactura,
    error_validation,
    venta_factura,
  } = React.useContext(FacturacionCtx);

  const obtenerDatos = (e) => {
    const { name, value } = e.target;
    setDatosFactura({
      ...datosFactura,
      [name]: value,
    });
  };

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box position="relative">
        <Typography className={classes.title}>
          <b>Detalles de facturación</b>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={2}>
          <Typography color="textSecondary">Venta</Typography>
          <CustoTextField
            placeholder="Selecciona venta"
            fullWidth
            size="small"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ListaVentasFactura />
                </InputAdornment>
              ),
              readOnly: true,
              disabled: true,
            }}
            value={venta_factura ? venta_factura.folio : ""}
          />
        </Grid>
        <Grid item md={2}>
          <Typography color="textSecondary">Tipo de CFDI</Typography>
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            name="cfdi_type"
            error={error_validation.status && !datosFactura.cfdi_type}
          >
            <Select
              value={datosFactura.cfdi_type}
              name="cfdi_type"
              onChange={obtenerDatos}
              style={{
                paddingLeft: 4,
                height: 32,
              }}
            >
              <MenuItem value="">
                <em>Selecciona uno</em>
              </MenuItem>
              {tiposCfdi.map((res, index) => (
                <MenuItem
                  key={index}
                  value={res.Value}
                >{`${res.Value} - ${res.Name}`}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {error_validation.status && !datosFactura.cfdi_type
                ? error_validation.message
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <Typography color="textSecondary">Forma de pago</Typography>
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            name="payment_form"
            error={error_validation.status && !datosFactura.payment_form}
          >
            <Select
              value={datosFactura.payment_form}
              name="payment_form"
              onChange={obtenerDatos}
              style={{
                paddingLeft: 4,
                height: 32,
              }}
            >
              <MenuItem value="">
                <em>Selecciona uno</em>
              </MenuItem>
              {formaPago.map((res, index) => (
                <MenuItem
                  key={index}
                  value={res.Value}
                >{`${res.Value} - ${res.Name}`}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {error_validation.status && !datosFactura.payment_form
                ? error_validation.message
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <Typography color="textSecondary">Método de pago</Typography>
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            name="payment_method"
            error={error_validation.status && !datosFactura.payment_method}
          >
            <Select
              value={datosFactura.payment_method}
              name="payment_method"
              onChange={obtenerDatos}
              style={{
                paddingLeft: 4,
                height: 32,
              }}
            >
              <MenuItem value="">
                <em>Selecciona uno</em>
              </MenuItem>
              {metodoPago.map((res, index) => (
                <MenuItem
                  key={index}
                  value={res.Value}
                >{`${res.Value} - ${res.Name}`}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {error_validation.status && !datosFactura.payment_method
                ? error_validation.message
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <Typography color="textSecondary">Moneda</Typography>
          <FormControl
            variant="outlined"
            fullWidth
            size="small"
            name="currency"
            error={error_validation.status && !datosFactura.currency}
          >
            <Select
              value={datosFactura.currency}
              name="currency"
              onChange={obtenerDatos}
              style={{
                paddingLeft: 4,
                height: 32,
              }}
            >
              <MenuItem value="">
                <em>Selecciona uno</em>
              </MenuItem>
              {tipoCambio.map((res, index) => (
                <MenuItem
                  key={index}
                  value={res.Value}
                >{`${res.Value} - ${res.Name}`}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {error_validation.status && !datosFactura.currency
                ? error_validation.message
                : ""}
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
}
