import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Refresh, FilterDrama, CloudOff, Sync } from "@material-ui/icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Badge from "@material-ui/core/Badge";
import ComponentGetDataDBCloud from "./ComponentGetDataDBCloud";
import ComponentGetDataDBCloudAdmin from "./ComponentGetDataDBCloudAdmin";
import { AccesosContext } from "../../context/Accesos/accesosCtx";
import { IconButton } from "@material-ui/core";
import { MainContext } from "../../context/MainCtx";

export default function ComponentOnline({
  //isOnline,
  classes = {},
  //ventasToCloud,
  //sesion,
  fromVentas,
  size = "medium"
}) {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [openMenu, setOpenMenu] = useState(null);
  const { loadingPage, isOnline, ventasToCloud } = React.useContext(
    AccesosContext
  );
  const { isWeb } = React.useContext(MainContext);

  const handleClick = (event) => {
    setOpenMenu(event.currentTarget);
  };
  const handleClose = () => {
    setOpenMenu(null);
  };

  const ButtonContentSync = () => {
    if (!isOnline) {
      return <CloudOff />;
    } else {
      if (loadingPage) {
        return <Sync className="cloudSync" />;
      } else {
        if (ventasToCloud.length) {
          return (
            <Badge
              badgeContent={1}
              variant="dot"
              color="secondary"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <FilterDrama />
            </Badge>
          );
        } else {
          return <FilterDrama />;
        }
      }
    }
  };

  if(isWeb) return null

  return (
    <Box display="flex" alignItems={"center"}>
      <IconButton
        size={size}
        onClick={loadingPage ? null : handleClick}
        color="inherit"
        //className={classes.buttonIcon}
        //style={{ color: "white", borderColor: "white" }}
      >
        <ButtonContentSync />
      </IconButton>
      {fromVentas ? (
        <Menu
          id="simple-menu"
          anchorEl={openMenu}
          keepMounted
          open={openMenu}
          onClose={handleClose}
          style={{ zIndex: 9999 }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <MenuItem>
            <Button
              onClick={() => window.location.reload()}
              startIcon={<Refresh />}
            >
              Recargar aplicación
            </Button>
          </MenuItem>
          {/*    <MenuItem onClick={handleClose}>
            <ComponentVentasToCloud
              ventasToCloud={ventasToCloud}
              isOnline={isOnline}
              classes={classes}
            />
          </MenuItem> */}

          <MenuItem onClick={handleClose}>
            <ComponentGetDataDBCloud
              isOnline={isOnline}
              classes={classes}
              empresa={sesion.empresa}
              sucursal={sesion.sucursal}
            />
          </MenuItem>
        </Menu>
      ) : (
        <Menu
          id="simple-menu"
          anchorEl={openMenu}
          getContentAnchorEl={null}
          keepMounted
          open={openMenu}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem>
            <Button
              onClick={() => window.location.reload()}
              startIcon={<Refresh />}
            >
              Recargar aplicación
            </Button>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ComponentGetDataDBCloudAdmin
              isOnline={isOnline}
              classes={classes}
              empresa={sesion.empresa}
              sucursal={sesion.sucursal}
            />
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
}
