import React, { Fragment, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Dialog,
  Typography,
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  IconButton,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { DescriptionOutlined, Done } from "@material-ui/icons";
import { CANCELAR_ABONO_CLIENTE } from "../../../../../../../gql/Tesoreria/abonos";
import {
  formatoMexico,
  formatoFecha,
} from "../../../../../../../config/reuserFunctions";
import { imprimirTicketAbonos } from "../../../ImpTicketAbono";
import { AccesosContext } from "../../../../../../../context/Accesos/accesosCtx";
import { MainContext } from "../../../../../../../context/MainCtx";
import ExcelExportButton from "../../../../../../../components/ExcelExportButton";
const useStyles = makeStyles({});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
/* const columnsEffect = [
  {
    id: "fecha_movimiento_credito",
    label: "Fecha",
    minWidth: 60,
    widthPx: 160,
  },
  {
    id: "monto_total_abonado",
    label: "Cantidad abono",
    minWidth: 170,
    widthPx: 160,
  },
  { id: "cancelar", label: "Cancelar abono", minWidth: 170, widthPx: 160 },
]; */
const columnsExcel = [
  {
    id: "fecha_movimiento_credito",
    label: "Fecha",
    minWidth: 60,
    widthPx: 160,
  },
  {
    id: "monto_total_abonado",
    label: "Cantidad abono",
    minWidth: 170,
    widthPx: 160,
  },
  { id: "estatus", label: "Estado", minWidth: 200, widthPx: 200 },
];
function HistorialAbonos(props) {
  const classes = useStyles();
  const [openCancelar, setOpenCancelar] = useState(false);
  const [loadingCancelar, setLoadingCancelar] = useState(false);
  const [abonoSelected, setAbonoSelected] = useState(false);
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [cancelarAbonoCliente] = useMutation(CANCELAR_ABONO_CLIENTE);
  const { getData, ventaToCloud } = React.useContext(AccesosContext);
  const { snackMessage, turnoEnCurso } = React.useContext(MainContext);
  const navigate = useNavigate();

  const handleOpen = () => {
    props.setOpenHistorial(!props.openHistorial);
  };

  let dataExcel = [];
  if (props.rowSelected.abonos) {
    props.rowSelected.abonos.forEach((element) => {
      dataExcel.push({
        fecha_movimiento_credito: formatoFecha(
          element.fecha_movimiento.completa
        ),
        monto_total_abonado: formatoMexico(element.monto_total_abonado),
        estatus: element.status,
      });
    });
  }
  const affirmCancelar = (abono) => {
    try {
      if (props.rowSelected.facturacion) {
        snackMessage({
          message: "No se puede cancelar un abono facturado.",
          variant: "error",
        });
      } else {
        if (turnoEnCurso || sesion.accesos.tesoreria.caja_principal.ver) {
          setOpenCancelar(true);
          setAbonoSelected(abono);
        } else {
          navigate("/ventas");
        }
      }
    } catch (error) {}
  };

  const cancelAbono = async () => {
    try {
      setLoadingCancelar(true);

      const input = {
        tipo_movimiento: "CANCELACION_ABONO_CLIENTE",
        rol_movimiento: turnoEnCurso ? "CAJA" : "CAJA_PRINCIPAL",
        numero_caja: turnoEnCurso ? parseInt(turnoEnCurso.numero_caja) : 0,
        id_Caja: turnoEnCurso ? turnoEnCurso.id_caja : "",
        monto_abono: abonoSelected.monto_total_abonado,
        horario_turno: turnoEnCurso ? turnoEnCurso.horario_en_turno : "",
        concepto: "CANCELACION_ABONO_CLIENTE",
        id_usuario: sesion._id,
        numero_usuario_creador: sesion.numero_usuario,
        nombre_usuario_creador: sesion.nombre,
        id_cliente: props.rowSelected.cliente._id,
        credito_disponible: props.rowSelected.cliente.credito_disponible,
        numero_cliente: props.rowSelected.cliente.numero_cliente,
        nombre_cliente: props.rowSelected.cliente.nombre_cliente,
        telefono_cliente: props.rowSelected.cliente.telefono,
        email_cliente: props.rowSelected.cliente.email,
        id_abono: abonoSelected._id,
        id_venta: props.rowSelected._id,
        metodo_de_pago: abonoSelected.metodo_de_pago,
        caja_principal: turnoEnCurso
          ? false
          : sesion.accesos.tesoreria.caja_principal.ver,
      };

      await cancelarAbonoCliente({
        variables: {
          empresa: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          input: input,
        },
      });
      if (ventaToCloud) getData(sesion.empresa, sesion.sucursal);
      props.recargar();
      setOpenCancelar(false);
      setLoadingCancelar(false);
      handleOpen();
      snackMessage({
        message: "Abono cancelado con éxito.",
        variant: "success",
      });
    } catch (error) {
      setOpenCancelar(false);
      setLoadingCancelar(false);
      handleOpen();
      snackMessage({
        message: "La cancelación ha fallado.",
        variant: "error",
      });
    }
  };

  return (
    <Fragment>
      <Dialog
        open={props.openHistorial}
        onClose={handleOpen}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Transition}
      >
        <Dialog
          open={openCancelar}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenCancelar(false)}
          aria-labelledby="alert-eliminar-abono"
        >
          <DialogTitle id="alert-eliminar-abono">
            {"¿Está seguro de eliminar este abono?"}
          </DialogTitle>
          <DialogActions>
            <Button
              onClick={() => setOpenCancelar(false)}
              color="inherit"
              disabled={loadingCancelar}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => cancelAbono()}
              color="secondary"
              disabled={loadingCancelar}
              startIcon={
                loadingCancelar ? (
                  <CircularProgress size={20} color="inherit" />
                ) : null
              }
            >
              Eliminar
            </Button>
          </DialogActions>
        </Dialog>
        <DialogTitle>
          <Box display="flex">
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Historial de abonos
            </Typography>
            <Button
              variant="text"
              color="primary"
              onClick={handleOpen}
              size="large"
            >
              <CloseIcon style={{ fontSize: 30 }} />
            </Button>
          </Box>
          {props.rowSelected.abonos?.length > 0 ? (
            <Box
              mr={4}
              mrstyle={{ backgroundColor: "red", alignContent: "flex-end" }}
              justifyContent="flex-end"
            >
              <ExcelExportButton
                data={dataExcel}
                name="Historial Abonos"
                button
              />
            </Box>
          ) : (
            <div />
          )}
        </DialogTitle>
        <DialogContent>
          <Paper className={classes.root}>
            <TableContainer className={classes.container}>
              <Table stickyHeader size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Cantidad abono</TableCell>
                    <TableCell>Ticket</TableCell>
                    <TableCell>Cancelar abono</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.rowSelected.abonos?.map((abono, index) => {
                    return (
                      <ListaHistoriaAbonos
                        key={index}
                        row={abono}
                        affirmCancelar={affirmCancelar}
                        venta={props.rowSelected}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="primary"
            size="large"
            startIcon={<Done />}
            onClick={() => handleOpen()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

const ListaHistoriaAbonos = ({ row, affirmCancelar, venta }) => {
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const [loading, setLoading] = useState(false);
  const { snackMessage } = React.useContext(MainContext);

  const rePrint = async () => {
    setLoading(true);
    try {
      const dataImp = {
        turno: {
          usuario: row.nombre_usuario_creador,
          numero_usuario: row.numero_usuario_creador,
          caja: row.numero_caja,
          turno: row.horario_turno,
        },
        folio: venta.folio,
        numero_cliente: venta.cliente.numero_cliente,
        clave_cliente: venta.cliente.clave_cliente,
        nombre_cliente: venta.cliente.nombre_cliente,
        saldo_inicial: venta.total,
        total_anterior: row.saldo_credito_pendiente + row.monto_total_abonado,
        su_pago: row.monto_total_abonado,
        efectivo_recibido: row.montos_en_caja.monto_efectivo * -1,
        su_cambio: 0,
        total_restante: row.saldo_credito_pendiente,
        metodo_pago: row.metodo_de_pago ? row.metodo_de_pago.metodo : "",
        descuento: row.descuento ? row.descuento.dinero_descontado : 0,
        fecha_movimiento: row.fecha_movimiento.completa,
      };
      const result = await imprimirTicketAbonos(
        sesion, //sesion,
        dataImp, //datosAbonos
        "CLIENTE", //tipoAbono
        false, //liquidar
        true //reimpresion
      );
      if (result.error) {
        snackMessage({
          message: "Error de conexión con la impresora",
          variant: "error",
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      snackMessage({ message: "Hubo un error", variant: "error" });
    }
  };

  return (
    <TableRow hover tabIndex={-1}>
      <TableCell>{formatoFecha(row.fecha_movimiento.completa)}</TableCell>
      <TableCell padding="checkbox">
        <b>${formatoMexico(row.monto_total_abonado)}</b>
      </TableCell>
      <TableCell padding="checkbox" align="center">
        <IconButton size="small" onClick={() => rePrint()}>
          {loading ? <CircularProgress size={20} /> : <DescriptionOutlined />}
        </IconButton>
      </TableCell>
      {row.status === "CANCELADO" ? (
        <TableCell>{row.status}</TableCell>
      ) : (
        <TableCell padding="checkbox">
          <Button
            variant="text"
            color="primary"
            onClick={() => affirmCancelar(row)}
            size="large"
          >
            <CloseIcon style={{ fontSize: 22 }} />
          </Button>
        </TableCell>
      )}
    </TableRow>
  );
};

export default HistorialAbonos;
