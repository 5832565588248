import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Close, DescriptionOutlined, InfoOutlined } from "@material-ui/icons";
import { FaRegFilePdf } from "react-icons/fa";
import ReactToPrint from "react-to-print";
import { useState } from "react";
import ContentDataDoc from "./ContentData";

export default function ExportarPDF({
  data,
  loadingConsulta,
  error,
  getExport,
  banderaPDF,
  setBanderaPDF,
  setBanderaExcel,
  datosFiltro,
}) {
  const [open, setOpen] = useState(false);
  let ref = React.useRef();

  const handleClose = () => {
    setOpen(!open);
  };

  const handleExport = () => {
    setBanderaExcel(false);
    setBanderaPDF(true);
    getExport();
    handleClose();
  };

  if (loadingConsulta && banderaPDF) {
    return (
      <Button
        variant="text"
        color="primary"
        size="large"
        startIcon={<CircularProgress size={20} color="inherit" />}
      >
        PDF
      </Button>
    );
  }

  if (error) {
    return (
      <Button variant="text" color="primary" startIcon={<InfoOutlined />}>
        PDF
      </Button>
    );
  }

  return (
    <Box>
      <Button
        variant="text"
        color="primary"
        size="small"
        startIcon={<FaRegFilePdf />}
        onClick={() => handleExport()}
      >
        PDF
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle component="div">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gridGap={10}
          >
            <ReactToPrint
              trigger={() => (
                <Button
                  startIcon={<DescriptionOutlined />}
                  color="primary"
                  size="small"
                >
                  Descargar / imprimir
                </Button>
              )}
              content={() => ref.current}
            />
            <Button
              onClick={handleClose}
              color="default"
              variant="contained"
              disableElevation
              size="small"
              startIcon={<Close />}
            >
              Cerrar
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ContentDataDoc
            data={data}
            refcomp={ref}
            filter={datosFiltro}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
