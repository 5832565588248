import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Typography, Paper } from "@material-ui/core";
import { formatoMexico } from "../../../../../config/reuserFunctions";
import ModificarProductoFactura from "./EditarProductoFactura";
import EliminarProductoFactura from "./EliminarProductoFactura";
import { useContext } from "react";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-root": {
      border: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  paper: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(3),
  },
  title: {
    position: "absolute",
    top: -30,
    left: -18,
    backgroundColor: "white",
    padding: "0 8px",
  },
}));

export default function DetallesFactura() {
  const classes = useStyles();
  const { venta_factura, productos } = useContext(FacturacionCtx);

  if (!venta_factura) {
    return null;
  }

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Box position="relative">
        <Typography className={classes.title}>
          <b>Artículos</b>
        </Typography>
      </Box>
      <TableContainer>
        <Table stickyHeader size="small" aria-label="a dense table">
          <TableHead>
            <TableRow className={classes.root}>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox" />
              <TableCell style={{ width: 250 }}>Producto</TableCell>
              <TableCell align="right" style={{ width: 50 }}>
                Cantidad
              </TableCell>
              <TableCell align="right" style={{ width: 100 }}>
                IVA
              </TableCell>
              <TableCell align="right" style={{ width: 100 }}>
                IEPS
              </TableCell>
              <TableCell align="right" style={{ width: 100 }}>
                Subtotal
              </TableCell>
              <TableCell align="right" style={{ width: 100 }}>
                Impuestos
              </TableCell>
              <TableCell align="right" style={{ width: 100 }}>
                Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productos.map((producto, index) => {
              return (
                <RowTableProductos
                  venta={venta_factura}
                  producto={producto}
                  key={index}
                  index={index}
                />
              );
            })}
            <TableRow className={classes.root}>
              <TableCell colSpan={7} />
              <TableCell align="right">
                <b>Subtotal</b>
              </TableCell>
              <TableCell align="right">
                ${formatoMexico(venta_factura.subTotal)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.root}>
              <TableCell colSpan={7} />
              <TableCell align="right">
                <b>Impuestos</b>
              </TableCell>
              <TableCell align="right">
                ${formatoMexico(venta_factura.impuestos)}
              </TableCell>
            </TableRow>
            <TableRow className={classes.root}>
              <TableCell colSpan={7} />
              <TableCell align="right">
                <b>Total</b>
              </TableCell>
              <TableCell align="right">
                ${formatoMexico(venta_factura.total)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

const RowTableProductos = ({ venta, producto, index }) => {
  const { datos_generales } = producto.id_producto;

  return (
    <>
      <TableRow tabIndex={-1}>
        <TableCell style={{ padding: 0 }}>
          <EliminarProductoFactura
            venta={venta}
            producto={producto}
            index={index}
          />
        </TableCell>
        <TableCell style={{ padding: 0 }}>
          <ModificarProductoFactura
            venta={venta}
            producto={producto}
            index={index}
          />
        </TableCell>
        <TableCell>{datos_generales.nombre_comercial}</TableCell>
        <TableCell align="right">{producto.cantidad_venta}</TableCell>
        <TableCell align="right">
          ${formatoMexico(producto.iva_total)}
        </TableCell>
        <TableCell align="right">
          ${formatoMexico(producto.ieps_total)}
        </TableCell>
        <TableCell align="right">${formatoMexico(producto.subtotal)}</TableCell>
        <TableCell align="right">
          ${formatoMexico(producto.impuestos)}
        </TableCell>
        <TableCell align="right">${formatoMexico(producto.total)}</TableCell>
      </TableRow>
    </>
  );
};
