import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FacturacionCtx } from "../../../../../context/Facturacion/facturacionCtx.js";
import moment from "moment-timezone";
import { Grid, makeStyles } from "@material-ui/core";
import CodigoPostal from "../../NuevaFactura/Catalogos/CodigoPostal.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "6px",
  },
  folio: {
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(19),
  },
  paper: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    height: "100%",
  },
}));

export default function EmisorForm() {
  const classes = useStyles();
  const { datosFactura, setDatosFactura, error_validation } = React.useContext(
    FacturacionCtx
  );

  const obtenerDatos = (e) => {
    const { name, value } = e.target;
    setDatosFactura({
      ...datosFactura,
      [name]: value,
    });
  };

  const validateColor = (value) => {
    if (error_validation.status && !value) return { color: "red" };
    return { color: "black" };
  };

  return (
    <Box className={classes.paper}>
      <Grid container spacing={3} style={{alignItems: "center"}}>
        <Grid item>
          <Typography>{datosFactura.issuer.Name}</Typography>
        </Grid>
        <Grid item>
          <Typography style={validateColor(datosFactura.issuer.Rfc)}>
            {`RFC ${datosFactura.issuer.Rfc}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={validateColor(datosFactura.issuer.FiscalRegime)}>
            {`Régimen Fiscal ${datosFactura.issuer.FiscalRegime}`}
          </Typography>
        </Grid>
        <Grid item md={2}>
          <CodigoPostal />
        </Grid>
      </Grid>
      <Box className={classes.folio}>
        <Typography style={{ fontSize: 17 }}>
          <b style={validateColor(datosFactura.folio)}>{`Folio: `}</b>
          {datosFactura.folio}
        </Typography>
        <Typography style={{ fontSize: 17 }}>
          <b style={validateColor(datosFactura.serie)}>{`Serie: `}</b>
          {datosFactura.serie}
        </Typography>
        <FormControl
          variant="outlined"
          size="small"
          name="date"
          error={error_validation.status && !datosFactura.date}
        >
          <Select
            value={datosFactura.date}
            name="date"
            onChange={obtenerDatos}
            classes={{
              root: classes.root,
            }}
          >
            <MenuItem value="">
              <em>Selecciona una fecha</em>
            </MenuItem>
            <MenuItem value="0">
              {moment().tz("America/Mexico_City").format("LL")}
            </MenuItem>
            <MenuItem value="1">
              {moment().tz("America/Mexico_City").subtract(1, "d").format("LL")}
            </MenuItem>
            <MenuItem value="2">
              {moment().tz("America/Mexico_City").subtract(2, "d").format("LL")}
            </MenuItem>
          </Select>
          <FormHelperText>
            {error_validation.status && !datosFactura.date
              ? error_validation.message
              : ""}
          </FormHelperText>
        </FormControl>
      </Box>
    </Box>
  );
}
