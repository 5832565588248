import React from "react";
import Cajas from "./Cajas/Cajas";
import { useState } from "react";

export default function CajaIndex() {
  const [cajaSelected, setCajaSelected] = useState({});
  let obtenerCajasSucursal = [];
  return (
    <>
      <Cajas
        obtenerCajasSucursal={obtenerCajasSucursal}
        cajaSelected={cajaSelected}
        setCajaSelected={setCajaSelected}
      />
    </>
  );
}
