import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import moment from "moment-timezone";
import { formatoMexico } from "../../../../config/reuserFunctions";

const columns = [
  { label: "MONTOS", id: "montos" },
  { label: "SALDO INICIAL", id: "incial" },
  { label: "GASTOS", id: "gastos" },
  { label: "INGRESOS", id: "ingresos" },
  { label: "TOTAL EN SISTEMA", id: "sistema" },
  { label: "DECLARADO EN CAJA", id: "declarado" },
  { label: "DIFERENCIA", id: "diferencia" },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function InformacionCorteCaja({
  corte,
  montos_sistema,
  datos_ventas,
  loading,
  fechaInicio,
  fechaFin,
}) {
  const classes = useStyles();

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="30vh"
      >
        <CircularProgress />
      </Box>
    );

  const {
    movimientos_efectivo,
    movimientos_gastos,
    movimientos_ingresos,
  } = montos_sistema;

  const gastos_tarjetas =
    movimientos_gastos.monto_tarjeta_debito +
    movimientos_gastos.monto_tarjeta_credito;
  const ingresos_tarjetas =
    movimientos_ingresos.monto_tarjeta_debito +
    movimientos_ingresos.monto_tarjeta_credito;
  const totales_tarjetas =
    movimientos_efectivo.monto_tarjeta_debito +
    movimientos_efectivo.monto_tarjeta_credito;
  const corte_tarjetas =
    corte.montos_en_caja.monto_tarjeta_debito.monto +
    corte.montos_en_caja.monto_tarjeta_credito.monto;

  return (
    <>
      <Box textAlign="center">
        <Typography variant="h5">
          <b>Reporte de corte de caja {corte.sucursal.nombre_sucursal}</b>
        </Typography>
      </Box>
      <Box p={1} textAlign="center">
        <Typography variant="h6">
          {`Del ${moment(fechaInicio)
            .tz("America/Mexico_City")
            .format("LLL")} al ${moment(fechaFin)
            .tz("America/Mexico_City")
            .format("LLL")}`}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box p={1}>
          <Typography>
            <b>Usuario: </b> {corte.usuario_en_turno.nombre}
          </Typography>
        </Box>
        <Box p={1}>
          <Typography>
            <b>No. de usuario: </b>
            {corte.usuario_en_turno.numero_usuario}
          </Typography>
        </Box>

        <Box p={1}>
          <Typography>
            <b>Horario en turno: </b>
            {corte.horario_en_turno}
          </Typography>
        </Box>
        <Box p={1}>
          <Typography>
            <b>Numero de caja: </b>
            {corte.numero_caja}
          </Typography>
        </Box>
      </Box>
      <Paper className={classes.root} variant="outlined">
        <TableContainer>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Contado</TableCell>
                <TableCell align="center">Crédito</TableCell>
                <TableCell align="center">Descuento</TableCell>
                <TableCell align="center">Canceladas</TableCell>
                <TableCell align="center">Total Ventas</TableCell>
                <TableCell align="center">Subtotal</TableCell>
                <TableCell align="center">Impuestos</TableCell>
                <TableCell align="center">Descuentos</TableCell>
                <TableCell align="center">Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableCell align="center">
                {datos_ventas.ventas_contado}
              </TableCell>
              <TableCell align="center">
                {datos_ventas.ventas_creditos}
              </TableCell>
              <TableCell align="center">
                {datos_ventas.ventas_descuentos}
              </TableCell>
              <TableCell align="center">
                {datos_ventas.ventas_canceladas}
              </TableCell>
              <TableCell align="center">
                {datos_ventas.cantidad_total_ventas}
              </TableCell>
              <TableCell align="center">
                <b>${formatoMexico(datos_ventas.subtotal_ventas)}</b>
              </TableCell>
              <TableCell align="center">
                <b>${formatoMexico(datos_ventas.impuestos)}</b>
              </TableCell>
              <TableCell align="center">
                <b>${formatoMexico(datos_ventas.total_descuentos)}</b>
              </TableCell>
              <TableCell align="center">
                <b>${formatoMexico(datos_ventas.total_ventas)}</b>
              </TableCell>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box my={2} />
      <Paper className={classes.root} variant="outlined">
        <TableContainer>
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">MONTOS</TableCell>
                <TableCell align="right">SALDO INICIAL</TableCell>
                <TableCell align="right">GASTOS</TableCell>
                <TableCell align="right">INGRESOS</TableCell>
                <TableCell align="right">TOTAL EN SISTEMA</TableCell>
                <TableCell align="right">DECLARADO EN CAJA</TableCell>
                <TableCell align="right">DIFERENCIA</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <b>EFECTIVO</b>
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_efectivo.monto_inicial)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_gastos.monto_efectivo)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_ingresos.monto_efectivo)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_efectivo.monto_efectivo)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(corte.montos_en_caja.monto_efectivo.monto)}
                </TableCell>
                <TableCell align="right">
                  $
                  {formatoMexico(
                    corte.montos_en_caja.monto_efectivo.monto -
                      movimientos_efectivo.monto_efectivo
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <b>TARJETAS</b>
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  ${formatoMexico(gastos_tarjetas)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(ingresos_tarjetas)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(totales_tarjetas)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(corte_tarjetas)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(corte_tarjetas - totales_tarjetas)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <b>TRANSFERENCIAS</b>
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  ${formatoMexico(movimientos_gastos.monto_transferencia)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_ingresos.monto_transferencia)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_efectivo.monto_transferencia)}
                </TableCell>
                <TableCell align="right">
                  $
                  {formatoMexico(
                    corte.montos_en_caja.monto_transferencia.monto
                  )}
                </TableCell>
                <TableCell align="right">
                  $
                  {formatoMexico(
                    corte.montos_en_caja.monto_transferencia.monto -
                      movimientos_efectivo.monto_transferencia
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <b>MONEDERO</b>
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  ${formatoMexico(movimientos_gastos.monto_monedero)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_ingresos.monto_monedero)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_efectivo.monto_monedero)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(corte.montos_en_caja.monto_monedero.monto)}
                </TableCell>
                <TableCell align="right">
                  $
                  {formatoMexico(
                    corte.montos_en_caja.monto_monedero.monto -
                      movimientos_efectivo.monto_monedero
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <b>CRÉDITOS</b>
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  ${formatoMexico(movimientos_gastos.monto_creditos)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_ingresos.monto_creditos)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_efectivo.monto_creditos)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(corte.montos_en_caja.monto_creditos.monto)}
                </TableCell>
                <TableCell align="right">
                  $
                  {formatoMexico(
                    corte.montos_en_caja.monto_creditos.monto -
                      movimientos_efectivo.monto_creditos
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <b>CHEQUES</b>
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  ${formatoMexico(movimientos_gastos.monto_cheques)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_ingresos.monto_cheques)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_efectivo.monto_cheques)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(corte.montos_en_caja.monto_cheques.monto)}
                </TableCell>
                <TableCell align="right">
                  $
                  {formatoMexico(
                    corte.montos_en_caja.monto_cheques.monto -
                      movimientos_efectivo.monto_cheques
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <b>VALES DE DESPENSA</b>
                </TableCell>
                <TableCell align="right" />
                <TableCell align="right">
                  ${formatoMexico(movimientos_gastos.monto_vales_despensa)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_ingresos.monto_vales_despensa)}
                </TableCell>
                <TableCell align="right">
                  ${formatoMexico(movimientos_efectivo.monto_vales_despensa)}
                </TableCell>
                <TableCell align="right">
                  $
                  {formatoMexico(
                    corte.montos_en_caja.monto_vales_despensa.monto
                  )}
                </TableCell>
                <TableCell align="right">
                  $
                  {formatoMexico(
                    corte.montos_en_caja.monto_vales_despensa.monto -
                      movimientos_efectivo.monto_vales_despensa
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
