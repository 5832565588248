import React, { useContext, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  Box,
  Grid,
  TextField,
  Button,
  Container,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import { Typography, Divider, DialogActions } from "@material-ui/core";
import SnackBarMessages from "../../../../components/SnackBarMessages";
import BackdropComponent from "../../../../components/Layouts/BackDrop";
import ErrorPage from "../../../../components/ErrorPage";
import { useApolloClient, useMutation, useQuery } from "@apollo/client";

import { EmpresaContext } from "../../../../context/Catalogos/empresaContext";
import {
  ACTUALIZAR_EMPRESA,
  OBTENER_DATOS_EMPRESA,
} from "../../../../gql/Empresa/empresa";
import { regimenFiscal } from "../../Facturacion/catalogos";
import { Alert, AlertTitle } from "@material-ui/lab";
import RegistroSellos from "../../Facturacion/CFDISellos/RegistrarSellos";
import EliminarSellos from "../../Facturacion/CFDISellos/EliminarCSD";
import moment from "moment-timezone";
import { Info } from "@material-ui/icons";
import { AccesosContext } from "../../../../context/Accesos/accesosCtx";
import RegimenFiscalInput from "./FiscalRegimen";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  icon: {
    fontSize: 100,
  },
  subtitle: {
    marginLeft: "10px",
    width: "100%",
  },
  require: {
    "& span": {
      color: "red",
    },
  },
}));

export default function InformacionFiscal({ open, setOpen }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [errorForm, setErrorForm] = React.useState(
    useState({ error: false, message: "" })
  );
  const [alert, setAlert] = useState({ message: "", status: "", open: false });
  const sesion = JSON.parse(localStorage.getItem("sesionCafi"));
  const bloqueo =
    sesion.accesos.mi_empresa.informacion_fiscal.editar === false
      ? true
      : false;
  const { empresa, setEmpresa } = useContext(EmpresaContext);
  const { isOnline } = React.useContext(AccesosContext);
  const [actualizarEmpresa] = useMutation(ACTUALIZAR_EMPRESA);
  const [empresaFiscal, setEmpresaFiscal] = useState({
    nombre_fiscal: "",
    rfc: "",
    curp: "",
    info_adicio: "",
    regimen_fiscal: "",
    direccionFiscal: {
      calle: "",
      no_ext: "",
      no_int: "",
      codigo_postal: "",
      colonia: "",
      municipio: "",
      localidad: "",
      estado: "",
      pais: "",
    },
    datosBancarios: {
      cuenta: "",
      sucursal: "",
      clave_banco: "",
    },
  });
  const client = useApolloClient();

  const getEmpresaData = async () => {
    try {
      setLoading(true);
      const response = await client.query({
        query: OBTENER_DATOS_EMPRESA,
        variables: { id: sesion.empresa._id },
        fetchPolicy: "network-only",
      });
      if (response.data) {
        setLoading(false);
        const emp = response.data.obtenerEmpresa;
        let new_sesion = { ...sesion };
        new_sesion.empresa = emp;
        localStorage.setItem("sesionCafi", JSON.stringify(new_sesion));
        setEmpresa(emp);
        setEmpresaFiscal({
          nombre_fiscal: emp.nombre_fiscal,
          rfc: emp.rfc,
          curp: emp.curp,
          fecha_registro_sello_sat: emp.fecha_registro_sello_sat
            ? moment(emp.fecha_registro_sello_sat)
                .tz("America/Mexico_City")
                .format("DD/MM/YYYY")
            : "",
          info_adicio: emp.info_adicio,
          regimen_fiscal: emp.regimen_fiscal,
          sello_sat: emp.sello_sat,
          nombre_cer: emp.nombre_cer,
          nombre_key: emp.nombre_key,
          limite_timbres: emp.limite_timbres,
          timbres_usados: emp.timbres_usados,
          direccionFiscal: {
            calle: emp.direccionFiscal.calle,
            no_ext: emp.direccionFiscal.no_ext,
            no_int: emp.direccionFiscal.no_int,
            codigo_postal: emp.direccionFiscal.codigo_postal,
            colonia: emp.direccionFiscal.colonia,
            municipio: emp.direccionFiscal.municipio,
            localidad: emp.direccionFiscal.localidad,
            estado: emp.direccionFiscal.estado,
            pais: emp.direccionFiscal.pais,
          },
          datosBancarios: {
            cuenta: emp.datosBancarios.cuenta,
            sucursal: emp.datosBancarios.sucursal,
            clave_banco: emp.datosBancarios.clave_banco,
          },
        });
        return;
      }
      if (response.error) setError(response.error);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(error);
    }
  };

  const actEmp = async () => {
    try {
      setLoading(true);
      delete empresaFiscal.fecha_registro_sello_sat;
      let input = { ...empresaFiscal };
      input.nombre_fiscal = empresaFiscal.nombre_fiscal.toUpperCase();

      await actualizarEmpresa({
        variables: {
          id: sesion.empresa._id,
          sucursal: sesion.sucursal._id,
          input,
        },
      });
      setLoading(false);
      setAlert({
        message: "Se han actualizado correctamente los datos.",
        status: "success",
        open: true,
      });
      setErrorForm(false);
      getEmpresaData();
    } catch (error) {
      setLoading(false);
      setAlert({ message: "Hubo un error", status: "error", open: true });
      console.log(error);
      if (error.response) {
        console.log(error.response);
      } else if (error.networkError) {
        console.log(error.networkError.result);
      } else if (error.graphQLErrors) {
        console.log(error.graphQLErrors);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const obtenerCampos = (e) => {
    setEmpresaFiscal({
      ...empresaFiscal,
      [e.target.name]: e.target.value,
    });
  };
  const obtenerCamposDireccion = (e) => {
    setEmpresaFiscal({
      ...empresaFiscal,
      direccionFiscal: {
        ...empresaFiscal.direccionFiscal,
        [e.target.name]: e.target.value,
      },
    });
  };
  const obtenerCamposBancarios = (e) => {
    setEmpresaFiscal({
      ...empresaFiscal,
      datosBancarios: {
        ...empresaFiscal.datosBancarios,
        [e.target.name]: e.target.value,
      },
    });
  };

  React.useEffect(() => {
    if (open) getEmpresaData();
  }, [open]);

  return (
    <div>
      <SnackBarMessages alert={alert} setAlert={setAlert} />
      <BackdropComponent loading={loading} setLoading={setLoading} />

      {error ? (
        <ErrorPage error={error} />
      ) : (
        <Container style={{ marginTop: 16 }}>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12} className={classes.require}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography>
                    <b>Nombre fiscal o Razón social</b>
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={bloqueo}
                    type="text"
                    size="small"
                    error={errorForm.error && !empresaFiscal.nombre_fiscal}
                    name="nombre_fiscal"
                    variant="outlined"
                    value={
                      empresaFiscal.nombre_fiscal
                        ? empresaFiscal.nombre_fiscal
                        : ""
                    }
                    helperText={
                      errorForm.error &&
                      errorForm.message !== "El campo nombre es obligatorio"
                        ? errorForm.message
                        : ""
                    }
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Tooltip
                            arrow
                            title={
                              <Typography>
                                NOTA: El nombre o razón social se debe registrar
                                en mayusculas y sin el régimen societario(SA. DE
                                CV, entre otros.)
                              </Typography>
                            }
                          >
                            <Info color="primary" />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                    onChange={obtenerCampos}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    <b>RFC</b>
                  </Typography>
                  <TextField
                    fullWidth
                    disabled={bloqueo}
                    size="small"
                    name="rfc"
                    variant="outlined"
                    value={empresaFiscal.rfc ? empresaFiscal.rfc : ""}
                    onChange={obtenerCampos}
                  />
                </Grid>
                <Grid item xs={12}>
                  <RegimenFiscalInput
                    state={empresaFiscal}
                    setState={setEmpresaFiscal}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <b>CURP</b>
                  </Typography>
                  <TextField
                    disabled={bloqueo}
                    fullWidth
                    size="small"
                    name="curp"
                    variant="outlined"
                    value={empresaFiscal.curp ? empresaFiscal.curp : ""}
                    onChange={obtenerCampos}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    <b>Info. Adicional</b>
                  </Typography>
                  <TextField
                    disabled={bloqueo}
                    fullWidth
                    size="small"
                    name="info_adicio"
                    variant="outlined"
                    value={
                      empresaFiscal.info_adicio ? empresaFiscal.info_adicio : ""
                    }
                    onChange={obtenerCampos}
                    multiline
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography className={classes.subtitle}>
                <b>Firma digital</b>
              </Typography>
              <Divider />
              <Box m={1}>
                {empresaFiscal.sello_sat === true ? (
                  <Alert
                    severity="success"
                    action={
                      <EliminarSellos
                        datosEmpresa={empresaFiscal}
                        refetch={getEmpresaData}
                      />
                    }
                  >
                    <AlertTitle>Firma Digital Activa</AlertTitle>
                  </Alert>
                ) : (
                  <Alert
                    severity="info"
                    action={
                      <RegistroSellos
                        datosEmpresa={empresaFiscal}
                        refetch={getEmpresaData}
                      />
                    }
                  >
                    <AlertTitle>Sin Firma Digital</AlertTitle>
                    <ul>
                      <li>RFC obligatorio</li>
                    </ul>
                  </Alert>
                )}
              </Box>
              <Box my={1} hidden={!empresaFiscal.nombre_cer}>
                <Typography>
                  <b>Archivo *.cer</b>
                </Typography>
                <Typography>
                  {empresaFiscal.nombre_cer ? empresaFiscal.nombre_cer : ""}
                </Typography>
              </Box>
              <Box my={1} hidden={!empresaFiscal.nombre_key}>
                <Typography>
                  <b>Archivo *.key</b>
                </Typography>
                <Typography>
                  {empresaFiscal.nombre_key ? empresaFiscal.nombre_key : ""}
                </Typography>
              </Box>
              <Box my={1} hidden={!empresaFiscal.fecha_registro_sello_sat}>
                <Typography>
                  <b>Fecha de registro</b>
                </Typography>
                <Typography>
                  {empresaFiscal.fecha_registro_sello_sat}
                </Typography>
              </Box>
              <Box display="flex">
                <Box mr={3}>
                  <Typography>
                    <b>Límite de timbres</b>
                  </Typography>
                  <Typography>{empresaFiscal.limite_timbres}</Typography>
                </Box>
                <Box>
                  <Typography>
                    <b>Timbres usados</b>
                  </Typography>
                  <Typography>{empresaFiscal.timbres_usados}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Box my={2}>
            <Typography className={classes.subtitle}>
              <b>Domicilio</b>
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Box>
                <Typography>
                  <b>Calle</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="calle"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.calle
                      ? empresaFiscal.direccionFiscal.calle
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
              <Box>
                <Typography>
                  <b>Num. Ext</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="no_ext"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.no_ext
                      ? empresaFiscal.direccionFiscal.no_ext
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
              <Box>
                <Typography>
                  <b>Num. Int</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="no_int"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.no_int
                      ? empresaFiscal.direccionFiscal.no_int
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box>
                <Typography>
                  <b>C.P.</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="codigo_postal"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.codigo_postal
                      ? empresaFiscal.direccionFiscal.codigo_postal
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
              <Box>
                <Typography>
                  <b>Colonia</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="colonia"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.colonia
                      ? empresaFiscal.direccionFiscal.colonia
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
              <Box>
                <Typography>
                  <b>Municipio</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="municipio"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.municipio
                      ? empresaFiscal.direccionFiscal.municipio
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box>
                <Typography>
                  <b>Localidad</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="localidad"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.localidad
                      ? empresaFiscal.direccionFiscal.localidad
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
              <Box>
                <Typography>
                  <b>Estado</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="estado"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.estado
                      ? empresaFiscal.direccionFiscal.estado
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
              <Box>
                <Typography>
                  <b>Pais</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="pais"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.direccionFiscal.pais
                      ? empresaFiscal.direccionFiscal.pais
                      : ""
                  }
                  onChange={obtenerCamposDireccion}
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={1}>
            <Typography className={classes.subtitle}>
              <b>Datos bancarios</b>
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography>
                  <b>Cuenta</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="cuenta"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.datosBancarios.cuenta
                      ? empresaFiscal.datosBancarios.cuenta
                      : ""
                  }
                  onChange={obtenerCamposBancarios}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography>
                  <b>Sucursal</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="sucursal"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.datosBancarios.sucursal
                      ? empresaFiscal.datosBancarios.sucursal
                      : ""
                  }
                  onChange={obtenerCamposBancarios}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box>
                <Typography>
                  <b>Clave de banco</b>
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  name="clave_banco"
                  variant="outlined"
                  disabled={bloqueo}
                  value={
                    empresaFiscal.datosBancarios.clave_banco
                      ? empresaFiscal.datosBancarios.clave_banco
                      : ""
                  }
                  onChange={obtenerCamposBancarios}
                />
              </Box>
            </Grid>
          </Grid>
          {sesion.accesos.mi_empresa.informacion_fiscal.editar ===
          false ? null : (
            <Box
              display="flex"
              style={{ position: "absolute", top: 10, right: 24 }}
            >
              <Button onClick={handleClose} color="primary">
                Cancelar
              </Button>
              <Box mx={1} />
              <Button
                onClick={() => actEmp()}
                color="primary"
                variant="contained"
                disableElevation
                disabled={!isOnline}
              >
                Guardar
              </Button>
            </Box>
          )}
        </Container>
      )}
    </div>
  );
}
